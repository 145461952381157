// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 8px;
`;

export const TitleContent = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 8px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;
