import * as React from "react";

const SvgFlyingHeart = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 45 23" {...props}>
        <path
            d="M42.917 2.544c1.17 1.207 1.71 2.74 1.638 4.55-.12 1.284-.442 2.481-.985 3.557-.419.99-.919 1.847-1.453 2.567l-.14.183c-.173.222-.389.48-.718.86l-1.027 1.172-.402.382-.193.208c-1.149 1.198-3.752 3.338-7.406 6.068l-.657.49a.893.893 0 01-.385.118l-.25.012-.082-.001a.779.779 0 01-.296-.085l-.874-.658c-3.296-2.497-5.393-4.303-6.178-5.305l-.344-.355-1.127-1.556-.355-.48c-.325-.54-.623-1.354-.894-2.446-.269-1.083-.348-2.277-.234-3.493.171-1.84.954-3.371 2.413-4.76 1.431-1.362 3.093-2.105 4.895-2.198 2.08-.108 3.71.578 4.842 2.067l.14.19.18-.202c1.457-1.594 3.254-2.49 5.269-2.593 1.883-.097 3.425.473 4.623 1.708zM15.929 18.059l.505.13.5.128c2.548.626 5.02.835 7.421.626.308.276.651.574 1.028.891-2.807.372-5.71.187-8.701-.55l-.505-.13-.5-.127c-4.99-1.227-9.694-.852-14.129 1.123a.5.5 0 11-.407-.913c4.645-2.07 9.58-2.461 14.788-1.178zm1.267-4.137l.505.13.5.128c.861.212 1.715.376 2.56.492l.065.115.408.559.307.425a25.526 25.526 0 01-3.593-.623l-.504-.13-.5-.128c-4.991-1.227-9.695-.851-14.13 1.124a.5.5 0 01-.406-.914c4.645-2.069 9.58-2.46 14.788-1.178zm1.267-4.136l.504.13.5.127.055.013c.02.357.056.71.107 1.054a36.667 36.667 0 01-.414-.1l-.505-.128-.5-.128c-4.99-1.227-9.694-.852-14.128 1.123a.5.5 0 01-.407-.913c4.645-2.07 9.58-2.461 14.788-1.178zm1.234-4.273l.505.13.032.007a7.762 7.762 0 00-.355.94l-.434-.11c-4.99-1.226-9.694-.851-14.129 1.124a.5.5 0 11-.407-.913c4.645-2.07 9.58-2.461 14.788-1.178z"
            fill="currentcolor"
            fillRule="nonzero"
            stroke="currentcolor"
            strokeWidth={0.5}
        />
    </svg>
);

export default SvgFlyingHeart;
