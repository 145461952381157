import * as React from "react";

const SvgScorpioIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 17 16" {...props}>
        <path
            d="M.946 4.943c0-.48 0-.917-.043-1.31a87.766 87.766 0 00-.13-1.136 5.264 5.264 0 00-.216-.96A7.58 7.58 0 00.254.618h1.298c.173.35.26.699.389 1.092.086.393.173.742.216 1.135.087-.218.216-.393.346-.611l.39-.655c.129-.219.302-.393.475-.612.173-.218.39-.393.562-.568h1.124c.433.262.822.612 1.082 1.005.259.393.475.917.648 1.529.13-.219.26-.437.39-.7.13-.217.259-.436.432-.654.173-.219.302-.437.475-.612.173-.218.346-.393.563-.524h1.124c.649.393 1.124 1.005 1.513 1.835.39.83.52 1.834.52 3.014v4.499c0 .567.043 1.092.13 1.485.086.393.215.742.432.96.173.263.432.437.691.569.26.13.606.174.952.174h.389c.13 0 .26 0 .39-.043l-.13-1.092 1.73 1.485v.043l-1.687 1.835.043-1.005c-.13.044-.303.044-.433.044-.172 0-.302.044-.475.044-.562 0-1.081-.088-1.47-.262a2.364 2.364 0 01-1.038-.787 3.66 3.66 0 01-.606-1.31 7.24 7.24 0 01-.216-1.791V5.249c0-.524-.043-.961-.13-1.398-.086-.393-.173-.786-.302-1.092-.13-.306-.26-.568-.433-.786a1.946 1.946 0 00-.562-.48c-.216.218-.39.436-.605.698-.173.262-.346.524-.52.83a5.946 5.946 0 00-.432.917c-.13.35-.26.612-.389.961v7.513H5.79v-7.12c0-.611-.044-1.091-.087-1.528-.043-.437-.173-.786-.26-1.049-.13-.305-.259-.524-.432-.698a1.645 1.645 0 00-.562-.394c-.26.219-.476.437-.649.7-.216.261-.389.523-.562.83a5.946 5.946 0 00-.432.917c-.13.305-.26.611-.346.917V12.5H1.12V4.943H.945z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgScorpioIcon;
