// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, Switch, Knob, Label } from "./styled";
import { IToggleSwitch } from "./ToggleSwitch";
// #endregion Local Imports

const ToggleSwitch: React.FunctionComponent<IToggleSwitch.IProps> = ({
    isOn,
    label,
    _ref,
    onInputChange,
    name,
}): JSX.Element => {
    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Switch isOn={isOn}>
                <input
                    className="hidden"
                    name={name || ""}
                    type="checkbox"
                    checked={isOn}
                    onChange={onInputChange}
                    ref={_ref}
                    readOnly
                />
                <Knob isOn={isOn} />
            </Switch>
        </Container>
    );
};

export { ToggleSwitch };
