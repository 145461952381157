// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
`;

export const StyledIcon = styled.div`
    cursor: pointer;
`;

export const Modal = styled.div`
    position: absolute;
    bottom: 140px;
    z-index: 100;
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
`;
