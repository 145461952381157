// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IMenu } from "./Menu";
// #endregion Interface Imports

export const Menu: React.FunctionComponent<IMenu.IProps> = ({ children }) => {
    return <Container>{children}</Container>;
};
