// #region Global Imports
import * as React from "react";
/* import { useSelector } from "react-redux"; */
// #endregion Global Imports

// #region Local Imports
import { Image } from "@Components/Basic";
import { Container } from "./styled";
import { ILandingMobileHeader } from "./LandingMobileHeader";
/* import { Anniversary20th } from "../LandingHeader/styled";
import { IStore } from "@Interfaces"; */
// #endregion Local Imports

export const LandingMobileHeader: React.FunctionComponent<ILandingMobileHeader.IProps> = ({
    showLogoText,
}): JSX.Element => {
   /*  const { topComponent } = useSelector(({ home }: IStore) => home); */

    const src = `icons/siberalem-${showLogoText ? "logo" : "hearts"}-colored.svg`;

    const alt = showLogoText ? "siberalem logo full" : "siberalem logo hearts";

    const className = showLogoText ? "with-text" : "without-text";

    return (
        <Container>
            <Image src={src} alt={alt} className={className} />

            {/*  {topComponent.match("card") && (
                <Anniversary20th>
                    <img
                        data-cy=""
                        srcSet="/images/Badge.png, /images/Badge@2.png 2x"
                        src="/images/Badge@2.png"
                        alt="Siberalem 20 Yaşında!"
                    />
                </Anniversary20th>
            )} */}
        </Container>
    );
};
