// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    height: 100%;
    border-radius: 30px 30px 12px 12px;
    font-family: "Montserrat";
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.heights.modalHeaderHeight};
    background: ${({ theme }) => theme.gradients.greenToBlue};
    border-radius: 30px 30px 0px 0px;
    padding: 19px 0px 17px 38px;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 6px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.titleFontSizes.large};
    letter-spacing: -0.38px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        border-radius: unset;
    }
`;

export const ContentContainer = styled.div`
    background-color: #fff;
    border-radius: 0 0 30px 30px;

    display: flex;
    flex-direction: column;
    max-width: 730px;
    padding: 30px 95px 40px 95px;
    font-size: ${({ theme }) => theme.fontSizes.xLarge};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    letter-spacing: 0;
    line-height: 24px;
    overflow-y: scroll;
    max-height: calc(100vh - ${({ theme }) => theme.heights.modalHeaderHeight});

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        padding: 30px 80px 27px 80px;
        max-width: 610px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        border-radius: unset;
        padding: 30px 60px 27px 60px;
    }
`;

export const HintsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
`;

export const HintText = styled.span`
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.xLarge};
    color: ${({ theme }) => theme.colors.sliderAqua};
`;
export const Hints = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const customModalCloseIcon = css`
    color: #fff;
    top: 20px;
    font-size: 24px;
`;

export const HiddenPixel = styled.div`
    visibility: hidden;
    width: 1px;
    height: 1px;
    margin-top: 200px;
    display: none;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: block;
    }
`;
