// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    background-image: url("/images/free-credit-bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 -40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 370px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: unset;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        background-image: unset;
    }
`;
