// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { InvoiceService } from "@Services/API/Invoice";
import { ActionConsts } from "@Definitions";
import { InvoiceModel } from "@Interfaces";
// #endregion Local Imports

export const InvoiceActions = {
    GetUserInvoiceSummaryList: () => async (dispatch: Dispatch) => {
        const result = await InvoiceService.GetUserInvoiceSummaryList();
        if (result.Success) {
            dispatch({
                payload: {
                    InvoiceList: result.InvoiceList,
                },
                type: ActionConsts.Invoice.FetchUserInvoices,
            });
        } else {
            dispatch({
                payload: {
                    InvoiceList: [],
                },
                type: ActionConsts.Invoice.FetchUserInvoices,
            });
        }
    },
    AddInvoiceInfo: async (payload: InvoiceModel.AddInvoiceInfoPayload) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            type: ActionConsts.Invoice.AddInvoiceInfo.Request,
        });

        try {
            await InvoiceService.AddInvoiceInfo(payload);
            dispatch({
                type: ActionConsts.Invoice.AddInvoiceInfo.Success,
            });
        } catch (e) {
            dispatch({
                type: ActionConsts.Invoice.AddInvoiceInfo.Failed,
            });
        }
    },
};
