// #region Global Imports
import styled from "styled-components";
import TextareaAutosize from 'react-autosize-textarea';
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.mystic};
    box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    padding: 10px 18px;
`;

export const Textarea = styled(TextareaAutosize)`
    font-family: "Montserrat";
    max-height: 100px;
    min-height: 40px;
    resize: none;
    font-size: 14px;
    font-weight: 16px;
    width: 100%;
    border: none;
    :focus {
        outline: none;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Count = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray};
`;

export const IconStyle = styled.div`
    display: flex;
    cursor: pointer;
`;

export const StickersModal = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 45px;
    z-index: 100;
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
`;
