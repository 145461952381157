import styled from "styled-components";
import { theme } from "@Definitions";

const Container = styled.div``;

const InnerContainer = styled.div`
    width: 320px;

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        width: 100%;
    }
`;

const InputContainer = styled.div`
    div:first-child {
        margin-bottom: 15px;
    }
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 5px;
`;

const ContentText = styled.div`
    font-family: "Montserrat";
    color: #2a2a2a;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    width: 92%;
    line-height: 1.3;
`;

export { Container, InnerContainer, InputContainer, BoxContainer, ContentText };
