// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Dropdown, Button } from "@Components/Basic";
import {
    Container,
    FilterContent,
    AgeGroup,
    AgeToggleButton,
    LocationGroup,
    OtherGroup,
    ApplyButton,
    DropdownItem,
    Fader,
    HandyFilters,
    SwitchButtons,
    SwitchButton,
} from "./styled";
import { AgeRangeCard, SelectLocationCard, LocationFilter, ToggleSwitch } from "@Components";
import { MetaDataActions, InboxFilterActions } from "@Actions";

import mockData from "./mockData";
// #endregion Local Imports

// #region Interface Imports
import { IInboxFilter } from "./InboxFilter";
import { IStore, ISearchPage, IInboxPage, MetaDataModel, ConversationModel } from "@Interfaces";
// #endregion Interface Imports

export const InboxFilter: React.FunctionComponent<IInboxFilter.IProps> = ({
    t,
    onFilterButtonClick,
    resetFilter,
}) => {
    const { useState, useEffect, useCallback, useMemo } = React;
    const dispatch = useDispatch();

    const questionList = useMemo(
        () => ({
            Income: 51,
            Children: 63,
            Zodiac: 200,
            MaritalStatus: 201,
            RelationshipType: 202,
            Job: 203,
        }),
        []
    );

    const { countries, searchCities, towns } = useSelector((state: IStore) => state.metadata);

    const { filters } = useSelector((state: IStore) => state.inboxFilter);

    const { location, distance, ageRange, isOnline, hasPhoto, unread, ...advancedFilters } =
        filters;

    const setFilters = useCallback(
        async (nextFilters: IInboxPage.Filters) => {
            dispatch(await InboxFilterActions.SetFilters(nextFilters));
        },
        [dispatch]
    );

    // #region UI state
    const [ageCardIsVisible, setAgeCardIsVisible] = useState(false);
    const [locationCardIsVisible, setLocationCardIsVisible] = useState(false);
    // #endregion UI state

    // #region initial self values
    const self = useSelector((state: IStore) => state.profile.self);

    useEffect(() => {
        if (self) {
            if (Object.keys(location ?? {}).length === 0) {
                const selfAgeRange = {
                    from: parseInt(self.LookingForMinAge, 10),
                    to: parseInt(self.LookingForMaxAge, 10),
                };

                const selfLocation: ISearchPage.SelectedLocation = {
                    countryId: parseInt(self.CountryId, 10),
                    countryName: self.CountryName,
                    cityId: parseInt(self.CityId, 10),
                    cityName: self.CityName,
                    townId: parseInt(self.TownId, 10),
                    townName: self.TownName,
                };

                setFilters({
                    location: selfLocation,
                    ageRange: selfAgeRange,
                    hasPhoto: false,
                    unread: false,
                    isOnline: false,
                    MaritalStatus: undefined,
                    RelationshipType: undefined,
                    Job: undefined,
                    Zodiac: undefined,
                    Income: undefined,
                    Children: undefined,
                });
            }
        }
    }, [resetFilter, setFilters, self, location]);
    // #endregion initial self values

    // #region location actions
    const fetchCountries = useCallback(async () => {
        dispatch(await MetaDataActions.FetchCountries());
    }, [dispatch]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    const fetchCities = async (countryId: number) => {
        dispatch(await MetaDataActions.FetchCities(countryId, true));
    };

    const fetchTowns = async (cityId: number) => {
        dispatch(await MetaDataActions.FetchTowns(cityId, true));
    };
    // #endregion location actions

    // #region Other Group
    const mockedData: MetaDataModel.IFetchSearchFilterQuestions = mockData as any;

    const searchFilterQuestions: MetaDataModel.IFetchSearchFilterQuestions = {
        Questions: mockedData.Questions,
    };

    const getOptionsByItem = (item: MetaDataModel.IFetchSearchFilterQuestion) => {
        return item.ThirdPersonOptions.length > 0
            ? item.ThirdPersonOptions.map(val => ({
                  value: val.Id,
                  label: val.Name,
              }))
            : item.Options.map(val => ({
                  value: val.Id,
                  label: val.ThirdPersonName || val.Name,
              }));
    };
    // #endregion Other Group

    const filterPayloadSerializer = () => {
        interface SearchPayload extends ConversationModel.ISearchInPayload {
            [key: string]: boolean | string | number;
        }
        const filterData: Partial<SearchPayload> = {};

        if (ageRange) {
            filterData.minAge = ageRange!.from;
            filterData.maxAge = ageRange!.to;
        }
        if (location) {
            if (location.countryId !== undefined) filterData.country = Number(location.countryId);
            if (location.cityId) filterData.city = Number(location.cityId);
            if (location.townId) filterData.town = Number(location.townId);
        }
        if (distance) filterData.distance = distance;
        if (isOnline) filterData.isOnline = isOnline;
        if (hasPhoto) filterData.hasphoto = hasPhoto;
        if (unread) filterData.unread = unread;
        if (advancedFilters) {
            Object.keys(advancedFilters).forEach(k => {
                const lowkey = k.charAt(0).toLowerCase() + k.slice(1);
                filterData[lowkey] = (advancedFilters as any)[k].join(",");
            });
        }
        return filterData;
    };

    const handleFilter = () => {
        const SearchIn: Partial<ConversationModel.ISearchInPayload> = filterPayloadSerializer();
        const filterPayload: ConversationModel.IFilterRequest = {
            count: 0,
            SearchIn,
        };

        let filterCount = Object.keys(SearchIn).length;
        if (SearchIn.maxAge) filterCount -= 1;
        if (!SearchIn.town && !SearchIn.city && SearchIn.country === undefined) filterCount += 1;
        else {
            if (SearchIn.town) filterCount -= 1;
            if (SearchIn.city) filterCount -= 1;
        }

        onFilterButtonClick(filterPayload, filterCount);
    };

    const renderLocationFilter = () => (
        <LocationGroup>
            <LocationFilter
                cardIsVisible={locationCardIsVisible}
                selectedLocation={location}
                toggleLocationCard={() => {
                    setLocationCardIsVisible(val => !val);
                }}
                handleSliderChange={val =>
                    setFilters({
                        distance: val,
                    })
                }
                currentDistance={distance ?? 0}
            />
            {locationCardIsVisible && (
                <Fader>
                    <SelectLocationCard
                        t={t}
                        fetchCities={fetchCities}
                        fetchTowns={fetchTowns}
                        countries={countries}
                        cities={searchCities}
                        towns={towns}
                        defaultLocation={location}
                        onClickOverlay={() => setLocationCardIsVisible(false)}
                        saveLocation={newLocation => {
                            setFilters({
                                location: newLocation,
                            });
                            setLocationCardIsVisible(false);
                        }}
                    />
                </Fader>
            )}
        </LocationGroup>
    );

    const renderAgeFilter = () => (
        <AgeGroup>
            <AgeToggleButton
                onClick={() => {
                    setAgeCardIsVisible(val => !val);
                }}
                isActive={ageCardIsVisible}
            >
                <span>
                    {`${ageRange!.from} - ${ageRange!.to} `}
                    {t("common:inbox.filter.age")}
                </span>
            </AgeToggleButton>
            {ageCardIsVisible && (
                <Fader>
                    <AgeRangeCard
                        t={t}
                        saveAgeRange={(from, to) => {
                            setFilters({
                                ageRange: {
                                    from,
                                    to,
                                },
                            });
                            setAgeCardIsVisible(false);
                        }}
                        onClickOverlay={() => setAgeCardIsVisible(val => !val)}
                        lookingForMin={ageRange!.from}
                        lookingForMax={ageRange!.to}
                    />
                </Fader>
            )}
        </AgeGroup>
    );

    const renderToggleFilters = () => (
        <HandyFilters>
            <SwitchButtons>
                <SwitchButton
                    onClick={() => {
                        setFilters({
                            unread: !unread,
                        });
                    }}
                >
                    <ToggleSwitch isOn={!!unread} label={t("common:inbox.filter.onlyNotRead")} />
                </SwitchButton>
                <SwitchButton
                    onClick={() => {
                        setFilters({
                            isOnline: !isOnline,
                        });
                    }}
                >
                    <div className="mr">
                        <ToggleSwitch isOn={!!isOnline} label={t("common:inbox.filter.inSystem")} />
                    </div>
                </SwitchButton>
                <SwitchButton
                    onClick={() => {
                        setFilters({
                            hasPhoto: !hasPhoto,
                        });
                    }}
                >
                    <div className="mr">
                        <ToggleSwitch
                            isOn={!!hasPhoto}
                            label={t("common:inbox.filter.withPhoto")}
                        />
                    </div>
                </SwitchButton>
            </SwitchButtons>
        </HandyFilters>
    );

    const renderAdvancedFilters = () => (
        <OtherGroup>
            {searchFilterQuestions.Questions.map(
                (item: MetaDataModel.IFetchSearchFilterQuestion) => {
                    const filterKey =
                        Object.entries(questionList).find(el => el[1] === item.Id)?.[0] ??
                        undefined;

                    const options = getOptionsByItem(item);

                    const selectedOptions = filterKey
                        ? ((advancedFilters as any)[filterKey] ?? []).map((id: number) =>
                              options.find(opt => opt.value === id)
                          )
                        : [];

                    return (
                        <DropdownItem key={`dropdown--${item.Id}`}>
                            <Dropdown
                                t={t}
                                placeholder={item.ThirdPersonName}
                                options={options}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                isMulti
                                minHeight="30px"
                                borderColor="#00F07B"
                                name={`filter-${item.ThirdPersonName}`}
                                value={selectedOptions}
                                onChange={option => {
                                    setFilters({
                                        [`${filterKey}`]: option.map(
                                            (o: { value: number; label: string }) => o.value
                                        ),
                                    });
                                }}
                            />
                        </DropdownItem>
                    );
                }
            )}
        </OtherGroup>
    );

    return (
        <Container>
            <FilterContent>
                <div className="d-flex my-2">
                    {renderToggleFilters()}
                    {renderAgeFilter()}
                    {renderLocationFilter()}
                </div>
            </FilterContent>
            {renderAdvancedFilters()}
            <ApplyButton>
                <Button styleType="ghost" size="small" onClick={handleFilter}>
                    {t("common:inbox.filter.applyButton")}
                </Button>
            </ApplyButton>
        </Container>
    );
};
