import * as React from "react";

const SvgSliderHandleDistance = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 17 25" {...props}>
        <path
            d="M16.902 8.56c0 1.15-.182 2.125-.548 2.833l-5.936 12.222c-.183.354-.457.62-.822.797-.274.177-.64.266-1.096.266-.457 0-.822-.089-1.096-.266-.365-.177-.64-.531-.822-.797L.646 11.393C.28 10.685.098 9.711.098 8.56c0-2.214.822-4.162 2.465-5.756C4.207 1.209 6.125.5 8.41.5c2.283 0 4.292.797 5.845 2.303 1.826 1.594 2.648 3.542 2.648 5.756zm-4.2 0c0-1.152-.366-2.126-1.188-2.835-.822-.797-1.827-1.151-2.923-1.151s-2.1.354-2.922 1.151C4.847 6.522 4.48 7.497 4.48 8.56c0 1.063.366 2.037 1.188 2.834.822.797 1.826 1.152 2.922 1.152s2.1-.355 2.923-1.152c.822-.797 1.187-1.771 1.187-2.834z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSliderHandleDistance;
