// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
// #endregion Local Imports

export const Container = styled.span<{ isActive?: boolean }>`
    border-radius: 8px;
    ${({ isActive, theme }) => (isActive ? `background-color: ${theme.colors.lightBlue};` : "")}
    
    svg {
        transform: rotate(180deg);
       }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
       svg {
        transform: rotate(-90deg);
       }
    }

`;

Container.displayName = "MenuItem";
