// #region Global Imports
import React, { FC } from "react";
import dynamic from "next/dynamic";
// #endregion Global Imports

const NoSsrComponent: FC = ({ children }) => <>{children}</>;

const NoSSR = dynamic(() => Promise.resolve(NoSsrComponent), {
    ssr: false,
});

export { NoSSR };
