import styled from "styled-components";

export const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Container = styled(FlexWrapper)`
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.3);
    height: 600px;
`;

export const ImageWrapper = styled.div`
    height: 330px;
`;

export const CardBottom = styled.div`
    padding: 10px;
    height: 100%;
`;

export const CardTitle = styled.div`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.heavyBold};
    font-size: 21px;
    color: ${({ theme }) => theme.colors.red};
    line-height: 27px;
    margin-top: 26px;
    margin-bottom: 8px;
`;

export const TitleTicks = styled.p`
    margin: 0;
    font-size: 54px;
    line-height: 5px;
`;

export const CardDescription = styled.div`
    font-family: "Montserrat";
    font-style: italic;
    font-size: 14px;
    color: #2a2a2a;
    line-height: 18px;
    margin-bottom: 8px;
`;

export const CardLink = styled.div`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 11px;
    color: #2a2a2a;
    padding-bottom: 5px;
`;

export const Anchor = styled.a`
    text-decoration: none;
    color: black;
    cursor: pointer;
`;
