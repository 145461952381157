import * as React from "react";

const SvgAppleIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 21 21" {...props}>
        <g transform="translate(.5 .25)" fill="none" fillRule="evenodd">
            <rect fill="#FFF" width={20} height={20} rx={10} />
            <path
                d="M11.491 6.136l.197.01a2.574 2.574 0 012.028 1.096 2.515 2.515 0 00-1.198 2.11 2.43 2.43 0 001.484 2.236 5.57 5.57 0 01-.772 1.571c-.454.68-.93 1.344-1.687 1.357-.735.017-.982-.433-1.825-.433-.851 0-1.114.42-1.82.45-.72.026-1.27-.726-1.741-1.4-.942-1.375-1.675-3.876-.692-5.578A2.701 2.701 0 017.738 6.17c.721-.015 1.39.487 1.834.487.435 0 1.262-.6 2.116-.51zm.205-2.67a2.476 2.476 0 01-.566 1.775c-.4.497-1.006.784-1.645.777a2.356 2.356 0 01.581-1.708 2.52 2.52 0 011.63-.843z"
                fill="#000"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgAppleIcon;
