// #region Global Imports
import { css } from "styled-components";
// #endregion Global Imports

export { Container, Row, Col } from "./Grid";
export { Hr } from "./Hr";

export const landingCardStyle = css`
    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: calc(100vh - 64px);
        justify-content: flex-end;
    }
`;

export const otherCardSectionStyle = css`
    height: 920px;
    padding-top: 125px;
`;
