// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Presentation } from "./index.presentation";
import { ProfileActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { ProfileModel, IStore } from "@Interfaces";
import { IQuickProfileFill } from "./QuickProfileFill";
// #endregion Interface Imports

const QuickProfileFill: React.FunctionComponent<IQuickProfileFill.IProps> = ({
    t,
    closeModal,
    profileEditMode,
}): JSX.Element => {
    const { useEffect, useCallback, useState } = React;
    const dispatch = useDispatch();

    const fetchedEmptyQuestions = useSelector(
        (store: IStore) => store.profile.fetchedEmptyQuestions
    );

    const profileEditQuestion = useSelector((store: IStore) => store.profile.editQuestion)
        ?.Question;

    const { currentEmptyQuestion, self } = useSelector(({ profile }: IStore) => profile);

    const getEmptyQuestions = useCallback(async () => {
        dispatch(await ProfileActions.GetEmptyQuestions());
    }, [dispatch]);

    const [theQuestion, setTheQuestion] = useState<ProfileModel.IQuestion>();

    const askTheQuestion = (question: ProfileModel.IQuestion) => {
        setTheQuestion(question);
    };

    const skipTheQuestion = async () => {
        dispatch(await ProfileActions.SkipEmptyQuestion());
    };

    const [checkedAnswer, setCheckedAnswer] = useState<number[]>([]);
    const [textInputValue, setTextInputValue] = useState(theQuestion?.AnswerWithOptions || "");
    const [singleValueId, setSingleValueId] = useState<{ label: string; value: number }>();

    useEffect(() => {
        if (profileEditMode && profileEditQuestion) {
            askTheQuestion(profileEditQuestion);
            setTextInputValue(profileEditQuestion.AnswerWithOptions || "");
        }

        if (!profileEditMode) {
            if (fetchedEmptyQuestions && currentEmptyQuestion) {
                askTheQuestion(currentEmptyQuestion);
                setTextInputValue(currentEmptyQuestion.AnswerWithOptions || "");
            } else {
                getEmptyQuestions();
            }
        }
    }, [
        fetchedEmptyQuestions,
        currentEmptyQuestion,
        profileEditQuestion,
        getEmptyQuestions,
        profileEditMode,
    ]);

    useEffect(() => {
        setCheckedAnswer(theQuestion?.SelectedOptions || []);

        const selectedOptions = theQuestion?.Options.find(
            x => x.Id === theQuestion.SelectedOptions[0]
        );

        setSingleValueId(
            selectedOptions
                ? { label: selectedOptions.Name, value: selectedOptions.Id }
                : { label: t("common:Seçiniz"), value: 0 }
        );
    }, [theQuestion, t]);

    if (!theQuestion) {
        return <div />;
    }

    const presentationProps = {
        t,
        closeModal,
        profileEditMode,
        checkedAnswer,
        setCheckedAnswer,
        textInputValue,
        setTextInputValue,
        singleValueId,
        setSingleValueId,
        theQuestion,
        skipTheQuestion,
        profilePhoto: self?.Photo.Url,
    };

    return <Presentation {...presentationProps} />;
};

export { QuickProfileFill };
