// #region Global Imports
import React from "react";
import ContentLoader from "react-content-loader";
// #endregion Global Imports

// #region Local Imports
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IProfileSkeleton } from "./ProfileSkeleton";
// #endregion Interface Imports

export const ProfileSkeleton: React.FC<IProfileSkeleton.IProps> = () => {
    const screenSize = useScreenSize();
    const isDesktop = screenSize.includes("large");

    if (isDesktop) {
        return (
            <ContentLoader
                speed={2}
                width={608}
                height={600}
                viewBox="0 0 608 600"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="87" cy="84" r="72" />
                <rect x="0" y="0" rx="21" ry="21" width="608" height="130" />
                <rect x="262" y="140" rx="0" ry="0" width="70" height="12" />
                <rect x="352" y="140" rx="0" ry="0" width="70" height="12" />
                <rect x="442" y="140" rx="0" ry="0" width="70" height="12" />
                <rect x="0" y="194" rx="12" ry="12" width="608" height="198" />
                <rect x="12" y="422" rx="0" ry="0" width="150" height="18" />
                <rect x="12" y="452" rx="0" ry="0" width="608" height="12" />
                <rect x="12" y="476" rx="0" ry="0" width="608" height="12" />
                <rect x="12" y="500" rx="0" ry="0" width="608" height="12" />
                <rect x="12" y="532" rx="0" ry="0" width="150" height="18" />
                <rect x="12" y="562" rx="0" ry="0" width="608" height="12" />
                <rect x="12" y="584" rx="0" ry="0" width="608" height="12" />
            </ContentLoader>
        );
    }

    return (
        <ContentLoader
            speed={2}
            width={320}
            height={900}
            viewBox="0 0 320 900"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <circle cx="160" cy="110" r="110" />
            <rect x="0" y="18" rx="21" ry="21" width="320" height="365" />
            <rect x="22" y="390" rx="0" ry="0" width="70" height="12" />
            <rect x="112" y="390" rx="0" ry="0" width="70" height="12" />
            <rect x="202" y="390" rx="0" ry="0" width="70" height="12" />
            <rect x="0" y="412" rx="12" ry="12" width="320" height="198" />
            <rect x="12" y="621" rx="0" ry="0" width="150" height="18" />
            <rect x="12" y="652" rx="0" ry="0" width="320" height="12" />
            <rect x="12" y="676" rx="0" ry="0" width="320" height="12" />
            <rect x="12" y="700" rx="0" ry="0" width="320" height="12" />
            <rect x="11" y="732" rx="0" ry="0" width="150" height="18" />
            <rect x="12" y="762" rx="0" ry="0" width="320" height="12" />
            <rect x="12" y="784" rx="0" ry="0" width="320" height="12" />
        </ContentLoader>
    );
};
