import { v4 as uuid } from "uuid";
import { AccountModel } from "@Interfaces";

export const AppleService = {
    request: async (): Promise<AccountModel.AppleRequestResponse> => {
        const defaultResponse: AccountModel.AppleRequestResponse = {
            authorization: {
                code: "",
                state: "",
            },
        };
        const options = {
            clientId: "com.workbench.siberalemservice",
            redirectURI: window.location.origin,
            scope: "email",
            state: uuid(),
            usePopup: true,
        };

        try {
            window.AppleID.auth.init(options);

            const response: AccountModel.AppleRequestResponse = await window.AppleID.auth.signIn();

            if (response.authorization) {
                const { code, state } = response.authorization;
                return { authorization: { code, state } };
            } else return defaultResponse;
        } catch (error) {
            console.log("AppleID.auth.signIn error", error);
            return defaultResponse;
        }
    },
};
