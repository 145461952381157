export default [
    {
        imageName: "images/man-laptop.png",
        headerText: "Siberalem'e Ücretsiz Üye Olun",
        contentText:
            "Türkiye’nin en büyük arkadaş bulma sitesi Siberalem’e ücretsiz üye olun, yakınınızdaki erkek ve kız arkadaşlar ile tanışma fırsatını kaçırmayın. Ücretsiz tanışma sitesi Siberalem ile aradığınız arkadaşı kolayca bulabilirsiniz. Arkadaşlık sitesi Siberalem üzerinden diğer arkadaş veya sevgili arayan bayan ve erkek arkadaşlar ile tanışın.",
    },
    {
        imageName: "images/woman-laptop.png",
        headerText: "Aradığınız Arkadaş Adayını Kolayca Bulun",
        contentText:
            "Siberalem’e ücretsiz üye olduktan sonra, aradığınız erkek veya bayan arkadaş kriterlerini girerek, size en uygun arkadaşı bulabilirsiniz. İstanbul’da arkadaş arayan bayanlar, Ankara’daki yeni üyeler, erkek arkadaş arayan bayanlar gibi filtreler kullanarak binlerce arkadaş adayı arasından size en uygun olan erkek veya bayan arkadaş adayı ile canlı sohbet edin ve tanışın.",
    },
    {
        imageName: "images/couple-meet.png",
        headerText: "Aradığınız Arkadaş Sizi Bekliyor",
        contentText:
            "Türkiye’nin en büyük arkadaşlık sitesi Siberalem’e kayıtlı olan milyonlarca erkek ve bayan arkadaş adayları arasından size en uygun arkadaşı hemen bulun. İlk mesajı siz atın ve o özel kişi ile arkadaşlığınızı başlatın. Hemen ücretsiz tanışma sitesi Siberalem’e üye olun ve canlı sohbet etmeye başlayın. Aradığınız arkadaş burada sizi bekliyor. Onunla tanışma fırsatını sakın kaçırmayın.",
    },
    {
        imageName: "images/couple-kiss.png",
        headerText: "Arkadaş Arayanlar İçin Tek Adres",
        contentText:
            "Arkadaş arayan, evlilik arayan veya aşk arayan milyonlarca arkadaş adayı Siberalem’de sizi bekliyor. Yalnızlıktan sıkıldıysanız, hemen ücretsiz arkadaş bulma sitesi Siberalem’e üye olun ve hayallerinizdeki arkadaş ile tanışın. İstanbul, Ankara, İzmir ve Türkiye’nin her yerinde bayan arkadaş veya erkek arkadaş arayan adaylar ile ücretsiz sohbet edin, hayallerinizdeki arkadaş ile hemen tanışın.",
    },
];
