// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { CampaignModel } from "@Interfaces";
// #endregion Interface Imports

export const CampaignService = {
    Active: async (payload: CampaignModel.ActivePayload): Promise<CampaignModel.ActiveResponse> => {
        return Http.Request("POST", "/campaign/active", undefined, {
            ...payload,
        });
    },
};
