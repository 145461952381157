import * as React from "react";

const SvgCheckShield = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 28" {...props}>
        <path
            d="M22.77 4.782c-.345-.281-.75-.45-1.153-.45h-.865c-.693 0-2.135-.056-3.462-.394-1.73-.393-3.692-2.306-4.27-2.7a1.982 1.982 0 00-.98-.281c-.346 0-.692.113-.98.281-.058.057-2.135 2.194-4.212 2.7-1.327.338-2.77.394-3.462.394h-.865a1.89 1.89 0 00-1.212.45C.963 5.12.732 5.57.732 6.02v4.218c0 15.582 10.443 17.607 10.904 17.663h.577c.462-.056 11.02-2.081 11.02-17.663V6.02c.115-.507-.058-.957-.462-1.238h0zM12.099 2.701s2.423 2.362 4.846 2.925c1.442.337 2.942.394 3.865.394h.866s-.058.506-.058 4.218c0 14.344-9.577 16.032-9.577 16.032S2.58 24.582 2.58 10.238V6.02h.865c.923 0 2.423-.057 3.923-.394 2.423-.563 4.73-2.925 4.73-2.925zm4.211 5.962l-5.942 6.975-1.961-2.137c-.404-.45-1.154-.506-1.674-.169-.519.338-.576.956-.173 1.406l3.866 4.219 7.73-9.112c.404-.45.289-1.07-.23-1.407-.52-.337-1.27-.225-1.616.225z"
            fill="#FFF"
            stroke="#fff"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCheckShield;
