import * as React from "react";

const SvgEnvelope = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm8 10a2 2 0 012 2v10a2 2 0 01-2 2H10a2 2 0 01-2-2V13a2 2 0 012-2h16zm0 1H10a1 1 0 00-.993.883L9 13v10a1 1 0 00.883.993L10 24h16a1 1 0 00.993-.883L27 23V13a1 1 0 00-1-1zm-.62 1.575a.5.5 0 01.008.64l-.063.065-4.278 3.667 4.632 4.078a.5.5 0 01-.661.75l-4.738-4.17-.002.001a3.5 3.5 0 01-4.396.13l-.16-.13-.261-.224-4.605 4.38a.5.5 0 01-.639.042l-.068-.06a.5.5 0 01-.041-.639l.06-.067 4.53-4.31-4.023-3.448a.5.5 0 01-.107-.632l.052-.073a.5.5 0 01.632-.107l.073.052 5.048 4.327a2.5 2.5 0 003.103.12l.151-.12 5.048-4.327a.5.5 0 01.705.055z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgEnvelope;
