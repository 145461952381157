import * as React from "react";

const SvgCommunication = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c3.416 0 6.438.788 9.066 2.365a17.518 17.518 0 016.569 6.57C35.212 11.561 36 14.583 36 18c0 3.416-.788 6.438-2.365 9.066a17.518 17.518 0 01-6.57 6.569C24.439 35.212 21.417 36 18 36c-3.416 0-6.438-.788-9.066-2.365a17.518 17.518 0 01-6.569-6.57C.788 24.439 0 21.417 0 18c0-3.416.788-6.438 2.365-9.066a17.518 17.518 0 016.57-6.569C11.508.893 14.313.109 17.346.011L18 0zm0 1.5c-3.026 0-5.791.721-8.321 2.167a16.051 16.051 0 00-6.028 6.04C2.22 12.093 1.5 14.847 1.5 18c0 3.152.719 5.906 2.167 8.321a16.051 16.051 0 006.04 6.028C12.093 33.78 14.847 34.5 18 34.5c3.152 0 5.906-.719 8.321-2.167a16.051 16.051 0 006.028-6.04C33.78 23.907 34.5 21.153 34.5 18c0-3.152-.719-5.906-2.167-8.321a16.051 16.051 0 00-6.04-6.028C23.907 2.22 21.153 1.5 18 1.5zm-7.847 19.745c.192 3.654 3.476 6.277 3.644 6.409l.007.005.099 2.17s-3.128.77-5.61-1.195c.721-1.905 1.52-4.515 1.86-7.389zm7.95-15.204c6.714 0 10.293 3.44 10.368 9.95 1.581 1.902 1.645 3.581 1.407 4.666-.376 1.72-1.783 3.244-3.86 4.184.346 1.388.759 2.642 1.151 3.69-2.154 1.552-5.384 1.314-5.562 1.3l-.008-.001.099-2.171s.944-.729 1.873-1.96a22.875 22.875 0 01-3.774.656 1.59 1.59 0 11-.134-1.123c1.224-.111 3.063-.375 4.836-.998.337-.653.611-1.38.753-2.166.072-2.252.332-4.41-.62-5.363-1.885-1.885-2.4-2.208-2.963-3.783-1.178 1.57-2.24 1.416-7.766 3.39-2.356.84-3.227 2.844-3.868 5.032-1.03-5.466-.422-9.56 2.125-11.585 2.03-1.614 10.86-2.444 12.912 1.658 1.103 2.206.977 7.276.319 10.162.097.734.222 1.449.367 2.138 1.636-.793 2.731-1.987 3.019-3.3.255-1.166-.126-2.413-1.101-3.623l-.19-.226-.018-.026a.537.537 0 01-.118-.312c0-.01-.003-.021-.003-.032 0-5.992-3.11-9.03-9.243-9.03-6.134 0-9.244 3.038-9.244 9.03a.522.522 0 01-.015.124l-.018.06v5.996C7.177 21.877 6 20.586 6 19.067c0-1.156.684-2.18 1.737-2.824 0-.015-.004-.03-.004-.045 0-6.645 3.586-10.157 10.37-10.157z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgCommunication;
