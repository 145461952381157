// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Blurry = styled.div<{ showStickyBlury: boolean }>`
    position: sticky;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 5%, #ffffff 100%);
    bottom: 0;
    height: ${({ showStickyBlury }) => (showStickyBlury ? "220px" : "0px")};
    transition: all 0.5s ease-in-out;
    pointer-events: none;
`;

export const ModalContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
    display: flex;
    background: ${({ theme }) => theme.colors.white};
    ${({ isMobile }) =>
        isMobile
            ? css`
                  width: 100%;
              `
            : css`
                  height: 598px;
              `};
    flex-direction: ${({ isMobile }) => (isMobile ? "column-reverse" : "row")};
    transition: all 0.5s linear;
`;
export const ChatContent = styled.div<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "unset" : "480px")};
    position: relative;
    display: flex;
    flex-direction: column;
    ${({ isMobile }) =>
        isMobile &&
        css`
            display: flex;
            flex-direction: column;
            height: 100%;
        `};
`;
export const ProfileContent = styled.div<{ isMobile: boolean }>`
    display: flex;
    position: relative;
    height: inherit;
    ${({ isMobile }) =>
        isMobile &&
        css`
            position: absolute;
            top: 0;
            width: 100%;
        `};
`;

export const StyledCloseIcon = styled.div`
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${({ theme }) => theme.colors.white};

    svg {
        :hover {
            color: ${({ theme }) => theme.colors.red};
        }
    }
`;
