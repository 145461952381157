// #region Global Imports
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { FormBox } from "@Components";
import { MetaDataActions, ProfileActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { ILocation } from "./Location";
import { IStore, ISearchPage } from "@Interfaces";
import { Presentation } from "./Location.presentation";
// #endregion Interface Imports

export const Location: React.FunctionComponent<ILocation.IProps> = ({
    t,
    self,
    onCloseClick,
    isMobile,
}) => {
    const dispatch = useDispatch();
    const { useCallback, useEffect } = React;

    const fetchCountries = useCallback(async () => {
        dispatch(await MetaDataActions.FetchCountries());
    }, [dispatch]);

    const fetchCities = useCallback(
        async (countryId: number) => {
            dispatch(await MetaDataActions.FetchCities(countryId, false));
        },
        [dispatch]
    );

    const fetchTowns = useCallback(
        async (cityId: number, keyword?: string) => {
            dispatch(await MetaDataActions.FetchTowns(cityId, false, keyword));
        },
        [dispatch]
    );

    const countries = useSelector((state: IStore) => state.metadata.countries);
    const cities = useSelector((state: IStore) => state.metadata.cities);
    const towns = useSelector((state: IStore) => state.metadata.towns);

    const defaultLocation = {
        countryId: parseInt(self.CountryId, 10),
        countryName: self.CountryName,
        cityId: parseInt(self.CityId, 10),
        cityName: self.CityName,
        townId: parseInt(self.TownId, 10),
        townName: self.TownName,
    };

    useEffect(() => {
        if (!countries.length) fetchCountries();
    }, []);

    const saveLocation = async (location: ISearchPage.SelectedLocation) => {
        const { countryId, cityId, townId } = location;
        dispatch(await ProfileActions.UpdateProfileInfo({ countryId, cityId, townId }));
    };

    return (
        <FormBox
            title={t(`common:profileEdit.infoModal.location.title`)}
            onHandleSubmit={() => console.log("submit")}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">
                <Presentation
                    t={t}
                    countries={countries}
                    cities={cities}
                    towns={towns}
                    fetchCities={fetchCities}
                    fetchTowns={fetchTowns}
                    defaultLocation={defaultLocation}
                    saveLocation={saveLocation}
                    isMobile={isMobile}
                />
            </div>
        </FormBox>
    );
};
