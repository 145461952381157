// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { ISection } from "./Section";
// #endregion Local Imports

export const Container = styled.section<ISection.IProps>`
    background-image: url("${({ bgImage }) => bgImage}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;

    ${({ customStyle }) => customStyle && customStyle}

    @media screen and (max-width:${({ theme }) => theme.maxWidth.medium}){
        background-position: 60%;
        background-attachment: local;
    }

    ${({ theme, cropped }) => {
        return (
            cropped &&
            `@media screen and (max-width:${theme.maxWidth.small}){
                background-image: url("${cropped}");
                

            @media screen and (max-width:${theme.maxWidth.xSmall}){
                background-position: 50% 20%;
            `
        );
    }}
`;

export const InnerContainer = styled.div<{ centered: boolean }>`
    display: flex;
    ${({ centered }) =>
        centered
            ? `
              justify-content: center;
              align-items: center;`
            : ``}

    @media screen and (max-width:${({ theme }) => theme.maxWidth.small}){
        justify-content: center;
        align-items: flex-end;
    }
`;
