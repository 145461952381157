import styled from "styled-components";
import { ITextInput } from "./TextInput";

export const Container = styled.div`
    line-height: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const InputContainer = styled.div<{ width?: string; height?: string }>`
    height: ${({ height }) => height || "50px"};
    ${({ width }) => (width ? `width: ${width};` : "")}
`;

export const TextArea = styled.textarea`
    height: 130px;
    border-radius: 10px;
    padding-top: 20px;
    padding-left: 15px;
    resize: none;
    border: 2px solid #acb5c3;
    box-shadow: inset 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
    font-family: "Montserrat";
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    outline: none;
    line-height: 1;
`;

export const InputInnerContainer = styled.div<{ isValid?: boolean; disabled?: boolean }>`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    line-height: 14px;
    border: 2px solid
        ${({ theme, isValid, disabled }) => {
            if (disabled) {
                return theme.colors.disabled;
            }

            if (isValid !== undefined && isValid !== null) {
                return isValid ? theme.colors.green : theme.colors.red;
            }

            return theme.colors.disabled;
        }};
    border-radius: 25px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
`;

export const Input = styled.input<ITextInput.IProps>`
    font-family: "Montserrat";
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: -0.26px;
    padding-left: 15px;
    padding-top: 2px;
    width: 90%;
    height: 85%;
    border-radius: 25px;
    border: none;

    ::placeholder {
        color: ${({ theme, disabled }) => (disabled ? theme.colors.white : "")};
    }

    :focus {
        outline: unset;
        border-color: ${({ theme, isValid }) => {
            if (isValid !== undefined) {
                return isValid ? theme.colors.green : theme.colors.red;
            }

            return theme.colors.blue;
        }};
    }
`;

export const Icon = styled.div<{ isValid?: boolean; disabled?: boolean;}>`
    padding-right: 5px;
    
        color: ${({ theme, isValid, disabled }) => {
            if (disabled) {
                return theme.colors.white;
            }
    
            if (isValid === true) {
                return theme.colors.green;
            }
    
            if (isValid === false) {
                return theme.colors.red;
            }
    
            return theme.colors.gray;
        }};

`;

export const ErrorText = styled.div`
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.red};
    margin-left: 20px;
    margin-top: 10px;
`;

Input.displayName = "Input";
TextArea.displayName = "TextArea";
InputInnerContainer.displayName = "InputInnerContainer";
