// #region Global Imports
import React, { useState, useEffect, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { Badge, IconButton, Title } from "@Components/Basic";
import { theme } from "@Definitions/Styled";
import { IStore } from "@Interfaces";
import { HotornotGame, HotornotMaleLiked } from "@Icons";
import { Container, ContentBox, Header, HeaderIcons, BadgeIcon, HeaderIcon } from "./styled";
import { IHotOrNot } from "./HotOrNot";
import {
    Game,
    GameSkeleton,
    NewMatch,
    Banner,
    GoldMembership,
    Credits,
    UserItemList,
} from "./Components";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const HotOrNot: FC<IHotOrNot.IProps> = ({
    t,
    updateList,
    reduceList,
    like,
    dislike,
    fetchLikesToMe,
}): JSX.Element => {
    const [currentTab, setCurrentTab] = useState("game");
    const [notification, setNotification] = useState("");

    const hotOrNot = useSelector(({ dashboard }: IStore) => dashboard.hotOrNot);
    const { list, loading } = hotOrNot;
    const listLength = list.length;
    const { notificationCounts } = useSelector(({ dashboard }: IStore) => dashboard);

    const txt = (key: string) => t(`dashboard:HotOrNot.${key}`);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!listLength) {
            dispatch({ type: ActionConsts.Dashboard.HotOrNotListLoading });
            updateList();
        }
    }, []);

    const renderContent = (): JSX.Element => {
        switch (currentTab) {
            case "game":
                if (listLength === 3) updateList();

                if (!loading) {
                    return (
                        <Game
                            reduceList={reduceList}
                            like={like}
                            dislike={dislike}
                            showMatched={() => setNotification("newMatch")}
                            showNoQuota={() => setNotification("goldMember")}
                        />
                    );
                }

                return <GameSkeleton />;

            case "likes":
                return <UserItemList t={t} type="like" fetchLikesToMe={fetchLikesToMe} />;

            case "matches":
                // return <UserItemList t={t} type="match" />;
                return <ContentBox />;

            default:
                return <ContentBox />;
        }
    };

    const renderNotification = (): JSX.Element => {
        switch (notification) {
            case "newMatch":
                return (
                    <NewMatch
                        onContinueClick={() => console.log("continue")}
                        onStartChatClick={() => console.log("start chat")}
                        t={t}
                    />
                );

            case "banner":
                return (
                    <Banner
                        t={t}
                        src="https://theawesomer.com/photos/2017/08/mastodon_sultans_curse_t-300x250.jpg"
                        onSkipClick={() => console.log("skip")}
                    />
                );

            case "goldMember":
                return (
                    <GoldMembership
                        onPurchaseClick={async () => {
                            await Router.push(
                                `/payments/[type]/credit-card?source=hotornot`,
                                `/payments/gold/credit-card`
                            );
                        }}
                        t={t}
                    />
                );

            case "buyCredits":
                return (
                    <Credits
                        type="buy"
                        onButtonClick={async () => {
                            await Router.push(
                                `/payments/[type]/credit-card?source=hotornot`,
                                `/payments/coin/credit-card`
                            );
                        }}
                        t={t}
                    />
                );

            case "spendCredits":
                return (
                    <Credits
                        type="spend"
                        onButtonClick={() => console.log("spend credits")}
                        t={t}
                    />
                );

            default:
                return <ContentBox />;
        }
    };

    return (
        <Container>
            <Header>
                <Title fontWeight="bold" size="large">
                    {txt("title")}
                </Title>

                <HeaderIcons>
                    <div className="mr-3">
                        <IconButton
                            onIconClick={() => setCurrentTab("game")}
                            renderIcon={() => (
                                <HeaderIcon>
                                    {currentTab === "game" ? (
                                        <HotornotGame
                                            className="active"
                                            width="37px"
                                            height="44px"
                                        />
                                    ) : (
                                        <HotornotGame width="37px" height="44px" />
                                    )}
                                </HeaderIcon>
                            )}
                        />
                    </div>

                    <Badge
                        color={theme.colors.badgeGreen}
                        hoverColor={theme.colors.badgeHoverGreen}
                        count={notificationCounts.NewLikeCount}
                        customStyle={BadgeIcon}
                    >
                        <IconButton
                            onIconClick={() => setCurrentTab("likes")}
                            renderIcon={() => (
                                <HeaderIcon>
                                    {currentTab === "likes" ? (
                                        <HotornotMaleLiked
                                            className="active"
                                            width="37px"
                                            height="44px"
                                        />
                                    ) : (
                                        <HotornotMaleLiked width="37px" height="44px" />
                                    )}
                                </HeaderIcon>
                            )}
                        />
                    </Badge>

                    {/* <div>
                        <IconButton
                            onIconClick={() => setCurrentTab("matches")}
                            StyledIcon={HeaderIcon}
                            iconName={
                                currentTab === "matches"
                                    ? "hotornot-match active"
                                    : "hotornot-match"
                            }
                        />
                    </div> */}
                </HeaderIcons>
            </Header>
            {notification ? renderNotification() : renderContent()}
        </Container>
    );
};
