// #region Local Imports
import { Http } from "@Services";
import { PusherModel } from "./Pusher";
// #endregion Local Imports

export const PusherService = {
    GetChannel: async (): Promise<PusherModel.IFetchChannel> => {
        return Http.Request("POST", "/pusher/channel");
    },
};
