// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { BlockButton } from "@Components/BlockButton";
// #endregion Local Imports

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    font-family: Montserrat;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 1108px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 930px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 690px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 510px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 342px;
    }
`;

export const ContainerTitle = styled(FlexCenter)`
    font-weight: 400;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.red};
`;

export const Top = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TopTitle = styled(FlexCenter)`
    span {
        font-weight: 600;
        font-size: 60px;
        letter-spacing: -2px;
        text-align: center;
        line-height: 73px;
        margin-bottom: 24px;

        @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
            font-size: 48px;
            letter-spacing: -1.6px;
            line-height: 54px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            font-weight: 700;
            font-size: 36px;
            letter-spacing: 0;
            line-height: 42px;
            margin-bottom: 12px;
        }
    }
`;

export const TopContent = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 540px 540px;
    grid-column-gap: 30px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        grid-template-columns: 290px 610px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        grid-template-columns: 210px 420px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        grid-template-columns: 150px 330px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        grid-template-columns: 156px 156px;
    }
`;

export const TCLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        width: 540px;
        height: 310px;
        box-sizing: border-box;

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            width: 290px;
            height: 193px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: 210px;
            height: 140px;
            border-radius: 10px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
            width: 150px;
            height: 100px;
            border-radius: 10px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            width: 150px;
            height: 110px;
            border-radius: 10px;
        }
    }
`;

export const TCRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 10px;

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            font-size: 12px;
            line-height: 15px;
        }
    }

    #p1 {
        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            display: none;
        }
    }

    #p2 {
        @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
            margin-bottom: 0;
        }
        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            display: none;
        }
    }

    #p-short {
        display: none;

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            display: block;
            margin: 0;
        }
    }
`;

export const TCLButton = styled(BlockButton)`
    display: none;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: flex;
        padding: 0;
        width: 150px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: none;
    }
`;

export const TCRButton = styled(BlockButton)`
    width: 160px;
    padding: 0;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: none;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: none;
    }
`;

export const TCRLink = styled.a`
    font-weight: 500;
    font-size: 11px;
    margin: 0;
    cursor: pointer;
    display: none;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: block;
    }
`;

export const TCRText = styled.div`
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const Bottom = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 540px 540px;
    grid-template-rows: 255px;
    grid-column-gap: 30px;
    margin-top: 50px;
    padding-top: 50px;
    border-top: 2px solid white;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        grid-template-columns: 450px 450px;
        grid-template-rows: 210px;
        margin-top: 30px;
        padding-top: 30px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        grid-template-columns: 690px;
        grid-template-rows: auto;
        place-items: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        grid-template-columns: 510px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        grid-template-columns: 342px;
        margin-top: 20px;
        padding-top: 15px;
    }
`;

export const BottomArticle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 255px 255px;
    grid-column-gap: 30px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        grid-template-columns: 210px 210px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        grid-template-columns: 150px 510px;
        margin-bottom: 50px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        grid-template-columns: 150px 320px;
        margin-bottom: 30px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        grid-template-columns: 156px 156px;
        margin-bottom: 5px;
    }
`;

export const BAImage = styled.div`
    img {
        box-sizing: border-box;
        width: 255px;
        height: 255px;

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            width: 210px;
            height: 210px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: 150px;
            height: 150px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
            width: 150px;
            height: 150px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            width: 156px;
            height: 156px;
        }
    }
`;

export const BATitle = styled.div`
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        font-size: 18px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 14px;
        line-height: 16px;
    }
`;

export const BAText = styled.div`
    font-size: 14px;
    line-height: 18px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 12px;
        line-height: 14px;
    }
`;

export const BottomNews = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 450px;
        height: 210px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 510px;
        height: 240px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 342px;
        height: 170px;
    }
`;

export const BNCouple = styled.div`
    display: flex;

    img {
        width: 255px;

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            width: 210px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: 210px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
            width: 240px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            width: 156px;
        }
    }

    div:first-child {
        padding-right: 15px;
    }

    div:nth-child(2) {
        padding-left: 15px;
    }
`;

export const BNButton = styled.div`
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: flex;
        justify-content: center;

        div {
            width: 330px;
            padding: 0;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        div {
            width: 100%;
        }
    }
`;

ContainerTitle.displayName = "ContainerTitle";
TopTitle.displayName = "TopTitle";
BlockButton.displayName = "BlockButton";
Top.displayName = "Top";
Bottom.displayName = "Bottom";
