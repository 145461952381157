// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ isActive: boolean }>`
    cursor: pointer;
    margin-right: 20px;

    img {
        opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
    }
`;
