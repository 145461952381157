// #region Global Imports
import styled from "styled-components";
import { Anchor } from "@Components/Basic/Anchor";
import { scaleHeart, scaleIcon, scaleGrow, scaleShrink, fadeOut } from "./keyframes";
// #endregion Global Imports

export const Tint = styled.div`
    width: 100%;
    height: 227px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 99%);
`;

export const ImageControls = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ImageText = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 18px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: -0.3px;
    margin-left: 14px;
`;

export const ImageButtons = styled.div`
    display: flex;
`;

export const ImageButton = styled(Anchor)`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    place-items: center;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        color: white;
    }
`;

export const LikeButton = styled(ImageButton)`
    background-color: ${({ theme }) => theme.colors.red};

    i {
        padding-top: 3px;
        font-size: 17px;
    }
`;

export const DislikeButton = styled(ImageButton)`
    background-color: ${({ theme }) => theme.colors.gray};

    i {
        padding-top: 2px;
        font-size: 12px;
    }
`;

export const IconWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        animation: ${scaleIcon} 0.2s ease 0s 1;
        position: absolute;
        opacity: 1;
    }

    img {
        animation: ${scaleHeart} 0.2s ease 0s 1;
        position: absolute;
        opacity: 1;
        width: 84px;
        height: 76px;
    }
`;

export const AnimatedEffectWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
`;

const GrowEffect = styled.div`
    width: 40px;
    height: 40px;
    transform: scale(30);
    border-radius: 50%;
    opacity: 0.7;
    position: absolute;
    animation: ${scaleGrow} 0.2s cubic-bezier(0.75, 0.2, 0.83, 0.67) 0s 1;
    right: 56px;
    bottom: 0;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        right: 118px;
        bottom: 50px;
    }
`;

export const DislikeGrow = styled(GrowEffect)`
    background-color: ${({ theme }) => theme.colors.gray};
`;

export const LikeGrow = styled(GrowEffect)`
    background-color: ${({ theme }) => theme.colors.badgeGreen};
`;

const ShrinkEffect = styled.div`
    width: 40px;
    height: 40px;
    transform: scale(0);
    border-radius: 50%;
    position: absolute;
    animation: ${scaleShrink} 0.3s cubic-bezier(0.75, 0.2, 0.83, 0.67) 0s 1;
    opacity: 0.7;
    display: grid;
    place-items: center;

    i {
        animation: ${fadeOut} 0.3s ease 0s 1;
        color: white;
        opacity: 0.01;
    }
`;

export const LikeShrink = styled(ShrinkEffect)`
    background-color: ${({ theme }) => theme.colors.red};
    right: 8px;
    bottom: 6px;

    i {
        padding-top: 3px;
        font-size: 17px;
    }
`;

export const DislikeShrink = styled(ShrinkEffect)`
    background-color: ${({ theme }) => theme.colors.gray};
    right: 56px;
    bottom: 6px;

    i {
        padding-top: 2px;
        font-size: 12px;
    }
`;

ImageControls.displayName = "ImageControls";
LikeButton.displayName = "LikeButton";
DislikeButton.displayName = "DislikeButton";
AnimatedEffectWrapper.displayName = "AnimatedEffectWrapper";
LikeGrow.displayName = "LikeGrow";
DislikeGrow.displayName = "DislikeGrow";
