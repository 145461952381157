// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: Montserrat;
    width: 100%;
    margin: 0;
    padding: 0;
`;

export const CardContent = styled.div`
    display: flex;
    padding: 15px 6px;
`;

export const CardTextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CardText = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 20px;
    padding-left: 5px;
    padding-right: 25px;
    margin-bottom: 15px;
`;

export const ButtonText = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.facebook};
    letter-spacing: -0.3px;
    text-align: center;
    padding: 8px 3px;
`;

export const Percent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    position: absolute;
`;

export const Tint = styled.div<{ percent: string }>`
    background: conic-gradient(
        rgba(74, 74, 74, 0) ${({ percent }) => percent}%,
        rgba(74, 74, 74, 0.3) 0
    );
    position: absolute;
`;
