// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ApiStatus } from "@Interfaces/enums";
import { ActionConsts, noTry } from "@Definitions";
import {
    SearchService,
    NotificationService,
    InteractionService,
    PutMeUpHereService,
} from "@Services";
import { IStore, PutMeUpHereModel } from "@Interfaces";
// #endregion Local Imports

/**
 * ACTIONS
 */
export const DashboardActions = {
    Reset: () => ({
        type: ActionConsts.Dashboard.ResetReducer,
    }),
    GetHotOrNotList: async (listSize: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const currentList = getState().dashboard.hotOrNot.list;

        if (currentList.length < listSize) {
            const excludedUserIds = currentList.map(user => user.Id).join(",");

            await SearchService.GetHotOrNotList(listSize, excludedUserIds)
                .then(newList => {
                    dispatch({
                        payload: {
                            hotOrNot: {
                                list: [...currentList, ...newList.Users],
                                loading: false,
                            },
                        },
                        type: ActionConsts.Dashboard.GetHotOrNotList,
                    });
                })
                .catch(e => {
                    console.log("Error GetHotOrNotList", e);
                });
        }
    },
    ReduceHotOrNotList: () => (dispatch: Dispatch, getState: () => IStore) => {
        const { list } = getState().dashboard.hotOrNot;

        dispatch({
            payload: {
                hotOrNot: { list: list.slice(1, list.length) },
                dislikeResponse: undefined,
                likeStatus: { success: undefined },
            },
            type: ActionConsts.Dashboard.ReduceHotOrNotList,
        });
    },
    Like: async (likedUserId: number) => async (dispatch: Dispatch) => {
        const response = await InteractionService.Like(likedUserId);

        const likeStatus = {
            likedUserId,
            reason: response.Reason,
            isMatch: response.IsMatch,
            success: response.Success,
        };

        dispatch({
            payload: { likeStatus },
            type: ActionConsts.Dashboard.Like,
        });
    },
    Dislike: async (dislikedUserId: number) => async (dispatch: Dispatch) => {
        const response = await SearchService.Dislike(dislikedUserId);

        dispatch({
            payload: {
                dislikeResponse: response.Success,
            },
            type: ActionConsts.Dashboard.Dislike,
        });
    },
    GetNotification: async () => async (dispatch: Dispatch) => {
        const result = await NotificationService.GetNotificationCount();

        dispatch({
            payload: result,
            type: ActionConsts.Notification.GetNotificationCounts,
        });
    },
    FetchLikesToMe: async (getMore: boolean) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const { currentPage, list } = getState().dashboard.likesToMe;

        const page = getMore ? currentPage + 1 : 0;
        const type = getMore
            ? ActionConsts.Dashboard.AddLikesToMe
            : ActionConsts.Dashboard.FetchLikesToMe;

        dispatch({
            payload: {
                likesToMe: { loading: true },
            },
            type: ActionConsts.Dashboard.SetLikesToMeLoading,
        });

        await InteractionService.FetchLikesToMe(page, 20)
            .then(result => {
                const newList = getMore ? [...list, ...result.Interactions] : result.Interactions;
                dispatch({
                    payload: {
                        likesToMe: {
                            list: newList,
                            fetchedAll: result.Interactions.length === 0,
                            currentPage: page,
                            loading: false,
                        },
                    },
                    type,
                });
            })
            .catch(e => {
                console.log("FetchLikesToMe error: ", e);
            });
    },
    SetVisibility: async (id: string) => async (dispatch: Dispatch, getState: () => IStore) => {
        const { likesToMe } = getState().dashboard;
        const newList = likesToMe.list.map(item => {
            if (item.Profile.Id !== id) {
                return item;
            }
            return {
                ...item,
                IsHidden: false,
            };
        });
        dispatch({
            payload: {
                likesToMe: {
                    ...likesToMe,
                    list: newList,
                },
            },
            type: ActionConsts.Dashboard.SetVisibility,
        });
    },
    GetPutMeUpHere: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const putMeUpHere = await PutMeUpHereService.Get();

        const { isPurchaseRevealed } = getState().dashboard.putMeUpHere;

        dispatch({
            payload: {
                putMeUpHere: {
                    ...putMeUpHere,
                    isPurchaseRevealed,
                },
            },
            type: ActionConsts.PutMeUpHere.GetPutMeUpHere,
        });
    },
    AddPutMeUpHere: async (data: PutMeUpHereModel.IFetchedProfile) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const currentState = getState().dashboard.putMeUpHere;

        // ? Entry duplication may occur if response contains an already received entry.
        dispatch({
            payload: {
                putMeUpHere: {
                    ...currentState,
                    Result: [data, ...currentState.Result],
                },
            },
            type: ActionConsts.PutMeUpHere.GetPutMeUpHere,
        });
    },
    SetPurchaseRevealed: (isPurchaseRevealed: boolean) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const currentState = getState().dashboard.putMeUpHere;

        dispatch({
            payload: {
                putMeUpHere: {
                    ...currentState,
                    isPurchaseRevealed,
                },
            },
            type: ActionConsts.PutMeUpHere.SetPurchaseRevealed,
        });
    },
    GetRecentPhotos: async (page = 0) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        dispatch({
            payload: {
                recentPhotos: {
                    status: ApiStatus.loading,
                },
            },
            type: ActionConsts.Dashboard.GetRecentPhotos.Loading,
        });

        const existingPhotos = getState().dashboard.recentPhotos.photos || [];

        const [result] = await noTry(() => SearchService.RecentPhotos(page));

        if (result) {
            dispatch({
                payload: {
                    recentPhotos: {
                        photos: [...existingPhotos, ...result.Users],
                        status: ApiStatus.loaded,
                        total: result.Total,
                        page,
                    },
                },
                type: ActionConsts.Dashboard.GetRecentPhotos.Success,
            });
        } else {
            dispatch({
                payload: {
                    recentPhotos: {
                        status: ApiStatus.failed,
                    },
                },
                type: ActionConsts.Dashboard.GetRecentPhotos.Failure,
            });
        }
    },

    ResetRecentPhotos: () => ({
        type: ActionConsts.Dashboard.ResetRecentPhotos,
    }),
};
