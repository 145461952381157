// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Anchor, Text } from "@Components/Basic";
import { Edit } from "@Icons";
import { theme } from "@Definitions";
import { AccountSettingsContent, Title, Item, ItemText } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IAccountSettings } from "./AccountSettings";
// #endregion Interface Imports

export const AccountSettings: React.FunctionComponent<IAccountSettings.IProps> = ({
    t,
    onClick,
    showChangePassword,
}) => {
    return (
        <AccountSettingsContent onClick={() => onClick}>
            <Title>{t("common:settings.account.title")}</Title>
            <Item
                className={`d-flex py-2 flex-wrap no-gutters ${
                    showChangePassword && `justify-content-between`
                }`}
            >
                {showChangePassword && (
                    <ItemText className="py-2">
                        <Anchor onClick={() => onClick("resetPassword")}>
                            <Text size="xLarge" color="darkGray">
                                {t("common:settings.account.resetPassword")}
                                <Edit
                                    width="19px"
                                    height="22px"
                                    color={theme.colors.facebook}
                                    className="pl-2 align-middle"
                                />
                            </Text>
                        </Anchor>
                    </ItemText>
                )}
                <ItemText className="py-2">
                    <Anchor onClick={() => onClick("resetMail")}>
                        <Text size="xLarge" color="darkGray">
                            {t("common:settings.account.resetMail")}
                            <Edit
                                width="19px"
                                height="22px"
                                color={theme.colors.facebook}
                                className="pl-2 align-middle"
                            />
                        </Text>
                    </Anchor>
                </ItemText>
                <ItemText className="py-2 px-1">
                    <Anchor onClick={() => onClick("leaveFromMembership")}>
                        <Text size="xLarge" color="darkGray" className="red">
                            {t("common:settings.account.leaveFromMembership")}
                        </Text>
                    </Anchor>
                </ItemText>
            </Item>
        </AccountSettingsContent>
    );
};
