import { CampaignModel, OfferModel } from "@Interfaces";

export const CampaignSerializer = {
    OfferToCampaign: (data: OfferModel.OfferResponse): CampaignModel.Campaign => {
        return {
            Id: data.CampaignId,
            Name: data.CampaignName,
            InternalName: data.InternalName,
            Discount: data.Discount,
            FreeTimeDurationUnit: data.DurationUnit,
            FreeTimeDuration: data.Duration,
            ProductId: data.CampaignId,
            ValidPaymentMethods: data.PaymentMethods,
            IsDefault: true,
            Installment: data.Installment,
            ProductPrice: data.ProductPrice,
            ProductDurationUnit: data.DurationUnit,
            ProductDuration: data.Duration,
            DiscountedPrice: data.Price,
            Coin: data.Coin,
            isOffer: true,
        };
    },
};
