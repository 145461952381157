// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
    height: 370px;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: unset;
    }
`;
