// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    font-family: "Montserrat";
    flex-direction: column;
    margin-top: 10px;
    perspective: 1000px;
`;

export const TitleContent = styled.div`
    display: flex;
    cursor: pointer;
    margin: 12px 12px 0px 12px;
    justify-content: space-between;
    border-bottom: 2px solid #dfe3ea;
`;

export const Arrow = styled.i<{ isCollapse: boolean }>`
    display: flex;
    cursor: pointer;
    align-items: center;
    width: auto;
    margin-right: 5px;
    height: auto;
    color: ${({ theme }) => theme.colors.gray};
    transform: ${({ isCollapse }) => (isCollapse ? "rotate(-90deg)" : "rotate3d(90,90,1,180deg)")};
    transition: transform 0.4s 0.2s ease-in-out;
`;

export const CollapseContent = styled.div<{ collapse: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: ${({ collapse }) => (collapse ? "2000px" : "0px")};
    opacity: ${({ collapse }) => (collapse ? 1 : 0)};
    transition: all 0.5s linear;
`;
