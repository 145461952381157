// #region Global Imports
import getConfig from "next/config";
// #endregion Global Imports

export type IRuntime = "public" | "server";

export const getEnvSafely = (envKey: string, runtime: IRuntime = "public") => {
    try {
        if (runtime === "public") {
            return getConfig().publicRuntimeConfig[envKey];
        }
        return getConfig().serverRuntimeConfig[envKey];
    } catch (error) {
        // Fallbacks to STATIC_PATH in case method called in storybook, for now.
        return "";
    }
};
