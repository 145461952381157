// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Title } from "@Components/Basic";
import { Image } from "./styled";
import { phoneNumber } from "./data";
// #endregion Local Imports

// #region Interface Imports
import { ICallCenter } from "./CallCenter";
// #endregion Interface Imports

export const CallCenter: React.FunctionComponent<ICallCenter.IProps> = ({ t, type }) => {
    const tPrefix = "common:payment.paymentTypes.callCenter";
    return (
        <div className="d-flex flex-column align-items-center">
            <div className="text-center px-4 px-md-6 px-lg-8 mb-6">
                <Title size="large" fontWeight="bold" className="mb-4">
                    {t(`${tPrefix}.header`)}
                </Title>
                <Title
                    size="large"
                    color="black"
                    dangerouslySetInnerHTML={{
                        __html: t(`${tPrefix}.text.${type}`, { phoneNumber }),
                    }}
                />
            </div>
            <Image alt="call-center" src="/images/call-center-illustration.png" />
        </div>
    );
};
