// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

// region Local Imports
import { Modal } from "@Components";
// #endregion Local Imports

const mobileHeight = css`
    @media (max-height: ${({ theme }) => theme.heights.photoUploadModal}) {
        display: block;
        overflow: scroll;
    }
`;

export const PhotoModal = styled(Modal)<{ height: number }>`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}),
        (max-height: ${({ theme }) => theme.maxWidth.medium}) {
        height: 100%;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    ${({ height, theme }) =>
        height <= parseInt(theme.heights.photoUploadModal.slice(0, -2), 10) && mobileHeight}
`;

export const MainContainer = styled.div`
    width: 900px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: ${({ theme }) => theme.heights.photoUploadModal};
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        .no-gutters.row {
            height: calc(100vh - 117px);
            flex-wrap: unset;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`;

export const customFormBoxStyle = css`
    width: 100%;
    padding: 0;
`;

export const PhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;

    .dropzone {
        margin-top: 10px;
        margin-bottom: 10px;
        img {
            width: 248px;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        .dropzone {
            &-title {
                display: none;
            }
        }
    }
`;

export const TextContainer = styled.div`
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 75%;
    }
`;

export const LargeText = styled.span`
    font-size: 24px;
    color: #fff;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-bottom: 17px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        font-size: 22px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-bottom: 5px;
        font-size: 16px;
    }
`;

export const TextCard = styled.span`
    font-size: 14px;
    color: #fff;
    padding: 0 7px 45px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-left: 0;
        padding-bottom: 10px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        font-size: 12px;
    }
`;

export const TextFooter = styled.span`
    font-size: 11px;
    color: #fff;
`;

export const customAvatarEmptyView = css`
    width: 251px;
    height: 251px;
    border-radius: 5px;
    border: 1px dashed gray;
    opacity: 0.5;
`;

export const SideCardUp = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.gradients.redSpectrum};
    align-items: center;
    padding-top: 82px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: row;
        padding-top: 10px;
        padding-left: 20px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const SideCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: column-reverse;
    }
`;

export const SideCardText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        text-align: left;
        padding-left: 15px;
    }
`;

export const SideCardFooter = styled.div`
    background-color: ${({ theme }) => theme.colors.buttercup};
    padding: 8px;
    text-align: center;
    border-bottom-right-radius: 30px;
    line-height: 1.2;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding: 7px;
        border-bottom-right-radius: 0;
    }
`;

export const ProgressBarContainer = styled.div`
    width: 100%;
    line-height: 0;
    padding-left: 30px;
    position: absolute;
    bottom: 0;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-left: 0;
    }
`;

export const DropzoneContainer = styled.div`
    margin: 16px 0;
`;

export const UploadPhotoSliderContainer = styled.div`
    width: 100%;
`;
export const SliderWrapper = styled.div`
    width: 100%;
`;

export const ButtonsContainer = styled.div``;

export const customBlockButtonCss = css`
    box-shadow: none;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    span {
        font-weight: 500;
        text-transform: uppercase;

        color: ${({ theme }) => theme.colors.lightGray};
    }
    :hover {
        color: ${({ theme }) => theme.colors.sliderDarkBlue};
        border-color: ${({ theme }) => theme.colors.sliderDarkBlue};

        span {
            color: ${({ theme }) => theme.colors.sliderDarkBlue};
        }
    }
`;

export const customContainerStyle = css`
    width: 100%;
`;
export const LoadingContainer = styled.div`
    height: 100%;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: 550px;
    }
`;
