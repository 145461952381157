import * as React from "react";

const SvgCheck = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 10 8" {...props}>
        <path
            d="M9.243 1.04a1.5 1.5 0 010 2.12L5 7.404c-.256.257-.582.4-.917.433l-.144.007c-.384 0-.767-.147-1.06-.44L.757 5.283A1.5 1.5 0 012.88 3.16l1.06 1.06L7.121 1.04a1.5 1.5 0 012.122 0z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCheck;
