// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Title } from "@Components/Basic";
import { Container, TitleContent } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IProfileWarning } from "./ProfileWarning";
// #endregion Interface Imports

export const ProfileWarning: React.FunctionComponent<IProfileWarning.IProps> = ({
    t,
    visitProfile,
}) => {
    const title =
        visitProfile === "disabled"
            ? t("common:profileWarnings.disabled.title")
            : t("common:profileWarnings.inaccessible.title");

    const disabledContent = (
        <>
            <Text weight="bold">{t("common:profileWarnings.disabled.whatIsDisabled")}</Text>
            <Text className="my-4">{t("common:profileWarnings.disabled.resultsIntro")}</Text>
            <ul>
                <li>
                    <Text>{t("common:profileWarnings.disabled.results.first")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.disabled.results.second")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.disabled.results.third")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.disabled.results.fourth")}</Text>
                </li>
            </ul>
            <Text weight="bold">{t("common:profileWarnings.disabled.disabledProfile")}</Text>
            <Text
                dangerouslySetInnerHTML={{
                    __html: t("common:profileWarnings.activateProfile", {
                        settingsLink: "/settings",
                    }),
                }}
            />
        </>
    );

    const inaccessibleContent = (
        <>
            <ul>
                <li>
                    <Text>{t("common:profileWarnings.inaccessible.results.first")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.inaccessible.results.second")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.inaccessible.results.third")}</Text>
                </li>
            </ul>
        </>
    );

    return (
        <Container>
            <TitleContent>
                <Title color="red" fontWeight="bold" size="large">
                    {title}
                </Title>
            </TitleContent>
            <div className="my-5">
                {visitProfile === "disabled" && disabledContent}
                {visitProfile === "inaccessible" && inaccessibleContent}
            </div>
        </Container>
    );
};
