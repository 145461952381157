// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: "Montserrat";
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    width: 226px;
    border-radius: 12px;
    z-index: 100;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        left: unset;
        right: 0;
    }
`;

export const SliderWrapper = styled.div`
    padding: 0 4px;
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.sliderDarkBlue};
    font-size: 12px;
    margin-top: 7.5px;
    margin-bottom: 11px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
    margin-top: 21px;
`;

ButtonContainer.displayName = "ButtonContainer";
