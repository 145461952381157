// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Title, HR } from "@Components/Basic";
import { Menu } from "@Components/Menu";
import { MenuItem } from "@Components/Menu/Components";
import { PaymentSecurity } from "@Components/Payments";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentTypeMenu } from "./PaymentTypeMenu";
// #endregion Interface Imports

const items = [
    { key: "creditCard", link: "credit-card" },
    { key: "mobilePayment", link: "mobile-payment" },
    { key: "bankTransfer", link: "bank-transfer" },
    { key: "callCenter", link: "call-center" },
];

export const PaymentTypeMenu: React.FunctionComponent<IPaymentTypeMenu.IProps> = ({
    t,
    activeMenu,
    type,
}) => {
    return (
        <>
            <div className="py-3 px-3 mb-10">
                <Title fontWeight="bold" size="medium" className="mb-3">
                    {`1. ${t("common:payment.paymentTypes.title")}`}
                </Title>
                <HR className="my-0 py-0" />
                <Menu>
                    {items.map(item => {
                        return (
                            <Link
                                href={`/payments/[type]/${
                                    item.link
                                }?canScroll=true&referrer=sideBar&repeatScroll=${Math.random()}`}
                                as={`/payments/${type}/${item.link}`}
                                key={item.key}
                            >
                                <a>
                                    <MenuItem
                                        isActive={item.key === activeMenu}
                                        text={t(`common:payment.paymentTypes.${item.key}.title`)}
                                    />
                                </a>
                            </Link>
                        );
                    })}
                </Menu>
            </div>
            <PaymentSecurity t={t} />
        </>
    );
};
