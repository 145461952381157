import * as React from "react";

const SvgFacebookIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
        <path
            d="M24.5 12.073C24.5 5.405 19.127 0 12.5 0S.5 5.405.5 12.073C.5 18.1 4.888 23.094 10.625 24v-8.437H7.578v-3.49h3.047v-2.66c0-3.025 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971H16.33c-1.491 0-1.956.93-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V24c5.737-.906 10.125-5.9 10.125-11.927"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgFacebookIcon;
