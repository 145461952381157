// #region Global Imports
import * as React from "react";

// #endregion Global Imports

// #region Local Imports
import { ITitle } from "./Title";
import { Container } from "./styled";
// #endregion Local Imports

export const Title: React.FunctionComponent<ITitle.IProps> = props => {
    return <Container {...props} />;
};
