// #region Global Imports
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container, Col, Row } from "@Styled/Grid";
import { Card, Button } from "@Components/Basic";
import { PreviewsCol } from "./styled";
import { IConversation } from "./Conversation";
import { InboxActions } from "@Actions";
import { IStore } from "@Interfaces";
import {
    ProfileCard,
    ProfileCardSkeleton,
    MessageListItem,
    MessageListItemSkeleton,
} from "@Components";
import { useScreenSize, repeatElement, ActionConsts } from "@Definitions";
import { ChatSectionSkeleton } from "./Components/ChatSection/index.skeleton";
import { ChatSection } from "./Components/ChatSection";
// #endregion Local Imports

export const Conversation: React.FunctionComponent<IConversation.IProps> = ({
    previews,
    t,
    onMobileClick,
    getMoreAction,
    hasMore,
}) => {
    const { chat, loading } = useSelector(({ inbox }: IStore) => inbox);

    const dispatch = useDispatch();

    const screenSize = useScreenSize();

    useEffect(() => {
        (async () => {
            if (previews.length && !screenSize.match("small")) {
                dispatch(await InboxActions.FetchChat(previews[0].With.Id, 50));
            }
        })();

        return () => {
            dispatch({
                payload: { chat: undefined },
                type: ActionConsts.Inbox.FetchMessage,
            });
        };
    }, []);

    const renderSkeleton = () => repeatElement(<MessageListItemSkeleton />, 10);

    const renderShowMoreButton = () => (
        <div className="col d-flex justify-content-center">
            <Button onClick={getMoreAction} styleType="ghost" size="small" className="mx-auto my-4">
                {t("common:inbox.loadMore")}
            </Button>
        </div>
    );

    const renderPreviews = () => (
        <>
            {previews?.map(conv => (
                <MessageListItem
                    key={`message-list-item-${conv.With.Id}`}
                    t={t}
                    onClick={async () => {
                        if (screenSize.match("small")) onMobileClick(conv.With.Id);
                        else dispatch(await InboxActions.FetchChat(conv.With.Id, 50));
                    }}
                    isActive={chat?.With.Id === conv.With.Id}
                    data={conv}
                />
            ))}

            {loading ? renderSkeleton() : hasMore && renderShowMoreButton()}
        </>
    );

    const renderChatSection = () => {
        switch (screenSize) {
            case "large":
            case "xlarge":
                return (
                    <Col className="col-lg-8 clearfix">
                        <ChatSection sectionType="normal" t={t} />
                    </Col>
                );

            case "medium":
                return (
                    <Col className="col-sm-8 clearfix no-gutters">
                        <div className="col-sm-12">
                            {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                                <ProfileCard t={t} profile={chat?.With!} isModal={false} />
                            }
                        </div>

                        <div className="col-sm-12">
                            <ChatSection sectionType="normal" t={t} />
                        </div>
                    </Col>
                );

            default:
                return null;
        }
    };

    if (!previews.length) {
        return <Container>{t("common:inbox.noMessages")}</Container>;
    }

    return (
        <Row className="mt-5">
            <Col className="col-12 col-lg-9">
                <Card className="pr-0 pb-0">
                    <Row className="no-gutters">
                        <PreviewsCol className="col-12 col-md-4 p-3 pt-md-3 pl-md-3">
                            {renderPreviews()}
                        </PreviewsCol>

                        {chat?.With ? renderChatSection() : <ChatSectionSkeleton />}
                    </Row>
                </Card>
            </Col>

            {screenSize.match("large") && (
                <Col className="col-lg-3">
                    {chat?.With ? (
                        <ProfileCard t={t} profile={chat.With} />
                    ) : (
                        <ProfileCardSkeleton />
                    )}
                </Col>
            )}
        </Row>
    );
};
