// #region Global Imports
import React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
import { Step1, Step3, Closed } from "./Components";
import { ProfileActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { IProfileToggle } from "./ProfileToggle";
// #endregion Interface Imports

export const ProfileToggle: React.FunctionComponent<IProfileToggle.IProps> = ({
    t,
    isClosed,
    handleContinue,
}) => {
    const dispatch = useDispatch();

    const [step, setStep] = React.useState(isClosed ? 0 : 1);

    const goNext = () => {
        setStep(val => val + 1);
    };

    const closeProfile = async (password?: string) => {
        return dispatch(await ProfileActions.SetPreferences("ProfileOpen", false, password));
    };

    const openProfile = async () => {
        return dispatch(await ProfileActions.SetPreferences("ProfileOpen", true));
    };

    const tPrefix = `common:settings.profileToggle.${step ? `step${step}` : "closed"}`;

    const renderContent = () => {
        switch (step) {
            case 0:
                return (
                    <Closed
                        t={t}
                        tPrefix={tPrefix}
                        openProfile={openProfile}
                        handleContinue={handleContinue}
                    />
                );
            case 1:
                return (
                    <Step1
                        t={t}
                        tPrefix={tPrefix}
                        goNext={() => {
                            closeProfile();
                            goNext();
                        }}
                    />
                );
            // TODO: Temp disabled 2.step
            // case 2:
            //     return (
            //         <Step2
            //             t={t}
            //             tPrefix={tPrefix}
            //             goNext={goNext}
            //             closeProfile={(pw: string) => closeProfile(pw)}
            //         />
            //     );
            case 2:
                return <Step3 t={t} tPrefix="common:settings.profileToggle.step3" />;
            default:
                return <div />;
        }
    };

    return <Container>{renderContent()}</Container>;
};
