// #region Global Imports
import React, { useEffect, FC } from "react";
import ReactImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components";
import { useOutsideClick } from "@Definitions";
import { CloseIcon } from "@Icons";
import { GalleryView, DismissButton } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IImageGallery } from "./ImageGallery";
// #endregion Interface Imports

export const ImageGallery: FC<IImageGallery.IProps> = ({
    activeIndex,
    setActiveIndex,
    imageGalleryItems,
}) => {
    const createOverlayElement = () => {
        const overlay = document.createElement("div");

        overlay.className = "overlay";

        return overlay;
    };

    const injectOverlays = (gallery: HTMLElement) => {
        if (!gallery) return;

        const slides = gallery.querySelectorAll(".image-gallery-slide");

        slides.forEach(slide => {
            const [container, img, alreadyAppendedOverlay] = ["div", "img", ".overlay"].map(
                selector => slide.querySelector(selector)!
            );

            if (alreadyAppendedOverlay) return;

            const overlay = createOverlayElement();

            container.appendChild(overlay);

            const injected = slide.querySelector(".overlay") as HTMLDivElement;

            injected.style.width = `${img.clientWidth}px`;

            injected.style.marginLeft = `${(img.clientWidth / 2) * -1}px`;
        });
    };

    useEffect(() => {
        const escHandler = ({ key }: KeyboardEvent) => {
            if (key === "Escape") setActiveIndex(-1);
        };

        document.addEventListener("keyup", escHandler);

        return () => {
            document.removeEventListener("keyup", escHandler);
        };
    }, [setActiveIndex]);

    useOutsideClick(({ target }) => {
        if (activeIndex < 0 || !target) return; // * Ignore if unmounted.

        const eventSource = target as Element;

        const isSourcedFromImage = eventSource.classList.contains("overlay");

        const isSourcedFromNav = eventSource.tagName.match(/button|svg/);

        if (!isSourcedFromImage && !isSourcedFromNav) setActiveIndex(-1);
    });

    const shouldRender = imageGalleryItems.length && activeIndex > -1;

    return shouldRender ? (
        <GalleryView ref={injectOverlays}>
            <IconButton
                renderIcon={() => (
                    <DismissButton>
                        <CloseIcon width="24px" height="40px" />
                    </DismissButton>
                )}
            />

            <ReactImageGallery
                items={imageGalleryItems}
                startIndex={activeIndex}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
            />
        </GalleryView>
    ) : (
        <div />
    );
};
