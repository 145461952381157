// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { InvoiceModel } from "@Interfaces";
// #endregion Interface Imports

export const InvoiceService = {
    GetUserInvoiceSummaryList: async (): Promise<
        InvoiceModel.GetUserInvoiceSummaryListResponse
    > => {
        try {
            const response = await Http.Request<InvoiceModel.GetUserInvoiceSummaryListResponse>(
                "POST",
                `/invoice/GetUserInvoiceSummaryList`
            );
            return response;
        } catch (error) {
            console.log(error);
            return { Success: false, InvoiceList: [] };
        }
    },
    AddInvoiceInfo: async (
        payload: InvoiceModel.AddInvoiceInfoPayload
    ): Promise<InvoiceModel.AddInvoiceInfoResponse> => {
        return Http.Request("POST", `/invoice/AddInvoiceInfo`, undefined, {
            ...payload,
        });
    },
};
