// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 8px;
    font-family: "Montserrat";
`;
export const TitleContent = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 8px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    :hover {
        cursor: pointer;
    }
`;

export const LoadingContent = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 300px;
`;

export const ItemsContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
export const Item = styled.div`
    box-sizing: border-box;
    justify-content: flex-start;
    width: 50%;
    max-width: 50%;
    padding: 8px;
    border-bottom: 1px dashed ${({ theme }) => theme.colors.lightGray};
    position: relative;
    @media (min-width: ${({ theme }) => theme.maxWidth.medium}) {
        :nth-child(odd) {
            ::after {
                content: "";
                height: 90%;
                width: 1px;
                position: absolute;
                right: 0;
                top: 8px;
                background-color: ${({ theme }) => theme.colors.lightGray};
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 100%;
        max-width: 100%;
    }
`;
export const TitleText = styled.div<{ isActive: boolean }>`
    color: ${({ isActive, theme }) => (isActive ? theme.colors.red : theme.colors.lightGray)};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    letter-spacing: -0.3px;
    margin-right: 30px;
    cursor: pointer;
`;

export const ShowMoreButton = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 17px;
`;
export const EmptyViewContent = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 17px 0px;
`;

export const badgeStyle = css`
    top: -7px;
    right: 17px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 10px;
    text-align: center;
`;
