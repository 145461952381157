// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions/Styled";
// #endregion Local Imports

export const Container = styled.div<{ isActive: boolean }>`
    width: 523px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat";
    padding: 0 5px 0 10px;
    border: 1px solid
        ${({ isActive }) => (isActive ? theme.colors.sliderAqua : theme.colors.lighterGray)};

    transition: border ease-in-out 0.6s;

    @media screen and (max-width: ${theme.maxWidth.large}) {
        width: 404px;
    }

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        width: 374px;
        height: 82px;
        flex-direction: column;
        padding: 0;
    }

    @media screen and (max-width: ${theme.maxWidth.small}) {
        width: 494px;
        height: 42px;
        flex-direction: row;
        padding: 0 5px 0 10px;
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        width: 324px;
        height: 81px;
        flex-direction: column;
        padding: 0;
    }
`;

export const DropdownWrapper = styled.div`
    color: ${theme.colors.sliderDarkBlue};

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        width: 100%;
    }

    @media screen and (max-width: ${theme.maxWidth.small}) {
        width: unset;
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        width: 100%;
    }
`;

export const SliderWrapper = styled.div`
    width: 50%;

    @media screen and (max-width: ${theme.maxWidth.large}) {
        width: 50%;
    }

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        width: 100%;
        padding: 0 12px;
    }

    @media screen and (max-width: ${theme.maxWidth.small}) {
        width: 60%;
        padding: 0;
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        width: 100%;
        padding: 0 10px;
    }
`;

export const SelectedLocation = styled.div<{ isActive: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 3px;

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        height: 32px;
        justify-content: center;
        transition: border ease-in-out 0.6s;
        border-bottom: 1px solid
            ${({ isActive }) => (isActive ? theme.colors.sliderAqua : theme.colors.lighterGray)};
    }

    @media screen and (max-width: ${theme.maxWidth.small}) {
        height: 100%;
        justify-content: flex-start;
        border-bottom: none;
    }

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        span {
            max-width: 150px;
        }
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        height: 32px;
        justify-content: center;
        transition: border ease-in-out 0.6s;
        border-bottom: 1px solid
            ${({ isActive }) => (isActive ? theme.colors.sliderAqua : theme.colors.lighterGray)};
    }

    span {
        margin-right: 12px;
        font-weight: ${theme.fontWeights.medium};
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
    }

    .icon-wrapper {
        transition: transform ease-in-out 0.3s;
        display: inline-block;
        font-size: 10px;
    }

    .rotate {
        transform: rotate(180deg);
    }
`;

SelectedLocation.displayName = "SelectedLocation";
