// #region Global Imports
import styled, { CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ customStyle?: CSSProp }>`
    padding: 4px;
    width: 128px;
    background-color: ${({ theme }) => theme.colors.badgeHoverGreen};
    border-radius: 12px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;

    ${({ customStyle }) => customStyle && customStyle};

    img {
        width: 120px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        border-radius: 6px;
        width: 158px;
        padding: 2px;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 12px;

        img {
            width: 62px;
        }
    }
`;
