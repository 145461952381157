// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    min-height: 30px;
    padding: 16px 10px 0 10px;
    position: relative;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding: 18px 12px 0 12px;
    }
`;

export const HandleContainer = styled.div<{ offset: number; index: number }>`
    display: inline-block;
    position: absolute;
    margin-top: -20px;
    left: ${({ offset }) => `calc(${offset}% - 8px)`};
    z-index: ${({ index }) => index + 9};
    cursor: pointer;

    div {
        position: relative;
    }

    .handle-to {
        color: ${({ theme }) => theme.colors.sliderDarkBlue};

        :hover {
            color: ${({ theme }) => theme.colors.sliderDarkBlueHover};
        }
    }

    .handle-from {
        color: ${({ theme }) => theme.colors.sliderAqua};

        :hover {
            color: ${({ theme }) => theme.colors.sliderAquaHover};
        }
    }

    span {
        position: absolute;
        left: 3px;
        top: 2px;
        color: white;
        font-size: 10px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        pointer-events: none;
    }

    svg {
        margin: 0;
        z-index: 9;
    }

    input {
        display: none;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: -22px;
        left: ${({ offset, index }) => `calc(${offset}% - ${10 + index}px)`};

        span {
            font-size: 10px;
            left: 2.3px;
            top: 1px;
        }

        svg {
            margin-top: -4px;
        }
    }
`;
