import * as React from "react";

const SvgJobIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 17 11" {...props}>
        <path
            d="M.5 8.997v2h16v-2H.5zM10.052.702v2.462h-.509V.549C9.238.497 8.83.497 8.525.497c-.305 0-.713 0-1.018.052v2.615h-.51V.702c-3.41.77-5.497 3.95-5.497 7.795h14c.05-3.846-2.037-7.025-5.448-7.795zM8.5 6.997c-.823 0-1.5-.656-1.5-1.5 0-.843.677-1.5 1.5-1.5s1.5.657 1.5 1.5c0 .844-.677 1.5-1.5 1.5z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgJobIcon;
