// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import { Tint, ImageControls, ImageButtons } from "@Components/HotOrNot/Components/Game/styled";
import { ContentBox, ImageButton } from "./styled";
// #endregion Local Imports

interface Props {}

export const GameSkeleton: React.FC<Props> = () => (
    <ContentBox>
        <RectangleSkeleton width="374" height="622" />
        <Tint />
        <ImageControls>
            <RectangleSkeleton
                width="150"
                height="20"
                style={{ marginLeft: "8px", marginBottom: "4px" }}
            />
            <ImageButtons>
                <ImageButton>
                    <RectangleSkeleton width="40" height="40" />
                </ImageButton>
                <ImageButton>
                    <RectangleSkeleton width="40" height="40" />
                </ImageButton>
            </ImageButtons>
        </ImageControls>
    </ContentBox>
);
