// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const MasterDetailNavigation = styled.section`
    display: flex;
    justify-content: space-between;
    margin: 24px 0px 16px;

    button {
        background-color: unset;

        span {
            margin: 0 4px;
        }

        svg {
            transition: transform 0.4s ease;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin: 8px 0 16px;

        button {
            padding: 0.5rem 0.3rem;
            font-size: 14px;
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;

    button {
        border-color: ${({ theme }) => theme.colors.lightGray};
        color: ${({ theme }) => theme.colors.gray};

        :hover:not([disabled]) {
            z-index: 1; /* Switch overlapping borders */
            border-color: ${({ theme }) => theme.colors.azureRadianceDark};
            color: ${({ theme }) => theme.colors.azureRadianceDark};

            svg {
                color: ${({ theme }) => theme.colors.azureRadianceDark};
            }

            :first-child svg {
                transform: translateX(-4px) rotateY(180deg);
            }

            :last-child svg {
                transform: translateX(4px);
            }
        }

        :first-child {
            position: relative;
            left: 1px; /* Overlap borders */
            border-radius: 32px 0 0 32px;

            svg {
                transform: rotateY(180deg);
            }
        }

        :last-child {
            border-radius: 0 32px 32px 0;
        }
    }
`;
