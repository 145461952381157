// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Image, Button, Anchor } from "@Components/Basic";
import {
    Container,
    ContainerTitle,
    Top,
    TopContent,
    TopTitle,
    TCRight,
    TCLeft,
    TCRButton,
    TCLButton,
    TCRLink,
    TCRText,
    Bottom,
    BottomArticle,
    BAImage,
    BAText,
    BATitle,
    BottomNews,
    BNButton,
    BNCouple,
} from "./styled";
import { INewsContainer } from "./NewsContainer";
// #endregion Local Imports

const NewsContainer: React.FunctionComponent<INewsContainer.IProps> = (props): JSX.Element => {
    return (
        <Container {...props}>
            <ContainerTitle>
                <span>BASINDA SİBERALEM</span>
            </ContainerTitle>
            <Top>
                <TopTitle>
                    <span>Mutlu Çiftimiz Akşam Gazetesi’nde!</span>
                </TopTitle>
                <TopContent>
                    <TCLeft>
                        <Image src="images/happy-2.png" alt="Mutlu Çift" />
                        <a
                            href="//www.siberalem.com/siberalem-aksam-gazetesine-konuk-oldu/"
                            target="_blank" rel="noreferrer"
                        >
                            <TCLButton>HABERE GİT</TCLButton>
                        </a>
                    </TCLeft>
                    <TCRight>
                        <TCRText>
                            <p id="p1">
                                Yaptığımız işin en güzel yanı, birbirlerini bulmalarına aracı
                                olduğumuz çiftlerimizin mutluluklarına şahit olmak. Ebru ve Ersin
                                Ayalp çifti de bu mükemmel çiftlerimizden sadece biri.
                            </p>
                            <p id="p2">
                                Çiftimiz güzel hikayelerini birkez de Akşam Gazetesi ile paylaştılar
                                ve ortaya okunması çok keyifli bir aşk yazısı çıktı.
                            </p>
                            <p id="p-short">
                                Yaptığımız işin en güzel yanı, birbirlerini bulmalarına aracı
                                olduğumuz çiftlerimizin mutluluklarına şahit olmak...
                            </p>
                            <TCRLink
                                href="//www.siberalem.com/siberalem-aksam-gazetesine-konuk-oldu/"
                                target="_blank"
                            >
                                Devamını Oku &gt;
                            </TCRLink>
                        </TCRText>
                        <a
                            href="//www.siberalem.com/siberalem-aksam-gazetesine-konuk-oldu/"
                            target="_blank" rel="noreferrer"
                        >
                            <TCRButton>HABERE GİT</TCRButton>
                        </a>
                    </TCRight>
                </TopContent>
            </Top>

            <Bottom>
                <BottomArticle>
                    <BAImage>
                        <Image src="images/news-couple.png" alt="Mutlu Çift" />
                    </BAImage>
                    <div>
                        <BATitle>
                            <span>
                                Türkiye&apos;nin İnternetteki İlk, En Büyük ve Güvenilir Arkadaşlık
                                Sitesi.
                            </span>
                        </BATitle>
                        <BAText>
                            <p>✓ Bütün profiller editörlerce kontrol edilir,</p>
                            <p>✓ Haftanın 7 günü ulaşılabilir müşteri hizmetleri.</p>
                        </BAText>
                    </div>
                </BottomArticle>
                <BottomNews>
                    <BNCouple>
                        <Anchor className="mr-3">
                            <Image src="images/news-hurriyet.png" alt="Hürriyet" />
                        </Anchor>
                        <Anchor className="mr-3">
                            <Image src="images/news-milliyet.png" alt="Milliyet" />
                        </Anchor>
                    </BNCouple>
                    <BNCouple>
                        <Anchor className="mr-3">
                            <Image src="images/news-sabah.png" alt="Sabah" />
                        </Anchor>
                        <Anchor className="mr-3">
                            <Image src="images/news-cumhuriyet.png" alt="Cumhuriyet" />
                        </Anchor>
                    </BNCouple>
                    <BNButton>
                        <a href="//www.siberalem.com/basinda-biz/" target="_blank" rel="noreferrer">
                            <Button size="large" block>
                                TÜM HABERLER
                            </Button>
                        </a>
                    </BNButton>
                </BottomNews>
            </Bottom>
        </Container>
    );
};

export { NewsContainer };
