// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { ChatSection } from "@Components/Conversation/Components/ChatSection";
import { ModalContainer, ProfileContent, ChatContent, StyledCloseIcon } from "@Styled/Inbox";
import { IChatModal } from "./ChatModal";
import { ReactModal, ProfileCard, IconButton } from "@Components";
import { useScreenSize, ActionConsts } from "@Definitions";
import { IStore } from "@Interfaces";
import { mobileModalStyle, modalStyle } from "./styles";
import { CloseIcon } from "@Icons";
// #endregion Local Imports

const ChatModal: React.FunctionComponent<IChatModal.IProps> = ({ t }): JSX.Element => {
    const dispatch = useDispatch();

    const screenSize = useScreenSize();

    const isMobile = screenSize.includes("small");

    const chat = useSelector((state: IStore) => state.inbox.chat);

    React.useEffect(() => {
        return () => {
            dispatch({
                payload: { chat: undefined },
                type: ActionConsts.Inbox.FetchMessage,
            });
            dispatch({
                payload: false,
                type: ActionConsts.Common.SetChatModal,
            });
        };
    }, [dispatch]);

    const handleCloseModal = () => {
        dispatch({
            payload: false,
            type: ActionConsts.Common.SetChatModal,
        });
    };

    return (
        <ReactModal
            isOpen
            onRequestClose={handleCloseModal}
            contentLabel="modal"
            closeTimeoutMS={200}
            style={isMobile ? mobileModalStyle : modalStyle}
        >
            {chat?.With && (
                <ModalContainer isOpen isMobile={isMobile}>
                    <ChatContent isMobile={isMobile}>
                        <ChatSection sectionType="modal" setModalOpen={handleCloseModal} t={t} />
                    </ChatContent>
                    <ProfileContent isMobile={isMobile}>
                        <ProfileCard
                            t={t}
                            profile={chat?.With}
                            onBackClick={handleCloseModal}
                            isModal
                        />
                        {!isMobile && (
                            <IconButton
                                cy="inbox-chatbox-close-btn"
                                renderIcon={() => (
                                    <StyledCloseIcon>
                                        <CloseIcon height="17px" />
                                    </StyledCloseIcon>
                                )}
                                onIconClick={handleCloseModal}
                            />
                        )}
                    </ProfileContent>
                </ModalContainer>
            )}
        </ReactModal>
    );
};

export { ChatModal };
