// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ isActive: boolean }>`
    cursor: pointer;
    border-radius: 14px;
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 250px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        min-height: unset;
    }

    ${({ isActive }) => {
        return isActive
            ? css`
                  background-color: ${({ theme }) => theme.colors.lightBlue};
                  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
              `
            : css`
                  border: 1px solid ${({ theme }) => theme.colors.lighterGray};
              `;
    }}

    .fs-30 {
        font-size: 30px;
    }

    .draw {
        text-decoration: line-through;
    }

    .hidden {
        visibility: hidden;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            display: none !important;
        }
    }
`;

export const Main = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const CampaignName = styled.div`
    text-align: center;
    .name {
        font-size: 30px;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            text-align: left;
            font-size: 24px;
        }

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            font-size: 20px;
        }
    }
`;

export const CampaignPrice = styled.div`
    font-size: 15px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        text-align: right;
        font-size: 14px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 12px;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        flex-direction: row;
        text-align: center;

        img:first-child {
            margin-right: 1px;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column;
        text-align: center;
    }
`;

export const Image = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.minWidth.medium}) {
        justify-content: flex-end;
    }

    img {
        width: 54px;

        @media screen and (min-width: ${({ theme }) => theme.minWidth.small}) {
            width: 50px;
        }
    }
`;

export const HasAdvantage = styled.div`
    color: #fff;
    background-image: url("/images/badge-empty.png");
    background-size: cover;
    text-align: center;
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    flex-direction: column;
    right: 0px;
    top: 7px;

    &.double {
        top: 60px;
    }

    b {
        margin-top: -7px;
        margin-left: -3px;
    }

    span {
        font-size: 6px;
        font-weight: bold;
        display: block;
        margin-top: -2px;
        margin-left: -4px;
    }
`;

Footer.displayName = "Footer";
