// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Message = styled.div<{ customStyle?: CSSProp }>`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-bottom: 8px;
    ${({ customStyle }) => customStyle};
`;

export const MessageBox = styled.div`
    color: ${({ theme }) => theme.colors.white};
    width: auto;
    padding: 5px 16px;
    font-family: "Montserrat";
    font-style: italic;
    word-break: break-word;
`;

export const MessageInfo = styled.div``;

export const Time = styled.span`
    font-size: 10px;
    margin-right: 5px;
`;

export const Tick = styled.i`
    color: ${({ theme }) => theme.colors.sliderAqua};
    font-size: 9px;
`;

export const sentStyle = css`
    text-align: right;
    justify-content: flex-end;
    margin-left: 30%;
    ${MessageBox} {
        background-color: ${({ theme }) => theme.colors.azureRadiance};
        margin-left: auto;
        border-radius: 20px 2px 2px 20px;
    }
    ${Time} {
        color: ${({ theme }) => theme.colors.sliderAqua};
    }
`;

export const receivedStyle = css`
    text-align: left;
    justify-content: flex-start;
    margin-right: 30%;
    ${MessageBox} {
        color: ${({ theme }) => theme.colors.darkGray};
        background-color: ${({ theme }) => theme.colors.lightGray};
        margin-right: auto;
        border-radius: 2px 20px 20px 2px;
    }
    ${Time} {
        color: ${({ theme }) => theme.colors.gray};
    }
`;

export const StickerImage = styled.img<{ isSent?: boolean }>`
    height: 90px;
    width: 90px;
    display: flex;
    ${({ isSent }) =>
        isSent
            ? css`
                  margin-left: auto;
              `
            : css`
                  margin-right: auto;
              `}
`;
