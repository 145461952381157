import * as React from "react";

const SvgWinkedIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 37 36" {...props}>
        <path
            d="M18.5 0c9.941 0 18 8.059 18 18 0 2.403-.47 4.695-1.325 6.79a6.934 6.934 0 00-.823-.636A16.964 16.964 0 0035.5 18c0-9.389-7.611-17-17-17s-17 7.611-17 17 7.611 17 17 17c2.17 0 4.246-.407 6.154-1.148.192.29.404.565.636.823A17.938 17.938 0 0118.5 36c-9.941 0-18-8.059-18-18s8.059-18 18-18zm12 24a6 6 0 110 12 6 6 0 010-12zm2.17 3.279l-.095.083-3.535 3.535-.707-.706a1 1 0 00-1.498 1.32l.084.094 1.414 1.414c.15.15.336.243.53.277l.118.014h.118l.117-.014a.994.994 0 00.437-.194l.094-.083 4.243-4.243a1 1 0 00-1.32-1.497zM18.5 8c5.523 0 10 4.477 10 10a9.963 9.963 0 01-2.594 6.72 6.905 6.905 0 00-.687.685A9.958 9.958 0 0118.5 28c-5.523 0-10-4.477-10-10s4.477-10 10-10zm0 1a9 9 0 100 18 9 9 0 000-18zm-5.127 11.127a7.253 7.253 0 0010.253 0 .75.75 0 011.061 1.06 8.753 8.753 0 01-12.374 0 .75.75 0 111.06-1.06zM15.164 15a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8.093.75a.75.75 0 110 1.5h-3a.75.75 0 110-1.5h3z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
            opacity={0.9}
        />
    </svg>
);

export default SvgWinkedIcon;
