// #region Global Imports
import * as React from "react";
import Modal from "react-modal";
import noScroll from "no-scroll";
// #endregion Global Imports

// #region Local Imports
// #endregion Local Imports

// #region Interface Imports
import { IReactModal } from "./ReactModal";
// #endregion Interface Imports

export const ReactModal: React.FunctionComponent<IReactModal.IProps> = props => {
    const { style } = props;
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "50%",
            ...style?.content,
        },
        overlay: {
            zIndex: 9,
            ...style?.overlay,
        },
    };

    React.useEffect(() => {
        noScroll.on();

        return () => {
            noScroll.off();
        };
    }, []);

    return <Modal ariaHideApp={false} {...props} style={customStyles} />;
};
