// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { useScreenSize, getCustomSliderConfig } from "@Definitions";
import { SmartSlider, InfoCard } from "@Components";
import { Container, FlexWrapper, CardColumn } from "./styled";
import { IInfoCardContainer } from "./InfoCardContainer";
import data from "./InfoCardContainer.data";
// #endregion Local Imports

const getUniqueKey = (index: number): string => `InfoCards__InfoCard--${index}`;

const renderCards = (screenType: string): JSX.Element => {
    const customSliderConfig = getCustomSliderConfig(screenType);

    const slides = data.map((card, index) => (
        <CardColumn key={getUniqueKey(index)}>
            <InfoCard {...card} />
        </CardColumn>
    ));

    return <SmartSlider {...customSliderConfig} slides={slides} />;
};

export const InfoCardContainer: React.FunctionComponent<IInfoCardContainer.IProps> = (): JSX.Element => {
    const screenType = useScreenSize();

    return (
        <Container>
            <FlexWrapper>{renderCards(screenType)}</FlexWrapper>
        </Container>
    );
};
