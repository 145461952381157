import * as React from "react";

const SvgMailCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 37 36" {...props}>
        <path
            d="M18.5-.01c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm0 1c-9.389 0-17 7.611-17 17s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17zm8 10a2 2 0 012 2v10a2 2 0 01-2 2h-16a2 2 0 01-2-2v-10a2 2 0 012-2h16zm0 1h-16a1 1 0 00-.993.883l-.007.117v10a1 1 0 00.883.993l.117.007h16a1 1 0 00.993-.883l.007-.117v-10a1 1 0 00-1-1zm-.62 1.575a.5.5 0 01.008.64l-.063.065-4.278 3.667 4.632 4.078a.5.5 0 01-.661.75l-4.738-4.17a3.502 3.502 0 01-4.398.13l-.16-.129-.261-.224-4.605 4.38a.5.5 0 01-.639.042l-.068-.06a.5.5 0 01-.041-.639l.06-.067 4.53-4.31-4.023-3.448a.5.5 0 01-.107-.632l.052-.073a.5.5 0 01.632-.107l.073.052 5.048 4.327a2.5 2.5 0 003.103.12l.151-.12 5.048-4.327a.5.5 0 01.705.055z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgMailCircle;
