// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ photoCount: number }>`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #dde2e9;
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin-bottom: 8px;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        max-height: 53px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-right: ${({ photoCount }) => (photoCount > 4 ? "8px" : "0")};
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-right: 0;
    }
`;

export const BorderLine = styled.div`
    background-color: ${({ theme }) => theme.colors.badgeGreen};
    height: 2px;
    display: flex;
    margin: 6px;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        margin: 3px;
        height: 1px;
    }
`;

export const PhotoContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const PhotoContent = styled.div`
    display: flex;
    flex: 1 0 auto;
    margin: 0 5px;
    div:empty ${PhotoContainer} {
        display: none;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        flex: unset;
        justify-content: unset;
        margin: 0 -4px;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        margin: 0 -1px;
    }
`;

export const PhotoItem = styled.div`
    display: flex;
    justify-content: center;
    width: 75px;
    height: 75px;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin: 0 4px;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        margin: 0 12px;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        margin: 0px 4px;
        width: 37px;
        height: 37px;
    }
`;

export const PhotoCountBox = styled(PhotoItem)`
    background-color: #8791a1;
    cursor: unset;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        min-width: 37px;
        min-height: 37px;
    }
`;

export const UserImage = styled.img`
    display: block;
    max-width: 75px;
    max-height: 75px;
    width: 75px;
    height: 75px;
    border-radius: 4px;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        width: 37px;
        height: 37px;
    }
`;

export const CountText = styled.span`
    display: flex;
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        font-size: 12px;
    }
`;
