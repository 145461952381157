// #region Global Imports
import styled, { keyframes, css } from "styled-components";
// #endregion Global Imports

const fadeIn = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
`;
const Container = styled.div<{ isFadeIn: boolean }>`
    background: ${({ theme }) => theme.gradients.grayHeader};
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.18);
    border-radius: 3px 3px 8px 8px;
    overflow: hidden;
    transform: translateX(0);
    opacity: ${({ isFadeIn }) => (isFadeIn ? 1 : 0)};
    animation: ${({ isFadeIn }) => (isFadeIn ? fadeIn : fadeOut)} 0.4s ease 0s 1;
`;

const TopNav = styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.green};
    height: 64px;
    width: 100%;
`;
const CloseRight = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
const NavContent = styled.div<{ isFadeIn: boolean }>`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 32px 17px 96px;
    opacity: ${({ isFadeIn }) => (isFadeIn ? 1 : 0)};
    animation: ${({ isFadeIn }) => (isFadeIn ? fadeIn : fadeOut)} 0.5s ease 0s 1;

    overflow-y: auto;
    height: 100%;
`;

const NavItem = styled.div`
    margin: 9px;
`;

const navTextCss = css`
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    color: ${({ theme }) => theme.colors.gray};
    letter-spacing: -0.3px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const NavText = styled.div`
    padding-left: 24.5px;
    ${navTextCss}
    a {
        ${navTextCss}
    }
`;

const StyledIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 15px;
`;

const HR = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    width: 100%;
`;
export { HR, Container, CloseRight, TopNav, NavItem, NavContent, NavText, StyledIcon };
