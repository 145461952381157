// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const DeleteButton = styled.i`
    visibility: hidden;
    width: 20px;
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        visibility: visible;
    }
`;

export const Container = styled.div`
    display: flex;
    flex: 1;
    font-family: Montserrat;
    background: ${({ theme }) => theme.colors.white};

    :hover {
        cursor: pointer;
        border-radius: 12px;
        background: ${({ theme }) => theme.colors.lightBlue};
    }

    &:hover ${DeleteButton} {
        visibility: visible;
    }

    > a {
        display: flex;
        text-decoration: none;
    }
`;

export const AvatarContent = styled.div`
    display: flex;
    padding: 10px;
`;

export const InfoContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
`;

export const Time = styled.div<{ isSeen: boolean }>`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: flex;
    font-size: 10px;
    color: ${({ isSeen, theme }) => (isSeen ? theme.colors.badgeGreen : theme.colors.gray)};
    letter-spacing: -0.15px;
`;

export const New = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    display: flex;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.badgeGreen};
    letter-spacing: -0.15px;
`;

export const Username = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
`;

export const Location = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.mediumGray};
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

export const customAvatarStyle = css`
    width: 96px;
    height: 96px;
`;

export const StyledIcon = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    margin: 4px;
    align-items: center;
    display: flex;
    border-radius: 50%;

    :hover {
        color: ${({ theme }) => theme.colors.badgeHoverGreen};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const WinkedStyledIcon = styled(StyledIcon)`
    color: ${({ theme }) => theme.colors.badgeGreen};
`;

export const StyledDeleteIcon = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    align-items: center;
    display: flex;

    :hover {
        color: ${({ theme }) => theme.colors.sliderDarkBlue};
    }
`;

export const StyledRemoveFriendIcon = styled(StyledIcon)`
    color: ${({ theme }) => theme.colors.lightGray};

    :hover {
        color: ${({ theme }) => theme.colors.badgeHoverRed};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const StyledFriendIcon = styled(StyledIcon)`
    color: ${({ theme }) => theme.colors.badgeGreen};

    :hover {
        color: ${({ theme }) => theme.colors.badgeHoverRed};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;
