// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100vh;
    justify-content: center;
    width: 375px;
    padding: 10px;
`;
export const SiberalemLogo = styled.div`
    position: absolute;
    top: 21px;
    left: 10px;
`;
