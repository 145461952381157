// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    min-height: ${({ theme }) => theme.heights.modalHeaderHeight};
    background: ${({ theme }) => theme.gradients.greenToBlue};
    border-radius: 30px 30px 0px 0px;
    padding: 19px 0px 17px 38px;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 6px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.titleFontSizes.large};
    letter-spacing: -0.38px;
`;

export const customModalCloseIcon = css`
    color: #fff;
    top: 20px;
    font-size: 24px;
`;
