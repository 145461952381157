// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const GalleryView = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);

    .image-gallery {
        &-slide .overlay {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
        }

        &-image {
            width: unset; /* Disable full-width */
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        .image-gallery {
            &-image {
                width: 100%;
            }
        }
    }
`;

export const DismissButton = styled.div`
    position: fixed;
    z-index: 100;
    top: 32px;
    right: 32px;
    color: #fff;
    svg {
        filter: drop-shadow(0px 0px 5px #222);
    }
`;
