// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: "Montserrat";
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 247px;
    border-radius: 12px;
    position: absolute;
    z-index: 100;
    top: calc(100% + 10px);

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 100%;
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.sliderDarkBlue};
    font-size: 12px;
    margin-top: 7.5px;
    margin-bottom: 11px;
`;

export const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
`;

ButtonContainer.displayName = "ButtonContainer";
