// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Placeholder = styled.div`
    font-style: italic;
    text-transform: capitalize;
    padding: 8px 16px;
    margin: 8px;
    box-sizing: content-box;
`;

export const ListView = styled.div`
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border: 1px solid;
    color: ${({ theme }) => theme.colors.lighterGray};
    background-color: #fff;
    margin-bottom: 16px;
`;

export const ListItem = styled.div`
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    padding: 8px 16px;
    color: ${({ theme }) => theme.colors.gray};
    text-transform: lowercase;
    white-space: nowrap;
    border-radius: 64px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    :hover {
        background-color: #d9f7ff;
    }

    ::after {
        content: "";
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        opacity: 0.6;
        background-color: ${({ theme }) => theme.colors.lighterGray};
    }

    :last-child {
        ::after {
            content: none;
        }
    }

    svg {
        ::before {
            display: block;
        }
    }

    ${ListView} > div:not(${Placeholder}) {
        border-radius: 2px;
        padding: 4px 8px;

        :hover {
            color: #0076ff;
        }
    }

    [class*=Tooltip] {
        > div {
            ::before,
            ::after {
                border-width: 0px 0px 16px 16px;
                top: 20px;
                left: -7px;
            }

            ::before {
                transform: rotateZ(-45deg) scale(1.1);
                border-color: transparent #dde2e9;
            }

            ::after {
                transform: rotateZ(-45deg);
                border-color: transparent #fff;
            }
        }
    }
`;
