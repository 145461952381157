// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { Repeater } from "@Components/Repeater";
import { Card, Title } from "@Components/Basic";
import { CoinNotificationType } from "@Interfaces/enums";
import { NotificationItem, NotificationItemSkeleton } from "./Components";
import {
    Container,
    CardInnerWrapper,
    CardTitleWrapper,
    customCardCss,
    CardScrollContent,
} from "./styled";
// #endregion Local Imports

// #region Inteface Imports
import { INotifications } from "./Notifications";
import { IStore, ICommonReducer } from "@Interfaces";
import { INotificationItem } from "./Components/NotificationItem/NotificationItem";
// #endregion Inteface Imports

const Notifications: React.FunctionComponent<INotifications.IProps> = (props): JSX.Element => {
    const { t } = props;

    const common = useSelector((state: IStore) => state.common);

    const getKey = (index: number, keyType: string) => `notifications-item-${keyType}-${index}`;

    const notifications = common.notifications.list.map((item: any) => {
        // TODO: FIXME
        switch (item.Type) {
            case CoinNotificationType[CoinNotificationType.LoginBonus]:
            case CoinNotificationType[CoinNotificationType.Initial]:
            case CoinNotificationType[CoinNotificationType.QuestionOccupancyBonus]:
                return {
                    dateText: dayjs(item.Date).fromNow(),
                    image: "",
                    type: "coin",
                    text: t(`common:notifications.${item.Type}`, {
                        Amount: item.Amount,
                    }),
                };
            case "profileView":
            case "message":
            case "wink":
            case "like":
            case "match":
            case "friend":
                return {
                    image: item.Photo,
                    type: item.Type,
                    text: t(`common:notifications.${item.Type}`, {
                        Username: item.Username,
                    }),
                    id: item.UserId,
                };
            default:
                return {} as INotificationItem.IProps;
        }
    });

    return (
        <Container>
            <Card customStyle={customCardCss}>
                <CardInnerWrapper>
                    <CardTitleWrapper>
                        <Title fontWeight="bold" size="large">
                            {t("common:notifications.bildirimler")}
                        </Title>
                    </CardTitleWrapper>
                    <CardScrollContent>
                        {common.notifications.loading ? (
                            <Repeater times={3}>
                                <NotificationItemSkeleton />
                            </Repeater>
                        ) : (
                            notifications.map((item, i) => (
                                <NotificationItem key={getKey(i, item.type)} {...item} t={t} />
                            ))
                        )}
                    </CardScrollContent>
                </CardInnerWrapper>
            </Card>
        </Container>
    );
};

export { Notifications };
