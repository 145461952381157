import { IStore } from "@Interfaces";
import {
    DashboardActions,
    CommonActions,
    ProfileActions,
    OfferActions,
    MetaDataActions,
    InteractionActions,
} from "@Actions";

export const ThrowIfNotAuthenticated = (getState: () => IStore) => {
    if (!getState().auth.user) throw new Error("AuthenticationError");
};

export const FetchDashboardData = async (dispatch: Function) => {
    dispatch(await ProfileActions.GetProfile());
    dispatch(await DashboardActions.GetPutMeUpHere());
    dispatch(await CommonActions.GetCoin());
    dispatch(await CommonActions.SetOccupancy());
    dispatch(await CommonActions.GetChannel());
    dispatch(await OfferActions.GetOffer());
    dispatch(await MetaDataActions.GetSearchFilterQuestions());
    dispatch(await InteractionActions.GetOnlineFriends(0, 24));
    dispatch(await ProfileActions.ShowPhotoModal());
    setTimeout(async () => {
        dispatch(await DashboardActions.GetNotification());
        dispatch(await CommonActions.CoinNotification());
    }, 1000);
};
