// #region Global Imports
import React from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
// #endregion Global Imports

// #region Local Imports
import { ApiStatus } from "@Interfaces/enums";
import { Text, Button, TextInput, Dropdown, Anchor } from "@Components/Basic";
import { Modal, FormBox } from "@Components";
import { Container, Form } from "./styled";
import { Row, Col } from "@Styled";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentSuccess } from "./PaymentSuccess";
import { MetaDataActions } from "@Actions";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

export const PaymentSuccess: React.FunctionComponent<IPaymentSuccess.IProps> = ({
    t,
    type,
    invoiceFormOnSubmit,
}) => {
    const dispatch = useDispatch();
    const { countries, cities } = useSelector((state: IStore) => state.metadata);
    const { addInvoiceInfo } = useSelector((state: IStore) => state.invoice);
    const { register, handleSubmit, errors, setValue } = useForm<IPaymentSuccess.InvoiceForm>({
        mode: "onBlur",
    });

    const tt = (name: string, options?: any) => {
        return t(`common:payment.paymentSuccess.${type}.${name}`, { ...options });
    };

    const [selectedCountry, setSelectedCountry] =
        React.useState<{ value: string; label: string }>();
    const [selectedCity, setSelectedCity] = React.useState();
    const [renderInvoiceModal, setRenderInvoiceModal] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            dispatch(await MetaDataActions.FetchCountries());
        })();
    }, [dispatch]);

    React.useEffect(() => {
        if (selectedCountry) {
            setSelectedCity(undefined);
            setValue("city", null);
            (async () => {
                dispatch(
                    await MetaDataActions.FetchCities(parseInt(selectedCountry!.value, 10), false)
                );
            })();
        }
    }, [selectedCountry]);

    // invoice modal close
    React.useEffect(() => {
        if (ApiStatus.loaded === addInvoiceInfo.status) {
            setRenderInvoiceModal(false);
        }
    }, [addInvoiceInfo.status]);

    const renderModal = () => {
        if (!renderInvoiceModal) {
            return false;
        }

        return (
            <Modal>
                <FormBox
                    title={t("common:payment.paymentSuccess.invoice.title")}
                    onCloseClick={() => setRenderInvoiceModal(false)}
                    onHandleSubmit={handleSubmit(invoiceFormOnSubmit)}
                >
                    <Form>
                        <div className="mb-5">
                            <TextInput
                                name="name"
                                isValid={errors.name && false}
                                errorText={errors.name ? errors.name.message : ""}
                                placeholder={t("common:payment.paymentSuccess.invoice.name.label")}
                                _ref={register()}
                            />
                        </div>
                        <div className="mb-5">
                            <TextInput
                                name="address"
                                isValid={errors.address && false}
                                errorText={errors.address ? errors.address.message : ""}
                                placeholder="Adresiniz"
                                _ref={register()}
                            />
                        </div>
                        <Row className="mb-5">
                            <Col className="col-6">
                                <Dropdown
                                    t={t}
                                    name="country"
                                    isValid={errors.country && false}
                                    errorText={errors.country ? errors.country.message : ""}
                                    onChange={item => {
                                        setSelectedCountry(item);
                                        setValue("country", item.value);
                                    }}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.country.label"
                                    )}
                                    value={selectedCountry}
                                    options={countries.map(item => ({
                                        label: item.Name,
                                        value: item.Id,
                                    }))}
                                    _ref={register(
                                        {
                                            name: "country",
                                        },
                                        {
                                            required: `${t(
                                                "common:payment.paymentSuccess.invoice.country.validation.required"
                                            )}`,
                                        }
                                    )}
                                />
                            </Col>
                            <Col className="col-6">
                                <Dropdown
                                    t={t}
                                    name="city"
                                    isValid={errors.city && false}
                                    errorText={errors.city ? errors.city.message : ""}
                                    onChange={item => {
                                        setSelectedCity(item);
                                        setValue("city", item.value);
                                    }}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.city.label"
                                    )}
                                    value={selectedCity}
                                    options={cities.map(item => ({
                                        label: item.Name,
                                        value: item.Id,
                                    }))}
                                    _ref={register(
                                        {
                                            name: "city",
                                        },
                                        {
                                            required: `${t(
                                                "common:payment.paymentSuccess.invoice.city.validation.required"
                                            )}`,
                                        }
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col className="col-6">
                                <TextInput
                                    name="postalCode"
                                    isValid={errors.postalCode && false}
                                    errorText={errors.postalCode ? errors.postalCode.message : ""}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.postalCode.label"
                                    )}
                                    _ref={register()}
                                />
                            </Col>
                            <Col className="col-6">
                                <TextInput
                                    name="phone"
                                    isValid={errors.phone && false}
                                    errorText={errors.phone ? errors.phone.message : ""}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.phone.label"
                                    )}
                                    _ref={register()}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col className="col-6">
                                <TextInput
                                    name="taxOffice"
                                    isValid={errors.taxOffice && false}
                                    errorText={errors.taxOffice ? errors.taxOffice.message : ""}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.taxOffice.label"
                                    )}
                                    _ref={register()}
                                />
                            </Col>
                            <Col className="col-6">
                                <TextInput
                                    name="taxNo"
                                    isValid={errors.taxNo && false}
                                    errorText={errors.taxNo ? errors.taxNo.message : ""}
                                    placeholder={t(
                                        "common:payment.paymentSuccess.invoice.taxNo.label"
                                    )}
                                    _ref={register({
                                        required: `${t(
                                            "common:payment.paymentSuccess.invoice.taxNo.validation.required"
                                        )}`,
                                        pattern: {
                                            value: /^\d{10,11}$/,
                                            message: `${t(
                                                "common:payment.paymentSuccess.invoice.taxNo.validation.pattern"
                                            )}`,
                                        },
                                    })}
                                />
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <Button
                                type="submit"
                                styleType="ghost"
                                size="medium"
                                className="text-uppercase"
                            >
                                {t("common:payment.paymentSuccess.invoice.submit")}
                            </Button>
                        </div>
                    </Form>
                </FormBox>
            </Modal>
        );
    };

    return (
        <>
            {renderModal()}
            <Container className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <img
                        src={`/images/gradient-${
                            type === "coin" ? "coin-icon.png" : "circled-star.png"
                        }`}
                        alt="success"
                    />
                </div>
                <div className="d-flex flex-column align-items-center mb-sm-10 mb-4">
                    <Text color="badgeGreen" weight="bold" className="fs-45">
                        {tt("mainText")}
                    </Text>
                    <Text color="badgeGreen" weight="bold" className="fs-21">
                        {tt("subText")}
                    </Text>
                </div>
                <div className="d-flex flex-column mb-5 mt-sm-10">
                    <Text size="small" weight="bold" className="mb-1">
                        {tt("reminder.title")}
                    </Text>
                    <Text size="small" className="mb-1">
                        {tt("reminder.text1")}
                    </Text>
                    {type === "gold" && (
                        <div>
                            <Text size="small" className="mr-1" elementType="span">
                                {tt("reminder.text2")}
                            </Text>
                            <Link href="/settings">
                                <a>
                                    <Text size="small" elementType="span">
                                        {t("common:settings.settings")}
                                    </Text>
                                </a>
                            </Link>
                            <Text size="small" elementType="span">
                                {tt("reminder.text3")}
                            </Text>
                        </div>
                    )}
                    <Text size="small" weight="bold" className="my-1">
                        {tt("reminder.caution")}
                    </Text>

                    <Anchor onClick={() => setRenderInvoiceModal(true)}>
                        <Text
                            size="small"
                            weight="bold"
                            className="my-1"
                            dangerouslySetInnerHTML={{
                                __html: tt(`reminder.receipt`),
                            }}
                        />
                    </Anchor>
                </div>
                <div className="d-flex justify-content-center">
                    <Link href="/dashboard">
                        <a>
                            <Button size="slim" styleType="facebook">
                                {tt("buttonText")}
                            </Button>
                        </a>
                    </Link>
                </div>
            </Container>
        </>
    );
};
