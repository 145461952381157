// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic/Text";
import { IButton } from "./Button";
import { Container } from "./styled";
// #endregion Local Imports

export const Button: React.FunctionComponent<IButton.IProps> = ({
    onClick,
    type,
    styleType,
    size,
    block,
    icon,
    iconPosition,
    disabled,
    children,
    className,
    cy,
}) => {
    return (
        <Container
            type={type || "button"}
            block={block}
            size={size}
            disabled={disabled}
            styleType={styleType}
            onClick={onClick}
            className={className}
            data-cy={cy}
        >
            {icon && iconPosition === "left" && icon}
            <Text elementType="span">{children}</Text>
            {icon && iconPosition === "right" && icon}
        </Container>
    );
};
