// #region Global Imports
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Pusher, { Channel } from "pusher-js";
import { useRouter } from "next/router";
// #endregion Global Imports

// #region Local Imports
import { MembershipType } from "@Interfaces/enums";
import { ActionConsts, PusherSerializer } from "@Definitions";
import { InboxActions, DashboardActions, ProfileActions, OfferActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { IStore, PhotoModel, PaymentModel } from "@Interfaces";
import { IPusherComponent } from "./PusherComponent";
// #endregion Local Imports

export const PusherComponent: React.FunctionComponent<IPusherComponent.IProps> = () => {
    const channelName = useSelector((state: IStore) => state.common.pusherChannel);

    const channel = React.useRef<Channel>();
    const publicChannel = React.useRef<Channel>();
    const dispatch = useDispatch();
    const router = useRouter();

    const pusherClient = new Pusher("2c3b42393ad348bdc837", { cluster: "mt1" });
    pusherClient.disconnect();
    React.useEffect(() => {
        if (!channelName) return;
        if (channel.current?.subscribed) return;
        if (publicChannel.current?.subscribed) return;

        if (pusherClient.allChannels().length > 0) return;

        pusherClient.disconnect();
        pusherClient.connect();

        channel.current = pusherClient.subscribe(channelName);
        publicChannel.current = pusherClient.subscribe("putmeuphere");

        channel.current.unbind_all();
        channel.current.unbind_global();

        publicChannel.current.unbind_all();
        publicChannel.current.unbind_global();

        channel.current.bind("messagesend", (data: IPusherComponent.MessageSendData) => {
            const messageData = {
                Username: data.Username,
                UserId: data.From,
                Photo: data.Photo.Url,
                Type: "message",
            };

            (async () => {
                dispatch({
                    type: ActionConsts.Common.SetNotification,
                    payload: {
                        ...messageData,
                    },
                });
                dispatch(await InboxActions.FetchNewMessage(data));
            })();

            dispatch({
                type: ActionConsts.Notification.GetNotificationCounts,
                payload: PusherSerializer.MessageToUnreadCounts(data),
            });

            if (data.IsRequest) {
                if (router.query.slug === "requests") {
                    (async () => {
                        dispatch(await InboxActions.PushNewRequests(data));
                    })();
                }
            }
        });

        channel.current.bind("readconversation", (data: IPusherComponent.ReadConversationData) => {
            (async () => {
                dispatch(await InboxActions.FetchMessageRead(data.With));
            })();
        });

        channel.current.bind("payment", (data: PaymentModel.SubscriptionSuccessResponse) => {
            if (data.Type === "Card3ds") {
                switch (data.Result) {
                    case "Fail":
                        dispatch({
                            payload: {
                                subscriptions: {
                                    error: data,
                                },
                            },
                            type: ActionConsts.Payment.Subscriptions.Failed,
                        });
                        break;

                    case "Success":
                        dispatch(ProfileActions.SetMembershipType(MembershipType.gold));
                        dispatch({
                            payload: {
                                subscriptions: {
                                    data,
                                },
                            },
                            type: ActionConsts.Payment.Subscriptions.Success,
                        });
                        break;

                    default:
                        break;
                }
            }
        });

        channel.current.bind("probation", () => {
            (async () => {
                dispatch({
                    type: ActionConsts.Profile.SeenProbation,
                    payload: {
                        InProbation: true,
                    },
                });
            })();
        });

        channel.current.bind("unreadconvos", (data: IPusherComponent.UnreadConvosData) => {
            (async () => {
                dispatch({
                    type: ActionConsts.Notification.GetNotificationCounts,
                    payload: {
                        NewRequestCount: data.UnreadRequest,
                        NewChatsCount: data.UnreadDialogs,
                        NewPrioritizedCount: data.UnreadPrioritized,
                    },
                });
            })();
        });

        publicChannel.current.bind("putmeuphere", (data: IPusherComponent.PutMeUpHereData) => {
            (async () => {
                dispatch(await DashboardActions.AddPutMeUpHere(data));
            })();
        });

        channel.current.bind("wink", (data: IPusherComponent.NewWinkData) => {
            const winkData = {
                Username: data.Username,
                UserId: data.UserId,
                Photo: data.Photo.Url,
                Type: "wink",
            };

            (async () => {
                dispatch({
                    type: ActionConsts.Notification.GetNotificationCounts,
                    payload: {
                        NewWinkCount: data.Count,
                    },
                });
                dispatch({
                    type: ActionConsts.Common.SetNotification,
                    payload: {
                        ...winkData,
                    },
                });
            })();
        });
        channel.current.bind("friend", (data: IPusherComponent.NewFriendData) => {
            const addFriendData = {
                Username: data.Username,
                UserId: data.UserId,
                Photo: data.Photo.Url,
                Type: "friend",
            };

            (async () => {
                dispatch({
                    type: ActionConsts.Notification.GetNotificationCounts,
                    payload: {
                        NewFriendCount: data.Count,
                    },
                });
                dispatch({
                    type: ActionConsts.Common.SetNotification,
                    payload: {
                        ...addFriendData,
                    },
                });
            })();
        });

        channel.current.bind("like", (data: IPusherComponent.NewWinkData) => {
            const likeData = {
                Username: `${data.Username.charAt(0)}*****`,
                UserId: data.UserId,
                Photo: data.Photo.Url,
                Type: "like",
            };

            (async () => {
                dispatch({
                    type: ActionConsts.Notification.GetNotificationCounts,
                    payload: {
                        NewLikeCount: data.Count,
                    },
                });
                dispatch({
                    type: ActionConsts.Common.SetNotification,
                    payload: {
                        ...likeData,
                    },
                });
            })();
        });

        channel.current.bind("profileView", (data: IPusherComponent.NewProfileViewData) => {
            const profileViewData = {
                Username: data.Username,
                UserId: data.UserId,
                Photo: data.Photo.Url,
                Type: "profileView",
            };

            (async () => {
                dispatch({
                    type: ActionConsts.Notification.GetNotificationCounts,
                    payload: {
                        NewProfileViewCount: data.Count,
                    },
                });
                dispatch({
                    type: ActionConsts.Common.SetNotification,
                    payload: {
                        ...profileViewData,
                    },
                });
            })();
        });

        channel.current.bind("photo", (data: PhotoModel.IPhoto) => {
            (async () => {
                dispatch(await ProfileActions.AddPhoto(data));
            })();
        });

        channel.current.bind("offer", () => {
            (async () => {
                dispatch(await OfferActions.GetOffer());
            })();
        });

        channel.current.bind("coin", (data: IPusherComponent.NewCoinData) => {
            dispatch({
                payload: data.Coin,
                type: ActionConsts.Common.SetCoin,
            });
        });

        // eslint-disable-next-line consistent-return
        return () => {
            pusherClient.disconnect();
            pusherClient.unbind_all();
            pusherClient.unbind_global();

            pusherClient.unsubscribe(channelName);
            pusherClient.unsubscribe("putmeuphere");

            channel?.current?.unbind_all();
            channel?.current?.unbind_global();

            publicChannel?.current?.unbind_all();
            publicChannel?.current?.unbind_global();
        };
    }, [channelName]);

    return <div />;
};
