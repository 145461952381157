import * as React from "react";

const SvgHeart = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 20 18" {...props}>
        <path
            d="M18.281 1.748c1.094 1.063 1.64 2.354 1.719 3.797 0 .987-.156 1.974-.547 2.886a8.513 8.513 0 01-1.015 2.05c-.313.455-.782.987-1.407 1.746l-.312.304c-.782.911-2.969 2.733-6.407 5.24-.156.075-.234.075-.39.075s-.235 0-.39-.076c-3.516-2.581-5.626-4.328-6.407-5.24l-.313-.303c-.624-.76-1.015-1.29-1.406-1.746-.312-.456-.625-1.14-.937-2.05A8.944 8.944 0 010 5.545c0-1.519.547-2.734 1.64-3.797C2.735.685 4.064.154 5.548.154c1.797 0 3.281.683 4.375 2.126C11.016.913 12.5.154 14.297.154c1.562 0 2.89.531 3.984 1.594h0z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgHeart;
