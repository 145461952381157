// #region Global Imports
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { ApiStatus, MembershipType } from "@Interfaces/enums";
import { IStore } from "@Redux/IStore";
import {
    AddPhoto,
    BecomeGoldMember,
    BuyCredits,
    FreeCredits,
    Offer,
    TryGold,
    DashBoardWidgetSkeleton,
} from "@Components";
import { PaymentActions, CommonActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { RedirectPayment, ActionConsts } from "@Definitions";
import { IDashboardWidget } from "./DashboardWidget";
// #endregion Interface Imports

export const DashboardWidget: React.FunctionComponent<IDashboardWidget.IProps> = ({ t }) => {
    const dispatch = useDispatch();

    const offer = useSelector((state: IStore) => state.offer);
    const campaign = useSelector((state: IStore) => state.campaign);
    const subscriptions = useSelector((state: IStore) => state.payment.subscriptions);
    const profileSelf = useSelector((state: IStore) => state.profile.self);
    const common = useSelector((state: IStore) => state.common);

    // buy standard offer
    const buyOffer = async () => {
        if (campaign.data && campaign.data.length > 0) {
            // select offer campaign
            dispatch(PaymentActions.SetSelectedCampaign(campaign.data[0]));
            // redirect payment screen
            await RedirectPayment("offer");
        }
    };

    // activate gift gold membership offer
    const activateGiftMemberShip = async () => {
        dispatch(
            await PaymentActions.Subscription(
                {
                    PaymentMethod: 3,
                    CampaignId: offer.data!.CampaignId,
                },
                "Payment Widget",
                offer.data!.HeaderText
            )
        );
    };

    // listen try gold membership is success
    const [tryGoldIsSuccess, setTryGoldIsSuccess] = useState(false);
    useEffect(() => {
        if (subscriptions.status === ApiStatus.loaded) {
            setTryGoldIsSuccess(true);
        }
    }, [subscriptions.status]);

    if (ApiStatus.loading >= offer.status) {
        return <DashBoardWidgetSkeleton />;
    }

    // check offer data
    if (offer.data) {
        const offerData = offer.data;

        // offer is gift
        if (offerData.PaymentMethods === "Gift") {
            // try gold package activated
            if (tryGoldIsSuccess) {
                return (
                    <TryGold
                        t={t}
                        isSuccess
                        title={t("dashboard:Widgets.TryGold.success.title")}
                        description={t("dashboard:Widgets.TryGold.success.description")}
                    />
                );
            }

            return (
                <TryGold
                    t={t}
                    description={offerData.HeaderText}
                    onClickActivatedButton={activateGiftMemberShip}
                    disabledActivatedButton={
                        ApiStatus.init !== subscriptions.status &&
                        ApiStatus.loading >= subscriptions.status
                    }
                />
            );
        }

        // offer standard
        return (
            <Offer
                t={t}
                image={offerData.ImageUrl}
                endDate={dayjs(offerData.EndDate)}
                description={offerData.HeaderText}
                onClickBuyButton={buyOffer}
            />
        );
    }

    // gold membership
    if (profileSelf?.MembershipType === MembershipType.gold) {
        // upload profile photo
        if (profileSelf.Photo.Id === null) {
            return (
                <AddPhoto
                    t={t}
                    onClickAddPhotoButton={async () =>
                        dispatch(await CommonActions.SetShowUploadPhoto(true))
                    }
                />
            );
        }

        // coin
        if (common.coin < 600) {
            return <BuyCredits t={t} />;
        }

        return (
            <FreeCredits
                t={t}
                onClickButton={() =>
                    dispatch({
                        type: ActionConsts.Common.SetWinCreditModal,
                        payload: true,
                    })
                }
            />
        );
    }

    return <BecomeGoldMember t={t} />;
};
