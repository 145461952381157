// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { ProfileActions } from "@Actions";
import { Button, FormBox, CustomSliderRange } from "@Components";
// #endregion Local Imports

// #region Interface Imports
import { IAgeRange } from "./AgeRange";
// #endregion Interface Imports

export const AgeRange: React.FunctionComponent<IAgeRange.IProps> = ({
    t,
    onCloseClick,
    ageMin,
    ageMax,
}) => {
    const dispatch = useDispatch();
    const { useState } = React;
    const [max, setMax] = useState(ageMax);
    const [min, setMin] = useState(ageMin);

    const setCurrentValues = (from: number, to: number) => {
        setMax(to);
        setMin(from);
    };

    const saveAgeRange = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const payload = {
            lookingForMinAge: min,
            lookingForMaxAge: max,
        };

        dispatch(await ProfileActions.UpdateProfileInfo(payload));
    };

    return (
        <FormBox
            title={t("common:profileEdit.infoModal.ageRange.title")}
            onHandleSubmit={saveAgeRange}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">
                <CustomSliderRange
                    rangeFrom={ageMin}
                    rangeTo={ageMax}
                    sendValues={setCurrentValues}
                />
            </div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
