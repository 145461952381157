// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { BlockButton } from "@Components/BlockButton";
import { GrayGradientDiv } from "../../styled";
// #endregion Local Imports

export const Container = styled(GrayGradientDiv)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 77px;
    padding-bottom: 85px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding: 163px 0;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        padding: 62px 0;
    }
`;

export const Top = styled.div`
    width: 190px;

    span {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 16px;
        color: ${({ theme }) => theme.colors.darkGray};
        line-height: 20px;
    }
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: 16px;
        color: ${({ theme }) => theme.colors.red};
        line-height: 20px;
    }

    span:nth-child(2) {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 36px;
        line-height: 44px;
    }
`;

export const PurchaseButton = styled(BlockButton)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: #ffffff;
    letter-spacing: -0.38px;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    padding: 0 22px;
    height: 32px;
`;

PurchaseButton.displayName = "PurchaseButton";
