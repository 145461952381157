import { theme } from "@Definitions/Styled";

export const mobileModalStyle = {
    overlay: {
        backgroundColor: theme.colors.transparentBlack,
    },
    content: {
        padding: 0,
        top: "unset",
        left: "unset",
        right: "unset",
        bottom: "unset",
        marginRight: "unset",
        transform: "unset",
        minWidth: "unset",
        display: "flex",
        width: "100%",
        height: "100%",
    },
};
export const modalStyle = {
    overlay: {
        backgroundColor: theme.colors.transparentBlack,
    },
    content: {
        padding: "0px",
        border: "unset",
        minWidth: 0,
    },
};
