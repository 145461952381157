// #region Global Imports
import React, { useState, useEffect } from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./PutMeUpHere.container";
import { Skeleton } from "./PutMeUpHere.skeleton";
import { IPutMeUpHere } from "./PutMeUpHere";
// #endregion Local Imports

export const PutMeUpHere: React.FunctionComponent<IPutMeUpHere.IProps> = (props): JSX.Element => {
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
    }, []);

    if (!didMount) return <Skeleton />;

    return <Container {...props} />;
};
