import * as React from "react";

const SvgRightArrow = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 15 24" {...props}>
        <path
            d="M14.085 12.067c0 .268-.135.402-.27.536L2.644 23.732c-.135.134-.404.268-.539.268-.134 0-.404-.134-.538-.268L.354 22.525c-.134-.134-.27-.402-.27-.536 0-.268.136-.402.27-.536l9.423-9.52L.354 2.547c-.134-.134-.27-.402-.27-.536s.136-.268.27-.536L1.566.268C1.7.134 1.97 0 2.104 0c.135 0 .404.134.539.268l11.173 11.129c.134.402.269.536.269.67z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgRightArrow;
