import * as React from "react";

const SvgLock = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 28 41" {...props}>
        <path
            d="M14 .296a6.75 6.75 0 016.75 6.75h0v8.718a13.495 13.495 0 016.75 11.693c0 7.456-6.044 13.5-13.5 13.5S.5 34.913.5 27.457c0-4.997 2.715-9.36 6.75-11.694V7.046A6.75 6.75 0 0114 .296zm0 15.161c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6.75a3 3 0 011.5 5.598v3.402a1.5 1.5 0 01-3 0v-3.401a3 3 0 011.5-5.599zm0-20.411a5.25 5.25 0 00-5.25 5.25h0v7.97A13.458 13.458 0 0114 13.957c1.862 0 3.637.377 5.25 1.06V7.045c0-2.9-2.35-5.25-5.25-5.25z"
            fill="#FFF"
            stroke="#fff"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgLock;
