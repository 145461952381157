// #region Global Imports
import styled from "styled-components";
import { Dropdown } from "@Components/Basic";
// #endregion Global Imports

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const DropdownGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 280px;
`;

export const SelectDay = styled(Dropdown)`
    width: 78px;
    margin-right: 15px;
`;

export const SelectMonth = styled(Dropdown)`
    width: 78px;
    margin-right: 15px;
`;

export const SelectYear = styled(Dropdown)`
    width: 86px;
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.red};
    font-family: "Montserrat";
    font-size: 10px;
`;

ErrorMessage.displayName = "ErrorMessage";
Wrapper.displayName = "BirthdayPicker";
