// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IIyzicoCard } from "./IyzicoCard";
// #endregion Interface Imports

export const IyzicoCard: React.FunctionComponent<IIyzicoCard.IProps> = ({
    t,
    className,
    customStyle,
}) => {
    const text = t("common:payment.paymentTypes.creditCard.payment.iyzico.badge");
    return (
        <Container className={className} customStyle={customStyle}>
            <img alt="iyzico" src="/images/iyzico.png" />
            <Text
                color="white"
                weight="bold"
                dangerouslySetInnerHTML={{ __html: text }}
                className="text-center"
            />
        </Container>
    );
};
