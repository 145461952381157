// #region Global Imports
import * as React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { IBlockButton } from "./BlockButton";
// #endregion Local Imports

const Container = styled(Button)`
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.38px;
    background: ${({ theme, disabled }) =>
        disabled ? theme.colors.disabled : theme.gradients.greenToBlue};
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    height: 52px;
    border-radius: 26px;
    text-align: center;
    color: #fff;
    display: block;
    padding: 0 44px;
    border: none;

    :hover {
        background: ${({ theme, disabled }) => (disabled ? "" : theme.gradients.greenToBlueHover)};
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 19px;
    }
`;

export const BlockButton: React.FunctionComponent<IBlockButton.IProps> = props => {
    return <Container {...props} />;
};
