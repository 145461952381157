// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div``;

export const MidSection = styled.div`
    display: flex;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        flex-direction: column;
    }
`;

export const ContactInfoBox = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    justify-self: flex-end;
    border-radius: 12px;
`;

ContactInfoBox.displayName = "ContactInfoBox";
