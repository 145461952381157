// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic/RectangleSkeleton";
import { Container } from "./styled";
// #endregion Local Imports

interface Props {}

export const NotificationItemSkeleton: React.FC<Props> = () => (
    <Container>
        <RectangleSkeleton width="300" height="24" />
    </Container>
);
