import React from "react";
import ContentLoader from "react-content-loader";
import { useScreenSize } from "@Definitions";

export const ChatSectionSkeleton: React.FC = () => {
    const screenSize = useScreenSize();
    const isDesktop = screenSize.match("large");
    const isTablet = screenSize.match("medium");

    const calculateWidth = () => {
        switch (screenSize) {
            case "xlarge":
                return 550;
            case "large":
                return 440;
            case "medium":
                return 410;
            default:
                return 550;
        }
    };
    const calculateHeight = () => {
        switch (screenSize) {
            case "xlarge":
                return 590;
            case "large":
                return 510;
            case "medium":
                return 500;
            default:
                return 550;
        }
    };

    const calculateViewbox = () => {
        switch (screenSize) {
            case "xlarge":
                return "0 0 645 630";
            case "large":
                return "0 0 625 650";
            case "medium":
                return "0 0 600 600";
            default:
                return "0 0 645 610";
        }
    };

    return (
        <div className="chat-section col-lg-8 col-md-8">
            {(isDesktop || isTablet) && (
                <ContentLoader
                    speed={2}
                    width={calculateWidth()}
                    height={calculateHeight()}
                    viewBox={calculateViewbox()}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <circle cx="510" cy="20" r="15" />
                    <circle cx="545" cy="20" r="15" />
                    <circle cx="580" cy="20" r="15" />

                    <rect x="0" y="110" rx="12" ry="10" width="130" height="40" />
                    <rect x="0" y="110" rx="12" ry="10" width="130" height="40" />
                    <rect x="0" y="190" rx="12" ry="10" width="100" height="40" />
                    <rect x="480" y="270" rx="12" ry="12" width="120" height="40" />
                    <rect x="480" y="350" rx="12" ry="12" width="120" height="40" />
                    <rect x="0" y="400" rx="12" ry="12" width="600" height="198" />
                </ContentLoader>
            )}
        </div>
    );
};
