import * as React from "react";

const SvgPiscesIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 12 13" {...props}>
        <path
            d="M.035 6.165h2.508C2.457 4.227 1.981 2.465 1.03.88h1.297c.908 1.542 1.384 3.304 1.47 5.286h3.46c.086-1.982.562-3.7 1.47-5.286h1.297c-.908 1.586-1.427 3.348-1.513 5.286h2.508v1.102H8.51c.043 1.013.216 1.982.519 2.907.302.925.692 1.762 1.21 2.6H8.9a9.097 9.097 0 01-1.168-2.556 12.753 12.753 0 01-.475-2.907h-3.46c-.086 2.07-.605 3.92-1.6 5.462H.857c.519-.837.908-1.674 1.21-2.599.303-.925.476-1.85.52-2.907H.077V6.165H.035z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgPiscesIcon;
