// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Button } from "@Components/Basic";
import { Container, Modal, ModalContent, Overlay } from "./styled";
import { IConfirmCreditModal } from "./ConfirmCreditModal";
// #endregion Local Imports

export const ConfirmCreditModal: React.FC<IConfirmCreditModal.IProps> = ({
    t,
    children,
    coin,
    isOpen,
    onClick,
    onClickOverlay,
}): JSX.Element => {
    return (
        <Container className="mr-2">
            {children}
            {isOpen && (
                <>
                    <Overlay onClick={onClickOverlay} />
                    <Modal>
                        <ModalContent>
                            <Text className="mb-4" weight="bold">
                                {t("common:ConfirmCreditModal:description", {
                                    coin,
                                })}
                            </Text>
                            <Button className="text-uppercase" onClick={onClick}>
                                {t("common:ConfirmCreditModal.confirmButton")}
                            </Button>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </Container>
    );
};
