// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: "Montserrat";
    display: flex;
    padding: 12px;
    border-radius: 12px;
    margin: 9px 0;
    width: 714px;
    align-items: center;
    cursor: pointer;

    :hover {
        background-color: ${({ theme }) => theme.colors.lightBlue};
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 594px;
        padding: 10px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        align-items: flex-start;
        width: 374px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 494px;
        padding: 14px 20px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 329px;
        padding: 5px 10px 0 10px;
    }
`;

export const ContainerLeft = styled.div`
    width: 142px;
    height: 142px;
    display: flex;
    align-items: center;
    margin-right: 30px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 112px;
        align-items: flex-start;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 88px;
        height: 120px;
        margin-right: 14px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 122px;
        height: 122px;
        margin-right: 29px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 88px;
        height: 88px;
        margin: 13px 20px 18px 0;
    }
`;

export const AvatarStyle = css`
    width: 142px;
    height: 142px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 112px;
        height: 112px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 88px;
        height: 88px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 122px;
        height: 122px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 88px;
        height: 88px;
    }
`;

export const ContainerRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RightTop = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 30px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        height: 40px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column-reverse;
        height: 30px;
    }
`;

export const NameAge = styled.div`
    align-self: flex-end;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        font-size: 14px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        align-self: flex-start;
    }
`;

export const DateInfo = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray};
    letter-spacing: -0.15px;
    text-align: right;

    .span-wrapper {
        .date {
            display: none;
        }

        :hover {
            .desc {
                display: none;
            }

            .date {
                display: inline-block;
            }
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        font-size: 9px;
        letter-spacing: -0.14px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        align-self: flex-end;
    }
`;

export const CustomBubble = css`
    background: ${({ theme }) => theme.colors.sliderAqua};
    margin-top: 10px;
    margin-right: 0 !important;
    max-width: 515px;
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-style: italic;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        max-width: 430px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        display: none;
    }

    .info-bubble-text {
        div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    :after {
        all: unset;
        content: "";
        position: absolute;
        top: 8px;
        left: 20px;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-bottom-color: ${({ theme }) => theme.colors.sliderAqua};
        border-top: 0;
        border-left: 0;
        margin-left: -10px;
        margin-top: -20px;
    }
`;

export const InfoDiv = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 370px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        max-width: 300px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        max-width: 250px;
        margin-bottom: 8px;
        margin-top: 4px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        max-width: 190px;
        margin-top: 4px;
        margin-bottom: 6px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        max-width: 190px;
        margin-top: 2px;
        margin-bottom: 5px;
    }

    .icon-wrapper {
        margin-right: 7px;
        max-height: 16px;
        max-width: 16px;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            display: none;
        }
    }

    div:last-child {
        span::after {
            content: "";
        }
    }

    div {
        display: flex;
        align-items: center;
        height: 24px;
        margin-right: 18px;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            margin-right: 4px;
            height: auto;

            span::after {
                content: ", ";
            }
        }

        span {
            font-weight: ${({ theme }) => theme.fontWeights.medium};
            font-size: 12px;
            color: ${({ theme }) => theme.colors.mediumGray};

            @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
                font-size: 10px;
            }

            @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
                font-size: 9px;
                line-height: 12px;
            }
        }
    }
`;

export const InfoIcon = styled.i<{ colorType: "zodiac" | "status" | "job" | "location" }>`
    font-size: ${({ colorType }) => (colorType === "job" ? "12px" : "16px")};

    color: ${({ colorType, theme }) =>
        colorType === "zodiac"
            ? theme.colors.zodiacPurple
            : colorType === "location"
            ? theme.colors.sliderAqua
            : colorType === "status"
            ? theme.colors.red
            : colorType === "job" && theme.colors.orange};
`;

export const StyledIcon = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    align-items: center;
    display: flex;
    border-radius: 50%;

    :hover {
        color: ${({ theme }) => theme.colors.green};
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const IconButtons = styled.div`
    width: 96px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

export const RightBottom = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: column;
    }
`;
