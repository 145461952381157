// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, HiddenCheckbox, Box, ErrorText } from "./styled";
import { ICheckbox } from "./Checkbox";
// #endregion Local Imports

const Checkbox: React.FunctionComponent<ICheckbox.IProps> = (props): JSX.Element => {
    const {
        disabled = false,
        checked = false,
        isValid,
        errorText,
        _ref,
        children,
        cy,
        ...otherProps
    } = props;

    return (
        <Container>
            <HiddenCheckbox
                data-cy={cy}
                checked={checked}
                type="checkbox"
                ref={_ref}
                {...otherProps}
            />
            <Box checked={checked} disabled={disabled}>
                <img alt="check" src={`/icons/check${disabled ? "-disabled" : ""}.svg`} />
            </Box>
            <div>
                {children}
                <ErrorText>{isValid === false && errorText}</ErrorText>
            </div>
        </Container>
    );
};

export { Checkbox };
