// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    border: 1px solid #dde2e9;
    padding: 18px 15px 11px;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        padding: 12px 64px 12px 24px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        :after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            width: 45px;
            height: 100%;
            right: 15px;
            background-image: ${({ theme }) => theme.gradients.whiteShadowPhotoSliderRgpLeft};
            pointer-events: none;
            border-radius: 15px;
        }
    }

    ${({ isOpen }) => ({
        maxHeight: isOpen ? "800px" : "60px",
        transition: `max-height 0.3s ${isOpen ? "ease-in" : "ease-out"}`,
    })};
`;

export const TabContent = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
`;

export const InboxFilterContent = styled.div<{ isOpen: boolean }>`
    padding: 20px 0px;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    max-height: ${({ isOpen }) => (isOpen ? " 800px;" : " 50px;")};
    transform-origin: top;
    transition: all 0.4s linear;

    ${({ isOpen }) => ({ zIndex: isOpen ? 1 : -1 })}
`;

export const BadgeContainer = styled.div`
    display: flex;
    align-items: baseline;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 100%;
        justify-content: space-between;
    }
`;

export const TitleText = styled.div<{ isActive: boolean }>`
    display: flex;
    font-family: "Montserrat";
    letter-spacing: 0.4px;
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ isActive, theme }) => (isActive ? theme.colors.gray : theme.colors.lightGray)};

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-right: 15px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        margin-right: 11px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    cursor: pointer;
    margin-right: 30px;

    i {
        align-self: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-right: 0;
    }
`;

export const FilterButton = styled.div<{ isOpen: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;

    > * {
        color: ${({ isOpen, theme }) => (isOpen ? theme.colors.sliderDarkBlue : theme.colors.gray)};
    }
`;

export const FilterCount = styled.div`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray};
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
`;

export const FilterButtonText = styled.span`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
`;
