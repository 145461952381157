// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import {
    Container,
    AvatarContent,
    Buttons,
    InfoContent,
    InfoContentItem,
    IconContainer,
} from "./styled";
// #endregion Local Imports

export const ProfileViewListItemSkeleton: React.FunctionComponent = () => (
    <Container>
        <AvatarContent>
            <RectangleSkeleton width="96" height="96" />
        </AvatarContent>
        <InfoContent>
            <InfoContentItem>
                <RectangleSkeleton width="120" height="10" />
            </InfoContentItem>
            <InfoContentItem>
                <RectangleSkeleton width="120" height="10" />
            </InfoContentItem>
            <InfoContentItem>
                <RectangleSkeleton width="120" height="10" />
            </InfoContentItem>
            <Buttons>
                <div className="d-flex">
                    <IconContainer>
                        <RectangleSkeleton width="40" height="40" />
                    </IconContainer>
                    <IconContainer>
                        <RectangleSkeleton width="40" height="40" />
                    </IconContainer>
                    <IconContainer>
                        <RectangleSkeleton width="40" height="40" />
                    </IconContainer>
                </div>
            </Buttons>
        </InfoContent>
    </Container>
);
