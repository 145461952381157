// #region Global Imports
import React, { useState, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic/IconButton";
import { Gender as GenderType } from "@Interfaces/enums";
import { InteractionActions, InboxActions } from "@Actions";
import { InteractionService } from "@Services";
import { IProfileInteractionsButtonContainer } from "./ProfileInteractionsButtonContainer";
import {
    WinkedIcon,
    WinkIcon,
    WomanRemoveFriendIcon,
    ManRemoveFriendIcon,
    WomanAddedFriendIcon,
    ManAddedFriendIcon,
    WomanAddFriendIcon,
    ManAddFriendIcon,
    CircleConversationIcon,
} from "@Icons";
import {
    Container,
    CustomIconStyle,
    RedIconStyle,
    CustomGreenIconStyle,
    GreenIconStyle,
    Hover,
    NoHover,
    OnHover,
} from "./styled";
// #endregion Local Imports

const ProfileInteractionsButtonContainer: FC<IProfileInteractionsButtonContainer.IProps> = ({
    userId,
    Gender,
    IsFriend,
    customStyle,
    inConversation,
    deactiveAddFriend,
}): JSX.Element => {
    const dispatch = useDispatch();

    // ? `isWinked` and `added` are used optimistically, saying that
    // ? these are NOT direct bindings of API responses. Instead,
    // ? managed manually without waiting any response.
    const [isWinked, setIsWinked] = useState(false);
    const [added, setAdded] = useState(IsFriend);

    useEffect(() => {
        setIsWinked(false);
        setAdded(IsFriend);
    }, [userId, IsFriend]);

    const handleHover = (onHover: JSX.Element, noHover: JSX.Element) => (
        <Hover>
            <NoHover>{noHover}</NoHover>
            <OnHover>{onHover}</OnHover>
        </Hover>
    );

    const onWinkButtonClick = async (id: string) => {
        dispatch(await InteractionActions.Wink(id, "profile view"));
        setIsWinked(true);
    };

    const onAddFriendButtonClick = async (id: string) => {
        if (added) {
            dispatch(await InteractionActions.RemoveFriend(id));
        } else {
            dispatch(await InteractionActions.AddFriend(id, "profile view"));
        }

        setAdded(val => !val);
    };

    const onChatButtonClick = async (id: string) => {
        dispatch(await InboxActions.ShowChatModal(id, 50));
    };

    const createFriendIconButton = (onHover: boolean): JSX.Element => {
        const genderType = Gender === GenderType.female ? "woman" : "man";

        let iconComponent: JSX.Element;

        if (onHover) {
            if (added) {
                iconComponent = (
                    <RedIconStyle>
                        {genderType === "woman" ? (
                            <WomanRemoveFriendIcon width="39px" height="54px" />
                        ) : (
                            <ManRemoveFriendIcon width="39px" height="54px" />
                        )}
                    </RedIconStyle>
                );
            } else {
                iconComponent = (
                    <GreenIconStyle>
                        {genderType === "woman" ? (
                            <WomanAddedFriendIcon width="39px" height="54px" />
                        ) : (
                            <ManAddedFriendIcon width="39px" height="54px" />
                        )}
                    </GreenIconStyle>
                );
            }
        } else if (added) {
            iconComponent = (
                <GreenIconStyle>
                    {genderType === "woman" ? (
                        <WomanAddedFriendIcon width="39px" height="54px" />
                    ) : (
                        <ManAddedFriendIcon width="39px" height="54px" />
                    )}
                </GreenIconStyle>
            );
        } else {
            iconComponent = (
                <CustomIconStyle>
                    {genderType === "woman" ? (
                        <WomanAddFriendIcon width="39px" height="54px" />
                    ) : (
                        <ManAddFriendIcon width="39px" height="54px" />
                    )}
                </CustomIconStyle>
            );
        }

        return (
            <IconButton
                renderIcon={() => iconComponent}
                onIconClick={() => {
                    onAddFriendButtonClick(userId);
                }}
            />
        );
    };

    return (
        <Container customStyle={customStyle}>
            {handleHover(
                <IconButton
                    renderIcon={() => (
                        <GreenIconStyle>
                            <WinkedIcon width="36px" height="54px" />
                        </GreenIconStyle>
                    )}
                    onIconClick={() => onWinkButtonClick(userId)}
                />,
                <IconButton
                    renderIcon={() =>
                        isWinked ? (
                            <GreenIconStyle>
                                <WinkedIcon width="36px" height="54px" />
                            </GreenIconStyle>
                        ) : (
                            <CustomIconStyle>
                                <WinkIcon width="36px" height="54px" />
                            </CustomIconStyle>
                        )
                    }
                    onIconClick={() => onWinkButtonClick(userId)}
                />
            )}
            {!inConversation && (
                <IconButton
                    renderIcon={() => (
                        <CustomGreenIconStyle>
                            <CircleConversationIcon width="50px" height="51px" />
                        </CustomGreenIconStyle>
                    )}
                    onIconClick={() => onChatButtonClick(userId)}
                />
            )}
            {!deactiveAddFriend &&
                handleHover(createFriendIconButton(true), createFriendIconButton(false))}
        </Container>
    );
};

export { ProfileInteractionsButtonContainer };
