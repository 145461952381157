// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const customLabel = css`
    font-size: 20px;
`;

export const SolidBlock = styled.div`
    display: block;
    width: 100%;
`;

export const Item = styled.div<{ stagger?: number }>`
    opacity: 0;
    animation: enter 0.6s ease forwards;
    animation-delay: ${({ stagger: s }) => `${s}ms`};

    @keyframes enter {
        from {
            opacity: 0;
            transform: translateY(48px);
        }
        to {
            opacity: 1;
        }
    }

    img {
        width: 100%;
    }
`;

export const FluidBlock = styled.div`
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    width: 100%;

    > div {
        margin: 32px auto;
    }

    [class*="BlockButton"] {
        margin: 32px 0;
        text-transform: uppercase;
        background-image: none;
        color: ${({ theme }) => theme.colors.azureRadianceDark};
    }
`;
