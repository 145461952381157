// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { SmartSlider } from "@Components";
import {
    Container,
    Image,
    PhotoFrame,
    customSliderCss,
    CloseIcon as CloseIconContainer,
    OverlayText,
    Text,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IUploadPhotoSlider } from "./UploadPhotoSlider";
import { useScreenSize, getCustomSliderConfig, theme } from "@Definitions";
import { CloseIcon } from "@Icons";
import { PhotoModel } from "@Interfaces";
// #endregion Interface Imports

export const UploadPhotoSlider: React.FunctionComponent<IUploadPhotoSlider.IProps> = ({
    getSelectedPhoto,
    onRemovePhoto,
    photos,
    t,
}) => {
    const screenType = useScreenSize();

    const customSliderConfig = getCustomSliderConfig(screenType, {
        xsmall: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: false,
            dots: false,
        },
        small: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: false,
            dots: false,
        },
        medium: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: false,
            dots: false,
        },
        large: {
            slidesToShow: 6,
            slidesToScroll: 6,
            infinite: false,
            dots: false,
        },
        xlarge: {
            slidesToShow: 6,
            slidesToScroll: 6,
            infinite: false,
            dots: false,
        },
        common: {
            hide: false,
        },
    });

    const getUniqueKey = (id: string) => `photoUpload--${id}`;

    const approved: PhotoModel.IPhoto[] = [];
    const unapproved: PhotoModel.IPhoto[] = [];
    const deleted: PhotoModel.IPhoto[] = [];

    photos?.forEach((item: PhotoModel.IPhoto) => {
        switch (item.State) {
            case "Approved":
                approved.push(item);
                break;
            case "Unapproved":
                unapproved.push(item);
                break;
            case "Deleted":
                deleted.push(item);
                break;
            default:
                break;
        }
    });

    const sortedPhotos = [...approved, ...unapproved, ...deleted];

    const slidePhotos = sortedPhotos.map(item => (
        <PhotoFrame onClick={() => getSelectedPhoto(item)} key={getUniqueKey(item.CreateDate)}>
            <CloseIconContainer onClick={() => onRemovePhoto(item.Id)}>
                <CloseIcon width="7px" color={theme.colors.gray} className="mt-1" />
            </CloseIconContainer>
            <Image src={`${item.Url}?&preset=siberthumb`} />
            {item.State !== "Approved" && (
                <>
                    <OverlayText />
                    <Text stateType={item.State}>
                        {item.State === "Deleted"
                            ? t("common:photoUpload.rejected")
                            : t("common:photoUpload.waitingApprove")}
                    </Text>
                </>
            )}
        </PhotoFrame>
    )) as Array<React.ReactElement>;

    return (
        <Container>
            <SmartSlider
                {...customSliderConfig}
                slides={slidePhotos}
                customSliderCss={customSliderCss}
            />
        </Container>
    );
};
