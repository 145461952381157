// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IHoverToolTip } from "./HoverToolTip";
// #endregion Interface Imports

export const HoverToolTip: React.FunctionComponent<IHoverToolTip.IProps> = ({
    children,
    customStyle,
}) => {
    return <Container customStyle={customStyle}>{children}</Container>;
};
