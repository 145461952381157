import * as React from "react";

const SvgCircleCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm-2.828 11.343l2.827 2.828 2.83-2.828a2 2 0 112.828 2.829l-2.829 2.827 2.829 2.83a2 2 0 11-2.829 2.828L18 20.828l-2.827 2.829a2 2 0 11-2.829-2.829L15.171 18l-2.828-2.827a2 2 0 112.829-2.829z"
            fill="#FFF"
            stroke="#FFF"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCircleCloseIcon;
