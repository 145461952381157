import * as React from "react";

const SvgMenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm6 21a2 2 0 110 4H12a2 2 0 110-4h12zm0-6a2 2 0 110 4H12a2 2 0 110-4h12zm0-6a2 2 0 110 4H12a2 2 0 110-4h12z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgMenuIcon;
