// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    background: ${({ theme }) => theme.gradients.lightBlueVertical};
`;

export const SecurityItem = styled.div`
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.white};

    span {
        font-size: 16px;
    }

    svg {
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.3));
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        span {
            font-size: 12px;
        }
    }
`;

SecurityItem.displayName = "SecurityItem";
