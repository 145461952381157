// #region Global Imports
import * as React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    HandleContainer,
    HandleContent,
    HandleUnit,
    HandleValue,
    HandleWrapper,
    StartIcon,
} from "./styled";
import { ICustomSlider } from "./CustomSlider";
// #endregion Local Imports
import { SliderHandleDistance } from "@Icons";

const CustomSlider: React.FunctionComponent<ICustomSlider.IProps> = ({
    defaultValue,
    onChange,
}): JSX.Element => {
    const { useState, useEffect } = React;
    const minVal = 1;
    const maxVal = 30;
    const multiplier = 10;

    const [sliderValue, setSliderValue] = useState(
        parseInt((defaultValue / multiplier).toFixed(0), 10)
    );

    useEffect(() => {
        setSliderValue(parseInt((defaultValue / multiplier).toFixed(0), 10));
    }, [defaultValue]);

    const CustomHandle: React.FunctionComponent<{
        offset: number;
        value: number;
    }> = (props): JSX.Element => {
        const { value, offset } = props;
        const currentVal = value * multiplier;

        return (
            <HandleWrapper>
                <input
                    type="number"
                    name="customSlider"
                    min={minVal}
                    max={maxVal}
                    value={currentVal}
                    readOnly
                />
                <StartIcon value={value}>
                    <SliderHandleDistance width="12px" height="17px" />
                </StartIcon>
                <HandleContainer offset={offset}>
                    <HandleContent value={value}>
                        <div className="info">
                            <HandleValue>{currentVal === 300 ? "300+" : currentVal}</HandleValue>
                            <HandleUnit>KM</HandleUnit>
                        </div>
                        <SliderHandleDistance width="12px" height="17px" />
                    </HandleContent>
                </HandleContainer>
            </HandleWrapper>
        );
    };

    const onAfterChange = (value: number) => {
        const currentVal = value * multiplier;
        const distance = currentVal === 300 ? 10000 : currentVal;
        onChange(distance);
        setSliderValue(value);
    };

    return (
        <Container>
            <Slider
                className="rc"
                min={minVal}
                max={maxVal}
                defaultValue={sliderValue}
                value={sliderValue}
                trackStyle={{
                    backgroundImage: "linear-gradient(to right, #00C1FF, #0076FF)",
                    height: 4,
                    zIndex: 9,
                }}
                railStyle={{ opacity: 0.2, height: 3, margin: 1 }}
                dots
                dotStyle={{
                    height: 3,
                    width: 3,
                    margin: 2,
                }}
                handle={CustomHandle}
                onAfterChange={onAfterChange}
                onChange={setSliderValue}
            />
        </Container>
    );
};

export { CustomSlider };
