import styled from "styled-components";

export const DFlex = styled.div`
    display: flex;
`;

export const SBetween = styled(DFlex)`
    justify-content: space-between;
`;

export const FlexCenter = styled(DFlex)`
    justify-content: center;
    align-items: center;
`;

export const BoxContent = styled(DFlex)`
    flex-direction: column;
`;

export const OR = styled(FlexCenter)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray};
    text-align: center;
`;

export const Anchor = styled.a`
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGray};
    cursor: pointer;
`;

export const MBottom = styled.div<{ mb: string }>`
    margin-bottom: ${({ mb }) => mb}px;
`;
