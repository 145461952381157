// #region Global Imports
import styled from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${theme.colors.mystic};
    margin: 0 5px;
    border-radius: 5px;

    div:nth-child(n + 6) {
        border-bottom: unset;
    }
    div:nth-child(5) {
        border-right: unset;
    }
    div:last-child {
        border-right: unset;
    }
`;

export const Sticker = styled.div<{ assets: any }>`
    background-image: url(${({ assets }) => assets.Thumb});
    background-size: contain;
    width: 64px;
    height: 64px;
    cursor: pointer;
    border-right: 1px solid ${theme.colors.mystic};
    border-bottom: 1px solid ${theme.colors.mystic};

    &:hover {
        background-image: url(${({ assets }) => assets.Gif});
    }
`;

Sticker.displayName = "Sticker";
