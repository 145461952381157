// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Text, Button } from "@Components/Basic";
import { Row, Col } from "@Styled";
import { Title, UnorderedList, Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentGoldMemberAlert } from "./PaymentGoldMemberAlert";
// #endregion Interface Imports

export const PaymentGoldMemberAlert: React.FunctionComponent<IPaymentGoldMemberAlert.IProps> = ({
    t,
    paymentType,
    onClickResumeButton,
}) => {
    return (
        <Container>
            <Row className="py-14 px-5">
                <Col className="col-12 col-sm-7 offset-sm-3 col-lg-5 offset-lg-3">
                    <Title className="mb-3" weight="heavyBold">
                        {t("common:payment.goldMemberAlert.title")}
                    </Title>
                    <Title className="mb-5" weight="bold">
                        {t("common:payment.goldMemberAlert.subTitle")}
                    </Title>

                    <Text weight="bold">{t("common:payment.goldMemberAlert.creditsTitle")}</Text>
                    <UnorderedList>
                        <li>{t("common:payment.goldMemberAlert.credits1")}</li>
                        <li>{t("common:payment.goldMemberAlert.credits2")}</li>
                        <li>{t("common:payment.goldMemberAlert.credits3")}</li>
                        <li>{t("common:payment.goldMemberAlert.credits4")}</li>
                    </UnorderedList>

                    <Link
                        href={`/payments/[type]/${paymentType}?source=coin`}
                        as={`/payments/gold/${paymentType}`}
                    >
                        <a>
                            <Button className="mb-3">
                                {t("common:payment.goldMemberAlert.goldPackages")}
                            </Button>
                        </a>
                    </Link>

                    <Button
                        onClick={onClickResumeButton}
                        styleType="muted"
                        size="small"
                        className="text-uppercase"
                        cy="buyCredit-btn"
                    >
                        {t("common:payment.goldMemberAlert.buyCredit")}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
