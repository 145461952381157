// #region Global Imports
import React, { FC } from "react";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { QuickProfileFill } from "@Components";
import { ButtonGroup, Container, Title, SubTitle } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IPresentational } from "./EmptyView";
// #endregion Interface Imports

export const Presentation: FC<IPresentational> = ({
    activatePhotoModal,
    setPurchaseRevealed,
    ContainsYou,
    isQuickModalOpen,
    setQuickModal,
    title,
    t,
}) => (
    <>
        <Container>
            {title && <Title>{title}</Title>}

            <SubTitle>
                {t(
                    "common:EmptyView.Dikkatleri üzerine çekmek istiyorsan profilini biraz daha geliştirmeyi dene"
                )}
            </SubTitle>

            <ButtonGroup>
                <Button styleType="ghost" size="small" onClick={() => setQuickModal(true)}>
                    {t("common:Profil Doldur")}
                </Button>
                <Button styleType="ghost" size="small" onClick={activatePhotoModal}>
                    {t("common:Fotoğraf Ekle")}
                </Button>
            </ButtonGroup>

            {!ContainsYou && (
                <Button onClick={() => setPurchaseRevealed(true)}>
                    {t("common:Beni Vitrinde Göster")}
                </Button>
            )}
        </Container>

        {isQuickModalOpen && <QuickProfileFill t={t} closeModal={() => setQuickModal(false)} />}
    </>
);
