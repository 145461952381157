import * as React from "react";

const SvgVirgoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 17" {...props}>
        <path
            d="M.923 5.506c0-.48 0-.917-.043-1.31S.793 3.41.75 3.06C.707 2.71.62 2.405.533 2.1.447 1.793.36 1.487.23 1.181h1.3c.173.35.26.699.39 1.092.086.393.173.743.216 1.136.087-.219.217-.393.347-.612l.39-.655c.13-.218.303-.393.477-.612l.563-.567h1.126c.434.262.824.611 1.084 1.004.26.393.476.918.65 1.529.13-.218.26-.437.39-.699.13-.218.26-.437.433-.655.13-.218.303-.437.476-.612.174-.218.347-.393.564-.524h1.126c.477.306.867.743 1.213 1.267.303.524.563 1.18.693 1.922l.39-.524c.13-.175.304-.35.477-.524h1.083a5.094 5.094 0 011.387 1.965c.303.743.433 1.573.433 2.534 0 1.485-.26 2.751-.823 3.843-.564 1.092-1.343 2.01-2.383 2.709.13.524.26 1.004.39 1.441.13.437.303.83.433 1.18h-1.517l-.26-.918c-.086-.306-.173-.655-.26-1.004-.953.48-2.08.83-3.379 1.048v-1.31c.607-.088 1.126-.263 1.646-.394.52-.174.997-.35 1.43-.611-.086-.48-.13-1.005-.173-1.573a23.7 23.7 0 01-.087-1.834V5.812c0-.524-.043-.96-.13-1.398-.086-.393-.173-.786-.303-1.092-.13-.305-.26-.568-.433-.786a1.948 1.948 0 00-.564-.48c-.216.218-.39.436-.606.698a13.78 13.78 0 00-.52.83 5.941 5.941 0 00-.433.918c-.13.305-.26.611-.39.96v7.514H5.733v-7.12c0-.612-.044-1.092-.087-1.53-.043-.436-.173-.785-.26-1.047-.13-.306-.26-.525-.433-.7a1.649 1.649 0 00-.564-.392c-.26.218-.476.436-.65.699-.173.262-.39.524-.563.83a5.941 5.941 0 00-.433.917c-.13.35-.26.611-.347.917v7.513H1.01V5.506H.923zm10.832 4.674c0 .48 0 .917.043 1.354.044.437.044.83.13 1.18a6.061 6.061 0 001.56-2.14c.347-.83.52-1.88.52-3.015 0-.437-.043-.83-.087-1.18a4.752 4.752 0 00-.216-.916c-.087-.262-.217-.48-.347-.7-.13-.218-.303-.349-.476-.523-.217.174-.39.393-.607.611-.217.219-.39.48-.563.786v4.543h.043z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgVirgoIcon;
