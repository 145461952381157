import * as React from "react";

const SvgPlusCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
        <g transform="translate(1 1)" fill="currentcolor" fillRule="evenodd">
            <circle stroke="currentcolor" cx={10} cy={10} r={10.5} />
            <path
                d="M5 9h3.999L9 5a1 1 0 012 0v4h4a1 1 0 010 2h-4v4a1 1 0 11-2 0l-.001-4H5a1 1 0 010-2z"
                fill="#fff"
            />
        </g>
    </svg>
);

export default SvgPlusCircle;
