// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { useScreenSize, repeatElement } from "@Definitions";
import { MessageListItemSkeleton, ProfileCardSkeleton } from "@Components";
import { ChatSectionSkeleton } from "../ChatSection/index.skeleton";
import { Container } from "./styled";
// #endregion Local Imports

export const ConversationSkeleton: React.FC = () => {
    const screenSize = useScreenSize();
    const isDesktop = screenSize.includes("large");
    const isTablet = screenSize.includes("medium");

    const renderlistItem = () => repeatElement(<MessageListItemSkeleton />, 5);

    if (isDesktop || isTablet) {
        return (
            <Container className="container">
                <div className="d-flex">
                    <div className="col-lg-9 inner-container">
                        <div className="row">
                            <div className="pt-3 pr-0 col-lg-4 col-md-4">{renderlistItem()}</div>
                            <ChatSectionSkeleton />
                        </div>
                    </div>
                    {isDesktop && (
                        <div className="request-item col-lg-3">
                            <ProfileCardSkeleton />
                        </div>
                    )}
                </div>
            </Container>
        );
    }
    return (
        <div className="mt-5">
            <MessageListItemSkeleton />
        </div>
    );
};
