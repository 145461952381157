import * as React from "react";

const SvgPassword = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm0 3.5a4.5 4.5 0 014.495 4.288L22.5 9l.001 5.812a9 9 0 11-9.001 0V9A4.5 4.5 0 0118 4.5zm0 10.107a8 8 0 100 16 8 8 0 000-16zm0 4.5a2 2 0 011.001 3.732L19 25.107a1 1 0 01-2 0V22.84a2 2 0 011-3.733zM18 5.5a3.5 3.5 0 00-3.495 3.308L14.5 9v5.313a8.972 8.972 0 013.5-.706c1.241 0 2.424.252 3.5.706V9A3.5 3.5 0 0018 5.5z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgPassword;
