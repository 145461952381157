// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports
import {
    Container as MainContainer,
    CampaignName as Campaign,
    CampaignPrice as Price,
} from "@Components/Payments/GoldPackage/styled";

export const Container = styled(MainContainer)``;

export const CampaignName = styled(Campaign)``;

export const CampaignText = styled.div<{ isMobile: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? "0" : "24px")};
    margin-bottom: ${({ isMobile }) => (isMobile ? "0" : "0.75rem")};
`;

export const CampaignPrice = styled(Price)``;

export const RectangleContainer = styled.div`
    margin-bottom: 5px;
`;

export const MobileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
