// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
    z-index: 1;
    background-image: ${({ theme }) => theme.gradients.grayHeader};
    box-shadow: 0 0px 20px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 64px;
    display: grid;
    place-items: center;

    > img {
        height: 44px;
    }

    .with-text {
        width: 193px;
    }

    .without-text {
        width: 44px;
    }
`;
