// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { useLocationForm } from "@Definitions/Hooks/UseLocationForm";
import { Card, Dropdown, Button } from "@Components/Basic";
import { Overlay } from "@Components";
import { Container, Title, InputContainer, ButtonContainer } from "./styled";
import { ISelectLocationCard } from "./SelectLocationCard";
// #endregion Local Imports

const SelectLocationCard: React.FunctionComponent<ISelectLocationCard.IProps> = ({
    t,
    fetchCities,
    fetchTowns,
    countries,
    cities,
    towns,
    saveLocation,
    defaultLocation,
    onClickOverlay,
}): JSX.Element => {
    const {
        selectedCountry,
        selectedCity,
        selectedTown,
        countryOptions,
        cityOptions,
        townOptions,
        townInput,
        changeCountry,
        changeCity,
        changeTown,
        changeTownInput,
        idOf,
        apply,
    } = useLocationForm({
        isSearch: true,
        t,
        countries,
        cities,
        towns,
        defaultLocation,
        saveLocation,
        fetchCities,
        fetchTowns,
    });

    return (
        <>
            <Overlay onClick={onClickOverlay} />
            <Container>
                <Card>
                    <Title>
                        <span>{t("search:Yaşadığı Yer")}</span>
                    </Title>
                    <InputContainer>
                        <Dropdown
                            t={t}
                            name="select-country"
                            width="227px"
                            minHeight="40px"
                            key="select-country"
                            value={selectedCountry}
                            options={countryOptions}
                            onChange={changeCountry}
                            placeholder={t("common:selectLocation.country")}
                        />
                    </InputContainer>

                    <InputContainer>
                        <Dropdown
                            t={t}
                            name="select-city"
                            width="227px"
                            minHeight="40px"
                            key="select-city"
                            options={cityOptions}
                            value={selectedCity}
                            onChange={changeCity}
                            placeholder={t("common:selectLocation.city")}
                        />
                    </InputContainer>

                    {idOf(selectedCity) > -1 && (
                        <InputContainer>
                            <Dropdown
                                t={t}
                                name="select-town"
                                width="227px"
                                minHeight="40px"
                                key="select-town"
                                options={townOptions}
                                value={selectedTown}
                                onChange={changeTown}
                                inputValue={townInput}
                                onInputChange={changeTownInput}
                                forceReactSelect={idOf(selectedCountry) > 0 || false}
                                placeholder={t("common:selectLocation.town")}
                                noOptionsMessage={() => t("common:selectLocation.noResults")}
                            />
                        </InputContainer>
                    )}
                    <ButtonContainer>
                        <Button styleType="ghost" size="small" onClick={apply}>
                            {t("common:buttonText.UYGULA")}
                        </Button>
                    </ButtonContainer>
                </Card>
            </Container>
        </>
    );
};

export { SelectLocationCard };
