import * as React from "react";

const SvgStickerIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
        <g filter="none" fill="#8791A1" fillRule="nonzero" stroke="#8791A1" strokeWidth={0.3}>
            <path d="M22 8a4 4 0 014 4v8a8 8 0 01-8 8h-8a4 4 0 01-4-4V12a4 4 0 014-4h12zm0 1H10a3 3 0 00-3 3v12a3 3 0 003 3h5.5v-1.5c0-.817.13-1.604.373-2.34h.273a8.718 8.718 0 01-6.333-2.562.75.75 0 111.06-1.06 7.28 7.28 0 001.21.976c1.241.798 3.037 1.18 4.473 1.15A7.49 7.49 0 0123 18l2-.001V12a3 3 0 00-3-3zm3 9.999L23 19a6.5 6.5 0 00-6.5 6.5V27H18a7 7 0 007-7v-1.001zM12.664 13.35a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm6.593 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" />
        </g>
    </svg>
);

export default SvgStickerIcon;
