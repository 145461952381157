// #region Global Imports
import * as React from "react";
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { Card, Title, Button, Text, HR } from "@Components/Basic";
import { DurationUnits, ProductType } from "@Interfaces/enums";
import { Goback } from "@Icons";
import { Primary, Secondary, Footer, Content, Img } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentSelectedPackage } from "./PaymentSelectedPackage";
// #endregion Interface Imports

export const PaymentSelectedPackage: React.FunctionComponent<IPaymentSelectedPackage.IProps> = ({
    t,
    type,
    campaign,
}) => {
    const tt = (name: string, params?: any) => {
        return t(`common:payment.selectedPackage.${type}.${name}`, params);
    };

    const campaignProperties = (val: DurationUnits) => {
        switch (val) {
            case DurationUnits.Day:
                return "durationUnit.Day";
            case DurationUnits.Week:
                return "durationUnit.Week";
            case DurationUnits.Month:
                return "durationUnit.Month";
            case DurationUnits.Year:
                return "durationUnit.Year";
            default:
                return "";
        }
    };

    const productType = (val: ProductType) => {
        switch (val) {
            case ProductType.Gold:
                return "Gold";
            case ProductType.Credit:
                return "Kredi";
            default:
                return "";
        }
    };

    return (
        <Card className="d-flex flex-column p-3">
            <Content>
                <div className="d-lg-block d-none">
                    <Title fontWeight="bold" size="medium" className="mb-3">
                        {tt("title")}
                    </Title>
                    <HR className="py-0 mt-0 mb-4" />
                </div>
                <div className="d-flex justify-content-center mt-lg-10 mb-lg-5 mr-lg-0 mr-3">
                    <Img
                        src={`/images/gradient-${
                            type === "gold" ? "circled-star" : "coin-icon"
                        }.png`}
                        alt="icon"
                    />
                </div>
                <div>
                    <Title size="medium" fontWeight="bold" className="d-lg-none d-md-block">
                        {tt("title")}
                    </Title>
                    <div className="mb-lg-3">
                        <Primary type={type}>
                            {type === "coin" && (
                                <Text
                                    weight="bold"
                                    color="sliderDarkBlue"
                                    className="text-lg-center text-item "
                                >
                                    {tt("textPrimary", { coin: campaign.Coin })}
                                </Text>
                            )}
                            {type === "gold" && (
                                <>
                                    <Text
                                        weight="bold"
                                        color="sliderDarkBlue"
                                        className="text-lg-center text-item"
                                    >
                                        <span className="mr-1">{campaign.ProductDuration}</span>
                                        <span>
                                            {t(
                                                `common:enums.${campaignProperties(
                                                    campaign.ProductDurationUnit
                                                )}`
                                            )}
                                        </span>
                                    </Text>
                                    <Text
                                        weight="bold"
                                        color="sliderDarkBlue"
                                        className="text-lg-center text-item "
                                    >
                                        {tt("textCampaignName", {
                                            name: productType(campaign.ProductType!),
                                        })}
                                    </Text>
                                    <Text
                                        weight="bold"
                                        color="sliderDarkBlue"
                                        className="text-lg-center text-item "
                                    >
                                        {tt("textPrice", {
                                            price: campaign.DiscountedPrice,
                                        })}
                                    </Text>
                                </>
                            )}
                        </Primary>
                        <Secondary>
                            <Text color="sliderDarkBlue" className="text-lg-center">
                                {type === "coin" &&
                                    tt("textSecondary", { price: campaign.DiscountedPrice })}
                                {type === "gold" && tt("textSecondary", { coin: campaign.Coin })}
                            </Text>
                        </Secondary>
                    </div>
                </div>
            </Content>
            <Footer>
                <div className="d-flex justify-content-lg-center justify-content-end mb-lg-10 pb-lg-10 button-container">
                    <Button
                        icon={<Goback width="14px" height="13px" className="mr-3" />}
                        styleType="muted"
                        iconPosition="left"
                        size="slim"
                        className="mb-lg-10 mb-3 text-uppercase"
                        onClick={() => Router.back()}
                    >
                        {tt("buttonText")}
                    </Button>
                </div>
                <div className="text-lg-center">
                    <Text size="small" weight="bold">
                        {tt("infoBold", { count: 1 })}
                    </Text>
                    {type === "coin" ? (
                        <Text size="small" color="red" weight="medium">
                            {tt("infoWarning")}
                        </Text>
                    ) : (
                        <Text size="small" weight="medium">
                            {tt("infoNormal")}
                        </Text>
                    )}
                </div>
            </Footer>
        </Card>
    );
};
