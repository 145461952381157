import * as React from "react";

const SvgGooglePlayMin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <defs>
            <linearGradient
                x1="61.031%"
                y1="4.966%"
                x2="26.52%"
                y2="71.904%"
                id="google-play-min_svg__a"
            >
                <stop stopColor="#00A0FF" offset="0%" />
                <stop stopColor="#00A1FF" offset=".66%" />
                <stop stopColor="#00BEFF" offset="26.01%" />
                <stop stopColor="#00D2FF" offset="51.22%" />
                <stop stopColor="#00DFFF" offset="76.04%" />
                <stop stopColor="#00E3FF" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="107.583%"
                y1="49.994%"
                x2="-130.477%"
                y2="49.994%"
                id="google-play-min_svg__b"
            >
                <stop stopColor="#FFE000" offset="0%" />
                <stop stopColor="#FFBD00" offset="40.87%" />
                <stop stopColor="orange" offset="77.54%" />
                <stop stopColor="#FF9C00" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="86.25%"
                y1="30.897%"
                x2="-50.143%"
                y2="136.018%"
                id="google-play-min_svg__c"
            >
                <stop stopColor="#FF3A44" offset="0%" />
                <stop stopColor="#C31162" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="-18.807%"
                y1="-11.873%"
                x2="42.096%"
                y2="35.069%"
                id="google-play-min_svg__d"
            >
                <stop stopColor="#32A071" offset="0%" />
                <stop stopColor="#2DA771" offset="6.85%" />
                <stop stopColor="#15CF74" offset="47.62%" />
                <stop stopColor="#06E775" offset="80.09%" />
                <stop stopColor="#00F076" offset="100%" />
            </linearGradient>
        </defs>
        <g fillRule="nonzero" fill="none">
            <path
                d="M.443.254C.258.45.148.756.148 1.15v14.12c0 .396.11.701.295.897l.048.046 7.91-7.91v-.186L.49.208.443.253z"
                fill="url(#google-play-min_svg__a)"
                transform="translate(.5 .25)"
            />
            <path
                d="M11.037 10.942L8.4 8.304v-.186l2.636-2.637.06.034L14.22 7.29c.892.507.892 1.336 0 1.844l-3.124 1.774-.06.034z"
                fill="url(#google-play-min_svg__b)"
                transform="translate(.5 .25)"
            />
            <path
                d="M11.097 10.908L8.4 8.21.443 16.168c.294.311.78.35 1.327.04l9.327-5.3"
                fill="url(#google-play-min_svg__c)"
                transform="translate(.5 .25)"
            />
            <path
                d="M11.097 5.514L1.77.214C1.223-.095.737-.056.443.255l7.958 7.958 2.696-2.698z"
                fill="url(#google-play-min_svg__d)"
                transform="translate(.5 .25)"
            />
            <path
                d="M11.537 11.098L2.27 16.364c-.518.295-.982.275-1.28.007l-.047.048.048.045c.297.268.76.288 1.28-.007l9.326-5.3-.06-.059z"
                fill="#000"
                opacity={0.2}
            />
            <path
                d="M.943 16.325c-.185-.197-.295-.502-.295-.897v.093c0 .395.11.7.295.897l.048-.048-.048-.045zM14.72 9.29l-3.183 1.808.06.06 3.124-1.775c.446-.253.669-.588.669-.921-.038.302-.265.597-.67.828z"
                fill="#000"
                opacity={0.12}
            />
            <path
                d="M2.27.558l12.45 7.074c.406.23.632.526.67.829 0-.334-.223-.669-.67-.922L2.27.465C1.378-.042.648.379.648 1.4v.093c0-1.022.73-1.443 1.622-.936z"
                fill="#FFF"
                opacity={0.25}
            />
        </g>
    </svg>
);

export default SvgGooglePlayMin;
