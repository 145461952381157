// #region Global Imports
import { useEffect } from "react";
// #endregion Global Imports

type ClickHander = (event: MouseEvent) => void;

export const useOutsideClick = (handler: ClickHander, deps = []) =>
    useEffect(() => {
        document.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    }, [handler, ...deps]);
