import * as React from "react";

const SvgHotornotMatch = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 37 36" {...props}>
        <path
            d="M18.5 0c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm0 1c-9.389 0-17 7.611-17 17s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17zm5.503 19.825l1.49 1.342-.274.267-.175.194c-.904.963-3.023 2.706-6.232 5.045a.64.64 0 01-.305.075h-.085c-.156 0-.235 0-.39-.075l-.685-.505a108.876 108.876 0 01-2.8-2.141l1.368-1.468c.488.386 1.032.806 1.631 1.259l.878.659.888-.664c2.09-1.578 3.578-2.82 4.235-3.512l.153-.17.125-.132.178-.174zm-1.206-10.768c1.465 0 2.724.467 3.776 1.401l.208.193c1.094 1.063 1.64 2.354 1.719 3.797 0 .987-.156 1.974-.547 2.885a8.513 8.513 0 01-1.015 2.05c-.053.077-.11.156-.171.237l-1.496-1.345.161-.244c.236-.381.452-.83.63-1.347l.141-.363c.166-.458.262-.97.289-1.533l.008-.285-.022-.236c-.103-.852-.457-1.565-1.09-2.182-.719-.697-1.546-1.028-2.591-1.028-1.154 0-2.076.454-2.814 1.375l-1.604 2.005-1.55-2.046c-.693-.913-1.572-1.334-2.782-1.334-.974 0-1.799.335-2.512 1.028-.727.707-1.035 1.412-1.035 2.363 0 .779.124 1.546.36 2.236.24.7.467 1.208.653 1.503l.113.139 1.163 1.45.23.223.124.133c.235.273.645.663 1.221 1.157L13 23.752c-.635-.549-1.092-.988-1.375-1.318l-.313-.304-.384-.474c-.414-.518-.718-.918-1.022-1.273-.312-.455-.625-1.139-.937-2.05a8.944 8.944 0 01-.469-2.885c0-1.519.547-2.734 1.64-3.797 1.094-1.063 2.422-1.594 3.907-1.594 1.797 0 3.281.683 4.375 2.126 1.094-1.367 2.578-2.126 4.375-2.126z"
            fill="#8791A1"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgHotornotMatch;
