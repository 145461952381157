// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ type: string }>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0 5px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    pointer-events: ${({ type }) => (type === "like" ? "none" : "unset")};
`;

export const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    padding-left: 3px;
    border-radius: 20px;

    &:hover {
        background-color: #d9f7ff;
        cursor: pointer;
    }
`;

export const Image = styled.img`
    border-radius: 100%;
    width: 36px;
    height: 36px;
`;

export const Content = styled.div`
    font-family: "Montserrat";
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray};
    margin-left: 10px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
`;

export const ContentSpan = styled.div`
    margin-right: 5px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CoinTime = styled(Content)`
    font-size: 10px;
    font-weight: 700;
`;

export const CoinContentContainer = styled.div``;

export const Icon = styled.i`
    font-size: 36px;
    color: ${({ theme }) => theme.colors.gray};
    padding-top: 5px;
`;
