// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { PaymentModel } from "@Interfaces";
// #endregion Interface Imports

export const PaymentService = {
    Subscription: async (
        userId: string,
        payload: PaymentModel.SubscriptionPayload
    ): Promise<PaymentModel.SubscriptionSuccessResponse> => {
        return Http.Request("POST", `/profiles/${userId}/subscriptions`, undefined, {
            ...payload,
        });
    },
    Installment: async (
        payload: PaymentModel.InstallmentPayload
    ): Promise<PaymentModel.InstallmentSuccessRawResponse> => {
        return Http.Request("POST", `/payment/Installment`, undefined, {
            ...payload,
        });
    },
    MobilePaymentCheck: async (
        userId: number | string,
        paymentId: number | string
    ): Promise<PaymentModel.MobilePaymentCheckResponse> => {
        return Http.Request("GET", `/profiles/${userId}/payments/${paymentId}`, undefined);
    },
};
