// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { ImageContainer, IconContainer } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IStickerAvatar } from "./StickerAvatar";
// #endregion Interface Imports
import { Check } from "@Icons";
import { theme } from "@Definitions";

export const StickerAvatar: React.FunctionComponent<IStickerAvatar.IProps> = ({
    isOwned,
    activeThumb,
    passiveThumb,
    handleClick,
    isActive,
    purchaseButtonClick,
}) => {
    return (
        <div className="d-flex align-items-center">
            <ImageContainer isActive={isActive} onClick={handleClick}>
                <img src={isActive || isOwned ? activeThumb : passiveThumb} alt="sticker-icon" />
                {isOwned && (
                    <IconContainer>
                        <Check width="7.5px" height="7px" color={theme.colors.white} />
                    </IconContainer>
                )}
            </ImageContainer>
            {!isOwned && isActive && (
                <Button onClick={purchaseButtonClick} styleType="facebook" size="small">
                    Satın Al
                </Button>
            )}
        </div>
    );
};
