import styled from "styled-components";
import { IModal } from "./Modal";

export const Container = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(42, 42, 42, 0.7);

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        align-items: flex-start;
        background-color: unset;
    }
`;

export const ChildBed = styled.div`
    position: relative;
    height: auto;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 100%;
        height: 100%;
    }
`;

export const DismissButton = styled.i<IModal.IProps>`
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 24px;
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.gray};
    ${({ customModalCloseIcon }) => customModalCloseIcon && customModalCloseIcon};
`;
