// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic/Button";
// #endregion Local Imports

export const Container = styled(Button)<{ disabled: boolean }>`
    background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.lightGray : theme.colors.labelGreen};
    display: inline-flex;
    height: 20px;
    border-radius: 4px;
    color: #ffffff;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :hover {
        .button {
            background-color: ${({ theme }) => theme.colors.badgeHoverGreen};
            ${({ disabled }) => !disabled && "text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);"}
        }
    }

    .button {
        ${({ theme, disabled }) => disabled && `background-color: ${theme.colors.gray} !important;`}
    }
`;

export const TextWrapper = styled.div`
    height: 100%;
    padding-left: 6px;
    padding-right: 8px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 11px;
`;

export const CloseButton = styled.div`
    justify-self: flex-end;
    width: 20px;
    height: 20px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 9px;
`;
