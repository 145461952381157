// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { IIconButton } from "./IconButton";
import { Content, DefaultStyledIcon } from "./styled";
// #endregion Local Imports

const IconButton: React.FunctionComponent<IIconButton.IProps> = ({
    onIconClick,
    iconName,
    StyledIcon,
    onIconPress,
    renderIcon,
    cy,
}): JSX.Element => {
    const renderStyledIcon = () =>
        StyledIcon ? (
            <StyledIcon className={`icon ${iconName}`} />
        ) : (
            <DefaultStyledIcon className={`icon ${iconName}`} />
        );
    return (
        <span
            role="button"
            onClick={onIconClick}
            onKeyPress={onIconPress}
            tabIndex={0}
            data-cy={cy}
        >
            <Content>{(renderIcon && renderIcon()) || renderStyledIcon()}</Content>
        </span>
    );
};

export { IconButton };
