// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { ContentBox as MainContentBox } from "@Components/HotOrNot/styled";
import { ImageButton as MainImageButton } from "@Components/HotOrNot/Components/Game/styled";
// #endregion Local Imports

export const ContentBox = styled(MainContentBox)`
    overflow: hidden;
`;

export const ImageButton = styled(MainImageButton)`
    overflow: hidden;
    border-radius: 50%;
`;
