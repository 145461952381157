// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, Span, SpanCount } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IValidationCounter } from "./ValidationCounter";
// #endregion Interface Imports

const ValidationCounter: React.FunctionComponent<IValidationCounter.IProps> = (
    props
): JSX.Element => {
    const { count, countLimit } = props;

    return (
        <Container>
            <Span {...props}>EN ÇOK:</Span>
            <SpanCount count={count} countLimit={countLimit}>
                {count}
                <span>/</span>
            </SpanCount>
            <Span {...props}>{countLimit}</Span>
        </Container>
    );
};

export { ValidationCounter };
