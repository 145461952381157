// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, LoadingContent } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ILoading } from "./Loading";
// #endregion Interface Imports

export const Loading: React.FunctionComponent<ILoading.IProps> = ({ spinner }: ILoading.IProps) => {
    return <Container>{spinner && <LoadingContent />}</Container>;
};
