// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ContentBox } from "../../styled";
import { Container, BannerArea, SkipButton, Blank } from "./styled";
import { IBanner } from "./Banner";
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

const Banner: React.FunctionComponent<IBanner.IProps> = ({
    src = "",
    onSkipClick,
    t,
}): JSX.Element => {
    let bannerSize = "";

    switch (useScreenSize()) {
        case "xlarge":
            bannerSize = "300x250";
            break;
        case "large":
            bannerSize = "250x250";
            break;
        case "medium":
            bannerSize = "336x280";
            break;
        case "small":
            bannerSize = "336x280";
            break;
        case "xsmall":
            bannerSize = "300x250";
            break;
        default:
            break;
    }

    const txt = (key: string) => t(`dashboard:HotOrNot.Banner.${key}`);

    return (
        <ContentBox>
            <Container>
                <Blank />
                <BannerArea src={src}>
                    <span>{bannerSize}</span>
                    <span>banner</span>
                </BannerArea>
                <Blank>
                    <SkipButton iconClick={onSkipClick}>{txt("buttonText")}</SkipButton>
                </Blank>
            </Container>
        </ContentBox>
    );
};

export { Banner };
