// #region Global imports
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
// #endregion Global imports

// #region Local imports
import { Button, IconButton, Checkbox, Text } from "@Components/Basic";
import { ConfirmCreditModal } from "@Components/ConfirmCreditModal";
import { InboxActions } from "@Actions";
import { IStore, StickerModel } from "@Interfaces";
import { MessageItem, MessageInput, HoverToolTip } from "@Components";
import { ConversationService } from "@Services";
import { useScreenSize, ActionConsts, theme } from "@Definitions";
import { Exclamation, Ban, Trash, FlyingHeart } from "@Icons";
import {
    StyledIcon,
    SendMessageContainer,
    UserButtons,
    MessagesContainer,
    HoverToolTipWrapper,
    IconButtonWrapper,
    customHoverToolTipCss,
    NotificationContainer,
    ReceivedNewMessageNotification,
} from "../../styled";
// #endregion Local imports

// #region Interface imports
import { IConversation } from "../../Conversation";
// #endregion Interface imports

export const ChatSection: React.FunctionComponent<IConversation.ChatSectionProps> = ({
    sectionType,
    t,
    setModalOpen,
}) => {
    const { chat, stickers, sentMessageStatus, traffic } = useSelector(
        ({ inbox }: IStore) => inbox
    );

    // ? `stableState` is being used to identify changes
    // ? since all `chat` is being re-written on every change
    // ? and it causes React to think it's a new binding each time.
    const stableState = useRef<IConversation.StableState>({
        withId: "",
        hasNew: false,
        messagesCount: 0,
        firstListingDate: undefined,
    });

    const dispatch = useDispatch();
    const screenSize = useScreenSize();
    const isMobile = screenSize.includes("small");

    const msgs = useRef<any>();
    const olderScrollHeight = useRef(0);
    const [featureCoin, setFeatureCoin] = useState(0);
    const [messageText, setMessageText] = useState("");
    const [sendWithEnter, setSendWithEnter] = useState(true);
    const [feauterdConfirmShow, setFeauterdConfirmShow] = useState(false);
    const [receivedNewMessageNotification, setReceivedNewMessageNotification] = useState(false);

    const sendMessage = async (
        confirmPrioritized: boolean,
        sticker?: StickerModel.IStickerItem
    ) => {
        if (!chat || (!sticker && !messageText)) return;

        setFeauterdConfirmShow(false);

        dispatch(
            await InboxActions.SendMessage({
                ToUserId: chat.With.Id,
                Message: sticker ? String(sticker.Id) : messageText,
                IsPrioritized: chat.prioritizedState,
                ConfirmPrioritized: confirmPrioritized,
                Type: sticker ? 3 : 1,
                Sticker: sticker,
            })
        );
    };

    const markAsRead = async () => {
        stableState.current.hasNew = false;
        dispatch(await InboxActions.MarkAsRead(stableState.current.withId));
    };

    // * Sets `stableState`
    // ? Magnets to bottom onDidMount
    // ? and onNewMessageReceived only if closer than 16px to bottom line.
    useEffect(() => {
        if (!chat) return;

        const { With, Messages, HasNew } = chat;

        const { withId, messagesCount, firstListingDate } = stableState.current;

        const { scrollTop, scrollHeight, clientHeight } = msgs.current;

        const isFollowing =
            scrollTop >= scrollHeight - clientHeight - (scrollHeight - olderScrollHeight.current);

        const isSameChat = With.Id === withId;

        const isUpdated = Messages.length !== messagesCount;

        const isForwards = Messages[0]?.Date === firstListingDate;

        if (isSameChat) {
            if (isUpdated) {
                stableState.current = {
                    ...stableState.current,
                    messagesCount: Messages.length,
                    hasNew: isUpdated && isForwards,
                    firstListingDate: Messages[0]?.Date,
                };

                if (stableState.current.hasNew) {
                    if (isFollowing) {
                        msgs.current.scrollTop = msgs.current.scrollHeight;

                        setReceivedNewMessageNotification(false);
                    } else {
                        setReceivedNewMessageNotification(true);
                    }
                } else {
                    msgs.current.scrollTop += scrollHeight - olderScrollHeight.current;
                }
            }
        } else {
            stableState.current = {
                withId: With.Id,
                hasNew: HasNew,
                messagesCount: Messages.length,
                firstListingDate: Messages[0]?.Date,
            };

            msgs.current.scrollTop = msgs.current.scrollHeight;

            if (HasNew) markAsRead();
        }

        olderScrollHeight.current = scrollHeight;
    }, [chat]);

    // ? Fetches older messages, only if snapped to topline.
    // ? And marks as read, only if closer than 16px to bottom line.
    const handleScroll = ({ target }: React.UIEvent<HTMLDivElement>) => {
        if (!chat) return;

        const { scrollTop, scrollHeight, clientHeight } = target as HTMLDivElement;

        const threshold = 16;
        const isTopped = scrollTop === 0;
        const isBottomed = scrollTop >= scrollHeight - clientHeight - threshold;

        if (isTopped) {
            const { With, Messages, Count } = chat;

            if (Messages.length < Count && !traffic) {
                const oldestMessageDate = Messages[0].Date; // ? reversed

                (async () => {
                    dispatch(await InboxActions.FetchChat(With.Id, 10, oldestMessageDate));
                })();
            }
        }

        if (isBottomed) {
            if (stableState.current.hasNew) {
                setReceivedNewMessageNotification(false);
                markAsRead();
            }
        }
    };

    useEffect(() => {
        return () => {
            stableState.current.withId = "";
        };
    }, []);

    useEffect(() => {
        if (!stickers) {
            (async () => {
                dispatch(await InboxActions.FetchStickers());
            })();
        }
    }, [dispatch, stickers]);

    useEffect(() => {
        switch (sentMessageStatus?.status) {
            case "Success":
            case "InReview":
                setMessageText("");
                break;

            case "ConfirmRequired":
                if (sentMessageStatus?.coin?.FeatureCoin) {
                    setFeatureCoin(sentMessageStatus.coin.FeatureCoin);
                    setFeauterdConfirmShow(true);
                }
                break;

            case "QuotaNotAvailable":
                Router.push(
                    "/payments/[type]/credit-card?quotaNotAvailable=true&source=message",
                    "/payments/gold/credit-card?quotaNotAvailable=true"
                );
                break;

            case "NotEnoughCoin":
                Router.push("/payments/[type]/credit-card", "/payments/coin/credit-card");
                break;

            case "InvisibleProfile":
                Router.push("/profilewarning", "/profilewarning");
                break;

            default:
                break;
        }

        dispatch({
            type: ActionConsts.Inbox.SetMessageStatus,
            // Add timestamp to trigger useEffect
            payload: undefined,
        });
    }, [dispatch, sentMessageStatus]);

    const complainUser = async () => {
        const answer = window.confirm(t("common:inbox.chat.complainInfo"));

        if (answer && chat) {
            const response = await ConversationService.Complain(chat?.With.Id);

            if (response.Success) {
                window.alert("Kullanıcı şikayet edildi.");
            } else {
                window.alert("Kullanıcı şikayet edilirken bir hata oluştu.");
            }

            if (setModalOpen) {
                setModalOpen(false);
            }
        }
    };

    const deleteMessages = async () => {
        const answer = window.confirm(t("common:inbox.chat.deleteInfo"));

        if (answer && chat) {
            dispatch(
                await InboxActions.ArchiveMessage(
                    chat.With.Id,
                    sectionType === "modal" ? "requests" : "messages"
                )
            );

            if (setModalOpen) {
                setModalOpen(false);
            }
        }
    };

    const banUser = async () => {
        if (!chat) return;

        const answer = window.confirm(t("common:inbox.chat.banInfo"));

        if (answer) {
            dispatch(
                await InboxActions.BanUser(
                    chat.With.Id,
                    sectionType === "normal" ? "messages" : "requests"
                )
            );

            if (setModalOpen) {
                setModalOpen(false);
            }
        }
    };

    const onClickPrioritizedButton = () => {
        if (!chat?.IsPrioritized) {
            dispatch({
                type: ActionConsts.Inbox.SetPrioritizedStatus,
                payload: !chat?.prioritizedState,
            });
        }
    };

    return (
        <>
            <MessagesContainer
                prioritized={chat?.IsPrioritized || chat?.prioritizedState}
                ref={msgs}
                isMobile={isMobile}
                className="px-3"
                onScroll={handleScroll}
            >
                {chat?.Messages.map(msg => (
                    <MessageItem
                        key={`msg-item-${msg.MessageId}`}
                        date={msg.Date}
                        text={msg.Text}
                        isRead={msg.IsRead}
                        isSent={msg.SenderId !== chat?.With.Id}
                        type={msg.Type}
                        sticker={msg.Sticker}
                    />
                ))}

                <NotificationContainer>
                    <ReceivedNewMessageNotification
                        active={receivedNewMessageNotification}
                        onClick={() => {
                            msgs.current.scrollTop = msgs.current.scrollHeight;
                            setReceivedNewMessageNotification(false);
                        }}
                    >
                        {/* // TODO: use t() */}
                        Okunmamış Mesajlar
                    </ReceivedNewMessageNotification>
                </NotificationContainer>
            </MessagesContainer>
            <SendMessageContainer className="px-5 py-3" sectionType={sectionType}>
                <div
                    role="textbox"
                    tabIndex={-1}
                    onKeyDown={e => {
                        if (sendWithEnter && e.key === "Enter") {
                            e.preventDefault();
                            sendMessage(false);
                        }
                    }}
                >
                    <MessageInput
                        value={messageText}
                        onChange={val => {
                            setMessageText(val);
                        }}
                        stickers={stickers}
                        sendMessage={sendMessage}
                        t={t}
                    />
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <Checkbox
                        checked={sendWithEnter}
                        onChange={() => {
                            setSendWithEnter(val => !val);
                        }}
                    >
                        <small>{t("common:inbox.chat.sendByEnter")}</small>
                    </Checkbox>
                    <div className="d-flex">
                        <ConfirmCreditModal
                            t={t}
                            coin={featureCoin}
                            isOpen={feauterdConfirmShow}
                            onClickOverlay={() => setFeauterdConfirmShow(false)}
                            onClick={() => sendMessage(true)}
                        >
                            <Button
                                styleType="ghost"
                                onClick={onClickPrioritizedButton}
                                size="small"
                                className="mr-3"
                            >
                                <FlyingHeart
                                    width="51px"
                                    height="32px"
                                    color={
                                        chat?.IsPrioritized || chat?.prioritizedState
                                            ? theme.colors.red
                                            : theme.colors.gray
                                    }
                                />
                            </Button>
                        </ConfirmCreditModal>

                        <Button
                            styleType="ghost"
                            onClick={async () => {
                                sendMessage(false);
                            }}
                            size="small"
                            cy="sendMessage-btn"
                        >
                            {t("common:inbox.chat.send")}
                        </Button>
                    </div>
                </div>
            </SendMessageContainer>
            <UserButtons>
                <IconButtonWrapper>
                    <IconButton
                        renderIcon={() => (
                            <StyledIcon>
                                <Exclamation width="25px" height="24px" />
                            </StyledIcon>
                        )}
                        onIconClick={complainUser}
                    />
                    <HoverToolTipWrapper>
                        <HoverToolTip customStyle={customHoverToolTipCss}>
                            <Text size="small" color="gray">
                                {t("common:inbox.chat.complain")}
                            </Text>
                        </HoverToolTip>
                    </HoverToolTipWrapper>
                </IconButtonWrapper>

                <IconButtonWrapper>
                    <IconButton
                        renderIcon={() => (
                            <StyledIcon>
                                <Ban width="25px" height="24px" />
                            </StyledIcon>
                        )}
                        onIconClick={banUser}
                    />
                    <HoverToolTipWrapper>
                        <HoverToolTip customStyle={customHoverToolTipCss}>
                            <Text size="small" color="gray">
                                {t("common:inbox.chat.ban")}
                            </Text>
                        </HoverToolTip>
                    </HoverToolTipWrapper>
                </IconButtonWrapper>

                <IconButtonWrapper>
                    <IconButton
                        renderIcon={() => (
                            <StyledIcon>
                                <Trash width="25px" height="24px" />
                            </StyledIcon>
                        )}
                        onIconClick={deleteMessages}
                    />
                    <HoverToolTipWrapper>
                        <HoverToolTip customStyle={customHoverToolTipCss}>
                            <Text size="small" color="gray">
                                {t("common:inbox.chat.delete")}
                            </Text>
                        </HoverToolTip>
                    </HoverToolTipWrapper>
                </IconButtonWrapper>
            </UserButtons>
        </>
    );
};
