export const ActionConsts = {
    Dashboard: {
        ResetReducer: "Dashboard_ResetReducer",
        SetReducer: "Dashboard_SetReducer",
        GetHotOrNotList: "Dashboard_GetHotOrNotList",
        HotOrNotListLoading: "DASHBOARD_HOTORNOTLIST_LOADING",
        ReduceHotOrNotList: "Dashboard_ReduceHotOrNotList",
        Like: "Dashboard_Like",
        Dislike: "Dashboard_Dislike",
        FetchLikesToMe: "Dashboard_FetchLikesToMe",
        AddLikesToMe: "DASHBOARD_ADD_LIKESTOME",
        ResetLikesToMe: "DASHBOARD_RESET_LIKESTOME",
        SetLikesToMeLoading: "DASHBOARD_SET_LIKESTOME_LOADING",

        // * Issue #694
        // TODO: Move top-level keys into separate files
        // TODO: Abstract away staged actions
        // ...createStatefulActionsFor("GetRecentPhotos", ...),

        GetRecentPhotos: {
            Loading: "🔄 GetRecentPhotos",
            Success: "✅ GetRecentPhotos",
            Failure: "❎ GetRecentPhotos",
        },

        ResetRecentPhotos: "Dashboard_ResetRecentPhotos",
        SetVisibility: "DASHBOARD_SET_VISIBILITY",
    },
    Home: {
        SetTopComponent: "HOME_SET_TOP_COMPONENT",
        ResetReducer: "Home_ResetReducer",
        SetReducer: "Home_SetReducer",
        OnlineUserCount: "ONLINE_USER_COUNT",
    },
    Account: {
        AuthStateChange: "ACCOUNT_LOGIN_STATE",
        Login: "ACCOUNT_LOGIN",
        LoginFail: "ACCOUNT_LOGIN_FAIL",
        LoginReset: "ACCOUNT_LOGIN_RESET",
        Captcha: "ACCOUNT_CAPTCHA",
        FacebookRegister: "FACEBOOK_REGISTER",
        FacebookToken: "FACEBOOK_TOKEN",
        AppleLogin: {
            AppleResponse: "APPLE_RESPONSE",
            AppleRegister: "APPLE_REGISTER",
        },
        Logout: "ACCOUNT_LOGOUT",
        ChangePasswordSuccess: "ACCOUNT_CHANGE_PASSWORD_SUCCESS",
        ChangePasswordFail: "ACCOUNT_CHANGE_PASSWORD_FAIL",
        ChangePasswordReset: "ACCOUNT_CHANGE_PASSWORD_RESET",
        UpdateEmailFail: "ACCOUNT_UPDATE_EMAIL_FAIL",
        UpdateEmailSuccess: "ACCOUNT_UPDATE_EMAIL_SUCCESS",
        UpdateEmailReset: "ACCOUNT_UPDATE_EMAIL_RESET",
        ResignSuccess: "ACCOUNT_RESIGN_SUCCESS",
        ResignFail: "ACCOUNT_RESIGN_FAIL",
        ResignReset: "ACCOUNT_RESIGN_RESET",
    },
    Profile: {
        ResetReducer: "PROFILE_RESET_REDUCER",
        GetProfile: "GET_PROFILE",
        GetProfileWithId: "GET_PROFILE_WITH_ID",
        GetPreferences: "GET_PREFERENCES",
        SetPreferences: "SET_PREFERENCES",
        GetSubscription: "GET_SUBSCRIPTION",
        SetAutoSubscription: "SET_AUTO_SUBSCRIPTION",
        SetMembershipType: "SET_MEMBERSHIP_TYPE",
        SetEmptyQuestion: "SET_EMPTY_QUESTION",
        SetEmptyQuestions: "SET_EMPTY_QUESTIONS",
        SetQuestion: "SET_QUESTION",
        SetUpdateStatus: "SET_UPDATE_STATUS",
        SetEditQuestion: "SET_EDIT_QUESTION",
        SetEditResult: "SET_EDIT_RESULT",
        SetUploadPhotoType: "SET_UPLOAD_PHOTO_TYPE",
        GetPhotosFromPusher: "SET_PHOTOS_FROM_PUSHER",
        SeenProbation: "PROFILE_SEEN_PROBATION",
        Set: "SET",
    },
    Notification: {
        GetNotificationCounts: "GET_NOTIFICAITON_COUNT",
    },
    Interaction: {
        ResetReducer: "INTERACTION_RESET_REDUCER",
        GetFriends: "GET_FRIENDS",
        GetOnlineFriends: "GET_ONLINE_FRIENDS",
        GetProfileVisitors: "GET_PROFILE_VISITORS",
        GetProfileWinks: "GET_PROFILE_WINKS",
        GetProfileSentWinks: "GET_PROFILE_SENT_WINKS",
        DeleteWink: "WINK_DELETE_WINK",
        Wink: "WINK",
        GetBans: "GET_BANNED_PROFILES",
        UnBan: "UNBAN_PROFILE",
        Friend: {
            Request: "FRIEND_REQUEST",
            Success: "FRIEND_SUCCESS",
            Failed: "FRIEND_FAILED",
        },
        SetIsFriendStatus: "SET_FRIEND_STATUS",
        SetLoading: "SET_LOADING_STATUS",
    },
    Common: {
        ResetReducer: "COMMON_RESET_REDUCER",
        SaveScrollPosition: "SAVE_SCROLL_POSITION",
        SetCoin: "COMMON_SET_COIN",
        SetOccupancy: "COMMON_SET_OCCUPANCY",
        GetCoinNotification: "GET_COIN_NOTIFICATION",
        GetChannel: "COMMON_GET_CHANNEL",
        SetStickers: "SET_STICKERS",
        SetStickerSuccess: "COMMON_SET_STICKER_SUCCESS",
        SetChatModal: "COMMON_SET_CHAT_MODAL",
        SetNewRegister: "COMMON_SET_NEW_REGISTER",
        SetPhotoUploadModalState: "COMMON_SET_PHOTO_UPLOAD_MODAL_STATE",
        SetNotification: "COMMON_SET_NOTIFICATION",
        SetWinCreditModal: "COMMON_WIN_CREDIT_MODAL",
        SetNotificationsLoading: "SET_NOTIFICATIONS_LOADING",
        SetGuestUser: "SET_GUEST_USER",
    },
    PutMeUpHere: {
        GetPutMeUpHere: "GET_PUT_ME_UP_HERE",
        SetPurchaseRevealed: "SET_PURCHASE_REVEALED",
    },
    Coin: {
        CheckCoin: "CHECK_COIN_COUNT",
    },
    Search: {
        ResetReducer: "RESET_REDUCER",
        SetSearchResults: "SET_SEARCH_RESULTS",
        AddSearchResults: "ADD_SEARCH_RESULTS",
        SetLoading: "SET_LOADING",
    },
    SearchFilters: {
        ResetReducer: "FILTERS_RESET_REDUCER",
        SetFilters: "SET_FILTERS",
    },
    MetaData: {
        ResetReducer: "METADATA_RESET_REDUCER",
        GetSearchFilterQuestions: "GET_SEARCH_FILTER_QUESTIONS",
        FetchCountries: "FETCH_COUNTRIES",
        FetchCities: "FETCH_CITIES",
        FetchTowns: "FETCH_TOWNS",
        FetchJobs: "FETCH_JOBS",
        SetTowns: "SET_TOWNS",
    },
    Campaign: {
        ResetReducer: "CAMPAIGN_RESET_REDUCER",
        Active: {
            Request: "CAMPAIGN_ACTIVE_REQUEST",
            Success: "CAMPAIGN_ACTIVE_SUCCESS",
            Failed: "CAMPAIGN_ACTIVE_FAILED",
        },
    },
    Payment: {
        ResetReducer: "PAYMENT_RESET_REDUCER",
        SelectedCampaign: "PAYMENT_SELECTED_CAMPAIGN",
        SelectedOperator: "PAYMENT_SELECTED_OPERATOR",
        Subscriptions: {
            Reset: "PAYMENT_SUBSCRIPTION_RESET",
            Request: "PAYMENT_SUBSCRIPTION_REQUEST",
            Success: "PAYMENT_SUBSCRIPTION_SUCCESS",
            Failed: "PAYMENT_SUBSCRIPTION_FAILED",
        },
        Installment: {
            Reset: "PAYMENT_INSTALLMENT_RESET",
            Request: "PAYMENT_INSTALLMENT_REQUEST",
            Success: "PAYMENT_INSTALLMENT_SUCCESS",
            Failed: "PAYMENT_INSTALLMENT_FAILED",
        },
        MobilePaymentCheck: {
            Reset: "PAYMENT_MOBILEPAYMENT_CHECK_RESET",
            Request: "PAYMENT_MOBILEPAYMENT_CHECK_REQUEST",
            Success: "PAYMENT_MOBILEPAYMENT_CHECK_SUCCESS",
            Failed: "PAYMENT_MOBILEPAYMENT_CHECK_FAILED",
        },
    },
    Inbox: {
        ResetReducer: "INBOX_RESET_REDUCER",
        FetchPrioritized: "INBOX_FETCH_PRIORITIZED",
        FetchRequests: "INBOX_FETCH_REQUESTS",
        FetchReplied: "INBOX_FETCH_REPLIED",
        FetchChatPreviews: "INBOX_FETCH_CHAT_PREVIEWS",
        FetchChat: "INBOX_FETCH_CHAT",
        FetchMessage: "INBOX_FETCH_MESSAGE",
        PushNewRequests: "INBOX_PUSH_NEW_REQUESTS",
        SyncRequests: "INBOX_SYNC_REQUESTS",
        FetchStickers: "INBOX_FETCH_STICKERS",
        SetMessageStatus: "INBOX_SET_MESSAGE_STATUS",
        SetPrioritizedStatus: "INBOX_SET_PRIORITIZED_STATUS",
        ClearRequests: "INBOX_CLEAR_REQUESTS",
        FetchInboxEmptyView: "INBOX_FETCH_EMPTY_VIEW",
        SetLoading: "SET_LOADING",
        SetTraffic: "SET_TRAFFIC",
    },
    InboxFilters: {
        ResetReducer: "INBOX_FILTERS_RESET_REDUCER",
        SetFilters: "SET_INBOX_FILTERS",
    },
    Invoice: {
        ResetReducer: "INVOICE_RESET_REDUCER",
        FetchUserInvoices: "INVOICE_FETCH_USER_INVOICES",
        AddInvoiceInfo: {
            Request: "INVOICE_ADD_INVOICE_INFO_REQUEST",
            Success: "INVOICE_ADD_INVOICE_INFO_SUCCESS",
            Failed: "INVOICE_ADD_INVOICE_INFO_FAILED",
        },
    },
    Offer: {
        ResetReducer: "OFFER_RESET_REDUCER",
        Request: "OFFER_REQUEST",
        Success: "OFFER_SUCCESS",
        Failed: "OFFER_FAILED",
    },
};
