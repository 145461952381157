import * as React from "react";

const SvgSmileHeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 51 50" {...props}>
        <path
            d="M25.45 0c13.808 0 25 11.193 25 25s-11.192 25-25 25c-13.806 0-25-11.193-25-25s11.194-25 25-25zm0 1.389C12.41 1.389 1.84 11.959 1.84 25c0 13.04 10.57 23.611 23.61 23.611 13.041 0 23.612-10.57 23.612-23.611 0-13.04-10.571-23.611-23.611-23.611zm0 9.722c7.672 0 13.89 6.218 13.89 13.889 0 7.67-6.218 13.889-13.89 13.889-7.67 0-13.888-6.218-13.888-13.889 0-7.67 6.218-13.889 13.889-13.889zm0 1.389c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5zm-7.12 15.454c.516.515 1.08.968 1.681 1.356a10.073 10.073 0 0012.56-1.356 1.042 1.042 0 011.473 1.473 12.156 12.156 0 01-15.16 1.635 12.195 12.195 0 01-2.026-1.635 1.042 1.042 0 111.473-1.473zm12.68-7.56c1.685-1.681 3.997.072 3.538 2.214-.32 1.466-1.811 2.242-2.94 2.788-1.184.575-1.49.704-1.88.963-.25-.403-.488-.647-1.31-1.696-.793-1.006-1.838-2.343-1.532-3.809.46-2.17 3.26-2.73 4.123-.46zm-6.431.142c.38 1.452-.596 2.82-1.353 3.819-.794 1.05-1.009 1.304-1.239 1.712-.406-.245-.728-.355-1.936-.917-1.164-.536-2.702-1.253-3.095-2.698-.575-2.142 1.665-3.913 3.465-2.282.739-2.263 3.595-1.75 4.158.366z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSmileHeartIcon;
