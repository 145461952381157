// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Card, Title, Text, Button } from "@Components/Basic";
import { Col, Row } from "@Styled";
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ITryGold } from "./TryGold";
// #endregion Interface Imports

export const TryGold: React.FunctionComponent<ITryGold.IProps> = ({
    t,
    title,
    description,
    isSuccess,
    onClickActivatedButton,
    disabledActivatedButton,
}): JSX.Element => (
    <Card overflowHidden>
        <Container className="py-5 px-5 px-xl-9 align-items-center">
            <Row className="no-gutters w-100 text-center">
                <Col className="col-12">
                    <Title
                        className="text-center mb-5"
                        color="red"
                        size="large"
                        fontWeight="bold"
                        dangerouslySetInnerHTML={{
                            __html: title || t("dashboard:Widgets.TryGold.title"),
                        }}
                    />
                    <Text
                        className="text-center mb-5"
                        color="black"
                        size="large"
                        weight="bold"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    {!isSuccess && (
                        <Button
                            styleType="gradient"
                            disabled={disabledActivatedButton}
                            onClick={onClickActivatedButton}
                            className="text-uppercase"
                        >
                            {t("dashboard:Widgets.TryGold.activate")}
                        </Button>
                    )}
                </Col>
            </Row>
        </Container>
    </Card>
);
