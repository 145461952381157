// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ContentBox, GrayGradientDiv } from "../../styled";
import { Container, CreditButton, CreditIcon, Text } from "./styled";
import { Credit } from "@Icons";
import { ICredits } from "./Credits";
// #endregion Local Imports

const Credits: React.FunctionComponent<ICredits.IProps> = ({
    type,
    onButtonClick,
    t,
}): JSX.Element => {
    const txt = (key: string) => t(`dashboard:HotOrNot.Credits.${type}.${key}`);
    return (
        <ContentBox>
            <GrayGradientDiv>
                <Container>
                    <CreditIcon>
                        <Credit width="72px" height="72px" />
                    </CreditIcon>
                    <Text type={type}>
                        <span>{txt("text")}</span>
                    </Text>
                    <CreditButton onClick={onButtonClick}>
                        <span>{txt("buttonText")}</span>
                    </CreditButton>
                </Container>
            </GrayGradientDiv>
        </ContentBox>
    );
};

export { Credits };
