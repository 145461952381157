// #region Global Imports
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic";
import { friendlyLocationLong, findButtonIcon } from "@Definitions/Friendly";
import { ApiStatus } from "@Interfaces/enums";
import {
    Container,
    AvatarContent,
    Time,
    New,
    Username,
    Location,
    Buttons,
    InfoContent,
    customAvatarStyle,
    DeleteButton,
    StyledIcon,
    WinkedStyledIcon,
    StyledDeleteIcon,
    StyledRemoveFriendIcon,
    StyledFriendIcon,
} from "./styled";
import { IProfileViewListItem } from "./ProfileViewListItem";
import { Avatar } from "@Components";
import { Preset, RedirectProfile } from "@Definitions";
import { ConversationIcon, WinkedIcon, WinkIcon, Trash } from "@Icons";
import { InboxActions } from "@Actions";
import { IStore } from "@Interfaces";
// #endregion Local Imports

const ProfileViewListItem: React.FunctionComponent<IProfileViewListItem.IProps> = ({
    data,
    t,
    hasDeleteButton,
    onDeleteButtonClick,
    onWinkButtonClick,
    onFriendButtonClick,
    hideInteractionButtons,
    hideAddFriendButton,
    analyticSource
}): JSX.Element => {
    const [wink, setWink] = React.useState(false);

    const status = useSelector((state: IStore) => state.interaction.status);

    const handleWink = () => {
        if (onWinkButtonClick) {
            onWinkButtonClick();
            setWink(true);
        }
    };

    const dispatch = useDispatch();

    const openChat = async (id: string) => {
        dispatch(await InboxActions.ShowChatModal(id, 50));
    };

    const handleFriend = () => {
        if (status !== ApiStatus.loading && onFriendButtonClick) {
            onFriendButtonClick();
        }
    };

    return (
        <Container
            onClick={async () => {
                await RedirectProfile(data.Profile.Id, analyticSource);
            }}
        >
            <AvatarContent>
                <Avatar
                    src={`${data.Profile.Photo.Url || ""}${Preset.thumb}`}
                    type={data.Profile.IsOnline ? "online" : "offline"}
                    alt="test"
                    customStyle={customAvatarStyle}
                />
            </AvatarContent>

            <InfoContent>
                {data.Date && (
                    <Time isSeen={!data.IsSeen}>
                        {dayjs(data.Date).format("LLL")}
                        {!data.IsSeen && (
                            <New>
                                &nbsp;
                                {t("common:Yeni")}
                            </New>
                        )}
                    </Time>
                )}
                <Username>{`${data.Profile.Username}, ${data.Profile.Age}`}</Username>
                <Location>
                    {friendlyLocationLong(data.Profile.Location, data.Profile.TownName)}
                </Location>
                <Buttons>
                    {!hideInteractionButtons && (
                        <div className="d-flex">
                            <IconButton
                                renderIcon={() => (
                                    <StyledIcon>
                                        <ConversationIcon width="40px" height="40px" />
                                    </StyledIcon>
                                )}
                                onIconClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                                    openChat(data.Profile.Id);
                                    e.stopPropagation();
                                }}
                            />

                            <IconButton
                                renderIcon={() =>
                                    wink ? (
                                        <WinkedStyledIcon>
                                            <WinkedIcon width="40px" height="40px" />
                                        </WinkedStyledIcon>
                                    ) : (
                                        <StyledIcon>
                                            <WinkIcon width="40px" height="40px" />
                                        </StyledIcon>
                                    )
                                }
                                onIconClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                                    e.stopPropagation();
                                    handleWink();
                                }}
                            />

                            {!hideAddFriendButton && (
                                <IconButton
                                    renderIcon={() =>
                                        data.IsFriend ? (
                                            <StyledFriendIcon>
                                                {findButtonIcon(data.Profile.Gender, data.IsFriend)}
                                            </StyledFriendIcon>
                                        ) : (
                                            <StyledRemoveFriendIcon>
                                                {findButtonIcon(data.Profile.Gender, data.IsFriend)}
                                            </StyledRemoveFriendIcon>
                                        )
                                    }
                                    onIconClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                                        e.stopPropagation();
                                        handleFriend();
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {hasDeleteButton && (
                        <DeleteButton>
                            <IconButton
                                renderIcon={() => (
                                    <StyledDeleteIcon>
                                        <Trash width="14" height="14px" />
                                    </StyledDeleteIcon>
                                )}
                                onIconClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                                    e.stopPropagation();
                                    if (onDeleteButtonClick) onDeleteButtonClick();
                                }}
                            />
                        </DeleteButton>
                    )}
                </Buttons>
            </InfoContent>
        </Container>
    );
};

export { ProfileViewListItem };
