// #region Global Imports
import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { TextInput, Button } from "@Components/Basic";
import { FormBox } from "@Components";
import { Password } from "@Icons";
import { IResetPassword } from "./ResetPassword";
import { Container, PasswordForm } from "./styled";
import { HomeActions, AccountActions } from "@Actions";
// #endregion Local Imports

const ResetPassword: React.FunctionComponent<IResetPassword.IProps> = (props): JSX.Element => {
    const { t, secureKey } = props;

    const dispatch = useDispatch();

    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, t("home:resetPassword.passwordMinError"))
            .required(t("home:resetPassword.passwordError"))
            .label("password"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], t("home:resetPassword.confirmPasswordError"))
            .required(t("home:resetPassword.passwordError"))
            .label("confirmPassword"),
    });

    const { register, handleSubmit, errors, formState } = useForm<IResetPassword.FormValues>({
        mode: "onBlur",
        validationSchema: ResetPasswordSchema,
    });

    const onSubmit = (values: IResetPassword.FormValues) => {
        dispatch(AccountActions.ResetPassword(secureKey, values.password));
    };

    return (
        <Container>
            <FormBox
                isLarge={false}
                title={t("home:resetPassword.title")}
                onCloseClick={() => {
                    dispatch(HomeActions.SetTopComponent({ topComponent: "login" }));
                }}
                onHandleSubmit={() => {
                    console.log("test");
                }}
            >
                <PasswordForm onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <TextInput
                            autoFocus
                            isValid={
                                errors.password
                                    ? !errors.password
                                    : formState.touched.password
                                    ? true
                                    : undefined
                            }
                            placeholder={t("home:resetPassword.placeholder")}
                            renderIcon={() => (
                                <Password width="38px" height="38px" />
                            )}
                            type="password"
                            id="password"
                            name="password"
                            height="auto"
                            errorText={errors.password ? errors.password.message : ""}
                            _ref={register()}
                        />
                    </div>
                    <div className="mb-3">
                        <TextInput
                            isValid={
                                errors.confirmPassword
                                    ? !errors.confirmPassword
                                    : formState.touched.confirmPassword
                                    ? true
                                    : undefined
                            }
                            placeholder={t("home:resetPassword.confirmPlaceholder")}
                            renderIcon={() => (
                                <Password width="38px" height="38px" />
                            )}
                            type="password"
                            height="auto"
                            id="confirmPassword"
                            name="confirmPassword"
                            errorText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                            _ref={register}
                        />
                    </div>

                    <Button
                        onClick={e => {
                            if (formState.isValid) {
                                handleSubmit(onSubmit)(e);
                            }
                        }}
                        disabled={!formState.isValid || formState.isSubmitting}
                        type="submit"
                    >
                        {t("home:resetPassword.save")}
                    </Button>
                </PasswordForm>
            </FormBox>
        </Container>
    );
};

export { ResetPassword };
