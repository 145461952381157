// #region Global Imports
import * as React from "react";
import dayjs from "dayjs";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    AvatarContainer,
    LastLogin,
    BubbleContainer,
    ProfileProperties,
    Username,
    IconInfo,
    IconInfoSpan,
    ZodiacContainer,
    ButtonContainer,
    ArrowIcon,
    customAvatarStyle,
    customBubbleStyle,
    customProfileIntButton,
    ProfileUsernameCss,
    ProfilePropertiesCustomCss,
} from "./styled";
import { Avatar, InfoBubble, ProfileInteractionsButtonContainer } from "@Components";
import { SliderHandleDistance, RelationshipstatusIcon, JobIcon } from "@Icons";
import { useScreenSize, friendlyBubbleText, Preset, findZodiacbyId, theme } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IProfileCard } from "./ProfileCard";
// #endregion Interface Imports

export const ProfileCard: React.FunctionComponent<IProfileCard.IProps> = ({
    t,
    profile,
    isModal,
    onBackClick,
}) => {
    const screenSize = useScreenSize();
    const { ZodiacId } = profile;

    const renderMediumInfoContent = () => {
        if (screenSize.includes("medium") && !isModal) {
            return true;
        }
        if (screenSize.includes("small")) {
            return true;
        }
        return false;
    };

    const shouldRender = screenSize.includes("large") || (isModal && !screenSize.includes("xs"));

    return (
        <Container isModal={isModal} gender={profile.Gender}>
            {screenSize.includes("small") && isModal && (
                <ArrowIcon
                    data-cy="profilecard-back-btn"
                    className="icon chevron-left"
                    onClick={onBackClick}
                />
            )}

            {screenSize !== "xsmall" && (
                <AvatarContainer isModal={isModal}>
                    <Link href="/profile/[id]?source=conversation" as={`/profile/${profile.Id}`}>
                        <a>
                            <Avatar
                                customStyle={customAvatarStyle(isModal)}
                                type={profile.IsOnline ? "online" : "offline"}
                                src={`${profile.Photo?.Url}${Preset.thumb2x}`}
                                alt="profile-picture"
                            />
                        </a>
                    </Link>
                </AvatarContainer>
            )}
            {shouldRender && (
                <BubbleContainer>
                    <LastLogin>
                        {t("common:inbox.profile-card.last-login").toUpperCase()}
                        {dayjs(profile.LastSeenOn).fromNow()}
                    </LastLogin>
                    {profile.StatusMessage && (
                        <InfoBubble
                            fullBubble={false}
                            customStyle={customBubbleStyle}
                            text={friendlyBubbleText(profile.StatusMessage, 70)}
                        />
                    )}
                </BubbleContainer>
            )}

            <ProfileProperties customStyle={ProfilePropertiesCustomCss(isModal)}>
                <Link href="/profile/[id]?source=conversation" as={`/profile/${profile.Id}`}>
                    <Username
                        href={`/profile/${profile.Id}`}
                        className="mb-0 mb-lg-4"
                        customStyle={ProfileUsernameCss}
                    >
                        {`${profile.Username}, ${profile.Age}`}
                    </Username>
                </Link>

                {!screenSize.includes("small") && !(screenSize.includes("medium") && !isModal) && (
                    <>
                        <IconInfo>
                            <SliderHandleDistance width="11px" height="19px" />
                            <IconInfoSpan>{`${profile.Location} ${profile.TownName}`}</IconInfoSpan>
                        </IconInfo>
                        <ZodiacContainer className="profile-card">
                            <IconInfo>
                                {findZodiacbyId(ZodiacId)}
                                <IconInfoSpan>{profile.Zodiac}</IconInfoSpan>
                            </IconInfo>
                            {profile.MaritalStatus && (
                                <IconInfo>
                                    <RelationshipstatusIcon
                                        width="18px"
                                        height="19px"
                                        color={theme.colors.white}
                                    />
                                    <IconInfoSpan>{profile.MaritalStatus}</IconInfoSpan>
                                </IconInfo>
                            )}
                        </ZodiacContainer>
                        {profile.Job && (
                            <IconInfo>
                                <JobIcon width="25px" height="19px" color={theme.colors.white} />
                                <IconInfoSpan>{profile.Job}</IconInfoSpan>
                            </IconInfo>
                        )}
                    </>
                )}
                {renderMediumInfoContent() && (
                    <>
                        <IconInfoSpan>{profile.Location}</IconInfoSpan>
                        <ZodiacContainer className="profile-card">
                            {profile.Zodiac && <IconInfoSpan>{profile.Zodiac}</IconInfoSpan>}
                            {profile.MaritalStatus && (
                                <IconInfoSpan>{profile.MaritalStatus}</IconInfoSpan>
                            )}
                            {profile.Job && <IconInfoSpan>{profile.Job}</IconInfoSpan>}
                        </ZodiacContainer>
                    </>
                )}
            </ProfileProperties>

            <ButtonContainer>
                <ProfileInteractionsButtonContainer
                    customStyle={customProfileIntButton}
                    IsFriend={profile.IsFriend}
                    inConversation
                    Gender={profile.Gender}
                    userId={profile.Id}
                />
            </ButtonContainer>
        </Container>
    );
};
