// #region Global Imports
import * as React from "react";
import { css, CSSProp } from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { friendlyLocation } from "@Definitions/Friendly/Location";
import Link from "next/link";
import { Container, Friend, Item, Text, HR, Comma } from "./styled";
import { Avatar, IconBlockButton, Text as Label } from "@Components";
// #endregion Local Imports

// #region Interface Imports
import { IOnlineFriends } from "./OnlineFriends";
// #endregion Interface Imports

const customStyle: CSSProp = css`
    width: 30px;
    height: 30px;
`;

const OnlineFriends: React.FunctionComponent<IOnlineFriends.IProps> = ({
    t,
    friendList,
}): JSX.Element => {
    const getKey = (index: number) => `friends-${index}`;
    const renderOnlineFriends = () => {
        return friendList.slice(0, 5).map((item, index) => (
            <Friend key={getKey(index)}>
                <Item>
                    <Link href="/profile/[id]?source=friends list" as={`/profile/${item.Id}`}>
                        <a>
                            <Avatar
                                type="online"
                                src={item.Profile.Photo.Url}
                                alt={
                                    item.Profile.Photo.PhotoName
                                        ? item.Profile.Photo.PhotoName
                                        : item.Profile.Username
                                }
                                customStyle={customStyle}
                            />
                            <Text hideText>{item.Profile.Username}</Text>
                            <Comma>,</Comma>
                            <Text>{item.Profile.Age}</Text>
                            <Comma>,</Comma>
                            <Text hideText>
                                {friendlyLocation(item.Profile.Location, item.Profile.TownName)}
                            </Text>
                        </a>
                    </Link>
                </Item>
                <HR />
            </Friend>
        ));
    };
    return (
        <Container>
            {renderOnlineFriends()}
            <Link href="/friends" as="/friends">
                <a>
                    <IconBlockButton variant="text" fullWidth>
                        <Label color="lightGray">{t("common:Header.buttons.all")}</Label>
                    </IconBlockButton>
                </a>
            </Link>
        </Container>
    );
};

export { OnlineFriends };
