// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { AccountModel } from "@Interfaces";
// #endregion Interface Imports
type ResignRequestPayload = AccountModel.DeactivateAccountPayload;
export const AccountService = {
    EstimateLocation: async (): Promise<AccountModel.EstimateLocationData> => {
        return Http.Request<AccountModel.EstimateLocationData>(
            "GET",
            "/metadata/EstimateLocation",
            undefined,
            undefined
        );
    },
    Register: async (
        payload: AccountModel.RegisterPayload
    ): Promise<AccountModel.RegisterResponse> => {
        const locationData: AccountModel.EstimateLocationData =
            await AccountService.EstimateLocation();

        const { CountryId, CityId, TownId } = locationData;
        try {
            const response: AccountModel.RegisterResponse = await Http.Request(
                "POST",
                "/account/Register",
                undefined,
                {
                    ...payload.body,
                    CountryId,
                    CityId,
                    TownId,
                }
            );

            return response;
        } catch (e) {
            return {
                UserId: "-1",
                Token: "",
                Reason: "Fail",
                ReasonId: 1,
                Success: false,
                Id: "",
                Url: null,
                Coin: null,
                Errors: null,
            };
        }
    },
    Login: async (payload: AccountModel.LoginPayload): Promise<AccountModel.LoginResponse> => {
        let response: AccountModel.LoginResponse;

        try {
            response = await Http.Request<AccountModel.LoginResponse>(
                "POST",
                "/account/login",
                undefined,
                payload.body
            );
        } catch (error) {
            response = {
                UserId: "-1",
                Token: "",
                ForceUpdate: false,
                Reason: "Fail",
                ReasonId: 1,
                HasActiveSubscription: false,
            };

            if (error.status === 409 && error.statusText === "Conflict") {
                response.Reason = "Captcha";
            }
        }

        return response;
    },
    OnlineUserCount: async (): Promise<AccountModel.OnlineUserCountResponse> => {
        let response: AccountModel.OnlineUserCountResponse;

        try {
            response = await Http.Request<AccountModel.OnlineUserCountResponse>(
                "POST",
                "/account/OnlineUserCount",
                undefined
            );
        } catch (error) {
            console.log(error);
            response = {
                Success: false,
                Count: 0,
                MaleCount: 0,
                FemaleCount: 0,
            };
        }

        return response;
    },
    CheckUsername: async (
        payload: AccountModel.CheckUsernamePayload
    ): Promise<AccountModel.CheckUsernameResponse> => {
        let response: AccountModel.CheckUsernameResponse;

        try {
            response = await Http.Request<AccountModel.CheckUsernameResponse>(
                "POST",
                "/account/checkusername",
                undefined,
                payload.body
            );
        } catch (error) {
            response = {
                Reason: "",
                ReasonId: -1,
                Success: false,
                Ok: false,
            };
        }

        return response;
    },
    CheckEmail: async (
        payload: AccountModel.CheckEmailPayload
    ): Promise<AccountModel.CheckEmailResponse> => {
        let response: AccountModel.CheckEmailResponse;

        try {
            response = await Http.Request<AccountModel.CheckEmailResponse>(
                "POST",
                "/account/checkemail",
                undefined,
                payload.body
            );
        } catch (error) {
            response = {
                Reason: "",
                ReasonId: -1,
                Success: false,
                Ok: false,
            };
        }

        return response;
    },
    ForgotPassword: async (
        payload: AccountModel.ForgotPasswordPayload
    ): Promise<AccountModel.ForgotPasswordResponse> => {
        let response: AccountModel.ForgotPasswordResponse;

        try {
            response = await Http.Request<AccountModel.ForgotPasswordResponse>(
                "POST",
                "/account/forgotpassword",
                undefined,
                payload.body
            );
        } catch (error) {
            response = {
                Success: true,
            };
        }

        return response;
    },
    LoginWithApple: async (
        payload: AccountModel.AppleLoginPayload
    ): Promise<AccountModel.AppleLoginResponse> => {
        return await Http.Request<AccountModel.AppleLoginResponse>(
            "POST",
            "/account/applelogin",
            undefined,
            payload
        );
    },
    LoginWithFacebook: async (
        payload: AccountModel.FacebookLoginPayload
    ): Promise<AccountModel.FacebookLoginResponse> => {
        let response: AccountModel.FacebookLoginResponse;

        try {
            response = await Http.Request<AccountModel.FacebookLoginResponse>(
                "POST",
                "/account/facebooklogin",
                undefined,
                payload.body
            );
        } catch (error) {
            response = {
                UserId: "-1",
                Token: "",
                ForceUpdate: false,
                Reason: "Fail",
                ReasonId: 1,
                HasActiveSubscription: false,
            };
        }

        return response;
    },
    RegisterWithFacebook: async (
        payload: AccountModel.FacebookRegisterPayload
    ): Promise<AccountModel.FacebookRegisterResponse> => {
        const locationData: AccountModel.EstimateLocationData =
            await AccountService.EstimateLocation();

        const { CountryId, CityId, TownId } = locationData;
        try {
            const response: AccountModel.FacebookRegisterResponse = await Http.Request(
                "POST",
                `/account/FacebookRegister`,
                undefined,
                {
                    ...payload.body,
                    CountryId,
                    CityId,
                    TownId,
                }
            );

            return response;
        } catch (e) {
            console.error(e);
            return {
                UserId: "-1",
                Token: "",
                Reason: "Fail",
                ReasonId: 1,
                Success: false,
                Id: "",
                Url: null,
                Coin: null,
                Errors: null,
            };
        }
    },
    RegisterWithApple: async (
        payload: AccountModel.AppleRegisterPayload
    ): Promise<AccountModel.AppleRegisterResponse> => {
        const locationData: AccountModel.EstimateLocationData =
            await AccountService.EstimateLocation();

        const { CountryId, CityId, TownId } = locationData;
        try {
            const response: AccountModel.AppleRegisterResponse = await Http.Request(
                "POST",
                `/account/AppleRegister`,
                undefined,
                {
                    ...payload.body,
                    CountryId,
                    CityId,
                    TownId,
                }
            );

            return response;
        } catch (e) {
            console.error(e);
            return {
                UserId: "-1",
                Token: "",
                Reason: "Fail",
                ReasonId: 1,
                Success: false,
                Id: "",
                Url: null,
                Coin: null,
                Errors: null,
            };
        }
    },
    ResetPassword: async (
        payload: AccountModel.ResetPasswordPayload
    ): Promise<AccountModel.ResetPasswordResponse> => {
        try {
            const response: AccountModel.ResetPasswordResponse = await Http.Request(
                "POST",
                "/account/ChangePasswordBySecureKey",
                undefined,
                payload.body
            );

            return response;
        } catch (e) {
            return {
                Success: false,
                Id: "",
                Token: "",
            };
        }
    },
    ChangePassword: async (
        payload: AccountModel.ChangePasswordPayload
    ): Promise<AccountModel.IChangePasswordResponseData> => {
        try {
            const response: AccountModel.IChangePasswordResponseData = await Http.Request(
                "POST",
                "/account/changepassword",
                undefined,
                payload.body
            );
            return response;
        } catch (e) {
            return {
                Reason: "error",
                Success: false,
                Token: "",
                UserId: "",
            };
        }
    },
    ChangeMail: async (
        payload: AccountModel.UpdateEmailRequestPayload
    ): Promise<AccountModel.IUpdateEmailResponseData> => {
        try {
            const response: AccountModel.IUpdateEmailResponseData = await Http.Request(
                "POST",
                "/account/UpdateEmail",
                undefined,
                payload
            );
            return response;
        } catch (e) {
            return {
                Token: "",
                UserId: "",
                Reason: "error",
                Success: false,
            };
        }
    },
    Resign: async (payload: ResignRequestPayload): Promise<AccountModel.IResignResponseData> => {
        try {
            const response: AccountModel.IResignResponseData = await Http.Request(
                "POST",
                "/account/resign",
                undefined,
                payload
            );
            return response;
        } catch (e) {
            return {
                Success: false,
                Reason: "",
            };
        }
    },
    LoginWithSecureToken: async (Key: string): Promise<AccountModel.LoginResponse> => {
        return Http.Request("POST", "/account/LoginWithSecureToken", undefined, {
            Key,
        });
    },
};
