import * as React from "react";

const SvgGoback = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 14 11" {...props}>
        <path
            d="M3.624 5.321a.5.5 0 11-.707.708L.796 3.907a.5.5 0 010-.707L2.917 1.08a.5.5 0 11.707.707l-1.27 1.267H9.34A3.973 3.973 0 0113.31 6.83l.005.198A3.973 3.973 0 019.34 11h0-3.717a.5.5 0 010-1H9.34a2.973 2.973 0 100-5.946h0-6.984L3.624 5.32z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.2}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgGoback;
