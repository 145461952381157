export const FormatHelper = (value: number, type: "currency" | "decimal") => {
    let formattedValue;

    switch (type) {
        case "currency":
            formattedValue = value.toLocaleString("tr-TR", { style: "currency", currency: "TRY" });
            break;
        case "decimal":
            formattedValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            break;
        default:
            formattedValue = value;
            break;
    }

    return formattedValue;
};

const clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
};

export const FormatCreditCardNumber = (value: string) => {
    if (!value) {
        return value;
    }

    const clearValue = clearNumber(value);

    return `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12
    )} ${clearValue.slice(12, 16)}`.trim();
};

export const FormatCvcNumber = (value: string) => {
    const clearValue = clearNumber(value);
    const maxLength = 4;

    return clearValue.slice(0, maxLength);
};
