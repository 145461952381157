import * as React from "react";

const SvgNewMatchIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 20 18" {...props}>
        <path
            d="M15.503 10.768l1.49 1.342-.274.267-.175.194c-.904.963-3.023 2.707-6.232 5.045a.64.64 0 01-.304.076h-.086c-.156 0-.235 0-.39-.076l-.685-.505a108.865 108.865 0 01-2.8-2.14l1.368-1.468c.488.385 1.032.806 1.631 1.258l.878.659.888-.664c2.09-1.577 3.578-2.819 4.235-3.512l.153-.169.125-.132.178-.175zM14.297 0c1.465 0 2.724.467 3.776 1.401l.208.194C19.375 2.658 19.921 3.948 20 5.39c0 .987-.156 1.974-.547 2.886a8.513 8.513 0 01-1.015 2.05 6.72 6.72 0 01-.171.237L16.77 9.218l.161-.243c.236-.382.452-.83.63-1.347l.141-.364c.166-.458.262-.97.289-1.532L18 5.447l-.022-.236c-.103-.853-.457-1.566-1.09-2.182C16.168 2.33 15.341 2 14.296 2c-1.154 0-2.076.454-2.814 1.376L9.88 5.38 8.33 3.334C7.635 2.421 6.756 2 5.546 2c-.974 0-1.799.335-2.512 1.029C2.308 3.735 2 4.44 2 5.39c0 .78.124 1.546.36 2.237.24.7.467 1.208.653 1.502l.113.14 1.163 1.45.23.223.124.132c.235.274.645.663 1.221 1.158L4.5 13.696c-.635-.55-1.092-.989-1.375-1.319l-.313-.304-.384-.474c-.414-.518-.718-.918-1.022-1.272-.312-.456-.625-1.14-.937-2.05A8.944 8.944 0 010 5.39c0-1.518.547-2.733 1.64-3.796C2.735.532 4.064 0 5.548 0c1.797 0 3.281.683 4.375 2.126C11.016.76 12.5 0 14.297 0z"
            fill="currentcolor"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgNewMatchIcon;
