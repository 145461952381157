import * as React from "react";

const SvgCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <path
            d="M17.456.485a1.5 1.5 0 010 2.122L11.093 8.97l6.363 6.365a1.5 1.5 0 11-2.121 2.12L8.97 11.092l-6.364 6.365a1.5 1.5 0 11-2.122-2.121L6.85 8.97.485 2.607A1.5 1.5 0 112.607.485L8.97 6.85 15.335.485a1.5 1.5 0 012.12 0z"
            fill="currentcolor"
            stroke="#fff"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCloseIcon;
