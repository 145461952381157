import * as React from "react";

const SvgCoinLightIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
        <path
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM2 12c0 5.185 3.947 9.449 9 9.95v-3.02a7.002 7.002 0 010-13.86V2.05C5.947 2.55 2 6.814 2 12zm11.001-9.95V5.07c1.473.211 2.798.88 3.825 1.859L14.101 9.2a3.5 3.5 0 10.374 5.275l2.475 2.475A6.98 6.98 0 0113 18.929v3.022C18.054 21.448 22 17.185 22 12c0-5.185-3.946-9.448-8.999-9.95z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCoinLightIcon;
