// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import {
    Container,
    CampaignName,
    CampaignPrice,
    RectangleContainer,
    CampaignText,
    MobileContainer,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IGoldPackageSkeleton } from "./GoldPackageSkeleton";
// #endregion Interface Imports

export const GoldPackageSkeleton: React.FC<IGoldPackageSkeleton.IProps> = ({ isMobile }) => {
    const renderMobile = () => (
        <MobileContainer>
            <CampaignName>
                <CampaignText isMobile={isMobile}>
                    <RectangleContainer>
                        <RectangleSkeleton width="115" height="30" />
                    </RectangleContainer>
                    <RectangleContainer>
                        <RectangleSkeleton width="115" height="18" />
                    </RectangleContainer>
                </CampaignText>
            </CampaignName>
            <CampaignPrice>
                <RectangleSkeleton width="112" height="18" />
            </CampaignPrice>
        </MobileContainer>
    );

    const renderDesktop = () => (
        <>
            <CampaignName>
                <CampaignText isMobile={isMobile}>
                    <RectangleSkeleton width="142" height="45" />
                    <RectangleSkeleton width="142" height="18" />
                </CampaignText>
            </CampaignName>
            <div className="d-md-flex d-none flex-column align-items-center">
                <RectangleContainer>
                    <RectangleSkeleton width="35" height="18" />
                </RectangleContainer>
                <RectangleContainer>
                    <RectangleSkeleton width="122" height="45" />
                </RectangleContainer>
            </div>
            <CampaignPrice>
                <RectangleSkeleton width="147" height="22" />
            </CampaignPrice>
        </>
    );
    return <Container isActive={false}>{isMobile ? renderMobile() : renderDesktop()}</Container>;
};
