// #region Global Imports
import React, { useState, useRef } from "react";
/* import { Line } from "rc-progress"; */
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Col, Row } from "@Styled/Grid";
import {
    MainContainer,
    PhotoContainer,
    SideCardContainer,
    SideCardUp,
    SideCardFooter,
    LargeText,
    customFormBoxStyle,
    ProgressBarContainer,
    SideCardText,
    TextCard,
    TextFooter,
    DropzoneContainer,
    ButtonsContainer,
    customBlockButtonCss,
    UploadPhotoSliderContainer,
    SliderWrapper,
    TextContainer,
    customContainerStyle,
    LoadingContainer,
    PhotoModal,
} from "./styled";
import {
    FormBox,
    Text,
    PhotoUploadOptions,
    UploadPhotoSlider,
    IconBlockButton,
    UploadFacebookPhotos,
    Loading,
} from "@Components";
import { useScreenSize, theme } from "@Definitions";
import { DoubleHeart } from "@Icons";
import { ProfileActions, CommonActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { IProfilePhotoUpload } from "./ProfilePhotoUpload";
import { PhotoModel, IStore } from "@Interfaces";
// #endregion Interface Imports

export const ProfilePhotoUpload: React.FunctionComponent<IProfilePhotoUpload.IProps> = ({ t }) => {
    const screenSize = useScreenSize();

    const { uploadPhotoType, self } = useSelector((state: IStore) => state.profile);
    const photos = self?.Photos;

    const dispatch = useDispatch();
    const [selectedPhoto, setSelectedPhoto] = useState(self?.Photo);
    const dropzoneRef: React.MutableRefObject<any> = useRef();
    const addButtonRef: React.MutableRefObject<any> = useRef();
    const hasProfileImage = (photos?.length || 0) > 0;
    const [showLoading, setShowLoading] = useState(false);

    React.useEffect(() => {
        setShowLoading(false);
        if (photos?.length === 1) {
            setSelectedPhoto(photos[0]);
        }
    }, [photos]);

    const onDropPhoto = async (acceptedFiles: Array<Blob>) => {
        setShowLoading(true);
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        dispatch(await ProfileActions.UploadProfilePhoto(formData));
    };

    const onRemovePhoto = async (id: string) => {
        const validateRemove = window.confirm(t("common:photoUpload.confirmDelete"));
        if (validateRemove) {
            const photoIndex = photos!.findIndex(item => item.Id === id);
            const newSelectedPhotoIndex =
                photos?.length === photoIndex + 1 ? photoIndex - 1 : photoIndex + 1;

            await dispatch(await ProfileActions.RemovePhoto(id));

            if (photos && newSelectedPhotoIndex >= 0) {
                setSelectedPhoto(photos[newSelectedPhotoIndex]);
            } else {
                setSelectedPhoto(self?.Photo);
            }
        }
    };

    const SetPrimaryPhoto = async (id: string) => {
        await dispatch(await ProfileActions.SetPrimaryPhoto(id));
        await dispatch(await ProfileActions.GetProfile());
        window.confirm(t("common:photoUpload.updatedProfile"));
    };

    const onCloseClick = async () => {
        dispatch(await CommonActions.SetShowUploadPhoto(false));
    };

    const renderLoadingComponent = () => (
        <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "100%" }}
        >
            <Loading spinner />
        </div>
    );

    return (
        <PhotoModal height={window.innerHeight}>
            <MainContainer>
                {uploadPhotoType === "fromFacebook" && (
                    <UploadFacebookPhotos t={t} onCloseClick={onCloseClick} />
                )}
                {uploadPhotoType === "edit" && (
                    <FormBox
                        customChildrenStyle={customFormBoxStyle}
                        customContainerStyle={customContainerStyle}
                        title={t("common:photoUpload.formboxTitle")}
                        onCloseClick={onCloseClick}
                        isDismissible
                    >
                        <Row className="no-gutters">
                            <Col className="col-lg-9 col-md-12">
                                {showLoading ? (
                                    <LoadingContainer>{renderLoadingComponent()}</LoadingContainer>
                                ) : (
                                    <PhotoContainer>
                                        <TextContainer>
                                            <Text size="small" weight="medium">
                                                {t("common:photoUpload.title")}
                                            </Text>
                                        </TextContainer>
                                        {(screenSize === "small" || screenSize === "xsmall") && (
                                            <PhotoUploadOptions
                                                buttonText={t("common:photoUpload.addPhoto")}
                                                optionType="small"
                                                _ref={addButtonRef}
                                                _dropzoneRef={dropzoneRef}
                                                t={t}
                                            />
                                        )}
                                        <DropzoneContainer>
                                            {hasProfileImage &&
                                                (screenSize === "xlarge" ||
                                                    screenSize === "large" ||
                                                    screenSize === "medium") && (
                                                    <PhotoUploadOptions
                                                        t={t}
                                                        buttonText={t("common:photoUpload.add")}
                                                        optionType="small"
                                                        _ref={addButtonRef}
                                                        _dropzoneRef={dropzoneRef}
                                                    />
                                                )}
                                            {!hasProfileImage && <div />}

                                            <Dropzone
                                                ref={dropzoneRef}
                                                onDrop={(acceptedFiles: any) =>
                                                    onDropPhoto(acceptedFiles)
                                                }
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <p className="dropzone-title">
                                                                {" "}
                                                                {t("common:photoUpload.drag")}
                                                            </p>
                                                            <img
                                                                src={`${selectedPhoto?.Url}?&preset=siberthumb`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </DropzoneContainer>

                                        {!hasProfileImage &&
                                            (screenSize === "xlarge" ||
                                                screenSize === "large" ||
                                                screenSize === "medium") && (
                                                <PhotoUploadOptions
                                                    buttonText={t("common:photoUpload.add")}
                                                    optionType="large"
                                                    _ref={addButtonRef}
                                                    _dropzoneRef={dropzoneRef}
                                                    t={t}
                                                />
                                            )}
                                        {hasProfileImage && (
                                            <UploadPhotoSliderContainer>
                                                <ButtonsContainer>
                                                    {selectedPhoto?.State === "Approved" && (
                                                        <IconBlockButton
                                                            customStyle={customBlockButtonCss}
                                                            color={theme.colors.lightGray}
                                                            variant="outlined"
                                                            iconClick={() =>
                                                                SetPrimaryPhoto(selectedPhoto?.Id)
                                                            }
                                                        >
                                                            {t("common:photoUpload.setPhoto")}
                                                        </IconBlockButton>
                                                    )}
                                                    <IconBlockButton
                                                        customStyle={customBlockButtonCss}
                                                        color={theme.colors.lightGray}
                                                        variant="outlined"
                                                        iconClick={() =>
                                                            onRemovePhoto(selectedPhoto?.Id || "")
                                                        }
                                                    >
                                                        {t("common:photoUpload.remove")}
                                                    </IconBlockButton>
                                                </ButtonsContainer>

                                                <SliderWrapper>
                                                    <UploadPhotoSlider
                                                        t={t}
                                                        photos={photos}
                                                        getSelectedPhoto={(
                                                            item: PhotoModel.IPhoto
                                                        ) => setSelectedPhoto(item)}
                                                        onRemovePhoto={(id: string) =>
                                                            onRemovePhoto(id)
                                                        }
                                                    />
                                                </SliderWrapper>
                                            </UploadPhotoSliderContainer>
                                        )}

                                        <ProgressBarContainer>
                                            {/*  <Line
                                        percent={progressTime}
                                        strokeWidth={1}
                                        strokeColor={[
                                            "#87d068",
                                            {
                                                "100%": "#108ee9",
                                                "10%": "red",
                                                "0%": "#87d068",
                                            },
                                        ]}
                                        strokeLinecap="butt"
                                    /> */}
                                        </ProgressBarContainer>
                                    </PhotoContainer>
                                )}
                            </Col>
                            <Col className="col-lg-3">
                                <SideCardContainer>
                                    <SideCardUp>
                                        <DoubleHeart
                                            width="66px"
                                            height="58px"
                                            color={theme.colors.white}
                                            className="mb-1"
                                        />
                                        <SideCardText>
                                            <LargeText>
                                                {t("common:photoUpload.quickAdd")}
                                            </LargeText>
                                            <TextCard>{t("common:photoUpload.morePhoto")}</TextCard>
                                        </SideCardText>
                                    </SideCardUp>
                                </SideCardContainer>
                            </Col>
                        </Row>
                    </FormBox>
                )}
            </MainContainer>
        </PhotoModal>
    );
};
