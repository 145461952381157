// #region Global Imports
import * as React from "react";
// #endregion Global Imports

export const Container: React.FunctionComponent<{ className?: string; cy?: string }> = ({
    children,
    className,
    cy,
}) => (
    <div data-cy={cy || ""} className={`${className || ""} container`}>
        {children}
    </div>
);

export const Row: React.FunctionComponent<{ className?: string; cy?: string }> = ({
    children,
    className,
    cy,
}) => (
    <div data-cy={cy || ""} className={`${className || ""} row`}>
        {children}
    </div>
);

export const Col: React.FunctionComponent<{ className: string; cy?: string }> = ({
    children,
    className,
    cy,
}) => (
    <div data-cy={cy || ""} className={className || ""}>
        {children}
    </div>
);

Container.displayName = "Container";
Row.displayName = "Row";
Col.displayName = "Col";
