// #region Global Imports
import styled, { css } from "styled-components";
import { Dropdown, Anchor, Text } from "@Components/Basic";
// #endregion Global Imports

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 495px;
    width: 730px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 40px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 690px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: unset;
        height: 100%;
        min-height: unset;
        border-radius: unset;
    }
`;

export const ChildrenContainer = styled.div`
    flex: 1;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        flex: unset;
        height: 100%;
        padding: 32px 0 96px;
        overflow-y: auto;
    }
`;

export const BoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;
    width: 100%;
    padding-left: 10px;
    padding-right: 25px;
    border-radius: 30px 30px 0 0;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    background: url("/images/multiple-arrow.png") no-repeat center,
        ${({ theme }) => theme.gradients.greenToBlue} no-repeat center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: -0.38px;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        border-radius: unset;
    }
`;

export const StyleIcon = styled.div`
    :hover {
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.3));
    }

    :active {
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.1));
    }
`;

export const ButtonIcon = styled.i`
    font-size: 20px;
    padding-top: 4px;
`;

export const Header = styled(Text)`
    width: 80%;
    margin: 0 0 32px;
    text-align: center;
    font-size: 30px;
`;

export const ButtonContainer = styled.div`
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        button {
            padding: 0.625rem 2rem;
            box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.3);
        }
    }
`;

export const IncomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
`;

export const DropdownSelector = styled(Dropdown)`
    width: 200px;
`;

export const BoxFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 48px 48px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
    color: #c9ced5;

    /* Button Surroundings */
    > div {
        &:nth-child(odd) {
            display: flex;
            justify-content: flex-end;
            min-width: 96px;

            @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
                min-width: unset;

                &:first-child {
                    justify-content: center;
                    position: fixed;
                    top: 80px;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, 0.92);
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

                    > div {
                        padding: 8px 16px;
                    }
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        position: fixed;
        bottom: 0;
        padding: 0 16px 32px;
        justify-content: center;
    }
`;

export const BoxFooterRight = styled.span`
    align-self: flex-end;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.azureRadianceDark};
    }
`;

export const InterestContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: scroll;
    padding: 32px 0;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        overflow: unset;
        padding: unset;

        > [class*="InnerContainer"] {
            display: block;
        }
    }
`;

export const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    .content {
        width: 350px;
    }
`;

export const TextAreaFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 700;
    color: #c9ced5;
    margin-top: 15px;
`;

export const FooterRight = styled.div`
    display: flex;

    svg {
        margin-left: 5px;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.azureRadianceDark};
    }
`;

export const FooterSpan = styled(Anchor)`
    &:hover {
        color: ${({ theme }) => theme.colors.azureRadianceDark};
        cursor: pointer;
    }
`;

export const customAvatarStyle = css`
    width: 60px;
    height: 60px;

    span {
        font-size: 16px;
    }
`;
