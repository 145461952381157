// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { SeoModel } from "@Interfaces";
// #endregion Interface Imports

export const SeoService = {
    Meta: async (query: string): Promise<SeoModel.Meta> => {
        return Http.Request("GET", `/seo/get`, {
            Text: query,
        });
    },
};
