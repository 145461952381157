import * as React from "react";

const SvgCoinDark = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 6C11.373 6 6 11.373 6 18s5.373 12 12 12 12-5.373 12-12S24.627 6 18 6zm-1 2.05v3.02a7.002 7.002 0 000 13.86v3.02c-5.053-.501-9-4.765-9-9.95s3.947-9.449 9-9.95zm2.001 0C24.054 8.551 28 12.814 28 18s-3.946 9.448-8.999 9.95V24.93a6.98 6.98 0 003.949-1.98l-2.475-2.474A3.5 3.5 0 1120.1 15.2l2.725-2.27a6.978 6.978 0 00-3.825-1.859z"
            fill="#8791A1"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCoinDark;
