import * as React from "react";

const SvgManCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 49 49" {...props}>
        <path
            d="M24.5.092c13.255 0 24 10.746 24 24 0 13.255-10.745 24-24 24s-24-10.745-24-24c0-13.254 10.745-24 24-24zm0 1.808c-12.255 0-22.19 9.936-22.19 22.192 0 5.123 1.736 9.841 4.651 13.597l-.057.022c.743-.295 1.337-.443 1.783-.737.446-.295 1.189-.443 2.08-.738.892-.295 1.635-.59 1.932-.737l5.795-2.655v-4.425c-1.04-.884-1.783-2.654-2.229-5.456-.445.147-1.04-.148-1.486-.738-.445-.59-.743-1.327-.891-1.917v-1.033c0-.295.148-.737.148-1.18 0-.442.298-.737.446-1.032a1.14 1.14 0 01.743-.295c-.297-2.212-.446-3.834-.446-4.867.298-1.917 1.338-3.54 3.121-5.014 1.783-1.475 4.012-2.213 6.538-2.213 2.526 0 4.755.738 6.539 2.213C32.76 8.362 33.8 9.984 33.8 11.9c.149.738 0 2.36-.297 4.867.594-.147.891.295 1.189 1.033.297.884.297 1.622.297 2.36 0 .59-.297 1.18-.743 1.77-.595.737-1.04.884-1.635.884-.148 1.18-.446 2.36-.891 3.245-.446.885-.743 1.475-1.04 1.77l-.298.294v4.278c.595.294 1.486.59 2.824 1.18l1.486.442c2.982 1.146 5.342 2.477 7.119 3.954a22.098 22.098 0 004.88-13.886C46.69 11.836 36.754 1.9 24.5 1.9z"
            fill="currentcolor"
            stroke="#fff"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgManCircleFilled;
