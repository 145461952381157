import * as React from "react";

const SvgAriesIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 13 12" {...props}>
        <path
            d="M3.814 1.717c-.27 0-.501.038-.694.116-.231.078-.386.233-.54.388-.154.155-.27.387-.347.659a3.411 3.411 0 00-.115.93c0 .698.192 1.24.616 1.628.424.388 1.003.582 1.85.582h.039v1.085h-.039a4.7 4.7 0 01-1.503-.232 3.887 3.887 0 01-1.195-.62c-.346-.272-.578-.62-.77-1.047-.193-.427-.27-.892-.27-1.435 0-.504.077-.969.231-1.357a3.7 3.7 0 01.617-1.007c.231-.272.578-.466.925-.62C2.966.63 3.35.591 3.737.591c.346 0 .693.04 1.002.156.308.116.578.27.809.465.231.194.424.426.617.698.192.27.308.542.424.852h.038c.116-.31.231-.581.424-.852.154-.272.347-.504.578-.698.232-.194.501-.35.771-.465.309-.117.617-.156.964-.156.385 0 .77.078 1.117.194.347.117.656.35.926.62.27.272.462.582.616 1.008.154.388.231.853.231 1.357 0 .543-.077 1.008-.27 1.435-.192.426-.423.775-.77 1.046-.347.272-.733.504-1.195.62-.463.117-.964.233-1.503.233h-.039V6.02h.039c.81 0 1.464-.194 1.85-.582.424-.387.617-.93.617-1.628 0-.349-.039-.659-.116-.93a1.79 1.79 0 00-.347-.66c-.154-.154-.347-.31-.54-.387a2.005 2.005 0 00-.693-.116c-.309 0-.578.077-.848.232-.27.155-.463.388-.656.737-.192.31-.346.698-.423 1.163-.116.465-.155.969-.155 1.59v5.62H6.011V5.44c0-.582-.039-1.125-.155-1.59-.115-.465-.23-.853-.424-1.163a3.067 3.067 0 00-.655-.737c-.385-.155-.655-.232-.963-.232h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgAriesIcon;
