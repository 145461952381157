// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Anchor } from "@Components/Basic";
import { CustomSlider } from "@Components";
import { ChevronDown } from "@Icons";
import { Container, DropdownWrapper, SliderWrapper, SelectedLocation } from "./styled";
import { ILocationFilter } from "./LocationFilter";
// #endregion Local Imports

const LocationFilter: React.FunctionComponent<ILocationFilter.IProps> = ({
    toggleLocationCard,
    cardIsVisible,
    selectedLocation,
    handleSliderChange,
    currentDistance,
}): JSX.Element => {
    const [isActive, setIsActive] = React.useState(cardIsVisible);

    const activate = () => {
        setIsActive(val => !val);
        toggleLocationCard();
    };

    React.useEffect(() => {
        setIsActive(cardIsVisible);
    }, [cardIsVisible]);

    let location = "";

    if (selectedLocation) {
        const { countryId, countryName, cityId, cityName, townId, townName } = selectedLocation;

        location = countryName;

        if (countryId === 0) {
            if (cityId !== undefined && cityId > -1) {
                location = `${countryName}-${cityName}`;

                if (townId !== undefined && townId > -1) {
                    location = `${cityName}-${townName}`;
                }
            }
        } else if (cityId !== undefined && cityId > -1) {
            location += `-${cityName}`;
        }
    }

    return (
        <Container isActive={isActive}>
            <DropdownWrapper>
                <SelectedLocation isActive={isActive}>
                    <Anchor onClick={activate} className="toggle-button">
                        <div>
                            <span className="location-text">{location}</span>
                            <span className={`icon-wrapper ${isActive ? "rotate" : ""}`}>
                                <ChevronDown width="17px" height="20px" />
                            </span>
                        </div>
                    </Anchor>
                </SelectedLocation>
            </DropdownWrapper>
            <SliderWrapper>
                <CustomSlider defaultValue={currentDistance} onChange={handleSliderChange} />
            </SliderWrapper>
        </Container>
    );
};

export { LocationFilter };
