// #region Global Imports
import styled from "styled-components";
import { FontWeights } from "@Interfaces/styled";
// #endregion Global Imports

export const Container = styled.div`
    background-image: url("/images/gold-membership-alert-bd.png");
    background-repeat: no-repeat;
    background-position: 24% 20%;
`;

export const Title = styled.div<{ weight?: keyof FontWeights }>`
    font-size: 24px;
    line-height: 24px;
    font-weight: ${({ weight, theme }) =>
        weight ? theme.fontWeights[weight] : theme.fontWeights.medium};
`;

export const UnorderedList = styled.ul`
    padding-left: 20px;
`;
