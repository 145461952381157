// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, Sticker } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IStickerItem } from "./StickerItem";
// #endregion Interface Imports

export const StickerItem: React.FunctionComponent<IStickerItem.IProps> = ({
    items,
    handleClick,
}) => {
    const getKey = (index: number) => `stickerItem-${index}`;

    const stickers = items.map((item, i) => (
        <Sticker
            key={getKey(i)}
            onClick={() => {
                handleClick(item);
            }}
            assets={item}
        />
    ));

    return <Container>{stickers}</Container>;
};
