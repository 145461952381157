import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { IStore } from "@Interfaces";
import { InboxActions } from "../../../../Actions/InboxActions";

export const useInboxPage = (slug: string | string[]) => {
    const dispatch = useDispatch();
    const unreads = useSelector((state: IStore) => state.dashboard.notificationCounts);
    const inbox = useSelector((state: IStore) => state.inbox);

    useEffect(() => {
        if (slug !== "prioritized") return;

        (async () => {
            dispatch(await InboxActions.FetchPrioritized());
        })();
    }, [dispatch, slug]);

    useEffect(() => {
        if (slug !== "requests") return;

        (async () => {
            dispatch(await InboxActions.FetchRequests());
        })();
    }, [dispatch, slug]);

    useEffect(() => {
        if (slug !== "replied") return;

        (async () => {
            dispatch(await InboxActions.FetchReplied(false));
        })();
    }, [dispatch, slug]);

    useEffect(() => {
        if (slug !== "messages") return;

        (async () => {
            dispatch(await InboxActions.FetchChatPreviews(false));
        })();
    }, [dispatch, slug]);

    return { inbox, unreads };
};
