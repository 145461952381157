// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Badge, Anchor } from "@Components/Basic";
import { InboxFilter } from "@Components";
import { useScreenSize, theme } from "@Definitions";
import { FilterSetup } from "@Icons";
import {
    Container,
    BadgeContainer,
    FilterButtonText,
    FilterCount,
    FilterButton,
    TabContent,
    InboxFilterContent,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IInboxHeader } from "./InboxHeader";
import { InboxActions, InboxFilterActions } from "@Actions";
import { ConversationModel } from "@Interfaces";
// #endregion Interface Imports

export const InboxHeader: React.FunctionComponent<IInboxHeader.IProps> = (
    props: IInboxHeader.IProps
) => {
    const { t, children, activeTab } = props;
    const { useState } = React;
    const screenSize = useScreenSize();
    const dispatch = useDispatch();

    const shouldRenderFilters = activeTab === "prioritized" || activeTab === "requests";

    const [showFilter, setShowFilter] = useState(false);
    const [filterCount, setFilterCount] = useState<number | undefined>(undefined);

    const filterInbox = async (payload: ConversationModel.IFilterRequest, count: number) => {
        setFilterCount(count);
        dispatch(await InboxActions.Filter(payload));
    };

    const resetFilter = async () => {
        setShowFilter(false);
        setFilterCount(0);
        dispatch(InboxFilterActions.Reset());
        dispatch(await InboxActions.FetchRequests());
    };

    return (
        <Container isOpen={showFilter && shouldRenderFilters}>
            <TabContent>
                <BadgeContainer>{children}</BadgeContainer>

                {(screenSize === "xlarge" || screenSize === "large") && shouldRenderFilters && (
                    <div className="row">
                        {!!filterCount && (
                            <FilterCount>
                                <Anchor className="mr-3" onClick={resetFilter}>
                                    {t("common:inbox.filter.clearFilters")}
                                </Anchor>
                                <Badge count={filterCount ?? 0} color={theme.colors.badgeGreen} />
                            </FilterCount>
                        )}
                        <FilterButton
                            onClick={() => setShowFilter(!showFilter)}
                            isOpen={showFilter}
                        >
                            <FilterButtonText>{t("common:inbox.header.filtrele")}</FilterButtonText>
                            <FilterSetup width="16px" height="19px" className="mr-5" />
                        </FilterButton>
                    </div>
                )}
            </TabContent>

            {screenSize !== "xsmall" && (
                <InboxFilterContent isOpen={showFilter && shouldRenderFilters}>
                    <hr className="py-0 my-1" />
                    <InboxFilter
                        t={t}
                        onFilterButtonClick={filterInbox}
                        resetFilter={filterCount === 0}
                    />
                </InboxFilterContent>
            )}
        </Container>
    );
};
