// #region Global Imports
import React, { useState, useEffect } from "react";
// #endregion Global Imports

// #region Local Imports
import { IRecentPhotos } from "./RecentPhotos";
import { WrappedComponent } from "./RecentPhotos.container";
// #endregion Local Imports

export const RecentPhotos: React.FunctionComponent<IRecentPhotos.IProps> = (props): JSX.Element => {
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
    }, []);

    if (!didMount) return <div />;

    return <WrappedComponent {...props} />;
};
