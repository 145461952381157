// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const InfoItem = styled.div`
    color: #ffffff;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

    svg {
        filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.3));
    }

    .transparent {
        opacity: 0.5;
    }

    span {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: 16px;
        letter-spacing: 0.18px;
    }
`;

InfoItem.displayName = "InfoItem";
