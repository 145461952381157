// #region Global Imports
import React from "react";
import Cards, { Focused } from "react-credit-cards";
// #endregion Global Imports

// #region Local Imports
import { TextInput, Dropdown } from "@Components/Basic";
import { FormatCreditCardNumber, FormatCvcNumber } from "@Definitions";
import { Row, Col } from "@Styled";
import { ExpirationDate } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ICreditCardForm } from "./CreditCardForm";
// #endregion Interface Imports

export const CreditCardForm: React.FunctionComponent<ICreditCardForm.IProps> = ({
    t,
    register,
    watch,
    setValue,
    errors,
}) => {
    const [focus, setFocus] = React.useState<Focused>("number");

    const months = [];
    for (let index = 1; index <= 12; index++) {
        const value = index <= 9 ? `0${index}` : index;

        months.push({ value, label: value });
    }

    const minYear = new Date().getFullYear();
    const maxYear = minYear + 10;

    const years = [];
    for (let index = minYear; index <= maxYear; index++) {
        years.push({ value: index, label: index });
    }

    return (
        <div>
            <div className="mb-3">
                <Cards
                    cvc={watch("cvc", "") as string}
                    focused={focus}
                    expiry={`${watch("expiryMonth", "")}${watch("expiryYear", "")}`}
                    name={watch("cardOwnerName", "") as string}
                    number={watch("cardNumber", "") as string | number}
                    placeholders={{ name: t("common:payment.paymentForm.cardSurfaceName") }}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    type="tel"
                    name="cardNumber"
                    className="input-appearance-none"
                    onFocus={() => setFocus("number")}
                    placeholder={t("common:payment.paymentForm.cardNumber")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue("cardNumber", FormatCreditCardNumber(event.target.value));
                    }}
                    isValid={errors.cardNumber && false}
                    _ref={register({
                        required: true,
                    })}
                    cy="cardNumber-input"
                />
            </div>
            <div className="mb-3">
                <TextInput
                    name="cardOwnerName"
                    placeholder={t("common:payment.paymentForm.cardOwnerName")}
                    onFocus={() => setFocus("name")}
                    isValid={errors.cardOwnerName && false}
                    _ref={register({
                        required: true,
                    })}
                    cy="cardOwnerName-input"
                />
            </div>
            <Row className="mb-3">
                <Col className="col d-flex align-items-center flex-grow-0">
                    <ExpirationDate size="small" fontWeight="bold">
                        {t("common:payment.paymentForm.expirationDate")}
                    </ExpirationDate>
                </Col>
                <Col className="col flex-grow-1">
                    <Dropdown
                        t={t}
                        name="expiryMonth"
                        onMenuOpen={() => setFocus("expiry")}
                        onChange={(e) => {
                            setValue("expiryMonth", e.value);
                        }}
                        placeholder={t("common:payment.paymentForm.month")}
                        options={months}
                        isValid={false}
                        borderColor="#acb5c3"
                        _ref={register(
                            {
                                name: "expiryMonth",
                            },
                            {
                                required: true,
                            }
                        )}
                        cy="expiry-month"
                    />
                </Col>
                <Col className="col flex-grow-1 mb-3">
                    <Dropdown
                        t={t}
                        name="expiryYear"
                        onMenuOpen={() => setFocus("expiry")}
                        onChange={(e) => {
                            setValue("expiryYear", e.value);
                        }}
                        placeholder={t("common:payment.paymentForm.year")}
                        options={years}
                        isValid={errors.expiryYear && false}
                        borderColor="#acb5c3"
                        _ref={register(
                            {
                                name: "expiryYear",
                            },
                            {
                                required: true,
                            }
                        )}
                        cy="expiry-year"
                    />
                </Col>
                <Col className="col-12 col-md flex-grow-1">
                    <TextInput
                        name="cvc"
                        type="tel"
                        className="input-appearance-none"
                        maxLength={4}
                        onFocus={() => setFocus("cvc")}
                        placeholder="Cvc"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue("cvc", FormatCvcNumber(event.target.value));
                        }}
                        isValid={errors.cvc && false}
                        _ref={register({
                            required: true,
                        })}
                        cy="cvc-input"
                    />
                </Col>
            </Row>
        </div>
    );
};
