import * as React from "react";

const SvgEighteenValid = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 40 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18 0 1.097-.098 2.17-.286 3.213a6.812 6.812 0 00-.984-.175c.177-.985.27-2 .27-3.038 0-9.389-7.611-17-17-17S1 8.611 1 18s7.611 17 17 17c3.765 0 7.244-1.224 10.06-3.295.178.284.375.554.59.809A17.925 17.925 0 0118 36C8.059 36 0 27.941 0 18S8.059 0 18 0zm16 22a6 6 0 110 12 6 6 0 010-12zm2.17 3.279l-.095.083-3.535 3.535-.707-.706a1 1 0 00-1.498 1.32l.084.094 1.414 1.414c.15.15.336.243.53.277l.118.014h.118l.117-.014a.994.994 0 00.437-.194l.094-.083 4.243-4.243a1 1 0 00-1.32-1.497zM15 10a1 1 0 011 1h4a1 1 0 011-1h2a1 1 0 011 1h2a2 2 0 012 2v11a2 2 0 01-2 2H10a2 2 0 01-2-2V13a2 2 0 012-2h2a1 1 0 011-1h2zm11 2H10a1 1 0 00-1 1v11a1 1 0 001 1h16a1 1 0 001-1V13a1 1 0 00-1-1zm-5.203 1.548c.747 0 1.407.114 1.98.343.575.228 1.02.553 1.338.973.317.42.476.914.476 1.484 0 .438-.098.828-.294 1.169-.196.34-.48.623-.854.847.485.242.856.56 1.113.952.257.392.385.844.385 1.358 0 .606-.173 1.136-.518 1.589-.345.452-.83.802-1.456 1.05-.625.247-1.349.37-2.17.37s-1.542-.123-2.163-.37c-.62-.248-1.101-.598-1.442-1.05-.34-.453-.511-.983-.511-1.59 0-.513.128-.965.385-1.357s.623-.71 1.099-.952a2.391 2.391 0 01-.84-.847 2.302 2.302 0 01-.294-1.17c0-.569.159-1.063.476-1.483.317-.42.76-.745 1.33-.973.57-.229 1.223-.343 1.96-.343zm-5.506.168v9.8h-2.268v-7.98h-1.96v-1.82h4.228zm5.506 5.488c-.56 0-1.003.126-1.33.378-.327.252-.49.592-.49 1.022 0 .438.163.784.49 1.036.327.252.77.378 1.33.378.57 0 1.02-.129 1.35-.385.332-.257.498-.6.498-1.03 0-.429-.166-.77-.497-1.021-.331-.252-.782-.378-1.351-.378zm0-3.99c-.457 0-.824.11-1.1.329-.274.22-.412.52-.412.903 0 .373.135.667.406.882.27.214.64.322 1.106.322.467 0 .84-.108 1.12-.322.28-.215.42-.509.42-.882 0-.383-.14-.684-.42-.903-.28-.22-.653-.33-1.12-.33z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgEighteenValid;
