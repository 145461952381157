import * as React from "react";

const SvgBan = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
        <path
            d="M12.364 0c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm9.063 5.764l-15.299 15.3A10.95 10.95 0 0012.364 23c6.075 0 11-4.925 11-11a10.95 10.95 0 00-1.937-6.236zM12.364 1c-6.075 0-11 4.925-11 11 0 2.315.715 4.463 1.937 6.236L18.6 2.936A10.95 10.95 0 0012.364 1z"
            fill="currentcolor"
            fillRule="nonzero"
            stroke="currentcolor"
            strokeWidth={0.3}
        />
    </svg>
);

export default SvgBan;
