// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Card, Button } from "@Components/Basic";
import { Container, Title, ButtonContainer, SliderWrapper } from "./styled";
import { CustomSliderRange, Overlay } from "@Components";
import { IAgeRangeCard } from "./AgeRangeCard";
// #endregion Local Imports

const AgeRangeCard: React.FunctionComponent<IAgeRangeCard.IProps> = ({
    t,
    saveAgeRange,
    lookingForMax,
    lookingForMin,
    onClickOverlay,
}): JSX.Element => {
    const [from, setFrom] = React.useState(lookingForMin);
    const [to, setTo] = React.useState(lookingForMax);

    const getValuesToCard = (fromVal: number, toVal: number) => {
        setFrom(fromVal);
        setTo(toVal);
    };

    const apply = () => {
        saveAgeRange(from, to);
    };

    return (
        <>
            <Overlay onClick={onClickOverlay} />
            <Container>
                <Card>
                    <Title>{t("search:Yaş Aralığı")}</Title>
                    <SliderWrapper>
                        <CustomSliderRange
                            rangeFrom={lookingForMin}
                            rangeTo={lookingForMax}
                            sendValues={getValuesToCard}
                        />
                    </SliderWrapper>
                    <ButtonContainer>
                        <Button styleType="ghost" size="small" onClick={apply}>
                            {t("common:buttonText.UYGULA")}
                        </Button>
                    </ButtonContainer>
                </Card>
            </Container>
        </>
    );
};

export { AgeRangeCard };
