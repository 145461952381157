import * as React from "react";

const SvgTrash = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 15 15" {...props}>
        <path
            d="M8.866.5c1.05 0 1.916.816 1.992 1.85l.006.15h3.502c.247 0 .448.177.49.41l.008.09-.008.088a.508.508 0 01-.4.404l-.09.008h-1.502v9.998c0 .507-.384.936-.88.995l-.115.007h-8.01c-.5 0-.93-.387-.988-.886l-.007-.116V3.5H1.362a.496.496 0 01-.49-.41L.864 3l.008-.088a.508.508 0 01.4-.404l.09-.008h3.502c0-1.062.815-1.919 1.849-1.995L6.862.5h2.004zm2.998 3h-8l-.005 10h8.01l-.006-.001.001-.001V3.5zm-6 2c.238 0 .448.178.492.413l.008.09v5.994c0 .24-.177.45-.41.495l-.09.008-.088-.008a.51.51 0 01-.404-.405l-.008-.09V6.003c0-.27.224-.503.5-.503zm2 0c.238 0 .448.178.492.413l.008.09v5.994c0 .24-.177.45-.41.495l-.09.008-.088-.008a.51.51 0 01-.404-.405l-.008-.09V6.003c0-.27.224-.503.5-.503zm2 0c.238 0 .448.178.492.413l.008.09v5.994c0 .24-.177.45-.41.495l-.09.008-.088-.008a.51.51 0 01-.404-.405l-.008-.09V6.003c0-.27.224-.503.5-.503zm-1.01-4h-1.98c-.558 0-1.01.444-1.01 1h4a1 1 0 00-1.01-1z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgTrash;
