// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { IRepeater } from "./Repeater";
import { repeatElement } from "@Definitions";
// #endregion Local Imports

export const Repeater: React.FC<IRepeater.IProps> = ({ children, times }) => {
    return <>{repeatElement(children, times)}</>;
};
