// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { TextInput, Dropdown, Button } from "@Components/Basic";
import {
    Container,
    OptionsContainer,
    DropdownItem,
    FilterFooter,
    ButtonContainerLeft,
    TextInputContainer,
} from "./styled";
import { FormBox, Modal } from "@Components";
import { Info } from "@Icons";
import { ISearchFilterModal } from "./SearchFilterModal";
import { MetaDataModel, ISearchPage } from "@Interfaces";
// #endregion Local Imports

export const SearchFilterModal: React.FunctionComponent<ISearchFilterModal.IProps> = ({
    searchFilterQuestions,
    appliedFilters,
    setFilters,
    closeFunc,
    t,
}): JSX.Element => {
    const { useEffect, useCallback, useState, useMemo } = React;

    const [selectedQuestions, setSelectedQuestions] =
        useState<ISearchFilterModal.TSelectedQuestions>([]);
    const [nickname, setNickname] = useState("");
    const [keyword, setKeyword] = useState("");
    const [inputValue, setInputValue] = useState("");

    const questionList = useMemo(
        () => ({
            // [name]: id
            Weight: 14,
            Height: 16,
            Disability: 22,
            Income: 51,
            Education: 46,
            Religion: 56,
            Children: 63,
            Smoking: 59,
            Drinking: 61,
            Zodiac: 200,
            MaritalStatus: 201,
            RelationshipType: 202,
            Job: 203,
        }),
        []
    );

    useEffect(() => {
        if (appliedFilters.Keyword) {
            setInputValue(appliedFilters.Keyword);
            setKeyword(appliedFilters.Keyword);
        } else if (appliedFilters.Nickname) {
            setInputValue(`@${appliedFilters.Nickname}`);
            setNickname(appliedFilters.Nickname);
        }
    }, [appliedFilters.Keyword, appliedFilters.Nickname]);

    const setFilterSelections = (questions: ISearchFilterModal.TSelectedQuestions) => {
        const serializedFilterQuestions: {
            [key: string]: string | undefined;
        } = {};

        Object.keys(questionList).forEach(key => {
            serializedFilterQuestions[key] = undefined;
        });

        questions.forEach(q => {
            const joined = q.selectedOptions?.map(option => option.value).join();
            const key = Object.entries(questionList).find(entry => entry[1] === q.questionId)![0];
            serializedFilterQuestions[key] = joined;
        });

        setFilters({
            ...(serializedFilterQuestions as ISearchPage.IModalQuestions),
            Nickname: nickname || undefined,
            Keyword: keyword || undefined,
        });
    };

    const deserializeQuestions = useCallback(
        (questions: ISearchPage.IModalQuestions) => {
            const deserialized: ISearchFilterModal.TSelectedQuestions | undefined = [];

            const formatOption = (id: number, values: any, thirdPersonName: boolean) => {
                const item = searchFilterQuestions.Questions.find(q => q.Id === id);

                if (item) {
                    const itemOptions = item.ThirdPersonOptions.length
                        ? item.ThirdPersonOptions
                        : item.Options;

                    const formatted = {
                        questionId: id,
                        selectedOptions: values.split(",").map((value: string) => {
                            const option = itemOptions.find(o => o.Id.toString() === value);

                            return {
                                value,
                                label: thirdPersonName ? option?.ThirdPersonName : option?.Name,
                            };
                        }),
                    };

                    deserialized.push(formatted);
                }
            };
            Object.entries(questions).forEach(([key, value]) => {
                const thirdPersonQuestionIds = [59, 61, 63];
                const questionId = (questionList as any)[key];
                if (questionId !== undefined) {
                    formatOption(questionId, value, thirdPersonQuestionIds.includes(questionId));
                }
            });
            setSelectedQuestions(deserialized);
        },
        [searchFilterQuestions.Questions, questionList]
    );

    useEffect(() => {
        if (Object.keys(appliedFilters).length) {
            deserializeQuestions(appliedFilters);
        }
    }, [appliedFilters, deserializeQuestions]);

    const getOptionsByItem = (item: MetaDataModel.IFetchSearchFilterQuestion) => {
        return item.ThirdPersonOptions.length > 0
            ? item.ThirdPersonOptions.map(val => ({
                  value: val.Id,
                  label: val.Name,
              }))
            : item.Options.map(val => ({
                  value: val.Id,
                  label: val.ThirdPersonName || val.Name,
              }));
    };

    const handleChange = (option: any, item: MetaDataModel.IFetchSearchFilterQuestion) => {
        const obj = {
            questionId: item.Id,
            selectedOptions: option,
        };

        setSelectedQuestions(selectedQuestionsVal => {
            const selectedQuestionsTemp = selectedQuestionsVal;

            let isFound = false;

            selectedQuestionsTemp.forEach((selectedQuestion, index) => {
                if (selectedQuestion.questionId === item.Id) {
                    if (option?.length === 0 || option === null) {
                        selectedQuestionsTemp.splice(index, 1);
                    } else {
                        selectedQuestionsTemp[index].selectedOptions = obj.selectedOptions;
                    }

                    isFound = true;
                }

                return selectedQuestionsTemp;
            });

            return isFound ? [...selectedQuestionsTemp] : [...selectedQuestionsTemp, obj];
        });
    };

    const handleTextInput = (e: any) => {
        const val = e.target.value;

        setInputValue(val);

        if (val.split("")[0] === "@") {
            setNickname(val.substr(1, val.length).split(" ")[0]);
            setKeyword("");
        } else {
            setNickname("");
            setKeyword(val);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setFilterSelections(selectedQuestions);
        closeFunc();
    };

    const renderOptions = () => {
        return (
            <OptionsContainer>
                {searchFilterQuestions.Questions.map(
                    (item: MetaDataModel.IFetchSearchFilterQuestion) => {
                        let dropdownValue = null;

                        const selectedOptions = selectedQuestions.filter(
                            p => p.questionId === item.Id
                        );

                        if (selectedOptions.length > 0) {
                            dropdownValue = selectedOptions[0].selectedOptions;
                        }

                        // TODO: FIXME
                        return (
                            <DropdownItem key={`dropdown--${item.Id}`}>
                                <Dropdown
                                    t={t}
                                    placeholder={item.ThirdPersonName}
                                    options={getOptionsByItem(item)}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    isMulti
                                    minHeight="30px"
                                    borderColor="#00F07B"
                                    name={`filter-${item.ThirdPersonName}`}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    value={dropdownValue}
                                    onChange={option => handleChange(option, item)}
                                    closeMenuOnSelect={false}
                                />
                            </DropdownItem>
                        );
                    }
                )}
                <TextInputContainer>
                    <TextInput
                        placeholder="@kullanıcıadı ya da anahtar sözcük ara"
                        renderIcon={() => <Info width="23px" height="23px" />}
                        height="39px"
                        onChange={handleTextInput}
                        value={inputValue}
                    />
                </TextInputContainer>
            </OptionsContainer>
        );
    };

    return (
        <Modal>
            <FormBox title="FİLTRE EKLE" onCloseClick={closeFunc} onHandleSubmit={handleSubmit}>
                <Container>{renderOptions()}</Container>
                <FilterFooter>
                    <ButtonContainerLeft>
                        <Button
                            styleType="ghost"
                            className="mr-4"
                            onClick={() => {
                                setSelectedQuestions([]);
                                setInputValue("");
                                setNickname("");
                                setKeyword("");
                            }}
                        >
                            {t("common:Formu temizle")}
                        </Button>
                    </ButtonContainerLeft>
                    <Button size="large" type="submit">
                        {t("common:Arama yap")}
                    </Button>
                </FilterFooter>
            </FormBox>
        </Modal>
    );
};
