// #region Global Imports
import styled, { css } from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    width: 100%;
    @media (max-width: ${theme.maxWidth.medium}) {
        min-height: unset;
    }
`;

export const customSliderCss = css`
    .slick-list {
        height: unset;
    }

    .slick-next {
        right: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        font-style: normal;
        font-weight: 400;

        ::before {
            content: "";
            display: block;
            opacity: 1;
            width: 27px;
            height: 27px;
            mask: url("/icons/arrow-point-to-right.svg") no-repeat center / cover;
            background-color: ${theme.colors.gray};
        }
    }
`;
