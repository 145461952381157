// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Locale Imports
import { CoinService, ProfileService, PusherService } from "@Services";
import { ActionConsts, ThrowIfNotAuthenticated } from "@Definitions";
import { IStore } from "@Interfaces";
// #region Locale Imports

export const CommonActions = {
    /**
     * Saves last scroll position into the store.
     * @param scrollY Vertical scroll value as number
     */
    SaveScrollPosition: (scrollY: number) => ({
        type: ActionConsts.Common.SaveScrollPosition,
        payload: scrollY,
    }),

    GetCoin: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        ThrowIfNotAuthenticated(getState);

        const result = await CoinService.GetCoinCount();

        dispatch({
            payload: result.Coin,
            type: ActionConsts.Common.SetCoin,
        });
    },

    SetOccupancy: async () => async (dispatch: Dispatch) => {
        const result = await ProfileService.GetOccupancy();
        dispatch({
            payload: result.Ratio,
            type: ActionConsts.Common.SetOccupancy,
        });
    },

    CoinNotification: async () => async (dispatch: Dispatch) => {
        dispatch({ type: ActionConsts.Common.SetNotificationsLoading });

        const result = await CoinService.GetCoinNotification();

        dispatch({
            payload: result.Notifications,
            type: ActionConsts.Common.GetCoinNotification,
        });
    },
    GetChannel: async () => async (dispatch: Dispatch) => {
        const result = await PusherService.GetChannel();

        dispatch({
            payload: result.Name,
            type: ActionConsts.Common.GetChannel,
        });
    },
    SetShowUploadPhoto: async (showPhotoUpload: boolean) => async (dispatch: Dispatch) => {
        dispatch({
            payload: showPhotoUpload,
            type: ActionConsts.Common.SetPhotoUploadModalState,
        });
    },
};
