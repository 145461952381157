// #region Global Imports
import * as React from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
// #endregion Global Imports

// #region Local Imports
import { TextInput, Checkbox, Button, Small, Alert } from "@Components/Basic";
import { ILoginBox } from "./LoginBox";
import { FormBox, FacebookButton, AppleLoginButton } from "@Components";
import { FriendsIcon, Password } from "@Icons";
import { SBetween, FlexCenter, BoxContent, OR, Anchor, MBottom } from "./styled";
import { AccountActions, HomeActions } from "@Actions";
import { IStore } from "@Interfaces";
// #endregion Local Imports

export const LoginBox: React.FunctionComponent<ILoginBox.IProps> = ({ t }): JSX.Element => {
    const dispatch = useDispatch();
    const { useState } = React;

    const error = useSelector((state: IStore) => state.auth.user?.Reason);

    const captchaIsRequired = useSelector((state: IStore) => state.auth.captcha.isRequired);

    const isLoading = useSelector((state: IStore) => state.auth.isAuthenticating);

    const [token, setToken] = useState("");

    const [rememberMe, setRememberMe] = useState(true);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required(t("home:email.required")),
        password: Yup.string().required(t("home:forgotPassword.required")),
    });

    const { register, handleSubmit, errors } = useForm<ILoginBox.FormValues>({
        validationSchema: LoginSchema,
    });

    const onSubmit = ({ email, password }: ILoginBox.FormValues) => {
        dispatch(
            AccountActions.Login(
                {
                    body: { username: email, password, "g-recaptcha-response": token },
                },
                rememberMe
            )
        );
    };

    const handleCaptcha = (ct: string | null) => {
        if (ct) {
            setToken(ct);
        }
    };

    return (
        <FormBox
            title="ÜYE GİRİŞİ"
            onCloseClick={() => {
                dispatch(HomeActions.SetTopComponent({ topComponent: "card" }));
                dispatch(AccountActions.LoginReset());
            }}
            isLarge={false}
            onHandleSubmit={handleSubmit(onSubmit)}
            cy="loginform"
        >
            <BoxContent>
                <MBottom mb="10">
                    <MBottom mb="20">
                        <TextInput
                            isValid={errors.email && false}
                            placeholder={t("common:login.userName")}
                            renderIcon={() => <FriendsIcon width="38px" height="38px" />}
                            errorText={errors.email ? errors.email.message : ""}
                            name="email"
                            cy="email-input"
                            _ref={register}
                        />
                    </MBottom>

                    <MBottom mb="20">
                        <TextInput
                            isValid={errors.password && false}
                            placeholder={t("common:login.password")}
                            renderIcon={() => <Password width="38px" height="38px" />}
                            type="password"
                            name="password"
                            errorText={errors.password ? errors.password.message : ""}
                            _ref={register}
                            cy="password-input"
                        />
                    </MBottom>
                    <MBottom mb="10">
                        <SBetween>
                            <Checkbox
                                checked={rememberMe}
                                name="rememberMe"
                                _ref={register}
                                onChange={() => {
                                    setRememberMe(!rememberMe);
                                }}
                            >
                                <Small>{t("common:login.rememberMe")}</Small>
                            </Checkbox>

                            <Anchor
                                onClick={() => {
                                    dispatch(
                                        HomeActions.SetTopComponent({ topComponent: "forgot" })
                                    );
                                }}
                            >
                                {t("common:login.forgotPassword")}
                            </Anchor>
                        </SBetween>
                    </MBottom>
                    <div className="d-flex justify-content-center my-5">
                        {captchaIsRequired && (
                            <ReCAPTCHA
                                sitekey="6Le_XwsTAAAAABBVQiRoYarF34R4Svs8dnGBE3fL"
                                onChange={handleCaptcha}
                            />
                        )}
                    </div>
                    <Button
                        styleType="gradient"
                        size="large"
                        block
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {t("common:login.loginButton")}
                    </Button>
                    {error === "Fail" && (
                        <div className="d-flex align-items-center py-4" style={{ width: 320 }}>
                            <Alert type="danger" cy="login-alert">
                                {t("common:login.error")}
                            </Alert>
                        </div>
                    )}
                </MBottom>
                <MBottom mb="5">
                    <OR>{t("common:login.or")}</OR>
                </MBottom>
                <MBottom mb="10">
                    <FlexCenter>
                        <AppleLoginButton t={t} />
                    </FlexCenter>
                </MBottom>
            </BoxContent>
        </FormBox>
    );
};
