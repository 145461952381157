// #region Global Imports
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Button, Text } from "@Components/Basic";
import { useOutsideClick } from "@Definitions";
import { PlusCircle, Upload, FacebookIcon } from "@Icons";
import { ProfileActions } from "@Actions";
import { Container, UploadList, SmallUploadItem, UploadItem, UploadOptions } from "./styled";
import { FacebookService } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { IPhotoUploadOptions } from "./PhotoUploadOptions";
// #endregion Interface Imports

export const PhotoUploadOptions: React.FunctionComponent<IPhotoUploadOptions.IProps> = ({
    optionType,
    buttonText,
    _ref,
    _dropzoneRef,
    t,
}) => {
    const dispatch = useDispatch();
    const [showUploadOptions, setShowUploadOptions] = useState(false);

    const uploadOptions: {
        title: string;
        iconName: "upload" | "facebook-icon" | "instagram-icon";
        type: string;
    }[] = [
            { title: t("common:photoUpload.fromLocale"), iconName: "upload", type: "computer" },
        ];

    const renderIcon = (iconName: string, optionType: string) => {
        switch (iconName) {
            case "upload":
                const uploadIcon = optionType === "small" ? (
                    <Upload width="18px" height="21px" />
                ) : (
                    <Upload width="72px" height="80" />
                )

                return uploadIcon;

            case "facebook-icon":
                const facebookIcon = optionType === "small" ? (
                    <FacebookIcon width="18px" height="21px" />
                ) : (
                    <FacebookIcon width="72px" height="80" />
                )

                return facebookIcon;

            default:
                return <></>
        }
    }

    useOutsideClick(({ target }: MouseEvent) => {
        const host = _ref.current;
        if (!host || !target) return;
        setShowUploadOptions(false);
        if (host.contains(target as Node)) {
            setShowUploadOptions(true);
        }
    });

    const handleUploadOptions = async (type: string) => {
        if (type === "computer" && _ref.current && _dropzoneRef.current) {
            _dropzoneRef.current.open();
        }
        if (type === "facebook") {
            const user = await FacebookService.login();

            if (user.authResponse) {
                dispatch(await ProfileActions.SetUploadOptions("fromFacebook"));
            }
        }
    };

    const getKey = (type: string) => `list-item-${type}`;

    return (
        <>
            {optionType === "small" && (
                <Container>
                    <div ref={_ref}>
                        <Button
                            size="small"
                            styleType="facebook"
                            icon={<PlusCircle width="12px" height="14px" className="ml-2" />}
                            iconPosition="right"
                            onClick={() => setShowUploadOptions(!showUploadOptions)}
                        >
                            {buttonText}
                        </Button>
                    </div>

                    {showUploadOptions && (
                        <UploadList optionType={optionType} className="upload-list">
                            {uploadOptions.map(item => (
                                <SmallUploadItem
                                    onClick={() => handleUploadOptions(item.type)}
                                    key={getKey(item.type)}
                                >
                                    <Text size="xSmall">{item.title}</Text>
                                    {renderIcon(item.iconName, optionType)}
                                </SmallUploadItem>
                            ))}
                        </UploadList>
                    )}
                </Container>
            )}
            {optionType === "large" && (
                <UploadOptions ref={_ref}>
                    {uploadOptions.map(item => (
                        <UploadItem
                            onClick={() => handleUploadOptions(item.type)}
                            key={getKey(item.type)}
                        >
                            {renderIcon(item.iconName, optionType)}
                            <Text size="small">{item.title}</Text>
                        </UploadItem>
                    ))}
                </UploadOptions>
            )}
        </>
    );
};
