// #region Global Imports
import Router from "next/router";
import Nookies from "nookies";
// #endregion Global Imports

// #region Local Imports
import { ProfileActions } from "@Actions";
import { ReduxNextPageContext } from "@Interfaces";
// #endregion Local Imports

export const Redirect = (
    ctx?: ReduxNextPageContext,
    path = "",
    type: "replace" | "push" = "push"
) => {
    const redirectTo = `/${path}`;

    if (ctx?.res) {
        ctx.res.writeHead(302, {
            Location: redirectTo,
        });

        ctx.res.end();
    } else if (type === "push") {
        Router.push(redirectTo);
    } else {
        Router.replace(redirectTo);
    }
};

export const RedirectIfNotAuthorized = (ctx?: ReduxNextPageContext) => {
    Redirect(ctx, "", "replace");
};

export const HandleAuthenticatedPage = async (ctx: ReduxNextPageContext) => {
    try {
        if (ctx?.res) {
            await ctx.store?.dispatch?.(await ProfileActions.GetProfile());
        }
    } catch (e) {
        if (e.status === 401 || e.message === "AuthenticationError") {
            Nookies.destroy(ctx, "account", { path: "/" });
            RedirectIfNotAuthorized(ctx);
        }
    }
};
