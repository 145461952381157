/* eslint-disable no-case-declarations */
// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Modal } from "@Components";
import { Location, Job, Birthday, Marital, AgeRange, Gender, Relationship } from "./Components";
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IUpdateInfoModal } from "./UpdateInfoModal";
import { IStore, ProfileModel } from "@Interfaces";
// #endregion Interface Imports

export const UpdateInfoModal: React.FunctionComponent<IUpdateInfoModal.IProps> = ({
    t,
    infoType,
    onCloseClick,
}) => {
    const self = useSelector((state: IStore) => state.profile.self);

    const screenSize = useScreenSize();
    const isMobile = screenSize.includes("small");

    const renderContent = (selfData: ProfileModel.IProfile): JSX.Element => {
        switch (infoType) {
            case "birthday":
                return <Birthday t={t} onCloseClick={onCloseClick} self={selfData} />;
            case "marital":
                return <Marital t={t} onCloseClick={onCloseClick} self={selfData} />;
            case "location":
                return (
                    <Location
                        t={t}
                        self={selfData}
                        onCloseClick={onCloseClick}
                        isMobile={isMobile}
                    />
                );
            case "job":
                return <Job t={t} onCloseClick={onCloseClick} currentJobId={selfData.JobId} />;
            case "lookingForAgeRange":
                const max = parseInt(selfData.LookingForMaxAge, 10);
                const min = parseInt(selfData.LookingForMinAge, 10);
                return <AgeRange t={t} onCloseClick={onCloseClick} ageMax={max} ageMin={min} />;
            case "lookingForGender":
                return (
                    <Gender t={t} onCloseClick={onCloseClick} gender={selfData?.LookingForGender} />
                );
            case "lookingForRelationship":
                const ct = selfData.RelationshipTypeIds;
                const currentTypes = ct.length
                    ? selfData.RelationshipTypeIds.split(",").map(id => parseInt(id, 10))
                    : [];
                return (
                    <Relationship t={t} onCloseClick={onCloseClick} currentTypes={currentTypes} />
                );
            default:
                return <div />;
        }
    };

    return <Modal>{self && renderContent(self)}</Modal>;
};
