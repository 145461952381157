// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Badge, IconButton } from "@Components/Basic";
import { Modal } from "@Components";
import { Container, CloseIcon, TopNav, NavItem, NavContent, NavText, StyledIcon } from "./styled";

// #endregion Local Imports

// #region Interface Imports
import { IMobileNavigationMenu } from "./MobileNavigationMenu";
import { theme } from "@Definitions";
import {
    CircleCloseIcon,
    ChatIcon,
    ConversationIcon,
    WinkIcon,
    ViewIcon,
    FriendsIcon,
    SearchIcon,
} from "@Icons";
// #endregion Interface Imports

const MobileNavigationMenu: React.FunctionComponent<IMobileNavigationMenu.IProps> = ({
    t,
    badgeCountObject,
    onClose,
}): JSX.Element => {
    const { useState } = React;

    const [fadeIn, setFadeIn] = useState(true);

    const handleClose = () => {
        setFadeIn(false);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    return (
        <Modal>
            <Container isFadeIn={fadeIn}>
                <TopNav>
                    <IconButton
                        renderIcon={() => (
                            <CloseIcon>
                                <CircleCloseIcon width="36px" height="36px" />
                            </CloseIcon>
                        )}
                        onIconClick={() => handleClose()}
                    />
                </TopNav>
                <NavContent isFadeIn={fadeIn} onClick={() => handleClose()}>
                    <NavItem data-cy="mobileNav-requests-btn" key="nav-items-chat">
                        <Link
                            href="/inbox/[slug]"
                            as={
                                badgeCountObject.NewPrioritizedCount > 0
                                    ? "/inbox/prioritized"
                                    : "/inbox/requests"
                            }
                        >
                            <a>
                                <Badge
                                    count={badgeCountObject.NewRequestCount}
                                    color={theme.colors.badgeRed}
                                    hoverColor={theme.colors.badgeHoverRed}
                                >
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <ChatIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </Badge>
                                <NavText>{t(`common:Header.Menu Items.sohbet istekleri`)}</NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <NavItem key="nav-items-conversation">
                        <Link href="/inbox/[slug]" as="/inbox/messages">
                            <a>
                                <Badge
                                    count={badgeCountObject.NewConversationCount}
                                    color={theme.colors.badgeRed}
                                    hoverColor={theme.colors.badgeHoverRed}
                                >
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <ConversationIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </Badge>
                                <NavText>{t(`common:Header.Menu Items.sohbetlerim`)}</NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <NavItem key="nav-items-wink">
                        <Link href="/winks" as="/winks">
                            <a>
                                <Badge
                                    count={badgeCountObject.NewWinkCount}
                                    color={theme.colors.badgeRed}
                                    hoverColor={theme.colors.badgeHoverRed}
                                >
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <WinkIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </Badge>
                                <NavText>{t(`common:Header.Menu Items.göz kırpmalarım`)}</NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <NavItem key="nav-items-view">
                        <Link href="/profilevisitor" as="/profilevisitor">
                            <a>
                                <Badge
                                    count={badgeCountObject.NewProfileViewCount}
                                    color={theme.colors.badgeRed}
                                    hoverColor={theme.colors.badgeHoverRed}
                                >
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <ViewIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </Badge>
                                <NavText>
                                    {t(`common:Header.Menu Items.profilime bakanlar`)}
                                </NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <NavItem key="nav-items-friends">
                        <Link href="/friends" as="/friends">
                            <a>
                                <Badge
                                    count={badgeCountObject.Friends}
                                    color={theme.colors.badgeGreen}
                                    hoverColor={theme.colors.badgeHoverGreen}
                                >
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <FriendsIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </Badge>
                                <NavText>{t(`common:Header.Menu Items.arkadaşlar`)}</NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <NavItem key="nav-items-search">
                        <Link href="/search" as="/search">
                            <a data-cy="searchMobile-btn">
                                <IconButton
                                    renderIcon={() => (
                                        <StyledIcon>
                                            <SearchIcon width="36px" height="36px" />
                                        </StyledIcon>
                                    )}
                                />
                                <NavText>{t(`common:Header.Menu Items.arama`)}</NavText>
                            </a>
                        </Link>
                    </NavItem>
                </NavContent>
            </Container>
        </Modal>
    );
};

export { MobileNavigationMenu };
