// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions";
// #endregion Local Imports

export const Container = styled.div`
    width: 1054px;

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        width: 727px;
    }

    @media screen and (max-width: ${theme.maxWidth.small}) {
        width: 365px;
    }
`;

export const FlexWrapper = styled.div<{ dir?: string }>`
    display: flex;
    flex-direction: ${({ dir }) => (dir === "column" ? "column" : "row")};
    align-items: center;

    @media screen and (max-width: ${theme.maxWidth.small}) {
        &.call-to-action-buttons {
            flex-direction: column;
            padding: unset;

            > div {
                // Button
                width: 100%;
                margin: 8px 0;
            }
        }
    }

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        &.slider-row {
            align-items: unset;
            width: 100%;
            height: 600px;
        }

        &.call-to-action-buttons {
            padding: 0 16px;

            > div {
                // Button
                min-width: calc(100% - 32px);
                margin: 8px 0;
                font-size: 18px;
            }
        }
    }

    &.call-to-action-buttons {
        position: relative;
        width: 100%;
        margin: 48px 0 32px;
        justify-content: space-between;
        box-sizing: border-box;

        > div {
            // Button
            min-width: calc(50% - 16px);
            box-sizing: border-box;
        }
    }
`;

export const CardColumn = styled.div`
    padding: 0 16px;
    display: flex;
`;

export const SectionIndicator = styled.h4`
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
`;

export const SectionTitle = styled.h1`
    font-weight: bold;
    text-transform: capitalize;
    font-size: 48px;
    margin: 0 0 16px;

    @media screen and (max-width: ${theme.maxWidth.small}) {
        font-size: 30px;
        text-align: center;
    }
`;
