// #region Global Imports
import * as React from "react";
import Link from "next/link";
import Router from "next/router";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Months } from "@Interfaces/arrays";
import { CoinTransactionType } from "@Interfaces/enums";
import { Image, Text, Button } from "@Components/Basic";
import { Preset } from "@Definitions";
import {
    Container,
    Left,
    Right,
    GreenLight,
    Username,
    Description,
    Date,
    ShowButton,
    MidSpace,
} from "./styled";
import { IUserItem } from "./UserItem";
import { Analytics, CoinService, InteractionService } from "@Services";
import { DashboardActions } from "@Actions";
// #endregion Local Imports

const UserItem: React.FunctionComponent<IUserItem.IProps> = ({
    date,
    imageSrc,
    isHidden,
    id,
    username,
    age,
    location,
    isOnline,
    isNew,
    t,
    setSelectedItem,
    isShowConfirmCredit,
}): JSX.Element => {
    const { useState } = React;
    const [isVisible, setIsVisible] = useState(!isHidden);
    const [featureCoin, setFeatureCoin] = useState(0);
    const [directToCoinBuy, setDirectToCoinBuy] = useState(false);
    const [confirmCreditSectionCancel, setConfirmCreditSectionCancel] = useState(true);

    const dispatch = useDispatch();

    let starred = "";

    if (!isVisible && username) {
        starred = username.split("")[0] + new Array(username.length - 1).fill("*").join("");
    }

    const dateString = `${date &&
        `${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`}`;

    const txt = (key: string) => t(`dashboard:HotOrNot.UserItem.${key}`);

    const showUser = async () => {
        setConfirmCreditSectionCancel(true);
        const checkCoinCountRequest = await CoinService.CheckCoinCount({
            type: CoinTransactionType.LikeAvatar,
        });

        setFeatureCoin(checkCoinCountRequest.FeatureCoin);
        setSelectedItem(id);

        if (!checkCoinCountRequest.IsEnough) {
            setDirectToCoinBuy(true);
        }
    };

    const confirmCreditSectionSuccess = async () => {
        const openVisibilityRequest = await InteractionService.OpenVisibility(id);

        if (openVisibilityRequest.Success) {
            setConfirmCreditSectionCancel(false);
            setIsVisible(true);
            dispatch(await DashboardActions.SetVisibility(id.toString()));
            Analytics.event("Spent Coin", { sa_feature: "liked me" });
        } else if (directToCoinBuy) {
            await Router.push("/payments/[type]/credit-card", "/payments/coin/credit-card");
            setDirectToCoinBuy(false);
        } else {
            await Router.push(
                `/payments/[type]/credit-card?source=hotornot`,
                `/payments/coin/credit-card/payment`
            );
        }
    };

    const onConfirmCreditSectionCancel = async () => {
        setConfirmCreditSectionCancel(false);
    };

    const renderConfirmation = () => {
        return (
            <div className="text-center my-11 px-6">
                <Text className="mb-2" weight="bold" size="medium">
                    {t("common:ConfirmCreditModal:description", {
                        coin: featureCoin,
                    })}
                </Text>
                <Button
                    className="text-uppercase mr-2"
                    size="small"
                    onClick={confirmCreditSectionSuccess}
                >
                    {t("common:ConfirmCreditModal.confirmButton")}
                </Button>
                <Button size="small" styleType="muted" onClick={onConfirmCreditSectionCancel}>
                    İptal Et
                </Button>
            </div>
        );
    };

    const renderUserItem = () => (
        <Container>
            <Left>
                <Image
                    src={`${imageSrc}${isVisible ? Preset.thumb2x : Preset.blurThumb2x}`}
                    alt={username}
                />
                {isOnline && <GreenLight />}
            </Left>

            <MidSpace />
            <Right>
                <div>
                    <Date>
                        {isNew && <span className="bold">{`${txt("new")} `}</span>}
                        {dateString}
                    </Date>
                    <Username>{!isVisible ? starred : username}</Username>
                    <Description>{`${age}, ${location}`}</Description>
                </div>
                <div>
                    {!isVisible && (
                        <ShowButton iconClick={showUser}>
                            <span>{txt("show")}</span>
                        </ShowButton>
                    )}
                </div>
            </Right>
        </Container>
    );

    if (isVisible) {
        return (
            <Link href="/profile/[id]?source=hotornot" as={`/profile/${id}`}>
                <a>{renderUserItem()}</a>
            </Link>
        );
    }

    if (isShowConfirmCredit && confirmCreditSectionCancel) {
        return renderConfirmation();
    }

    return renderUserItem();
};

export { UserItem };
