// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

const SharedSpan = styled.span`
    color: ${({ theme }) => theme.colors.gray};
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Container = styled.div`
    background-image: ${({ theme }) => theme.gradients.grayHeader};
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.18);
    width: 100%;
    min-height: 84px;
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        min-height: 64px;
    }
`;

const Grid = styled.div`
    display: flex;
    height: 84px;
    justify-content: space-between;
    align-self: center;
`;

const Logo = styled.img<Partial<{ isMobile: boolean }>>`
    display: flex;
    width: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
    height: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
`;

const HamburgerMenuIcon = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.badgeGreen};
`;

const HamburgerMobileMenuIcon = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.gray};
`;

const Left = styled.div<Partial<{ isMobile: boolean }>>`
    display: flex;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-left: ${({ isMobile }) => (isMobile ? "12px" : "39px")};
    }
`;

const Middle = styled.div`
    display: flex;
`;
const ProfileMenuIcon = styled.div`
    display: flex;
    position: absolute;
    top: 1px;
    left: 1px;
    opacity: 1;
`;

const ProfileAvatarIcon = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
`;

const Profile = styled.div`
    position: relative;
    padding: 10px;
    width: 37px;
    height: 44px;
    :hover {
        .avatar {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }
`;

const ProfileItem = styled.div`
    width: 160px;
    display: flex;
`;

const textCss = css`
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray};
    letter-spacing: -0.28px;
`;

const ProfileItemText = styled.div`
    ${textCss}
    padding: 9px;

    :hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.badgeGreen};
        border-radius: 40px;
        cursor: pointer;
        a {
            display: flex;
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.badgeGreen};
            border-radius: 40px;
            cursor: pointer;
        }
    }
    a {
        ${textCss}
    }
`;

const ProfileItemToolTip = css`
    padding: 10px;
    margin: 8px 0;
    border-top: 2px solid ${({ theme }) => theme.colors.badgeGreen};
    border-radius: 0 0px 8px 8px;
    background-color: #fff;
    box-shadow: 0 0 20px -10px;

    ::before {
        right: 7px;
        border-left: 9px solid ${({ theme }) => theme.colors.transparent};
        border-right: 9px solid ${({ theme }) => theme.colors.transparent};
        border-bottom: 8px solid ${({ theme }) => theme.colors.badgeGreen};
        background-color: ${({ theme }) => theme.colors.transparent};
        transform: rotateZ(0deg) translateY(-20px);
    }
`;

export const MenuItemToolTip = css`
    padding: 10px;
    margin: 15px 0;
    border-top: 2px solid ${({ theme }) => theme.colors.badgeGreen};
    border-radius: 0 0px 8px 8px;
    background-color: #fff;
    box-shadow: 0 0 20px -10px;

    ::before {
        right: 123px;
        border-left: 9px solid ${({ theme }) => theme.colors.transparent};
        border-right: 9px solid ${({ theme }) => theme.colors.transparent};
        border-bottom: 8px solid ${({ theme }) => theme.colors.badgeGreen};
        background-color: ${({ theme }) => theme.colors.transparent};
        transform: rotateZ(0deg) translateY(-20px);
    }
`;

const Right = styled.div<Partial<{ isMobile: boolean }>>`
    display: flex;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-right: ${({ isMobile }) => (isMobile ? "12px" : "39px")};
    }
`;

const ButtonContent = styled.div`
    position: relative;
    margin-right: 7px;

    :hover {
        svg {
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;

const Label = styled(SharedSpan)`
    font-size: 12px;
    letter-spacing: -0.18px;
    line-height: 10px;
`;
const CointText = styled(SharedSpan)`
    width: 47px;
    height: 20px;
    margin-left: 4px;
    font-size: 10px;
    letter-spacing: -0.15px;
    line-height: 10px;
`;
const CoinCountText = styled(SharedSpan)`
    font-size: 24px;
    letter-spacing: -0.3px;
    align-self: center;
`;

const Icon = styled.i`
    font-size: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.gray};
`;

const MobileContainer = styled.div`
    background-image: ${({ theme }) => theme.gradients.grayHeader};
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.18);
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NavigationMenu = styled.div`
    display: flex;
    margin-left: 18px;
    margin-bottom: 2px;
`;

export const HoverToolTipWrapper = styled.div`
    opacity: 0;
`;

const NavItem = styled.div`
    position: relative;
    height: 36px;

    > a {
        display: block;
        padding-left: 10px;

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            padding-left: 8px;
        }
    }

    :hover {
        ${HoverToolTipWrapper} {
            opacity: 1;
        }
    }
`;

const StyledBadge = styled.i`
    :hover {
        span {
            background: ${({ theme }) => theme.colors.badgeHoverRed};
            cursor: pointer;
        }
    }
`;

const StyledIcon = styled.div`
    color: ${({ theme }) => theme.colors.gray};
    align-items: center;
    display: flex;
    border-radius: 50%;
    :hover {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.green};
    }
`;

export {
    Grid,
    Container,
    CoinCountText,
    CointText,
    Profile,
    ProfileItem,
    ButtonContent,
    Logo,
    Left,
    Middle,
    Right,
    ProfileItemToolTip,
    ProfileAvatarIcon,
    ProfileMenuIcon,
    ProfileItemText,
    StyledBadge,
    StyledIcon,
    MobileContainer,
    HamburgerMenuIcon,
    HamburgerMobileMenuIcon,
    Label,
    NavigationMenu,
    NavItem,
    Icon,
};
