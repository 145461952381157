// #region Global Imports
import React from "react";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { Check } from "@Components/Icons";
import { theme } from "@Definitions/Styled";
import { Avatar } from "@Components";
import {
    Container,
    Time,
    New,
    AvatarContent,
    InfoContent,
    SeenInfo,
    Seen,
    Name,
    Message,
    customAvatarStyle,
} from "./styled";
import { Preset, friendlyBubbleText, useScreenSize } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IMessageListItem } from "./MessageListItem";
// #endregion Interface Imports

export const MessageListItem: React.FunctionComponent<IMessageListItem.IProps> = ({
    t,
    data,
    onClick,
    isActive,
}) => {
    const {
        With: { Photo, IsOnline, Username, Id },
        LastMessage: { Date, Text, Type, SenderId, IsRead },
        HasNew,
    } = data;

    const renderSeenIcons = () => {
        let color = theme.colors.gray;

        if (SenderId === Id) {
            return <></>;
        }
        if (IsRead) {
            color = theme.colors.sliderAqua;
        }

        return (
            <>
                <Check width="16" height="10" color={color} />
                <Check width="12" height="10" color={color} />
            </>
        );
    };

    const screenSize = useScreenSize();

    const safelyPrintMessagePreview = () => {
        switch (screenSize) {
            case "small":
                return friendlyBubbleText(Text, 32);

            case "medium":
            case "large":
                return friendlyBubbleText(Text, 13);

            default:
                return friendlyBubbleText(Text, 20);
        }
    };

    return (
        <>
            <Container
                data-cy="message-list-item-box"
                onClick={onClick}
                HasNew={HasNew}
                isActive={isActive}
            >
                <AvatarContent>
                    <Avatar
                        src={`${Photo?.Url || ""}${Preset.thumb}`}
                        type={IsOnline ? "online" : "offline"}
                        alt={Photo?.PhotoName || ""}
                        customStyle={customAvatarStyle}
                    />
                </AvatarContent>

                <InfoContent>
                    <SeenInfo>
                        <Seen>{renderSeenIcons()}</Seen>

                        <Time isSeen={HasNew}>
                            {HasNew && (
                                <New>
                                    &nbsp;
                                    {t("common:Yeni")}
                                </New>
                            )}
                            {dayjs(Date).fromNow()}
                        </Time>
                    </SeenInfo>

                    <Name>{Username}</Name>

                    <Message>
                        {Type === 3 ? "Çıkartma gönderdi..." : safelyPrintMessagePreview()}
                    </Message>
                </InfoContent>
            </Container>

            <hr className="py-0 my-1" />
        </>
    );
};
