// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Card } from "@Components/Basic/Card";
// #endregion Local Imports

export const Container = styled.div`
    position: relative;
`;

export const Modal = styled.div`
    position: absolute;
    left: calc(-300px / 2);
    bottom: calc(100% + 15px);
    margin-left: calc(100% / 2);
    z-index: 100;
    &::before {
        content: " ";
        width: 0;
        height: 0px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-bottom: -4px;
        margin-left: -5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #fff;
    }
`;

export const ModalContent = styled(Card)`
    text-align: center;
    min-width: 300px;
    padding: 20px 10px;
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
`;
