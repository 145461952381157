// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

// #region  Interface Imports
import { Gender } from "@Interfaces/enums";

// #endregion  Interface Imports

export const Container = styled.div<{ customStyle?: CSSProp; isModal?: boolean; gender?: Gender }>`
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: ${({ gender }) =>
        gender === Gender.female ? `url("/images/female-bg-1.png")` : `url("/images/male-bg.png")`};
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: ${({ isModal }) => (isModal ? "0px" : "48px")};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ isModal }) => (isModal ? "0px" : "10px")};
    height: ${({ isModal }) => (isModal ? "100%" : "92%")};
    width: 255px;
    padding: 0 15px 0 15px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 210px;
        border-radius: ${({ isModal }) => (isModal ? "0" : "10px")};
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 100%;
    }

    ${({ customStyle }) => customStyle && customStyle};
    ${({ isModal, gender, theme }) =>
        css`
            @media (max-width: ${isModal ? theme.maxWidth.small : theme.maxWidth.medium}) {
                flex-direction: row;
                background-image: unset;
                height: 68px;
                width: 100%;
                background: ${({ theme: { gradients } }) =>
                    gender === Gender.female ? gradients.pinkToBlue : gradients.estorilToBlue};
                margin-top: 0;
                padding-bottom: 0;
            }
        `};
`;

export const AvatarContainer = styled.div<{ isModal?: boolean }>`
    margin-top: ${({ isModal }) => (isModal ? "50px" : "-42px")};

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: ${({ isModal }) => (isModal ? "50px" : "0px")};
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-top: 0px;
    }
`;

export const BubbleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    display: hidden;
`;

export const ArrowIcon = styled.i`
    height: 100%;
    font-size: 40px;
    margin-bottom: 8px;
    transform: rotate(180deg);
`;

export const LastLogin = styled.span<{ isEmptyView?: boolean }>`
    font-size: 9px;
    margin-bottom: 0px
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-bottom: 15px;
    }
`;

export const ProfileProperties = styled.div<{ customStyle?: CSSProp }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 15px;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        align-items: baseline;
    }
    ${({ customStyle }) => customStyle && customStyle};
`;

export const ProfilePropertiesCustomCss = (isModal?: boolean) => css`
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-right: 32px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-left: 4px;
        margin-right: 20px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        ${!isModal && "align-items:  flex-start;"}

        margin: 0 0 0 10px;
    }
`;

export const Username = styled.a<{ customStyle?: CSSProp }>`
    font-size: 15px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

    ${({ customStyle }) => customStyle && customStyle};

    &:hover {
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const ProfileUsernameCss = css`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: 0;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 12px;
    }
`;

export const IconInfo = styled.div<{ customStyle?: CSSProp }>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
`;

export const ZodiacContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

    &.profile-card {
        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            justify-content: unset;
            width: 100%;
        }
        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            font-size: 11px;
        }
    }
`;

export const IconInfoSpan = styled.span<{ customStyle?: CSSProp }>`
    margin-left: 8px;
    font-size: 12px;
    :not(:last-of-type)::after {
        content: ", ";
    }
    :last-child::after {
        content: "";
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-left: 7px;
        font-size: 11px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-left: 0;
        margin-right: 2px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 10px;
    }

    ${({ customStyle }) => customStyle && customStyle};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 110px;

    &.profile-card {
        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            margin-top: 0;
        }
    }
`;

export const customAvatarStyle = (isModal?: boolean) => css`
    width: 215px;
    height: 215px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 180px;
        height: 180px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: ${isModal ? "180px" : "48px"};
        height: ${isModal ? "180px" : "48px"};
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 48px;
        height: 48px;
    }
`;

export const customAvatarEmptyView = css`
    width: 269px;
    height: 269px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 180px;
        height: 180px;
    }
`;

export const customBubbleStyle = css`
    width: 230px;
    opacity: 0.8;
    max-height: 82px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px 0 25px 0;

    &:after {
        border-top-color: rgba(0, 0, 0, 0.3);
    }

    .info-bubble-text {
        line-height: 1.1;
        font-size: 13px;
        text-align: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-right: 0 !important;
        margin-top: 0;
        width: 185px;
        max-height: 100px;
    }

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        margin-right: 0 !important;
    }
`;

export const customProfileIntButton = css`
    padding-right: 8px;
    padding-left: 8px;
    width: 95%;

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        border-radius: 30px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 52px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 96%;
    }
`;

Container.displayName = "Container";
