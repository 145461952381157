import styled from "styled-components";

export const InputWrapper = styled.div`
    width: 345px;
    margin: 60px 0;
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
