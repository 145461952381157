// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Card, Title } from "@Components/Basic";
import {
    AboutYouContent,
    Container,
    LookingContent,
    Item,
    TitleText,
    Text,
    BadgeContent,
    BadgeText,
    BadgeItem,
    Content,
    LookingGenderContent,
    SmileIcon,
    CustomAboutYouInfoMobileBubbleStyle,
    CustomAboutYouInfoBubbleStyle,
    TitleTextContainer,
} from "./styled";
import { useScreenSize, findLookingForGender } from "@Definitions";
import { ILookingForCard } from "./LookingForCard";
import { InfoBubble } from "@Components";
// #endregion Local Imports

const LookingForCard: React.FunctionComponent<ILookingForCard.IProps> = ({
    t,
    lookingForGender,
    lookingForMaxAge,
    lookingForMinAge,
    aboutYou,
    relationshipType,
}): JSX.Element => {
    const screenSize = useScreenSize();
    const showSmileIcon = screenSize.includes("large");

    const renderRelationshipTypes = (): JSX.Element => {
        if (relationshipType) {
            const getKey = (item: string) => `looking-for-badge-items-${item}`;
            return (
                <Item>
                    <TitleText>{t("common:LookingForProfile.Aradığı İlişki Türü")}</TitleText>
                    <BadgeContent>
                        {relationshipType.split(",").map(item => (
                            <BadgeItem key={getKey(item)}>
                                <BadgeText>{item}</BadgeText>
                            </BadgeItem>
                        ))}
                    </BadgeContent>
                </Item>
            );
        }
        return <div />;
    };
    return (
        <Container>
            <Card>
                <TitleTextContainer>
                    <Title fontWeight="bold" size="large">
                        {t("common:LookingForProfile.aradığı kişi")}
                    </Title>
                </TitleTextContainer>
                <Content>
                    <LookingContent>
                        <Item>
                            <TitleText>
                                {t("common:LookingForProfile.Aradığı yaş aralığı")}
                            </TitleText>
                            <Text>
                                {lookingForMinAge}
                                {" - "}
                                {lookingForMaxAge}
                            </Text>
                        </Item>
                        <Item>
                            <TitleText>{t("common:LookingForProfile.Aradığı cinsiyet")}</TitleText>
                            <Text>{findLookingForGender(t, lookingForGender)}</Text>
                        </Item>
                    </LookingContent>
                    <LookingGenderContent>{renderRelationshipTypes()}</LookingGenderContent>
                </Content>
                {aboutYou && (
                    <AboutYouContent>
                        {showSmileIcon && (
                            <SmileIcon src="/icons/smile-heart-icon.svg" alt="about-you" />
                        )}

                        <InfoBubble
                            text={aboutYou}
                            fullBubble={false}
                            customStyle={
                                showSmileIcon
                                    ? CustomAboutYouInfoBubbleStyle
                                    : CustomAboutYouInfoMobileBubbleStyle
                            }
                        />
                    </AboutYouContent>
                )}
            </Card>
        </Container>
    );
};

export { LookingForCard };
