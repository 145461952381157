// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ flexDirection: string }>`
    font-family: "Montserrat";

    display: flex;
    flex-direction: column;
    padding: 12px 12px 0px 12px;
    width: ${({ flexDirection }) => (flexDirection === "column" ? "50%" : "100%")};
`;
export const Content = styled.div<{ flexDirection: string }>`
    display: flex;
    flex: 1;
    flex-direction: ${({ flexDirection }) => flexDirection};
    padding: 10px 0px;
    font-size: 12px;
    font-family: "Montserrat";
`;

export const Answer = styled.div`
    display: flex;
    flex: 1;
    word-break: break-all;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const Question = styled.div`
    display: flex;
    flex: 1;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
export const HR = styled.div`
    width: auto;
    border: 1px dashed ${({ theme }) => theme.colors.lightGray};
`;

export const EditButton = styled.button`
    border: unset;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.transparent};
    border: unset;

    :focus {
        outline: unset;
    }
    :hover {
        cursor: pointer;
    }
    transition: opacity 0.3s ease;
`;
export const DeleteButton = styled.button`
    display: flex;
    align-items: center;
    border: unset;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.transparent};
    border: unset;
    :focus {
        outline: unset;
    }
    &:hover {
        cursor: pointer;
        svg {
            color: ${({ theme }) => theme.colors.red};
        }
    }

    transition: opacity 0.3s ease;
`;

export const EditableContent = styled.div<{ flexDirection: string }>`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
    justify-content: space-between;
    padding: 10px 0px;
    margin: 5px 0px;
    font-size: 12px;
    transition: all 0.2s ease;

    :hover {
        background: ${({ theme }) => theme.colors.lightBlue};
        color: ${({ theme }) => theme.colors.azureRadianceDark};
        padding-left: 5px;
        border-radius: 12px;
        cursor: pointer;

        ${EditButton} {
            opacity: 1;
        }

        ${DeleteButton} {
            opacity: 1;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        ${EditButton} {
            display: none;
        }

        ${DeleteButton} {
            opacity: 1;
        }
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
`;

export const QuestionGroup = styled.div`
    display: flex;
    flex-direction: column;
`;
