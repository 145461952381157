import * as React from "react";

const SvgLeoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 12 17" {...props}>
        <path
            d="M-.007 10.005c0-.393.044-.742.18-1.048.134-.35.269-.611.493-.874.225-.262.45-.48.764-.655.314-.174.628-.306.987-.393-.09-.262-.18-.524-.224-.83-.045-.306-.09-.611-.09-.917 0-.612.09-1.18.314-1.704.225-.524.494-.96.854-1.354.359-.393.853-.699 1.392-.917a4.688 4.688 0 011.796-.35c.674 0 1.257.132 1.796.35.54.218.988.568 1.392.961.405.437.674.917.899 1.529.224.611.314 1.223.314 1.965 0 .656-.045 1.223-.135 1.791a13.71 13.71 0 01-.36 1.573c-.134.48-.313.96-.493 1.398-.18.436-.36.873-.494 1.266-.135.393-.27.786-.36 1.18a5.28 5.28 0 00-.134 1.179c0 .218.045.437.09.611a.902.902 0 00.27.437c.134.131.269.219.403.262.18.044.315.088.54.088.179 0 .403 0 .538-.044.18-.044.315-.087.45-.175l.403 1.092c-.224.131-.494.219-.763.306-.27.087-.539.087-.853.087-.315 0-.629-.043-.943-.13a1.889 1.889 0 01-.809-.481c-.224-.218-.449-.48-.583-.83a3.317 3.317 0 01-.225-1.223c0-.48.045-.917.135-1.354.09-.437.224-.83.36-1.267.134-.393.313-.83.493-1.267.18-.436.36-.873.494-1.31.135-.48.27-.96.36-1.441a10.1 10.1 0 00.134-1.704c0-.611-.09-1.18-.27-1.616-.18-.48-.404-.83-.673-1.136a2.435 2.435 0 00-.988-.655 3.413 3.413 0 00-1.123-.218c-.404 0-.808.087-1.122.218-.36.131-.629.35-.898.612-.27.262-.45.567-.584.96-.135.394-.225.83-.225 1.355 0 .305.045.611.09.873s.135.568.225.874c.404.043.718.175 1.032.35.315.174.584.392.809.654.224.263.404.568.539.918.134.35.18.699.18 1.092 0 .437-.09.83-.225 1.223-.18.393-.36.699-.674.96-.314.263-.629.481-1.033.656-.404.175-.808.218-1.302.218s-.898-.087-1.302-.218c-.404-.175-.719-.35-.988-.655a4.673 4.673 0 01-.674-.961c-.09-.437-.18-.874-.18-1.31h0zm1.392 0c0 .262.045.525.134.743.09.218.225.393.36.568.18.174.359.262.583.35.225.087.45.13.719.13.27 0 .494-.043.718-.13.225-.088.405-.22.584-.35.18-.175.27-.35.36-.568.09-.218.134-.48.134-.743a1.99 1.99 0 00-.135-.742 1.788 1.788 0 00-.359-.568c-.18-.175-.36-.262-.584-.35a1.908 1.908 0 00-.718-.13c-.27 0-.494.043-.719.13-.224.088-.404.219-.583.35-.18.175-.27.35-.36.568a1.99 1.99 0 00-.134.742h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgLeoIcon;
