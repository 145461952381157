// #region Global Imports
import React from "react";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import {
    Message,
    MessageBox,
    Time,
    Tick,
    MessageInfo,
    sentStyle,
    receivedStyle,
    StickerImage,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IMessageItem } from "./MessageItem";
// #endregion Interface Imports

export const MessageItem: React.FunctionComponent<IMessageItem.IProps> = ({
    text,
    date,
    isRead,
    isSent,
    type,
    sticker,
}) => {
    return (
        <Message customStyle={isSent ? sentStyle : receivedStyle}>
            {type === 3 && sticker ? (
                <StickerImage src={sticker.Gif} alt="GIF" isSent={isSent} />
            ) : (
                <MessageBox data-cy="message-item">{text}</MessageBox>
            )}

            <MessageInfo>
                <Time>{dayjs(date).fromNow()}</Time>
                {isSent && (
                    <>
                        <Tick className="icon check" />
                        {isRead && <Tick className="icon check" />}
                    </>
                )}
            </MessageInfo>
        </Message>
    );
};
