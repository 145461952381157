// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container, customSliderCss } from "./styled";
import { SearchActions } from "@Actions";
import { EmptyViewProfileCard, Text, SmartSlider } from "@Components";
import { useScreenSize, getCustomSliderConfig } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IInboxEmptyView } from "./InboxEmptyView";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

export const InboxEmptyView: React.FunctionComponent<IInboxEmptyView.IProps> = ({ t }) => {
    const dispatch = useDispatch();
    const screenType = useScreenSize();

    React.useEffect(() => {
        (async () => {
            dispatch(await SearchActions.InboxEmptyViewSearch());
        })();
    }, [dispatch]);

    const users = useSelector((state: IStore) => state.inbox.emptyViewSearch?.result);

    const customSliderConfig = getCustomSliderConfig(screenType, {
        xsmall: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
        },
        small: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
        },
        common: {
            hide: false,
        },
    });

    const getKey = (type: string) => `empty-view-${type}`;

    const ProfileCards = users?.map(user => (
        <div key={getKey(user.Id)} className="col-lg-3 col-md-4 d-flex justify-content-center mb-3">
            <EmptyViewProfileCard t={t} profile={user} />
        </div>
    )) as Array<React.ReactElement>;

    return (
        <Container>
            <div className="d-flex flex-column align-items-center mb-7 text-center">
                <Text className="mb-3" color="red" weight="bold" size="xLarge" elementType="span">
                    {t("common:inbox.empty-view.no-message")}
                </Text>
                {ProfileCards && (
                    <Text
                        className="mb-3"
                        color="black"
                        weight="bold"
                        size="medium"
                        elementType="span"
                    >
                        {t("common:inbox.empty-view.meet-member")}
                    </Text>
                )}
            </div>

            {(screenType === "small" || screenType === "xsmall") && ProfileCards ? (
                <SmartSlider
                    {...customSliderConfig}
                    slides={ProfileCards}
                    customSliderCss={customSliderCss}
                />
            ) : (
                <div className="container">
                    <div className="row">{ProfileCards}</div>
                </div>
            )}
        </Container>
    );
};
