import React from "react";

import { Gender as GenderType } from "@Interfaces/enums";
import {
    WomanAddedFriendIcon,
    WomanAddFriendIcon,
    ManAddedFriendIcon,
    ManAddFriendIcon,
} from "@Icons";

export const findButtonIcon = (gender: GenderType, isFriend: boolean) => {
    switch (gender) {
        case GenderType.female:
            return isFriend ? (
                <WomanAddedFriendIcon width="43px" height="40px" />
            ) : (
                <WomanAddFriendIcon width="43px" height="40px" />
            );
        case GenderType.male:
            return isFriend ? (
                <ManAddedFriendIcon width="43px" height="40px" />
            ) : (
                <ManAddFriendIcon width="43px" height="40px" />
            );
        default:
            return <ManAddFriendIcon />;
    }
};
