// #region Global Imports
import React, { useState, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";
import * as Scroll from "react-scroll";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { SiberalemLogo, SiberalemHearts, AppleStoreMin, GooglePlayMin } from "@Icons";
import {
    Container,
    ContentContainer,
    Content,
    DFlex,
    LogoDiv,
    IconsDiv,
    IconButton,
    ButtonsDiv,
/*     Anniversary20th, */
} from "./styled";
import { HomeActions } from "@Actions";
import { ILandingHeader } from "./LandingHeader";
import { useScreenSize, theme } from "@Definitions";
// #endregion Local Imports

export const LandingHeader: FC<ILandingHeader.IProps> = ({ isSticky, t }): JSX.Element => {
    const dispatch = useDispatch();

    const [didMount, setDidMount] = useState(false);

    const scrollToTop = (andThen?: Function) => {
        if (didMount) {
            Scroll.animateScroll.scrollToTop({ ignoreCancelEvents: true });
        }

        if (andThen) andThen();
    };

    const setTopComponent = (topComponent: "register" | "login" | "card") => {
        scrollToTop(() => dispatch(HomeActions.SetTopComponent({ topComponent })));
    };

    useEffect(() => {
        setDidMount(true);
    }, []);

    const screenSize = useScreenSize();

    const renderLogo = () => {
        if (isSticky) {
            if (screenSize !== "small") return <SiberalemHearts width="62" height="62" />;

            return <SiberalemHearts width="300" height="62" color={theme.colors.white} />;
        }

        return <SiberalemLogo width="300" height="62" color={theme.colors.white} />;
    };

    return (
        <Container isSticky={isSticky}>
            <ContentContainer>
                <Content>
                    <DFlex>
                        <LogoDiv onClick={() => setTopComponent("card")}>{renderLogo()}</LogoDiv>

                        <IconsDiv className={isSticky ? "hidden" : ""}>
                            <IconButton className="apple-store">
                                <Link href="//itunes.apple.com/tr/app/siberalem/id649741474?mt=8">
                                    <a target="_blank" className="text-center">
                                        <AppleStoreMin width="16px" height="16px" />
                                    </a>
                                </Link>
                            </IconButton>

                            <IconButton className="google-play">
                                <Link href="//play.google.com/store/apps/details?id=com.workbench.siberalem&hl=tr">
                                    <a target="_blank" className="text-center">
                                        <GooglePlayMin width="16px" height="16px" />
                                    </a>
                                </Link>
                            </IconButton>
                        </IconsDiv>
                    </DFlex>

                    {/*         <Anniversary20th>
                        <img
                            srcSet="/images/Badge.png, /images/Badge@2.png 2x"
                            src="/images/Badge@2.png"
                            alt="Siberalem 20 Yaşında!"
                        />
                    </Anniversary20th> */}

                    <ButtonsDiv>
                        {isSticky && (
                            <Button
                                styleType="gradient"
                                size="large"
                                className="mr-3"
                                onClick={() => setTopComponent("register")}
                            >
                                {t("common:buttons.sign")}
                            </Button>
                        )}

                        <Button
                            styleType="gradient"
                            size="large"
                            cy="login-btn"
                            onClick={() => setTopComponent("login")}
                        >
                            {t("common:buttons.login")}
                        </Button>
                    </ButtonsDiv>
                </Content>
            </ContentContainer>
        </Container>
    );
};
