// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

import { ModalHeader, customModalCloseIcon } from "@Styled/Modal";

export const MainContainer = styled.div`
    overflow-x: hidden;
    @media (min-width: ${({ theme }) => theme.maxWidth.small}) {
        max-width: 825px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.medium}) and (max-width: ${({ theme }) =>
            theme.maxWidth.medium}) {
        min-height: 100vh;
        min-width: 100vw;
    }
`;

export const Header = styled(ModalHeader)`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        background: ${({ theme }) => theme.colors.white};
        border-radius: 0px;
        color: ${({ theme }) => theme.colors.red};
        text-shadow: none;
        padding-top: 37px;
    }
`;

export const InfoCardContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        & > * {
            border-radius: 0px 0px 12px 0px;
        }
    }
`;

export const Row = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0px 0px 12px 12px;
    @media (min-width: ${({ theme }) => theme.minWidth.medium}) and (max-width: ${({ theme }) =>
            theme.maxWidth.medium}) {
        min-height: calc(100vh - ${({ theme }) => theme.heights.modalHeaderHeight});
    }
`;

export const Col = styled.div`
    background-image: url("/images/coins.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 300px;
    flex-grow: 1;
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        background: none;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.small}) and (max-width: ${({ theme }) =>
            theme.maxWidth.medium}) {
        padding-bottom: 140px !important;
    }
`;

export const List = styled.div`
    padding-top: 20px;
    p {
        margin-bottom: 0.3rem;
        line-height: 1.3;
    }
`;

export const Buttons = styled.div`
    display: flex;
    padding-left: 2rem;
    flex-wrap: wrap;
    button {
        margin: 10px;
    }
`;

export const TextContainer = styled.div`
    padding-left: 2rem;
    div {
        margin-top: 1rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const closeIcon = css`
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        ${customModalCloseIcon}
    }
`;
