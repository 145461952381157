// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const customMobileInfoBubbleStyle = (color: string): CSSProp => css`
    background-color: ${color};
    border-radius: 8px;
    text-align: center;
    margin-top: 0px;
    max-height: unset;
    min-width: unset;
    :after {
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-right-color: ${color};
        border-left: 0;
        border-top: 0;
        margin-top: -10px;
        margin-left: -10px;
    }
    div {
        font-family: Montserrat;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-style: italic;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.darkGray};
        letter-spacing: 0;
        line-height: 16px;
    }
`;

export const customInfoBubbleStyle = (color: string): CSSProp => css`
    background-color: ${color};
    border-radius: 8px;
    text-align: center;
    margin: 0px 0px 15px 0px;
    max-height: unset;
    min-width: unset;
    :after {
        border-top-color: ${color};
    }
    div {
        display: flex;
        align-items: center;
        min-height: 65px;
        font-family: Montserrat;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-style: italic;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.darkGray};
        letter-spacing: 0;
        line-height: 16px;
        position: relative;
    }
`;

export const Container = styled.div<{ hasNew: boolean; isPrioritized: boolean }>`
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: ${({ theme: { colors }, isPrioritized }) =>
        isPrioritized ? colors.lightPink : colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border: 1px solid
        ${({ theme: { colors }, hasNew }) => (hasNew ? colors.badgeGreen : colors.transparent)};
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    font-size: 10px;
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const Content = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
    margin-top: 7px;
`;

export const AvatarContent = styled.div`
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
`;

export const Tooltip = styled.div`
    transition: 0s;
    opacity: 0;
    z-index: -1;
    padding: 10px;
    margin-right: 30px;
    font-size: 12px;
    border-radius: 10px;
    position: absolute;
    top: 100px;
    min-width: 50px;
    transition: all 0.5s ease-in-out 0.5s;

    :after {
        content: "";
        position: absolute;
        opacity: 0;
        top: 0;
        left: 20px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #2a2a2ad1;
        border-top: 0;
        margin-left: -10px;
        margin-top: -10px;
        transition: all 0.5s ease-in-out 0.5s;
    }
`;
export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    :hover ${Tooltip} {
        opacity: 1;
        background-color: #2a2a2ad1;
        top: 130px;
        z-index: 2;
        :after {
            opacity: 1;
        }
    }
`;
export const MobileTextContent = styled(TextContent)`
    margin-left: 18px;
`;
export const UserInfo = styled.div<{ isMobile: boolean }>`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
`;
export const Time = styled.div<{ isSeen: boolean }>`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: flex;
    font-size: 10px;
    color: ${({ isSeen, theme }) => (isSeen ? theme.colors.badgeGreen : theme.colors.gray)};
    letter-spacing: -0.15px;
`;
export const New = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    display: flex;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.badgeGreen};
    letter-spacing: -0.15px;
`;

export const DeleteButton = styled.div`
    display: flex;
`;
export const CustomPrioritizedIconStyle = styled.div`
    color: ${({ theme }) => theme.colors.darkPink};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const PrioritizedContent = styled.div<{ isMobile?: boolean }>`
    ${({ isMobile }) =>
        isMobile
            ? css`
                  display: flex;
              `
            : css`
                  position: absolute;
                  right: 0;
                  top: 0;
                  > div {
                      display: flex;
                      text-align: center;
                      color: red;
                  }
              `};

    flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
    justify-content: center;
    width: 80px;
    height: ${({ isMobile }) => (isMobile ? "18px" : "27px")};
    span {
        color: ${({ theme }) => theme.colors.darkPink};
        font-weight: ${({ theme }) => theme.fontWeights.heavyBold};
        font-size: ${({ isMobile }) => (isMobile ? "9px" : "12px")};
        line-height: 1;
        text-align: center;
        justify-content: center;
        display: flex;
        align-self: center;
        height: 35px;
        text-transform: uppercase;
    }
`;

export const customAvatarStyle = (isMobile: boolean): CSSProp =>
    css`
        cursor: pointer;
        width: ${isMobile ? 88 : 150}px;
        height: ${isMobile ? 88 : 150}px;
    `;

export const StyledDeleteIcon = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    align-items: center;
    display: flex;
    :hover {
        color: ${({ theme }) => theme.colors.sliderDarkBlue};
    }
`;

export const StyledChatIcon = styled.div`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.badgeGreen};
    align-items: center;
    display: flex;
    border-radius: 50%;
    cursor: pointer;

    :hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.badgeHoverGreen};
    }
`;

export const InfoIcon = styled.i<{ iconType: "chat" | "location" }>`
    font-size: ${({ iconType }) => (iconType === "chat" ? "54px" : "16px")};
    color: ${({ iconType, theme }) =>
        iconType === "chat" ? theme.colors.zodiacPurple : theme.colors.sliderAqua};

    margin-right: 8px;
`;

export const LocationText = styled.div`
    font-family: "Montserrat";
    font-size: 11px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: flex;
    align-items: center;
`;

export const Footer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 12px;
`;
