// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { MetaDataModel } from "./MetaData";
// #endregion Interface Imports

export const MetaDataService = {
    FetchCountries: async (): Promise<MetaDataModel.IResponse> => {
        try {
            const response: MetaDataModel.IResponse = await Http.Request(
                "POST",
                "/metadata/countries"
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    FetchCities: async (
        countryId: number,
        search = false
    ): Promise<MetaDataModel.IResponse> => {
        try {
            const response: MetaDataModel.IResponse = await Http.Request(
                "POST",
                "/metadata/cities",
                undefined,
                { countryId, search }
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    FetchTowns: async (
        cityId: number,
        search = false,
        keyword?: string
    ): Promise<MetaDataModel.IResponse> => {
        try {
            const response: MetaDataModel.IResponse = await Http.Request(
                "POST",
                "/metadata/towns",
                undefined,
                { cityId, search, keyword }
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    SearchFilterQuestions: async (): Promise<MetaDataModel.IFetchSearchFilterQuestions> => {
        const response = await Http.Request<MetaDataModel.IFetchSearchFilterQuestions>(
            "POST",
            "/metadata/SearchFilterQuestions",
            undefined,
            {
                questionIds: "201, 202, 203, 200, 4, 14, 16, 22, 46, 51, 56, 59, 61, 63",
            }
        );

        return response;
    },
    FetchJobs: async (): Promise<MetaDataModel.IResponse> => {
        try {
            const response: MetaDataModel.IResponse = await Http.Request("POST", "/metadata/jobs");
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
