// #region Global Imports
import { Dispatch, AnyAction } from "redux";
// #endregion Global Imports

// #region Locale Imports
import { ProfileQuestionType, MembershipType } from "@Interfaces/enums";
import { Analytics, ProfileService } from "@Services";
import { ActionConsts, ThrowIfNotAuthenticated } from "@Definitions";
import { IStore, ProfileModel, PhotoModel } from "@Interfaces";
import { CommonActions } from "@Actions";
// #region Locale Imports

const fetchEmptyQuestion = async (id: number) => {
    const result = await ProfileService.GetQuestion(id);

    if (result.Success) {
        return result.Question;
    }

    return undefined;
};

const reduceEmptyQuestions = async (
    dispatch: Function,
    getState: () => IStore,
    answeredQuestionId: number
) => {
    const { emptyQuestions, currentEmptyQuestion, skippedQuestionIds } = getState().profile;

    const reducedEmptyQuestions = emptyQuestions.filter(eq => eq.Id !== answeredQuestionId);

    let newEmptyQuestion = currentEmptyQuestion;

    if (reducedEmptyQuestions.length) {
        const unskippedQuestions = reducedEmptyQuestions.filter(
            req => !skippedQuestionIds.includes(req.Id)
        );

        newEmptyQuestion = await fetchEmptyQuestion(unskippedQuestions[0].Id);
    }

    const action = {
        payload: {
            emptyQuestions: reducedEmptyQuestions,
            currentEmptyQuestion: newEmptyQuestion,
        },
        type: ActionConsts.Profile.SetEmptyQuestions,
    };

    dispatch(action);
};

export const ProfileActions = {
    GetProfile: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        ThrowIfNotAuthenticated(getState);

        const { user } = getState().auth;

        try {
            const result = await ProfileService.GetProfile({
                userId: user!.UserId,
                token: user!.Token,
            });

            dispatch({
                payload: { self: result },
                type: ActionConsts.Profile.GetProfile,
            });
            // eslint-disable-next-line no-empty
        } catch (e) {}
    },
    GetProfileWithId: async (id: string | string[]) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        ThrowIfNotAuthenticated(getState);

        const result = await ProfileService.GetProfileWithId(Number(id));

        dispatch({
            payload: { visitProfile: result },
            type: ActionConsts.Profile.GetProfileWithId,
        });
    },

    GetEmptyQuestions: async () => async (dispatch: Dispatch) => {
        const result = await ProfileService.GetEmptyQuestions();

        if (result.Success) {
            let currentEmptyQuestion;
            const emptyQuestions = result.Questions;

            if (emptyQuestions.length) {
                currentEmptyQuestion = await fetchEmptyQuestion(emptyQuestions[0].Id);
            }

            dispatch({
                payload: {
                    emptyQuestions,
                    currentEmptyQuestion,
                    fetchedEmptyQuestions: true,
                },
                type: ActionConsts.Profile.SetEmptyQuestions,
            });
        }
    },

    GetQuestion: async (id: number) => async (dispatch: Dispatch) => {
        const result = await ProfileService.GetQuestion(id);

        dispatch({
            payload: { currentEmptyQuestion: result },
            type: ActionConsts.Profile.SetQuestion,
        });
    },

    SkipEmptyQuestion: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const { emptyQuestions, currentEmptyQuestion, skippedQuestionIds } = getState().profile;

        if (emptyQuestions.length && currentEmptyQuestion) {
            const emptyQuestion = emptyQuestions.find(eq => eq.Id === currentEmptyQuestion.Id);
            const emptyQuestionIndex = emptyQuestion ? emptyQuestions.indexOf(emptyQuestion) : 0;

            const unskippedQuestions = emptyQuestions.slice(emptyQuestionIndex + 1);

            let newQuestionId;
            let skippedIds = [...skippedQuestionIds];

            if (unskippedQuestions.length && emptyQuestion) {
                newQuestionId = unskippedQuestions[0].Id;
                skippedIds.push(emptyQuestion.Id);
            } else {
                newQuestionId = emptyQuestions[0].Id;
                skippedIds = [];
            }

            const newQuestion = await fetchEmptyQuestion(newQuestionId);

            dispatch({
                payload: {
                    skippedQuestionIds: skippedIds,
                    currentEmptyQuestion: newQuestion,
                },
                type: ActionConsts.Profile.SetEmptyQuestions,
            });
        }
    },

    UpdateQuestions: async (payload: ProfileModel.UpdateQuestionActionPayload) => async (
        dispatch: Function,
        getState: () => IStore
    ) => {
        const result = await ProfileService.UpdateQuestions({ body: payload });

        if (result.Success) await reduceEmptyQuestions(dispatch, getState, payload.questionId);

        dispatch(await CommonActions.SetOccupancy());
    },

    UpdateQuestionAndProfile: async (payload: ProfileModel.UpdateQuestionActionPayload) => async (
        dispatch: Function,
        getState: () => IStore
    ) => {
        dispatch({
            payload: { profileEditStatus: "loading" },
            type: ActionConsts.Profile.SetEditResult,
        });

        const result = await ProfileService.UpdateQuestions({ body: payload });

        const actionPayload: any = { profileEditStatus: result.Success ? "success" : "error" };

        if (result.Success) {
            await reduceEmptyQuestions(dispatch, getState, payload.questionId);

            switch (payload.questionId) {
                case ProfileQuestionType.PresentationTitle:
                    actionPayload.self = {
                        ...getState().profile.self,
                        PresentationTitle: payload.text || "",
                    };
                    break;

                default:
                    break;
            }
        }

        const action = {
            payload: actionPayload,
            type: ActionConsts.Profile.SetUpdateStatus,
        };

        dispatch(action);

        dispatch(await CommonActions.SetOccupancy());
    },

    UpdateProfileInfo: async (payload: ProfileModel.IInfo) => async (
        dispatch: Dispatch<AnyAction>,
        getState: () => IStore
    ) => {
        dispatch({
            payload: { profileEditStatus: "loading" },
            type: ActionConsts.Profile.SetEditResult,
        });

        const result = await ProfileService.UpdateInfo(payload);

        const actionPayload: any = {
            profileEditStatus: result.Success ? "success" : "error",
        };

        if (result.Success) {
            if (payload.maritalStatus) {
                actionPayload.self = {
                    ...getState().profile.self,
                    MaritalStatusId: payload.maritalStatus,
                };
            }
        }

        dispatch({
            payload: actionPayload,
            type: ActionConsts.Profile.SetUpdateStatus,
        });
    },

    // ReduceEmptyQuestions: async (answeredQuestionId: number) => async (
    //     dispatch: Dispatch,
    //     getState: () => IStore
    // ) => {
    //     const { emptyQuestions, currentEmptyQuestion, skippedQuestionIds } = getState().profile;

    //     const reducedEmptyQuestions = emptyQuestions.filter(eq => eq.Id !== answeredQuestionId);

    //     let newEmptyQuestion = currentEmptyQuestion;

    //     if (reducedEmptyQuestions.length) {
    //         const unskippedQuestions = reducedEmptyQuestions.filter(
    //             req => !skippedQuestionIds.includes(req.Id)
    //         );

    //         newEmptyQuestion = await fetchEmptyQuestion(unskippedQuestions[0].Id);
    //     }

    //     const action = {
    //         payload: {
    //             emptyQuestions: reducedEmptyQuestions,
    //             currentEmptyQuestion: newEmptyQuestion,
    //         },
    //         type: ActionConsts.Profile.SetEmptyQuestions,
    //     };

    //     dispatch(action);
    // },

    // TODO: Refactor Action and Service: UploadPhoto
    UploadProfilePhoto: async (payload: FormData) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        Analytics.event("Uploaded Photo", { sa_upload_type: "local file" });
        const result = await ProfileService.UploadProfilePhoto(payload);

        const profile = JSON.parse(
            JSON.stringify(getState().profile.self)
        ) as ProfileModel.IProfile;

        const newPhoto = {
            Id: result.Id,
            Url: result.Url,
            PhotoName: result.Url,
            State: "Unapproved",
            CreateDate: "2010",
        };

        profile.Photos.splice(1, 0, newPhoto);

        dispatch({
            payload: {
                self: {
                    ...profile,
                },
            },
            type: ActionConsts.Profile.Set,
        });
    },

    RemovePhoto: async (id: string) => async (dispatch: Dispatch, getState: () => IStore) => {
        await ProfileService.RemovePhoto(id);

        const profile = JSON.parse(
            JSON.stringify(getState().profile.self)
        ) as ProfileModel.IProfile;

        profile.Photos.splice(
            profile.Photos.findIndex(item => item.Id === id),
            1
        );

        dispatch({
            payload: {
                self: {
                    ...profile,
                },
            },
            type: ActionConsts.Profile.Set,
        });
    },

    AddPhoto: async (data: PhotoModel.IPhoto) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const currentState = getState().profile.self;

        dispatch({
            payload: {
                self: {
                    ...currentState,
                    Photos: [...currentState!.Photos, data],
                },
            },
            type: ActionConsts.Profile.GetPhotosFromPusher,
        });
    },

    SetPrimaryPhoto: async (id: string) => async () => {
        await ProfileService.SetPrimaryPhoto(id);
    },

    GetPreferences: async () => async (dispatch: Dispatch) => {
        const result = await ProfileService.GetPreferences();

        dispatch({
            payload: { preferences: result.Preferences },
            type: ActionConsts.Profile.GetPreferences,
        });
    },

    SetPreferences: async (
        preferencesName: string,
        preferencesVal: boolean,
        password?: string
    ) => async (dispatch: Dispatch) => {
        const result = await ProfileService.SetPreferences(
            preferencesName,
            preferencesVal,
            password
        );

        if (result.Success) {
            dispatch({
                payload: {
                    [preferencesName]: preferencesVal,
                },
                type: ActionConsts.Profile.SetPreferences,
            });
        }
    },

    GetSubscription: async (userId: string) => async (dispatch: Dispatch) => {
        const result = await ProfileService.GetSubscriptions(userId);

        dispatch({
            payload: { subscription: result },
            type: ActionConsts.Profile.GetSubscription,
        });
    },

    SetAutoSubscription: async (userId: string, value: boolean) => async (dispatch: Dispatch) => {
        let result;
        if (value) result = await ProfileService.SetAutoSubscriptionActive(userId);
        else result = await ProfileService.SetAutoSubscriptionPassive(userId);
        dispatch({
            payload: { autoSubscription: result },
            type: ActionConsts.Profile.SetAutoSubscription,
        });
    },
    SetEditQuestion: (question: ProfileModel.IQuestion) => async (dispatch: Dispatch) => {
        dispatch({
            payload: { editQuestion: { Success: true, Question: question } },
            type: ActionConsts.Profile.SetEditQuestion,
        });
    },

    SetUploadOptions: async (uploadPhotoType: string) => async (dispatch: Dispatch) => {
        dispatch({
            payload: { uploadPhotoType },
            type: ActionConsts.Profile.SetUploadPhotoType,
        });
    },

    UploadPhotoWithUrl: async (payload: PhotoModel.IPhotoUploadWithUrlRequest) => async () => {
        Analytics.event("Uploaded Photo", { sa_upload_type: "facebook" });
        await ProfileService.UploadPhotoWithUrl(payload);
    },

    SetMembershipType: (membershipType: MembershipType) => (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const self = JSON.parse(JSON.stringify(getState().profile.self)) as ProfileModel.IProfile;
        self.MembershipType = membershipType;

        dispatch({
            payload: {
                self,
            },
            type: ActionConsts.Profile.SetMembershipType,
        });
    },

    ShowPhotoModal: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const isNewRegister = getState().common.isNewRegister;
        try {
            const result = await ProfileService.ShowPhotoModal();
            dispatch({
                payload: result.canShow,
                type: ActionConsts.Common.SetPhotoUploadModalState,
            });
            if (result.canShow || isNewRegister) {
                Analytics.event("Visited Photo Upload Page", {
                    sa_source: result.canShow ? "remind modal" : "post_registration",
                });
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}
    },

    SeenProbation: async () => async (dispatch: Dispatch) => {
        const result = await ProfileService.SeenProbation();
        if (result.Success) {
            dispatch({
                payload: { InProbation: false },
                type: ActionConsts.Profile.SeenProbation,
            });
        }
    },
};
