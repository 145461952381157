import * as React from "react";

const SvgDislike = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 47 47" {...props}>
        <path
            d="M3.414.586l19.799 19.798L43.013.586a2 2 0 012.828 2.828l-19.8 19.799 19.8 19.8a2 2 0 11-2.829 2.828l-19.799-19.8-19.799 19.8a2 2 0 11-2.828-2.829l19.798-19.799L.586 3.414A2 2 0 013.414.586z"
            fill="#FFF"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgDislike;
