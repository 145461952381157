import * as React from "react";

const SvgDoubleHeart = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 54 41" {...props}>
        <path
            d="M19.522 24.987c1.21 3.798-1.082 7.806-2.864 10.69-1.845 3.023-2.354 3.727-2.863 4.922-1.018-.562-1.846-.773-4.964-2.04-2.99-1.195-6.936-2.812-8.082-6.61-1.718-5.696 3.628-11.04 8.337-6.962 1.59-6.33 8.718-5.626 10.436 0zM37.39 7.307c7.725-7.97 18.324.34 16.217 10.49-1.469 6.947-8.3 10.625-13.472 13.214-5.427 2.724-6.832 3.337-8.62 4.563-1.149-1.907-2.234-3.065-6.001-8.037-3.64-4.768-8.428-11.103-7.023-18.05C20.598-.8 33.43-3.456 37.39 7.306z"
            fill="currentcolor"
            stroke="#fff"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgDoubleHeart;
