// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Image, Title } from "@Components/Basic";
import { IInfoCard } from "./InfoCard";
import {
    Container,
    ContentContainer,
    ImageContainer,
    ContentDownContainer,
    ContentHeader,
    ContentTitle,
} from "./styled";
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

export const InfoCard: React.FunctionComponent<IInfoCard.IProps> = ({
    imageName,
    headerText,
    contentText,
}) => {
    const screenSize = useScreenSize();

    return (
        <Container>
            <ContentContainer>
                <ImageContainer>
                    <Image src={imageName} alt={headerText} />
                </ImageContainer>
                <ContentDownContainer>
                    <ContentHeader>
                        <Title fontWeight="bold" size={screenSize === "large" ? "large" : "xlarge"}>
                            {headerText}
                        </Title>
                    </ContentHeader>
                    <ContentTitle>{contentText}</ContentTitle>
                </ContentDownContainer>
            </ContentContainer>
        </Container>
    );
};
