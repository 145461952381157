// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, Text } from "./styled";
// #endregion Local Imports

// #region Local Imports
import { friendlyBubbleText } from "@Definitions";
import { IInfoBubble } from "./InfoBubble";
// #endregion Local Imports

const InfoBubble: React.FunctionComponent<IInfoBubble.IProps> = ({
    fullBubble,
    customStyle,
    text,
}): JSX.Element => {
    const { useState, useEffect, useRef } = React;
    const textSplitNumber = 90;
    const isOverflow = text.length > textSplitNumber;

    const infoBubbleRef: React.MutableRefObject<any> = useRef();

    const [preTitle, setPreTitle] = useState("");
    const [isTransform, setIsTransform] = useState(false);

    const expandPreTitle = () => {
        if (isOverflow && fullBubble) {
            if (isTransform === false) {
                setPreTitle(text);
                setIsTransform(true);
            } else {
                setIsTransform(false);
                setPreTitle(friendlyBubbleText(text, textSplitNumber));
            }
        }
    };
    const handleOutsideClick = ({ target }: MouseEvent) => {
        const host = infoBubbleRef.current;

        if (!host.contains(target)) {
            if (isTransform) {
                setIsTransform(false);
                setPreTitle(friendlyBubbleText(preTitle, textSplitNumber));
            }
        }
    };

    useEffect(() => {
        setPreTitle(text);
        if (text.length > textSplitNumber && !isTransform && fullBubble) {
            setPreTitle(friendlyBubbleText(text, textSplitNumber));
        }

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [handleOutsideClick, preTitle, isTransform]);

    return (
        <Container
            ref={infoBubbleRef}
            customStyle={customStyle}
            onClick={() => fullBubble && expandPreTitle()}
            isOverflow={isOverflow}
            isTransform={isTransform}
        >
            <Text isTransform={isTransform} className="info-bubble-text">
                <div>{preTitle}</div>
            </Text>
        </Container>
    );
};

export { InfoBubble };
