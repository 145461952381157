import * as React from "react";

const SvgConversationIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm3 11.544a8 8 0 016.923 12.01l-.163.27.924 2.77a.5.5 0 01-.554.653l-.079-.02-2.771-.923A8 8 0 1121 12.544zm0 1a7 7 0 103.744 12.915l.401-.254 2.274.758-.758-2.274.255-.401A7 7 0 0021 13.544zM14 8a7.992 7.992 0 016.65 3.55 8.956 8.956 0 00-1.152.118 7 7 0 00-11.57 7.816l.156.26.255.401-.758 2.274 2.274-.758.4.255a6.95 6.95 0 002.027.872c.096.375.216.741.359 1.097a7.944 7.944 0 01-2.652-.962l-.269-.163-2.771.924a.5.5 0 01-.654-.545l.021-.088.924-2.771A8 8 0 0114 8z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgConversationIcon;
