// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ILandingLayout } from "./LandingLayout";
import { Container, ContentContainer, HeaderContainer } from "./styled";
import { LandingHeader, Footer, LandingMobileHeader } from "@Components";
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

const LandingLayout: React.FunctionComponent<ILandingLayout.IProps> = ({
    t,
    children,
    hiddenHeader,
}): JSX.Element => {
    const [isSticky, setIsSticky] = React.useState<boolean>(false);
    const size = useScreenSize();

    const handleScroll = () => {
        const positionY = Math.round(window.scrollY);

        if (positionY > 100) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    React.useEffect(() => {
        if (size !== "xsmall") {
            window.addEventListener("scroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [t, size]);

    return (
        <Container>
            <HeaderContainer>
                {size &&
                    !hiddenHeader &&
                    (size === "xsmall" ? (
                        <LandingMobileHeader showLogoText />
                    ) : (
                        <LandingHeader isSticky={isSticky} t={t} />
                    ))}
            </HeaderContainer>

            <ContentContainer isSticky={isSticky}>{children}</ContentContainer>

            <Footer t={t} />
        </Container>
    );
};

export { LandingLayout };
