// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div``;

export const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
`;
