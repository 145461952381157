// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`

    .inner-container {
      border-radius:10px;
        box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.2);
       
    }

    .request-item {
        padding-top: 45px;
    }

    .chat-section {
         border-left:1px solid rgba(0, 0, 0, 0.1);
      
    }
`;
