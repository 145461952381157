// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Locale Imports
import { ActionConsts } from "@Definitions";
import { Analytics, PutMeUpHereService } from "@Services";
import { IStore } from "@Interfaces";
// #endregion Locale Imports

export const PutMeUpHereActions = {
    PutMeUpHere: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        try {
            const response = await PutMeUpHereService.Use();

            if (!response.Success) return;

            const { putMeUpHere } = getState().dashboard;

            // send event analytics
            Analytics.event("Spent Coin", { sa_feature: "featured profile" });

            dispatch({
                payload: {
                    putMeUpHere: {
                        ...putMeUpHere,
                        ContainsYou: true,
                    },
                },
                type: ActionConsts.PutMeUpHere.GetPutMeUpHere,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    },
};
