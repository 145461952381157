// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentCampaignDescription } from "./PaymentCampaignDescription";
// #endregion Interface Imports

export const PaymentCampaignDescription: React.FunctionComponent<IPaymentCampaignDescription.IProps> = ({
    t,
    type,
}) => {
    if (type === "gold") {
        return (
            <>
                <Text weight="bold" size="medium" className="mb-1">
                    {t(`common:payment.gold.includeTax`)}
                </Text>
                <Text weight="regular" size="small">
                    {t(`common:payment.gold.giftCreditDescription`)}
                </Text>
            </>
        );
    }

    return (
        <>
            <Text weight="bold" size="medium" className="mb-1">
                {t(`common:payment.coin.creditDescription`)}
            </Text>
            <Text color="red" weight="bold" size="medium" className="mb-1">
                {t(`common:payment.coin.buyGoldAccount`)}
            </Text>
            <Text size="small" className="mb-3">
                {t(`common:payment.coin.includeTax`)}
            </Text>
        </>
    );
};
