import styled from "styled-components";

export const BannedIcon = styled.div`
    position: relative;
    margin-right: 30px;

    .front {
        color: red;
        position: absolute;
        top: 0;
        left: 0%;
    }

    .back {
        color: gray;
    }
`;

export const Feature = styled.div`
    display: flex;
    justify-content: center;
    max-width: 400px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        max-width: 305px;
    }
`;

Feature.displayName = "Feature";
BannedIcon.displayName = "BannedIcon";
