// #region Global Imports
import React from "react";
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { Text, Button } from "@Components/Basic";
import { Header } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IStep3 } from "./Step3";
// #endregion Interface Imports

export const Step3: React.FunctionComponent<IStep3.IProps> = ({ t, tPrefix }) => {
    const goHome = () => {
        Router.replace("/dashboard");
    };

    return (
        <>
            <Header className="mt-10">
                <Text color="red" weight="bold">
                    {t(`${tPrefix}.header`)}
                </Text>
            </Header>
            <Text
                className="my-10 px-10 text-center"
                size="medium"
                dangerouslySetInnerHTML={{
                    __html: t(`${tPrefix}.text`),
                }}
            />

            <Button styleType="facebook" size="small" onClick={goHome}>
                {t(`${tPrefix}.buttonText`)}
            </Button>
        </>
    );
};
