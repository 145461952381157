import * as React from "react";

const SvgSliderHandleAge = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 21 21" {...props}>
        <path
            d="M2.704.089h16a2 2 0 012 2v10.75a2 2 0 01-.8 1.6l-8 6a2 2 0 01-2.4 0l-8-6a2 2 0 01-.8-1.6V2.089a2 2 0 012-2z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSliderHandleAge;
