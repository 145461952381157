// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { theme } from "@Definitions";
import { Goback, RightArrow } from "@Icons";
import { MasterDetailNavigation, ButtonGroup } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { Component, Presentational } from "./MasterDetailNav";
// #endregion Interface Imports

export const Presentation: Component<Presentational> = ({ t, goBack, go, prev, next }) => (
    <MasterDetailNavigation>
        <Button
            styleType="plain"
            iconPosition="left"
            icon={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Goback
                    width="23px"
                    height="21px"
                    color={theme.colors.azureRadianceDark}
                    className="mb-1"
                />
            }
            onClick={goBack}
            data-cy="back-button"
        >
            {t("common:MasterDetailNav.back")}
        </Button>

        <ButtonGroup>
            <Button
                styleType="plain"
                iconPosition="left"
                icon={<RightArrow width="18px" height="21px" color={theme.colors.gray} />}
                onClick={() => go(prev)}
                disabled={!prev}
                data-cy="prev-button"
            >
                {t("common:MasterDetailNav.prev")}
            </Button>
            <Button
                styleType="plain"
                iconPosition="right"
                icon={<RightArrow width="18px" height="21px" color={theme.colors.gray} />}
                onClick={() => go(next)}
                disabled={!next}
                data-cy="next-button"
            >
                {t("common:MasterDetailNav.next")}
            </Button>
        </ButtonGroup>
    </MasterDetailNavigation>
);
