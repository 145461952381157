export const paymentTypeToAnalyticsString = (method: number) => {
    let paymentType;
    switch (method) {
        case 1:
            paymentType = "credit card";
            break;

        case 2:
            paymentType = "mobil odeme";
            break;

        case 3:
            paymentType = "gift";
            break;

        default:
            break;
    }
    return paymentType;
};
