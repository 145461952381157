// #region Global Imports
import React from "react";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { Gender as GenderType } from "@Interfaces/enums";
import {
    useScreenSize,
    Preset,
    friendlyLocationLong,
    friendlyBubbleText,
    findZodiacbyId,
    theme,
} from "@Definitions";
import { IProfileHeader } from "./ProfileHeader";
import {
    Container,
    PresentationTitleContent,
    MobilePresentationTitle,
    MobileContainer,
    Sticky,
    MobileContent,
    Content,
    MobilePhotoContent,
    PhotoContent,
    PhotoContentItem,
    InteractionsBar,
    InteractionsBarItem,
    StatusBar,
    StatusBarText,
    StatusBarItem,
    StickyPhoto,
    MobilePhoto,
    PhotoAdd,
    Info,
    UserInfo,
    LocationInfo,
    LastLogin,
    MobileLastLogin,
    DesktopContainer,
    DesktopContent,
    TopBar,
    BottomBar,
    customMobileInfoBubbleStyle,
    customAvatarMobileStyle,
    customAvatarStickyStyle,
    customAvatarStyle,
    customPhotoAddStyle,
    customInfoBubbleStyle,
} from "./styled";
import {
    Avatar,
    IconBlockButton,
    InfoBubble,
    ProfileInteractionsButtonContainer,
} from "@Components";
import { RelationshipstatusIcon, JobIcon, LocationIcon, Camera } from "@Icons";
// #endregion Local Imports

const ProfileHeader: React.FunctionComponent<IProfileHeader.IProps> = (props): JSX.Element => {
    const size = useScreenSize();
    const { isSticky, t, profileData, onAvatarClick, profileSelf } = props;

    const {
        Id,
        Photos,
        Photo: { Url, PhotoName },
        IsOnline,
        Username,
        Age,
        Location,
        TownName,
        IsFriend,
        Gender,
        Zodiac,
        ZodiacId,
        MaritalStatus,
        Job,
        LastSeenOn,
        PresentationTitle,
    } = profileData;

    const isSelfProfile = Id !== profileSelf?.Id;

    const isFemale = () => Gender === GenderType.female;
    const thumbUrl = Url + Preset.thumb;

    const renderSticky = (): JSX.Element => (
        <Sticky isFemale={isFemale()}>
            <StickyPhoto>
                <Avatar
                    src={thumbUrl}
                    type={IsOnline ? "online" : "offline"}
                    alt={PhotoName || ""}
                    customStyle={customAvatarStickyStyle}
                    onClick={onAvatarClick}
                />
            </StickyPhoto>
            <Info isSticky={isSticky}>
                <UserInfo isSticky={isSticky}>{`${Username}, ${Age}`}</UserInfo>
                <LocationInfo isSticky={isSticky}>
                    {friendlyLocationLong(Location, TownName)}
                </LocationInfo>
            </Info>
            {!size.includes("small") && isSelfProfile && (
                <InteractionsBar isSticky={isSticky}>
                    <InteractionsBarItem>
                        <ProfileInteractionsButtonContainer
                            IsFriend={IsFriend}
                            Gender={Gender}
                            userId={Id}
                        />
                    </InteractionsBarItem>
                </InteractionsBar>
            )}
        </Sticky>
    );

    const renderStatusBar = (): JSX.Element => (
        <StatusBar>
            {Zodiac && (
                <StatusBarItem colorType="zodiac">
                    {findZodiacbyId(ZodiacId)}
                    <StatusBarText>{Zodiac}</StatusBarText>
                </StatusBarItem>
            )}
            {MaritalStatus && (
                <StatusBarItem colorType="status">
                    <RelationshipstatusIcon height="17" width="20" />
                    <StatusBarText>{MaritalStatus}</StatusBarText>
                </StatusBarItem>
            )}
            {Job && (
                <StatusBarItem colorType="job">
                    <JobIcon height="17" width="26" />
                    <StatusBarText>{Job}</StatusBarText>
                </StatusBarItem>
            )}
        </StatusBar>
    );

    const renderMobile = (): JSX.Element => {
        return (
            <MobileContainer isSticky={isSticky}>
                {isSticky ? (
                    renderSticky()
                ) : (
                    <MobileContent
                        img={isFemale() ? "/images/female-bg.png" : "/images/male-bg.png"}
                    >
                        <MobilePhotoContent>
                            <MobilePhoto>
                                <Avatar
                                    src={thumbUrl}
                                    type={IsOnline ? "online" : "offline"}
                                    alt={PhotoName || ""}
                                    customStyle={customAvatarMobileStyle}
                                    onClick={onAvatarClick}
                                />
                                {Photos?.length - 1 > 0 && (
                                    <PhotoAdd>
                                        <IconBlockButton
                                            startIcon={<Camera width="24px" height="24px" />}
                                            iconClick={() => alert("open photos!")}
                                            customStyle={customPhotoAddStyle}
                                            hoverTextColor="#0076FF"
                                        >
                                            {`+${Photos.length - 1}`}
                                        </IconBlockButton>
                                    </PhotoAdd>
                                )}
                            </MobilePhoto>
                        </MobilePhotoContent>

                        <MobileLastLogin>
                            <span>{`${t("common:Son giriş")}:`}</span>
                            <span>
                                {IsOnline ? t("common:Online") : dayjs(LastSeenOn).fromNow()}
                            </span>
                        </MobileLastLogin>
                        <Info>
                            {PresentationTitle?.length > 0 && (
                                <MobilePresentationTitle>
                                    <InfoBubble
                                        fullBubble
                                        text={friendlyBubbleText(PresentationTitle, 400)}
                                        customStyle={customMobileInfoBubbleStyle}
                                    />
                                </MobilePresentationTitle>
                            )}
                            <UserInfo isSticky={isSticky}>{`${Username}, ${Age}`}</UserInfo>
                            <LocationInfo isSticky={isSticky}>
                                <LocationIcon
                                    width="9px"
                                    height="18px"
                                    color={theme.colors.white}
                                />
                                {friendlyLocationLong(Location, TownName)}
                            </LocationInfo>
                        </Info>
                        {size === "medium" && (
                            <InteractionsBar>
                                {isSelfProfile && (
                                    <InteractionsBarItem>
                                        <ProfileInteractionsButtonContainer
                                            IsFriend={IsFriend}
                                            Gender={Gender}
                                            userId={Id}
                                        />
                                    </InteractionsBarItem>
                                )}
                            </InteractionsBar>
                        )}
                    </MobileContent>
                )}

                {renderStatusBar()}
            </MobileContainer>
        );
    };

    const renderDesktop = (): JSX.Element => {
        return (
            <DesktopContainer>
                <Container
                    img={
                        Gender === GenderType.female
                            ? "/images/female-bg.png"
                            : "/images/male-bg.png"
                    }
                >
                    {isSticky ? (
                        renderSticky()
                    ) : (
                        <DesktopContent>
                            <PhotoContent>
                                <PhotoContentItem>
                                    <Avatar
                                        src={thumbUrl}
                                        type={IsOnline ? "online" : "offline"}
                                        alt={PhotoName || ""}
                                        customStyle={customAvatarStyle}
                                        onClick={onAvatarClick}
                                    />
                                </PhotoContentItem>
                            </PhotoContent>
                            <Content>
                                <TopBar>
                                    {PresentationTitle && (
                                        <PresentationTitleContent>
                                            <InfoBubble
                                                fullBubble
                                                text={friendlyBubbleText(PresentationTitle, 400)}
                                                customStyle={customInfoBubbleStyle}
                                            />
                                        </PresentationTitleContent>
                                    )}
                                    <LastLogin>
                                        <span>{`${t("common:Son giriş")}:`}</span>
                                        <span>
                                            {IsOnline
                                                ? t("common:Online")
                                                : dayjs(LastSeenOn).fromNow()}
                                        </span>
                                    </LastLogin>
                                </TopBar>
                                <BottomBar>
                                    <Info isSticky={isSticky}>
                                        <UserInfo isSticky={isSticky}>
                                            {`${Username}, ${Age}`}
                                        </UserInfo>
                                        <LocationInfo isSticky={isSticky}>
                                            <LocationIcon
                                                width="9px"
                                                height="20px"
                                                color={theme.colors.white}
                                            />
                                            {friendlyLocationLong(Location, TownName)}
                                        </LocationInfo>
                                    </Info>

                                    {isSelfProfile && (
                                        <InteractionsBar>
                                            <InteractionsBarItem>
                                                <ProfileInteractionsButtonContainer
                                                    IsFriend={IsFriend}
                                                    Gender={Gender}
                                                    userId={Id}
                                                />
                                            </InteractionsBarItem>
                                        </InteractionsBar>
                                    )}
                                </BottomBar>
                            </Content>
                        </DesktopContent>
                    )}
                </Container>
                {renderStatusBar()}
            </DesktopContainer>
        );
    };

    const renderProfileHeader = (): JSX.Element => {
        switch (size) {
            case "xsmall":
            case "small":
            case "medium":
                return renderMobile();

            default:
                return renderDesktop();
        }
    };
    return renderProfileHeader();
};

export { ProfileHeader };
