// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

// #region  Interface Imports
import { Gender } from "@Interfaces/enums";
// #endregion  Interface Imports

export const Container = styled.div<{ customStyle?: CSSProp; isModal?: boolean; gender: Gender }>`
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: ${({ gender }) =>
        gender === Gender.female ? `url("/images/female-bg-1.png")` : `url("/images/male-bg.png")`};
    background-repeat: no-repeat;
    background-size: ${({ isModal }) => (isModal ? "cover" : "auto")};
    margin-top: ${({ isModal }) => (isModal ? "0px" : "50px")};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ isModal }) => (isModal ? "0px" : "10px")};
    ${({ customStyle }) => customStyle && customStyle}
`;

export const emptyViewContainerStyle = css`
    width: 255px;
    height: 430px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 210px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 400px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 330px;
        height: 540px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        width: 220px;
        height: 400px;
    }
`;

export const AvatarContainer = styled.div<{ isModal?: boolean }>`
    margin-top: ${({ isModal }) => (isModal ? "50px" : "-42px")};
`;

export const LastLogin = styled.span`
    font-size: 9px;
    margin-top: 15px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ProfileProperties = styled.div<{ customStyle?: CSSProp }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    ${({ customStyle }) => customStyle && customStyle};
`;

export const Username = styled.span<{ customStyle?: CSSProp }>`
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-bottom: 25px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        font-size: 18px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 15px;
    }

    ${({ customStyle }) => customStyle && customStyle};
`;

export const IconInfo = styled.div<{ customStyle?: CSSProp }>`
    display: flex;

    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-bottom: 22px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        margin-bottom: 15px;
    }
`;

export const ZodiacContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 70%;
`;

export const IconInfoSpan = styled.span<{ customStyle?: CSSProp }>`
    margin-left: 8px;
    font-size: 12px;

    :not(:last-of-type)::after {
        content: ", ";
    }
    :last-child::after {
        content: "";
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        font-size: 15px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        font-size: 12px;
    }

    ${({ customStyle }) => customStyle && customStyle};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 187px;
`;

export const customAvatarEmptyView = css`
    width: 215px;
    height: 215px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 180px;
        height: 180px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 269px;
        height: 269px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        width: 180px;
        height: 180px;
    }
`;

export const customEmptyViewButton = css`
    border-radius: 30px !important;
    height: 60px;
    width: 86%;
`;

Container.displayName = "Container";
Username.displayName = "Username";
