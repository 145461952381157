// #region Global Imports
import * as React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// #endregion Global Imports

// #region Local Imports
import { ISmartSlider } from "./SmartSlider";
// #endregion Local Imports

const Container = styled.div<{ customSliderCss?: any }>`
    width: 100%;

    .slick-list {
        height: 616px;
    }

    li.slick-active {
        transform: scale(2);
        transition: transform 0.2s ease;

        button::before {
            color: #00f07b !important;
            transition: color 0.2s ease;
        }
    }
    ${({ customSliderCss }) => customSliderCss && customSliderCss};
`;

const initialSliderConfig: ISmartSlider.ISliderProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const getUniqueKey = (index: number): string => `SmartSlider__SlideItem SlideItem--${index}`;

export const SmartSlider: React.FunctionComponent<ISmartSlider.IProps> = ({
    hide,
    slides,
    custom = {},
    customSliderCss,
}): JSX.Element => {
    if (hide) return <>{slides}</>;

    const sliderConfig = {
        ...initialSliderConfig,
        ...custom,
    };

    return (
        <Container customSliderCss={customSliderCss}>
            {slides.length && (
                <Slider {...sliderConfig}>
                    {slides.map((slide, index) => (
                        <div key={getUniqueKey(index)}>{slide}</div>
                    ))}
                </Slider>
            )}
        </Container>
    );
};
