import * as React from "react";

const SvgChatIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm0 7c5.523 0 10 4.477 10 10s-4.477 10-10 10c-1.845 0-3.573-.5-5.057-1.37l-.293-.18-2.753.918a1 1 0 01-1.295-1.153l.03-.112.918-2.753A9.953 9.953 0 018 18c0-5.523 4.477-10 10-10zm0 1a9 9 0 00-9 9c0 1.615.425 3.165 1.219 4.526l.175.288.255.402-1.068 3.203 3.203-1.068.402.255A8.952 8.952 0 0018 27a9 9 0 000-18z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgChatIcon;
