// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Anchor, HR } from "@Components/Basic";
import { CollapsibleCard } from "@Components";
import { PhoneCircle, MailCircle, FaxCircle } from "@Icons";
import { theme } from  "@Definitions";
import { Container, MidSection, ContactInfoBox } from "./styled";
import { BankAccount } from "./Components/BankAccount";
// #endregion Local Imports

// #region Interface Imports
import { IBankTransfer } from "./BankTransfer";
import { bankData } from "./data";
// #endregion Interface Imports

export const BankTransfer: React.FunctionComponent<IBankTransfer.IProps> = ({ t }) => {
    const tt = (name: string) => {
        return t(`common:payment.paymentTypes.bankTransfer.content.${name}`);
    };

    const [tab, setTab] = React.useState(bankData.currencies[0]);

    return (
        <Container className="p-3">
            <div className="mb-5 d-flex">
                <Text weight="bold" size="xxLarge" className="mr-2">
                    {`${tt("recipient")}:`}
                </Text>
                <Text weight="medium" size="xxLarge">
                    Sosyal Bilgi İletişim Teknolojileri A.Ş.
                </Text>
            </div>
            <div className="d-flex mb-1">
                {bankData.currencies.map((currency: string) => {
                    return (
                        <Anchor
                            key={currency}
                            className={currency}
                            onClick={() => setTab(currency)}
                        >
                            <Text
                                weight="bold"
                                size="medium"
                                color={tab === currency ? "gray" : "lightGray"}
                                className="mr-7"
                            >
                                {tt(`currencies.${currency}`)}
                            </Text>
                        </Anchor>
                    );
                })}
            </div>
            <HR className="mb-4" />
            <MidSection>
                <div className="col-lg-9 col-md-8 col-sm-12 pl-0 bank-accounts">
                    {bankData.banks.map((bank, i) => {
                        return (
                            <BankAccount key={`bank-${i + 1}`} tt={tt} bank={bank} currency={tab} />
                        );
                    })}
                </div>
                <ContactInfoBox className="col-lg-3 col-md-4 col-sm-12 p-2">
                    <Text weight="bold" size="regular" elementType="p">
                        {tt("onComplete")}
                    </Text>
                    <div className="row">
                        <div className="col-md-12 col-6 d-flex align-items-center">
                            <PhoneCircle 
                                width="26px" 
                                height="34px" 
                                color={theme.colors.labelGreen}
                                className="pt-1 mr-1"
                            />
                            <Text size="xSmall" elementType="span">
                                T: 0 212 252 94 49
                            </Text>
                        </div>
                        <div className="col-md-12 col-6 d-flex align-items-center">
                            <FaxCircle 
                                width="26px" 
                                height="34px" 
                                color={theme.colors.labelGreen}
                                className="pt-1 mr-1"
                            />
                            <Text size="xSmall" elementType="span">
                                F: 0 212 252 94 15
                            </Text>
                        </div>
                        <div className="col-md-12 col-6 d-flex align-items-center">
                            <MailCircle 
                                width="26px" 
                                height="34px" 
                                color={theme.colors.labelGreen}
                                className="pt-1 mr-1"
                            />
                            <Text size="xSmall" elementType="span">
                                havale@siberalem.com
                            </Text>
                        </div>
                    </div>
                </ContactInfoBox>
            </MidSection>
            <CollapsibleCard title={tt("howTo.title")} titleColor="darkGray" titleSize="xLarge">
                <div className="p-3">
                    <Text weight="bold" size="xxSmall" className="mb-2">
                        {tt("howTo.methods.textTop")}
                    </Text>
                    <Text weight="bold" size="xSmall">
                        {tt("howTo.methods.title1")}
                    </Text>
                    <Text weight="regular" size="xSmall" className="mb-2">
                        {tt("howTo.methods.description1")}
                    </Text>
                    <Text weight="bold" size="xSmall">
                        {tt("howTo.methods.title2")}
                    </Text>
                    <Text weight="regular" size="xSmall" className="mb-2">
                        {tt("howTo.methods.description2")}
                    </Text>
                    <Text weight="bold" size="xSmall">
                        {tt("howTo.methods.title3")}
                    </Text>
                    <Text weight="regular" size="xSmall" className="mb-2">
                        {tt("howTo.methods.description3")}
                    </Text>
                    <Text weight="bold" size="xxSmall">
                        {tt("howTo.methods.textBottom")}
                    </Text>
                </div>
            </CollapsibleCard>
        </Container>
    );
};
