import styled, { CSSProp } from "styled-components";

export const Container = styled.div`
    position: relative;
    display: inline-block;
`;
export const Content = styled.div<{ hoverColor?: string }>`
    position: relative;
    display: inline-block;
    :hover > span {
        background-color: ${({ hoverColor }) => hoverColor};
    }
`;

export const BadgeContent = styled.span<{
    color: string;
    hoverColor?: string;
    customStyle?: CSSProp;
}>`
    z-index: 1;
    position: absolute;
    top: -1px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 10px;
    color: #ffffff;
    background-color: ${({ color }) => color};
    height: 14px;
    width: 22px;
    border-radius: 2px;

    :hover {
        background-color: ${({ hoverColor }) => hoverColor};
        cursor: pointer;
    }
    ${({ customStyle }) => customStyle && customStyle};
`;

export const DefaultBadgeContent = styled.span<{ color: string; customStyle?: CSSProp }>`
    top: -1px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 10px;
    color: #ffffff;
    background-color: ${({ color }) => color};
    height: 14px;
    width: 22px;
    border-radius: 2px;

    :hover {
        cursor: pointer;
    }
    ${({ customStyle }) => customStyle && customStyle};
`;
