// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ButtonBase, IconText, StartIcon, EndIcon } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IIconBlockButton } from "./IconBlockButton";
// #endregion Interface Imports

const IconBlockButton: React.FunctionComponent<IIconBlockButton.IProps> = (props): JSX.Element => {
    const { iconClick, endIcon, startIcon, children, href, cy } = props;
    return (
        // eslint-disable-next-line no-script-url
        <a href={href || "javascript:void(0)"} onClick={iconClick}>
            <ButtonBase {...props} data-cy={cy}>
                {startIcon && <StartIcon>{startIcon}</StartIcon>}
                <IconText {...props}>{children}</IconText>
                {endIcon && <EndIcon>{endIcon}</EndIcon>}
            </ButtonBase>
        </a>
    );
};

export { IconBlockButton };
