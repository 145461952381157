// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: "Montserrat";
    height: 100%;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        align-items: center;
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    height: 320px;

    img {
        align-self: flex-end;

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            width: 100%;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: unset;
        }
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        height: 292px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 368px;
    }
`;

export const ContentDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 35px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-top: 15px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        align-items: center;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        text-align: center;
    }
`;
export const ContentHeader = styled.div`
    height: 100px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 96%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 80%;
    }
`;

export const ContentTitle = styled.div`
    font-size: 15px;
    /*    width: 95%; */
    margin-top: 10px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-top: 5px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 78%;
        margin-top: 0;
    }
`;
