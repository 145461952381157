import { keyframes } from "styled-components";

export const scaleHeart = keyframes`
  from {
    transform:scale(0.1);

  to {
    transform:scale(1);
  }
`;

export const scaleIcon = keyframes`
  from {
    font-size: 6px;
  }

  to {
    font-size: 46px;
  }
`;

export const scaleGrow = keyframes`
    from {
        transform: scale(1);
        opacity:1;
    }

    to {
        transform: scale(30);
        opacity:0.7
        }
`;

export const scaleShrink = keyframes`
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0);
        opacity: 0.7;
    }
`;

export const fadeOut = keyframes`
    from {
        opacity:1;
    }

    to {
        opacity:0.01
        }
`;
