// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    width: 730px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 610px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 570px;
    }
`;

export const OptionsContainer = styled.div`
    height: 360px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px 5px 10px;
`;

export const DropdownItem = styled.div`
    margin-bottom: 8px;
    width: 343px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 283px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 263px;
    }
`;

export const FilterFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px -14px -10px;
    padding: 10px 14px 0;
    box-shadow: 0px -3px 8px -5px rgba(0, 0, 0, 0.35);
`;

export const ButtonContent = styled.span`
    padding: 4px 7px 2px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.facebook};
`;

export const ButtonSaveContent = styled(ButtonContent)``;

export const ButtonContainerLeft = styled.div`
    display: flex;

    div:first-child {
        margin-right: 8px;
    }
`;

export const TextInputContainer = styled.div`
    width: 343px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 283px;

        input[type="text"] {
            font-size: 12px;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 263px;

        input[type="text"] {
            font-size: 11px;
        }
    }
`;

DropdownItem.displayName = "DropdownItem";
ButtonContent.displayName = "ButtonContent";
