// #region Global Imports
import React from "react";
import Link from "next/link";
import StackGrid from "react-stack-grid";
// #endregion Global Imports

// #region Local Imports
import { Label, Image, RectangleSkeleton } from "@Components/Basic";
import { ApiStatus } from "@Interfaces/enums";
import { BlockButton } from "@Components";
import { Preset } from "@Definitions";
import { Container, Row } from "@Styled";
import { Item, FluidBlock, SolidBlock, customLabel } from "./styled";
import { IRecentPhotos } from "./RecentPhotos";
// #endregion Local Imports

const getUniqueKey = (Id: string, index: number) => `RecentPhotos_Photo_Photo--${Id}--${index}`;

export const Presentation: React.FunctionComponent<IRecentPhotos.IPresentation> = ({
    columnWidth,
    handleLoad,
    patchFetch,
    freshChunk,
    viewModel,
    isLoaded,
    status,
    total,
    page,
    t,
}): JSX.Element => {
    return (
        <Container>
            <Row>
                <SolidBlock>
                    <FluidBlock>
                        <Label
                            customLabel={customLabel}
                            text={t("dashboard:RecentPhotos.caption")}
                        />
                    </FluidBlock>

                    <StackGrid
                        columnWidth={columnWidth}
                        gutterWidth={15}
                        gutterHeight={15}
                        duration={0}
                    >
                        {viewModel.map(({ Id, PhotoUrl, stagger }, index) =>
                            Id ? (
                                <Item key={getUniqueKey(Id, index)} stagger={stagger}>
                                    <Link
                                        href="/profile/[id]?masterAsPath=/dashboard&source=latest photos"
                                        as={`/profile/${Id}`}
                                    >
                                        <a>
                                            <Image
                                                loading="eager"
                                                src={`${PhotoUrl}${Preset.recentPhotos}`}
                                                alt={Id}
                                            />
                                        </a>
                                    </Link>
                                </Item>
                            ) : (
                                <Item key={getUniqueKey("skeleton", index)}>
                                    <RectangleSkeleton
                                        width={columnWidth}
                                        height={200 + (index % 20) * 20}
                                        failed={status === ApiStatus.failed}
                                    />
                                </Item>
                            )
                        )}
                    </StackGrid>

                    <section hidden>
                        {freshChunk.map(({ Id, PhotoUrl }, index) => (
                            <Image
                                key={`load-source-${getUniqueKey(Id, index)}`}
                                src={`${PhotoUrl}${Preset.recentPhotos}`}
                                alt={Id}
                                onLoad={() => handleLoad()}
                                onError={() => handleLoad({ Id, PhotoUrl })}
                                loading="eager"
                            />
                        ))}
                    </section>

                    {isLoaded && page * 20 < total && (
                        // eslint-disable-next-line no-return-await
                        <FluidBlock>
                            <BlockButton onClick={() => patchFetch()}>
                                {t("dashboard:RecentPhotos.more")}
                            </BlockButton>
                        </FluidBlock>
                    )}
                </SolidBlock>
            </Row>
        </Container>
    );
};
