import * as React from "react";

const SvgChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 17 10" {...props}>
        <path
            d="M.94 2.879A1.5 1.5 0 113.06.757l5.304 5.304L13.667.757A1.5 1.5 0 1115.79 2.88L9.425 9.243a1.5 1.5 0 01-2.015.097l-.107-.097L.94 2.879z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgChevronDown;
