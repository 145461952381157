import * as React from "react";

const SvgAquariusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 14 10" {...props}>
        <path
            d="M3.587.07h.735l.951 2.205L7.35.069h.735l.951 2.249 2.12-2.249h.778l1.47 3.46h-1.168l-.908-2.12-1.989 2.12h-.951l-.865-2.12-2.033 2.12h-.864l-.865-2.12-2.033 2.12H.257l3.33-3.46h0zm0 6.01h.735l.951 2.205L7.35 6.08h.735l.951 2.249 2.12-2.249h.778l1.47 3.46h-1.168l-.908-2.12-1.989 2.12h-.951l-.865-2.12-2.033 2.12h-.864L3.76 7.42 1.727 9.54H.257l3.33-3.46h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgAquariusIcon;
