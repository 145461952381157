// #region Global Imports
import styled, { css } from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
`;

export const PhotoFrame = styled.div`
    border: 1px solid ${theme.colors.gray};
    width: 98px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &:hover {
        border: 1px solid ${theme.colors.sliderDarkBlue};
    }

    &:hover  {
        svg {
            color: ${theme.colors.azureRadianceDark};
        }
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        width: 89px;
    }
`;
export const CloseIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${theme.colors.athensGray};
    width: 16px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    z-index: 3;
`;
export const Image = styled.img`
    width: 100%;
    padding: 5px;
    border-radius: 7px;
`;

export const Text = styled.span<{ stateType: string }>`
    position: absolute;
    top: 33%;
    top: ${({ stateType }) => (stateType === "Deleted" ? "42%" : "33%")};
    left: ${({ stateType }) => (stateType === "Deleted" ? "8%" : "0")};
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
`;

export const OverlayText = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.gray};
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.75;
    border-radius: 3px;
`;

export const customSliderCss = css`
    .slick-list {
        height: 115px;
        width: 100%;
        background-color: ${theme.colors.athensGray};
        border-bottom-left-radius: 40px;
        margin-top: 20px;

        @media screen and (max-width: ${theme.maxWidth.medium}) {
            border-bottom-left-radius: 0;
        }

        ::before,
        ::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            width: 75px;
            height: 100%;
        }

        ::before {
            left: 0px;
            background-image: ${theme.gradients.whiteShadowPhotoSliderRgpRight};
            pointer-events: none;
        }

        ::after {
            right: 0;
            background-image: ${theme.gradients.whiteShadowPhotoSliderRgpLeft};
            pointer-events: none;

            @media screen and (max-width: ${theme.maxWidth.xSmall}) {
                width: 64px;
                background-image: ${theme.gradients.whiteShadowPhotoSliderRgpLeft};
                opacity: 0.9;
            }
        }

        .slick-track {
            padding-top: 10px;
            padding-bottom: 10px;

            .slick-slide {
                div {
                    outline: unset;
                }
            }

            &:after {
                width: 64px;
                background-image: ${theme.gradients.whiteShadowPhotoSliderRgpLeft};
                opacity: 0.9;
            }
        }
    }

    .slick-next {
        right: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        font-style: normal;
        font-weight: 400;

        ::before {
            content: "";
            display: block;
            opacity: 1;
            width: 27px;
            height: 27px;
            mask: url("/icons/arrow-point-to-right.svg") no-repeat center / cover;
            background-color: ${theme.colors.gray};
        }
    }
    .slick-prev {
        left: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        font-style: normal;
        font-weight: 400;

        ::before {
            transform: rotate(180deg);
            content: "";
            display: block;
            opacity: 1;
            width: 27px;
            height: 27px;
            mask: url("/icons/arrow-point-to-right.svg") no-repeat center / cover;
            background-color: ${theme.colors.gray};
        }
    }
`;
