// #region Global Imports
import React, { useState, useEffect } from "react";
// #endregion Global Imports

// #region Local Imports
import { Gender as GenderEnum } from "@Interfaces/enums";
import { IGenderSelect } from "./GenderSelect";
import { WomanDefault, ManDefault } from "@Icons";
import { theme } from "@Definitions";
import { Container, GenderContainer, GenderDiv, GenderIcons, IconContainer, ErrorText } from "./styled";
// #endregion Local Imports

export const GenderSelect: React.FunctionComponent<IGenderSelect.IProps> = (props) => {
    const { errorText, t, onChange, isValid, name, _ref, value, cy } = props;
    const [gender, setGender] = useState<GenderEnum>(value);
    useEffect(() => {
        if (gender) {
            onChange(gender);
        }
    }, [gender]);

    return (
        <Container>
            <GenderContainer>
                <input hidden type="text" name={name} ref={_ref} />
                <GenderDiv>
                    {t("common:register.Cinsiyetiniz")}
                    <ErrorText>{isValid === false && errorText}</ErrorText>
                </GenderDiv>
                <GenderIcons>
                    <IconContainer
                        data-cy={cy}
                        onClick={() => {
                            setGender(1);
                        }}
                    >
                        <WomanDefault
                            width="84px" 
                            height="84px" 
                            color={gender === GenderEnum.female ? theme.colors.green : theme.colors.gray}
                        />
                    </IconContainer>
                    <IconContainer
                        onClick={() => {
                            setGender(2);
                        }}
                    >
                        <ManDefault 
                            width="84px" 
                            height="84px" 
                            color={gender === GenderEnum.male ? theme.colors.green : theme.colors.gray}
                            className="ml-6"
                        />
                    </IconContainer>
                </GenderIcons>
            </GenderContainer>
        </Container>
    );
};
