import { TFunction } from "next-i18next";
import { Gender } from "@Interfaces/enums";

export const findLookingForGender = (t: TFunction, lookingForGender: number): string => {
    switch (lookingForGender) {
        case Gender.male:
            return `${t("common:LookingForProfile.Erkek Arkadaş")}`;
        case Gender.female:
            return `${t("common:LookingForProfile.Kadın Arkadaş")}`;
        case Gender.all:
            return `${t("common:LookingForProfile.Erkek & Kadın Arkadaş")}`;
        default:
            return "";
    }
};
