// #region Global Imports
import * as React from "react";
import ContentLoader from "react-content-loader";
// #endregion Global Imports

// #region Local Imports
import { IRectangleSkeleton } from "./RectangleSkeleton";
// #endregion Local Imports

export const RectangleSkeleton: React.FC<IRectangleSkeleton.IProps> = ({
    width,
    height,
    style,
    failed,
}) => (
    <ContentLoader
        speed={failed ? 0 : 2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={failed ? "#aaa" : "#f3f3f3"}
        foregroundColor="#ecebeb"
        style={style}
    >
        <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
);
