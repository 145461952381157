// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const MemberContainer = styled.div`
    font-family: Montserrat;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 15px 0 15px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const MemberTitle = styled.span`
    color: ${({ theme }) => theme.colors.sliderAqua};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    letter-spacing: -0.3px;
`;
export const MemberContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const MailNotificationsContent = styled.div`
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
`;
export const VisibilitySettingsContent = styled.div`
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
`;

export const AccountSettingsContent = styled.div`
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
`;

export const InvoicesContent = styled.div`
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
`;

export const Title = styled(MemberTitle)`
    display: flex;
    flex: 1;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Item = styled.div`
    padding: 10px 0 10px 0;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.lightGray};
`;

export const ItemTitle = styled.div`
    width: 165px;
`;

export const ItemDesc = styled.div`
    width: 300px;
`;
export const ItemText = styled.div`
    svg {
        visibility: hidden;
    }
    :hover {
        > a {
            div {
                color: ${({ theme }) => theme.colors.facebook} !important;
            }
            .red {
                color: ${({ theme }) => theme.colors.red} !important;
            }
        }
        svg {
            visibility: visible;
        }
    }
`;

export const GoldButton = styled.div`
    width: 125px;
`;
export const AutoRenewalButton = styled.div`
    display: flex;
`;
