// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Col, Row } from "@Styled/Grid";
import { Checkbox, Button, Text, Badge, Anchor } from "@Components/Basic";
import {
    Container,
    customSliderCss,
    customContainerStyle,
    customFormBoxStyle,
    SliderItem,
    SideCardContainer,
    SideCardUp,
    SideCardFooter,
    LargeText,
    SideCardText,
    TextCard,
    TextFooter,
    PhotosContainer,
    PhotoFrame,
    Image,
    Footer,
    OverlayText,
    customBoxHeaderStyle,
    badgeCustomStyle,
    AnchorSpan,
    FormBoxTitle,
} from "./styled";
import { SmartSlider, FormBox } from "@Components";
import { FacebookService } from "@Services";
import { ProfileActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { IUploadFacebookPhotos } from "./UploadFacebookPhotos";
import { useScreenSize, getCustomSliderConfig, theme } from "@Definitions";
import { Check, Goback, DoubleHeart } from "@Icons";
import { FacebookModel } from "@Interfaces";
// #endregion Interface Imports

export const UploadFacebookPhotos: React.FunctionComponent<IUploadFacebookPhotos.IProps> = ({
    onCloseClick,
    t,
    categoriesArray,
    albumsArray,
}) => {
    const screenType = useScreenSize();
    const dispatch = useDispatch();

    const customSliderConfig = getCustomSliderConfig(screenType, {
        xsmall: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
        },
        small: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
        },
        medium: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: false,
        },
        large: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: false,
        },
        xlarge: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
        },
        common: {
            hide: false,
        },
    });

    const [activeAlbumId, setActiveAlbumId] = React.useState("");
    const [selectedPhotos, setSelectedPhotos] = React.useState<
        Array<IUploadFacebookPhotos.ISelectedPhotos>
    >([]);
    const [albumCategories, setAlbumCategories] = React.useState<
        Array<FacebookModel.ICategoryItem>
    >(categoriesArray || []);
    const [albumPhotos, setAlbumPhotos] = React.useState<FacebookModel.IAlbumPhotosResponse>(
        albumsArray || {
            data: [],
        }
    );

    const onCategoriesSelect = async (id: string) => {
        setActiveAlbumId(id);
        const photos = await FacebookService.albumPhotos(id);
        setAlbumPhotos(photos);
    };

    React.useEffect(() => {
        (async () => {
            const albums = await FacebookService.albums();
            setAlbumCategories(albums.data);

            const firstAlbumId = albums.data[0].id;
            onCategoriesSelect(firstAlbumId);
        })();
    }, []);

    const sliderItems = albumCategories.map((item: FacebookModel.ICategoryItem) => (
        <SliderItem
            key={`slider-item-${item.id}`}
            onClick={() => onCategoriesSelect(item.id)}
            isActive={item.id === activeAlbumId}
        >
            {item.name}
        </SliderItem>
    ));

    const getSelectedAlbum = (albumId: string) => {
        const selectedAlbum = selectedPhotos.filter(p => p.albumId === albumId);

        if (selectedAlbum.length > 0) {
            return selectedAlbum.filter(p => p.albumId === albumId)[0];
        }
        return { albumId, urls: [] } as IUploadFacebookPhotos.ISelectedPhotos;
    };

    const setSelectedItems = (source: string) => {
        const selectedPhotosAlbum = getSelectedAlbum(activeAlbumId);
        const selectedPhotosAlbumIndex = selectedPhotos.indexOf(selectedPhotosAlbum);

        if (selectedPhotosAlbum.urls.includes(source)) {
            selectedPhotosAlbum.urls = selectedPhotosAlbum.urls.filter(item => item !== source);
        } else {
            selectedPhotosAlbum.urls = [...selectedPhotosAlbum.urls, source];
        }

        const newSelectedPhotos = JSON.parse(JSON.stringify(selectedPhotos));

        if (selectedPhotosAlbumIndex >= 0) {
            newSelectedPhotos[selectedPhotosAlbumIndex] = selectedPhotosAlbum;
        } else {
            newSelectedPhotos.push(selectedPhotosAlbum);
        }

        setSelectedPhotos(newSelectedPhotos);
    };

    const isAlreadySelected = (source: string) => {
        const selectedPhotosAlbum = getSelectedAlbum(activeAlbumId);

        return selectedPhotosAlbum.urls.includes(source);
    };

    const isAllPhotosSelected = () => {
        const selectedPhotosAlbum = getSelectedAlbum(activeAlbumId);
        return selectedPhotosAlbum.urls.length === albumPhotos.data.length;
    };

    const handleSelectAll = () => {
        const selectedPhotosAlbum = getSelectedAlbum(activeAlbumId);
        const selectedPhotosAlbumIndex = selectedPhotos.indexOf(selectedPhotosAlbum);

        if (!isAllPhotosSelected()) {
            selectedPhotosAlbum.urls = albumPhotos?.data.map(
                (item: FacebookModel.IPhotoItem) => item.images[item.images.length - 1].source
            );
        } else {
            selectedPhotosAlbum.urls = [];
        }

        const newSelectedPhotos = JSON.parse(JSON.stringify(selectedPhotos));

        if (selectedPhotosAlbumIndex >= 0) {
            newSelectedPhotos[selectedPhotosAlbumIndex] = selectedPhotosAlbum;
        } else {
            newSelectedPhotos.push(selectedPhotosAlbum);
        }

        setSelectedPhotos(newSelectedPhotos);
    };

    if (albumCategories.length === 0) {
        return <div />;
    }

    const renderFormBoxTitle = (): JSX.Element => (
        <>
            <Badge
                count={selectedPhotos.reduce((acc: number, val: any) => acc + val.urls.length, 0)}
                color={theme.colors.red}
                customStyle={badgeCustomStyle}
            >
                <FormBoxTitle>
                    <Anchor
                        onClick={async () =>
                            dispatch(await ProfileActions.SetUploadOptions("edit"))
                        }
                    >
                        <Goback width="25px" height="24px" color={theme.colors.white} />
                        <AnchorSpan>{t("common:photoUpload.facebookPhotos")}</AnchorSpan>
                    </Anchor>
                </FormBoxTitle>
            </Badge>
            <div />
        </>
    );

    const getSelectedPhotos = async () => {
        const selectedPhotosUrl = selectedPhotos
            .reduce(
                (acc: string, val: IUploadFacebookPhotos.ISelectedPhotos) =>
                    `${acc + val.urls.join()},`,
                ""
            )
            .slice(0, -1);

        await dispatch(await ProfileActions.UploadPhotoWithUrl({ url: selectedPhotosUrl }));
        dispatch(await ProfileActions.SetUploadOptions("edit"));
    };

    return (
        <Container>
            <FormBox
                customChildrenStyle={customFormBoxStyle}
                customContainerStyle={customContainerStyle}
                customBoxHeaderStyle={customBoxHeaderStyle}
                onCloseClick={() => onCloseClick()}
                renderTitle={() => renderFormBoxTitle()}
            >
                <Row className="no-gutters">
                    <Col className="col-lg-9 col-md-12">
                        <SmartSlider
                            {...customSliderConfig}
                            slides={sliderItems}
                            customSliderCss={customSliderCss}
                        />

                        <PhotosContainer>
                            {albumPhotos.data.map(item => {
                                const fullSizePhoto = item.images[item.images.length - 1];
                                return (
                                    <PhotoFrame
                                        key={`photo-item-${item.id}`}
                                        onClick={() => setSelectedItems(fullSizePhoto.source)}
                                    >
                                        <Image
                                            bgSrc={
                                                (
                                                    item.images[3] ||
                                                    item.images[item.images.length - 1]
                                                ).source
                                            }
                                        />
                                        <OverlayText
                                            isActive={isAlreadySelected(fullSizePhoto.source)}
                                        >
                                            <Check
                                                width="50px"
                                                height="46px"
                                                color={theme.colors.white}
                                            />
                                        </OverlayText>
                                    </PhotoFrame>
                                );
                            })}
                        </PhotosContainer>
                        <Footer>
                            <Checkbox
                                name="select-all"
                                checked={isAllPhotosSelected()}
                                onChange={() => handleSelectAll()}
                            >
                                <Text size="small">{t("common:photoUpload.selectAll")}</Text>
                            </Checkbox>
                            <Button
                                size="slim"
                                styleType="facebook"
                                iconPosition="right"
                                onClick={() => getSelectedPhotos()}
                            >
                                {t("common:photoUpload.getSelectedPhotos")}
                            </Button>
                        </Footer>
                    </Col>
                    <Col className="col-lg-3">
                        <SideCardContainer>
                            <SideCardUp>
                                <DoubleHeart
                                    width="66px"
                                    height="58px"
                                    color={theme.colors.white}
                                    className="mb-1"
                                />
                                <SideCardText>
                                    <LargeText>{t("common:photoUpload.quickAdd")}</LargeText>
                                    <TextCard>{t("common:photoUpload.morePhoto")}</TextCard>
                                </SideCardText>
                            </SideCardUp>
                        </SideCardContainer>
                    </Col>
                </Row>
            </FormBox>
        </Container>
    );
};
