// #region Global Imports
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Scroll from "react-scroll";
// #endregion Global Imports

// #region Local Imports
import { ApiStatus } from "@Interfaces/enums";
import { useScreenSize } from "@Definitions";
import { IStore, SearchModel } from "@Interfaces";
import { DashboardActions } from "@Actions";
import { IRecentPhotos } from "./RecentPhotos";
import { Presentation } from "./RecentPhotos.presentation";
// #endregion Local Imports

const calculateColumnWidth = (screenType: string) => {
    switch (screenType) {
        case "xlarge":
            return (1110 - 5 * 15) / 4;

        case "large":
            return (930 - 4 * 15) / 3;

        case "medium":
            return (690 - 4 * 15) / 3;

        default:
            return 0;
    }
};

const filterOutErroredImages = (currentState: IRecentPhotos.IState) => {
    const { viewModel, freshChunk, errored, columCount } = currentState;

    const healthyImages = freshChunk.filter(photoUser => {
        return !errored.find(err => err.Id === photoUser.Id);
    });

    const vm = [...viewModel];

    vm.splice(-columCount, 20, ...healthyImages);

    return vm;
};

const getLast20WithStagger = (
    photos: SearchModel.RecentPhotoUser[],
    { length }: IRecentPhotos.PhotoUserWithStagger[]
): IRecentPhotos.PhotoUserWithStagger[] => {
    return photos.slice(length ? -20 : 0).map((p, index) => ({ ...p, stagger: 20 * index }));
};

export const WrappedComponent: React.FunctionComponent<IRecentPhotos.IProps> = ({
    t,
}): JSX.Element => {
    const { photos, total, page, status } = useSelector(
        ({ dashboard }: IStore) => dashboard.recentPhotos
    );

    const [state, setState] = useState<IRecentPhotos.IState>({
        isWideEnough: false,
        loadedCount: 0,
        viewModel: [],
        freshChunk: [],
        errored: [],
        isLoaded: false,
        columnWidth: 4,
        columCount: 4,
        total: 0,
    });

    const screenType = useScreenSize();

    const dispatch = useDispatch();

    const [isScrollRestored, setScrollRestored] = useState(false);

    const { scrollY: top } = useSelector((store: IStore) => store.common);

    useEffect(() => {
        setTimeout(() => {
            if (state.isLoaded && !isScrollRestored && top) {
                Scroll.animateScroll.scrollTo(top);

                setScrollRestored(true);
            }
        }, 400);
    }, [state.isLoaded, isScrollRestored, top]);

    const getRecentPhotos = useCallback(
        async (requestedPage?: number) => {
            const columCount = screenType.includes("xlarge") ? 4 : 3;

            const skeletons = Array(columCount * 2).fill({});

            setState(currentState => ({
                ...currentState,
                columCount: columCount * 2,
                viewModel: [...currentState.viewModel, ...skeletons],
            }));

            dispatch(await DashboardActions.GetRecentPhotos(requestedPage));
        },
        [dispatch]
    );

    const patchFetch = useCallback(
        async (patch = true) => {
            setState(currentState => ({
                ...currentState,
                viewModel: patch ? currentState.viewModel : [],
                freshChunk: [],
                loadedCount: 0,
                isLoaded: false,
            }));

            await getRecentPhotos(patch ? page + 1 : 0);
        },
        [getRecentPhotos, page]
    );

    // didMount & freshChunkDidFetch
    useEffect(() => {
        // TODO: Add predicates (isFailed) | Issue #684
        if (status === ApiStatus.failed) return;

        // Brand click & reload
        if (!photos.length) {
            patchFetch(false);
        } else {
            setState(currentState => ({
                ...currentState,
                freshChunk: getLast20WithStagger(photos, currentState.viewModel),
            }));
        }
    }, [total, photos, status, patchFetch]);

    // imageDidLoad
    useEffect(() => {
        if (!!state.loadedCount && state.loadedCount === state.freshChunk.length) {
            setState(currentState => ({
                ...currentState,
                viewModel: filterOutErroredImages(currentState),
                isLoaded: true,
            }));
        }
    }, [state.loadedCount, state.freshChunk]);

    // window.resize
    useEffect(() => {
        setState(currentState => ({
            ...currentState,
            isWideEnough: !screenType.includes("small"),
            columnWidth: calculateColumnWidth(screenType),
        }));
    }, [screenType]);

    const handleLoad = (erroredImage?: IRecentPhotos.ErroredImage) => {
        setState(currentState => {
            const { loadedCount, errored } = currentState;

            return {
                ...currentState,
                loadedCount: loadedCount + 1,
                errored: erroredImage ? [...errored, erroredImage] : errored,
            };
        });
    };

    if (!state.isWideEnough) return <div />;

    const presentational = {
        ...state,
        status,
        page,
        t,
        handleLoad,
        patchFetch,
        total,
    };

    return <Presentation {...presentational} />;
};
