// #region Global Imports
import React from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import {
    Zodiac as ZodiacType,
    Gender as GenderType,
    MaritalStatus as MaritalStatusType,
} from "@Interfaces/enums";
import { Text } from "@Components/Basic";
import { Avatar, InfoBubble } from "@Components";
import {
    Container,
    HeaderCard,
    PhotoContent,
    customAvatarStyle,
    AvatarContent,
    AvatarHover,
    AddPhotoButton,
    InfoContent,
    InfoBubbleStyle,
    EditButton,
    UserInfo,
    AgeButton,
    LocationInfo,
    QuickProfileButton,
    AvatarSmall,
    HoverableBubble,
    StatusBar,
    StatusItem,
    HowItLooks,
    ZodiacIconContainer
} from "./styled";
import { Camera, LocationIcon, Heart, JobIcon, Edit } from "@Icons";
import { Preset, friendlyBubbleText, friendlyLocationLong, theme, findZodiacbyId} from "@Definitions";
import { ProfileActions, CommonActions } from "@Actions";
import { Analytics } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { IProfileEditHeader } from "./ProfileEditHeader";
import { ProfileModel } from "@Interfaces";
// #endregion Interface Imports

export const ProfileEditHeader: React.FunctionComponent<IProfileEditHeader.IProps> = ({
    t,
    profileData,
    openModal,
    openInfoModal,
    openQuickFill,
}) => {
    const dispatch = useDispatch();

    const {
        Photo: { Url, PhotoName },
        IsOnline,
        Username,
        Age,
        Id,
        Gender,
        PresentationTitle,
        TownName,
        Location,
        ZodiacId,
        MaritalStatusId,
        Job,
        Sections,
    } = profileData;

    const thumbUrl = Url + Preset.thumb2x;

    const presentationTitle = PresentationTitle
        ? friendlyBubbleText(PresentationTitle, 30)
        : t("common:profileEdit.header.presentationTitle");

    const setEditQuestion = (question: ProfileModel.IQuestion) => {
        dispatch(ProfileActions.SetEditQuestion(question));
        openModal();
    };

    const openPhotoModal = async () => {
        Analytics.event("Visited Photo Upload Page", {
            sa_source: "profile edit",
        });
        dispatch(await CommonActions.SetShowUploadPhoto(true));
    };

    return (
        <Container>
            <HeaderCard
                image={
                    Gender === GenderType.female ? "/images/female-bg.png" : "/images/male-bg.png"
                }
            >
                <PhotoContent onClick={openPhotoModal}>
                    <AvatarContent>
                        <Avatar
                            src={thumbUrl}
                            type={IsOnline ? "online" : "offline"}
                            alt={PhotoName || ""}
                            customStyle={customAvatarStyle}
                        />
                        <AvatarHover>
                            <Text>{t("common:profileEdit.header.addPhoto")}</Text>
                        </AvatarHover>
                        <AddPhotoButton>
                            <Camera width="24px" height="18px" color={theme.colors.black} />
                        </AddPhotoButton>
                    </AvatarContent>
                </PhotoContent>
                <InfoContent>
                    <HoverableBubble onClick={() => setEditQuestion(Sections[0].Questions[0])}>
                        <InfoBubble
                            text={presentationTitle}
                            customStyle={InfoBubbleStyle}
                            fullBubble={false}
                        />
                        <EditButton data-cy="editpresentation-btn">
                            <Edit 
                                width="11" 
                                height="18" 
                                color={theme.colors.white} 
                            />
                        </EditButton>
                    </HoverableBubble>
                    <UserInfo>
                        <Text color="white" weight="bold">
                            {`${friendlyBubbleText(Username, 15)}, `}
                        </Text>
                        <AgeButton onClick={() => openInfoModal("birthday")}>
                            <Text color="white" weight="bold">
                                {Age}
                            </Text>
                        </AgeButton>
                    </UserInfo>
                    <div>
                        <LocationInfo onClick={() => openInfoModal("location")}>
                            <LocationIcon width="13px" height="18px" color={theme.colors.white} />
                            <Text color="white" size="small">
                                {friendlyLocationLong(Location, TownName)}
                            </Text>
                        </LocationInfo>
                    </div>
                </InfoContent>

                <QuickProfileButton onClick={openQuickFill}>
                    <Text weight="bold" size="medium">
                        {t("common:profileEdit.header.quickProfileFill")}
                    </Text>
                    <Avatar
                        src={thumbUrl}
                        type={IsOnline ? "online" : "offline"}
                        alt={PhotoName || ""}
                        customStyle={AvatarSmall}
                        withPercentage
                    />
                </QuickProfileButton>
            </HeaderCard>
            <StatusBar>
                <StatusItem disabled>
                    <ZodiacIconContainer>
                        {findZodiacbyId(ZodiacId)}
                    </ZodiacIconContainer>
                    <Text size="medium">{t(`common:enums.zodiac.${ZodiacType[ZodiacId]}`)}</Text>
                </StatusItem>
                <StatusItem onClick={() => openInfoModal("marital")}>
                    <Heart 
                        width="20px" 
                        height="21px" 
                        color={theme.colors.red} 
                        className="mr-1 pb-1"
                    />
                    <Text size="medium">
                        {t(`common:enums.maritalStatus.${MaritalStatusType[MaritalStatusId]}`)}
                    </Text>
                </StatusItem>
                <StatusItem onClick={() => openInfoModal("job")}>
                    <JobIcon 
                        width="28px" 
                        height="21px" 
                        color={theme.colors.orange} 
                        className="mr-1 pb-1"
                    />
                    <Text size="medium">{Job || t(`common:profileEdit.header.job`)}</Text>
                </StatusItem>
            </StatusBar>
            <HowItLooks>
                <Link href="/profile/[id]" as={`/profile/${Id}`}>
                    <a>
                        <Text size="xSmall" weight="bold">
                            {t(`common:profileEdit.header.howItLooks`)}
                        </Text>
                    </a>
                </Link>
            </HowItLooks>
        </Container>
    );
};
