// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{
    background?: string;
    overflowHidden?: boolean;
    customStyle?: CSSProp;
}>`
    background: ${({ background }) => background || "rgba(255, 255, 255, 0.9)"};
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    ${({ overflowHidden }) =>
        overflowHidden &&
        css`
            overflow: hidden;
        `};

    ${({ customStyle }) => customStyle && customStyle};
`;

export const CardTitle = styled.div<{ titlePosition?: "flex-start" | "center" | "flex-end" }>`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: ${({ theme }) => theme.colors.red};
    letter-spacing: -0.3px;
    padding: 12px 12px 0px 12px;
    display: flex;
    width: 100%;
    justify-content: ${({ titlePosition }) => titlePosition || "center"};
`;

export const CardContent = styled.div`
    width: 100%;
    height: 100%;
`;

CardTitle.displayName = "CardTitle";
