// #region Global Imports
import Router from "next/router";
import { useSelector, useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { CoinTransactionType } from "@Interfaces/enums";
import { IStore } from "@Interfaces";
import { CoinService } from "@Services";
import { PutMeUpHereActions, DashboardActions } from "@Actions";
// #endregion Local Imports

export const findOutIfCoinsAreEnough = async () => {
    try {
        const { IsEnough } = await CoinService.CheckCoinCount({
            type: CoinTransactionType.PutMeUpHere,
        });

        return IsEnough;
    } catch (error) {
        // TODO: Send report in service level
        // eslint-disable-next-line no-alert
        return window.alert("Bir sorun oluştu! En kısa zamanda düzelteceğiz.");
    }
};

export const useUpThere = () => {
    const dispatch = useDispatch();

    const { ContainsYou } = useSelector(({ dashboard }: IStore) => dashboard.putMeUpHere);

    const setPurchaseRevealed = (isPurchaseRevealed: boolean) => {
        dispatch(DashboardActions.SetPurchaseRevealed(isPurchaseRevealed));
    };

    const putUserUpThere = async () => {
        const isEnough = await findOutIfCoinsAreEnough();

        if (isEnough && !ContainsYou) {
            dispatch(await PutMeUpHereActions.PutMeUpHere());
        } else {
            Router.push("/payments/[type]/credit-card", "/payments/coin/credit-card");
        }
    };

    return { ContainsYou, putUserUpThere, setPurchaseRevealed };
};
