// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Button, Text } from "@Components/Basic";
import { Tooltip } from "@Components";
import { Container, Numbers, Image, ButtonWrapper, StyledText } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IBankAccount } from "./BankAccount";
// #endregion Interface Imports

export const BankAccount: React.FunctionComponent<IBankAccount.IProps> = ({
    tt,
    currency,
    bank,
}) => {
    const { iban, account } = bank[currency];
    const ibanJoint = iban.split(" ").join("");

    const copyIban = (): void => {
        const copyText = document.getElementById(ibanJoint);
        const range = document.createRange();
        const selection = window.getSelection();

        if (copyText && selection) {
            selection.removeAllRanges();
            range.selectNodeContents(copyText);
            selection.addRange(range);
            document.execCommand("copy");
            selection.removeAllRanges();
        }
    };

    return (
        <Container>
            <Image>
                <img src={bank.image} alt={bank.name} />
            </Image>
            <Numbers onClick={copyIban}>
                <div className="d-flex flex-column">
                    <div className="m-0 p-0 branch-account">
                        <StyledText size="small" weight="bold" elementType="span" className="mr-1">
                            {`${bank.branch} ${tt("branch")}`}
                        </StyledText>
                        <StyledText size="small" weight="medium" elementType="span">
                            {`${tt("accountNo")}: ${account}`}
                        </StyledText>
                    </div>
                    <StyledText size="small" weight="medium" elementType="span">
                        <span>{`${tt("iban")}: ${iban}`}</span>
                    </StyledText>
                    <span className="hidden" id={ibanJoint}>
                        {ibanJoint}
                    </span>
                </div>
                <ButtonWrapper>
                    <Button styleType="plain" size="slim">
                        {tt("copy")}
                    </Button>
                </ButtonWrapper>
                <Tooltip placement="top">
                    <Text color="facebook">{tt("copied")}</Text>
                </Tooltip>
            </Numbers>
        </Container>
    );
};
