// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Checkbox, Small } from "@Components/Basic";
import { InnerContainer, CheckboxContainer } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ICheckBoxGroup } from "./CheckBoxGroup";
// #endregion Interface Imports

const CheckBoxGroup: React.FunctionComponent<ICheckBoxGroup.IProps> = ({
    customStyle,
    checkboxData,
    setCheckedAnswer,
    checkedAnswer,
}): JSX.Element => {
    return (
        <>
            <InnerContainer>
                {(checkboxData || []).map((val: ICheckBoxGroup.CheckboxData) => (
                    <CheckboxContainer
                        key={`interests-checkbox-item-${val.Id}`}
                        customStyle={customStyle}
                    >
                        <Checkbox
                            name={val.Name}
                            checked={checkedAnswer.includes(val.Id)}
                            disabled={checkedAnswer.length === 7 && !checkedAnswer.includes(val.Id)}
                            onChange={() => {
                                if (!checkedAnswer.includes(val.Id)) {
                                    setCheckedAnswer(checkedAnswerVal => [
                                        ...checkedAnswerVal,
                                        val.Id,
                                    ]);
                                } else {
                                    setCheckedAnswer(checkedAnswerVal =>
                                        checkedAnswerVal.filter(item => item !== val.Id)
                                    );
                                }
                            }}
                        >
                            <Small>{val.Name}</Small>
                        </Checkbox>
                    </CheckboxContainer>
                ))}
            </InnerContainer>
        </>
    );
};

export { CheckBoxGroup };
