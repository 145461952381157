import * as React from "react";

const SvgEmojiIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
        <g filter="none" fill="#8791A1" stroke="#8791A1" strokeWidth={0.3} fillRule="evenodd">
            <path d="M16 8c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 23.523 6 18 10.477 8 16 8zm0 1a9 9 0 100 18 9 9 0 000-18zm-5.127 11.127a7.253 7.253 0 0010.253 0 .75.75 0 011.061 1.06 8.753 8.753 0 01-12.374 0 .75.75 0 111.06-1.06zM12.664 15a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8.093.75a.75.75 0 110 1.5h-3a.75.75 0 010-1.5h3z" />
        </g>
    </svg>
);

export default SvgEmojiIcon;
