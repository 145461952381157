export const SectionData = [
    {
        id: 1,
        title: "temel bilgiler",
    },
    {
        id: 2,
        title: "fiziksel özellikler",
    },
    {
        id: 6,
        title: "genel bilgiler",
    },
    {
        id: 3,
        title: "tarz / stil",
    },
    {
        id: 4,
        title: "yaşam, aile",
    },
    {
        id: 5,
        title: "kültür sanat",
    },
];
