import * as React from "react";

const SvgCreditPlus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 58 56" {...props}>
        <g fill="currentcolor" stroke="#fff" strokeWidth={0.5} fillRule="evenodd">
            <path d="M27.5 5.2c13.255 0 24 10.745 24 24s-10.745 24-24 24-24-10.745-24-24 10.745-24 24-24zm-20 24c0 10.37 7.893 18.898 18 19.901v-6.043c-6.785-.97-12-6.805-12-13.858 0-7.053 5.215-12.888 12-13.858V9.299c-10.107 1.003-18 9.53-18 19.901zM29.501 9.299v6.043a13.956 13.956 0 017.652 3.718l-5.45 4.542a7 7 0 10.747 10.548l4.95 4.95a13.962 13.962 0 01-7.9 3.958l.001 6.043C39.607 48.097 47.5 39.571 47.5 29.2c0-10.37-7.893-18.897-17.999-19.901zM46.086 5.614h3.998l.002-4a1 1 0 012 0v4h4a1 1 0 010 2h-4v4a1 1 0 11-2 0l-.002-4h-3.998a1 1 0 010-2zM1.56 50.64h3v-3a.75.75 0 111.5 0v3h3a.75.75 0 010 1.5h-3v3a.75.75 0 01-1.5 0v-3h-3a.75.75 0 110-1.5z" />
        </g>
    </svg>
);

export default SvgCreditPlus;
