// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { IAlert } from "./Alert";
// #endregion Local Imports

const Alert: React.FunctionComponent<IAlert.IProps> = ({
    type,
    title,
    children,
    cy,
}): JSX.Element => {
    return (
        <div className={`alert alert-${type}`} data-cy={cy}>
            {title && <h4>{title}</h4>}
            {children}
        </div>
    );
};

export { Alert };
