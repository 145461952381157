// #region Global Imports
import * as React from "react";
import styled from "styled-components";
// #endregion Global Imports

const HrElement = styled.hr`
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
`;

export const Hr: React.FunctionComponent<{ className?: string }> = ({ className }) => (
    <HrElement className={`${className || ""}`} />
);
