// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: "Montserrat";
`;

export const Content = styled.div`
    display: flex;
`;

export const LookingContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 11px;
    flex: 1;
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        flex-direction: row;
    }
`;

export const Item = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
export const GenderStatus = styled.div`
    display: flex;
`;
export const TitleText = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
`;
export const Text = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
    font-size: 18px;
`;

export const BadgeContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const LookingGenderContent = styled.div`
    display: flex;
    flex: 1;
    padding: 11px;
`;
export const BadgeItem = styled.div`
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 4px;
    margin: 3px 5px 3px 0;
    background-color: ${({ theme }) => theme.colors.badgeGreen};
`;

export const BadgeText = styled.span`
    padding: 4px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 11px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
`;

export const AboutYouContent = styled.div`
    display: flex;
    margin-left: 8px;
`;

export const TitleTextContainer = styled.div`
    padding-top: 11px;
    padding-left: 11px;
`;

export const SmileIcon = styled.img``;

export const CustomAboutYouInfoMobileBubbleStyle: CSSProp = css`
    margin: 8px;
    background-color: ${({ theme }) => theme.colors.pink};
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    display: flex;
    align-items: center;
    cursor: text;
    max-height: unset;
    :after {
        all: unset;
        content: "";
        position: absolute;
        top: 8px;
        left: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-bottom-color: ${({ theme }) => theme.colors.pink};
        border-top: 0;
        border-left: 0;
        margin-left: -10px;
        margin-top: -20px;
    }
    div {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-style: italic;
        font-size: 12px;
        color: #2a2a2a;
        letter-spacing: 0;
        text-align: left;
        line-height: 15px;
    }
`;

export const CustomAboutYouInfoBubbleStyle: CSSProp = css`
    margin: 16px;
    background-color: ${({ theme }) => theme.colors.pink};
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    display: flex;
    align-items: center;
    cursor: text;
    max-height: unset;
    :after {
        all: unset;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-right-color: ${({ theme }) => theme.colors.pink};
        border-left: 0;
        border-top: 0;
        margin-top: -10px;
        margin-left: -11px;
    }
    div {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-style: italic;
        font-size: 12px;
        color: #2a2a2a;
        letter-spacing: 0;
        text-align: left;
        line-height: 15px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        margin-right: 8px !important;
    }
`;
