// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Button, HR } from "@Components/Basic";
import { SearchIcon, HotornotMatch, ConversationIcon, Ban } from "@Icons";
import { theme } from "@Definitions";
import { BannedIcon, Feature } from "./styled";
import { Header } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IStep1 } from "./Step1";
// #endregion Interface Imports

export const Step1: React.FunctionComponent<IStep1.IProps> = ({ t, tPrefix, goNext }) => {
    return (
        <>
            <Header>
                <Text color="red" weight="bold">
                    {t(`${tPrefix}.header`)}
                </Text>
            </Header>
            <HR />
            <Feature>
                <BannedIcon>
                    <SearchIcon width="72px" height="72px" className="back" />
                    <Ban width="75px" height="72px" className="front" />
                </BannedIcon>
                <Text>{t(`${tPrefix}.disabled1`)}</Text>
            </Feature>
            <HR />
            <Feature>
                <BannedIcon>
                    <HotornotMatch width="74px" height="72px" className="back" />
                    <Ban width="75px" height="72px" color={theme.colors.red} className="front" />
                </BannedIcon>
                <Text>{t(`${tPrefix}.disabled2`)}</Text>
            </Feature>
            <HR />
            <Feature>
                <BannedIcon>
                    <ConversationIcon width="72px" height="72px" className="back" />
                    <Ban width="75px" height="72px" className="front" />
                </BannedIcon>
                <Text>{t(`${tPrefix}.disabled3`)}</Text>
            </Feature>
            <HR />
            <Button onClick={goNext} styleType="facebook" size="small">
                {t(`${tPrefix}.buttonText`)}
            </Button>
        </>
    );
};
