// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { PaymentInfoCard } from "@Components/Payments";
import { Text, Button } from "@Components/Basic";
import { ActionConsts } from "@Definitions";
import { Modal } from "@Components";
import {
    MainContainer,
    InfoCardContainer,
    Row,
    List,
    Buttons,
    TextContainer,
    Col,
    Header,
    closeIcon,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IWinCreditModal } from "./WinCreditModal";
// #endregion Interface Imports

const WinCreditModal: React.FunctionComponent<IWinCreditModal.IProps> = ({ t }): JSX.Element => {
    const dispatch = useDispatch();

    const onDismiss = () => {
        dispatch({
            type: ActionConsts.Common.SetWinCreditModal,
            payload: false,
        });
    };

    return (
        <Modal customModalCloseIcon={closeIcon} isDismissible onDismiss={onDismiss}>
            <MainContainer>
                <Header>{t("common:winCredit.header")}</Header>
                <Row className="row m-0 flex-column flex-lg-row px-2 px-md-4 px-lg-0 mx-lg-0 pb-3 pb-sm-10 pb-lg-0">
                    <Col className="col col-lg col-md-12 pb-10 pb-lg-0 px-0">
                        <List>
                            <ul>
                                <li>
                                    <p>
                                        <strong>{t("common:winCredit.list.loginEveryday")}</strong>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            {t("common:winCredit.list.approvedPhoto.header")}
                                        </strong>
                                        <br />
                                        {t("common:winCredit.list.approvedPhoto.explanation")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            {t("common:winCredit.list.filledProfile.header")}
                                        </strong>
                                        <br />
                                        {t("common:winCredit.list.filledProfile.explanation")}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            {t("common:winCredit.list.becomeGoldMember.header")}
                                        </strong>
                                        <br />
                                        {t("common:winCredit.list.becomeGoldMember.explanation")}
                                    </p>
                                </li>
                            </ul>
                        </List>
                        <Buttons>
                            <Link
                                href="/payments/[type]/credit-card?source=menu link"
                                as="/payments/gold/credit-card"
                            >
                                <a>
                                    <Button onClick={onDismiss} type="button" styleType="facebook">
                                        {t("common:winCredit.membership")}
                                    </Button>
                                </a>
                            </Link>
                            <Link
                                href="/payments/[type]/credit-card?source=menu link"
                                as="/payments/coin/credit-card"
                            >
                                <a>
                                    <Button onClick={onDismiss} type="button" styleType="facebook">
                                        {t("common:winCredit.credit")}
                                    </Button>
                                </a>
                            </Link>
                        </Buttons>
                        <TextContainer className="col-sm-8">
                            <Text weight="medium" size="medium">
                                {t("common:winCredit.explanation")}
                            </Text>
                        </TextContainer>
                    </Col>
                    <InfoCardContainer className="col col-lg-4 col-md-12 px-0">
                        <PaymentInfoCard t={t} type="coin" />
                    </InfoCardContainer>
                </Row>
            </MainContainer>
        </Modal>
    );
};

export { WinCreditModal };
