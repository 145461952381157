// #region Global Imports
import * as React from "react";
import dayjs from "dayjs";
import Link from "next/link";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic";
import { Zodiac as ZodiacType, MaritalStatus as MaritalType } from "@Interfaces/enums";
import { Avatar, InfoBubble } from "@Components";
import {
    Container,
    CustomBubble,
    ContainerLeft,
    ContainerRight,
    StyledIcon,
    AvatarStyle,
    RightTop,
    DateInfo,
    NameAge,
    InfoDiv,
    InfoIcon,
    IconButtons,
    RightBottom,
} from "./styled";
import { ISearchResultUserItem } from "./SearchResultUserItem";
import { Preset } from "@Definitions";
import { ConversationIcon, WinkedIcon, WinkIcon } from "@Icons";
import { InteractionActions, InboxActions } from "@Actions";
// #endregion Local Imports

const SearchResultUserItem: React.FunctionComponent<ISearchResultUserItem.IProps> = ({
    t,
    Id,
    IsOnline,
    Image,
    Age,
    Username,
    LastActivityDate,
    Location,
    StatusMessage,
    ZodiacId,
    MaritalStatusId,
    Job,
}): JSX.Element => {
    const dispatch = useDispatch();
    const [winked, setWinked] = React.useState(false);

    const wink = async (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(await InteractionActions.Wink(Id, "search results"));
        setWinked(true);
    };

    const openChat = async (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(await InboxActions.ShowChatModal(Id, 50));
    };

    const lastSeenDate = new Date(LastActivityDate);

    return (
        <Link href="/profile/[id]?masterAsPath=/search&source=search_result" as={`/profile/${Id}`}>
            <a>
                <Container>
                    <ContainerLeft>
                        <Avatar
                            type={IsOnline ? "online" : "default"}
                            src={`${Image}${Preset.thumb}`}
                            alt="Avatar"
                            customStyle={AvatarStyle}
                        />
                    </ContainerLeft>
                    <ContainerRight>
                        <RightTop>
                            <NameAge>{`${Username}, ${Age}`}</NameAge>
                            {!IsOnline && (
                                <DateInfo>
                                    <span className="span-wrapper">
                                        <span className="date">
                                            {dayjs(LastActivityDate).format("LLL")}
                                        </span>
                                        <span className="desc">
                                            {dayjs(lastSeenDate).fromNow()}
                                        </span>
                                    </span>
                                </DateInfo>
                            )}
                        </RightTop>
                        {StatusMessage && (
                            <div>
                                <InfoBubble
                                    fullBubble={false}
                                    text={StatusMessage}
                                    customStyle={CustomBubble}
                                />
                            </div>
                        )}
                        <RightBottom>
                            <InfoDiv>
                                {Location && (
                                    <div>
                                        <div className="icon-wrapper">
                                            <InfoIcon
                                                className="icon location-icon"
                                                colorType="location"
                                            />
                                        </div>
                                        <span>{Location}</span>
                                    </div>
                                )}
                                {ZodiacId > 0 && (
                                    <div>
                                        <div className="icon-wrapper">
                                            <InfoIcon
                                                className={`icon ${ZodiacType[
                                                    ZodiacId
                                                ].toLocaleLowerCase()}-icon`}
                                                colorType="zodiac"
                                            />
                                        </div>
                                        <span>
                                            {t(`common:enums.zodiac.${ZodiacType[ZodiacId]}`)}
                                        </span>
                                    </div>
                                )}
                                {MaritalStatusId > 0 && (
                                    <div>
                                        <div className="icon-wrapper">
                                            <InfoIcon
                                                className="icon relationshipstatus-icon"
                                                colorType="status"
                                            />
                                        </div>
                                        <span>
                                            {t(
                                                `common:enums.maritalStatus.${MaritalType[MaritalStatusId]}`
                                            )}
                                        </span>
                                    </div>
                                )}
                                {Job && (
                                    <div>
                                        <div className="icon-wrapper">
                                            <InfoIcon className="icon job-icon" colorType="job" />
                                        </div>
                                        <span>{Job}</span>
                                    </div>
                                )}
                            </InfoDiv>
                            <IconButtons>
                                <IconButton
                                    renderIcon={() => (
                                        <StyledIcon>
                                            <ConversationIcon width="40px" height="40px" />
                                        </StyledIcon>
                                    )}
                                    onIconClick={openChat}
                                />
                                <IconButton
                                    renderIcon={() => (
                                        <StyledIcon>
                                            {winked ? (
                                                <WinkedIcon width="40px" height="40px" />
                                            ) : (
                                                <WinkIcon width="40px" height="40px" />
                                            )}
                                        </StyledIcon>
                                    )}
                                    onIconClick={wink}
                                />
                            </IconButtons>
                        </RightBottom>
                    </ContainerRight>
                </Container>
            </a>
        </Link>
    );
};

export { SearchResultUserItem };
