// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    .fs-45 {
        font-size: 45px;
    }

    .fs-21 {
        font-size: 21px;
    }

    a {
        color: ${({ theme }) => theme.colors.facebook};
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const Form = styled.div`
    width: 600px;
    text-align: left;
`;
