// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Container, Col, Row } from "@Styled";
// #endregion Local Imports

export const RoundedBox = styled.div<{ flowOut?: boolean; isLoading: boolean }>`
    position: relative;
    border-radius: 128px;
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: min-height 0.6s ease, filter 0.6s ease;

    ${({ isLoading }) =>
        isLoading && {
            filter: "blur(2px)",
        }}

    ${({ flowOut }) => {
        return flowOut
            ? {
                  "min-height": "140px",
              }
            : {
                  "min-height": "83px",
              };
    }}

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        border-radius: 35px;

        ${({ flowOut }) => {
            return flowOut
                ? {
                      "min-height": "130px",
                  }
                : {
                      "min-height": "69px",
                  };
        }}

        ::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
            display: block;
            width: 16px;
            height: 100%;
            background-image: ${({ theme }) => theme.gradients.whiteShadowRgpRight}
        }
    }

    .slick-slider {
        display: flex;

        ::after {
            content: "";
            flex: 1 0 auto;
            width: 32px;
        }

        ::before {
            @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
                width: 78px;
            }
        }
    }

    .slick-list {
        height: 80px;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            min-width: 256px;
            height: 68px;
            padding: 2px 0 0;
        }

        ::before,
        ::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            width: 32px;
            height: 100%;
        }

        ::before {
            left: 0px;
            background-image: ${({ theme }) => theme.gradients.whiteShadowRgpRight}
        }

        ::after {
            right: 0;
            background-image: ${({ theme }) => theme.gradients.whiteShadowRgpLeft};
            pointer-events: none;

            @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
                width: 64px;
                opacity: 0.9
            }
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 1;
        width: 24px;
        height: 16px;
        font-style: normal;
        font-weight: 400;

        ::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            mask: url('/icons/arrow-point-to-right.svg') no-repeat center / cover;
            background-color: ${({ theme }) => theme.colors.gray};
        }
    }

    .slick-prev {
        left: 0;
        transform: rotateZ(180deg);
        transform-origin: 12px 4px;
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        display: none !important;
    }
`;

export const One = styled.div<{ active?: boolean }>`
    width: 60px;
    height: 60px;
    box-sizing: content-box;
    border-radius: 50px;
    border: 3px solid transparent;
    margin: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    border-color: ${({ active }) => active && "#00f07b"};

    img {
        height: 100%;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 44px;
        height: 44px;
    }
`;

export const PurchaseViewToggle = styled.div`
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 80px;
    cursor: pointer;

    ${One} {
        width: 72px;
        height: 72px;
        margin: 0 2px;
    }

    + [class*="SmartSlider"] .slick-slider {
        .slick-list {
            ::before {
                width: 100px;
                background-image: ${({ theme }) => theme.gradients.whiteShadowRgpRight};
            }
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: 68px;

        ${One} {
            width: 62px;
            height: 62px;
        }
    }
`;

export const SideKick = styled.div`
    position: relative;
    left: -24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    font-size: 10px;
    border: 2px solid white;
    background-image: ${({ theme }) => theme.gradients.greenToBlue};
    border-radius: 50%;

    svg {
        transform: rotateZ(45deg);
    }
`;

export const PurchaseView = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Montserrat;
    background-color: rgba(0, 0, 0, 0.7);

    .close-icon {
        position: absolute;
        right: 32px;
        color: #fff;
        cursor: pointer;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        /* justify-content: flex-end; */
        padding: 0 64px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column;
        justify-content: start;
        height: 130px;

        .close-icon  {
            top: 16px;
            right: 16px;
            font-size: 24px;
        }
    }
`;

export const PurchaseViewText = styled.div`
    max-width: 40%;
    color: #fff;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        max-width: 48%;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        max-width: 43%;
        font-size: 15px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        max-width: 58%;
        font-size: 12px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        max-width: 81%;
        margin: 4px 0 10px;
        line-height: 1.3;
        margin-left:18px;
    }
`;

export const PurchaseViewCTA = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 64px;
    background-color: white;
    color: #0073ff;
    cursor: pointer;

    ::before {
        content: "DEVAM ET";
    }
`;

export const customSliderCss = css`
    .slick-list {
        border-radius: 128px;
    }
`;

export { Container, Row, Col };
