import * as React from "react";

const SvgHotornotGame = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 37 36" {...props}>
        <path
            d="M18.5 0c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm0 1c-9.389 0-17 7.611-17 17s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17zm-2.808 18.598c.445 1.352-.399 2.78-1.055 3.806l-.712 1.121c-.155.25-.248.42-.342.633l-.21-.103c-.285-.129-.627-.24-1.412-.544l-.564-.22c-1.037-.415-2.24-1.004-2.617-2.214-.633-2.028 1.335-3.932 3.069-2.48.586-2.253 3.21-2.003 3.843 0zm6.578-6.296c2.845-2.838 6.747.121 5.972 3.735-.483 2.21-2.54 3.49-4.33 4.395l-2.53 1.23c-.575.282-.902.46-1.274.706l-.11-.172c-.353-.533-.75-.98-1.76-2.258l-.782-.997c-1.25-1.619-2.606-3.654-2.144-5.863.776-3.663 5.5-4.609 6.958-.776z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgHotornotGame;
