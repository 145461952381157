// #region Global Imports
import * as React from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Dropdown, Button } from "@Components/Basic";
import { MaritalStatus } from "@Interfaces/enums";
import { ProfileActions } from "@Actions";
import { FormBox } from "@Components";
// #endregion Local Importss

// #region Interface Imports
import { IMarital } from "./Marital";

// #endregion Interface Imports

export const Marital: React.FunctionComponent<IMarital.IProps> = ({ t, onCloseClick, self }) => {
    const dispatch = useDispatch();

    const { handleSubmit, setValue } = useForm<{
        option: IMarital.OptionType;
    }>();

    const maritalOptions: IMarital.OptionType[] = [
        { value: MaritalStatus.single.toString(), label: t("common:enums.maritalStatus.single") },
        { value: MaritalStatus.married.toString(), label: t("common:enums.maritalStatus.married") },
        { value: MaritalStatus.widow.toString(), label: t("common:enums.maritalStatus.widow") },
        {
            value: MaritalStatus.divorced.toString(),
            label: t("common:enums.maritalStatus.divorced"),
        },
        {
            value: MaritalStatus.separate.toString(),
            label: t("common:enums.maritalStatus.separate"),
        },
    ];

    const [maritalStatusValue, setMaritalStatusValue] = React.useState(
        maritalOptions.find((o) => o.value === self?.MaritalStatusId.toString()) ||
            maritalOptions[0]
    );

    const onSubmit = async () => {
        dispatch(
            await ProfileActions.UpdateProfileInfo({ maritalStatus: maritalStatusValue.value })
        );
    };

    return (
        <FormBox
            title={t(`common:profileEdit.infoModal.marital.title`)}
            onHandleSubmit={handleSubmit(onSubmit)}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">
                <Dropdown
                    t={t}
                    placeholder={t("common:profileEdit.infoModal.marital.title")}
                    options={maritalOptions}
                    name="maritialStatus"
                    value={maritalStatusValue}
                    onChange={(option: IMarital.OptionType) => {
                        setValue("maritialStatus", option);
                        setMaritalStatusValue(option);
                    }}
                />
            </div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
