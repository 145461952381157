// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { IconBlockButton } from "@Components/IconBlockButton";
// #endregion Local Imports

export const Container = styled.div`
    width: 318px;
    height: 126px;
    border-radius: 6px;
    margin-bottom: 14px;
    padding: 2px;
    display: flex;

    :hover {
        background-color: ${({ theme }) => theme.colors.lightBlue};
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 278px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 358px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 328px;
        height: 156px;
    }
`;

export const Left = styled.div`
    width: 122px;
    height: 122px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 150px;
        height: 150px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const MidSpace = styled.div`
    width: 46px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 30px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 37px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 29px;
    }
`;

export const Right = styled.div`
    padding-top: 9px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        justify-content: flex-start;
    }
`;

export const GreenLight = styled.div`
    width: 13px;
    height: 13px;
    background-color: ${({ theme }) => theme.colors.green};
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 4px;
`;

export const Date = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray};
    letter-spacing: -0.15px;
    line-height: 13px;

    .bold {
        font-weight: ${({ theme }) => theme.fontWeights.heavyBold};
    }
`;

export const Username = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: -0.17px;
    line-height: 18px;
`;

export const Description = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: -0.3px;
    line-height: 13px;
    margin-top: 4px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-bottom: 25px;
    }
`;

export const ShowButton = styled(IconBlockButton)`
    padding: 10.5px 17px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.facebook};
    letter-spacing: -0.3px;
    text-align: center;

    span {
        margin: 0;
        padding: 0;
    }
`;

Username.displayName = "Username";
ShowButton.displayName = "ShowButton";
GreenLight.displayName = "GreenLight";
Date.displayName = "Date";
Description.displayName = "Description";
