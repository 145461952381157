import * as React from "react";

const SvgPhoto = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm-4 9a1 1 0 011 1h11a2 2 0 012 2v10a2 2 0 01-2 2H10a2 2 0 01-2-2V13a2 2 0 012-2h1a1 1 0 011-1h2zm12 2H10a1 1 0 00-.993.883L9 13v10a1 1 0 00.883.993L10 24h16a1 1 0 00.993-.883L27 23V13a1 1 0 00-1-1zm-8 2a4 4 0 110 8 4 4 0 010-8zm0 1a3 3 0 100 6 3 3 0 000-6zm7-2a1 1 0 110 2 1 1 0 010-2z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.2}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgPhoto;
