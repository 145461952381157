// #region Global Imports
import React from "react";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
import { InvoicesContent, Title } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IInvoices } from "./Invoices";
// #endregion Interface Imports

export const Invoices: React.FunctionComponent<IInvoices.IProps> = ({ t, invoiceList }) => {
    const renderInvoices = () => {
        return invoiceList.map(value => {
            return (
                // eslint-disable-next-line react/jsx-key
                <tr>
                    <td>
                        <Text color="black" size="medium" weight="medium">
                            {dayjs(value.InvoiceDate).format("L")}
                        </Text>
                    </td>
                    <td>
                        <Text color="black" size="medium" weight="medium">
                            {t("common:settings:invoices.amount", {
                                Amount: value.Amount.toFixed(2),
                            })}
                        </Text>
                    </td>
                    <td>
                        <Text color="black" size="medium" weight="medium">
                            {value.InvoiceNo}
                        </Text>
                    </td>
                    <td>
                        <a
                            href={`api/invoice/GetPdf?id=${value.PDFLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Text color="black" size="large" weight="medium">
                                {t("common:settings.invoices.eInvoice")}
                            </Text>
                        </a>
                    </td>
                </tr>
            );
        });
    };
    return (
        <InvoicesContent>
            <Title>{t("common:settings.invoices.title")}</Title>
            {invoiceList.length > 0 ? (
                <table className="table table-hover table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">
                                <Text color="gray">{t("common:settings.invoices.date")}</Text>
                            </th>
                            <th scope="col">
                                <Text color="gray">{t("common:settings.invoices.price")}</Text>
                            </th>
                            <th scope="col">
                                <Text color="gray">
                                    {t("common:settings.invoices.invoiceNumber")}
                                </Text>
                            </th>
                            <th scope="col">
                                <Text color="gray">{t("common:settings.invoices.invoiceUrl")}</Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{renderInvoices()}</tbody>
                </table>
            ) : (
                <Text className="py-3">{t("common:settings.invoices.emptyText")}</Text>
            )}
        </InvoicesContent>
    );
};
