// #region Global Imports
import styled from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const CustomRadioInput = styled.div`
    position: relative;

    input[type="radio"] {
        display: none;

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        padding-left: 26px;
        margin-bottom: 0;

        /* > span {
            visibility: hidden;
        } */

        &:before,
        &:after {
            position: absolute;
            content: " ";
            border-radius: 50%;
            transition: all 0.3s ease;
            transition-property: transform;
            top: 2px;
        }
        &:before {
            left: 0;
            top: 2px;
            width: 19px;
            height: 19px;
            border: 2px solid ${theme.colors.cadetBlue};
            box-shadow: inset 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
        }
        &:after {
            left: 5px;
            top: 7px;
            width: 10px;
            height: 10px;
            transform: scale(0);
            background-color: ${theme.colors.brightTurquoise};
        }
    }
`;
