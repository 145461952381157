// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

import {
    Container as MainContainer,
    AvatarContainer as MainAvatarContainer,
    ProfileProperties as MainProfileProperties,
    ZodiacContainer as MainZodiacContainer,
    ButtonContainer as MainButtonContainer,
    IconInfo as MainIconInfo,
} from "@Components/ProfileCard/styled";

export const Container = styled(MainContainer)`
    border: 1px solid ${({ theme }) => theme.colors.transparent};
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    background-image: unset;
    border-radius:10px;
`;

export const AvatarContainer = styled(MainAvatarContainer)`
    border-radius: 50%;
    overflow: hidden;


    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 180px;
        height: 180px;
    }
`;

export const ProfileProperties = styled(MainProfileProperties)`
    margin-bottom: 5px;
`;

export const ZodiacContainer = styled(MainZodiacContainer)``;

export const IconInfo = styled(MainIconInfo)`
    margin-bottom: 20px;
`;

export const ButtonContainer = styled(MainButtonContainer)``;


export const customAvatarEmptyView = css`
    width: 269px;
    height: 269px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 180px;
        height: 180px;
    }
`;




Container.displayName = "Container";
