import styled from "styled-components";

export const FooterWrapper = styled.div`
    background: ${({ theme }) => theme.gradients.redSpectrum};
    padding-top: 30px;
    padding-bottom: 25px;
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;

    .brand {
        cursor: pointer;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: row;
        align-items: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column;
    }
`;

export const SocialLogo = styled.i`
    font-size: 20px;
    margin-right: 5px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 27px;
    }
`;

export const StoreGoogleLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 5px;
    img {
        width: 10px;
        padding-left: 1px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 26px;
        height: 26px;

        img {
            width: 14px;
        }
    }
`;

export const StoreAppleLogo = styled(StoreGoogleLogo)`
    img {
        width: 21px;
        padding-top: 1px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        img {
            width: 27px;
            padding-left: 0;
        }
    }
`;

export const SocialLogoContainer = styled.div`
    display: flex;
    margin-left: 55px;

    a,
    a:hover {
        color: #fff;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-left: 108px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-left: 43px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-left: 0;
        margin-top: 85px;
    }
`;

export const MiddleHeader = styled.div`
    font-weight: 700;
    margin-bottom: 10px;
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 85%;
    }
`;

export const MiddleContent = styled.div`
    font-family: "Montserrat";
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: 10px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column;
        margin-top: 20px;
    }
`;

export const MiddleLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        align-items: center;
        text-align: center;
    }
`;

export const MiddleLeftContent = styled.div`
    display: flex;
    flex-direction: column;

    span {
        width: 90%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        span {
            width: 85%;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        text-align: center;

        span {
            width: 90%;
        }
    }
`;

export const MiddleRight = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        text-align: center;
        margin-top: 15px;
        align-items: center;
    }
`;

export const MiddleRightAddres = styled.div`
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }
`;

export const FooterLinkWrapper = styled.div`
    margin-top: 20px;
    border: 1px solid #fff;
    border-left: none;
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FooterLink = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    font-family: "Montserrat";
    padding-top: 15px;

    a:last-child {
        div:after {
            display: none;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 85%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 70%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        width: 90%;
    }
`;

export const LinkItem = styled.div`
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    margin-bottom: 15px;
    text-decoration: none;
    color: #fff;

    &:after {
        pointer-events: none;
        content: "";
        width: 0;
        height: 100%;
        margin-left: 10px;
        border: 1px solid #fff;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        &:nth-child(7n) {
            &:after {
                display: none;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        &:nth-child(7n) {
            &:after {
                display: none;
            }
        }

        &:nth-child(6n) {
            &:after {
                display: none;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        &:nth-child(4n) {
            &:after {
                display: none;
            }
        }

        &:nth-child(6n) {
            &:after {
                display: unset;
            }
        }
        &:nth-child(8n) {
            &:after {
                display: unset;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        &:nth-child(7n) {
            &:after {
                display: none;
            }
        }
    }
`;

export const SiteLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    border-bottom: 1px solid #fff;
    padding-top: 15px;
`;

export const SiteLinks = styled.div`
    display: flex;
    justify-content: center;

    a:last-child {
        div:after {
            display: none;
        }
    }
`;

FooterLink.displayName = "FooterLink";
LogoContainer.displayName = "LogoContainer";
SocialLogoContainer.displayName = "SocialLogoContainer";
MiddleContent.displayName = "MiddleContent";
LinkItem.displayName = "LinkItem";
StoreGoogleLogo.displayName = "StoreGoogleLogo";
StoreAppleLogo.displayName = "StoreAppleLogo";
