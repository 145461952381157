import { ParsedUrlQuery } from "querystring";

export const useType = (query: ParsedUrlQuery): "gold" | "coin" | undefined => {
    const { type } = query;

    if (type === "gold" || type === "coin") {
        return type;
    }

    return undefined;
};
