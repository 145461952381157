// #region Global Imports
// #endregion Global Imports

import { FacebookModel } from "@Interfaces";

export const FacebookService = {
    instance: async () => {
        if (typeof window !== "undefined") {
            const hello = await import("hellojs");

            return hello;
        }

        return null;
    },
    init: async () => {
        if (typeof window !== "undefined") {
            const hello = await FacebookService.instance();

            hello?.init?.({ facebook: "114482365303687" });
        }
    },
    login: async () => {
        const hello = await FacebookService.instance();

        const authResponse = await hello!.default("facebook").login({
            scope: "public_profile,email,user_birthday,user_photos,user_gender",
            redirect_uri: window.location.href,
        });

        return authResponse;
    },
    me: async (): Promise<FacebookModel.IMeResponse> => {
        const hello = await FacebookService.instance();

        const me = await hello!
            .default("facebook")
            .api("me", "get", { fields: "email,gender,birthday" });

        return me;
    },
    albums: async (): Promise<FacebookModel.IAlbumCategories> => {
        const hello = await FacebookService.instance();

        const albums = await hello!.default("facebook").api("me/albums", "get");

        return albums;
    },
    albumPhotos: async (id: string): Promise<FacebookModel.IAlbumPhotosResponse> => {
        const hello = await FacebookService.instance();

        const albums = await hello!
            .default("facebook")
            .api(`${id}/photos`, "get", { fields: "images" });

        return albums;
    },
};
