// #region Global Imports
import styled from "styled-components";
import { Text } from "@Components/Basic/Text";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        flex-direction: column;
        align-items: flex-start;
    }

    .hidden {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
`;

export const Image = styled.div`
    width: 122px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 130px;
    }

    img {
        max-height: 36px;
    }
`;

export const ButtonWrapper = styled.div`
    display: none;
`;

export const StyledText = styled(Text)``;

export const Numbers = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 18px;
    padding-right: 5px;
    padding-left: 12px;
    line-height: 16px;
    cursor: pointer;

    .branch-account {
        height: auto;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        padding: 0;

        .branch-account {
            display: flex;
            flex-direction: column;
            height: unset;
        }
    }

    :hover {
        background-color: ${({ theme }) => theme.colors.lightBlue};

        ${ButtonWrapper} {
            display: block;

            @media screen and (max-width: ${({ theme }) => theme.maxWidth.large}) {
                display: none;
            }
        }

        ${StyledText} {
            color: ${({ theme }) => theme.colors.facebook};
        }
    }
`;

Numbers.displayName = "Numbers";
