// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions/Styled";
import { Anchor } from "@Components/Basic/Anchor";
// #endregion Local Imports

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Switch = styled(Anchor)<{ isOn: boolean }>`
    width: 40px;
    height: 20px;
    border-radius: 12px;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
    padding: 2px;
    background-color: ${({ isOn }) => (isOn ? theme.colors.labelGreen : theme.colors.disabled)};

    .hidden {
        display: none;
    }
`;

export const Knob = styled.div<{ isOn: boolean }>`
    width: 16px;
    height: 16px;
    background-image: linear-gradient(180deg, #ffffff 0%, #d7dde6 100%);
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-left: ${({ isOn }) => (isOn ? "20px" : "0")};
    transition: margin-left 0.3s ease-out;
`;

export const Label = styled.div`
    font-weight: ${theme.fontWeights.medium};
    font-size: 12px;
    color: ${theme.colors.gray};
    margin-right: 8px;

    @media screen and (max-width: ${theme.maxWidth.medium}) {
        font-size: 10px;
    }

    @media screen and (max-width: ${theme.maxWidth.xSmall}) {
        margin-right: 4px;
    }
`;

Label.displayName = "Label";
Switch.displayName = "SwitchButton";
