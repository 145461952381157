// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { SearchModel } from "@Interfaces";
// #endregion Interface Imports

export const SearchService = {
    GetHotOrNotList: async (
        listSize: number,
        excludedUserIds: string
    ): Promise<SearchModel.GetHotOrNotListResponse> => {
        return new Promise((resolve, reject) => {
            Http.Request<SearchModel.GetHotOrNotListResponse>(
                "POST",
                "/search/hotornot",
                undefined,
                {
                    size: listSize,
                    exclude: excludedUserIds,
                }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    Dislike: async (dislikedUserId: number): Promise<SearchModel.DislikeResponse> => {
        let response: SearchModel.DislikeResponse;

        try {
            response = await Http.Request<SearchModel.GetHotOrNotListResponse>(
                "POST",
                "/search/rejectmatch",
                undefined,
                { rejectedId: dislikedUserId }
            );
        } catch (error) {
            response = {
                Success: false,
            };
        }

        return response;
    },

    // * Issue #694
    // TODO: Move services into separate files
    // TODO: Names should be verb
    RecentPhotos: (page: number): Promise<SearchModel.RecentPhotosResponse> => {
        return Http.Request<SearchModel.RecentPhotosResponse>(
            "POST",
            "/search/AllRecentPhotoUploads",
            undefined,
            { page, size: 20 }
        );
    },

    Search: async (payload: SearchModel.SearchRequest): Promise<SearchModel.SearchResponse> => {
        let response: SearchModel.SearchResponse;

        try {
            response = await Http.Request<SearchModel.SearchResponse>(
                "POST",
                "/search/Search",
                undefined,
                payload
            );
        } catch (error) {
            response = {
                Success: false,
                Users: [],
                Total: 0,
            };
        }

        return response;
    },
};
