// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, CardTitle, CardContent } from "./styled";
import { ICard } from "./Card";
// #endregion Local Imports

const Card: React.FunctionComponent<ICard.IProps> = ({
    title,
    children,
    titlePosition,
    className,
    background,
    overflowHidden,
    customStyle,
}): JSX.Element => {
    return (
        <Container
            customStyle={customStyle}
            background={background}
            overflowHidden={overflowHidden}
        >
            {title && <CardTitle titlePosition={titlePosition}>{title}</CardTitle>}
            <CardContent className={className}>{children}</CardContent>
        </Container>
    );
};

export { Card };
