// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { InteractionModel, ProfileVisitorsModel, BanModel } from "@Interfaces";
// #endregion Interface Imports

const initialInteractionResponse: ProfileVisitorsModel.IProfileVisitors = {
    Count: 0,
    Success: false,
    Enabled: false,
    Interactions: [],
};

const initialFetchLikesToMeInteractionResponse: InteractionModel.IFetchLikesToMeResponse = {
    Count: 0,
    Success: false,
    Enabled: false,
    Interactions: [],
};

export const InteractionService = {
    Like: async (likedUserId: number): Promise<InteractionModel.LikeResponse> => {
        let response: InteractionModel.LikeResponse;
        try {
            response = await Http.Request<InteractionModel.LikeResponse>(
                "POST",
                "/interaction/like",
                undefined,
                { with: likedUserId }
            );
        } catch (error) {
            response = {
                Success: false,
                Reason: "",
                IsMatch: false,
            };
        }

        return response;
    },
    onlineFriends: async (
        page: number,
        pageSize: number
    ): Promise<InteractionModel.IOnlineFriends> => {
        const response = await Http.Request<InteractionModel.IOnlineFriends>(
            "POST",
            "/interaction/onlineFriends",
            undefined,
            {
                page,
                pageSize,
            }
        );

        return response;
    },
    friendsV2: async (
        page: number,
        pageSize: number,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<InteractionModel.IFriends> => {
        const response = await Http.Request<InteractionModel.IFriends>(
            "POST",
            "/interaction/friends_v2",
            undefined,
            {
                page,
                pageSize,
            },
            userObj
        );

        return response;
    },
    FetchLikesToMe: async (
        page: number,
        pageSize: number
    ): Promise<InteractionModel.IFetchLikesToMeResponse> => {
        let response: InteractionModel.IFetchLikesToMeResponse;
        try {
            response = await Http.Request<InteractionModel.IFetchLikesToMeResponse>(
                "POST",
                "/interaction/likes",
                undefined,
                {
                    page,
                    pageSize,
                }
            );
        } catch (error) {
            response = initialFetchLikesToMeInteractionResponse;
        }

        return response;
    },
    FetchProfileVisitors: async (
        page: number,
        pageSize: number,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<ProfileVisitorsModel.IProfileVisitors> => {
        let response: ProfileVisitorsModel.IProfileVisitors;

        try {
            response = await Http.Request<ProfileVisitorsModel.IProfileVisitors>(
                "POST",
                "/interaction/views",
                undefined,
                {
                    page,
                    pageSize,
                },
                userObj
            );
        } catch (error) {
            response = initialInteractionResponse;
        }
        return response;
    },
    FetchProfileWinks: async (
        page: number,
        pageSize: number,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<ProfileVisitorsModel.IProfileVisitors> => {
        let response: ProfileVisitorsModel.IProfileVisitors;

        try {
            response = await Http.Request<ProfileVisitorsModel.IProfileVisitors>(
                "POST",
                "/interaction/Winks",
                undefined,
                {
                    page,
                    pageSize,
                },
                userObj
            );
        } catch (error) {
            response = initialInteractionResponse;
        }
        return response;
    },
    FetchProfileSentWinks: async (
        page: number,
        pageSize: number,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<ProfileVisitorsModel.IProfileVisitors> => {
        const response: ProfileVisitorsModel.IProfileVisitors = await Http.Request<
            ProfileVisitorsModel.IProfileVisitors
        >(
            "POST",
            "/interaction/SentWinks",
            undefined,
            {
                page,
                pageSize,
            },
            userObj
        );

        return response;
    },
    DeleteWinks: async (userId: string): Promise<{ Success: boolean }> => {
        let response;
        try {
            if (!userId) throw new Error("(DeleteWinks) userId not found");
            response = await Http.Request<InteractionModel.LikeResponse>(
                "POST",
                "/interaction/DeleteWinks",
                undefined,
                { winks: userId }
            );
        } catch (error) {
            response = {
                Success: false,
            };
        }

        return response;
    },
    Wink: async (userId: string): Promise<{ Success: boolean }> => {
        let response;
        try {
            if (!userId) throw new Error("(Wink) userId not found");
            response = await Http.Request<InteractionModel.LikeResponse>(
                "POST",
                "/interaction/Wink",
                undefined,
                { with: userId }
            );
        } catch (error) {
            response = {
                Success: false,
            };
        }

        return response;
    },

    Bans: async (
        page: number,
        pageSize: number,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<BanModel.IBan> => {
        let response: BanModel.IBan;

        try {
            response = await Http.Request<BanModel.IBan>(
                "POST",
                "/interaction/bans",
                undefined,
                {
                    page,
                    pageSize,
                },
                userObj
            );
        } catch (error) {
            response = initialInteractionResponse;
        }
        return response;
    },

    UnBan: async (
        users: string,
        userObj: {
            userId: string;
            token: string;
        }
    ): Promise<BanModel.IUnban> => {
        let response: BanModel.IUnban;

        try {
            response = await Http.Request<BanModel.IUnban>(
                "POST",
                "/interaction/unban",
                undefined,
                {
                    users,
                },
                userObj
            );
        } catch (error) {
            response = {
                Success: false,
            };
        }

        return response;
    },

    AddFriend: async (userId: string): Promise<{ Success: boolean }> => {
        let response;

        try {
            response = await Http.Request<{ Success: boolean }>(
                "POST",
                "/interaction/friend",
                undefined,
                {
                    with: userId,
                }
            );
        } catch {
            response = {
                Success: false,
            };
        }

        return response;
    },

    RemoveFriend: async (userId: string): Promise<{ Success: boolean }> => {
        let response;

        try {
            response = await Http.Request<{ Success: boolean }>(
                "POST",
                "/interaction/unfriend",
                undefined,
                {
                    users: userId,
                }
            );
        } catch {
            response = {
                Success: false,
            };
        }

        return response;
    },

    OpenVisibility: async (withId: number | string): Promise<{ Success: boolean }> => {
        let response;

        try {
            response = await Http.Request<{ Success: boolean }>(
                "POST",
                "/interaction/avatar",
                undefined,
                {
                    with: withId,
                }
            );
        } catch {
            response = {
                Success: false,
            };
        }

        return response;
    },
};
