// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
import { IProfileVisibilityWarning } from "./ProfileVisibilityWarning";
// #endregion Local Imports

export const ProfileVisibilityWarning = ({ t }: IProfileVisibilityWarning.IProps) => {
    return (
        <div>
            <Text className="my-5" weight="bold">
                {t("common:profileWarnings.invisibleProfile.reason")}
            </Text>
            <ul>
                <li>
                    <Text>{t("common:profileWarnings.invisibleProfile.results.first")}</Text>
                </li>
                <li>
                    <Text>{t("common:profileWarnings.invisibleProfile.results.second")}</Text>
                </li>
            </ul>
            <Text
                className="mb-3"
                dangerouslySetInnerHTML={{
                    __html: t("common:profileWarnings.activateVisibility", {
                        settingsLink: "/settings",
                    }),
                }}
            />
        </div>
    );
};
