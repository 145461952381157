// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic/RectangleSkeleton";
import {
    Container,
    Content,
    TextContent,
    AvatarContent,
    Footer,
    Avatar,
    UserInfo,
    MobileTextContent,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IChatRequestItemSkeleton } from "./ChatRequestItemSkeleton";
// #endregion Interface Imports

export const ChatRequestItemSkeleton: React.FC<IChatRequestItemSkeleton.IProps> = ({
    isMobile,
}) => {
    const renderMobile = () => (
        <Content isMobile={isMobile}>
            <Avatar isMobile={isMobile}>
                <RectangleSkeleton width="88" height="88" />
            </Avatar>
            <MobileTextContent>
                <RectangleSkeleton
                    width="100"
                    height="16"
                    style={{
                        marginBottom: "8px",
                    }}
                />
                <RectangleSkeleton width="400" height="32" />
            </MobileTextContent>
        </Content>
    );

    const renderDesktop = () => (
        <Content isMobile={isMobile}>
            <TextContent>
                <RectangleSkeleton width="308" height="80" />
            </TextContent>
            <AvatarContent>
                <Avatar isMobile={isMobile}>
                    <RectangleSkeleton width="150" height="150" />
                </Avatar>
                <UserInfo>
                    <RectangleSkeleton width="100" height="16" />
                </UserInfo>
            </AvatarContent>
            <Footer>
                <RectangleSkeleton width="56" height="56" />
            </Footer>
        </Content>
    );
    return (
        <Container hasNew={false} isPrioritized={false}>
            {isMobile ? renderMobile() : renderDesktop()}
        </Container>
    );
};
