// #region Global Imports
import * as React from "react";
import dayjs from "dayjs";
import Link from "next/link";
// #endregion Global Imports

import {
    Container,
    AvatarContainer,
    LastLogin,
    ProfileProperties,
    Username,
    IconInfo,
    IconInfoSpan,
    ZodiacContainer,
    ButtonContainer,
    customAvatarEmptyView,
    customEmptyViewButton,
    emptyViewContainerStyle,
} from "./styled";
import { Avatar, ProfileInteractionsButtonContainer } from "@Components";
import { SliderHandleDistance, RelationshipstatusIcon, JobIcon } from "@Icons";
import { Preset, theme, findZodiacbyId } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IEmptyViewProfileCard } from "./EmptyViewProfileCard";

// #endregion Interface Imports

export const EmptyViewProfileCard: React.FunctionComponent<IEmptyViewProfileCard.IProps> = ({
    t,
    profile,
    isModal,
}) => {
    const { ZodiacId } = profile;
    return (
        <Container customStyle={emptyViewContainerStyle} isModal={isModal} gender={profile.Gender}>
            <Link href="/profile/[id]?source=inbox empty view" as={`/profile/${profile.Id}`}>
                <a>
                    <AvatarContainer isModal={isModal}>
                        <Avatar
                            customStyle={customAvatarEmptyView}
                            type={profile.IsOnline ? "online" : "offline"}
                            src={`${profile.Photos[0].Url}${Preset.thumb2x}`}
                            alt="profile-picture"
                        />
                    </AvatarContainer>
                </a>
            </Link>

            <LastLogin>
                {t("common:inbox.profile-card.last-login").toUpperCase()}
                {dayjs(profile.LastActivityDate).fromNow()}
            </LastLogin>

            <ProfileProperties>
                <Username>{`${profile.Username}, ${profile.Age}`}</Username>
                <IconInfo>
                    <SliderHandleDistance width="11px" height="19px" />
                    <IconInfoSpan>{`${profile.Location}`}</IconInfoSpan>
                </IconInfo>
                <ZodiacContainer>
                    <IconInfo>
                        {findZodiacbyId(ZodiacId)}
                        <IconInfoSpan>{profile.Zodiac}</IconInfoSpan>
                    </IconInfo>
                    <IconInfo>
                        <RelationshipstatusIcon
                            width="18px"
                            height="19px"
                            color={theme.colors.white}
                        />
                        <IconInfoSpan>
                            {t(`common:enums.maritalStatus.${profile.MaritalStatus.toLowerCase()}`)}
                        </IconInfoSpan>
                    </IconInfo>
                </ZodiacContainer>
                {profile.Job && (
                    <IconInfo>
                        <JobIcon width="25px" height="19px" color={theme.colors.white} />
                        <IconInfoSpan>{profile.Job}</IconInfoSpan>
                    </IconInfo>
                )}
            </ProfileProperties>

            <ButtonContainer>
                <ProfileInteractionsButtonContainer
                    customStyle={customEmptyViewButton}
                    IsFriend={false}
                    inConversation={false}
                    Gender={profile.Gender}
                    userId={profile.Id}
                    deactiveAddFriend
                />
            </ButtonContainer>
        </Container>
    );
};
