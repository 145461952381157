// #region Global Imports
import styled from "styled-components";
import { Container, Col, Row } from "@Styled";
// #endregion Global Imports

export const InnerContainer = styled.div`
    background-color: yellow;
`;

export const LeftCol = styled.div`
    height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 696px;
    }
`;

export const ColOffer = styled(Col)`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: 20px;
    }
`;

export const RightCol = styled(LeftCol)`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: unset;
    }
`;

export { Container, Row, Col };
