import styled from "styled-components";

const Content = styled.div`
    width: 100%;
    position: relative;
    :hover {
        cursor: pointer;
    }
`;

const DefaultStyledIcon = styled.i`
    color: ${({ theme }) => theme.colors.gray};
    font-size: 36px;
    height: 36px;
    :hover {
        color: ${({ theme }) => theme.colors.green};
    }
`;
export { Content, DefaultStyledIcon };
