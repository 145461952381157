// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { FacebookButton, DiscoverLoginBox } from "@Components";
import { ILandingCard } from "./LandingCard";
import {
    Container,
    LandingContent,
    TextContent,
    Title,
    SubTitle,
    Description,
    SignUpButton,
    MemberLoginButton,
    ButtonsContent,
    Text,
    FbSignupButton,
    DiscoverContent,
} from "./styled";
import { HomeActions } from "@Actions";
// #endregion Local Importss

const LandingCard: React.FunctionComponent<ILandingCard.IProps> = (props): JSX.Element => {
    const { t } = props;
    const dispatch = useDispatch();

    return (
        <Container>
            <LandingContent>
                <TextContent>
                    <Title data-cy="landing-header" dangerouslySetInnerHTML={{
                        __html: t("home:landing.title")
                    }}></Title>
                    <SubTitle data-cy="landing-header">{t("home:landing.subtitle")}</SubTitle>
                    <Description>{t("home:landing.description")}</Description>
                </TextContent>
                <MemberLoginButton>
                    <Button
                        block
                        onClick={() => {
                            dispatch(HomeActions.SetTopComponent({ topComponent: "login" }));
                        }}
                        cy="mobileLogin-btn"
                    >
                        {t("home:buttons.login")}
                    </Button>
                </MemberLoginButton>
                <ButtonsContent>
                    <SignUpButton
                        cy="register-btn"
                        block
                        onClick={() => {
                            dispatch(HomeActions.SetTopComponent({ topComponent: "register" }));
                        }}
                    >
                        {t("home:buttons.signup")}
                    </SignUpButton>
                </ButtonsContent>
            </LandingContent>
            <DiscoverContent>
                <DiscoverLoginBox t={t} />
            </DiscoverContent>
        </Container>
    );
};
export { LandingCard };
