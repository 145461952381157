// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
// #endregion Local Imports

export const Container = styled.div`
    width: 100%;
    height: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.lighterGray};
    padding-top: 18px;
    overflow-y: scroll;
`;

export const Fader = styled.div`
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 110%
    );
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0 36px 0;
`;

export const ShowMoreButton = styled(Button)`
    padding: 10.5px 17px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.facebook};
    letter-spacing: -0.3px;
    text-align: center;
    z-index: 300;

    span {
        margin: 0;
        padding: 0;
    }
`;

ShowMoreButton.displayName = "ShowMoreButton";
