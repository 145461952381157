// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { PutMeUpHereModel } from "@Interfaces";
// #endregion Interface Imports

export const PutMeUpHereService = {
    Get: async (): Promise<PutMeUpHereModel.IFetchResponse> => {
        const response = await Http.Request<PutMeUpHereModel.IFetchResponse>(
            "POST",
            "/PutMeUpHere/get",
            undefined,
            undefined,
            undefined
        );

        return response;
    },

    Use: async (): Promise<PutMeUpHereModel.UseResponse> => {
        const response = await Http.Request<PutMeUpHereModel.UseResponse>(
            "POST",
            "/putmeuphere/use",
            undefined,
            undefined,
            undefined
        );

        return response;
    },
};
