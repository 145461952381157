// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import { Container, Left, MidSpace, Right } from "@Components/HotOrNot/Components/UserItem/styled";
// #endregion Local Imports

export const UserItemSkeleton = () => (
    <Container>
        <Left>
            <RectangleSkeleton width="150" height="150" />
        </Left>
        <MidSpace />
        <Right>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <RectangleSkeleton width="70" height="13" style={{ margin: "2px" }} />
                <RectangleSkeleton width="100" height="18" style={{ margin: "2px" }} />
                <RectangleSkeleton width="100" height="13" style={{ margin: "2px" }} />
            </div>
        </Right>
    </Container>
);
