// #region Global Imports
import * as React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { ProfileActions } from "@Actions";
import { BirthdayPicker, Button, FormBox } from "@Components";
import { Wrapper } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IBirthday } from "./Birthday";

// #endregion Interface Imports

export const Birthday: React.FunctionComponent<IBirthday.IProps> = ({ t, onCloseClick, self }) => {
    const dispatch = useDispatch();

    const BirthdaySchema = Yup.object().shape({
        birthday: Yup.string().required(t("common:profileEdit.infoModal.birthday.errorText")),
    });

    const { handleSubmit, errors, formState, setValue, register } = useForm<{
        birthday: string | Date;
    }>({
        mode: "onBlur",
        validationSchema: BirthdaySchema,
    });

    const onSubmit = async (value: { birthday: string | Date }) => {
        const birthday = dayjs(value.birthday).format("YYYY/MM/DD");
        dispatch(await ProfileActions.UpdateProfileInfo({ birthday }));
    };

    const renderBirtdayPicker = () => {
        return (
            <BirthdayPicker
                isValid={
                    errors.birthday
                        ? !errors.birthday
                        : formState.touched.birthday
                        ? true
                        : undefined
                }
                t={t}
                onDateChange={(value: Date, allIsSelected: boolean) => {
                    if (allIsSelected) {
                        setValue("birthday", value, true);
                    } else {
                        setValue("birthday", "");
                    }
                }}
                errorText={errors.birthday ? errors.birthday.message : ""}
                showError={errors.birthday && true}
                name="birthday"
                value={self!.Birthday ? new Date(self!.Birthday) : undefined}
                _ref={register}
            />
        );
    };
    return (
        <FormBox
            title={t(`common:profileEdit.infoModal.birthday.title`)}
            onHandleSubmit={handleSubmit(onSubmit)}
            onCloseClick={onCloseClick}
        >
            <Wrapper className="mb-sm-2 mb-10 p-sm-10 p-0">{renderBirtdayPicker()}</Wrapper>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
