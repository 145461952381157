// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ HasNew: boolean; isActive?: boolean }>`
    font-family: Montserrat;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme: { colors }, HasNew, isActive }) =>
        isActive ? colors.lightBlue : HasNew ? colors.whiteIce : colors.white};
    border-radius: 4px;
    :hover {
        cursor: pointer;
        background: ${({ theme }) => theme.colors.lightBlue};
    }
    > a {
        display: flex;
        text-decoration: none;
    }
`;

export const Time = styled.div<{ isSeen: boolean }>`
    justify-content: flex-end;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    color: ${({ isSeen, theme }) => (isSeen ? theme.colors.badgeGreen : theme.colors.gray)};
    letter-spacing: -0.15px;
`;

export const New = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    display: flex;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.badgeGreen};
    letter-spacing: -0.15px;
    margin-right: 5px;
`;
export const customAvatarStyle = css`
    width: 48px;
    height: 48px;
`;
export const AvatarContent = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
`;

export const InfoContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 5px 10px;
`;

export const SeenInfo = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Seen = styled.div`
    display: flex;
    padding-right: 10px;
    align-items: center;
`;

export const Name = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0;
`;
export const Message = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 16px;
`;

export const Hr = styled.hr``;
