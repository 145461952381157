// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 13px 0px 12px;
    display: flex;
`;
