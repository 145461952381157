// #region Global Imports
import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
// #endregion Global Imports

// #region Local Imports
import { Gender } from "@Interfaces/enums";
import { SearchActions, SearchFilterActions, MetaDataActions, OfferActions } from "@Actions";
import { ISearchPage, IStore } from "@Interfaces";
// #endregion Local Imports

export const useSearchPage = () => {
    // #region external hooks
    const dispatch = useDispatch();
    const { query } = useRouter();
    // #endregion external hooks

    // #region UI state
    const [locationCardIsVisible, setLocationCardIsVisible] = useState(false);
    const [ageCardIsVisible, setAgeCardIsVisible] = useState(false);
    const [filterModalIsVisible, setFilterModalIsVisible] = useState(false);

    const getUI = () => ({
        location: locationCardIsVisible,
        age: ageCardIsVisible,
        filter: filterModalIsVisible,
    });

    const toggleUI = (handle: "location" | "age" | "filter", update?: boolean) => {
        switch (handle) {
            case "location":
                setLocationCardIsVisible(update !== undefined ? update : !locationCardIsVisible);
                break;
            case "age":
                setAgeCardIsVisible(update !== undefined ? update : !ageCardIsVisible);
                break;
            case "filter":
                setFilterModalIsVisible(update !== undefined ? update : !filterModalIsVisible);
                break;
            default:
                // eslint-disable-next-line no-console
                console.log("Unexpected handle, ", handle);
                break;
        }
    };
    // #endregion UI state

    // #region offer
    useEffect(() => {
        (async () => {
            dispatch(await OfferActions.GetOffer());
        })();
    }, [dispatch]);

    const { offer } = useSelector((store: IStore) => store);
    // #endregion offer

    // #region location metadata
    const { countries, searchCities, towns } = useSelector((state: IStore) => state.metadata);

    const fetchCountries = useCallback(async () => {
        dispatch(await MetaDataActions.FetchCountries());
    }, [dispatch]);

    const fetchCities = async (countryId: number) => {
        dispatch(await MetaDataActions.FetchCities(countryId, true));
    };

    const fetchTowns = async (cityId: number, keyword?: string) => {
        dispatch(await MetaDataActions.FetchTowns(cityId, true, keyword));
    };

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    // #endregion location metadata

    // #region search
    const { searchResults, loading, total } = useSelector((state: IStore) => state.search);

    const debouncer = useRef(0);

    const debounce = (cb: Function, ms: number) => {
        clearTimeout(debouncer.current);
        debouncer.current = setTimeout(cb, ms);
    };

    const makeNewSearch = useCallback(() => {
        debounce(async () => {
            dispatch(await SearchActions.MakeSearch(false));
        }, 400);
    }, [dispatch]);

    const getMore = async () => {
        dispatch(await SearchActions.MakeSearch(true));
    };
    // #endregion search

    // #region filters
    const { filters } = useSelector((state: IStore) => state.searchFilter);

    const modalQuestions = useSelector((state: IStore) => state.metadata.searchFilterQuestions);

    const {
        location,
        distance,
        ageRange,
        gender,
        isOnline,
        isNewUser,
        hasPhoto,
        ...advancedFilters
    } = filters;

    useEffect(() => {
        makeNewSearch();
    }, [filters, makeNewSearch]);

    const setFilters = useCallback(
        (changedFilters: ISearchPage.Filters) => {
            dispatch(SearchFilterActions.SetFilters(changedFilters));
        },
        [dispatch]
    );
    // #endregion filters

    // #region query filters effect
    useEffect(() => {
        const [isOnlineQuery, isNewUserQuery] = [query.isOnline, query.newMember].map(
            q => q === "true"
        );

        setFilters({
            ...(query.isOnline !== undefined ? { isOnline: isOnlineQuery } : {}),
            ...(query.newMember !== undefined ? { isNewUser: isNewUserQuery } : {}),
        });
    }, [query, setFilters]);
    // #endregion query filters effect

    // #region self values
    const self = useSelector((state: IStore) => state.profile.self);

    useEffect(() => {
        if (self) {
            const selfAgeRange: ISearchPage.SelectedAgeRange = {
                from: parseInt(self.LookingForMinAge, 10),
                to: parseInt(self.LookingForMaxAge, 10),
            };

            const selfLocation: ISearchPage.SelectedLocation = {
                countryId: parseInt(self.CountryId, 10),
                countryName: self.CountryName,
                cityId: parseInt(self.CityId, 10),
                cityName: self.CityName,
                townId: parseInt(self.TownId, 10),
                townName: self.TownName,
            };

            const selfGender: Gender = self.LookingForGender;

            setFilters({
                ...(Object.keys(location!).length === 0 ? { location: selfLocation } : {}),
                ...(Object.keys(ageRange!).length === 0 ? { ageRange: selfAgeRange } : {}),
                ...(!gender ? { gender: selfGender } : {}),
            });
        }
    }, [location, gender, ageRange, self, setFilters]);
    // #endregion self values

    return {
        getUI,
        toggleUI,
        offer,
        searchResults,
        loading,
        total,
        getMore,
        countries,
        searchCities,
        towns,
        fetchCities,
        fetchTowns,
        modalQuestions,
        location,
        distance,
        ageRange,
        gender,
        isOnline,
        isNewUser,
        hasPhoto,
        advancedFilters,
        setFilters,
    };
};
