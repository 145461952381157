// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { IconBlockButton } from "@Components/IconBlockButton";
import { Button } from "@Components/Basic/Button";
// #endregion Local Imports

export const Container = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #920819;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

export const RadialGradient = styled.div`
    width: 100%;
    height: 80%;
    background-image: radial-gradient(61% 34%, #ff2a44 -4%, #920819 144%);
    position: absolute;
    top: 0;
    left: 0;
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    width: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    i {
        color: white;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-top: 70px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        padding-top: 24px;
    }
`;

export const ContentTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentBottom = styled.div``;

export const Title = styled.div`
    i {
        font-size: 57px;
        text-shadow: 2px 2px 4px rgba(42, 42, 42, 0.3);

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            font-size: 52px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            font-size: 67px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            font-size: 64px;
        }
    }
`;

export const Icon = styled.div`
    width: 40px;
    height: 36px;
    position: relative;

    svg {
        position: absolute;
        top: 0;
    }
`;

export const Couple = styled.div`
    display: flex;
    justify-content: space-between;
    width: 235px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 350px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 305px;
    }
`;

export const MatchedPerson = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        width: 102px;
        height: 102px;
        border: 2px solid ${({ theme }) => theme.colors.green};
        border-radius: 50%;
        overflow: hidden;

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: 130px;
            height: 130px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    span {
        margin-top: 13px;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: 14px;
        color: #ffffff;
        letter-spacing: -0.23px;

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            margin-top: 10px;
        }
    }
`;

export const CurrentUser = styled.div`
    width: 102px;
    height: 102px;
    border-radius: 50%;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-style: none;

    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 130px;
        height: 130px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-end;
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: 100px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-bottom: 22px;
    }
`;

export const StartChatButton = styled(IconBlockButton)`
    padding: 10.5px 12px;

    span {
        padding: 0;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 16px;
        color: #1877f2;
        letter-spacing: -0.3px;
        text-align: center;
        line-height: 19px;
    }
`;

export const ContinueButton = styled(Button)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.3px;
    text-align: center;
    margin-top: 20px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: 30px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-top: 20px;
    }
`;

Content.displayName = "Content";
ContinueButton.displayName = "ContinueButton";
StartChatButton.displayName = "StartChatButton";
