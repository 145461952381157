// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IOverlay } from "./Overlay";
// #endregion Interface Imports

const Overlay: React.FC<IOverlay.IProps> = ({ onClick }): JSX.Element => (
    <Container onClick={onClick} />
);

export { Overlay };
