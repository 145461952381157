// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Button, Text } from "@Components/Basic";
import { ToggleSwitch } from "@Components";
import { Title, Content, Item, VisibilitySettingsContent } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IVisibilitySettings } from "./VisibilitySettings";
// #endregion Interface Imports

export const VisibilitySettings: React.FunctionComponent<IVisibilitySettings.IProps> = ({
    t,
    profileViewStatus,
    preferences,
    openProfileToggleModal,
}) => {
    const { useState, useEffect } = React;

    const [profileView, setProfileView] = useState(false);

    useEffect(() => {
        setProfileView(preferences?.ProfileViewEnabled || false);
    }, [preferences]);

    const handleViewToggle = () => {
        setProfileView(!profileView);
        profileViewStatus(!profileView);
    };

    const handleProfileToggle = () => {
        openProfileToggleModal();
    };

    return (
        <VisibilitySettingsContent>
            <Title>{t("common:settings.visibility.title")}</Title>
            <Content>
                <div className="d-flex py-2 justify-content-around">
                    <Item className="col-5">
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.visibility.open/close Profile")}
                        </Text>
                    </Item>
                    <Item className="col-2 d-flex align-items-center">
                        <Text color="gray" size="large">
                            {t(
                                `common:settings.visibility.${
                                    preferences?.ProfileOpen ? "open" : "close"
                                }`
                            )}
                        </Text>
                    </Item>
                    <Item className="col-5 d-flex align-items-center">
                        <Button styleType="muted" size="small" onClick={handleProfileToggle}>
                            {t("common:settings.visibility.changeButton")}
                        </Button>
                    </Item>
                </div>
                <Item className="d-flex py-2  no-gutters">
                    <div className="col-5">
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.visibility.profileView")}
                        </Text>
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleViewToggle();
                        }}
                        onKeyPress={() => {
                            handleViewToggle();
                        }}
                    >
                        <ToggleSwitch isOn={profileView} />
                    </div>
                </Item>
            </Content>
        </VisibilitySettingsContent>
    );
};
