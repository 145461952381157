// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions/ActionConsts";
import { Modal } from "@Components";
import { Ban, Exclamation, Communication } from "@Icons";
import { Row, Col } from "@Styled";
import {
    Container,
    Header,
    ContentContainer,
    HintsContainer,
    HintText,
    customModalCloseIcon,
    HiddenPixel,
} from "./styled";
import { Hint } from "./Hint";
// #endregion Local Imports

// #region Interface Imports
import { IWelcomeModal } from "./WelcomeModal";
// #endregion Interface Imports

export const WelcomeModal: React.FC<IWelcomeModal.IProps> = ({ t, username }): JSX.Element => {
    const dispatch = useDispatch();

    const handleOnDismiss = () => {
        dispatch({ type: ActionConsts.Common.SetNewRegister, payload: false });
    };

    return (
        <Modal
            customModalCloseIcon={customModalCloseIcon}
            isDismissible
            onDismiss={handleOnDismiss}
        >
            <Container>
                <Header>{t("common:welcome.welcome")}</Header>
                <ContentContainer>
                    <p>
                        {t("common:welcome.dear")}
                        <strong>{` ${username}`}</strong>
                    </p>

                    <p>{t("common:welcome.paragraph.content")}</p>

                    <p>{t("common:welcome.paragraph.end")}</p>

                    <HintsContainer className="mb-md-3">
                        <HintText>{t("common:welcome.hint")}</HintText>

                        <Row className="d-flex flex-wrap">
                            <Col className="col mb-3">
                                <Hint
                                    renderIcon={() => (
                                        <Ban width="150px" height="71px" />
                                    )}                                    
                                    header={t("common:welcome.ban.header")}
                                    backgroundColor="thousandSonsBlue"
                                >
                                    {t("common:welcome.ban.content")}
                                </Hint>
                            </Col>

                            <Col className="col mb-3">
                                <Hint
                                    renderIcon={() => (
                                        <Exclamation width="150px" height="71px" />
                                    )} 
                                    header={t("common:welcome.complaint.header")}
                                    backgroundColor="tractorBeam"
                                >
                                    {t("common:welcome.complaint.content")}
                                </Hint>
                            </Col>

                            <Col className="col mb-3">
                                <Hint
                                    renderIcon={() => (
                                        <Communication width="150px" height="71px" />
                                    )}  
                                    header={t("common:welcome.communication.header")}
                                    backgroundColor="skyOfMagritte"
                                >
                                    {t("common:welcome.communication.content")}
                                </Hint>
                            </Col>
                        </Row>
                    </HintsContainer>
                    <HiddenPixel />
                </ContentContainer>
            </Container>
        </Modal>
    );
};
