// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { InteractionActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { IIntervalComponent } from "./IntervalComponent";
// #endregion Local Imports

export const IntervalComponent: React.FC<IIntervalComponent.IProps> = () => {
    const { useEffect } = React;
    const dispatch = useDispatch();

    useEffect(() => {
        const friendsInterval = setInterval(async () => {
            dispatch(await InteractionActions.GetOnlineFriends(0, 24));
        }, 60000);
        return () => clearInterval(friendsInterval);
    }, []);

    return <></>;
};
