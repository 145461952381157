// #region Global Imports
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Gender } from "@Interfaces/enums";
import { IOnlineUserButton } from "./OnlineUserButton";
import {
    Container,
    MiddleContent,
    CountContent,
    ButtonText,
    IconFigure,
    IconRightArrow,
    IconGreenLight,
    Count,
} from "./styled";
import { IStore } from "@Interfaces";
import { AccountActions } from "@Actions";
// #endregion Local Imports

export const OnlineUserButton: React.FunctionComponent<IOnlineUserButton.IProps> = props => {
    const onlineUsers = useSelector((state: IStore) => state.home.onlineUserCounts);

    const { gender } = props;
    const { t } = props;
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(AccountActions.LoginAsGuest({ gender }));
    };

    return (
        <Container {...props} onClick={handleClick}>
            <IconFigure
                className={`icon ${gender === Gender.male ? "man-figure" : "woman-figure"}`}
            />
            <MiddleContent>
                <CountContent>
                    <Count>
                        {gender === Gender.male
                            ? onlineUsers.Count !== 0 && onlineUsers.MaleCount
                            : onlineUsers.Count !== 0 && onlineUsers.FemaleCount}
                    </Count>
                    <IconGreenLight />
                </CountContent>
                <ButtonText>{t("common:Kişi Sistemde")}</ButtonText>
            </MiddleContent>
            <IconRightArrow className="icon right-arrow" />
        </Container>
    );
};
