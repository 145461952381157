// #region Global Imports
import styled, { css } from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Amount = styled.span`
    font-weight: ${theme.fontWeights.bold};
    font-size: 30px;
    line-height: 1.1;
    margin-bottom: 4px;
    margin-right: 3px;
`;

export const CreditContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TotalContainer = styled.div`
    display: flex;
`;

export const TotalSpan = styled(Amount)`
    font-size: 15px;
    text-transform: uppercase;
`;

export const Image = styled.img`
    width: 52px;
`;

export const RadioButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
`;

export const Container = styled.div<{ active?: boolean; block?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Montserrat";
    margin-right: 30px;
    background-color: ${({ active }) => (active ? theme.colors.lightBlue : "unset")};
    border: 1px solid ${({ active }) => (active ? theme.colors.lightBlue : theme.colors.lightGray)};
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 0;
    position: relative;
    cursor: pointer;

    ${({ block }) => {
        if (block) {
            return css`
                ${Amount} {
                    margin-right: 8px;
                    font-size: 27px;
                    @media (max-width: ${theme.maxWidth.xSmall}) {
                        font-size: 20px;
                        margin-bottom: 2px;
                    }
                }

                ${CreditContainer} {
                    flex-direction: row;
                }

                ${TotalContainer} {
                    @media (max-width: ${theme.maxWidth.xxSmall}) {
                        flex-direction: column;
                        padding-top: 10px;
                    }
                }
                ${TotalSpan} {
                    @media (max-width: ${theme.maxWidth.small}) {
                        font-size: 14px;
                    }
                }

                ${RadioButtonContainer} {
                    @media (max-width: ${theme.maxWidth.small}) {
                        display: none;
                    }
                }

                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 7px 20px;
            `;
        }
        return "";
    }}

    ${({ active }) => {
        if (active) {
            return css`
                ${Amount} {
                    color: ${theme.colors.azureRadianceDark};
                }
            `;
        }
        return "";
    }}
`;
