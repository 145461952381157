import styled from "styled-components";
import { Image as BasicImage } from "@Components/Basic/Image";
import { IAvatar } from "./Avatar";

const Container = styled.div<Partial<IAvatar.IProps>>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    line-height: 1;
    border-radius: 50%;
    ${({ type, theme: { colors } }) => {
        switch (type) {
            case "online":
                return `border: 3px solid ${colors.badgeGreen};`;
            case "offline":
                return `border: 2px solid ${colors.gray};`;
            default:
                return "";
        }
    }}

    overflow: hidden;
    user-select: none;
    ${({ customStyle }) => customStyle && customStyle};
`;

const Image = styled(BasicImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Percent = styled.div`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    position: absolute;
`;

const Tint = styled.div<{ percent: string }>`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: conic-gradient(
        rgba(74, 74, 74, 0) ${({ percent }) => percent}%,
        rgba(74, 74, 74, 0.3) 0
    );
    position: absolute;
`;

export { Container, Image, Percent, Tint };
