export const today = new Date();

export const days = new Array(31).fill(0).map((x, i) => {
    const day = (i + 1).toString();
    return {
        label: day,
        value: day,
    };
});

/*
const months = [
    { label: "Ocak", value: "0" },
    { label: "Şubat", value: "1" },
    { label: "Mart", value: "2" },
    { label: "Nisan", value: "3" },
    { label: "Mayıs", value: "4" },
    { label: "Haziran", value: "5" },
    { label: "Temmuz", value: "6" },
    { label: "Ağustos", value: "7" },
    { label: "Eylül", value: "8" },
    { label: "Ekim", value: "9" },
    { label: "Kasım", value: "10" },
    { label: "Aralık", value: "11" },
];
*/

export const months = new Array(12).fill(today.getFullYear()).map((x, i) => {
    return {
        label: (i + 1).toString(),
        value: i.toString(),
    };
});

export const years = new Array(80).fill(today.getFullYear() - 17).map((x, i) => {
    const year = (parseInt(x, 10) - i).toString();
    return {
        label: year,
        value: year,
    };
});

export const ageIsValid = (birthdayDate: Date, todayDate: Date) => {
    const yearDiff = todayDate.getFullYear() - birthdayDate.getFullYear();

    if (yearDiff > 18) return true;

    if (yearDiff === 18) {
        const monthIndexDiff = todayDate.getMonth() - birthdayDate.getMonth();

        if (monthIndexDiff > 0) return true;

        if (monthIndexDiff === 0) {
            const dayDiff = todayDate.getDate() - birthdayDate.getDate();

            if (dayDiff >= 0) return true;
        }
    }

    return false;
};

export const getBirthday = (year: string, monthIndex: string, day: string) => {
    return new Date(parseInt(year, 10), parseInt(monthIndex, 10), parseInt(day, 10));
};
