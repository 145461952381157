import * as React from "react";

const SvgRelationshipstatusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 14" {...props}>
        <path
            d="M14.21 1.214c.821.811 1.231 1.796 1.29 2.897 0 .753-.117 1.507-.41 2.202a6.54 6.54 0 01-.762 1.564c-.234.348-.586.753-1.055 1.333l-.234.232c-.586.695-2.226 2.085-4.805 3.997-.117.058-.175.058-.293.058-.117 0-.175 0-.293-.058-2.636-1.97-4.218-3.302-4.804-3.997l-.235-.232c-.468-.58-.761-.985-1.054-1.333-.235-.347-.47-.869-.703-1.564A6.933 6.933 0 01.5 4.11c0-1.159.41-2.086 1.23-2.897C2.55.403 3.547-.003 4.66-.003c1.348 0 2.461.522 3.281 1.623.82-1.043 1.934-1.623 3.282-1.623 1.172 0 2.168.406 2.988 1.217z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgRelationshipstatusIcon;
