// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { CoinModel } from "@Interfaces";
// #endregion Interface Imports

export const CoinService = {
    GetCoinCount: async (): Promise<CoinModel.ICoin> => {
        const response = await Http.Request<CoinModel.ICoin>(
            "POST",
            "/coin/get",
            undefined,
            undefined,
            undefined
        );

        return response;
    },

    GetCoinNotification: async (): Promise<CoinModel.ICoinNotificationResponse> => {
        const response = await Http.Request<CoinModel.ICoinNotificationResponse>(
            "POST",
            "/coin/notification",
            undefined,
            undefined,
            undefined
        );
        return response;
    },

    CheckCoinCount: async (
        payload: CoinModel.CoinCheckPayload
    ): Promise<CoinModel.ICoinCheckResponse> => {
        const response = await Http.Request<CoinModel.ICoinCheckResponse>(
            "POST",
            "/coin/check",
            undefined,
            payload
        );
        return response;
    },
};
