// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

import { theme } from "@Definitions";

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${theme.maxWidth.medium}) {
        flex-direction: row;
    }
`;

export const Primary = styled.div<{ type: "gold" | "coin" }>`
    font-size: ${({ type }) => (type === "gold" ? "30px" : "42px")};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${theme.maxWidth.medium}) {
        font-size: 30px;
        flex-direction: row;
        .text-item {
            margin-right: 8px;
        }
    }

    @media (max-width: ${theme.maxWidth.small}) {
        font-size: 17px;
        .text-item {
            margin-right: 4px;
        }
    }

    @media (max-width: ${theme.maxWidth.xxSmall}) {
        font-size: 13px;
    }
`;

export const Secondary = styled.span`
    width: 100%;
    font-size: 30px;

    @media (max-width: ${theme.maxWidth.small}) {
        font-size: 23px;
    }

    @media (max-width: ${theme.maxWidth.xxSmall}) {
        font-size: 15px;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-container {
        @media (max-width: ${theme.maxWidth.small}) {
            justify-content: center !important;
            padding-left: 35px;
        }
    }

    div {
        width: 100%;
    }
`;

export const Img = styled.img`
    object-fit: contain;
    @media (max-width: ${theme.maxWidth.xxSmall}) {
        height: 85px;
    }
`;
