// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { NotificationModel } from "@Interfaces";
// #endregion Interface Imports

export const NotificationService = {
    GetNotificationCount: async (): Promise<NotificationModel.INotificationCounts> => {
        const response = await Http.Request<NotificationModel.INotificationCounts>(
            "POST",
            "/notification/check",
            undefined,
            undefined,
            undefined
        );

        return response;
    },
};
