// #region Global Imports
import styled, { CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{
    isLarge?: boolean;
    isMobile: boolean;
    customStyle?: CSSProp;
}>`
    opacity: 1;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    display: inline-block;

    ${({ customStyle }) => customStyle && customStyle};

    ${({ isMobile, isLarge }) =>
        isMobile
            ? {
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  opacity: 1,
                  backgroundColor: "#fff",
              }
            : {
                  height: "max-content",
                  minWidth: isLarge ? "600px" : "350px",
                  borderRadius: "30px",
              }}
`;

export const ChildrenContainer = styled.div<{ customStyle?: CSSProp }>`
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    padding: 32px 16px;
    ${({ customStyle }) => customStyle && customStyle};

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        height: 100%;
        overflow-y: auto;
        border-radius: unset;
    }
`;

export const HeaderSlug = styled.div`
    padding: 0 0 32px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.blue};
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        padding: 16px 0;
    }
`;

export const BoxHeader = styled.div<{ customStyle?: CSSProp }>`
    display: grid;
    grid-template-columns: 80px auto 80px;
    place-items: center;
    width: 100%;
    height: 50px;
    background: ${({ theme }) => theme.gradients.greenToBlue};
    border-radius: 30px 30px 0 0;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: -0.38px;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    ${({ customStyle }) => customStyle && customStyle};

    svg {
        :hover {
            text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
            filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.3));
        }
    
        :active {
            filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.1));
        }
    }
`;

Container.displayName = "FormBox";
