// #region Global imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "next-i18next";
// #endregion Global imports

// #region Local imports
import { InteractionActions } from "@Actions";
import { IStore, IProfileInteractionsContainer } from "@Interfaces";
// #endregion Local imports

export const useProfileWinkPage = (t: TFunction) => {
    const dispatch = useDispatch();

    const { NewWinkCount } = useSelector((state: IStore) => state.dashboard.notificationCounts);
    const winksData = useSelector((state: IStore) => state.interaction.interactions.winks);
    const sentWinksData = useSelector((state: IStore) => state.interaction.interactions.sentWinks);
    const showMore = useSelector((state: IStore) => state.interaction.showMore);
    const loading = useSelector((state: IStore) => state.interaction.interactions.winks.loading);

    const [winksPage, setWinksPage] = React.useState(0);
    const [moreButtonDisabled, setMoreButtonDisabled] = React.useState(false);
    const [winksType, setWinksType] = React.useState<
        IProfileInteractionsContainer.InteractionsTitle["actionType"]
    >("ProfileWinks");

    const moreButtonClick = async () => {
        setMoreButtonDisabled(true);
        if (winksType) await dispatch(await InteractionActions[winksType](winksPage + 1, 24));
        setWinksPage(winksPage + 1);
        setMoreButtonDisabled(false);
    };

    const handleInteractions = async (
        type: IProfileInteractionsContainer.InteractionsTitle["actionType"]
    ) => {
        setWinksType(type);
        if (type) await dispatch(await InteractionActions[type](0, 24));
        setWinksPage(0);
    };

    const titleObj: IProfileInteractionsContainer.InteractionsTitle[] = [
        {
            key: "winks",
            title: t("common:Winks.göz kırpmalarım"),
            emptyTitle: t("common:EmptyView.Hiç kimse sana göz kırpmamış"),
            isActive: winksType === "ProfileWinks",
            badgeCount: NewWinkCount,
            actionType: "ProfileWinks",
            hasDeleteButton: true,
        },
        {
            key: "sentwinks",
            title: t("common:Winks.gidenler"),
            emptyTitle: t("common:EmptyView.Hiç kimseye göz kırpmamışsın"),
            isActive: winksType === "ProfileSentWinks",
            badgeCount: 0,
            actionType: "ProfileSentWinks",
            hasDeleteButton: false,
        },
    ];

    const findData = () => {
        switch (winksType) {
            case "ProfileWinks":
                return winksData;
            case "ProfileSentWinks":
                return sentWinksData;
            default:
                return winksData;
        }
    };

    const onDeleteButtonClick = async (userId: string) => {
        dispatch(await InteractionActions.DeleteWinks(userId));
    };

    const onWinkButtonClick = async (userId: string) => {
        dispatch(await InteractionActions.Wink(userId, "wink list"));
    };
    const onFriendButtonClick = async (userId: string, isFriend: boolean) => {
        console.log("onFriendButtonClick", userId, isFriend);

        if (winksType === "ProfileWinks") {
            dispatch(await InteractionActions.SetProfileWinkFriendStatus(userId));
        } else {
            dispatch(await InteractionActions.SetProfileSentWinkFriendStatus(userId));
        }

        if (isFriend) {
            dispatch(await InteractionActions.RemoveFriend(userId));
        } else {
            dispatch(await InteractionActions.AddFriend(userId, "wink list"));
        }
    };

    return {
        titleObj,
        handleInteractions,
        loading,
        findData,
        moreButtonClick,
        showMore,
        moreButtonDisabled,
        onDeleteButtonClick,
        onWinkButtonClick,
        onFriendButtonClick,
    };
};
