// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Gender as GenderType } from "@Interfaces/enums";
import { ProfileActions } from "@Actions";
import { Button, FormBox, Dropdown } from "@Components";
// #endregion Local Imports

// #region Interface Imports
import { IGender } from "./Gender";
// #endregion Interface Imports

export const Gender: React.FunctionComponent<IGender.IProps> = ({ t, onCloseClick, gender }) => {
    const dispatch = useDispatch();
    const { useState, useEffect } = React;

    const options: Array<IGender.IOption> = Object.keys(GenderType)
        .map((gt: any) => {
            const genderId = GenderType[gt];

            if (typeof genderId === "number") {
                const value = parseInt(genderId, 10).toString(); // typescript workaround

                return { label: t(`common:enums.gender.${gt}`), value };
            }

            return { label: "", value: "" };
        })
        .filter((gt) => gt.value.length);

    const currentGender = parseInt(gender.toString(), 10);

    const currentOption =
        options.find((o: IGender.IOption) => o.value === currentGender.toString()) || options[0];

    const [selectedOption, setSelectedOption] = useState<IGender.IOption>();

    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const val = selectedOption ? selectedOption.value : undefined;

        if (val) {
            dispatch(
                await ProfileActions.UpdateProfileInfo({ lookingForGender: parseInt(val, 10) })
            );
        }
    };

    const handleChange = (option: IGender.IOption) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        handleChange(currentOption);
    }, []);

    return (
        <FormBox
            title={t("common:profileEdit.infoModal.gender.title")}
            onHandleSubmit={submit}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">
                {selectedOption && (
                    <Dropdown
                        t={t}
                        options={options}
                        onChange={handleChange}
                        value={selectedOption}
                    />
                )}
            </div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
