// #region Global Imports
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Interface Imports
import { Gender, MaritalStatus } from "@Interfaces/enums";
// #endregion Interface Imports

// #region Local Imports
import { Dropdown, Checkbox, TextInput, Text } from "@Components/Basic";
import { useScreenSize } from "@Definitions";
import { FriendsIcon, Password, Envelope } from "@Icons";
import {
    BlockButton,
    FacebookButton,
    AppleLoginButton,
    FormBox,
    GenderSelect,
    BirthdayPicker,
} from "@Components";
import { IRegisterBox } from "./RegisterBox";
import {
    InnerContainer,
    LeftContainer,
    RightContainer,
    NamePassword,
    DownContainer,
    MainContainer,
    Contract,
    ButtonsContainer,
    MBottom,
    MaritialStatusContainer,
    DatePickerContainer,
    HiddenPixel,
} from "./styled";
import { AccountService } from "@Services";
import { AccountActions, HomeActions } from "@Actions";
import { IStore } from "@Interfaces";
// #endregion Local Imports

export const RegisterBox: React.FunctionComponent<IRegisterBox.IProps> = ({
    t,
    onCloseClick,
}): JSX.Element => {
    const screenSize = useScreenSize();
    const isMobile = screenSize.includes("small");

    const dispatch = useDispatch();

    const me = useSelector((state: IStore) => state.auth.social.facebook?.me);
    const fbToken = useSelector((state: IStore) => state.auth.social.facebook?.token);
    const apple = useSelector((state: IStore) => state.auth.social.apple);

    const hasOtherLoginMethod: boolean = !!fbToken || !!apple.mail;

    const maritialOptions = [
        { value: MaritalStatus.single, label: "bekar" },
        { value: MaritalStatus.married, label: "evli" },
        { value: MaritalStatus.widow, label: "dul" },
        { value: MaritalStatus.divorced, label: "boşanmış" },
        { value: MaritalStatus.separate, label: "ayrı yaşıyor" },
    ];

    const RegisterSchema = Yup.object().shape({
        gender: Yup.string().required(t("common:validation.Lütfen cinsiyet")),
        username: Yup.string()
            .required(t("common:validation.noEmptyUsername"))
            .min(4, t("common:validation.invalidAmountOfCharacter"))
            .max(40, t("common:validation.invalidAmountOfCharacter")),
        email: Yup.string()
            .email(t("common:validation.Geçerli eposta"))
            .required(t("common:validation.Bu alan")),
        maritalStatus: Yup.string().required(t("common:validation.Bu alan")),
        acceptContract: Yup.boolean().oneOf([true], t("common:register.Devam etmek için")),
        birthday: Yup.string().required(t("common:validation.Belirtilen tarih")),
        password: hasOtherLoginMethod
            ? Yup.string()
            : Yup.string()
                  .required(t("common:validation.Lütfen parola"))
                  .min(6, t("common:validation.Parolanız en az"))
                  .max(24, t("common:validation.Parolanız en az")),
    });

    const {
        register,
        watch,
        handleSubmit,
        errors,
        setValue,
        formState,
        setError,
        clearError,
        triggerValidation,
    } = useForm<IRegisterBox.FormValues>({
        validationSchema: RegisterSchema,
    });

    useEffect(() => {
        const email = me?.email || apple.mail;
        setValue([{ email }]);
    }, [me, apple]);

    const [maritialStatus, setMaritialStatus] = useState<IRegisterBox.ISelectOption>();

    const buttonDisabled = formState.isSubmitting;

    const username = watch("username");
    const email = watch("email");

    const onChangeUsername = () => {
        clearError("username");
        triggerValidation("username");
    };

    const uniqueCheckUsername = async () => {
        if (!username) return;

        const { Ok, ReasonId } = await AccountService.CheckUsername({
            body: { username },
        });

        if (!Ok) {
            switch (ReasonId) {
                case 6:
                    setError("username", "InvalidUsername", t("common:validation.invalidUsername"));
                    break;
                case 7:
                    setError(
                        "username",
                        "UsernameExist",
                        t("common:register.Kullanıcı adı kullanımda")
                    );
                    break;
                default:
                    break;
            }
        } else {
            clearError("username");
        }
    };

    const onBlurUsername = async () => {
        await triggerValidation("username");
        if (!errors.username) {
            uniqueCheckUsername();
        }
    };

    const uniqueCheckEmail = async () => {
        if (!email) return;

        const { Ok, Reason } = await AccountService.CheckEmail({
            body: { email },
        });

        if (!Ok) {
            setError(
                "email",
                "EmailExist",
                Reason === "EmailExists"
                    ? t("common:register.E posta kullanımda")
                    : t("common:register.E posta hatalı")
            );
        } else {
            clearError("email");
        }
    };

    const onSubmit = async (values: IRegisterBox.FormValues) => {
        if (errors.username || errors.email) return;

        const params = { ...values, birthday: dayjs(values.birthday).format("YYYY/MM/DD") };

        if (fbToken) {
            dispatch(AccountActions.RegisterWithFacebook({ body: params }, onCloseClick));
        } else if (apple.mail) {
            dispatch(AccountActions.RegisterWithApple({ body: params }, onCloseClick));
        } else {
            dispatch(
                AccountActions.Register(
                    {
                        body: params,
                    },
                    onCloseClick
                )
            );
        }
    };

    useEffect(() => {
        register({ name: "maritalStatus" });
    }, [register]);

    const passValidState = (field: string) => {
        const hasError = Object.keys(errors).find(f => f === field);

        const isDirty = formState.dirtyFields.has(field);

        if (hasError) return false;

        if (isDirty) return true;

        return undefined;
    };

    const acceptContract = watch("acceptContract");

    return (
        <FormBox
            title={t("common:buttons.signup")}
            onCloseClick={() => {
                dispatch(HomeActions.SetTopComponent({ topComponent: "card" }));
                if (onCloseClick) onCloseClick();
            }}
            onHandleSubmit={handleSubmit(onSubmit)}
        >
            <MainContainer>
                <InnerContainer>
                    <LeftContainer>
                        <GenderSelect
                            isValid={errors.gender && false}
                            errorText={errors.gender ? errors.gender.message : ""}
                            name="gender"
                            _ref={register}
                            value={
                                me?.gender
                                    ? me!.gender === "male"
                                        ? Gender.male
                                        : Gender.female
                                    : undefined
                            }
                            onChange={(value: Gender) => {
                                setValue("gender", value, true);
                            }}
                            t={t}
                            cy="register-gender"
                        />

                        <NamePassword>
                            <MBottom mb="20">
                                <TextInput
                                    isValid={passValidState("username")}
                                    placeholder={t("common:register.Kullanıcı Adı")}
                                    renderIcon={() => <FriendsIcon width="38px" height="38px" />}
                                    errorText={errors.username ? errors.username.message : ""}
                                    name="username"
                                    onBlur={onBlurUsername}
                                    onChange={onChangeUsername}
                                    _ref={register}
                                    cy="register-username"
                                />
                            </MBottom>
                            {!hasOtherLoginMethod && (
                                <MBottom mb="20">
                                    <TextInput
                                        isValid={passValidState("password")}
                                        placeholder={t("common:register.Şifreniz")}
                                        renderIcon={() => <Password width="38px" height="38px" />}
                                        type="password"
                                        errorText={errors.password ? errors.password.message : ""}
                                        name="password"
                                        onBlur={() => {
                                            triggerValidation("password");
                                        }}
                                        onChange={() => {
                                            triggerValidation("password");
                                        }}
                                        _ref={register}
                                        cy="register-password"
                                    />
                                </MBottom>
                            )}
                        </NamePassword>
                    </LeftContainer>
                    <RightContainer>
                        <TextInput
                            isValid={passValidState("email")}
                            placeholder={t("common:register.Eposta")}
                            errorText={errors.email ? errors.email.message : ""}
                            renderIcon={() => <Envelope width="38px" height="38px" />}
                            name="email"
                            onBlur={uniqueCheckEmail}
                            _ref={register}
                            cy="register-email"
                        />

                        <DatePickerContainer>
                            <BirthdayPicker
                                isValid={errors.birthday && false}
                                t={t}
                                onDateChange={(value: Date, allIsSelected: boolean) => {
                                    if (allIsSelected) {
                                        setValue("birthday", value, true);
                                    } else {
                                        setValue("birthday", "");
                                    }
                                }}
                                errorText={errors.birthday ? errors.birthday.message : ""}
                                showError={errors.birthday && true}
                                name="birthday"
                                value={me?.birthday ? new Date(me!.birthday) : undefined}
                                _ref={register}
                                isMobile={isMobile}
                            />
                        </DatePickerContainer>

                        <MaritialStatusContainer>
                            <Dropdown
                                t={t}
                                isValid={errors.maritalStatus && false}
                                placeholder={t("common:register.Medeni Durumunuz")}
                                options={maritialOptions}
                                errorText={errors.maritalStatus ? errors.maritalStatus.message : ""}
                                name="maritalStatus"
                                value={maritialStatus}
                                onChange={(option: IRegisterBox.ISelectOption) => {
                                    setValue("maritalStatus", parseInt(option.value, 10));
                                    setMaritialStatus(option);
                                    clearError("maritalStatus");
                                }}
                                cy="register-marital"
                            />
                        </MaritialStatusContainer>
                    </RightContainer>
                </InnerContainer>
                <DownContainer>
                    <Contract>
                        <Checkbox
                            isValid={errors.acceptContract && false}
                            errorText={errors.acceptContract ? errors.acceptContract.message : ""}
                            checked={acceptContract}
                            name="acceptContract"
                            _ref={register}
                            onChange={() => {
                                setValue("acceptContract", !acceptContract);
                            }}
                            className="align-items-end"
                            cy="register-contract"
                        >
                            <Text
                                size="small"
                                dangerouslySetInnerHTML={{
                                    __html: t("common:register.Üyelik Sözleşmesini", {
                                        membershipContract:
                                            "https://www.siberalem.com/uyelik-sozlesmesi/",
                                        kvkkContract:
                                            "https://www.siberalem.com/kisisel-veri-kullanim-sartlari/",
                                    }),
                                }}
                            />
                        </Checkbox>
                    </Contract>

                    <ButtonsContainer flexEnd={hasOtherLoginMethod}>
                        {screenSize !== "xsmall" && !hasOtherLoginMethod && (
                            <div className="other-buttons">
                                <AppleLoginButton t={t} />
                            </div>
                        )}
                        <BlockButton
                            disabled={buttonDisabled}
                            onClick={() =>
                                (async () => {
                                    await uniqueCheckUsername();
                                    await uniqueCheckEmail();
                                })()
                            }
                            type="submit"
                            cy="register-submit-btn"
                        >
                            {t("common:buttons.sign")}
                        </BlockButton>
                    </ButtonsContainer>
                    <HiddenPixel />
                </DownContainer>
            </MainContainer>
        </FormBox>
    );
};
