import styled from "styled-components";

export const StyledAnchor = styled.a`
    cursor: pointer;

    :hover {
        text-decoration: none;
    }

    :focus {
        outline: none;
    }
`;
