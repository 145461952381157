// #region Local Imports
import { Http } from "@Services";
import { ConversationModel } from "./Conversation";
// #endregion Local Imports

export const ConversationService = {
    GetPrioritized: async (): Promise<ConversationModel.ConversationPreviews> => {
        const response = await Http.Request<ConversationModel.ConversationPreviews>(
            "POST",
            "/conversation/Prioritized"
        );

        return response;
    },
    GetRequests: async (fromDate?: string): Promise<ConversationModel.ConversationPreviews> => {
        const response = await Http.Request<ConversationModel.ConversationPreviews>(
            "POST",
            "/conversation/Request",
            undefined,
            { count: 40, fromDate }
        );

        return response;
    },
    GetReplied: async (fromDate?: string): Promise<ConversationModel.ConversationPreviews> => {
        const response = await Http.Request<ConversationModel.ConversationPreviews>(
            "POST",
            "/conversation/Replied",
            undefined,
            { count: 20, fromDate }
        );

        return response;
    },
    GetUnreads: async (): Promise<ConversationModel.UnreadResponse> => {
        const response = await Http.Request<ConversationModel.UnreadResponse>(
            "POST",
            "/conversation/unreadmessagecounts"
        );

        return response;
    },
    GetChats: async (fromDate?: string): Promise<ConversationModel.ConversationPreviews> => {
        const response = await Http.Request<ConversationModel.ConversationPreviews>(
            "POST",
            "/conversation/Chats",
            undefined,
            { count: 20, fromDate }
        );

        return response;
    },
    ArchiveMessage: async (userId: string): Promise<ConversationModel.SuccessResponse> => {
        const response = await Http.Request<ConversationModel.SuccessResponse>(
            "POST",
            "/conversation/archive",
            undefined,
            {
                others: userId,
            }
        );

        return response;
    },
    MarkAsRead: async (withUserId: string): Promise<ConversationModel.SuccessResponse> => {
        const response = await Http.Request<ConversationModel.SuccessResponse>(
            "POST",
            "/conversation/MarkAsRead",
            undefined,
            {
                withUserId,
            }
        );

        return response;
    },
    GetMessageList: async (
        touserid: string,
        count: number,
        fromDate?: string // ? Optionally fetches history
    ): Promise<ConversationModel.IFetchMessages> => {
        return Http.Request("POST", "/message/list", undefined, { touserid, count, fromDate });
    },
    SendMessage: async (
        payload: ConversationModel.ISendMessagePayload
    ): Promise<ConversationModel.ISendMessageResponse> =>
        Http.Request("POST", "/message/send", undefined, payload),

    Filter: async (
        payload: ConversationModel.IFilterRequest
    ): Promise<ConversationModel.ConversationPreviews> =>
        Http.Request<ConversationModel.ConversationPreviews>(
            "POST",
            "/conversation/filter",
            undefined,
            payload
        ),
    Complain: async (withUserId: string): Promise<{ Success: boolean }> =>
        Http.Request("POST", "/conversation/Complain", undefined, { with: withUserId }),
    Ban: async (withUserId: string): Promise<{ Success: boolean }> =>
        Http.Request("POST", "/interaction/ban", undefined, { with: withUserId }),
};
