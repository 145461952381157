// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic/RectangleSkeleton";
import {
    Container,
    AvatarContainer,
    ProfileProperties,
    IconInfo,
    ZodiacContainer,
    ButtonContainer,
} from "./styled";
import { useScreenSize } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IProfileCardSkeleton } from "./ProfileCardSkeleton";
// #endregion Interface Imports

export const ProfileCardSkeleton: React.FunctionComponent<IProfileCardSkeleton.IProps> = ({
    isModal,
}) => {
    const screenSize = useScreenSize();

    return (
        <Container isModal={isModal}>
            {screenSize !== "xsmall" && (
                <AvatarContainer>
                    <RectangleSkeleton width="215" height="215" />
                </AvatarContainer>
            )}

            <ProfileProperties>
                <RectangleSkeleton style={{ marginBottom: "10px" }} width="100" height="10" />
                {!screenSize.includes("small") && !(screenSize.includes("medium") && !isModal) && (
                    <>
                        <RectangleSkeleton
                            style={{ marginBottom: "30px" }}
                            width="180"
                            height="30"
                        />
                        <RectangleSkeleton
                            style={{ marginBottom: "15px" }}
                            width="100"
                            height="10"
                        />
                        <IconInfo>
                            <RectangleSkeleton
                                style={{ borderRadius: "10px", marginRight: "5px" }}
                                width="20"
                                height="20"
                            />
                            <RectangleSkeleton width="100" height="10" />
                        </IconInfo>

                        <ZodiacContainer className="profile-card">
                            <IconInfo>
                                <RectangleSkeleton
                                    style={{ borderRadius: "10px", marginRight: "5px" }}
                                    width="20"
                                    height="20"
                                />
                                <RectangleSkeleton width="40" height="10" />
                            </IconInfo>
                            <IconInfo>
                                <RectangleSkeleton
                                    style={{ borderRadius: "10px", marginRight: "5px" }}
                                    width="20"
                                    height="20"
                                />
                                <RectangleSkeleton width="40" height="10" />
                            </IconInfo>
                        </ZodiacContainer>
                        <IconInfo>
                            <RectangleSkeleton
                                style={{ borderRadius: "10px", marginRight: "5px" }}
                                width="20"
                                height="20"
                            />
                            <RectangleSkeleton width="100" height="10" />
                        </IconInfo>
                    </>
                )}
            </ProfileProperties>

            <ButtonContainer>
                <RectangleSkeleton style={{ borderRadius: "30px" }} width="110" height="60" />
            </ButtonContainer>
        </Container>
    );
};
