import * as React from "react";

const SvgChevronRight = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 33 49" {...props}>
        <defs>
            <filter
                x="-7.5%"
                y="-38.2%"
                width="116.2%"
                height="182.4%"
                filterUnits="objectBoundingBox"
                id="chevron-right_svg__a"
            >
                <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    stdDeviation={3}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter
                x="-68.4%"
                y="-24.2%"
                width="213.9%"
                height="160.4%"
                filterUnits="objectBoundingBox"
                id="chevron-right_svg__b"
            >
                <feOffset dx={-2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    stdDeviation={3}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    in="shadowBlurOuter1"
                />
            </filter>
            <path
                d="M11.92 17.736l15.556 15.557a.998.998 0 010 1.414L11.92 50.264a1 1 0 11-1.414-1.414L25.355 34l-14.85-14.85a1 1 0 011.415-1.414z"
                id="chevron-right_svg__c"
            />
        </defs>
        <g
            filter="url(#chevron-right_svg__a)"
            transform="translate(-6 -13)"
            fill="none"
            fillRule="evenodd"
        >
            <g transform="matrix(-1 0 0 1 37.982 0)" fill="currentcolor">
                <use filter="url(#chevron-right_svg__b)" xlinkHref="#" />
                <use xlinkHref="#chevron-right_svg__c" />
            </g>
        </g>
    </svg>
);

export default SvgChevronRight;
