import * as React from "react";

const SvgCamera = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 21 16" {...props}>
        <path
            d="M6.5.5a1 1 0 011 1h11a2 2 0 012 2v10a2 2 0 01-2 2h-16a2 2 0 01-2-2v-10a2 2 0 012-2h1a1 1 0 011-1h2zm12 2h-16a1 1 0 00-.993.883L1.5 3.5v10a1 1 0 00.883.993l.117.007h16a1 1 0 00.993-.883l.007-.117v-10a1 1 0 00-1-1zm-8 2a4 4 0 110 8 4 4 0 010-8zm0 1a3 3 0 100 6 3 3 0 000-6zm7-2a1 1 0 110 2 1 1 0 010-2z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCamera;
