// #region Global Imports
import styled from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const ImageContainer = styled.div<{ isActive: boolean }>`
    cursor: pointer;
    position: relative;
    padding: 3px;

    &:after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${theme.colors.sliderDarkBlue};
        display: ${({ isActive }) => (isActive ? "block" : "none")};
        margin-top: 4px;
        margin-left: 17px;
        position: absolute;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: ${theme.colors.badgeGreen};
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff;

    i {
        font-size: 6px;
        color: ${theme.colors.white};
    }
`;
