// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Gender } from "@Interfaces/enums";
import { Anchor } from "@Components/Basic";
import { IOnlineUserButton } from "./OnlineUserButton";
// #endregion Local Imports

export const Container = styled(Anchor)<IOnlineUserButton.IProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.38px;
    background: ${({ theme, gender }) =>
        gender === Gender.male ? theme.gradients.estorilToBlue : theme.gradients.pinkToPurple};
    height: 60px;
    border-radius: 30px;
    color: ${({ theme }) => theme.colors.white};
    width: 162px;
    :hover {
        background: ${({ theme, gender }) =>
            gender === Gender.male
                ? theme.gradients.estorilToBlueHover
                : theme.gradients.pinkToPurpleHover};
    }
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    margin-right: 10px;
`;

export const MiddleContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
`;
export const CountContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    letter-spacing: 0.6px;
    margin-right: 2px;
    color: ${({ theme }) => theme.colors.white};
`;

export const ButtonText = styled.span`
    font-size: 11px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconFigure = styled.i`
    font-size: 42px;
    padding-top: 13px;
    padding-left: 16px;
    width: 37px;
    height: 61px;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconRightArrow = styled.i`
    font-size: 24px;
    padding-top: 8px;
    margin-right: 10px;
    height: 36px;
    width: 15px;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconGreenLight = styled.i`
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #00f07b;
    border: 2px solid #fff;
`;

export const Count = styled.div`
    height: 20px;
`;

Count.displayName = "Count";
