// #region Global Imports
import styled from "styled-components";
import { IValidationCounter } from "./ValidationCounter";
// #endregion Global Imports

export const Container = styled.div``;

export const SpanCount = styled.span<IValidationCounter.IProps>`
    color: ${props => {
        if (props.count === 0) return ({ theme }) => theme.colors.lightGray;
        if (props.count <= props.countLimit) return ({ theme }) => theme.colors.brightGreen;
        return ({ theme }) => theme.colors.red;
    }};
`;

export const Span = styled(SpanCount)`
    margin-right: 3px;
`;

SpanCount.displayName = "SpanCount";
