// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

import { ILabel } from "./Label";

export const Container = styled.div<ILabel.IProps>`
    color: ${({ theme }) => theme.colors.red};
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 2px;
    ${({ customLabel }) => customLabel && customLabel};
`;
