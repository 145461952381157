// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container, Fader, ShowMoreButton, ButtonWrapper } from "./styled";
import { ContentBox } from "../../styled";
import { IUserItemList } from "./UserItemList";
import { UserItem, NoLikes } from "..";
import { InteractionModel, IStore } from "@Interfaces";
import { ActionConsts } from "@Definitions";
import { UserItemSkeleton } from "../UserItem/Components/UserItemSkeleton";
import { Repeater } from "@Components";
// #endregion Local Imports

const UserItemList: React.FunctionComponent<IUserItemList.IProps> = ({
    fetchLikesToMe,
    type,
    t,
}): JSX.Element => {
    const dispatch = useDispatch();

    const [selectedItemId, setSelectedItemId] = React.useState("");

    const likesToMe = useSelector((state: IStore) => state.dashboard.likesToMe);

    React.useEffect(() => {
        fetchLikesToMe(false);
        return () => {
            dispatch({ type: ActionConsts.Dashboard.ResetLikesToMe });
        };
    }, []);

    const showMore = () => {
        fetchLikesToMe(true);
    };

    // set NewLikeCount 0
    React.useEffect(() => {
        dispatch({
            type: ActionConsts.Notification.GetNotificationCounts,
            payload: {
                NewLikeCount: 0,
            },
        });
    }, [dispatch]);

    if (likesToMe.currentPage > -1 && !likesToMe.list.length) return <NoLikes t={t} />;

    return (
        <ContentBox>
            <Container>
                {likesToMe.list.map(
                    (item: InteractionModel.IFetchLikesToMeInteraction, index: number) => {
                        return (
                            <UserItem
                                key={`${type}-userItem-${index.toString()}`}
                                t={t}
                                date={new Date(item.Date)}
                                imageSrc={item.Profile.Photo.Url}
                                isHidden={item.IsHidden}
                                id={item.Profile.Id}
                                username={item.Profile.Username}
                                age={item.Profile.Age}
                                location={item.Profile.Location}
                                isOnline={item.Profile.IsOnline}
                                isNew={!item.IsSeen}
                                isShowConfirmCredit={selectedItemId === item.Profile.Id}
                                setSelectedItem={(val: string) => setSelectedItemId(val)}
                            />
                        );
                    }
                )}
                {likesToMe.loading ? (
                    <Repeater times={4}>
                        <UserItemSkeleton />
                    </Repeater>
                ) : (
                    !likesToMe.fetchedAll && (
                        <ButtonWrapper>
                            <ShowMoreButton styleType="ghost" onClick={showMore}>
                                <span>{t("dashboard:HotOrNot.UserItemList.showMoreButton")}</span>
                            </ShowMoreButton>
                        </ButtonWrapper>
                    )
                )}
            </Container>
            <Fader />
        </ContentBox>
    );
};

export { UserItemList };
