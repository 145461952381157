// #region Global Imports
import styled, { css } from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
`;

export const SliderItem = styled.div<{ isActive: boolean }>`
    font-size: 12px;
    color: ${({ isActive }) => (isActive ? theme.colors.white : theme.colors.sliderAqua)};
    font-weight: 600;
    cursor: pointer;
    padding-left: 5px;
    text-align: center;

    @media (max-width: ${theme.maxWidth.medium}) {
        font-size: 10px;
    }
    @media (max-width: ${theme.maxWidth.xSmall}) {
        font-size: 12px;
        padding-right: 20px;
        padding-left: 0;
    }
`;

export const customFormBoxStyle = css`
    width: 100%;
    padding: 0;
`;

export const customBoxHeaderStyle = css`
    grid-template-columns: 380px auto 80px;

    svg {
        margin-right: 15px;
        margin-top: -10px;
        filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 6px);
    }
`;

export const customSliderCss = css`
    .slick-list {
        height: 40px;
        width: 100%;
        background-color: ${theme.colors.azureRadianceDark};
        padding-left: 12px;

        ::before,
        ::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            width: 75px;
            height: 100%;
        }

        .slick-track {
            padding-top: 8px;

            .slick-slide {
                div {
                    outline: unset;
                }
            }
        }
    }

    .slick-next {
        right: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        font-style: normal;
        font-weight: 400;

        ::before {
            content: "";
            display: block;
            opacity: 1;
            width: 18px;
            height: 18px;
            mask: url("/icons/arrow-point-to-right.svg") no-repeat center / cover;
            background-color: ${theme.colors.white};
        }
    }
    .slick-prev {
        display: none;
        ::before {
            display: none;
        }
    }
`;

export const customContainerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const PhotosContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0 10px;
    height: 420px;
    overflow-y: scroll;
    margin-bottom: 60px;

    @media (max-width: ${theme.maxWidth.medium}) {
        padding: 10px 0 0 37px;
    }

    @media (max-width: ${theme.maxWidth.xxSmall}) {
        padding: 10px 0 0 16px;
    }
`;
export const OverlayText = styled.div<{ isActive: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ isActive }) => (isActive ? theme.colors.badgeGreen : theme.colors.gray)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.75;
    border-radius: 6px;
    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    display: flex;

    svg {
        align-self: center;
    }
`;

export const PhotoFrame = styled.div`
    border: 1px solid ${theme.colors.gray};
    width: 144px;
    height: 144px;
    border-radius: 7px;
    position: relative;
    cursor: pointer;
    margin: 10px;
    position: relative;
    padding: 5px;

    &:hover ${OverlayText} {
        visibility: visible;
    }

    @media (max-width: ${theme.maxWidth.small}) {
        width: 94px;
        height: 94px;
    }
    @media (max-width: ${theme.maxWidth.xSmall}) {
        padding: 4px;
    }
`;

export const Image = styled.div<{ bgSrc: string }>`
    width: 100%;
    height: 100%;
    background-image: url(${({ bgSrc }) => bgSrc});
    background-position: center;
    background-size: cover;
    border-radius: 7px;
    @media (max-width: ${theme.maxWidth.xxSmall}) {
        padding: 4px;
    }
`;

export const SideCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media (max-width: ${theme.maxWidth.medium}) {
        flex-direction: column-reverse;
    }
`;

export const SideCardText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (max-width: ${theme.maxWidth.medium}) {
        text-align: left;
        padding-left: 15px;
    }
`;

export const SideCardFooter = styled.div`
    background-color: ${theme.colors.buttercup};
    padding: 7px;
    text-align: center;
    border-bottom-right-radius: 40px;
    line-height: 1.2;

    @media (max-width: ${theme.maxWidth.medium}) {
        padding: 7px;
        border-bottom-right-radius: 0;
    }
`;

export const TextCard = styled.span`
    font-size: 14px;
    color: ${theme.colors.white};
    padding: 0 7px 45px;

    @media (max-width: ${theme.maxWidth.medium}) {
        padding-left: 0;
        padding-bottom: 10px;
    }

    @media (max-width: ${theme.maxWidth.small}) {
        font-size: 12px;
    }
`;

export const TextFooter = styled.span`
    font-size: 11px;
    color: ${theme.colors.white};
`;

export const LargeText = styled.span`
    font-size: 24px;
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 17px;

    @media (max-width: ${theme.maxWidth.medium}) {
        font-size: 22px;
        margin-bottom: 5px;
    }

    @media (max-width: ${theme.maxWidth.small}) {
        margin-bottom: 5px;
        font-size: 16px;
    }
`;

export const SideCardUp = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.gradients.redSpectrum};
    align-items: center;
    padding-top: 50px;
    height: 90%;

    @media (max-width: ${theme.maxWidth.medium}) {
        flex-direction: row;
        padding-top: 10px;
        padding-left: 20px;
        border-bottom-left-radius: 37px;
        border-bottom-right-radius: 37px;
    }
    @media (max-width: ${theme.maxWidth.small}) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    position: absolute;
    bottom: 0;
    padding: 12px;
    border-bottom-left-radius: 40px;
    width: 100%;
    background-color: ${theme.colors.white};
    box-shadow: 0px -5px 17px -3px rgba(0, 0, 0, 0.75);

    .label {
        margin-bottom: 0 !important;
    }

    @media (max-width: ${theme.maxWidth.medium}) {
        border-bottom-left-radius: 0;
    }
`;

export const badgeCustomStyle = css`
    right: -26px;
`;

export const Icon = styled.i`
    font-size: 17px;
    text-shadow: none;
    color: ${theme.colors.white};

    :hover {
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    }

    :active {
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    }
`;

export const AnchorSpan = styled.span`
    text-transform: uppercase;
`;

export const FormBoxTitle = styled.div`
    @media (max-width: ${theme.maxWidth.xSmall}) {
        color: ${({ theme: { colors } }) => colors.blue};
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        padding-left: 30px;
        padding-top: 15px;

        svg {
            color: ${({ theme: { colors } }) => colors.blue};
            margin-right: 10px;
            margin-top: -8px;
        }
    }
`;
