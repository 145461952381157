// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ISection } from "./Section";
import { Container, InnerContainer } from "./styled";
// #endregion Local Imports

export const Section: React.FunctionComponent<ISection.IProps> = props => {
    const { children, className, bgImage, centered = false, cropped, customStyle } = props;

    return (
        <Container
            className={className}
            bgImage={bgImage}
            cropped={cropped}
            customStyle={customStyle}
        >
            <InnerContainer centered={centered} className="container">
                {children}
            </InnerContainer>
        </Container>
    );
};
