import * as React from "react";

const SvgConversationPlus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 47 49" {...props}>
        <g fill="currentcolor" stroke="#fff" strokeWidth={0.5} fillRule="evenodd">
            <path d="M30.5 15.087c8.837 0 16 7.164 16 16 0 2.924-.784 5.664-2.154 8.023l-.326.537 1.848 5.543a1 1 0 01-1.109 1.303l-.156-.038-5.543-1.848a15.925 15.925 0 01-8.56 2.48c-8.837 0-16-7.163-16-16 0-8.836 7.163-16 16-16zm0 2c-7.732 0-14 6.268-14 14s6.268 14 14 14c2.692 0 5.267-.759 7.488-2.168l.803-.51 4.547 1.516-1.516-4.547.51-.802a13.923 13.923 0 002.168-7.489c0-7.732-6.268-14-14-14zM16.5 6c5.543 0 10.428 2.819 13.3 7.101-.784.03-1.552.11-2.303.236A13.97 13.97 0 0016.5 8c-7.732 0-14 6.268-14 14 0 2.485.647 4.87 1.855 6.97l.314.518.509.803-1.516 4.547 4.547-1.516.803.51a13.9 13.9 0 004.052 1.744c.192.75.433 1.483.717 2.194a15.888 15.888 0 01-5.303-1.924l-.538-.326-5.543 1.848a1 1 0 01-1.306-1.09l.041-.175L2.98 30.56A15.925 15.925 0 01.5 22c0-8.837 7.163-16 16-16zM32.086 5.414h3.998l.002-4a1 1 0 112 0v4h4a1 1 0 010 2h-4v4a1 1 0 11-2 0l-.002-4h-3.998a1 1 0 010-2zM4.56 43.527h3v-3a.75.75 0 111.5 0v3h3a.75.75 0 010 1.5h-3v3a.75.75 0 01-1.5 0v-3h-3a.75.75 0 110-1.5z" />
        </g>
    </svg>
);

export default SvgConversationPlus;
