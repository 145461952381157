// #region Global Imports
import React from "react";
import { useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
import { ToggleSwitch } from "@Components";
import { Title, ItemTitle, ItemDesc, Content, Item, MailNotificationsContent } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IMailNotifications } from "./MailNotifications";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

export const MailNotifications: React.FunctionComponent<IMailNotifications.IProps> = ({
    t,
    selectedPreference,
}) => {
    const { useState, useEffect } = React;
    const preferences = useSelector((state: IStore) => state.profile.preferences);

    const [getMail, setMail] = useState(false);
    const [getMatchMail, setMatchMail] = useState(false);
    const [getCampaigns, setCampaigns] = useState(false);

    useEffect(() => {
        setMail(preferences?.NotificationMail || false);
        setMatchMail(preferences?.MatchMail || false);
        setCampaigns(preferences?.CampaignMail || false);
    }, [preferences]);

    const handleToggle = (key: string) => {
        switch (key) {
            case "NotificationMail":
                setMail(!getMail);
                selectedPreference(key, !getMail);
                break;
            case "MatchMail":
                setMatchMail(!getMatchMail);
                selectedPreference(key, !getMatchMail);
                break;
            case "CampaignMail":
                setCampaigns(!getCampaigns);
                selectedPreference(key, !getCampaigns);
                break;
            default:
                break;
        }
    };
    return (
        <MailNotificationsContent>
            <Title>{t("common:settings.mailNotifications.Title")}</Title>
            <Content>
                <Item className="d-flex py-2 flex-wrap justify-content-between no-gutters">
                    <ItemTitle>
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.mailNotifications.notifications.title")}
                        </Text>
                    </ItemTitle>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleToggle("NotificationMail");
                        }}
                        onKeyPress={() => {
                            handleToggle("NotificationMail");
                        }}
                    >
                        <ToggleSwitch isOn={getMail} />
                    </div>
                    <ItemDesc>
                        <Text color="darkGray" size="medium">
                            {t("common:settings.mailNotifications.notifications.text")}
                        </Text>
                    </ItemDesc>
                </Item>
                <Item className="d-flex py-2 flex-wrap justify-content-between no-gutters">
                    <ItemTitle>
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.mailNotifications.membersForYou.title")}
                        </Text>
                    </ItemTitle>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleToggle("MatchMail");
                        }}
                        onKeyPress={() => {
                            handleToggle("MatchMail");
                        }}
                    >
                        <ToggleSwitch isOn={getMatchMail} />
                    </div>
                    <ItemDesc>
                        <Text color="darkGray" size="medium">
                            {t("common:settings.mailNotifications.membersForYou.text")}
                        </Text>
                    </ItemDesc>
                </Item>
                <Item className="d-flex py-2 flex-wrap justify-content-between no-gutters">
                    <ItemTitle>
                        <Text weight="bold" color="darkGray" size="xxLarge">
                            {t("common:settings.mailNotifications.campaings.title")}
                        </Text>
                    </ItemTitle>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleToggle("CampaignMail");
                        }}
                        onKeyPress={() => {
                            handleToggle("CampaignMail");
                        }}
                    >
                        <ToggleSwitch isOn={getCampaigns} />
                    </div>
                    <ItemDesc>
                        <Text color="darkGray" size="medium">
                            {t("common:settings.mailNotifications.campaings.text")}
                        </Text>
                    </ItemDesc>
                </Item>
            </Content>
        </MailNotificationsContent>
    );
};
