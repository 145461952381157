// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Button } from "@Components/Basic";
import { Header } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IClosed } from "./Closed";
// #endregion Interface Imports

export const Closed: React.FunctionComponent<IClosed.IProps> = ({
    t,
    tPrefix,
    openProfile,
    handleContinue,
}) => {
    const handleOpenButton = async () => {
        await openProfile();
        handleContinue();
    };

    return (
        <>
            <Header className="mt-10">
                <Text color="red" weight="bold">
                    {t(`${tPrefix}.header`)}
                </Text>
            </Header>
            <div className="d-flex align-items-center justify-content-center px-10">
                <Text className="my-10 px-10 text-center" size="medium">
                    {t(`${tPrefix}.text`)}
                </Text>
            </div>
            <Button className="mb-10" styleType="gradient" size="medium" onClick={handleOpenButton}>
                {t(`${tPrefix}.button1Text`)}
            </Button>
            <Button styleType="muted" size="slim" onClick={handleContinue}>
                {t(`${tPrefix}.button2Text`)}
            </Button>
        </>
    );
};
