// #region Global Imports
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { IUseLocationForm } from "./useLocationFrom";
import { MetaDataActions } from "@Actions";
// #endregion Local Imports

export const useLocationForm = ({
    isSearch,
    t,
    countries,
    cities,
    towns,
    defaultLocation,
    saveLocation,
    fetchCities,
    fetchTowns,
}: IUseLocationForm.IProps) => {
    const dispatch = useDispatch();

    const countryDefault: IUseLocationForm.IOption = {
        label: t("common:selectLocation.any"),
        value: "-1",
    };
    const cityDefault: IUseLocationForm.IOption = {
        label: t("common:selectLocation.any"),
        value: "-1",
    };
    const townDefault: IUseLocationForm.IOption = {
        label: t("common:selectLocation.any"),
        value: "-1",
    };
    const townAlt: IUseLocationForm.IOption = {
        label: t("common:selectLocation.town"),
        value: "-1",
    };

    const [countryOptions, setCountryOptions] = useState([countryDefault]);
    const [cityOptions, setCityOptions] = useState([cityDefault]);
    const [townOptions, setTownOptions] = useState(isSearch ? [townDefault] : []);

    const [selectedCountry, setSelectedCountry] = useState<IUseLocationForm.IOption>();
    const [selectedCity, setSelectedCity] = useState<IUseLocationForm.IOption>();
    const [selectedTown, setSelectedTown] = useState<IUseLocationForm.IOption>();

    const [townInput, setTownInput] = useState("");

    const idOf = (option?: IUseLocationForm.IOption): number => {
        if (!option) return -2;
        return parseInt(option.value, 10);
    };

    useEffect(() => {
        setCountryOptions([
            countryDefault,
            ...countries.map(c => {
                return {
                    label: c.Name,
                    value: c.Id.toString(),
                };
            }),
        ]);
    }, [countries]);

    useEffect(() => {
        setCityOptions([
            cityDefault,
            ...cities.map(c => {
                return {
                    label: c.Name,
                    value: c.Id.toString(),
                };
            }),
        ]);
    }, [cities]);

    useEffect(() => {
        if (towns.length) {
            setTownOptions([
                townDefault,
                ...towns.map(town => {
                    return {
                        label: town.Name,
                        value: town.Id.toString(),
                    };
                }),
            ]);
        }
    }, [towns]);

    useEffect(() => {
        if (defaultLocation && !selectedCountry) {
            const { countryId, cityId, townId } = defaultLocation;

            if (countryId !== undefined) {
                if (countryId > -1 && !cities.some(c => c.CountryId === countryId)) {
                    fetchCities(countryId);
                }

                setSelectedCountry({
                    value: countryId.toString(),
                    label: defaultLocation.countryName,
                });
            }

            if (cityId !== undefined) {
                if (
                    countryId === 0 &&
                    cityId > -1 &&
                    !towns.some(town => town.CityId === cityId.toString())
                ) {
                    fetchTowns(cityId);
                }

                const cityOption = {
                    value: cityId.toString(),
                    label: defaultLocation.cityName,
                };

                if (isSearch && cityId === 3) {
                    cityOption.value = "2";
                }

                setSelectedCity(cityOption);
            }

            if (townId !== undefined) {
                setSelectedTown({
                    value: townId.toString(),
                    label: defaultLocation.townName,
                });
            }
        }
    }, [defaultLocation]);

    const changeCountry = (option: IUseLocationForm.IOption) => {
        setSelectedCountry(option);

        const countryId = idOf(option);

        if (countryId > -1) fetchCities(countryId);

        setSelectedCity(cityDefault);
        setSelectedTown(townDefault);
        setCityOptions([]);
        setTownOptions([]);
    };

    const changeCity = (option: IUseLocationForm.IOption) => {
        setSelectedCity(option);

        const cityId = idOf(option);

        if (idOf(selectedCountry) === 0) {
            if (cityId > -1) fetchTowns(cityId);

            setSelectedTown(townDefault);
        } else {
            setSelectedTown(townAlt);
        }

        setTownOptions([]);
    };

    const changeTown = (option: IUseLocationForm.IOption) => {
        setSelectedTown(option);
    };

    const changeTownInput = (newValue: string) => {
        if (idOf(selectedCountry) > 0) {
            setTownInput(newValue);
            if (newValue.length > 1) {
                if (selectedCity && selectedCity.value) fetchTowns(idOf(selectedCity), newValue);
            } else {
                setTownOptions([]);
            }
        }
    };

    const apply = () => {
        if (selectedCountry && selectedCity && selectedTown) {
            const location = {
                countryId: idOf(selectedCountry) > -1 ? idOf(selectedCountry) : undefined,
                countryName: selectedCountry.label,
                cityId: idOf(selectedCity) > -1 ? idOf(selectedCity) : undefined,
                cityName: selectedCity.label,
                townId: idOf(selectedTown) > -1 ? idOf(selectedTown) : undefined,
                townName: selectedTown.label,
            };

            saveLocation(location);

            if (idOf(selectedCountry) > 0) {
                dispatch(MetaDataActions.DeleteTowns());
            }
        }
    };

    return {
        countryOptions,
        cityOptions,
        townOptions,
        selectedCountry,
        selectedCity,
        selectedTown,
        townInput,
        changeCountry,
        changeCity,
        changeTown,
        changeTownInput,
        idOf,
        apply,
    };
};
