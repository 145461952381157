// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Locale Imports
import { Analytics, InteractionService } from "@Services";
import { ActionConsts } from "@Definitions";
import {
    IStore,
    ProfileVisitorsModel,
    IInteractionReducer,
    IInteractionActions,
} from "@Interfaces";
// #region Locale Imports

const handleInteractions = (
    type: "visitors" | "winks" | "sentWinks" | "bans" | "friends" | "onlineFriends",
    interaction: IInteractionReducer.InteractionState,
    interactionFromServer: ProfileVisitorsModel.IProfileVisitorsInteraction[],
    page: number
) => {
    const existingInteractions = interaction.interactions[type].Interactions;
    let newInteractions: ProfileVisitorsModel.IProfileVisitors["Interactions"] = [];

    if (interactionFromServer.length === 0 && page === 0) {
        newInteractions = [];
    } else if (interactionFromServer.length > 0 && page === 0) {
        newInteractions = interactionFromServer;
    } else {
        newInteractions = [...existingInteractions, ...interactionFromServer];
    }

    return newInteractions;
};

export const InteractionActions = {
    ProfileVisitors: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            const {
                auth: { user },
                interaction,
            } = getState();

            dispatch(
                InteractionActions.SetLoading({
                    interactions: {
                        ...interaction.interactions,
                        visitors: {
                            ...interaction.interactions.visitors,
                            loading: true,
                        },
                    },
                })
            );

            if (!user) throw new Error("User not found");

            const userObj = { userId: user.UserId, token: user.Token };
            let showMore = true;

            const response = await InteractionService.FetchProfileVisitors(page, size, userObj);
            const { Interactions } = response;

            if (Interactions.length === 0 && page > 0) showMore = false;

            const newInteractions = handleInteractions("visitors", interaction, Interactions, page);
            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        visitors: {
                            ...response,
                            Interactions: newInteractions,
                            loading: false,
                        },
                    },
                    showMore,
                },
                type: ActionConsts.Interaction.GetProfileVisitors,
            });
        } catch (error) {
            console.log("error", error);
        }
    },
    ProfileWinks: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            const {
                auth: { user },
                interaction,
            } = getState();

            dispatch(
                InteractionActions.SetLoading({
                    interactions: {
                        ...interaction.interactions,
                        winks: {
                            ...interaction.interactions.winks,
                            loading: true,
                        },
                    },
                })
            );

            if (!user) throw new Error("User not found");

            const userObj = { userId: user.UserId, token: user.Token };

            const response = await InteractionService.FetchProfileWinks(page, size, userObj);

            const { Interactions } = response;

            const showMore = Interactions.length === size;

            const newInteractions = handleInteractions("winks", interaction, Interactions, page);

            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        winks: {
                            ...response,
                            Interactions: newInteractions,
                            loading: false,
                        },
                    },
                    showMore,
                },
                type: ActionConsts.Interaction.GetProfileWinks,
            });
        } catch (error) {
            console.log("error", error);
        }
    },
    ProfileSentWinks: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            const {
                auth: { user },
                interaction,
            } = getState();

            dispatch(
                InteractionActions.SetLoading({
                    interactions: {
                        ...interaction.interactions,
                        sentWinks: {
                            ...interaction.interactions.sentWinks,
                            loading: true,
                        },
                    },
                })
            );

            if (!user) throw new Error("User not found");

            const userObj = { userId: user.UserId, token: user.Token };

            const response = await InteractionService.FetchProfileSentWinks(page, size, userObj);

            const { Interactions } = response;

            const showMore = Interactions.length === size;

            const newInteractions = handleInteractions(
                "sentWinks",
                interaction,
                Interactions,
                page
            );

            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        sentWinks: {
                            ...response,
                            Interactions: newInteractions,
                            loading: false,
                        },
                    },
                    showMore,
                },
                type: ActionConsts.Interaction.GetProfileSentWinks,
            });
        } catch (error) {
            console.log("error", error);
        }
    },

    DeleteWinks: async (userId: string) => async (dispatch: Dispatch, getState: () => IStore) => {
        const response = await InteractionService.DeleteWinks(userId);
        if (!response.Success) {
            throw new Error("Error");
        }
        const { interaction } = getState();
        const existingWinks = interaction.interactions.winks.Interactions;

        const newWinksArray = (existingWinks || []).filter(item => userId !== item.Profile.Id);
        dispatch({
            payload: {
                interactions: {
                    ...interaction.interactions,
                    winks: {
                        Interactions: newWinksArray,
                    },
                },
            },
            type: ActionConsts.Interaction.DeleteWink,
        });
    },
    Wink: async (userId: string, source: IInteractionActions.WinkSource) => async (
        dispatch: Dispatch
    ) => {
        const response = await InteractionService.Wink(userId);

        Analytics.event("Winked", { sa_source: source }, true);

        dispatch({
            payload: { wink: response.Success },
            type: ActionConsts.Interaction.Wink,
        });
    },

    Bans: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            const {
                auth: { user },
                interaction,
            } = getState();

            dispatch(
                InteractionActions.SetLoading({
                    interactions: {
                        ...interaction.interactions,
                        bans: {
                            ...interaction.interactions.bans,
                            loading: true,
                        },
                    },
                })
            );

            if (!user) throw new Error("User not found");

            const userObj = { userId: user.UserId, token: user.Token };
            let showMore = true;

            const response = await InteractionService.Bans(page, size, userObj);
            const { Interactions } = response;

            if (Interactions.length === 0 && page > 0) showMore = false;

            const newInteractions = handleInteractions("bans", interaction, Interactions, page);

            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        bans: {
                            ...response,
                            Interactions: newInteractions,
                            loading: false,
                        },
                    },
                    showMore,
                },
                type: ActionConsts.Interaction.GetBans,
            });
        } catch (error) {
            console.log("error", error);
        }
    },

    UnBan: async (users: string) => async (dispatch: Dispatch, getState: () => IStore) => {
        try {
            const {
                auth: { user },
                interaction,
            } = getState();

            if (!user) throw new Error("User not found");

            const userObj = { userId: user.UserId, token: user.Token };
            await InteractionService.UnBan(users, userObj);

            const newBannedList = interaction.interactions.bans.Interactions.filter(
                item => item.Profile.Id !== users
            );

            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        bans: {
                            ...interaction.interactions.bans,
                            Interactions: newBannedList,
                            Count: interaction.interactions.bans.Count - 1,
                        },
                    },
                },
                type: ActionConsts.Interaction.UnBan,
            });
        } catch (error) {
            console.log("error", error);
        }
    },

    AddFriend: async (userId: string, source?: IInteractionActions.AddAddFriendSource) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            type: ActionConsts.Interaction.Friend.Request,
        });
        try {
            const result = await InteractionService.AddFriend(userId);
            
            // Send Analytics
            Analytics.event("Added Friend", { sa_source: source }, true);

            if (result.Success) {
                dispatch({
                    payload: { friends: result },
                    type: ActionConsts.Interaction.Friend.Success,
                });
            }
        } catch (error) {
            dispatch({
                type: ActionConsts.Interaction.Friend.Failed,
            });
        }
    },

    RemoveFriend: async (userId: string) => async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch({
            type: ActionConsts.Interaction.Friend.Request,
        });
        try {
            await InteractionService.RemoveFriend(userId);

            const { interaction } = getState();
            const existingFriends = interaction.interactions.friends.Interactions;
            const existingOnlineFriends = interaction.interactions.onlineFriends.Interactions;

            const newFriendsArray = (existingFriends || []).filter(
                item => userId !== item.Profile.Id
            );

            const newOnlineFriendsArray = (existingOnlineFriends || []).filter(
                item => userId !== item.Profile.Id
            );
            dispatch({
                payload: {
                    interactions: {
                        ...interaction.interactions,
                        friends: {
                            Interactions: newFriendsArray,
                        },
                        onlineFriends: {
                            Count: interaction.interactions.onlineFriends.Count - 1,
                            Interactions: newOnlineFriendsArray,
                        },
                    },
                },
                type: ActionConsts.Interaction.Friend.Success,
            });
        } catch (error) {
            dispatch({
                type: ActionConsts.Interaction.Friend.Failed,
            });
        }
    },

    GetFriends: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const {
            auth: { user },
            interaction,
        } = getState();

        dispatch(
            InteractionActions.SetLoading({
                interactions: {
                    ...interaction.interactions,
                    friends: {
                        ...interaction.interactions.friends,
                        loading: true,
                    },
                },
            })
        );

        if (!user) throw new Error("User not found");
        const userObj = { userId: user.UserId, token: user.Token };
        let showMore = true;
        const response = await InteractionService.friendsV2(page, size, userObj);

        const { Interactions } = response;

        if (Interactions.length === 0 && page > 0) showMore = false;

        const newInteractions = handleInteractions("friends", interaction, Interactions, page);
        dispatch({
            payload: {
                interactions: {
                    ...interaction.interactions,
                    friends: {
                        ...response,
                        Interactions: newInteractions,
                        loading: false,
                    },
                },
                showMore,
            },
            type: ActionConsts.Interaction.GetFriends,
        });
    },

    GetOnlineFriends: async (page: number, size: number) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const { interaction } = getState();
        let showMore = true;

        dispatch(
            InteractionActions.SetLoading({
                interactions: {
                    ...interaction.interactions,
                    onlineFriends: {
                        ...interaction.interactions.onlineFriends,
                        loading: true,
                    },
                },
            })
        );

        const response = await InteractionService.onlineFriends(page, size);
        const { Interactions } = response;
        if (Interactions.length === 0 && page > 0) showMore = false;

        const newInteractions = handleInteractions(
            "onlineFriends",
            interaction,
            Interactions,
            page
        );

        dispatch({
            payload: {
                interactions: {
                    ...interaction.interactions,
                    onlineFriends: {
                        ...response,
                        Interactions: newInteractions,
                        loading: false,
                    },
                },
                showMore,
            },
            type: ActionConsts.Interaction.GetOnlineFriends,
        });
    },

    SetVisitorIsFriendStatus: async (userId: string) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const {
            interaction: { interactions },
        } = getState();

        const newList = interactions.visitors.Interactions.map(item => {
            if (item.Profile.Id !== userId) {
                return item;
            }
            return {
                ...item,
                IsFriend: !item.IsFriend,
            };
        });

        dispatch({
            payload: {
                interactions: {
                    ...interactions,
                    visitors: {
                        ...interactions.visitors,
                        Interactions: newList,
                    },
                },
            },
            type: ActionConsts.Interaction.SetIsFriendStatus,
        });
    },

    SetProfileWinkFriendStatus: async (userId: string) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const {
            interaction: { interactions },
        } = getState();

        const newList = interactions.winks.Interactions.map(item => {
            if (item.Profile.Id !== userId) {
                return item;
            }
            return {
                ...item,
                IsFriend: !item.IsFriend,
            };
        });

        dispatch({
            payload: {
                interactions: {
                    ...interactions,
                    winks: {
                        ...interactions.winks,
                        Interactions: newList,
                    },
                },
            },
            type: ActionConsts.Interaction.SetIsFriendStatus,
        });
    },

    SetProfileSentWinkFriendStatus: async (userId: string) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const {
            interaction: { interactions },
        } = getState();

        const newList = interactions.sentWinks.Interactions.map(item => {
            if (item.Profile.Id !== userId) {
                return item;
            }
            return {
                ...item,
                IsFriend: !item.IsFriend,
            };
        });

        dispatch({
            payload: {
                interactions: {
                    ...interactions,
                    sentWinks: {
                        ...interactions.sentWinks,
                        Interactions: newList,
                    },
                },
            },
            type: ActionConsts.Interaction.SetIsFriendStatus,
        });
    },

    SetLoading: (payload: any) => ({
        payload,
        type: ActionConsts.Interaction.SetLoading,
    }),
};
