import styled from "styled-components";

export const ErrorText = styled.div`
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 10px;
    color: ${({ theme }) => theme.colors.red};
    margin-left: 20px;
`;

export const NativeSelect = styled.select<{
    width: string;
    minHeight: string;
    borderColor: string;
    hasValue: boolean;
}>`
    -webkit-appearance: none;
    appearance: none;
    padding-right: 5px;
    padding-left: 10px;
    border-radius: 25px;
    font-family: "Montserrat";
    color: ${({ theme, hasValue }) => (hasValue ? theme.colors.black : theme.colors.gray)};
    background-color: #fff;
    outline: unset;
    border-width: 2px;
    border-color: ${({ hasValue, theme }) =>
        hasValue ? theme.colors.brightGreen : theme.colors.cadetBlue};
    font-size: 14px;
    font-weight: 400;

    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        :hover {
            border-color: ${({ borderColor }) => borderColor};
        }
    }

    :focus {
        border-color: ${({ borderColor }) => borderColor};
    }

    width: ${({ width }) => width || "100%"};
    min-height: ${({ minHeight }) => minHeight || "50px"};
    min-width: 100px;
`;

export const IconContainer = styled.div`
    position: absolute;
    font-size: 8px;
    top: 20px;
    right: 10px;
    pointer-events: none;
`;

export const Container = styled.div`
    position: relative;
`;

Container.displayName = "Dropdown";
