// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.label`
    display: flex;
    align-items: center;
`;

export const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const Box = styled.div<{ checked?: boolean; disabled?: boolean }>`
    min-width: 20px;
    height: 20px;
    background: #ffffff;
    border: 2px solid #acb5c3;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ checked, disabled, theme }) => {
        let style = "";

        if (!checked) style = `img { display: none; }`;

        if (disabled) {
            style += `border: 2px solid ${theme.colors.disabled};`;
        } else {
            style += `
            border: 2px solid #acb5c3; cursor: pointer;
            :hover{
                border: 2px solid ${theme.colors.blue};
            }
        `;
            if (checked) style += "box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.3);";
        }

        return style;
    }}
`;

export const ErrorText = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.red};
`;
