// #region Global Imports
import React from "react";
import dayjs from "dayjs";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions/Styled";
import { IconButton, Anchor } from "@Components/Basic";
import { Avatar, InfoBubble } from "@Components";
import {
    Container,
    TitleBar,
    Time,
    New,
    DeleteButton,
    Content,
    MobileTextContent,
    TextContent,
    Tooltip,
    AvatarContent,
    PrioritizedContent,
    UserInfo,
    customInfoBubbleStyle,
    customMobileInfoBubbleStyle,
    customAvatarStyle,
    CustomPrioritizedIconStyle,
    StyledDeleteIcon,
    StyledChatIcon,
    InfoIcon,
    LocationText,
    Footer,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IChatRequestItem } from "./ChatRequestItem";
import { friendlyLocation, friendlyBubbleText, Preset } from "@Definitions";
import { Trash, ConversationIcon, FlyingHeart } from "@Icons";
// #endregion Interface Imports

export const ChatRequestItem: React.FunctionComponent<IChatRequestItem.IProps> = ({
    t,
    chatItemData,
    isMobile,
    onDeleteButtonClick,
    onChatbuttonClick,
    onMobileClick,
}) => {
    const {
        LastMessage: { Date, Text },
        With: { Photo, IsOnline, Username, Age, Id, Location, TownName },
        IsPrioritized,
        HasNew,
    } = chatItemData;

    const photoUrl = Photo?.Url + Preset.thumb;
    const alt = Photo?.PhotoName || "";
    const avatarType = IsOnline ? "online" : "offline";

    const findInfoBubbleColor = () => {
        if (IsPrioritized) {
            if (HasNew) return theme.colors.darkPink;
            return theme.colors.mediumPink;
        }
        if (HasNew) return theme.colors.lighterBlue;

        return theme.colors.lighterGray;
    };

    const renderMobile = (): JSX.Element => (
        <>
            <TitleBar>
                <Time isSeen={!HasNew}>
                    {dayjs(Date).format("LLL")}
                    {HasNew && (
                        <New>
                            &nbsp;
                            {t("common:Yeni")}
                        </New>
                    )}
                </Time>
                {IsPrioritized && (
                    <PrioritizedContent isMobile={isMobile}>
                        <IconButton
                            renderIcon={() => (
                                <CustomPrioritizedIconStyle>
                                    <FlyingHeart width="35px" height="18px" />
                                </CustomPrioritizedIconStyle>
                            )}
                            hoverColor={theme.colors.facebook}
                        />
                        <span>{t("common:Inbox.SpecialRequest")}</span>
                    </PrioritizedContent>
                )}
                <DeleteButton>
                    <IconButton
                        renderIcon={() => (
                            <StyledDeleteIcon>
                                <Trash width="14px" height="14px" />
                            </StyledDeleteIcon>
                        )}
                        hoverColor={theme.colors.facebook}
                        onIconClick={onDeleteButtonClick}
                    />
                </DeleteButton>
            </TitleBar>
            <Anchor onClick={onMobileClick}>
                <Content isMobile={isMobile}>
                    <AvatarContent>
                        <Avatar
                            src={photoUrl || ""}
                            alt={alt}
                            type={avatarType}
                            customStyle={customAvatarStyle(isMobile)}
                        />
                    </AvatarContent>
                    <MobileTextContent data-cy="chatrequest-item-mobile-btn">
                        <UserInfo isMobile={isMobile}>{`${Username}, ${Age}`}</UserInfo>

                        <InfoBubble
                            fullBubble={false}
                            text={friendlyBubbleText(Text, 31)}
                            customStyle={customMobileInfoBubbleStyle(findInfoBubbleColor())}
                        />
                    </MobileTextContent>
                </Content>
            </Anchor>
        </>
    );

    const renderDesktop = (): JSX.Element => (
        <>
            <TitleBar>
                <Time isSeen={!HasNew}>
                    {dayjs(Date).format("LLL")}
                    {HasNew && (
                        <New>
                            &nbsp;
                            {t("common:Yeni")}
                        </New>
                    )}
                </Time>
                <DeleteButton>
                    <IconButton
                        renderIcon={() => (
                            <StyledDeleteIcon>
                                <Trash width="14px" height="14px" />
                            </StyledDeleteIcon>
                        )}
                        hoverColor={theme.colors.facebook}
                        onIconClick={onDeleteButtonClick}
                    />
                </DeleteButton>
            </TitleBar>
            <Content isMobile={isMobile}>
                <TextContent data-cy="chatrequest-item-btn">
                    <Anchor onClick={onChatbuttonClick}>
                        <InfoBubble
                            fullBubble={false}
                            text={friendlyBubbleText(Text, 40)}
                            customStyle={customInfoBubbleStyle(findInfoBubbleColor())}
                        />
                    </Anchor>
                    {Text.length > 40 && <Tooltip>{friendlyBubbleText(Text, 200)}</Tooltip>}
                </TextContent>
                <AvatarContent>
                    <Link href="/profile/[id]?source=conversation" as={`/profile/${Id}`}>
                        <Avatar
                            src={photoUrl || ""}
                            alt={alt}
                            type={avatarType}
                            customStyle={customAvatarStyle(isMobile)}
                        />
                    </Link>
                    <UserInfo
                        isMobile={isMobile}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${Username},${Age}`}
                    >
                        {`${friendlyBubbleText(Username, 13)},${Age}`}
                    </UserInfo>
                    {Location && (
                        <LocationText>
                            <InfoIcon className="icon location-icon" iconType="location" />
                            <span>{friendlyLocation(Location, TownName || "")}</span>
                        </LocationText>
                    )}
                </AvatarContent>
                <Footer>
                    <IconButton
                        renderIcon={() => (
                            <StyledChatIcon>
                                <ConversationIcon width="56px" height="56px" />
                            </StyledChatIcon>
                        )}
                        onIconClick={onChatbuttonClick}
                    />
                    {IsPrioritized && (
                        <PrioritizedContent isMobile={isMobile}>
                            <IconButton
                                renderIcon={() => (
                                    <CustomPrioritizedIconStyle>
                                        <FlyingHeart width="51px" height="32px" />
                                    </CustomPrioritizedIconStyle>
                                )}
                                hoverColor={theme.colors.facebook}
                            />
                            <span>{t("common:Inbox.SpecialRequest")}</span>
                        </PrioritizedContent>
                    )}
                </Footer>
            </Content>
        </>
    );

    return (
        <Container hasNew={HasNew} isPrioritized={IsPrioritized}>
            {isMobile ? renderMobile() : renderDesktop()}
        </Container>
    );
};

export { ChatRequestItemSkeleton } from "@Components/ChatRequestItem/Components/ChatRequestItemSkeleton";
