import styled from "styled-components";
import { ITitle } from "./Title";

export const Container = styled.div<ITitle.IProps>`
    font-family: "Montserrat";
    font-weight: ${({ theme, fontWeight }) =>
        fontWeight ? theme.fontWeights[fontWeight] : theme.fontWeights.regular};

    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.red)};

    font-size: ${({ theme, size }) =>
        size ? theme.titleFontSizes[size] : theme.titleFontSizes.medium};
`;
