// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import {
    Container as MainContainer,
    Content as MainContent,
    TextContent as MainTextcontent,
    AvatarContent as MainAvatarContent,
    Footer as MainFooter,
    MobileTextContent as MainMobileTextContent,
    customAvatarStyle,
} from "@Components/ChatRequestItem/styled";
// #endregion Local Imports

export const Container = styled(MainContainer)``;

export const Content = styled(MainContent)`
    padding-top: 15px;
    align-items: center;
`;

export const TextContent = styled(MainTextcontent)`
    overflow: hidden;
`;

export const MobileTextContent = styled(MainMobileTextContent)`
    overflow: hidden;
    flex: 1 1 0;
`;

export const AvatarContent = styled(MainAvatarContent)``;

export const Avatar = styled.div<{ isMobile: boolean }>`
    border-radius: 50%;
    overflow: hidden;
    ${({ isMobile }) => customAvatarStyle(isMobile)}
`;

export const UserInfo = styled.div`
    padding-top: 8px;
    overflow: hidden;
`;

export const Footer = styled(MainFooter)`
    border-radius: 50%;
    overflow: hidden;
    width: fit-content;
`;
