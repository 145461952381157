// #region Global Imports
import styled, { CSSProp } from "styled-components";
// #endregion Global Imports

export const TooltipContainer = styled.div<{
    popped?: boolean;
    ref?: any;
    role?: string;
    customStyle?: CSSProp;
}>`
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    > div {
        transform: scale(1.2);
        transition: transform 0.2s ease;

        ::before,
        ::after {
            content: "";
            display: block;
            border: 0px solid;
            position: absolute;
        }

        ${({ customStyle: c }) => c && c};

        ${({ popped }) => popped && { transform: "unset" }}
    }

    ${({ popped }) =>
        popped && {
            "pointer-events": "unset",
            opacity: "unset",
        }}
`;
