const subscribers: { [key: string]: Function[] } = {};

export const pubSub = {
    publish(event: string, data?: any) {
        if (!subscribers[event]) return;

        subscribers[event].forEach(callback => {
            callback(data);
        });
    },
    subscribe(event: string, callback: Function) {
        if (!subscribers[event]) subscribers[event] = [];

        const index = subscribers[event].push(callback) - 1;

        return {
            unsubscribe() {
                subscribers[event].splice(index, 1);
            },
        };
    },
};
