// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Card, Title, Button } from "@Components/Basic";
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IFreeCredits } from "./FreeCredits";
// #endregion Interface Imports

export const FreeCredits: React.FunctionComponent<IFreeCredits.IProps> = ({
    t,
    onClickButton,
}): JSX.Element => (
    <Card overflowHidden>
        <Container className="py-4">
            <Title
                className="text-center mb-5 px-5"
                color="red"
                size="large"
                fontWeight="bold"
                dangerouslySetInnerHTML={{
                    __html: t("dashboard:Widgets.FreeCredits.title"),
                }}
            />
            <Button styleType="gradient" onClick={onClickButton} className="text-uppercase">
                {t("dashboard:Widgets.FreeCredits.winFreeCredit")}
            </Button>
        </Container>
    </Card>
);
