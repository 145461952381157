// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ customStyle?: CSSProp }>`
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    height: 60px;
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        border-radius: 30px 12px 12px 30px;
    }
    ${({ customStyle }) => customStyle && customStyle};
`;
const defaultIconStyle = css`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray};
`;

export const CustomIconStyle = styled.div`
    ${defaultIconStyle}
`;

export const RedIconStyle = styled.div`
    ${defaultIconStyle}
    color: ${({ theme }) => theme.colors.red};
`;
export const GreenIconStyle = styled.div`
    ${defaultIconStyle}
    color: ${({ theme }) => theme.colors.green};
`;
export const CustomGreenIconStyle = styled.div`
    ${defaultIconStyle}
    color: ${({ theme }) => theme.colors.green};
    :hover{
        color: ${({ theme }) => theme.colors.badgeHoverGreen};
    }
`;
export const OnHover = styled.div`
    display: none;
    align-items: center;
`;
export const NoHover = styled.div`
    display: flex;
    align-items: center;
`;
export const Hover = styled.div`
    position: relative;
    &:hover {
        ${OnHover} {
            display: flex !important;
        }
        ${NoHover} {
            display: none !important;
        }
    }
`;
