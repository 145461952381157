// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    InputContainer,
    Input,
    Icon,
    ErrorText,
    InputInnerContainer,
    TextArea,
} from "./styled";
import { ITextInput } from "./TextInput";
// #endregion Local Imports

export const TextInput: React.FunctionComponent<ITextInput.IProps> = (props) => {
    const {
        isValid,
        errorText,
        value,
        onChange,
        disabled = false,
        _ref,
        type,
        width,
        height,
        placeholder,
        cy,
        renderIcon
    } = props;

    return (
        <Container>
            {type === "textarea" && (
                <TextArea {...props} value={value} onChange={onChange} ref={_ref} data-cy={cy} />
            )}

            {type !== "textarea" && (
                <InputContainer width={width} height={height}>
                    <InputInnerContainer isValid={isValid} disabled={disabled}>
                        <Input
                            type={type || "text"}
                            {...props}
                            value={value}
                            onChange={onChange}
                            ref={_ref}
                            data-cy={cy}
                            placeholder={placeholder}
                        />
    
                        {renderIcon && 
                            <Icon isValid={isValid} disabled={disabled}>
                                {renderIcon()}
                            </Icon>
                        }
                    </InputInnerContainer>
                    <ErrorText>{!disabled && isValid === false && errorText}</ErrorText>
                </InputContainer>
            )}
        </Container>
    );
};
