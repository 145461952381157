// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { ProfileVisitorsModel } from "@Services/API/Interaction/ProfileVisitors";
import { Button, Badge } from "@Components/Basic";
import {
    ProfileViewListItem,
    EmptyView,
    ProfileVisibilityWarning,
    ProfileViewListItemSkeleton,
} from "@Components";
import { InteractionActions } from "@Actions";
import { IProfileInteractionsContainer } from "./ProfileInteractionsContainer";
import { theme, repeatElement } from "@Definitions";
import {
    Container,
    TitleContent,
    TitleText,
    ItemsContent,
    Item,
    ShowMoreButton,
    EmptyViewContent,
    badgeStyle,
} from "./styled";
// #endregion Local Imports

export const ProfileInteractionsContainer: React.FunctionComponent<IProfileInteractionsContainer.IProps> = ({
    t,
    titleObj,
    data,
    showMoreClick,
    showMoreButton,
    loading,
    onTitleClick,
    buttonDisabled,
    onDeleteButtonClick,
    onFriendButtonClick,
    onWinkButtonClick,
    hideInteractionButtons,
    showEnabledWarning = false,
    hideAddFriendButton,
    analyticSource
}: IProfileInteractionsContainer.IProps) => {
    const getKey = (username: string) => `profile-${titleObj[0].key}-${username}`;

    const findTitle = () => {
        const title = titleObj.find(x => (x.isActive ? x.emptyTitle : ""));
        return title?.emptyTitle || "";
    };

    const renderItems = (): JSX.Element[] | JSX.Element | undefined => {
        if (showEnabledWarning) {
            return <ProfileVisibilityWarning t={t} />;
        }
        if (data?.Interactions?.length) {
            const hasDeleteButton =
                titleObj.filter(titleItem => titleItem.hasDeleteButton && titleItem.isActive)
                    .length > 0;

            return data.Interactions.map(
                (item: ProfileVisitorsModel.IProfileVisitorsInteraction) => (
                    <Item key={getKey(item.Profile.Username)}>
                        <ProfileViewListItem
                            hideAddFriendButton={hideAddFriendButton}
                            hideInteractionButtons={hideInteractionButtons || false}
                            data={item}
                            t={t}
                            hasDeleteButton={hasDeleteButton}
                            onDeleteButtonClick={() =>
                                hasDeleteButton &&
                                onDeleteButtonClick &&
                                onDeleteButtonClick(item.Profile.Id)
                            }
                            onWinkButtonClick={() => onWinkButtonClick && onWinkButtonClick(item.Profile.Id)}
                            onFriendButtonClick={() =>
                                onFriendButtonClick &&
                                onFriendButtonClick(item.Profile.Id, item.IsFriend)
                            }
                            analyticSource={analyticSource}
                        />
                    </Item>
                )
            );
        }

        return (
            <EmptyViewContent>
                <EmptyView t={t} title={findTitle()} />
            </EmptyViewContent>
        );
    };

    const renderTitle = (): JSX.Element[] | undefined => {
        return titleObj?.map(item =>
            item.badgeCount > 0 ? (
                <Badge
                    key={`badge--${item.key}`}
                    count={item.badgeCount}
                    color={theme.colors.red}
                    customStyle={badgeStyle}
                >
                    <TitleText
                        onClick={() => onTitleClick?.(item.actionType)}
                        isActive={item.isActive}
                    >
                        {item.title}
                    </TitleText>
                </Badge>
            ) : (
                <TitleText
                    key={`tt--${item.key}`}
                    onClick={() => onTitleClick?.(item.actionType)}
                    isActive={item.isActive}
                >
                    {item.title}
                </TitleText>
            )
        );
    };

    const renderSketelon = () => (
        <ItemsContent>
            {repeatElement(
                <Item>
                    <ProfileViewListItemSkeleton />
                </Item>,
                10
            )}
        </ItemsContent>
    );

    return (
        <Container>
            <TitleContent>{renderTitle()}</TitleContent>
            {loading ? (
                renderSketelon()
            ) : (
                <>
                    <ItemsContent>{renderItems()}</ItemsContent>
                    {data && showMoreButton && !showEnabledWarning && (
                        <ShowMoreButton>
                            <Button
                                onClick={() => !buttonDisabled && showMoreClick && showMoreClick()}
                                disabled={buttonDisabled}
                            >
                                {t("common:DAHA FAZLA")}
                            </Button>
                        </ShowMoreButton>
                    )}
                </>
            )}
        </Container>
    );
};
