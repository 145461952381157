// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports

import { Button } from "@Components/Basic";
import { ReactModal, Text } from "@Components";
import { Container, SiberalemLogo as SiberalemLogoContainer } from "./styled";
import { SiberalemLogo, Exclamation } from "@Icons";
import { theme } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IProbationModal } from "./ProbationModal";
// #endregion Interface Imports

export const ProbationModal: React.FunctionComponent<IProbationModal.IProps> = ({
    t,
    onButtonClick,
}) => {
    const customStyles: ReactModal.Styles = {
        content: {
            background: "unset",
            border: "unset",
        },
        overlay: {
            height: "100vh",
            zIndex: 99999999,
            backgroundImage: "url(/images/bg-yellow.png)",
            backgroundSize: "cover",
        },
    };
    return (
        <ReactModal isOpen style={customStyles}>
            <Container>
                <SiberalemLogoContainer>
                    <SiberalemLogo width="232px" height="60px" color={theme.colors.black} />
                </SiberalemLogoContainer>

                <div className="mb-4">
                    <Exclamation width="96px" height="96px" color={theme.colors.black} />
                </div>
                <div className="mb-4">
                    <Text>{t("common:probation.text")}</Text>
                </div>
                <div className="mb-4">
                    <Text weight="bold">{t("common:probation.text2")}</Text>
                </div>
                <div className="mb-4">
                    <Text>{t("common:probation.text3")}</Text>
                </div>
                <div className="mb-4">
                    <Text weight="bold">{t("common:probation.team")}</Text>
                </div>
                <Button styleType="ghost" onClick={onButtonClick}>
                    <Text>{t("common:probation.button")}</Text>
                </Button>
            </Container>
        </ReactModal>
    );
};
