import * as React from "react";

const SvgCircledStar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 122 122" {...props}>
        <g fill="currentcolor" fillRule="evenodd">
            <path d="M61 0c33.69 0 61 27.31 61 61s-27.31 61-61 61S0 94.69 0 61 27.31 0 61 0zm0 10.167c-28.074 0-50.833 22.759-50.833 50.833 0 28.074 22.759 50.833 50.833 50.833 28.074 0 50.833-22.759 50.833-50.833 0-28.074-22.759-50.833-50.833-50.833z" />
            <path d="M61 75.615L39.023 91.248l8.078-25.732-21.66-16.07 26.969-.27L61 23.611l8.59 25.565 26.97.27-21.66 16.07 8.077 25.732z" />
        </g>
    </svg>
);

export default SvgCircledStar;
