import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
`;
