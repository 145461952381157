// #region Local Imports
import { Analytics } from "@Services/Analytics";
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { ProfileModel, PhotoModel, CampaignModel } from "@Interfaces";
// #endregion Interface Imports

export const ProfileService = {
    GetProfile: async (userObj: {
        userId: string;
        token: string;
    }): Promise<ProfileModel.IProfile> => {
        const response = await Http.Request<ProfileModel.IProfile>(
            "POST",
            "/profile/self",
            undefined,
            undefined,
            userObj
        );

        // Send Analytics
        const userProperties = {
            sa_su_age_segment: response.AgeSegment,
            sa_su_city: response.CityName,
            sa_su_country: response.CountryName,
            sa_su_gender: response.Gender === 1 ? "female" : "male",
            sa_su_has_facebook_id: response.HasFacebookId,
            sa_su_has_photo: response.HasPhoto,
            sa_su_has_push_token: response.HasAnyPushToken,
            sa_su_has_valid_email: !response.InvalidEmail,
            sa_su_is_premium: response.MembershipType === 3,
            sa_su_nickname: response.Username,
            sa_su_register_date_segment: response.RegisterDateSegment,
            sa_su_town: response.TownName,
        };
        Analytics.setUserProperties(userProperties);

        return response;
    },
    GetProfileWithId: async (id: number): Promise<ProfileModel.IProfile> => {
        const response = await Http.Request<ProfileModel.IProfile>(
            "POST",
            "/profile/get",
            undefined,
            {
                id,
                SetProfileView: true,
            }
        );

        return response;
    },

    GetOccupancy: async (): Promise<ProfileModel.IOccupancy> => {
        const response = await Http.Request<ProfileModel.IOccupancy>(
            "POST",
            "/profile/occupancy",
            undefined,
            undefined
        );

        return response;
    },

    GetEmptyQuestions: async (): Promise<ProfileModel.IGetEmptyQuestionsResponse> => {
        const response = await Http.Request<ProfileModel.IGetEmptyQuestionsResponse>(
            "POST",
            "/profile/getemptyquestions",
            undefined,
            undefined
        );

        return response;
    },

    GetQuestion: async (Id: number): Promise<ProfileModel.IGetQuestionResponse> => {
        const response = await Http.Request<ProfileModel.IGetQuestionResponse>(
            "POST",
            "/profile/getquestion",
            undefined,
            { Id }
        );

        return response;
    },

    UpdateQuestions: async (
        payload: ProfileModel.UpdateQuestionServicePayload
    ): Promise<{ Success: boolean }> => {
        const response = await Http.Request<{ Success: boolean }>(
            "POST",
            "/profile/updatequestion",
            undefined,
            payload.body
        );

        return response;
    },
    UpdateInfo: async (payload: ProfileModel.IInfo): Promise<{ Success: boolean }> => {
        const response = await Http.Request<{ Success: boolean }>(
            "POST",
            "/profile/updateinfo",
            undefined,
            payload
        );

        return response;
    },

    UploadProfilePhoto: async (payload: FormData): Promise<PhotoModel.IPhoto> => {
        const response = await Http.Upload<PhotoModel.IPhoto>("/photo/upload", payload);

        return response;
    },

    RemovePhoto: async (id: string): Promise<{ Success: boolean }> => {
        const response = await Http.Request<{ Success: boolean }>(
            "POST",
            "/profile/removephoto",
            undefined,
            {
                Id: id,
            }
        );

        return response;
    },

    GetPreferences: async (): Promise<ProfileModel.IPreferencesResponse> => {
        const response = await Http.Request<ProfileModel.IPreferencesResponse>(
            "POST",
            "/profile/preferences",
            undefined,
            undefined
        );

        return response;
    },

    SetPrimaryPhoto: async (id: string): Promise<{ Success: boolean }> => {
        const response = await Http.Request<{ Success: boolean }>(
            "POST",
            "/profile/setprimaryphoto",
            undefined,
            {
                Id: id,
            }
        );

        return response;
    },

    SetPreferences: async (
        preferencesName: string,
        preferencesVal: boolean,
        password?: string
    ): Promise<ProfileModel.ISetPreferencesResponseData> => {
        const response = await Http.Request<ProfileModel.ISetPreferencesResponseData>(
            "POST",
            "/profile/setpreferences",
            undefined,
            {
                [preferencesName]: preferencesVal,
                password,
            }
        );

        return response;
    },

    GetSubscriptions: async (userId: string): Promise<ProfileModel.IFetchActiveSubscription> => {
        const response = await Http.Request<ProfileModel.IFetchActiveSubscription>(
            "GET",
            `/profiles/${userId}/subscriptions/active`
        );
        return response;
    },
    SetAutoSubscriptionActive: async (
        userId: string
    ): Promise<ProfileModel.IFetchActiveSubscription> => {
        const response = await Http.Request<ProfileModel.IFetchActiveSubscription>(
            "PUT",
            `/profiles/${userId}/subscriptions/recurrency`
        );
        return response;
    },
    SetAutoSubscriptionPassive: async (
        userId: string
    ): Promise<ProfileModel.IFetchActiveSubscription> => {
        const response = await Http.Request<ProfileModel.IFetchActiveSubscription>(
            "DELETE",
            `/profiles/${userId}/subscriptions/recurrency`
        );
        return response;
    },

    UploadPhotoWithUrl: async (payload: PhotoModel.IPhotoUploadWithUrlRequest): Promise<{}> => {
        const response = await Http.Request<{}>("POST", "/photo/savefromurl", undefined, {
            ...payload,
        });

        return response;
    },
    CanSendStartedSession: async (): Promise<ProfileModel.ICanSendStartedSession> => {
        return Http.Request("POST", "/profile/cansendstartedsession");
    },

    Active: async (payload: CampaignModel.ActivePayload): Promise<CampaignModel.ActiveResponse> => {
        return Http.Request("POST", "/campaign/active", undefined, {
            ...payload,
        });
    },

    ShowPhotoModal: async (): Promise<{ canShow: boolean }> => {
        const response = await Http.Request<{ canShow: boolean }>(
            "POST",
            "/photo/showphotomodal_v2",
            undefined,
            undefined
        );

        return response;
    },

    SeenProbation: async (): Promise<{ Success: boolean }> => {
        const response = await Http.Request<{ Success: boolean }>(
            "POST",
            "/profile/SeenProbation",
            undefined
        );

        return response;
    },
};
