import React from "react";

import { Zodiac as ZodiacType } from "@Interfaces/enums";

import {
    ScorpioIcon,
    LeoIcon,
    PiscesIcon,
    VirgoIcon,
    TaurusIcon,
    GeminiIcon,
    AriesIcon,
    AquariusIcon,
    CapricornIcon,
    LibraIcon,
    SagittariusIcon,
    CancerIcon,
} from "@Icons";
import { theme } from "@Definitions";

export const findZodiacbyId = (id: number): JSX.Element => {
    switch (ZodiacType[id]) {
        case "Scorpio":
            return <ScorpioIcon width="17px" height="19px" color={theme.colors.white} />;
        case "Leo":
            return <LeoIcon width="11px" height="19px" color={theme.colors.white} />;
        case "Pisces":
            return <PiscesIcon width="15px" height="19px" color={theme.colors.white} />;
        case "Virgo":
            return <VirgoIcon width="15px" height="19px" color={theme.colors.white} />;
        case "Taurus":
            return <TaurusIcon width="17px" height="19px" color={theme.colors.white} />;
        case "Gemini":
            return <GeminiIcon width="16px" height="19px" color={theme.colors.white} />;
        case "Aries":
            return <AriesIcon width="17px" height="19px" color={theme.colors.white} />;
        case "Aquarius":
            return <AquariusIcon width="22px" height="19px" color={theme.colors.white} />;
        case "Capricorn":
            return <CapricornIcon width="16px" height="19px" color={theme.colors.white} />;
        case "Libra":
            return <LibraIcon width="18px" height="19px" color={theme.colors.white} />;
        case "Sagittarius":
            return <SagittariusIcon width="16px" height="19px" color={theme.colors.white} />;
        case "Cancer":
            return <CancerIcon width="18.5px" height="19px" color={theme.colors.white} />;
        default:
            return <></>;
    }
};
