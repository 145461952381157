// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    max-width: 391px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.red};
    font-size: 20px;
    letter-spacing: -0.3px;
    text-align: center;
`;
export const SubTitle = styled.div`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    line-height: 20px;
    margin-top: 20px;
`;

export const ButtonGroup = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 20px;
`;
