// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Col, Row } from "@Styled/Grid";
import { DoubleHeart, CheckShield, Lock } from "@Icons";
import { Container, SecurityItem } from "./styled";
import { data } from "./data";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentSecurity } from "./PaymentSecurity";
// #endregion Interface Imports

export const PaymentSecurity: React.FunctionComponent<IPaymentSecurity.IProps> = ({ t }) => {
    const renderIcons = (iconName: string): JSX.Element => {
        switch (iconName) {
            case "lock":
                return <Lock width="25px" height="44px" />;
            case "check-shield":
                return <CheckShield width="31px" height="44px" />;
            case "double-heart":
                return <DoubleHeart width="48px" height="44px" />;
            default:
                return <></>;
        }
    };

    return (
        <Container>
            <Row>
                {data.map((item: IPaymentSecurity.ISecurityItem) => {
                    return (
                        <Col key={`security-item-${item.textLocale}`} className="col-md-12 col-4">
                            <SecurityItem className="my-5 text-center">
                                <Row>
                                    <Col className="col-12 mb-3">{renderIcons(item.icon)}</Col>
                                    <Col className="col-12 d-flex justify-content-center">
                                        <span>
                                            {t(`common:payment.securityCard.${item.textLocale}`)}
                                        </span>
                                    </Col>
                                </Row>
                            </SecurityItem>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};
