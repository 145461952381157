// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    EditableContent,
    Content,
    Question,
    Answer,
    HR,
    ButtonGroup,
    EditButton,
    DeleteButton,
    QuestionGroup,
} from "./styled";
import { theme } from "@Definitions";
import { Edit, Trash } from "@Icons";
// #endregion Local Imports

// #region Local Imports
import { IProfileQuestionItem } from "./ProfileQuestionItem";
// #endregion Local Imports

const ProfileQuestionItem: React.FunctionComponent<IProfileQuestionItem.IProps> = ({
    question,
    answer,
    flexDirection,
    isEditable,
    onDeleteClick,
    onEditClick,
}): JSX.Element => {
    return (
        <Container flexDirection={flexDirection}>
            {isEditable ? (
                <EditableContent flexDirection={flexDirection} onClick={onEditClick}>
                    <QuestionGroup>
                        <Question>{question}</Question>
                        <Answer>{answer}</Answer>
                    </QuestionGroup>
                    <ButtonGroup>
                        <EditButton>
                            <Edit width="11px" height="22px" color={theme.colors.azureRadianceDark} />
                        </EditButton>
                        {answer && (
                            <DeleteButton onClick={onDeleteClick}>
                                <Trash width="18px" height="21px" color={theme.colors.gray} />
                            </DeleteButton>
                        )}
                    </ButtonGroup>
                </EditableContent>
            ) : (
                <Content flexDirection={flexDirection}>
                    <Question>{question}</Question>
                    <Answer>{answer}</Answer>
                </Content>
            )}
            <HR />
        </Container>
    );
};

export { ProfileQuestionItem };
