import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
`;

export const Header = styled.div`
    div {
        width: 300px;
        font-size: 19px;
        text-align: center;
    }
`;

Header.displayName = "Header";
