import { IData } from "./PaymentInfoCard";

export const data: IData = {
    gold: [
        { icon: "conversation-plus", textLocale: "messaging" },
        { icon: "credit-plus", textLocale: "credits" },
        { icon: "wink-plus", textLocale: "winks" },
        { icon: "double-heart", textLocale: "adFree" },
    ],
    coin: [
        { icon: "flying-heart", textLocale: "priority" },
        { icon: "man-circle-filled", textLocale: "showcase" },
        { icon: "hotornot-male-liked", textLocale: "hotornot" },
        { icon: "sticker", textLocale: "sticker" },
    ],
};
