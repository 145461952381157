// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IText } from "./Text";
// #endregion Interface Imports

export const Text: React.FunctionComponent<IText.IProps> = (props: IText.IProps) => {
    const {
        elementType = "div",
        children,
        weight,
        color,
        className,
        size,
        dangerouslySetInnerHTML,
    } = props;

    const fontWeigthStyle: { fontWeight?: number } = {};
    if (weight) {
        fontWeigthStyle.fontWeight = theme.fontWeights[weight];
    }

    const colorStyle: { color?: string } = {};
    if (color) {
        colorStyle.color = theme.colors[color];
    }

    const fontSizeStyle: { fontSize?: string } = {};
    if (size) {
        fontSizeStyle.fontSize = theme.fontSizes[size];
    }

    if (dangerouslySetInnerHTML) {
        return React.createElement(elementType || "div", {
            style: { ...fontWeigthStyle, ...colorStyle, ...fontSizeStyle },
            className,
            dangerouslySetInnerHTML,
        });
    }

    return React.createElement(
        elementType || "div",
        {
            style: { ...fontWeigthStyle, ...colorStyle, ...fontSizeStyle },
            className,
        },
        children
    );
};
