// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Title } from "@Components/Basic";
import { Container, TitleContent, Arrow, CollapseContent } from "./styled";
import { ICollapsibleCard } from "./CollapsibleCard";
// #endregion Local Imports

const CollapsibleCard: React.FunctionComponent<ICollapsibleCard.IProps> = ({
    title,
    children,
    titleSize,
    titleColor,
}): JSX.Element => {
    const { useState } = React;
    const [collapse, sestCollapse] = useState(true);

    return (
        <Container>
            <TitleContent onClick={() => sestCollapse(!collapse)}>
                {title && (
                    <Title fontWeight="bold" size={titleSize || "large"} color={titleColor}>
                        {title}
                    </Title>
                )}
                <Arrow className="icon right-arrow" isCollapse={collapse} />
            </TitleContent>
            <CollapseContent collapse={collapse}>{children}</CollapseContent>
        </Container>
    );
};

export { CollapsibleCard };
