// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

import { Colors } from "@Interfaces/styled";

export const HintContainer = styled.div<{ backgroundColor: keyof Colors }>`
    display: flex;
    flex-direction: column;
    min-height: 182px;
    min-width: 125px;
    height: 100%;
    background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    letter-spacing: 0;
    font-size: ${({ theme }) => theme.smallTagFontSizes.medium};
    padding: 0px 5px 20px 5px;

    svg {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 36px;
    }
`;

export const Header = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 18px;
    margin-bottom: 8px;
`;