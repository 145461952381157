import * as React from "react";

const SvgAppleStoreMin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 14 18" {...props}>
        <path
            d="M9.792 4.704l.24.014a3.862 3.862 0 013.042 1.645 3.773 3.773 0 00-1.797 3.165 3.645 3.645 0 002.226 3.353 8.356 8.356 0 01-1.158 2.358c-.682 1.02-1.396 2.016-2.531 2.035-1.102.025-1.473-.65-2.737-.65-1.277 0-1.672.631-2.73.675-1.08.04-1.905-1.089-2.612-2.099C.323 13.137-.777 9.385.697 6.833a4.052 4.052 0 013.41-2.08c1.082-.022 2.086.73 2.75.73.653 0 1.894-.9 3.174-.765zM10.045.7a3.714 3.714 0 01-.85 2.66 3.125 3.125 0 01-2.467 1.168 3.534 3.534 0 01.871-2.563A3.78 3.78 0 0110.045.7z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgAppleStoreMin;
