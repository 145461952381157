// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
`;
