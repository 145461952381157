import * as React from "react";

const SvgGeminiIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 13 15" {...props}>
        <path
            d="M12.165 2.06c-.52.262-1.083.48-1.69.655v10.44c.607.175 1.17.393 1.69.611v1.18c-.433-.219-.91-.393-1.386-.568a17.982 17.982 0 00-1.473-.35c-.52-.087-1.04-.174-1.56-.218-.52-.044-1.04-.044-1.516-.044-.477 0-.997 0-1.473.044-.477.044-1.04.088-1.517.219-.476.13-.996.218-1.516.349-.52.131-.953.35-1.386.568v-1.18c.26-.13.52-.262.823-.349.26-.087.563-.218.866-.306V2.671a9.724 9.724 0 01-1.69-.654V.837c.434.219.867.393 1.387.568.52.175.997.262 1.516.35.52.087 1.04.174 1.517.218.476.044.996.044 1.473.044.476 0 .996 0 1.516-.044s1.04-.087 1.56-.219c.52-.087.996-.218 1.473-.349.476-.131.953-.35 1.386-.568V2.06h0zM6.273 12.587c.52 0 .996 0 1.473.044.477.043.953.087 1.386.175V2.978c-.866.174-1.82.218-2.859.218-.52 0-.996 0-1.473-.044-.476-.043-.91-.087-1.386-.174v9.828c.433-.088.91-.132 1.386-.175.477-.044.953-.044 1.473-.044h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgGeminiIcon;
