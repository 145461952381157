// #region Global Imports
import { useContext } from "react";

import useWindowSize from "react-use/lib/useWindowSize";
// #endregion Global Imports

// #region Local Imports
import { theme, DeviceContext } from "@Definitions";
// #endregion Local Imports

export const useScreenSize = () => {
    const [large, medium, small, xSmall] = Object.values(theme.maxWidth).map(width =>
        parseInt(width.split("px")[0], 10)
    );

    const windowWidth = useWindowSize().width;
    const deviceType = useContext(DeviceContext);

    let width;
    if (windowWidth === Infinity) {
        switch (deviceType) {
            case "mobile":
                width = 375;
                break;
            case "tablet":
                width = 991;
                break;
            default:
                width = 1200;
        }
    } else {
        width = windowWidth;
    }

    if (width === Infinity) return "";
    if (width <= xSmall) return "xsmall";
    if (width <= small) return "small";
    if (width <= medium) return "medium";
    if (width <= large) return "large";

    return "xlarge";
};
