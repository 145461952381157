// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
    background-image: ${({ theme }) => theme.gradients.pinkToBlue};
    border-radius: 40px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);

    .right-border {
        border-right: 1px solid white;

        @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
            border-right: none;
        }
    }

    .text-shrink {
        @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            font-size: 14px;
        }
    }

    .custom-padding {
        @media screen and (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            padding: 0 !important;
        }
    }
`;

export const UserInfo = styled.div`
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: none;
    }
`;

export const AvatarStyle = css`
    width: 72px;
    height: 72px;
`;
