// #region Global Imports
import styled from "styled-components";
import { ISmall } from "./Small";

// #endregion Global Imports

export const Container = styled.small<ISmall.IProps>`
    font-weight: ${({ theme, fontWeight }) =>
        fontWeight ? theme.fontWeights[fontWeight] : theme.fontWeights.regular};
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.darkGray)};
    font-size: ${({ size, theme }) =>
        size ? theme.smallTagFontSizes[size] : theme.smallTagFontSizes.medium};
`;
