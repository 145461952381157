import * as React from "react";

const SvgPhoneCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm-6.205 6.496l.127.093.146.13 3.951 3.955.09.105a1.529 1.529 0 01-.14 2.017l-.433.415-.282.279-.21.214-.191.207-.024.023-.007.001-.002.01a.75.75 0 01-.47.436.712.712 0 01-.091.124l-.057.056c-.141.126-.035.435.578 1.288l.239.324.031.038c.866 1.132 1.838 2.071 3.373 3.37l.555.436c.56.43.934.678 1.233.818.25.117.344.13.391.11l.018-.01.078-.072a.747.747 0 01.278-.161l.045-.068.054-.065 1.349-1.433c.532-.532 1.27-.62 1.871-.223l.127.092.146.131 3.785 3.788.09.105c.368.482.428 1.167.076 1.744l-.09.13-.095.111L27 27.431a.746.746 0 01-.317.2.722.722 0 01-.115.15c-.801.8-2.59 1.201-4.644.799-2.745-.539-5.836-2.392-9.17-5.803-5.713-5.781-6.623-10.816-4.63-13.252a.746.746 0 01.354-.24l.043-.068.054-.064L9.924 7.72c.532-.533 1.27-.62 1.871-.224zm-2.888 3.768c-.174.62-.225 1.467-.048 2.446.415 2.295 1.971 4.986 4.965 8.016 3.138 3.21 5.968 4.907 8.39 5.382.916.18 1.73.161 2.335.035l.175-.041.081-.024-3.7-3.7-.028.014c-.748.297-1.565-.084-2.968-1.153l-.418-.324-.221-.176c-1.511-1.278-2.512-2.232-3.382-3.324l-.234-.298-.294-.4c-.83-1.152-1.175-1.888-.91-2.55l.049-.111-3.792-3.792zm14.587 9.922l-.829.881 3.773 3.773.822-.873c-.001.001-.002-.008.004-.005l.017.015-.024-.03-3.72-3.723-.043-.038zM10.994 8.77l-.829.88 3.904 3.904.418-.415.437-.42s.001-.009.008-.006l.016.014-.024-.03-3.888-3.89-.042-.037z"
            fill="currentcolor"
            fillRule="nonzero"
            stroke="currentcolor"
            strokeWidth={0.3}
        />
    </svg>
);

export default SvgPhoneCircle;
