import * as React from "react";

const SvgCapricornIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 17 17" {...props}>
        <path
            d="M.395 1.095H.48c.607 0 1.127.13 1.56.393.433.262.823.611 1.127 1.092.303.48.52 1.004.65 1.66.13.655.216 1.354.216 2.096v.219a27.01 27.01 0 01.65-1.791c.217-.524.433-1.005.65-1.485.217-.437.477-.874.693-1.223.26-.394.477-.743.78-1.092h.91c.65.349 1.126.83 1.473 1.485.347.655.477 1.485.477 2.49v4.455c0 .48.043.917.13 1.354.086.393.173.742.346 1.092.217-.306.434-.612.694-.917.26-.306.476-.525.78-.787.26-.218.563-.393.866-.524.303-.13.65-.174 1.04-.174s.736.043 1.04.174c.303.131.606.306.823.524.217.219.39.48.52.787.13.305.216.655.216 1.048s-.086.786-.216 1.136c-.173.35-.39.655-.65.917-.303.262-.65.437-1.04.611a4.91 4.91 0 01-1.43.219c-.216 0-.39 0-.606-.044l-.65-.13a2.033 2.033 0 01-.65-.263 2.757 2.757 0 01-.607-.35l-.78 1.049c-.26.35-.606.655-.953.917-.346.262-.736.48-1.17.655-.433.175-.91.219-1.43.219-.26 0-.476 0-.65-.044v-1.18c.087 0 .217.044.304.044h.303c.39 0 .737-.087 1.083-.218.347-.131.607-.35.91-.568.26-.218.52-.524.78-.83.26-.305.477-.611.737-.96-.304-.394-.52-.874-.694-1.442-.173-.568-.26-1.223-.26-2.01V5.158c0-.35 0-.655-.043-.917-.043-.306-.086-.568-.216-.787-.087-.262-.217-.436-.347-.655-.13-.175-.303-.306-.52-.437A8.521 8.521 0 006.46 3.76c-.26.524-.563 1.18-.823 1.835-.26.699-.52 1.441-.736 2.271-.217.83-.434 1.704-.65 2.62v2.578H2.864V7.341c0-.699-.043-1.31-.086-1.878-.087-.568-.174-1.049-.347-1.442a2.604 2.604 0 00-.693-.96c-.304-.263-.65-.394-1.127-.481V1.095H.395zm12.955 9.478c-.26 0-.477.044-.694.175-.216.131-.433.262-.606.48-.217.219-.39.437-.564.7-.173.261-.39.567-.563.83.477.392 1.083.61 1.82.61.26 0 .52-.043.78-.087.216-.087.433-.174.606-.305.174-.131.304-.306.39-.48.087-.176.13-.394.13-.656 0-.35-.13-.655-.346-.917-.217-.262-.52-.35-.953-.35h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCapricornIcon;
