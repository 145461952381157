// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text } from "@Components/Basic";
import { theme } from "@Definitions";
import { ChevronRight } from "@Icons";
import { Container } from "./styled";
import { Line } from "../../styled";
// #endregion Local Imports

// #region Interface Imports
import { IMenuItem } from "./MenuItem";
// #endregion Interface Imports

export const MenuItem: React.FunctionComponent<IMenuItem.IProps> = ({ isActive, text }) => {
    return (
        <>
            <Container
                isActive={isActive}
                className="d-flex align-items-center justify-content-between pt-2 pb-2 pr-1 pl-1 mt-1 mb-1"
            >
                <Text size="large" color={isActive ? "azureRadiance" : "gray"}>
                    {text}
                </Text>
                <ChevronRight 
                    width="20px"
                    height="36px"
                    color={isActive ? theme.colors.azureRadiance : theme.colors.gray}
                />
            </Container>
            <Line />
        </>
    );
};
