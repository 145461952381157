// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Button, Text } from "@Components/Basic";
import { AppleIcon } from "@Icons";
import { IAppleLoginButton } from "./AppleLoginButton";
import { AppleService } from "@Services";
import { AccountActions } from "@Actions";
// #endregion Local Imports

export const AppleLoginButton: React.FC<IAppleLoginButton.IProps> = props => {
    const { t } = props;
    const dispatch = useDispatch();

    const handleButtonClick = async () => {
        const response = await AppleService.request();

        const { code, state } = response.authorization;
        dispatch(
            AccountActions.LoginWithApple({
                accessToken: code,
                state,
            })
        );
    };

    return (
        <Button
            {...props}
            id="appleid-signin"
            styleType="apple"
            icon={<AppleIcon width="24" height="24" />}
            iconPosition="right"
            onClick={handleButtonClick}
        >
            <div style={{ padding: "0 1.2rem" }}>
                <Text>{t("common:Apple ile bağlan")}</Text>
            </div>
        </Button>
    );
};
