// #region Global Imports
import { Dispatch } from "redux";
// #region Global Imports

// #region Local Imports
import { IPusherComponent } from "@Components/PusherComponent/PusherComponent";
import { ActionConsts, PusherSerializer } from "@Definitions";
import { Analytics, ConversationService, StickerService } from "@Services";
import { IStore, ConversationModel } from "@Interfaces";
import { DashboardActions } from "@Actions";
// #region Local Imports

export const InboxActions = {
    FetchPrioritized: async () => async (dispatch: Dispatch) => {
        try {
            dispatch({ payload: { loading: true }, type: ActionConsts.Inbox.SetLoading });

            const data = await ConversationService.GetPrioritized();

            dispatch({ type: ActionConsts.Inbox.FetchPrioritized, payload: data.Conversations });
        } catch (e) {
            console.error("Error fetching prioritized messages", e);
        }
    },
    FetchRequests: async (fetchMore?: boolean) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        let lastDate: string | undefined;
        const { requests } = getState().inbox;

        try {
            dispatch({ payload: { loading: true }, type: ActionConsts.Inbox.SetLoading });

            if (fetchMore && requests?.length) {
                lastDate = requests[requests.length - 1].LastMessage.Date;
            }

            const data = await ConversationService.GetRequests(lastDate);

            if (fetchMore) {
                dispatch({
                    type: ActionConsts.Inbox.FetchRequests,
                    payload: [...(requests || []), ...data.Conversations],
                });
            } else {
                dispatch({ type: ActionConsts.Inbox.FetchRequests, payload: data.Conversations });
                dispatch({ type: ActionConsts.Inbox.ClearRequests });
            }
        } catch (e) {
            console.error("Error fetching message requests", e);
        }
    },
    FetchReplied: async (getMore: boolean) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            let fromDate;
            let storedData: ConversationModel.ConversationPrev[] = [];

            dispatch({ payload: { loading: true }, type: ActionConsts.Inbox.SetLoading });

            if (getMore) {
                const stored = getState().inbox.repliedMessages;

                if (stored && stored.length) {
                    storedData = [...stored];
                    fromDate = stored[stored.length - 1].LastMessage.Date;
                }
            }

            const newData = await ConversationService.GetReplied(fromDate);
            const repliedMessagesHasMore = newData.Count > 20;

            dispatch({
                type: ActionConsts.Inbox.FetchReplied,
                payload: {
                    repliedMessages: [...storedData, ...newData.Conversations],
                    repliedMessagesHasMore,
                    loading: false,
                },
            });
        } catch (e) {
            console.error("Error fetching message requests", e);
        }
    },
    ArchiveMessage: async (userId: string, type: ConversationModel.ChatTypes) => async (
        dispatch: Function,
        getState: () => IStore
    ) => {
        try {
            await ConversationService.ArchiveMessage(userId);
            await ConversationService.MarkAsRead(userId);
            dispatch(await DashboardActions.GetNotification());

            switch (type) {
                case "prioritized":
                    {
                        const { prioritized } = getState().inbox;

                        dispatch({
                            type: ActionConsts.Inbox.FetchPrioritized,
                            payload: prioritized?.filter(c => c.With.Id !== userId),
                        });
                    }
                    break;
                case "replied":
                    {
                        const { replied } = getState().inbox;

                        dispatch({
                            type: ActionConsts.Inbox.FetchReplied,
                            payload: replied?.filter(c => c.With.Id !== userId),
                        });
                    }
                    break;
                case "requests":
                    {
                        const { requests } = getState().inbox;

                        dispatch({
                            type: ActionConsts.Inbox.FetchRequests,
                            payload: requests?.filter(c => c.With.Id !== userId),
                        });
                    }
                    break;
                case "messages":
                    {
                        const { chatPreviews } = getState().inbox;

                        const newChatPreviews = chatPreviews?.filter(c => c.With.Id !== userId);
                        dispatch({
                            type: ActionConsts.Inbox.FetchChatPreviews,
                            payload: { chatPreviews: newChatPreviews || [] },
                        });

                        if (newChatPreviews?.length) {
                            dispatch(await InboxActions.FetchChat(newChatPreviews[0].With.Id, 50));
                        } else {
                            dispatch({ type: ActionConsts.Inbox.FetchChat, payload: undefined });
                        }
                    }
                    break;

                default:
                    return;
            }
        } catch (e) {
            console.error("Error marking conversation as read", e);
        }
    },
    FetchChatPreviews: async (getMore: boolean) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            let fromDate;
            let storedData: ConversationModel.ConversationPrev[] = [];

            dispatch({ payload: { loading: true }, type: ActionConsts.Inbox.SetLoading });

            if (getMore) {
                const stored = getState().inbox.chatPreviews;

                if (stored && stored.length) {
                    storedData = [...stored];
                    fromDate = stored[stored.length - 1].LastMessage.Date;
                }
            }

            const newData = await ConversationService.GetChats(fromDate);
            const hasMore = newData.Count > 20;

            dispatch({
                type: ActionConsts.Inbox.FetchChatPreviews,
                payload: {
                    chatPreviews: [...storedData, ...newData.Conversations],
                    chatPreviewsHasMore: hasMore,
                    loading: false,
                },
            });
        } catch (e) {
            console.error("Error fetching message requests", e);
        }
    },
    FetchChat: async (touserid: string, count: number, fromDate?: string) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        try {
            dispatch({ payload: { traffic: true }, type: ActionConsts.Inbox.SetTraffic });

            const data = await ConversationService.GetMessageList(touserid, count, fromDate);

            const Messages = fromDate
                ? data.Messages.reverse().concat(getState().inbox.chat?.Messages || [])
                : data.Messages.reverse();

            dispatch({
                type: ActionConsts.Inbox.FetchChat,
                payload: { ...data, Messages },
            });

            dispatch({ payload: { traffic: false }, type: ActionConsts.Inbox.SetTraffic });
        } catch (e) {
            console.error("Error fetching message requests", e);
        }
    },
    Filter: async (payload: ConversationModel.IFilterRequest) => async (dispatch: Dispatch) => {
        try {
            const data = await ConversationService.Filter(payload);

            dispatch({ type: ActionConsts.Inbox.FetchRequests, payload: data.Conversations });
        } catch (e) {
            console.error("Error fetching filter inbox messages requests", e);
        }
    },
    MarkAsRead: async (userId: string) => async (dispatch: Function) => {
        try {
            await ConversationService.MarkAsRead(userId);

            dispatch(await InboxActions.UpdateChatPreview(userId, { HasNew: false }));
        } catch (e) {
            console.error("Error marking conversation as read with id", userId);
        }
    },
    UpdatePreviews: async (
        userId: string,
        payload: object,
        type: "chatPreviews" | "repliedMessages"
    ) => async (dispatch: Function, getState: () => IStore) => {
        const existingPreviews = [...(getState().inbox[type] || [])];
        const sentMessagePreviewIndex = existingPreviews?.findIndex(
            prev => prev.With.Id === userId
        );
        const sentMessagePreview = existingPreviews[sentMessagePreviewIndex];

        const newMessagePreview = {
            ...sentMessagePreview,
            ...payload,
        };

        existingPreviews[sentMessagePreviewIndex] = newMessagePreview;

        const actionType = type === "chatPreviews" ? "FetchChatPreviews" : "FetchReplied";
        dispatch({
            type: ActionConsts.Inbox[actionType],
            payload: { [type]: existingPreviews },
        });
    },
    UpdateRepliedMessages: async (userId: string, payload: object) => async (
        dispatch: Function
    ) => {
        dispatch(await InboxActions.UpdatePreviews(userId, payload, "repliedMessages"));
    },
    UpdateChatPreview: async (userId: string, payload: object) => async (dispatch: Function) => {
        dispatch(await InboxActions.UpdatePreviews(userId, payload, "chatPreviews"));
    },
    SendMessage: async (payload: ConversationModel.ISendMessagePayload) => async (
        dispatch: Function,
        getState: () => IStore
    ) => {
        const response = await ConversationService.SendMessage(payload);

        dispatch({
            type: ActionConsts.Inbox.SetMessageStatus,
            // Add timestamp to trigger useEffect
            payload: {
                timestamp: new Date().toISOString(),
                status: response.Reason,
                coin: response.Coin,
            },
        });

        if (response.Reason === "Success" || response.Reason === "InReview") {
            const selfUser = getState().profile.self;

            const existingChat = getState().inbox.chat;
            const isp = existingChat?.IsPrioritized || !!getState().inbox.chat?.prioritizedState;

            if (payload.ConfirmPrioritized) {
                Analytics.event("Spent Coin", { sa_feature: "featured message" });
            }

            const alreadyStartedConversation = existingChat?.Messages.filter(
                message => message.SenderId === selfUser?.Id
            ).length;

            if (!alreadyStartedConversation) {
                Analytics.event(
                    "Joined Conversation",
                    {
                        sa_target_gender: selfUser?.Gender === 1 ? "female" : "male",
                    },
                    true
                );
            }

            const appendedChat = {
                ...existingChat,
                IsPrioritized: isp,
                Messages: [
                    ...(existingChat?.Messages || []),
                    {
                        Date: new Date(),
                        Ip: "",
                        IsRead: false,
                        MessageId: Math.random(),
                        SenderId: existingChat?.User.Id,
                        SenderName: existingChat?.User.Username,
                        Text: payload.Message,
                        Message: payload.Message,
                        Type: payload.Type,
                        Sticker: payload.Sticker,
                    },
                ],
                HasNew: true,
            };

            dispatch({
                type: ActionConsts.Inbox.FetchChat,
                payload: appendedChat,
            });

            dispatch(
                await InboxActions.UpdateChatPreview(payload.ToUserId, {
                    LastMessage: {
                        Type: payload.Type || 1,
                        Text: payload.Message,
                        Date: new Date().toUTCString(),
                        IsRead: false,
                    },
                })
            );

            dispatch(
                await InboxActions.UpdateRepliedMessages(payload.ToUserId, {
                    LastMessage: {
                        Type: payload.Type || 1,
                        Text: payload.Message,
                        Date: new Date().toUTCString(),
                        IsRead: false,
                    },
                })
            );
        } else {
            console.log("Message reason not success...");
        }
    },
    BanUser: async (withUserId: string, archiveType: ConversationModel.ChatTypes) => async (
        dispatch: Function
    ) => {
        await ConversationService.Ban(withUserId);
        dispatch(await InboxActions.ArchiveMessage(withUserId, archiveType));
    },
    PushNewRequests: async (messageData: IPusherComponent.MessageSendData) => async (
        dispatch: Function,
        getState: () => IStore
    ) => {
        const message = PusherSerializer.MessageToRequest(messageData);
        const state = getState().inbox;

        const existingRequests = state.newRequests || [];
        const existingRequestIndex = existingRequests.findIndex(
            req => req.With.Id === message.With.Id
        );

        if (existingRequestIndex === -1) {
            // Existing conversation not found, push to the beginning
            console.log("Can't found existing request");
            dispatch({
                type: ActionConsts.Inbox.PushNewRequests,
                payload: [...existingRequests, message],
            });
        }

        if (existingRequestIndex > -1) {
            // Existing conversation found, replace with older message
            console.log("Found existing request");
            existingRequests[existingRequestIndex] = message;

            dispatch({ type: ActionConsts.Inbox.PushNewRequests, payload: existingRequests });
        }
    },
    SyncRequests: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const state = getState().inbox;

        const existingConversations = state.requests || [];
        const newRequests = state.newRequests?.reverse() || [];
        const existingIndexes: number[] = [];

        newRequests.forEach((req, index) => {
            const existingConvIndex = existingConversations.findIndex(
                conv => conv.With.Id === req.With.Id
            );

            console.log(
                "This user just sent new message and already in conversations",
                existingConvIndex,
                req
            );

            if (existingConvIndex > -1) {
                existingConversations[existingConvIndex] = req;
            } else {
                existingConversations.push(req);
            }

            existingIndexes.push(index);
        });

        const remainingRequests = newRequests.filter(
            (_req, index) => !existingIndexes.includes(index)
        );

        const finalRequests = [...remainingRequests, ...existingConversations].sort((req, next) =>
            new Date(req.LastMessage.Date) < new Date(next.LastMessage.Date) ? 1 : -1
        );

        dispatch({ type: ActionConsts.Inbox.SyncRequests, payload: finalRequests });
    },
    FetchNewMessage: async (data: IPusherComponent.MessageSendData) => (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        if (data.IsRequest) return;
        const message = data;
        const state = getState().inbox;
        let chatState;
        let chatPreviews;

        const formattedMessage = PusherSerializer.MessageToMessage(message);

        if (state.chat?.With.Id === message.From) {
            chatState = {
                ...state.chat,
                HasNew: true,
                Messages: [...(state.chat?.Messages || []), formattedMessage],
            };
        }

        if (state.chatPreviews?.length) {
            const previewIndex = state.chatPreviews.findIndex(c => c.With.Id === message.From);

            if (previewIndex > -1) {
                const existing = state.chatPreviews[previewIndex];

                const newPreview = {
                    ...existing,
                    LastMessage: {
                        ...existing.LastMessage,
                        ...formattedMessage,
                    },
                    HasNew: true,
                };

                const newArr = [...state.chatPreviews];
                newArr[previewIndex] = newPreview;

                chatPreviews = newArr;
            } else {
                const newPreview = {
                    With: PusherSerializer.MessageToProfile(data),
                    LastMessage: formattedMessage,
                    HasNew: true,
                    IsPrioritized: data.IsPrioritized,
                };

                chatPreviews = [newPreview, ...state.chatPreviews];
            }
        } else {
            const profile = PusherSerializer.MessageToProfile(data);

            chatPreviews = [
                {
                    With: profile,
                    LastMessage: {
                        ...formattedMessage,
                    },
                    HasNew: true,
                },
            ];

            chatState = {
                With: profile,
                User: {
                    Id: message.To,
                },
                HasNew: true,
                Messages: [...(state.chat?.Messages || []), formattedMessage],
                Success: true,
                Count: 1,
                IsRequest: false,
            };
        }

        dispatch({
            type: ActionConsts.Inbox.FetchMessage,
            payload: {
                chatPreviews: chatPreviews || state.chatPreviews,
                chat: chatState || state.chat,
            },
        });
    },
    FetchMessageRead: async (userId: string) => async (
        dispatch: Dispatch,
        getState: () => IStore
    ) => {
        const existingChat = getState().inbox.chat;
        const existingPreviews = getState().inbox.chatPreviews;
        const existingReplied = getState().inbox.repliedMessages;

        if (existingChat) {
            const existingChatUserId = existingChat.With.Id;

            if (existingChatUserId === userId) {
                console.log("Existing chat matches with data");
                dispatch({
                    type: ActionConsts.Inbox.FetchChat,
                    payload: {
                        ...existingChat,
                        Messages: existingChat.Messages.map(msg => ({ ...msg, IsRead: true })),
                    },
                });
            }
        }

        if (existingPreviews) {
            dispatch({
                type: ActionConsts.Inbox.FetchChatPreviews,
                payload: {
                    chatPreviews: [
                        ...existingPreviews?.map(msg => ({
                            ...msg,
                            LastMessage: { ...msg.LastMessage, IsRead: true },
                        })),
                    ],
                },
            });
        }

        if (existingReplied) {
            dispatch({
                type: ActionConsts.Inbox.FetchReplied,
                payload: {
                    repliedMessages: [
                        ...existingReplied?.map(msg => ({
                            ...msg,
                            LastMessage: { ...msg.LastMessage, IsRead: true },
                        })),
                    ],
                },
            });
        }

        return null;
    },
    BulkMarkAsRead: async (ids: string[]) => async (dispatch: Dispatch, getState: () => IStore) => {
        if (!ids.length) return;

        const stringIds = ids.join(",");

        const result = await ConversationService.MarkAsRead(stringIds);

        console.log("Bulk mark as read response: ", result);

        const { requests } = getState().inbox;

        if (!requests) return;

        dispatch({
            type: ActionConsts.Inbox.FetchRequests,
            payload: requests.map(req => {
                return ids.includes(req.With.Id) ? { ...req, HasNew: false } : req;
            }),
        });
    },
    FetchStickers: async () => async (dispatch: Dispatch) => {
        try {
            const result = await StickerService.Get();

            dispatch({ type: ActionConsts.Inbox.FetchStickers, payload: result });
        } catch (e) {
            console.error("Error fetching stickers", e);
        }
    },
    ShowChatModal: async (touserId: string, count: number) => async (dispatch: Function) => {
        dispatch({
            type: ActionConsts.Common.SetChatModal,
            payload: true,
        });
        try {
            dispatch(await InboxActions.FetchChat(touserId, count));
        } catch (e) {
            console.error("Error fetching chat");
        }
    },
};
