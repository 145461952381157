import * as React from "react";

const SvgWomanDefault = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18 0 5.806-2.748 10.97-7.015 14.26-2.32 1.987-5.7 3.327-9.262 3.66l-.109.009-.226.019-.095.006a15.369 15.369 0 01-.562.031c-.192.008-.386.013-.58.014L18 36h-.075H18a18.315 18.315 0 01-1.293-.045l-.095-.008-.226-.018-.11-.01c-3.561-.332-6.942-1.672-9.261-3.658C2.748 28.969 0 23.806 0 18 0 8.059 8.059 0 18 0zm-4.148 29.235l-6.926 1.568c.22.23.455.453.704.668a16.909 16.909 0 008.545 3.432l.438.041.13.01.062.004.34.02A17.492 17.492 0 0018 35l.3-.003h.03l.398-.012.398-.022c.087-.005.175-.011.262-.018l-.155.01a16.915 16.915 0 009.138-3.484c.165-.143.325-.29.478-.44l.225-.228-6.927-1.566c-2 2.159-6.297 2.159-8.295-.002zm4.622 5.759l-.145.003.145-.003zm-1.708-.038l.108.007-.069-.005-.039-.002zM18 1C8.611 1 1 8.611 1 18c0 4.683 1.893 8.923 4.956 11.998l5.548-1.255C9.302 26.299 8 22.717 8 18.856c0-6.795 4.102-11 10-11 1.183 0 1.775.035 2.515.208 1.377.322 2.489 1.07 3.327 2.394C26.916 11.79 28 14.023 28 18.856c0 3.86-1.302 7.442-3.504 9.887l5.548 1.255A16.946 16.946 0 0035 18c0-9.389-7.611-17-17-17zm2.001 25.393a4.693 4.693 0 01-2 .463c-.692 0-1.365-.163-2-.46l-.001.46c0 .827-.43 1.573-1.097 1.999 1.608 1.334 4.586 1.334 6.195 0a2.374 2.374 0 01-1.092-1.825L20 26.856l.001-.463zm-2-17.537c-5.34 0-9.001 3.752-9.001 10 0 3.82 1.353 7.33 3.589 9.578l.05.05 1.288-.291a1.372 1.372 0 001.066-1.201l.007-.136v-1.059c-1.604-1.184-2.844-3.247-3.31-5.602a.5.5 0 01.059-.348 12.988 12.988 0 0110.836-6.48.5.5 0 01.422.207c.96 1.337 1.493 3.014 1.493 4.782 0 2.986-1.446 5.92-3.5 7.437v1.063c0 .595.386 1.118.943 1.301l.132.036 1.285.293c2.266-2.248 3.64-5.782 3.64-9.63 0-4.544-.928-6.39-3.692-7.539a.5.5 0 01-.237-.204c-.703-1.172-1.614-1.802-2.784-2.075-.642-.15-1.178-.182-2.287-.182zm4.344 5.521a11.988 11.988 0 00-9.635 5.809c.694 3.18 2.94 5.67 5.29 5.67 2.858 0 5.5-3.69 5.5-7.5 0-1.464-.412-2.85-1.155-3.979z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgWomanDefault;
