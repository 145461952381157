import * as React from "react";

const SvgLibraIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 15 13" {...props}>
        <path
            d="M14.295 8.94H8.327v-1.1c.303-.089.562-.221.778-.441.217-.177.433-.397.606-.661s.303-.529.389-.837c.086-.309.13-.617.13-.925 0-.397-.087-.793-.216-1.102-.13-.352-.303-.66-.563-.88a3.107 3.107 0 00-.865-.617 2.963 2.963 0 00-1.08-.22c-.39 0-.779.087-1.125.22-.346.132-.605.352-.865.616a2.933 2.933 0 00-.778 1.982c0 .353.043.662.13.926.086.308.216.573.389.837.173.264.389.484.605.66.26.177.52.31.779.441v1.102H.5V7.663h3.892a2.982 2.982 0 01-.778-1.145c-.217-.44-.303-.97-.303-1.498 0-.573.086-1.102.346-1.63.216-.485.519-.97.865-1.322.389-.396.821-.66 1.297-.88.476-.221 1.038-.31 1.6-.31s1.081.089 1.6.31c.519.22.908.528 1.297.88.39.397.649.837.865 1.322.216.528.346 1.057.346 1.63 0 .528-.086 1.057-.303 1.498a3.77 3.77 0 01-.778 1.145h3.762v1.278h.087zm0 3.965H.63v-1.277h13.665v1.277h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgLibraIcon;
