// #region Global Imports
import styled, { CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div<{ customStyle: CSSProp }>`
    padding: 10px;
    margin-top: 15px;
    border-top: 2px solid ${({ theme }) => theme.colors.badgeGreen};
    border-radius: 0 0 8px 8px;
    background-color: white;
    box-shadow: 0 0 20px -10px;
    position: absolute;
    transition: opacity 0.1s ease;
    z-index: 4;
    white-space: nowrap;

    ::before {
        display: block;
        border: 0px solid;
        position: absolute;
        content: "";
        left: 26px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 8px solid ${({ theme }) => theme.colors.badgeGreen};
        background-color: transparent;
        transform: rotateZ(0deg) translateY(-20px);
    }

    ${({ customStyle }) => customStyle && customStyle};
`;
