// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic/Button";
// #endregion Local Imports

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 731px;
    height: 562px;
    background: transparent;
    margin: 350px 0 93px 0;
    gap: 16px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 735px;
        height: 465px;
        margin: 228px 0 30px 0;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 390px;
        height: 684px;
        margin: 162px 0 40px 0;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 510px;
        height: 519px;
        margin: 404px 0 17px 0;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 346px;
        height: 395px;
        position: relative;
        margin: 0;
    }
`;

export const LandingContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        justify-content: center;
        align-items: center;
    }
`;

export const TextContent = styled.div`
    flex-direction: column;
    width: 528px;
    font-family: "Montserrat";
    color: #fff;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 350px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 365px;
        gap: 16px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xxSmall}) {
        width: 350px;
    }
`;

const TitleStyle = css`
    font-size: 48px;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    line-height: 54px;
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        font-size: 42px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        letter-spacing: 0;
        font-size: 32px;
        line-height: 40px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        text-align: center;
    }
`;

export const Title = styled.div`
    ${TitleStyle}
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

export const SubTitle = styled.div`
    ${TitleStyle}
    margin-bottom: 20px;
    display: none;
`;

export const Description = styled.div`
    margin-top: 16px;
    padding-right: 31px;
    width: 495px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 88%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: none;
    }
`;

export const SignUpButton = styled(Button)`
    width: 350px;
    height: 52px;
    padding: 0;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 370px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 330px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 345px;
    }
`;

export const ButtonsContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: column;
        align-self: flex-start;
        justify-content: space-around;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        align-self: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        position: absolute;
        height: 52px;
        bottom: 20px;
    }
`;

export const MemberLoginButton = styled.div`
    display: none;

    button {
        width: 330px;
        height: 52px;

        @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
            width: 370px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            width: 330px;
        }

        @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
            width: 345px;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: flex;
        justify-content: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        position: absolute;
        align-self: center;
        bottom: 92px;
    }
`;

export const Text = styled.div`
    display: flex;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: none;
    }
`;

export const FbSignupButton = styled.div`
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: none;
    }
`;

export const DiscoverContent = styled.div`
    display: flex;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        justify-content: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: none;
    }
`;
