// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Redirect } from "@Definitions/Server";
// #endregion Local Imports

export const useRedirect = (isRedirect: boolean, url: string) => {
    React.useEffect(() => {
        if (isRedirect) {
            Redirect(undefined, url);
        }
    }, []);

    return {};
};
