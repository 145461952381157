// #region Global Imports
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { CoinTransactionType } from "@Interfaces/enums";
import { Text, Button } from "@Components/Basic";
import { StickerAvatar, StickerItem } from "@Components";
import { CoinService } from "@Services";
import { ActionConsts, theme } from "@Definitions";
import { Check, CoinLightIcon } from "@Icons";
import { StickerActions } from "@Actions";
import { Container, IconContainer } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IStore } from "@Interfaces";
import { IStickers } from "./Stickers";
// #endregion Interface Imports

export const Stickers: React.FunctionComponent<IStickers.IProps> = props => {
    const { stickerData, t, onSelect } = props;
    const dispatch = useDispatch();
    const { buyStickerSuccess } = useSelector((state: IStore) => state.common);

    const [activeStickerGroup, setActiveStickerGroup] = useState(1);
    const [showPurchaseConfirm, setShowPurchaseConfirm] = useState(false);
    const [showPurchaseSuccess, setShowPurchaseSuccess] = useState(false);
    const [showStickers, setShowStickers] = useState(true);
    const [insufficientCoinError, SetInsufficientCoinError] = useState(false);

    const getKey = (index: number, keyType: string) => `${keyType}-${index}`;

    useEffect(() => {
        if (buyStickerSuccess) {
            setShowPurchaseSuccess(true);

            setTimeout(() => {
                setShowStickers(true);
                dispatch({
                    payload: false,
                    type: ActionConsts.Common.SetStickerSuccess,
                });
            }, 3000);
        }
    }, [buyStickerSuccess]);

    const confirmPurchase = async () => {
        dispatch(await StickerActions.BuySticker(activeStickerGroup));
        setShowPurchaseConfirm(false);
        setShowStickers(true);
    };

    const onPurchaseButtonClick = async () => {
        const result = await CoinService.CheckCoinCount({
            type: CoinTransactionType.StickerSet,
        });

        if (result.IsEnough) {
            setShowStickers(false);
            setShowPurchaseConfirm(true);
        } else {
            setShowStickers(false);
            SetInsufficientCoinError(true);
        }
    };

    const redirectToBuy = async () => {
        await Router.push("/payments/[type]/credit-card", "/payments/coin/credit-card");
    };

    const stickerAvatars = stickerData.map((item, i) => (
        <StickerAvatar
            key={getKey(i, "stickerAvatar")}
            isActive={activeStickerGroup === i + 1}
            handleClick={() => setActiveStickerGroup(item.Id)}
            purchaseButtonClick={() => onPurchaseButtonClick()}
            isOwned={item.IsOwned}
            activeThumb={item.ActiveThumb}
            passiveThumb={item.PassiveThumb}
        />
    ));

    const stickerItems = stickerData
        .filter(item => item.Id === activeStickerGroup)
        .map((item, i) => (
            <StickerItem
                key={getKey(i, "stickerItem")}
                handleClick={item.IsOwned ? onSelect : onPurchaseButtonClick}
                items={item.Items}
            />
        ));

    const purchaseSuccess = (
        <div className="d-flex flex-column justify-content-center align-items-center pt-10">
            <IconContainer>
                <Check width="47px" height="56px" color={theme.colors.white} className="pt-1" />
            </IconContainer>
            <Text className="text-center" size="xLarge" weight="bold">
                {t("common:inbox.stickers.success")}
            </Text>
        </div>
    );

    const purchaseModal = (text: string, callback: () => void, buttonText: string) => (
        <div className="d-flex flex-column px-7 pt-3 align-items-center">
            <CoinLightIcon width="72px" height="72px" color={theme.colors.mustard} />
            <Text className="text-center pb-3" size="xLarge" weight="bold">
                {text}
            </Text>
            <Button onClick={callback} className="py-1 px-5" size="medium">
                {buttonText}
            </Button>
        </div>
    );

    return (
        <Container>
            {showStickers && (
                <>
                    <div className="d-flex p-2 align-self-start">{stickerAvatars}</div>
                    {stickerItems}
                </>
            )}
            {showPurchaseConfirm &&
                purchaseModal(
                    t("common:inbox.stickers.purchase-text"),
                    confirmPurchase,
                    t("common:inbox.stickers.confirm")
                )}
            {showPurchaseSuccess && purchaseSuccess}
            {insufficientCoinError &&
                purchaseModal(
                    t("common:inbox.stickers.purchase-errorText"),
                    redirectToBuy,
                    t("common:inbox.stickers.buy-credit")
                )}
        </Container>
    );
};
