// #region Global Imports
import React from "react";
import dayjs from "dayjs";
// #endregion Global Imports

// #region Local Imports
import { Text, Button } from "@Components/Basic";
import { ToggleSwitch } from "@Components";
import { MemberContainer, MemberTitle, AutoRenewalButton } from "../../styled";

// #endregion Local Imports

// #region Interface Imports
import { IGoldMember } from "./GoldMember";
// #endregion Interface Imports

export const GoldMember: React.FunctionComponent<IGoldMember.IProps> = ({
    t,
    onClick,
    data,
    autoRenewal,
}) => {
    const { useState } = React;

    const [getAutoRenewal, setAutoRenewal] = useState(data.IsRecurring);

    const handleAutoRenewal = () => {
        setAutoRenewal(!getAutoRenewal);
        autoRenewal(!getAutoRenewal);
    };
    return (
        <>
            {data && (
                <MemberContainer>
                    <div className="d-flex flex-column w-100">
                        <MemberTitle>{t("common:settings.goldMember.title")}</MemberTitle>
                        <div className="d-flex py-2 justify-content-between">
                            <div className="d-flex flex-column w-100">
                                <Text color="darkGray" size="xxLarge">
                                    {t("common:settings.goldMember.text")}
                                </Text>
                                <Text weight="bold" color="darkGray" size="xxLarge">
                                    {dayjs(data.StartDate).format("LL")}
                                    {" / "}
                                    {dayjs(data.EndDate).format("LL")}
                                </Text>
                                <Text color="darkGray" size="xxLarge">
                                    {t("common:settings.goldMember.priceType")}
                                </Text>
                                <Text weight="bold" color="darkGray" size="xxLarge">
                                    {t(`common:settings.goldMember.${data.PaymentMethod}`)}
                                </Text>
                            </div>

                            <div className="d-flex flex-column w-100">
                                <div className="d-flex w-100 justify-content-between">
                                    {data.IsRecurrable && (
                                        <>
                                            <Text weight="bold" color="darkGray" size="xxLarge">
                                                {t("common:settings.goldMember.autoRenewal")}
                                            </Text>
                                            <AutoRenewalButton
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => {
                                                    handleAutoRenewal();
                                                }}
                                                onKeyPress={() => {
                                                    handleAutoRenewal();
                                                }}
                                            >
                                                <ToggleSwitch isOn={getAutoRenewal} />
                                            </AutoRenewalButton>
                                        </>
                                    )}
                                </div>
                                <div className="d-flex py-2 justify-content-center">
                                    <Button styleType="ghost" size="small" onClick={onClick}>
                                        {t("common:settings.goldMember.extandButton")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </MemberContainer>
            )}
        </>
    );
};
