import * as React from "react";

const SvgExclamation = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
        <path
            d="M12.364 0c2.277 0 4.292.526 6.044 1.577a11.679 11.679 0 014.38 4.38c1.05 1.751 1.576 3.766 1.576 6.043s-.526 4.292-1.577 6.044a11.679 11.679 0 01-4.38 4.38C16.657 23.473 14.642 24 12.365 24s-4.292-.526-6.044-1.577a11.679 11.679 0 01-4.38-4.38C.89 16.293.365 14.278.365 12S.89 7.708 1.941 5.956a11.679 11.679 0 014.38-4.38C8.036.597 9.905.073 11.927.008L12.364 0zm0 1c-2.017 0-3.86.48-5.548 1.445A10.7 10.7 0 002.798 6.47C1.843 8.062 1.364 9.899 1.364 12s.48 3.938 1.445 5.548a10.7 10.7 0 004.026 4.018C8.426 22.52 10.263 23 12.364 23s3.938-.48 5.548-1.445a10.7 10.7 0 004.018-4.026c.955-1.591 1.434-3.428 1.434-5.529s-.48-3.938-1.445-5.548a10.7 10.7 0 00-4.026-4.018C16.302 1.48 14.465 1 12.364 1zm1.642 15c.194 0 .29.097.387.193l.048.049a.455.455 0 01.145.338h0v3.285a.738.738 0 01-.193.483c-.193.096-.29.096-.387.096h0-3.285c-.096 0-.29-.096-.386-.193-.097-.096-.193-.193-.193-.386h0V16.58c0-.097.096-.29.193-.387.097-.096.193-.193.386-.193h0zm.057-11.556c.175 0 .262 0 .349.09.087.09.174.18.174.269h0l-.261 9.95c0 .09-.087.179-.175.268-.087.09-.261.09-.348.09h0-2.876c-.174 0-.261 0-.349-.09-.087-.09-.174-.179-.174-.268h0l-.261-9.95c0-.09.087-.18.174-.269 0-.09.174-.09.349-.09z"
            fill="currentcolor"
            fillRule="nonzero"
            stroke="currentcolor"
            strokeWidth={0.3}
        />
    </svg>
);

export default SvgExclamation;
