// #region Global Imports
import React from "react";
import { Picker } from "emoji-mart";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic";
import { EmojiIcon } from "@Icons";
import { Container, StyledIcon, Modal, Overlay } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IEmoji } from "./Emoji";
// #endregion Interface Imports

// import emoji-mart css
import "emoji-mart/css/emoji-mart.css";

export const Emoji: React.FunctionComponent<IEmoji.IProps> = ({
    isOpen,
    onClick,
    onClickOverlay,
    ...otherProps
}) => {
    const i18n = {
        search: "Arama",
        clear: "Temizle",
        notfound: "Bulunamadı",
        skintext: "Varsayılan cilt tonunuzu seçin",
        categories: {
            search: "Arama Sonuçları",
            recent: "Sık Kullanılan",
            people: "Suratlar ve İnsanlar",
            nature: "Hayvanlar ve Doğa",
            foods: "Yiyecek içecek",
            activity: "Aktivite",
            places: "Seyahat ve Yerler",
            objects: "Nesneler",
            symbols: "Semboller",
            flags: "Bayraklar",
            custom: "Tercih Edilen",
        },
        categorieslabel: "Kategoriler",
        skintones: {
            1: "Varsayılan Cilt Tonu",
            2: "Açık Cilt Tonu",
            3: "Normal-Açık Cilt Tonu",
            4: "Normal Cilt Tonu",
            5: "Normal-Koyu Cilt Tonu",
            6: "Koyu Cilt Tonu",
        },
    };

    return (
        <Container className="mr-2">
            <IconButton
                renderIcon={() => (
                    <StyledIcon>
                        <EmojiIcon width="28px" height="28px" />
                    </StyledIcon>
                )}
                onIconClick={onClick}
            />
            {isOpen && (
                <>
                    <Overlay onClick={onClickOverlay} />
                    <Modal>
                        <Picker
                            native
                            perLine={8}
                            showPreview={false}
                            showSkinTones={false}
                            darkMode={false}
                            i18n={i18n}
                            {...otherProps}
                        />
                    </Modal>
                </>
            )}
        </Container>
    );
};
