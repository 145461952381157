// #region Global Imports
import styled, { keyframes, css, CSSProp } from "styled-components";
// #endregion Global Imports

const heightFromAnimation = keyframes`
    from {
        height: 85px;

    }
    to {
        height: 365px;
    }
`;

const heightAnimation = keyframes`
    from {
        height: 190px;
    }
    to {
        height: 85px;
    }
`;

export const Container = styled.div<{ img: string }>`
    display: flex;
    width: 100%;
    background-image: url(${({ img }) => img});
    object-fit: cover;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 13px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const DesktopContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: "Montserrat";
`;
export const MobileContainer = styled.div<{ isSticky: boolean }>`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    ${({ isSticky }) =>
        isSticky &&
        css`
            height: 175px;
        `};
`;
export const Sticky = styled.div<{ isFemale: boolean }>`
    position: fixed;
    top: 2px;
    background-color: white;
    z-index: 1;
    display: flex;
    max-height: 70px;
    width: 290px;
    align-items: center;
    animation: ${heightAnimation} 0.5s ease 0s 1 forwards;
    background-image: ${({ theme, isFemale }) =>
        isFemale ? theme.gradients.purple : theme.gradients.estorilToBlue};
    box-shadow: 0px -2px white;

    border-radius: 14px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: -webkit-fill-available;
        margin-right: 35px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.small}) {
        width: 510px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        width: 390px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        width: 610px;
    }
    @media (min-width: ${({ theme }) => theme.minWidth.xLarge}) {
        width: 730px;
    }
`;
export const MobileContent = styled.div<{ img: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: ${heightFromAnimation} 0.5s ease 0s 1 forwards;
    background-image: url(${({ img }) => img});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.minWidth.small}) {
        min-height: 420px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        min-height: unset;
    }
`;
export const MobilePhotoContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 225px;
`;
export const PhotoContent = styled.div`
    display: flex;
    justify-content: center;
    width: 160px;

    @media (min-width: ${({ theme }) => theme.minWidth.xLarge}) {
        width: 209px;
    }
`;
const PhotoContentItem = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    width: 115px;
    left: 15px;
    top: 42px;

    @media (min-width: ${({ theme }) => theme.minWidth.xLarge}) {
        width: 145px;
        top: 12px;
    }
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-left: 30px;
`;

const StatusBar = styled.div`
    display: flex;
    margin-top: 12px;
    width: 100%;
    padding-left: 190px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        padding-left: 155px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        justify-content: center;
        padding-left: 0;
    }
`;

const InteractionsBar = styled.div<{ isSticky?: boolean }>`
    display: flex;
    justify-content: ${({ isSticky }) => (isSticky ? "flex-end" : "center")};
    height: 60px;
    width: 100%;
    padding-right: ${({ isSticky }) => (isSticky ? "4px" : "2px")};
    align-self: center;
    margin-bottom: ${({ isSticky }) => (isSticky ? "0" : "2px")};

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        width: auto;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: ${({ isSticky }) => (isSticky ? "0" : "8px")};
    }
`;

const InteractionsBarItem = styled.div`
    width: 152px;
`;

const StatusBarItem = styled.div<{ colorType: "zodiac" | "status" | "job" }>`
    display: flex;
    svg {
        margin-right: 6px;

        & > path {
            fill: ${({ theme, colorType }) =>
                colorType === "zodiac"
                    ? theme.colors.zodiacPurple
                    : colorType === "status"
                    ? theme.colors.red
                    : colorType === "job" && theme.colors.orange};
        }
    }
`;
const StatusBarText = styled.div`
    padding-right: 16px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    color: ${({ theme }) => theme.colors.mediumGray};
`;

export const customPhotoAddStyle: CSSProp = css`
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.gray};
    height: 21px;
    display: flex;
    text-align: center;
    justify-content: center;

    span:first-child {
        padding: 3px 4px 3px 4px;
        i {
            font-size: 15px;
        }
    }
    span:nth-child(2) {
        height: 21px;
        font-size: 11px;
        letter-spacing: 0.11px;
        align-items: center;
        padding: 0px 4px 2px 0px;
    }
`;
export const customAvatarStickyStyle: CSSProp = css`
    width: 48px;
    height: 48px;
`;
export const customAvatarMobileStyle: CSSProp = css`
    width: 220px;
    height: 220px;
`;
export const customAvatarStyle: CSSProp = css`
    width: 115px;
    height: 115px;

    @media (min-width: ${({ theme }) => theme.minWidth.xLarge}) {
        width: 145px;
        height: 145px;
    }
`;
const StickyPhoto = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 10px;
    ${customAvatarStickyStyle}
`;
const MobilePhoto = styled.div`
    display: flex;
    position: absolute;
    top: -10px;
    opacity: 1;
    ${customAvatarMobileStyle}
`;
const PhotoAdd = styled.div`
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 81px;
`;

const MobilePresentationTitle = styled.div`
    display: flex;
    padding: 0 33px 0 33px;
`;
export const TopBar = styled.div`
    display: flex;
    padding-top: 12px;
`;
export const BottomBar = styled.div`
    display: flex;
`;
const PresentationTitleContent = styled.div`
    display: flex;
`;

export const customMobileInfoBubbleStyle: CSSProp = css`
    background: rgba(0, 0, 0, 0.6);
    transform: scale(1);
    text-align: center;
    opacity: 0.7;

    :after {
        border-top-color: rgba(0, 0, 0, 0.6);
    }
    div {
        font-family: Montserrat-MediumItalic;
        font-size: 13px;
        color: ${({ theme }) => theme.colors.white};
        letter-spacing: -0.1px;
        line-height: 14px;
    }
`;
export const customInfoBubbleStyle: CSSProp = css`
    background: rgba(0, 0, 0, 0.6);
    transform: scale(1);
    text-align: center;
    border-bottom-left-radius: 0;
    opacity: 0.7;
    z-index: 5;
    margin-right: 160px;

    :after {
        border-top-color: rgba(0, 0, 0, 0.6);
        left: 0;
        margin-left: 0;
    }
    div {
        font-family: Montserrat;
        font-size: 13px;
        color: ${({ theme }) => theme.colors.white};
        letter-spacing: -0.1px;
        line-height: 14px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-right: 100px;
    }
`;

const Info = styled.div<{ isSticky?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-left: ${({ isSticky }) => (isSticky ? "6px" : "0px")};
    padding-top: ${({ isSticky }) => (isSticky ? "15px" : "0px")};
    ${({ isSticky }) => !isSticky && "align-items:center;"};

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        align-items: flex-start;
    }
`;
const infoText = css`
    font-family: Montserrat;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.white};
`;
const UserInfo = styled.div<{ isSticky?: boolean }>`
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ isSticky }) => (isSticky ? "15px" : "25px")};
    text-align: center;
    letter-spacing: 0;
    ${infoText}

    @media (min-width: ${({ theme }) => theme.minWidth.large}) {
        ${({ isSticky }) => !isSticky && " font-size:21px;"};

    }
`;
const LocationInfo = styled.div<{ isSticky?: boolean }>`
    display: flex;
    font-size: 12px;
    padding-bottom: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    svg {
        margin-right: ${({ isSticky }) => (!isSticky ? "5px" : "0px")};
        margin-top: -4px;
    }
    ${infoText}
`;
const MobileLastLogin = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-start;
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 9px;
    color: ${({ theme }) => theme.colors.white};
    text-align: right;
    right: 10px;
    top: 10px;
    width: 75px;
`;
const LastLogin = styled.div`
    display: flex;
    position: absolute;
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
    text-align: right;
    right: 7px;
    width: 130;

    span:first-child {
        margin-right: 2px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        flex-direction: column;
    }
`;

const DesktopContent = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 130px;
    justify-content: space-between;
`;

export {
    MobilePresentationTitle,
    PresentationTitleContent,
    InteractionsBar,
    InteractionsBarItem,
    StatusBar,
    StatusBarText,
    StatusBarItem,
    StickyPhoto,
    MobilePhoto,
    PhotoContentItem,
    PhotoAdd,
    Info,
    UserInfo,
    LocationInfo,
    MobileLastLogin,
    LastLogin,
    DesktopContent,
};
