// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const HeaderCard = styled.div<{ image: string }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-image: url(${({ image }) => image});
    object-fit: cover;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const customAvatarStyle: CSSProp = css`
    width: 100%;
    height: 100%;
`;

export const AddPhotoButton = styled.button`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -18px;
    padding: 3px;
    transition: border 0.3s ease;

    svg {
        transition: color 0.3s ease;
    }
`;

export const AvatarHover = styled.div`
    opacity: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: #ffffffb3;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.azureRadianceDark};
    transition: opacity 0.3s ease;
    width: 100%;
    height: 100%;
`;

export const PhotoContent = styled.div`
    display: flex;
    position: relative;

    :hover {
        ${AvatarHover} {
            opacity: 1;
        }

        ${AddPhotoButton} {
            border: 1px solid ${({ theme }) => theme.colors.azureRadianceDark};
        }

        svg {
            color: ${({ theme }) => theme.colors.azureRadianceDark};
        }
    }
`;

export const AvatarContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 20px;
    width: 145px;
    height: 145px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        top: 30px;
        width: 115px;
        height: 115px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        position: relative;
        top: -20px;
        margin-bottom: 10px;
        width: 240px;
        height: 240px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 300px;
        height: 300px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 220px;
        height: 220px;
    }
`;

const DefaultButton = styled.button`
    background-color: ${({ theme }) => theme.colors.transparent};
    border: unset;

    :focus {
        outline: unset;
    }

    :hover {
        cursor: pointer;
    }
`;

export const EditButton = styled(DefaultButton)`
    position: absolute;
    right: 10px;
    top: 12px;
    border: unset;

    opacity: 0;
    transition: opacity 0.3s ease;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        opacity: 1;
    }
`;

export const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 20px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-left: 70px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-left: 0;
        align-items: center;
    }
`;

export const HoverableBubble = styled.div`
    :hover {
        ${EditButton} {
            opacity: 1;
        }
    }
`;

export const InfoBubbleStyle = css`
    background-color: #00000050;
    margin-top: 5px;
    font-size: 12px;
    font-style: italic;
    width: 256px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    :after {
        border-top-color: #00000050;
        left: 10%;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        justify-content: center;

        :after {
            border-top-color: #00000050;
            left: 50%;
        }
    }
`;

export const AgeButton = styled(DefaultButton)`
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 2px 7px 1px 7px;
    margin-left: 2px;
    border: 1px solid ${({ theme }) => theme.colors.transparent};
    transition: border 0.3s ease;

    :hover {
        border: 1px solid ${({ theme }) => theme.colors.white};
    }
`;

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

export const LocationInfo = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.transparent};
    border-radius: 20px;
    padding: 5px 10px;
    margin-bottom: 5px;
    margin-left: -10px;
    transition: border 0.3s ease;

    :hover {
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.white};
    }

    svg {
        margin-right: 3px;
        margin-bottom: 4px;
    }
`;

export const QuickProfileButton = styled.div`
    width: 155px;
    height: 55px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.white};

    text-align: left;
    color: ${({ theme }) => theme.colors.gray};
    border-radius: 8px;

    align-self: flex-end;
    transition: color 0.3s ease;

    :hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.azureRadianceDark};
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 274px;
        height: 30px;
        position: relative;
        margin: 6px auto;
        padding-left: 20px;

        div:nth-child(2) {
            position: absolute;
            top: -8px;
            right: 10px;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        display: none;
    }
`;

export const AvatarSmall = css`
    width: 45px;
    height: 45px;

    span {
        font-size: 12px !important;
        text-shadow: 0 0 2px #000;
        color: #fff;
    }
`;

export const StatusBar = styled.div`
    display: flex;
    margin-left: 150px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-left: 110px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin: 0 auto;
    }
`;

export const StatusItem = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;

    border: 1px solid ${({ theme }) => theme.colors.transparent};
    border-radius: 20px;
    padding: 5px 10px;

    transition: border 0.3s ease;

    ${({ disabled }) =>
        !disabled &&
        css`
            :hover {
                cursor: pointer;
                border: 1px solid ${({ theme }) => theme.colors.azureRadianceDark};
                color: ${({ theme }) => theme.colors.azureRadianceDark};
            }
        `}
`;

export const ZodiacIconContainer = styled.div`
svg {
    color: ${({ theme }) => theme.colors.zodiacPurple};
    margin-right: 2px;
    padding-bottom: 2px;
}
`;

export const HowItLooks = styled.div`
    position: absolute;
    right: 5px;
    bottom: 15px;
    color: ${({ theme }) => theme.colors.gray};

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        color: ${({ theme }) => theme.colors.white};
        bottom: unset;
        top: 15px;
        max-width: 70px;
        text-align: center;
    }

    a {
        color: ${({ theme }) => theme.colors.gray};

        @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
