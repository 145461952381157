// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    Amount,
    TotalContainer,
    TotalSpan,
    Image,
    RadioButtonContainer,
} from "./styled";
import { RadioButton } from "@Components";
import { FormatHelper } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IChoiceCreditBox } from "./ChoiceCreditBox";
// #endregion Interface Imports

export const ChoiceCreditBox: React.FunctionComponent<IChoiceCreditBox.IProps> = ({
    name,
    value,
    active,
    block,
    bestPrice,
    creditCount,
    totalPrice,
    t,
    handleOnClick,
}) => {
    return (
        <Container active={active} block={block} onClick={handleOnClick}>
            <RadioButtonContainer>
                <RadioButton id={name} name={name} value={value} checked={active} />
            </RadioButtonContainer>
            <div className="d-flex flex-md-column flex-row">
                <Amount>{FormatHelper(creditCount!, "decimal")}</Amount>
                <Amount>{t("common:payment.coinPackages.credit")}</Amount>
            </div>
            {bestPrice && <Image src="/images/best-deal-tr.png" alt="best-deal-tr" />}
            <TotalContainer>
                <TotalSpan>
                    {t("common:payment.coinPackages.total")}
                    <TotalSpan> :</TotalSpan>
                </TotalSpan>
                <TotalSpan>{totalPrice}</TotalSpan>
                <TotalSpan>TL</TotalSpan>
            </TotalContainer>
        </Container>
    );
};
