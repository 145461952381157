import * as React from "react";

const SvgViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm0 11c4.441 0 8.319 2.413 10.394 5.999A11.992 11.992 0 0118 24a11.995 11.995 0 01-10.394-5.999A11.992 11.992 0 0118 12zm0 1a10.987 10.987 0 00-9.22 5A10.99 10.99 0 0018 23c3.773 0 7.212-1.915 9.22-5A10.99 10.99 0 0018 13zm0 2a3 3 0 110 6 3 3 0 010-6z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgViewIcon;
