// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 790px;
    margin: auto;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 740px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 670px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 480px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 345px;
    }
`;

export const InnerContainer = styled.div`
    display: flex;
    font-family: "Montserrat";
    color: #8791a1;
    font-size: 14px;
    font-weight: 700;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        flex-direction: column;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 10px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex: unset;
        width: 315px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 100%;
    }
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 20px;
    flex: 1;
    margin-left: 10px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex: unset;
        width: 315px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        width: 100%;
        margin-left: 0;
        padding-top: 0;
    }
`;

export const NamePassword = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DatePickerContainer = styled.div`
    height: 55px;
    margin: 20px 0;

    i:first-child {
        padding-top: 3px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-bottom: 20px;
        margin-top: 25px;
    }
`;

export const Icon = styled.i`
    color: #8791a1;
    font-size: 84px;
    flex: 1;
    padding-top: 5px;
    padding-left: 15px;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 63px;
    }
`;

export const DownContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-top: 20px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-top: 10px;
    }
`;

export const Contract = styled.div`
    display: flex;
    align-items: center;
    font-size: 9px;
    align-self: flex-start;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 80%;
    }
`;

export const ContractText = styled.div`
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        font-size: 10px;
    }
`;

export const MaritialStatusContainer = styled.div`
    height: 50px;
`;

export const ButtonsContainer = styled.div<{ flexEnd: boolean }>`
    display: flex;
   
    ${({ flexEnd }) =>
        flexEnd
            ? css`
                  justify-content: flex-end;
              `
            : css`
                  justify-content: space-between;
              `};
    align-items: center;
    margin-top: 15px;
    width: 100%;

    .other-buttons{
        display: flex;
        min-width:75%;
        justify-content: space-between;
       
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        .other-buttons{
            display: flex;
            min-width:auto;
            height: 100px;
            flex-direction: column;
        }
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-top: 20px;

      
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        justify-content: center;
        margin-top: 30px;
    }
`;

export const MBottom = styled.div<{ mb: string }>`
    margin-bottom: ${({ mb }) => mb}px;
`;

export const HiddenPixel = styled.div`
    visibility: hidden;
    width: 1px;
    height: 1px;
    margin-top: 200px;
    display: none;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        display: block;
    }
`;

ButtonsContainer.displayName = "ButtonsContainer";
MainContainer.displayName = "MainContainer";
