// #region Global Imports
import { DefaultTheme } from "styled-components";
// #endregion Global Imports

const theme: DefaultTheme = {
    colors: {
        transparent: "transparent",
        primary: "#2c3e50",
        disabled: "#c7cfdc",
        red: "#ff2a44",
        facebook: "#1877F2",
        blue: "#00c8f8",
        green: "#0ede79",
        white: "#ffffff",
        black: "#000000",
        gray: "#8791a1",
        darkGray: "#2a2a2a",
        whiteIce: "#DCFAEB",
        lightGray: "#C9CED5",
        lighterGray: "#dde2e9",
        badgeRed: "#FF374F",
        badgeHoverRed: "#D92E43",
        badgeGreen: "#04DD73",
        badgeHoverGreen: "#03BC61",
        mustard: "#cfb367",
        mediumGray: "#4a4a4a",
        lightBlue: "#D9F7FF",
        lighterBlue: "#BFEFFF",
        zodiacPurple: "#8334CC",
        orange: "#FFAE2A",
        brightGreen: "#00F07B",
        labelGreen: "#05D26E",
        pink: "#FFDFE3",
        foam: "#D9FDEB",
        mystic: "#E1E6ED",
        lightPink: "#FFECEE",
        mediumPink: "#FFD9DD",
        darkPink: "#FF94A1",
        sliderAqua: "#00c1ff",
        sliderAquaHover: "#08A2D4",
        sliderDarkBlue: "#0076ff",
        sliderDarkBlueHover: "#0866D4",
        azureRadiance: "#009BFF",
        azureRadianceDark: "#0076FF",
        cadetBlue: "#ACB5C3",
        brightTurquoise: "#00c8f8",
        athensGray: "#EDF0F4",
        creme: "#FEFAD2",
        buttercup: "#F5A623",
        alto: "#dedede",
        skyOfMagritte: "#0099FC",
        thousandSonsBlue: "#00D2EA",
        tractorBeam: "#00C0FA",
        transparentBlack: "rgba(0, 0, 0, 0.5)",
    },
    gradients: {
        greenToBlue:
            "linear-gradient(90deg, rgba(0,240,123,1) 0%, rgba(0,206,248,1) 50%, rgba(0,115,255,1) 100%)",
        greenToBlueHover: "linear-gradient(90deg, #00C07E 0%, #00ADFD 50%, #0057FF 100%)",
        greenToBlueVertical: "linear-gradient(180deg,#00F07B 0%,#00CEF8 48%,#0073FF 100%)",
        orangeVertical: "linear-gradient(180deg,#fad961 0%,#f76b1c 100%)",
        lightBlueVertical: "linear-gradient(180deg,#00CEF8 0%,#0073FF 100%)",
        pinkToPurple:
            "linear-gradient(90deg, rgba(255,0,125,1) 0%, rgba(193,0,254,1) 50%, rgba(128,0,150,1) 100%)",
        pinkToPurpleHover:
            "linear-gradient(90deg, rgba(157,34,112,1) 0%, rgba(130,31,160,1) 50%, rgba(108,27,134,1) 100%)",
        estorilToBlue:
            "linear-gradient(90deg, rgba(0,206,248,1) 0%, rgba(0,115,255,1) 50%, rgba(0,38,240,1) 100%)",
        estorilToBlueHover:
            "linear-gradient(90deg, rgba(15,103,195,1) 0%, rgba(12,84,197,1) 50%, rgba(8,41,193,1) 100%)",
        redSpectrum: "linear-gradient(180deg, rgba(245,81,95,1) 0%, rgba(159,4,27,1) 100%)",
        grayHeader: "linear-gradient(180deg, #f7f7f8 1%, #dee1e5 97%)",
        goldButton: "linear-gradient(90deg, #FAF1BB 0%, #B79139 46%, #FEF3BD 71%, #D9BA60 100%)",
        purple: " linear-gradient(134deg, #C86DD7 0%, #3023AE 100%);",
        pinkToBlue: "linear-gradient(90deg, rgba(200,109,215,1) 37%, rgba(48,35,174,1) 100%);",
        whiteShadow: "linear-gradient(to left, #fff, #fff, transparent)",
        whiteShadowRgpRight: "linear-gradient(to right, #fff, rgba(255,255,255,0))",
        whiteShadowRgpLeft: "linear-gradient(to left, #fff, rgba(255,255,255,0))",
        whiteShadowPhotoSliderRgpLeft: "linear-gradient(to left, #edf0f4, rgba(255,255,255,0))",
        whiteShadowPhotoSliderRgpRight: "linear-gradient(to right, #edf0f4, rgba(255,255,255,0))",
    },
    iconNames: {
        "apple-icon": "apple-icon",
        "apple-store-min": "apple-store-min",
        "aquarius-icon": "aquarius-icon",
        "aries-icon": "aries-icon",
        "arrow-point-to-right": "arrow-point-to-right",
        "avatar-female": "avatar-female",
        "avatar-man": "avatar-man",
        "bubble-mark": "bubble-mark",
        calendar: "calendar",
        "calendar-default": "calendar-default",
        "calendar-invalid": "calendar-invalid",
        "calendar-valid": "calendar-valid",
        camera: "camera",
        "cancer-icon": "cancer-icon",
        "capricorn-icon": "capricorn-icon",
        "chat-icon": "chat-icon",
        "check-disabled": "check-disabled",
        check: "check",
        "chevron-down": "chevron-down",
        "chevron-right": "chevron-right",
        "chevron-left": "chevron-left",
        "circle-close-icon": "circle-close-icon",
        "circle-conversation-icon": "circle-conversation-icon",
        "circled-star": "circled-star",
        clock: "clock",
        "close-icon-sm": "close-icon-sm",
        "close-icon": "close-icon",
        "coin-dark": "coin-dark",
        "coin-light-icon": "coin-light-icon",
        "conversation-icon": "conversation-icon",
        communication: "communication",
        credit: "credit",
        dislike: "dislike",
        "drag-drop": "drag-drop",
        "eighteen-invalid": "eighteen-invalid",
        "eighteen-valid": "eighteen-valid",
        eighteen: "eighteen",
        envelope: "envelope",
        "envelope-default": "envelope-default",
        "envelope-invalid": "envelope-invalid",
        "envelope-valid": "envelope-valid",
        "facebook-icon": "facebook-icon",
        "filter-setup": "filter-setup",
        "flying-heart": "flying-heart",
        "friends-icon": "friends-icon",
        "gemini-icon": "gemini-icon",
        goback: "goback",
        "google-play-min": "google-play-min",
        "google-play": "google-play",
        "green-light": "green-light",
        handglass: "handglass",
        "heart-border": "heart-border",
        heart: "heart",
        "hotornot-female-like": "hotornot-female-like",
        "hotornot-game": "hotornot-game",
        "hotornot-male-liked": "hotornot-male-liked",
        "hotornot-match": "hotornot-match",
        info: "info",
        "info-default": "info-default",
        "instagram-icon": "instagram-icon",
        "job-icon": "job-icon",
        "kalp-kalbe-karsi": "kalp-kalbe-karsi",
        "leo-icon": "leo-icon",
        "libra-icon": "libra-icon",
        "location-icon": "location-icon",
        male: "male",
        "man-add-friend-icon": "man-add-friend-icon",
        "man-added-friend-icon": "man-added-friend-icon",
        man: "man",
        "man-default": "man-default",
        "man-figure": "man-figure",
        "man-invalid": "man-invalid",
        "man-remove-friend-icon": "man-remove-friend-icon",
        "man-valid": "man-valid",
        "menu-icon": "menu-icon",
        "new-match-icon": "new-match-icon",
        password: "password",
        "password-default": "password-default",
        "password-invalid": "password-invalid",
        "password-valid": "password-valid",
        photo: "photo",
        "pinterest-icon": "pinterest-icon",
        "pisces-icon": "pisces-icon",
        "plus-circle": "plus-circle",
        "prioritized-icon": "prioritized-icon",
        "relationshipstatus-icon": "relationshipstatus-icon",
        "right-arrow": "right-arrow",
        "sagittarius-icon": "sagittarius-icon",
        "scorpio-icon": "scorpio-icon",
        "search-icon": "search-icon",
        "siberalem-hearts-colored": "siberalem-hearts-colored",
        "siberalem-hearts": "siberalem-hearts",
        "siberalem-icon": "siberalem-icon",
        "siberalem-logo-colored": "siberalem-logo-colored",
        "siberalem-logo": "siberalem-logo",
        "slider-handle-age": "slider-handle-age",
        "slider-handle-distance": "slider-handle-distance",
        "smile-heart-icon": "smile-heart-icon",
        "star-icon": "star-icon",
        "taurus-icon": "taurus-icon",
        trash: "trash",
        "twitter-icon": "twitter-icon",
        user: "user",
        "view-icon": "view-icon",
        "virgo-icon": "virgo-icon",
        "wink-icon": "wink-icon",
        "winked-icon": "winked-icon",
        "woman-add-friend-icon": "woman-add-friend-icon",
        "woman-added-friend-icon": "woman-added-friend-icon",
        woman: "woman",
        "woman-default": "woman-default",
        "woman-figure": "woman-figure",
        "woman-invalid": "woman-invalid",
        "woman-remove-friend-icon": "woman-remove-friend-icon",
        "woman-valid": "woman-valid",
        lock: "lock",
        "check-shield": "check-shield",
        "phone-circle": "phone-circle",
        "fax-circle": "fax-circle",
        "mail-circle": "mail-circle",
        exclamation: "exclamation",
        ban: "ban",
        "double-heart": "double-heart",
        upload: "upload",
        "sticker-icon": "sticker-icon",
        "emoji-icon": "emoji-icon",
        edit: "edit",
    },
    maxWidth: {
        large: "1199px",
        medium: "991px",
        small: "767px",
        xSmall: "575px",
        xxSmall: "375px",
    },
    minWidth: {
        xLarge: "1200px",
        large: "992px",
        medium: "768px",
        small: "576px",
        xSmall: "376px",
    },
    fontWeights: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        heavyBold: 900,
    },
    fontSizes: {
        xxSmall: "9px",
        xSmall: "10px",
        small: "12px",
        regular: "13px",
        medium: "14px",
        large: "15px",
        xLarge: "16px",
        xxLarge: "17px",
    },
    iconSizes: {
        xxSmall: "18px",
        xSmall: "26px",
        small: "32px",
        medium: "38px",
        large: "42px",
        xLarge: "46px",
        xxLarge: "50px",
    },
    titleFontSizes: {
        small: "14px",
        medium: "16px",
        large: "20px",
        xlarge: "24px",
    },
    smallTagFontSizes: {
        small: "10px",
        medium: "11px",
        large: "12px",
    },
    heights: {
        modalHeaderHeight: "60px",
        photoUploadModal: "570px",
    },
};

export { theme };
