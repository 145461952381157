// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { useLocationForm } from "@Definitions/Hooks/UseLocationForm";
import { Button, Dropdown } from "@Components/Basic";
import { InputContainer } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IPresentation } from "./Location";
// #endregion Interface Imports

export const Presentation: React.FunctionComponent<IPresentation.IProps> = ({
    t,
    countries,
    cities,
    towns,
    fetchCities,
    fetchTowns,
    defaultLocation,
    saveLocation,
}) => {
    const {
        selectedCountry,
        selectedCity,
        selectedTown,
        countryOptions,
        cityOptions,
        townOptions,
        townInput,
        changeCountry,
        changeCity,
        changeTown,
        changeTownInput,
        idOf,
        apply,
    } = useLocationForm({
        isSearch: false,
        t,
        countries,
        cities,
        towns,
        defaultLocation,
        saveLocation,
        fetchCities,
        fetchTowns,
    });

    const isValid = () => {
        return idOf(selectedCountry) > -1 && idOf(selectedCity) > -1 && idOf(selectedTown) > -1;
    };

    return (
        <>
            <div className="mb-2 p-10">
                <InputContainer>
                    <Dropdown
                        t={t}
                        name="select-country"
                        width="227px"
                        minHeight="40px"
                        key="select-country"
                        value={selectedCountry}
                        options={countryOptions}
                        onChange={changeCountry}
                        placeholder={t("common:selectLocation.country")}
                    />
                </InputContainer>

                <InputContainer>
                    <Dropdown
                        t={t}
                        name="select-city"
                        width="227px"
                        minHeight="40px"
                        key="select-city"
                        options={cityOptions}
                        value={selectedCity}
                        onChange={changeCity}
                        placeholder={t("common:selectLocation.city")}
                    />
                </InputContainer>

                {idOf(selectedCity) > -1 && (
                    <InputContainer>
                        <Dropdown
                            t={t}
                            name="select-town"
                            width="227px"
                            minHeight="40px"
                            key="select-town"
                            options={townOptions}
                            value={selectedTown}
                            onChange={changeTown}
                            inputValue={townInput}
                            onInputChange={changeTownInput}
                            forceReactSelect={idOf(selectedCountry) > 0 || false}
                            placeholder={t("common:selectLocation.town")}
                            noOptionsMessage={() => t("common:selectLocation.noResults")}
                        />
                    </InputContainer>
                )}
            </div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={!isValid()} onClick={apply}>
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </>
    );
};
