// #region Global Imports
import styled from "styled-components";
// #endregion GLobal Imports

// #region Local Imports
import { IIconBlockButton } from "./IconBlockButton";
// #endregion Local Imports

const ButtonBase = styled.div<Partial<IIconBlockButton.IProps>>`
    align-items: center;
    position: relative;
    display: ${({ fullWidth }) => (fullWidth ? "flex" : " inline-flex;")};
    ${({ fullWidth }) => fullWidth && "justify-content: center;"};
    ${({ variant, color }) => variant === "outlined" && `border: 1px solid ${color};`};
    padding: ${({ startIcon, endIcon }) => !startIcon && !endIcon && "8px;"};
    background: ${({ variant, color }) => (variant === "contained" ? color : "white")};
    font-size: 12px;
    letter-spacing: -0.18px;
    line-height: 10px;
    border-radius: 26px;
    box-sizing: border-box;
    font-family: "Montserrat";
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    box-shadow: ${({ variant }) => variant !== "text" && "2px 2px 6px 0px rgba(0, 0, 0, 0.3);"};
    ${({ variant, color }) => variant === "text" && `color: ${color}`};
    ${({ customStyle }) => customStyle && customStyle};
    :hover {
        text-decoration: "none";
        ${({ hoverColor }) => hoverColor && `background: ${hoverColor} `};
        span {
            ${({ hoverTextColor }) =>
                hoverTextColor &&
                `color: ${hoverTextColor};
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.24);`};
        }
        i {
            ${({ hoverTextColor }) => hoverTextColor && `color: ${hoverTextColor} `};
        }
    }
`;

const Label = styled.span`
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
`;
const IconText = styled.span<Partial<IIconBlockButton.IProps>>`
    display: flex;
    padding-right: ${({ startIcon }) => startIcon && "12px;"};
    padding-left: ${({ endIcon }) => endIcon && "12px;"};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StartIcon = styled.span`
    display: inherit;
    padding: 3px 14px 3px 3px;
`;
const EndIcon = styled.span`
    display: inherit;
    padding: 3px 3px 3px 12px;
`;

export { ButtonBase, IconText, StartIcon, EndIcon, Label };
