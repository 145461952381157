// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Card, Text, Title } from "@Components/Basic";
import { Col, Row } from "@Styled/Grid";
import { theme } from "@Definitions/Styled";
import {
    ConversationPlus,
    CreditPlus,
    WinkPlus,
    DoubleHeart,
    FlyingHeart,
    ManCircleFilled,
    HotornotMaleLiked,
    Sticker,
} from "@Icons";
import { InfoItem } from "./styled";
import { data } from "./data";
// #endregion Local Imports

// #region Interface Imports
import { IPaymentInfoCard, IInfoItem } from "./PaymentInfoCard";
// #endregion Interface Imports

export const PaymentInfoCard: React.FunctionComponent<IPaymentInfoCard.IProps> = ({ t, type }) => {
    const getBackground = () => {
        switch (type) {
            case "gold":
                return theme.gradients.greenToBlueVertical;
            case "coin":
                return theme.gradients.orangeVertical;
            default:
                return "";
        }
    };

    const renderIcons = (iconName: string): JSX.Element => {
        switch (iconName) {
            case "conversation-plus":
                return <ConversationPlus width="44px" height="57px" />;
            case "credit-plus":
                return <CreditPlus width="48px" height="57px" />;
            case "wink-plus":
                return <WinkPlus width="46px" height="57px" />;
            case "double-heart":
                return <DoubleHeart width="61px" height="57px" />;
            case "flying-heart":
                return <FlyingHeart width="90px" height="57px" />;
            case "man-circle-filled":
                return <ManCircleFilled width="46px" height="57px" />;
            case "hotornot-male-liked":
                return <HotornotMaleLiked width="47px" height="57px" />;
            case "sticker":
                return <Sticker width="46px" height="57px" />;
            default:
                return <></>;
        }
    };

    return (
        <Card className="p-1 pb-2" background={getBackground()}>
            <Card
                className="d-flex justify-content-center align-items-center py-3"
                background="white"
            >
                <Title fontWeight="bold">{t(`common:payment.infoCard.${type}.title`)}</Title>
            </Card>
            <Row>
                {data[type].map((item: IInfoItem, i: number) => {
                    return (
                        <Col key={`${type}-item-${i + 1}`} className="col-lg-12 col-md-3 col-6">
                            <InfoItem className="my-4">
                                <Row>
                                    <Col className="col-12">
                                        {item.icon === "man-circle-filled" && (
                                            <ManCircleFilled
                                                width="46px"
                                                height="57px"
                                                className="mr-2 transparent"
                                            />
                                        )}
                                        {renderIcons(item.icon)}
                                        {item.icon === "man-circle-filled" && (
                                            <ManCircleFilled
                                                width="46px"
                                                height="57px"
                                                className="ml-2 transparent"
                                            />
                                        )}
                                    </Col>
                                    <Col className="col-12 pr-4 pl-4">
                                        <Text color="white">
                                            {t(
                                                `common:payment.infoCard.${type}.${item.textLocale}`
                                            )}
                                        </Text>
                                    </Col>
                                </Row>
                            </InfoItem>
                        </Col>
                    );
                })}
            </Row>
        </Card>
    );
};
