import { IBankTransfer } from "./BankTransfer";

export const bankData: IBankTransfer.IBankData = {
    currencies: ["lira", "dollar", "euro"],
    banks: [
        {
            name: "Akbank",
            branch: "Perpa",
            lira: {
                account: "633 - 60349",
                iban: "TR97 0004 6006 3388 8000 0603 49",
            },
            dollar: {
                account: "633 - 60353",
                iban: "TR37 0004 6006 3300 1000 0603 53",
            },
            euro: {
                account: "633 - 60356",
                iban: "TR30 0004 6006 3303 6000 0603 56",
            },
            image: "/images/bank-akbank.png",
        },
        {
            name: "Garanti Bankası",
            branch: "Boğaziçi Ticari",
            lira: {
                account: "1666 - 6297666",
                iban: "TR44 0006 2001 6660 0006 2976 66",
            },
            dollar: {
                account: "1666 - 9082516",
                iban: "TR02 0006 2001 6660 0009 0825 16",
            },
            euro: {
                account: "1666 - 9099987",
                iban: "TR93 0006 2001 6660 0009 0999 87",
            },
            image: "/images/bank-garanti.png",
        },
        {
            name: "Türkiye İş Bankası",
            branch: "Balmumcu",
            lira: {
                account: "1135 - 968745",
                iban: "TR93 0006 4000 0011 1350 9687 45",
            },
            dollar: {
                account: "1135 - 3639262",
                iban: "TR76 0006 4000 0021 1353 6392 62",
            },
            euro: {
                account: "1135 - 3639296",
                iban: "TR31 0006 4000 0021 1353 6392 96",
            },
            image: "/images/bank-isbankasi.png",
        },
        {
            name: "VakıfBank",
            branch: "Salı Pazarı",
            lira: {
                account: "333 - 00158007301104680",
                iban: "TR70 0001 5001 5800 7301 1046 80",
            },
            dollar: {
                account: "333 - 00158048014653135",
                iban: "TR88 0001 5001 5804 8014 6531 35",
            },
            euro: {
                account: "333 - 00158048014653139",
                iban: "TR77 0001 5001 5804 8014 6531 39",
            },
            image: "/images/bank-vakifbank.png",
        },
        {
            name: "Yapı Kredi",
            branch: "Esentepe",
            lira: {
                account: "606 - 89570431",
                iban: "TR62 0006 7010 0000 0089 5704 31",
            },
            dollar: {
                account: "606 - 89326775",
                iban: "TR40 0006 7010 0000 0089 3267 75",
            },
            euro: {
                account: "606 - 89326779",
                iban: "TR29 0006 7010 0000 0089 3267 79",
            },
            image: "/images/bank-yapikredi.png",
        },
    ],
};
