// #region Global Imports
import * as React from "react";
import dayjs from "dayjs";
import Countdown from "react-countdown-now";
import { Trans } from "react-i18next";
// #endregion Global Imports

// #region Local Imports
import { Button, Text, Title, Card } from "@Components";
import { Col, Row } from "@Styled";
import { SearchContainer, Container, Footer } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IOffer } from "./Offer";
// #endregion Interface Imports

export const Offer: React.FunctionComponent<IOffer.IProps> = ({
    t,
    isSearchOffer,
    image,
    description,
    endDate,
    onClickBuyButton,
}): JSX.Element => {
    const renderCountDown = () => {
        const now = dayjs(new Date());
        const diffDay = endDate.diff(now, "day");

        if (diffDay > 0) {
            return (
                <strong className="text-lowercase mx-1">
                    {`${diffDay} ${t(`common:forms.datepicker.day`)}`}
                </strong>
            );
        }

        return (
            <strong className="text-lowercase mx-1">
                <Countdown
                    date={endDate?.toDate()}
                    zeroPadTime={2}
                    renderer={({ formatted }) => {
                        if (formatted.hours !== "00") {
                            return `${formatted.hours}:${formatted.minutes}:${formatted.seconds}`;
                        }
                        return `${formatted.minutes}:${formatted.seconds}`;
                    }}
                />
            </strong>
        );
    };

    const renderFooter = () => (
        <Footer>
            <Text color="red" size="small" className="text-center">
                <Trans i18nKey="dashboard:Widgets.Offer.countDown">
                    Teklifin bitmesine
                    {renderCountDown()}
                    kaldı.
                </Trans>
            </Text>
        </Footer>
    );

    if (isSearchOffer) {
        return (
            <SearchContainer className="p-3 my-3">
                <Row>
                    <Col className="col-3 d-none d-lg-flex justify-content-center align-items-center">
                        <img
                            width="120"
                            src={image || "/images/couple-kiss.png"}
                            alt={t("dashboard:Widgets.Offer.title")}
                        />
                    </Col>
                    <Col className="col-12 col-lg-9 text-center text-lg-left">
                        <Title className="" color="red" size="large" fontWeight="bold">
                            {t("dashboard:Widgets.Offer.title")}
                        </Title>
                        <Text className="mb-3" color="black" size="large" weight="bold">
                            {description}
                        </Text>

                        <Button
                            styleType="ghost"
                            onClick={onClickBuyButton}
                            className="text-uppercase"
                        >
                            {t("dashboard:Widgets.Offer.buy")}
                        </Button>
                    </Col>
                </Row>
            </SearchContainer>
        );
    }

    return (
        <Card overflowHidden>
            <Container className="text-center text-md-left align-items-lg-center text-lg-center">
                <Row className="no-gutters py-5 px-5 px-xl-9">
                    <Col className="col-3 col-lg-12 d-none d-md-flex justify-content-center align-items-center d-lg-none">
                        <img
                            width="120"
                            src={image || "/images/couple-kiss.png"}
                            alt={t("dashboard:Widgets.Offer.title")}
                        />
                    </Col>
                    <Col className="col-12 col-md-9 col-lg-12">
                        <Title className="mb-lg-4" color="red" size="large" fontWeight="bold">
                            {t("dashboard:Widgets.Offer.title")}
                        </Title>

                        <img
                            className="mb-3 d-none d-lg-inline"
                            width="120"
                            src={image || "/images/couple-kiss.png"}
                            alt={t("dashboard:Widgets.Offer.title")}
                        />
                        <Text className="mb-3" color="black" size="large" weight="bold">
                            {description}
                        </Text>

                        <Button
                            styleType="ghost"
                            onClick={onClickBuyButton}
                            className="text-uppercase mb-5"
                        >
                            {t("dashboard:Widgets.Offer.buy")}
                        </Button>
                    </Col>
                </Row>
                {renderFooter()}
            </Container>
        </Card>
    );
};
