// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { ProfileQuestionType } from "@Interfaces/enums";
import { TextInput, IconButton, Button, Anchor } from "@Components/Basic";
import { BoxHeader as SimpleHeader } from "@Components/FormBox/styled";
import { useScreenSize, Preset } from "@Definitions";
import { BubbleMark, CloseIcon } from "@Icons";
import {
    Container,
    BoxHeader,
    StyleIcon,
    ButtonContainer,
    ButtonIcon,
    IncomeContainer,
    DropdownSelector,
    BoxFooter,
    InterestContainer,
    TextAreaContainer,
    TextAreaFooter,
    FooterRight,
    FooterSpan,
    BoxFooterRight,
    customAvatarStyle,
    ChildrenContainer,
    Header,
} from "./styled";
import { Modal, Avatar, CheckBoxGroup, ValidationCounter, LandingMobileHeader } from "@Components";
import abouts from "./abouts.json";
import presentations from "./presentationTitles.json";
import { ProfileActions } from "@Actions";
// #endregion Local Imports

// #region Interface Imports
import { ProfileModel } from "@Interfaces";
import { IQuickProfileFill } from "./QuickProfileFill";
// #endregion Interface Imports

const Presentation: React.FunctionComponent<IQuickProfileFill.IPresentation> = ({
    t,
    closeModal,
    profileEditMode,
    checkedAnswer,
    setCheckedAnswer,
    textInputValue,
    setTextInputValue,
    singleValueId,
    setSingleValueId,
    theQuestion,
    skipTheQuestion,
    profilePhoto,
}): JSX.Element => {
    const dispatch = useDispatch();

    const screenSize = useScreenSize();
    const isMobile = !!screenSize.match(/xsmall/i);

    const updateQuestion = async (payload: ProfileModel.UpdateQuestionActionPayload) => {
        dispatch(await ProfileActions.UpdateQuestions(payload));
    };

    const updateQuestionAndProfile = async (payload: ProfileModel.UpdateQuestionActionPayload) => {
        dispatch(await ProfileActions.UpdateQuestionAndProfile(payload));
    };

    const { AllowMultipleOptions, AllowText, Id, Text, Options, MaxLength } = theQuestion;

    let questionType;
    let newOptions;

    const payloadBody: ProfileModel.UpdateQuestionActionPayload = {
        questionId: Id,
    };

    if (AllowMultipleOptions) {
        questionType = "checkboxGroup";
        payloadBody.options = checkedAnswer.join();
    } else if (!AllowText) {
        questionType = "singleSelect";

        newOptions = Options.map((item: ProfileModel.IOption) => ({
            value: item.Id,
            label: item.Name,
        }));

        payloadBody.options = singleValueId ? singleValueId.value : {};
    } else if (AllowText) {
        questionType = "text";
        payloadBody.text = textInputValue;
    }

    const getRandom = (items: Array<string>) => items[Math.floor(Math.random() * items.length)];

    const randomText = (type: number) => {
        switch (type) {
            case ProfileQuestionType.PresentationTitle:
                setTextInputValue(getRandom(presentations));
                break;
            case ProfileQuestionType.AboutMeTitle:
                setTextInputValue(getRandom(abouts));
                break;

            default:
                break;
        }
    };

    const canShowSuggest = (): boolean => {
        return (
            payloadBody.questionId === ProfileQuestionType.AboutMeTitle ||
            payloadBody.questionId === ProfileQuestionType.PresentationTitle
        );
    };

    return (
        <Modal isDismissible={profileEditMode && isMobile} onDismiss={closeModal}>
            <Container>
                {profileEditMode ? (
                    isMobile ? (
                        <LandingMobileHeader showLogoText />
                    ) : (
                        <SimpleHeader>
                            <div />
                            {Text}
                            <Anchor onClick={() => closeModal()}>
                                <CloseIcon width="17px" height="20px" />
                            </Anchor>
                        </SimpleHeader>
                    )
                ) : (
                    <BoxHeader>
                        <Avatar
                            type="default"
                            src={`${profilePhoto}${Preset.thumb}`}
                            alt="avatar-profile"
                            customStyle={customAvatarStyle}
                            withPercentage
                        />
                        <div>{t("common:profileEdit.header.quickProfileFill")}</div>
                        <IconButton
                            renderIcon={() => (
                                <StyleIcon>
                                    <CloseIcon width="17px" height="20px" />
                                </StyleIcon>
                            )}
                            onIconClick={() => closeModal()}
                        />
                    </BoxHeader>
                )}

                <ChildrenContainer>
                    {questionType === "singleSelect" && (
                        <IncomeContainer>
                            {(!profileEditMode || isMobile) && (
                                <Header weight="bold">{Text}</Header>
                            )}

                            <DropdownSelector
                                placeholder="Seçiniz"
                                name="singleSelect"
                                options={newOptions}
                                onChange={(option: { label: string; value: number }) =>
                                    setSingleValueId(option)
                                }
                                value={singleValueId}
                                t={t}
                            />
                        </IncomeContainer>
                    )}
                    {questionType === "checkboxGroup" && (
                        <InterestContainer>
                            {(!profileEditMode || isMobile) && (
                                <Header weight="bold">{Text}</Header>
                            )}

                            <CheckBoxGroup
                                checkboxData={theQuestion.Options}
                                setCheckedAnswer={setCheckedAnswer}
                                checkedAnswer={checkedAnswer}
                            />
                        </InterestContainer>
                    )}
                    {questionType === "text" && (
                        <TextAreaContainer>
                            {(!profileEditMode || isMobile) && (
                                <Header weight="bold">{Text}</Header>
                            )}

                            <div className="content">
                                <TextInput
                                    type="textarea"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTextInputValue(e.target.value);
                                    }}
                                    value={textInputValue}
                                    cy="pre-quickprofile-input"
                                />
                                <TextAreaFooter>
                                    <ValidationCounter
                                        count={textInputValue.length}
                                        countLimit={MaxLength}
                                    />
                                    {canShowSuggest() && (
                                        <FooterRight>
                                            <FooterSpan onClick={() => randomText(theQuestion.Id)}>
                                                {t("common:Aklıma bir şey gelmiyor")}
                                            </FooterSpan>
                                            <BubbleMark width="19px" height="19px" />
                                        </FooterRight>
                                    )}
                                </TextAreaFooter>
                            </div>
                        </TextAreaContainer>
                    )}
                </ChildrenContainer>

                <BoxFooter className={questionType}>
                    <div>
                        {questionType === "checkboxGroup" && MaxLength !== 0 && (
                            <ValidationCounter
                                count={checkedAnswer.length}
                                countLimit={MaxLength}
                            />
                        )}
                    </div>

                    <ButtonContainer>
                        {profileEditMode ? (
                            <Button
                                disabled={false}
                                onClick={() => updateQuestionAndProfile(payloadBody)}
                                cy="quickprofilefill-save-btn"
                            >
                                {t("common:profileEdit.infoModal.buttonText")}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => updateQuestion(payloadBody)}
                                icon={<ButtonIcon className="icon clock mr-3" />}
                                iconPosition="left"
                            >
                                {t("common:Buttons.Kaydet ve devam et")}
                            </Button>
                        )}
                    </ButtonContainer>

                    <div>
                        {!profileEditMode && (
                            <BoxFooterRight onClick={skipTheQuestion}>
                                {t("common:Atla")}
                            </BoxFooterRight>
                        )}
                    </div>
                </BoxFooter>
            </Container>
        </Modal>
    );
};

export { Presentation };
