// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { StickerModel } from "@Interfaces";
// #endregion Interface Imports

export const StickerService = {
    Get: async (): Promise<StickerModel.StickerResponse> => {
        return Http.Request("POST", "/Sticker/Get", undefined, undefined);
    },

    Buy: async (StickerId: string): Promise<StickerModel.IBuy[]> => {
        return Http.Request("POST", "/Sticker/Buy", undefined, { StickerId });
    },
};
