// #region Local Imports
import { getContactUrl } from "@Definitions";
import { FooterDataProvider } from "./Footer";
// #endregion Local Imports

const base = "//www.siberalem.com";

export const socials = [
    { href: "//instagram.com/siberalemcom", name: "instagram" },
    { href: "//facebook.com/siberalem", name: "facebook" },
    { href: "//twitter.com/siberalemcom", name: "twitter" },
    { href: "//pinterest.com/siberalemcom", name: "pinterest" },
    { href: `${base}/blog`, name: "siberalem" },
];

export const backlinks = ["workbenchteam.com"];

export const useFooterLinks: FooterDataProvider = (t, profile) => [
    {
        href: profile ? "/dashboard" : `${base}`,
        name: t("common:footer.links.Anasayfa"),
        internal: !!profile,
    },
    {
        href: getContactUrl(profile),
        name: t("common:footer.links.İletişim"),
        internal: !!profile,
    },
    {
        href: `${base}/blog`,
        name: t("common:footer.links.Blog"),
    },
    {
        href: `${base}/yardim-merkezi`,
        name: t("common:footer.links.Yardım"),
    },
    {
        href: `${base}/uyelik-sozlesmesi`,
        name: t("common:footer.links.Üyelik Sözleşme"),
    },
    {
        href: `${base}/gizlilik-ilkeleri`,
        name: t("common:footer.links.Gizlilik"),
    },
    {
        href: `${base}/islem-rehberi`,
        name: t("common:footer.links.İşlem Rehberi"),
    },
    {
        href: `${base}/yardim-merkezi/uyelik-oncesi/siberalem-mobil-uygulamalar/`,
        name: t("common:footer.links.Mobil Uygulamarı"),
    },
    {
        href: `${base}/mutluluk-hikayeleri/`,
        name: t("common:footer.links.Mutluluk hikaye"),
    },
];
