export const Preset = {
    /** @summary 210x... */
    blurThumb: "?preset=siberthumb&a.blur=100",

    blurThumb2x: "?preset=siberthumb2x&a.blur=100",

    /** @summary 800x600 */
    full: "?preset=siberwebfullpicture",

    placeholder: "?preset=siberthumb&a.blur=100&quality=20",

    /** @summary 200x... */
    recentPhotos: "?preset=siberlatestphotos",

    /** @summary 150x150 */
    thumb: "?preset=siberthumb",

    /** @summary 300x300 */
    thumb2x: "?preset=siberthumb2x",

    /** @summary 640x640 */
    thumb4x: "?preset=siberthumb4x",
};
