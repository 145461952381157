// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
import { css, CSSProp } from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Preset } from "@Definitions/PresetHelper";
import { Card, Button } from "@Components/Basic";
import { Avatar } from "@Components";
import { Container, CardContent, CardTextContent, CardText, ButtonText } from "./styled";
import { IProfileOccupancy } from "./ProfileOccupancy";
// #endregion Local Imports

// #region Interface Imports
import { IStore } from "@Interfaces";
// #endregion Interface Imports

const customStyle: CSSProp = css`
    width: 110px;
    height: 110px;
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 90px;
        height: 90px;
        align-self: flex-start;
    }
`;

const ProfileOccupancy: React.FunctionComponent<IProfileOccupancy.IProps> = ({
    t,
    openQuickFillModal,
}): JSX.Element => {
    const ratio = useSelector((state: IStore) => state.common.profileOccupancyRatio);
    const avatarUrl = useSelector((state: IStore) => state.profile.self?.Photo.Url);

    return (
        <Container>
            <Card>
                <CardContent>
                    <CardTextContent>
                        <CardText>{t("dashboard:ProfileOccupancy.cardText")}</CardText>
                        {ratio !== "100" && (
                            <Button styleType="ghost" onClick={openQuickFillModal}>
                                <ButtonText>
                                    {t("dashboard:ProfileOccupancy.buttonText")}
                                </ButtonText>
                            </Button>
                        )}
                    </CardTextContent>
                    <Avatar
                        src={avatarUrl + Preset.blurThumb}
                        type="default"
                        alt="avatar"
                        customStyle={customStyle}
                        withPercentage
                    />
                </CardContent>
            </Card>
        </Container>
    );
};

export { ProfileOccupancy };
