import * as React from "react";

const SvgCredit = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 72 72" {...props}>
        <path
            d="M36 0c19.882 0 36 16.118 36 36S55.882 72 36 72 0 55.882 0 36 16.118 0 36 0zM6 36c0 15.556 11.84 28.347 27 29.852v-9.065C22.823 55.332 15 46.58 15 36c0-10.58 7.823-19.332 18-20.787V6.148C17.84 7.653 6 20.444 6 36zM39 6.148v9.065c4.42.632 8.396 2.64 11.48 5.577l-8.175 6.813A10.454 10.454 0 0036 25.5c-5.799 0-10.5 4.701-10.5 10.5S30.201 46.5 36 46.5c2.9 0 5.524-1.175 7.424-3.075l7.425 7.424a20.942 20.942 0 01-11.848 5.938v9.065C54.16 64.346 66 51.556 66 36 66 20.444 54.16 7.654 39 6.148z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCredit;
