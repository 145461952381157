// #region Global Imports
import * as React from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { RelationshipType } from "@Interfaces/enums";
import { Checkbox } from "@Components/Basic";
import { ProfileActions } from "@Actions";
import { Button, FormBox } from "@Components";
// #endregion Local Imports

// #region Interface Imports
import { IRelationship } from "./Relationship";
// #endregion Interface Imports

export const Relationship: React.FunctionComponent<IRelationship.IProps> = ({
    t,
    onCloseClick,
    currentTypes,
}) => {
    const dispatch = useDispatch();
    const { useState } = React;

    const types: IRelationship.ICheckboxItem[] = Object.keys(RelationshipType)
        .map((rt: any) => {
            const value = RelationshipType[rt];

            if (typeof value === "number") {
                return { label: t(`common:enums.relationship.${rt}`), value };
            }

            return { label: "", value: -1 };
        })
        .filter(rt => rt.value > -1);

    const [selectedTypes, setSelectedTypes] = useState<number[]>(currentTypes);

    const saveRelationship = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const st = selectedTypes.join(",");
        if (st.length) {
            dispatch(await ProfileActions.UpdateProfileInfo({ relationshipTypes: st }));
        } else {
            window.alert(t("common:profileEdit.infoModal.relationship.noEmptySelection"));
        }
    };

    const handleChange = (e: React.ChangeEvent<any>) => {
        const typeId = parseInt(e.target.value, 10);

        if (!selectedTypes.includes(typeId)) {
            setSelectedTypes(st => [...st, typeId]);
        } else {
            setSelectedTypes(st => st.filter(item => item !== typeId));
        }
    };

    return (
        <FormBox
            title={t("common:profileEdit.infoModal.relationship.title")}
            onHandleSubmit={saveRelationship}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">
                {types.map((type, i) => {
                    return (
                        <Checkbox
                            key={`Update-Info-Modal-Relationship-Type-${i + 1}`}
                            checked={selectedTypes.includes(type.value)}
                            value={type.value}
                            onChange={handleChange}
                        >
                            {type.label}
                        </Checkbox>
                    );
                })}
            </div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
