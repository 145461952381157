// #region Global Imports
import styled, { keyframes } from "styled-components";
// #endregion Global Imports
const spin = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
    `;
export const Container = styled.div`
    display: flex;
`;

export const LoadingContent = styled.div`
    border: 7px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 50%;
    border-top: 7px solid ${({ theme }) => theme.colors.sliderDarkBlue};
    width: 60px;
    height: 60px;
    animation: ${spin} 2s linear infinite;
`;
