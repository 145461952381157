import * as React from "react";

const SvgLocationIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 12 17" {...props}>
        <path
            d="M11.744 6.14c0 .77-.122 1.422-.366 1.896l-3.972 8.178a1.182 1.182 0 01-.55.533c-.184.119-.428.178-.734.178-.305 0-.55-.06-.733-.178-.245-.118-.428-.355-.55-.533L.867 8.036C.622 7.562.5 6.91.5 6.14c0-1.482.55-2.785 1.65-3.852C3.25 1.221 4.533.748 6.061.748s2.872.533 3.911 1.54c1.222 1.067 1.772 2.37 1.772 3.852h0zm-2.81 0c0-.77-.245-1.422-.795-1.896-.55-.534-1.222-.77-1.956-.77-.733 0-1.405.236-1.955.77a2.554 2.554 0 00-.795 1.896c0 .711.245 1.363.795 1.896.55.534 1.222.77 1.955.77.734 0 1.406-.236 1.956-.77.55-.533.794-1.185.794-1.896h0z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgLocationIcon;
