// #region Global Imports
import { Dispatch } from "redux";
import { Analytics, StickerService } from "@Services";
import { ActionConsts } from "@Definitions";
// #endregion Global Imports

export const StickerActions = {
    BuySticker: async (StickerId: number) => async (dispatch: Dispatch) => {
        const response = await StickerService.Buy(String(StickerId));

        if (!response) {
            throw new Error("Error");
        }

        Analytics.event("Spent Coin", { sa_feature: "sticker" });

        dispatch({
            payload: response,
            type: ActionConsts.Inbox.FetchStickers,
        });
    },
};
