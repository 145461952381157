// #region Global Imports
import React, { useState, useEffect, useCallback } from "react";
import { createPopper } from "@popperjs/core";
// #endregion Global Imports

// #region Local Imports
import { ITooltip } from "./Tooltip";
import { useOutsideClick } from "@Definitions";
import { TooltipContainer } from "./styled";
// #endregion Local Imports

export const Slug: React.FunctionComponent<ITooltip.IProps> = ({
    children,
    placement,
    customStyle,
}): JSX.Element => {
    const [state, setState] = useState<ITooltip.LocalState>({
        didMount: false,
        popped: false,
    });

    const togglePopper = useCallback(
        (currentState, explicit?: boolean) => ({
            ...currentState,
            popped: typeof explicit !== "undefined" ? explicit : !currentState.popped,
        }),
        []
    );

    useOutsideClick(({ target }) => {
        const { host, tooltip } = state;

        if (!tooltip || !host || !target) return;

        if (host.contains(target as Node)) {
            if (!tooltip.contains(target as Node)) {
                setState(togglePopper);

                return;
            }
        }

        setState(currentState => togglePopper(currentState, false));
    });

    const initPopper = useCallback(
        tooltip => {
            const host = tooltip?.parentElement;

            if (!tooltip || !host) return;

            createPopper(host, tooltip, {
                placement,
                modifiers: [
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            mainAxis: false,
                            altAxis: false,
                            tether: false,
                            rootBoundary: "document",
                        },
                    },
                    {
                        name: "computeStyles",
                        options: {
                            adaptive: false,
                        },
                    },
                    {
                        name: "flip",
                        enabled: false,
                    },
                    {
                        name: "eventListeners",
                        options: {
                            scroll: false,
                            resize: false,
                        },
                    },
                ],
            });

            setState(currentState => ({
                ...currentState,
                host,
                tooltip,
            }));
        },
        [placement]
    );

    return (
        <TooltipContainer
            onClick={() => setState(currentState => togglePopper(currentState, false))}
            ref={initPopper}
            customStyle={customStyle}
            popped={state.popped}
        >
            <div>{children}</div>
        </TooltipContainer>
    );
};

export const Tooltip: React.FunctionComponent<ITooltip.IProps> = (props): JSX.Element => {
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
    }, []);

    if (!didMount) return <div />;

    return <Slug {...props} />;
};
