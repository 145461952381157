import * as React from "react";

const SvgSiberalemHearts = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 66 65" {...props}>
        <path
            d="M33.036 0C51.053 0 65.63 14.535 65.63 32.5S51.053 65 33.036 65C15.018 65 .44 50.465.44 32.5S15.018 0 33.036 0zM19.94 35.502c-3.267-2.729-6.976.847-5.783 4.659.794 2.54 3.532 3.623 5.607 4.423 2.163.847 2.737.988 3.444 1.364.353-.8.706-1.27 1.987-3.294 1.236-1.929 2.825-4.61 1.986-7.152-1.192-3.764-6.137-4.235-7.24 0zm19.638-11.83c-2.746-7.2-11.65-5.423-13.112 1.459-.975 4.649 2.348 8.887 4.873 12.077 2.613 3.327 3.367 4.102 4.164 5.378 1.24-.82 2.215-1.23 5.98-3.053 3.589-1.732 8.329-4.193 9.347-8.842 1.462-6.79-5.891-12.35-11.252-7.018z"
            fill="#FFF"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSiberalemHearts;
