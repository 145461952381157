// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 255px;
`;

const Item = styled.div`
    a {
        display: flex;
        padding: 4px;
        margin: 10px;
        :hover {
            background-color: ${({ theme }) => theme.colors.lightBlue};
            cursor: pointer;
        }
    }
`;

const Friend = styled.div`
    display: flex;
    flex-direction: column;
    span:first-of-type {
        margin-left: 5px;
    }
`;
const Text = styled.span<{ hideText?: boolean }>`
    align-self: center;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.mediumGray};
    text-align: left;
    ${({ hideText }) =>
        hideText &&
        ` 
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`;
const Comma = styled.span<{ hideText?: boolean }>`
    align-self: center;
    margin-right: 3px;
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.mediumGray};
`;
const HR = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin: 0 9.5px;
`;

export { Container, Friend, Item, Text, HR, Comma };
