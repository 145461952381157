// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic";
import { useScreenSize, getCustomSliderConfig } from "@Definitions";
import { HappyCoupleCard, SmartSlider } from "@Components";
import { IHappyCoupleContainer } from "./HappyCoupleContainer";
import { CardColumn, Container, FlexWrapper, SectionIndicator, SectionTitle } from "./styled";
import data from "./HappyCoupleContainer.data";
// #endregion Local Imports

const getUniqueKey = (index: number): string => `hpc-card-col-${index}`;

const renderCards = (cards: IHappyCoupleContainer.Cards, screenType: string): JSX.Element => {
    const customSliderConfig = getCustomSliderConfig(screenType);

    const slides = cards.map((card, index) => (
        <CardColumn key={getUniqueKey(index)}>
            <HappyCoupleCard {...card} />
        </CardColumn>
    ));

    return <SmartSlider {...customSliderConfig} slides={slides} />;
};

export const HappyCoupleContainer: React.FunctionComponent<IHappyCoupleContainer.IProps> = (
    props
): JSX.Element => {
    const { t } = props;

    const screenType = useScreenSize();

    return (
        <Container className="container">
            <FlexWrapper dir="column" className="row">
                <SectionIndicator className="mb-2">
                    {t("common:HappyCoupleContainerSectionIndicator")}
                </SectionIndicator>

                <SectionTitle>{t("common:HappyCoupleContainerSectionTitle")}</SectionTitle>

                <FlexWrapper dir="row" className="slider-row">
                    {renderCards(data, screenType)}
                </FlexWrapper>

                <FlexWrapper dir="row" className="call-to-action-buttons">
                    <div className="px-lg-4">
                        <a href="//www.siberalem.com/mutluluk-hikayeleri/" target="_blank" rel="noreferrer">
                            <Button size="large" block>
                                TÜM MUTLU ÇİFTLERİMİZ
                            </Button>
                        </a>
                    </div>
                    <div className="px-lg-4">
                        <a href="//www.siberalem.com/mutluluk-hikayeni-gonder/" target="_blank" rel="noreferrer">
                            <Button size="large" block>
                                HİKAYENİ PAYLAŞ
                            </Button>
                        </a>
                    </div>
                </FlexWrapper>
            </FlexWrapper>
        </Container>
    );
};
