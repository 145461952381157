// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Card as BasicCard } from "@Components/Basic";
// #endregion Local Imports

export const Card = styled(BasicCard)`
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 170px;
    }
`;

export const IconContainer = styled.div`
    overflow: hidden;
    border-radius: 50%;
    width: 90px;
    height: 90px;
`;

export const Button = styled.div`
    overflow: hidden;
    border-radius: 30px;
    width: 120px;
    height: 44px;
`;
