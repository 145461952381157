// #region Global Imports
import * as React from "react";
import styled from "styled-components";
import { getEnvSafely } from "@Definitions/Wrappers";
// #endregion Global Imports

// #region Local Imports
import { IImage } from "./Image";
// #endregion Local Imports

const Container = styled.img<IImage.IProps>`
    display: block;
`;

const handleSource = (src: string) =>
    src.match(/(http)|(www\.)/) ? src : `${getEnvSafely("STATIC_PATH")}/${src}`;

const getPropsWithAbsoluteUrl = (props: IImage.IProps) => ({
    ...props,
    src: handleSource(props.src),
});

export const Image: React.FunctionComponent<IImage.IProps> = (props) => {
    const { loading } = props;
    return <Container loading={loading || "lazy"} {...getPropsWithAbsoluteUrl(props)} />;
};
