export enum Gender {
    female = 1,
    male = 2,
    all = 3,
}

export enum ApiStatus {
    init = 1,
    loading = 2,
    loaded = 3,
    failed = 4,
}

export enum MaritalStatus {
    single = 1,
    married = 2,
    widow = 3,
    divorced = 4,
    separate = 5,
}

export enum Zodiac {
    Scorpio = 1,
    Leo = 2,
    Pisces = 3,
    Virgo = 4,
    Taurus = 5,
    Gemini = 6,
    Aries = 7,
    Aquarius = 8,
    Capricorn = 9,
    Libra = 10,
    Sagittarius = 11,
    Cancer = 12,
}

export enum MembershipType {
    normal = 1,
    gold = 3,
}

export enum CoinNotificationType {
    Initial = 1,
    LoginBonus = 7,
    QuestionOccupancyBonus = 11,
}

export enum CoinTransactionType {
    PrioritizedConversation = 3,
    PutMeUpHere = 4,
    LikeAvatar = 6,
    StickerSet = 13,
}

export enum ProductType {
    Gold = 1,
    Credit = 2,
}

export enum AccountSettingsErrors {
    EmailExists = 10,
    InvalidPassword = 9,
}

export enum ProfileQuestionType {
    PresentationTitle = 5,
    AboutMeTitle = 6,
}

export enum RelationshipType {
    friendship = 2,
    shortTerm = 8,
    marriage = 32,
    email = 4,
    longTerm = 16,
}

export enum DurationUnits {
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4,
}
