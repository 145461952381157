// #region Global Imports
import React, { MouseEvent, KeyboardEvent } from "react";
// #endregion Global Imports

// #region Interface Imports
import { IAnchor } from "./Anchor";
import { StyledAnchor } from "./styled";
// #endregion Interface Imports

export const Anchor: React.FunctionComponent<IAnchor.IProps> = ({
    children,
    className,
    onClick,
}) => {
    const handeClick = (e: MouseEvent | KeyboardEvent) => {
        e.preventDefault();
        if (onClick) onClick();
    };

    return (
        <StyledAnchor
            tabIndex={0}
            role="button"
            className={className}
            onClick={handeClick}
            onKeyPress={handeClick}
        >
            {children}
        </StyledAnchor>
    );
};
