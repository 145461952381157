/* eslint-disable no-sparse-arrays */

/**
 * Try-catch like a boss 😎
 * @param throwable Throwable function usually wrapped in try-catch
 */
export const noTry = async (throwable: Function) => {
    try {
        const result = await throwable();

        return [result];
    } catch (e) {
        return [, e];
    }
};
