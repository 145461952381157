// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IMobilePaymentMethod } from "./MobilePaymentMethod";
// #endregion Interface Imports

export const MobilePaymentMethod: React.FunctionComponent<IMobilePaymentMethod.IProps> = ({
    isActive,
    src,
    alt,
    onClick,
}) => {
    return (
        <Container isActive={isActive} onClick={onClick}>
            <img src={src} alt={alt} />
        </Container>
    );
};
