import * as React from "react";

const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1a9 9 0 100 18 9 9 0 000-18zm1 7.094v8H9v-8h2zm-1-4.188c.456 0 .82.135 1.092.404.272.27.408.618.408 1.044 0 .452-.136.824-.408 1.115-.272.291-.636.437-1.092.437-.449 0-.81-.141-1.086-.424-.276-.283-.414-.641-.414-1.076 0-.435.138-.794.414-1.076.275-.283.637-.424 1.086-.424z"
            fill="#8791A1"
            fillRule="nonzero"
        />
    </svg>
);

export default SvgInfo;
