// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
`;

export const HeaderContainer = styled(Container)`
    height: auto;
`;

export const ContentContainer = styled.div<{ isSticky?: boolean }>``;

ContentContainer.displayName = "ContentContainer";
