// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { theme } from "@Definitions/Styled";
import { Anchor } from "@Components/Basic";
// #endregion Local Imports

export const Container = styled.div`
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    min-height: 130px;
`;

export const FilterContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
`;

export const AgeGroup = styled.div`
    position: relative;
    margin-right: 8px;
    color: ${theme.colors.sliderDarkBlue};
`;

export const AgeToggleButton = styled(Anchor)<{ isActive: boolean }>`
    font-weight: ${theme.fontWeights.medium};
    font-size: 12px;
    width: 88px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid
        ${({ isActive }) => (isActive ? theme.colors.sliderAqua : theme.colors.lighterGray)};
    transition: border ease-in-out 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Fader = styled.div`
    background: transparent;
    position: absolute;
    padding-top: 5px;
    z-index: 100;
`;

export const HandyFilters = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SwitchButtons = styled.div`
    display: flex;
    align-items: center;

    a:first-of-type {
        font-weight: ${theme.fontWeights.bold};
        font-size: 12px;
        color: ${theme.colors.gray};
        margin-right: 5px;
        span {
            color: ${theme.colors.sliderDarkBlue};
        }
    }
`;

export const SwitchButton = styled(Anchor)`
    .mr {
        margin-right: 24px;

        @media screen and (max-width: ${theme.maxWidth.large}) {
            margin-right: 16px;
        }
    }
`;

export const LocationGroup = styled.div`
    width: 500px;
    position: relative;
`;

export const OtherGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
`;
export const DropdownItem = styled.div`
    margin-bottom: 8px;
    margin-right: 8px;
    min-width: 170px;
`;

export const ApplyButton = styled.div`
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 35px;
`;
