// #region Global imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "next-i18next";
// #endregion Global imports

// #region Local imports
import { InteractionActions } from "@Actions";
import { IStore, IProfileInteractionsContainer } from "@Interfaces";
// #endregion Local imports

export const useFriendsPage = (t: TFunction) => {
    const dispatch = useDispatch();

    const friendsData = useSelector((state: IStore) => state.interaction.interactions.friends);

    const onlineFriendsData = useSelector(
        (state: IStore) => state.interaction.interactions.onlineFriends
    );
    const showMore = useSelector((state: IStore) => state.interaction.showMore);

    const [friendPage, setFriendPage] = React.useState(0);
    const [moreButtonDisabled, setMoreButtonDisabled] = React.useState(false);
    const [friendType, setFriendType] = React.useState<
        IProfileInteractionsContainer.InteractionsTitle["actionType"]
    >("GetFriends");

    const moreButtonClick = async () => {
        setMoreButtonDisabled(true);
        if (friendType) await dispatch(await InteractionActions[friendType](friendPage + 1, 24));
        setFriendPage(friendPage + 1);
        setMoreButtonDisabled(false);
    };

    const handleInteractions = async (
        type: IProfileInteractionsContainer.InteractionsTitle["actionType"]
    ) => {
        setFriendType(type);
        if (type) await dispatch(await InteractionActions[type](0, 24));
        setFriendPage(0);
    };

    const titleObj: IProfileInteractionsContainer.InteractionsTitle[] = [
        {
            key: "friends",
            title: t("common:Friends.allFriends"),
            emptyTitle: t("common:EmptyView.Hiç arkadaşın yok"),
            isActive: friendType === "GetFriends",
            badgeCount: friendsData.Count || 0,
            actionType: "GetFriends",
            hasDeleteButton: false,
        },
        {
            key: "onlineFriends",
            title: t("common:Friends.onlineFriends"),
            emptyTitle: t("common:EmptyView.Hiç arkadaşın yok"),
            isActive: friendType === "GetOnlineFriends",
            badgeCount: onlineFriendsData.Count || 0,
            actionType: "GetOnlineFriends",
            hasDeleteButton: false,
        },
    ];

    const findData = () => {
        switch (friendType) {
            case "GetFriends":
                return friendsData;
            case "GetOnlineFriends":
                return onlineFriendsData;
            default:
                return friendsData;
        }
    };

    const onFriendButtonClick = async (userId: string) => {
        dispatch(await InteractionActions.RemoveFriend(userId));
    };

    const onWinkButtonClick = async (userId: string) => {
        dispatch(await InteractionActions.Wink(userId, "friends list"));
    };

    return {
        titleObj,
        handleInteractions,
        findData,
        moreButtonClick,
        showMore,
        moreButtonDisabled,
        onFriendButtonClick,
        onWinkButtonClick,
    };
};
