// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { CampaignSerializer } from "@Definitions/CampaignSerializer";
import { ActionConsts, ThrowIfNotAuthenticated } from "@Definitions";
import { OfferService } from "@Services";
import { IStore } from "@Interfaces";
// #endregion Local Imports

export const OfferActions = {
    GetOffer: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const { user } = getState().auth;
        if (!user || !user.UserId) {
            ThrowIfNotAuthenticated(getState);
        }

        dispatch({
            type: ActionConsts.Offer.Request,
        });

        try {
            const result = await OfferService.GetOffer(user!.UserId);

            dispatch({
                payload: {
                    data: result,
                },
                type: ActionConsts.Offer.Success,
            });

            if (result) {
                const campaigns = JSON.parse(JSON.stringify(getState().campaign.data));
                if (Array.isArray(campaigns)) {
                    const offer = CampaignSerializer.OfferToCampaign(result);
                    campaigns.unshift(offer);
                }

                dispatch({
                    payload: campaigns,
                    type: ActionConsts.Campaign.Active.Success,
                });
            }
        } catch (error) {
            dispatch({
                payload: {
                    data: undefined,
                },
                type: ActionConsts.Offer.Success,
            });
        }
    },
};
