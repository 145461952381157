// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

const expandCss = css`
    justify-content: center;
    max-height: 200px;
    height: 100px;
    margin-top: -70px;

    background: ${({ theme }) => theme.colors.mediumGray};

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: -45px;
    }
`;

const collapseCss = css`
    justify-content: center;
    max-height: 68px;
    background: ${({ theme }) => theme.colors.mediumGray};
`;

export const Container = styled.div<{
    customStyle?: CSSProp;
    isOverflow?: boolean;
    isTransform: boolean;
}>`
    display: flex;
    position: relative;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.mediumGray};
    border-radius: 0.4em;
    margin-bottom: 10px;
    min-width: 150px;
    ${({ isOverflow }) =>
        isOverflow &&
        `   cursor:pointer
    `};
    ${({ isTransform }) => (isTransform ? expandCss : collapseCss)};
    :after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;

        height: 0;
        border: 12px solid transparent;
        border-top-color: ${({ theme }) => theme.colors.mediumGray};
        border-bottom: 0;
        border-left: 0;
        margin-left: -6px;
        margin-bottom: -12px;
        width: 15px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 17px;
    }
    ${({ customStyle }) => customStyle && customStyle}
`;

export const Text = styled.div<{ isTransform?: boolean }>`
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    div {
        font-family: "Montserrat";
    }
`;
