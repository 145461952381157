// #region Global Imports
import Nookies from "nookies";
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { Gender } from "@Interfaces/enums";
import { AccountService, Analytics, FacebookService } from "@Services";
import { ActionConsts, Redirect, FetchDashboardData } from "@Definitions";
import { IAccountActions, IStore, AccountModel } from "@Interfaces";
import { HomeActions } from "@Actions";
// #endregion Local Imports

const saveUserCookie = (
    userObj: { user: Partial<AccountModel.LoginResponse> },
    remember?: boolean
) => {
    const options: { path: string; maxAge?: number } = {
        path: "/",
        maxAge: remember ? 3 * 30 * 24 * 60 * 60 : undefined,
    };

    Nookies.set(null, "account", JSON.stringify(userObj), options);
};

const logUserIn = (result: AccountModel.LoginResponse, dispatch: Function, remember?: boolean) => {
    const userObj = { user: result };
    saveUserCookie(userObj, remember);

    dispatch({
        payload: userObj,
        type: ActionConsts.Account.Login,
    });

    FetchDashboardData(dispatch);

    Redirect(undefined, "dashboard", "replace");
};

const updateCookie = (Token: string, UserId: string) => {
    const userObj = {
        user: { Token, UserId },
    };

    saveUserCookie(userObj);
};

const resetAllReducers = (dispatch: Dispatch) => {
    const resetTypes = [
        ActionConsts.Dashboard.ResetReducer,
        ActionConsts.Home.ResetReducer,
        ActionConsts.Account.Logout,
        ActionConsts.Profile.ResetReducer,
        ActionConsts.Interaction.ResetReducer,
        ActionConsts.Common.ResetReducer,
        ActionConsts.Search.ResetReducer,
        ActionConsts.SearchFilters.ResetReducer,
        ActionConsts.MetaData.ResetReducer,
        ActionConsts.Campaign.ResetReducer,
        ActionConsts.Payment.ResetReducer,
        ActionConsts.Inbox.ResetReducer,
        ActionConsts.Invoice.ResetReducer,
        ActionConsts.Offer.ResetReducer,
    ];

    resetTypes.forEach(type => {
        dispatch({ type });
    });
};

export const AccountActions = {
    LoginWithSecureToken: (userData: AccountModel.LoginResponse) => async (dispatch: Dispatch) => {
        dispatch({
            payload: { isAuthenticating: true },
            type: ActionConsts.Account.AuthStateChange,
        });

        logUserIn(userData, dispatch);
        Analytics.setUserId(userData.UserId);
    },
    Login: (payload: IAccountActions.ILogin, remember?: boolean) => async (dispatch: Dispatch) => {
        dispatch({
            payload: { isAuthenticating: true },
            type: ActionConsts.Account.AuthStateChange,
        });

        const result = await AccountService.Login(payload);

        // eslint-disable-next-line default-case
        switch (result.Reason) {
            case "Success":
            case "ProfileInReview":
                logUserIn(result, dispatch, remember);
                Analytics.setUserId(result.UserId);
                break;
            case "Fail":
                dispatch({
                    payload: {
                        user: result,
                    },
                    type: ActionConsts.Account.LoginFail,
                });
                break;
            case "Captcha":
                dispatch({
                    payload: {
                        captcha: { isRequired: true, token: "" },
                    },
                    type: ActionConsts.Account.Captcha,
                });
                break;
        }

        dispatch({
            payload: { isAuthenticating: false },
            type: ActionConsts.Account.AuthStateChange,
        });
    },
    LoginReset: () => async (dispatch: Dispatch) => {
        dispatch({
            payload: {
                user: {},
            },
            type: ActionConsts.Account.LoginReset,
        });
    },
    Register:
        (payload: IAccountActions.IRegister, onSuccess?: () => void) =>
        async (dispatch: Dispatch, getState: () => IStore) => {
            const result = await AccountService.Register(payload);
            const { isUserGuest } = getState().common;

            if (result.Success === true && result.Token) {
                updateCookie(result.Token, result.Id);

                await AccountActions.Login(payload)(dispatch);

                // Send Analytics
                Analytics.event("Completed Registration", {
                    sa_su_is_guest_register: isUserGuest || false,
                    register_source: "landing",
                });

                Analytics.accountRegister(result.Id);

                dispatch({
                    payload: true,
                    type: ActionConsts.Common.SetNewRegister,
                });

                if (onSuccess) onSuccess();
            } else if (result.Success === false) {
                console.log("RegisterFail", result);
            }
        },
    ForgotPassword: (payload: IAccountActions.IForgotPassword) => async () => {
        await AccountService.ForgotPassword(payload);
    },
    LoginWithApple: (payload: IAccountActions.IAppleLogin) => async (dispatch: Dispatch) => {
        const result = await AccountService.LoginWithApple(payload);

        // eslint-disable-next-line default-case
        switch (result.Reason) {
            case "Success":
            case "ProfileInReview":
                logUserIn(result, dispatch);

                // Send Analytics
                Analytics.setUserId(result.UserId);
                break;
            case "Fail":
                dispatch(HomeActions.SetTopComponent({ topComponent: "register" }));

                const { sub, email } = result.JWTResponse;
                const failPayload = {
                    userId: sub,
                    mail: email,
                };
                dispatch({
                    payload: failPayload,
                    type: ActionConsts.Account.AppleLogin.AppleRegister,
                });

                break;
        }
    },
    LoginWithFacebook: (payload: IAccountActions.IFacebookLogin) => async (dispatch: Dispatch) => {
        const me = await FacebookService.me();

        const result = await AccountService.LoginWithFacebook({
            body: { ...payload.body, facebookId: me.id },
        });

        // eslint-disable-next-line default-case
        switch (result.Reason) {
            case "Success":
            case "ProfileInReview":
                logUserIn(result, dispatch);

                // Send Analytics
                Analytics.setUserId(result.UserId);
                break;
            case "Fail":
                dispatch({ payload: me, type: ActionConsts.Account.FacebookRegister });
                dispatch(HomeActions.SetTopComponent({ topComponent: "register" }));
                break;
        }
    },
    RegisterWithFacebook:
        (payload: IAccountActions.IRegister, onSuccess?: () => void) =>
        async (dispatch: Dispatch, getState: () => IStore) => {
            const fbState = getState().auth.social.facebook;
            const fbToken = fbState.token || "";
            const fbId = fbState.me?.id || "";
            const { isUserGuest } = getState().common;

            const result = await AccountService.RegisterWithFacebook({
                body: { ...payload.body, FacebookAccessToken: fbToken, FacebookUserId: fbId },
            });

            if (result.Success === true) {
                AccountActions.LoginWithFacebook({ body: { accessToken: fbToken } })(dispatch);
                // Send Analytics

                Analytics.event("Completed Registration", {
                    sa_su_is_guest_register: isUserGuest || false,
                    register_source: "landing",
                });

                Analytics.accountRegister(result.Id);
                dispatch({
                    payload: true,
                    type: ActionConsts.Common.SetNewRegister,
                });

                if (onSuccess) onSuccess();
            } else if (result.Success === false) {
                console.log("RegisterFail", result);
            }
        },
    RegisterWithApple:
        (payload: IAccountActions.IRegister, onSuccess?: () => void) =>
        async (dispatch: Dispatch, getState: () => IStore) => {
            const appleState = getState().auth.social.apple;
            const appleId = appleState?.userId || "";
            const { isUserGuest } = getState().common;

            const result = await AccountService.RegisterWithApple({
                body: { ...payload.body, AppleUserId: appleId },
            });

            if (result.Success === true) {
                const { Id, Token } = result;
                logUserIn(
                    {
                        UserId: Id!,
                        Token: Token!,
                        Reason: "Success",
                        ForceUpdate: false,
                    },
                    dispatch
                );
                // Send Analytics

                Analytics.event("Completed Registration", {
                    sa_su_is_guest_register: isUserGuest || false,
                    register_source: "landing",
                });

                Analytics.accountRegister(Id);
                dispatch({
                    payload: true,
                    type: ActionConsts.Common.SetNewRegister,
                });

                if (onSuccess) onSuccess();
            } else if (result.Success === false) {
                console.log("RegisterFail", result);
            }
        },
    LoginAsGuest: (payload: IAccountActions.ILoginAsGuest) => async (dispatch: Dispatch) => {
        const credentials = {
            username: payload.gender === Gender.female ? "misafir2" : "misafir1",
            password: "123123",
        };

        Analytics.event("Login with Guest User", {
            sa_page: "landing",
            sa_su_gender: payload.gender === 1 ? "female" : "male",
        });

        dispatch({
            payload: true,
            type: ActionConsts.Common.SetGuestUser,
        });

        AccountActions.Login({ body: credentials })(dispatch);
    },
    ResetPassword: (SecureKey: string, NewPassword: string) => async (dispatch: Dispatch) => {
        const payload = {
            body: {
                SecureKey,
                NewPassword,
            },
        };

        const { Success, Token, Id } = await AccountService.ResetPassword(payload);

        if (Success) {
            const user: AccountModel.LoginResponse = {
                Token,
                UserId: Id,
                Reason: "Success",
                ForceUpdate: false,
                HasActiveSubscription: false,
            };

            logUserIn(user, dispatch);

            // view login component on landing screen
            setTimeout(() => {
                dispatch(HomeActions.SetTopComponent({ topComponent: "card" }));
            }, 1000);
        }
    },
    Logout: () => async (dispatch: Dispatch) => {
        Nookies.destroy(undefined, "account", { path: "/" });

        // Send Analytics
        Analytics.reset();

        Redirect(undefined, "", "replace");

        resetAllReducers(dispatch);
    },
    ChangePassword: (password: string, newPassword: string) => async (dispatch: Dispatch) => {
        const payload = {
            body: {
                password,
                newPassword,
            },
        };
        const result = await AccountService.ChangePassword(payload);

        const { Success, Token, UserId, ReasonId } = result;
        if (Success) {
            const user: AccountModel.LoginResponse = {
                Token,
                UserId,
                Reason: "Success",
                ReasonId,
                ForceUpdate: false,
                HasActiveSubscription: false,
            };

            Nookies.set(null, "account", JSON.stringify({ user }), {
                maxAge: 30 * 24 * 60 * 60,
                path: "/",
            });

            dispatch({
                payload: {
                    user,
                    changePassword: result,
                },
                type: ActionConsts.Account.ChangePasswordSuccess,
            });
        } else {
            dispatch({
                payload: {
                    changePassword: result,
                },
                type: ActionConsts.Account.ChangePasswordFail,
            });
        }
    },
    ChangePasswordReset: () => async (dispatch: Dispatch) => {
        dispatch({
            payload: {
                changePassword: {},
            },
            type: ActionConsts.Account.ChangePasswordSuccess,
        });
    },
    ChangeMail: (NewEmail: string, Password: string) => async (dispatch: Dispatch) => {
        const payload: AccountModel.UpdateEmailRequestPayload = {
            NewEmail,
            Password,
        };
        const result = await AccountService.ChangeMail(payload);
        if (result.Success) {
            dispatch({
                payload: {
                    updateMail: result,
                },
                type: ActionConsts.Account.UpdateEmailSuccess,
            });
        } else
            dispatch({
                payload: {
                    updateMail: result,
                },
                type: ActionConsts.Account.UpdateEmailFail,
            });
    },
    ChangeMailFacebook:
        (NewEmail: string, apple: AccountModel.FacebookUserPayload) =>
        async (dispatch: Dispatch) => {
            const { facebookAccessToken, facebookUserId } = apple;

            const payload: AccountModel.UpdateEmailFacebookRequestPayload = {
                NewEmail,
                facebookAccessToken,
                facebookUserId,
            };

            const result = await AccountService.ChangeMail(payload);
            if (result.Success) {
                dispatch({
                    payload: {
                        updateMail: result,
                    },
                    type: ActionConsts.Account.UpdateEmailSuccess,
                });
            } else
                dispatch({
                    payload: {
                        updateMail: result,
                    },
                    type: ActionConsts.Account.UpdateEmailFail,
                });
        },
    ChangeMailApple:
        (NewEmail: string, apple: AccountModel.AppleUserPayload) => async (dispatch: Dispatch) => {
            const { appleAccessToken, state } = apple;

            const payload: AccountModel.UpdateEmailAppleRequestPayload = {
                NewEmail,
                appleAccessToken,
                state,
            };

            const result = await AccountService.ChangeMail(payload);

            if (result.Success) {
                dispatch({
                    payload: {
                        updateMail: result,
                    },
                    type: ActionConsts.Account.UpdateEmailSuccess,
                });
            } else
                dispatch({
                    payload: {
                        updateMail: result,
                    },
                    type: ActionConsts.Account.UpdateEmailFail,
                });
        },
    ChangeMailReset: () => async (dispatch: Dispatch) => {
        dispatch({
            payload: {
                updateMail: {},
            },
            type: ActionConsts.Account.UpdateEmailReset,
        });
    },
    Resign:
        (customReason: string, option: number, password: string) => async (dispatch: Dispatch) => {
            type ResignRequestPayload = AccountModel.DeactivateAccountPayload;

            const payload: ResignRequestPayload = {
                customResignText: customReason,
                resignReason: option,
                password,
            };
            const result = await AccountService.Resign(payload);
            if (result.Success) {
                Nookies.destroy(undefined, "account", { path: "/" });

                dispatch({ payload: {}, type: ActionConsts.Account.ResignSuccess });
                resetAllReducers(dispatch);
                Redirect(undefined, "", "replace");
            } else
                dispatch({
                    payload: {
                        resign: result,
                    },
                    type: ActionConsts.Account.ResignFail,
                });
        },
    ResignFacebook:
        (customReason: string, option: number, fbUser: AccountModel.FacebookDeactiveUserPayload) =>
        async (dispatch: Dispatch) => {
            type ResignRequestPayload = AccountModel.DeactivateFacebookAccountPayload;

            const { facebookAccessToken, facebookUserId } = fbUser;

            const payload: ResignRequestPayload = {
                customResignText: customReason,
                resignReason: option,
                facebookAccessToken,
                facebookUserId,
            };
            const result = await AccountService.Resign(payload);
            if (result.Success) {
                Nookies.destroy(undefined, "account", { path: "/" });

                dispatch({ payload: {}, type: ActionConsts.Account.ResignSuccess });
                resetAllReducers(dispatch);
                Redirect(undefined, "", "replace");
            } else
                dispatch({
                    payload: {
                        resign: result,
                    },
                    type: ActionConsts.Account.ResignFail,
                });
        },
    ResignApple:
        (customReason: string, option: number, apple: AccountModel.AppleUserPayload) =>
        async (dispatch: Dispatch) => {
            type ResignRequestPayload = AccountModel.DeactivateAppleAccountPayload;

            const { appleAccessToken, state } = apple;

            const payload: ResignRequestPayload = {
                customResignText: customReason,
                resignReason: option,
                appleAccessToken,
                state,
            };

            const result = await AccountService.Resign(payload);

            if (result.Success) {
                dispatch({ payload: {}, type: ActionConsts.Account.ResignSuccess });
                resetAllReducers(dispatch);
            } else {
                dispatch({
                    payload: {
                        resign: result,
                    },
                    type: ActionConsts.Account.ResignFail,
                });
            }
        },
    ResignReset: () => async (dispatch: Dispatch) => {
        dispatch({
            payload: {
                resign: {},
            },
            type: ActionConsts.Account.ResignReset,
        });
    },
};
