import * as React from "react";

const SvgBubbleMark = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <path
            d="M2 16.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm9.5-16a8 8 0 11-2.469 15.612 3 3 0 11-4.573-3.81A8 8 0 0111.5.5zm0 1a7 7 0 100 14 7 7 0 000-14zm-.113 9.712c.246 0 .453.084.62.252a.844.844 0 01.253.62c0 .274-.088.49-.264.645a.89.89 0 01-.61.235.922.922 0 01-.623-.232c-.178-.154-.267-.37-.267-.647 0-.246.086-.453.258-.621a.87.87 0 01.633-.252zm.14-7.12c.567 0 1.067.105 1.5.314.434.21.769.493 1.005.853.237.36.355.75.355 1.172 0 .332-.068.623-.202.873-.135.25-.295.465-.48.647l-.39.366c-.166.152-.37.336-.61.551-.133.121-.24.228-.32.32-.08.091-.139.175-.178.251-.039.077-.07.153-.09.229l-.051.2-.046.201c-.075.407-.307.61-.698.61a.713.713 0 01-.512-.2c-.14-.132-.208-.33-.208-.591 0-.328.05-.613.152-.853.101-.24.236-.451.404-.633a8.18 8.18 0 01.516-.501l.43-.383c.117-.105.21-.191.276-.258a1.68 1.68 0 00.281-.372.9.9 0 00.114-.446c0-.312-.116-.576-.348-.79-.233-.216-.532-.323-.9-.323-.43 0-.746.108-.949.325-.17.18-.317.433-.443.756l-.072.202c-.133.442-.385.662-.756.662a.743.743 0 01-.554-.231c-.15-.154-.226-.321-.226-.501 0-.371.12-.747.358-1.128.238-.38.586-.696 1.043-.946.457-.25.99-.375 1.6-.375z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgBubbleMark;
