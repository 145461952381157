import * as React from "react";

const SvgWinkPlus = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 49 49" {...props}>
        <g fill="currentcolor" stroke="#fff" strokeWidth={0.5} fillRule="evenodd">
            <path d="M23.5 5.778c10.892 0 19.722 8.83 19.722 19.722 0 10.892-8.83 19.722-19.722 19.722-10.892 0-19.722-8.83-19.722-19.722 0-10.892 8.83-19.722 19.722-19.722zm0 1.972c-9.803 0-17.75 7.947-17.75 17.75s7.947 17.75 17.75 17.75 17.75-7.947 17.75-17.75S33.303 7.75 23.5 7.75zM13.39 29.694a14.359 14.359 0 002.386 1.926c5.611 3.61 13.054 2.854 17.835-1.926a1.48 1.48 0 112.092 2.092c-5.77 5.77-14.751 6.68-21.528 2.322a17.317 17.317 0 01-2.878-2.322 1.48 1.48 0 112.092-2.092zm3.531-10.11a2.958 2.958 0 110 5.916 2.958 2.958 0 010-5.917zm15.961 1.478a1.48 1.48 0 110 2.959h-5.916a1.48 1.48 0 010-2.959h5.916zM37.808 5.192h3.999l.001-4a1 1 0 112 0v4h4a1 1 0 010 2h-4v4a1 1 0 11-2 0l-.001-4h-3.999a1 1 0 010-2zM.838 43.662h3v-3a.75.75 0 011.5 0v3h3a.75.75 0 110 1.5h-3v3a.75.75 0 11-1.5 0v-3h-3a.75.75 0 010-1.5z" />
        </g>
    </svg>
);

export default SvgWinkPlus;
