// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container, Top, Center, PurchaseButton } from "./styled";
import { IGoldMembership } from "./GoldMembership";
import { ContentBox } from "../../styled";
// #endregion Local Imports

const GoldMembership: React.FunctionComponent<IGoldMembership.IProps> = ({
    onPurchaseClick,
    t,
}): JSX.Element => {
    const txt = (key: string) => t(`dashboard:HotOrNot.GoldMembership.${key}`);
    return (
        <ContentBox>
            <Container>
                <Top>
                    <span>{txt("top")}</span>
                </Top>
                <Center>
                    <span>{txt("center1")}</span>
                    <span>{txt("center2")}</span>
                    <span>{txt("center3")}</span>
                </Center>
                <div>
                    <PurchaseButton onClick={onPurchaseClick}>{txt("buttonText")}</PurchaseButton>
                </div>
            </Container>
        </ContentBox>
    );
};

export { GoldMembership };
