// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { CustomRadioInput } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IRadioButton } from "./RadioButton";
// #endregion Interface Imports

export const RadioButton: React.FunctionComponent<IRadioButton.IProps> = ({
    id,
    name,
    value,
    checked,
    labelText,
    ...otherProps
}) => {
    return (
        <CustomRadioInput>
            <input
                id={id}
                type="radio"
                value={value}
                checked={checked}
                name={name}
                {...otherProps}
            />
            <label htmlFor={id}>{labelText && <span>{labelText}</span>}</label>
        </CustomRadioInput>
    );
};
