// #region Global Imports
import styled, { css } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
    flex: 2;
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: 15px;
    }
    overflow: hidden;
    border-radius: 12px;
`;
export const CardTitleWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const CardInnerWrapper = styled.div`
    padding-right: 8px;
    padding-left: 8px;
    margin-top: 15px;
    text-align: center;
    height: 100%;
`;

export const CardScrollContent = styled.div`
    height: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const customCardCss = css`
    height: 100%;
`;

CardInnerWrapper.displayName = "CardInnerWrapper";
