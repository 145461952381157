// #region Local Imports
import Nookies from "nookies";
import { AmplitudeClient } from "amplitude-js";
import { getEnvSafely } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
// #endregion Interface Imports

let amplitude: AmplitudeClient;
let mixPanel: Mixpanel;

export const Analytics = {
    init: () => {
        // eslint-disable-next-line global-require
        amplitude = require("amplitude-js");
        amplitude?.init(getEnvSafely("AMPLITUDE_API_KEY"), undefined, {
            includeReferrer: true,
            includeUtm: true,
        });

        // eslint-disable-next-line global-require
        mixPanel = require("mixpanel-browser");
        console.log(mixPanel);
        mixPanel?.init(getEnvSafely("MIXPANEL_API_KEY"));

        const variantProperty = {
            sa_variant: "2020-react-web-app",
        };

        amplitude?.setUserProperties({ ...variantProperty });

        mixPanel?.register({
            ...variantProperty,
        });
    },
    event: (name: string, data?: object, isSample?: boolean) => {
        if (isSample) {
            const { account } = Nookies.get();
            if (account) {
                const parsedAccount = JSON.parse(account);
                const userId = parsedAccount.user.UserId;
                if (userId) {
                    if (userId % 100 !== 0) {
                        return;
                    }
                }
            }
        }

        if (data) {
            const extendDataWithVariant = {
                ...data,
                sa_variant: "2020-react-web-app",
            };
            amplitude?.logEvent(name, extendDataWithVariant);
            mixPanel?.track(name, extendDataWithVariant);
        } else {
            amplitude?.logEvent(name);
            mixPanel?.track(name);
        }
    },
    setUserId: (userId: string) => {
        amplitude?.setUserId(userId);
        mixPanel?.identify(userId);
        window.ga("send", "event", "Login", "Login", "UserId", userId);
    },
    setUserProperties: (data: object) => {
        amplitude?.setUserProperties(data);

        // replace registerSuperProperties to register
        mixPanel?.register({
            ...data,
        });
    },
    accountRegister: (userId: string) => {
        amplitude?.setUserId(userId);
        // replace createAlias to alias
        mixPanel?.alias(userId);

        window.ga("send", "event", "Üyelik", "Üye Ol Buton", "UserId", userId);
    },
    reset: () => {
        amplitude?.setUserId(null);
        mixPanel?.reset();
    },
    revenue: (productIdentifier: string, quantity: number, amount: number) => {
        amplitude?.logRevenue(amount, quantity, productIdentifier);
        mixPanel?.people.track_charge(amount);
    },
    pageEvent: () => {
        if (window.ga) {
            window.ga("send", "pageview", { page: window.location.pathname });
        }
    },
};
