// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { CampaignService } from "@Services/API/Campaign";
import { ActionConsts } from "@Definitions";
import { CampaignModel } from "@Interfaces";
// #endregion Local Imports

export const CampaignActions = {
    Active: async (payload: CampaignModel.ActivePayload) => async (dispatch: Dispatch) => {
        dispatch({
            type: ActionConsts.Campaign.Active.Request,
        });

        try {
            const result = await CampaignService.Active(payload);

            dispatch({
                payload: result.ActiveCampaigns,
                type: ActionConsts.Campaign.Active.Success,
            });

            if (result.Success) {
                dispatch({
                    payload: result.ActiveCampaigns,
                    type: ActionConsts.Campaign.Active.Success,
                });
            }
        } catch (e) {
            // Auth Error Response
            if (e.status === 406) {
                dispatch({
                    payload: [],
                    type: ActionConsts.Campaign.Active.Success,
                });

                return;
            }

            dispatch({
                type: ActionConsts.Campaign.Active.Failed,
            });
        }
    },
};
