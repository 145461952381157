// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding-left: 5px;
    padding-right: 13px;
    min-height: 32px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding-left: 7px;
        padding-right: 15px;
        min-height: 40px;
    }
`;

export const HandleWrapper = styled.div`
    input {
        display: none;
    }
`;

export const HandleContainer = styled.div<{ offset: number }>`
    margin-top: -24px;
    cursor: pointer;
    margin-left: ${({ offset }) => `calc(${offset}% - 26px)`};

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-left: ${({ offset }) => `calc(${offset}% - 31px)`};
    }
`;

export const HandleContent = styled.div<{ value: number }>`
    display: flex;
    justify-content: space-between;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    width: 36px;
    position: relative;
    color: ${({ theme }) => theme.colors.sliderDarkBlue};
    text-align: ${({ value }) => (value < 3 ? "left" : "right")};
    flex-direction: ${({ value }) => (value < 3 ? "row-reverse" : "row")};
    margin-left: ${({ value }) => (value < 3 ? "24px" : "0")};

    :hover {
        color: ${({ theme }) => theme.colors.sliderDarkBlueHover};
    }

    .info {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 10px;
        display: flex;
        flex-direction: column;
        margin-top: -2px;
        width: 24px;
    }

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 44px;
        margin-left: ${({ value }) => (value < 3 ? "26px" : "0")};
        font-size: 12px;

        svg {
            margin-right: 3px;
        }

        .info {
            margin-left: 1px;
            margin-top: -4px;
        }
    }
`;

export const HandleValue = styled.span`
    font-size: 10px;
    line-height: 7px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        font-size: 12px;
    }
`;

export const HandleUnit = styled.span`
    font-size: 9px;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        font-size: 11px;
    }
`;

export const StartIcon = styled.div<{ value: number }>`
    margin-top: -17px;
    margin-left: -3px;
    color: ${({ theme }) => theme.colors.sliderAqua};
    position: relative;
    opacity: ${({ value }) => (value < 4 ? "0.3" : "1")};

    svg {
        margin-top: -8px;
    }
    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-left: -5px;
    }
`;
