// #region Global Imports
import styled from "styled-components";
import { theme } from "@Definitions";
// #endregion Global Imports

export const Container = styled.div`
    width: 335px;
    height: 200px;
    border: 1px solid ${theme.colors.lightGray};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const IconContainer = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: ${theme.colors.badgeGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;
