// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;

    .col-forefront {
        z-index: 1;
    }
`;

export const ContentContainer = styled.div<{ isSticky?: boolean }>``;

ContentContainer.displayName = "ContentContainer";
