// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Image } from "@Components/Basic";
import { IPutMeUpHere } from "./PutMeUpHere";
import * as PMUH from "./styled";
import { CloseIcon } from "@Icons";
import { Preset, theme } from "@Definitions";
// #endregion Local Imports

export const Presentation: React.FunctionComponent<IPutMeUpHere.IPresentation> = ({
    t,
    screenType,
    isLoading,
    ContainsYou,
    userPhoto,
    users,
    handlePurchase,
    renderPromoteds,
    isRevealedAndXS,
    onPutMeThereClick,
    isPurchaseRevealed,
    setPurchaseRevealed,
}): JSX.Element => (
    <PMUH.Container>
        <PMUH.Row>
            <PMUH.Col className="col-12">
                <PMUH.RoundedBox flowOut={isRevealedAndXS()} isLoading={isLoading}>
                    <PMUH.PurchaseViewToggle onClick={onPutMeThereClick}>
                        <PMUH.One>
                            <Image src={`${userPhoto}${Preset.thumb}`} alt="Purchase" />
                        </PMUH.One>

                        {!ContainsYou && (
                        <PMUH.SideKick>
                            <CloseIcon width="12px" height="12px" color={theme.colors.white} />
                        </PMUH.SideKick>
                            )}
                    </PMUH.PurchaseViewToggle>

                    {isPurchaseRevealed && (
                    <PMUH.PurchaseView>
                        <PMUH.PurchaseViewText>
                            {t("common:PurchaseViewText", { credits: 300 })}
                        </PMUH.PurchaseViewText>

                        <PMUH.PurchaseViewCTA
                            onClick={handlePurchase}
                            role="button"
                            tabIndex={0}
                                />
                        <CloseIcon className="close-icon" onClick={() => setPurchaseRevealed(false)} width="20px" height="20px" color={theme.colors.white} />

                    </PMUH.PurchaseView>
                        )}

                    {renderPromoteds(screenType, users)}
                </PMUH.RoundedBox>
            </PMUH.Col>
        </PMUH.Row>
    </PMUH.Container>
    );
