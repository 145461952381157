// #region Global Imports
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { Presentation } from "./index.presentation";
// #endregion Local Imports

// #region Interface Imports
import { IStore } from "@Interfaces";
import { noTry } from "@Definitions";
import { SearchActions, DashboardActions } from "@Actions";
import { MasterVM, State, Component } from "./MasterDetailNav";
// #endregion Interface Imports

export const Container: Component = ({ t, masterAsPath, detailId }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({});

    const isComingFromSearch = masterAsPath.includes("search");

    // ? VM and total count selections depend on `master`
    const [masterViewModel, total] = useSelector(({ search, dashboard }: IStore): [
        MasterVM,
        number
    ] => {
        if (isComingFromSearch) return [search.searchResults, search.total];

        return [dashboard.recentPhotos.photos, dashboard.recentPhotos.total];
    });

    const fetchNextListingPage = useCallback(() => {
        noTry(async () => {
            if (isComingFromSearch) {
                dispatch(await SearchActions.MakeSearch(true));
            } else {
                const page = masterViewModel.length / 20;
                dispatch(await DashboardActions.GetRecentPhotos(page));
            }
        });
    }, [isComingFromSearch, masterViewModel, dispatch]);

    const goBack = () => Router.push(masterAsPath);

    const go = (id?: string) => {
        Router.push(`/profile/[id]?masterAsPath=${masterAsPath}`, `/profile/${id}`);
    };

    useEffect(() => {
        const currentIndex = masterViewModel.findIndex(({ Id }) => Id === detailId);

        const [prev, next] = [-1, +1].map(step => masterViewModel[currentIndex + step]?.Id);

        const canFetchMore = currentIndex + 1 < total;

        if (!next && canFetchMore) fetchNextListingPage();

        setState({ prev, next });
    }, [masterViewModel, detailId, total, fetchNextListingPage]);

    return Presentation({
        t,
        go,
        goBack,
        ...state,
    });
};
