import * as React from "react";

const SvgFaxCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18-.01c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18zm0 1C8.611.99 1 8.6 1 17.99s7.611 17 17 17 17-7.611 17-17-7.611-17-17-17zm6.25 7v5h2.375c.75 0 1.375.625 1.375 1.375h0v8.5c0 .75-.625 1.375-1.375 1.375h0-2.375v3.75h-12.5v-3.75H9.375c-.75 0-1.375-.625-1.375-1.375h0v-8.5c0-.75.625-1.375 1.375-1.375h2.375v-5h12.5zM23 19.24H13v7.5h10v-7.5zm3.625-5H9.365c-.028.003-.115.02-.115.125h0v8.51c.003.028.02.115.125.115h2.375v-5h12.5v5h2.375s.125 0 .125-.125h0v-8.5s0-.125-.125-.125h0zm-2.375 1.25c.375 0 .625.25.625.625s-.25.625-.625.625h0-.625c-.375 0-.625-.25-.625-.625s.25-.625.625-.625h0zM23 9.24H13v3.75h10V9.24z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgFaxCircle;
