// #region Global Imports
import * as React from "react";
import * as Yup from "yup";
import { useForm, ErrorMessage } from "react-hook-form/dist/react-hook-form.ie11";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { IDropdown } from "@Components/Basic/Dropdown/Dropdown";
import { Dropdown, Button, Text } from "@Components/Basic";
import { MetaDataActions, ProfileActions } from "@Actions";
import { FormBox } from "@Components";
// #endregion Local Imports

// #region Interface Imports
import { IJob } from "./Job";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

export const Job: React.FunctionComponent<IJob.IProps> = ({ t, onCloseClick, currentJobId }) => {
    const { useEffect, useState } = React;
    const dispatch = useDispatch();
    const jobs = useSelector((state: IStore) => state.metadata.jobs);

    useEffect(() => {
        (async () => {
            dispatch(await MetaDataActions.FetchJobs());
        })();
    }, []);

    const [selectedJob, setSelectedJob] = useState<IJob.ISelectOption>({ label: "", value: "" });
    const [options, setOptions] = useState<Array<IJob.ISelectOption>>([]);

    useEffect(() => {
        if (jobs.length) {
            const opts = jobs.map((item) => {
                return { value: item.Id.toString(), label: item.Name };
            });

            setOptions(opts);

            if (currentJobId) {
                const currentJobOption = opts?.find(
                    (op: IDropdown.ISelectOption) => op.value === currentJobId.toString()
                );

                if (currentJobOption) {
                    setSelectedJob(currentJobOption);
                }
            }
        }
    }, [jobs]);

    const FormSchema = Yup.object().shape({
        job: Yup.string().required(t("common:profileEdit.infoModal.job.errorText")),
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: FormSchema,
    });

    const onSubmit = async (values: any) => {
        dispatch(await ProfileActions.UpdateProfileInfo({ job: values.job }));
    };

    const renderDropdown = () => {
        return (
            <>
                <Dropdown
                    t={t}
                    placeholder={t("common:profileEdit.infoModal.job.title")}
                    options={options}
                    onChange={(option: { label: string; value: string }) => {
                        setValue("job", option.value);
                        setSelectedJob(option);
                    }}
                    value={selectedJob}
                    _ref={register(
                        {
                            name: "job",
                        },
                        {
                            required: true,
                        }
                    )}
                />
                <ErrorMessage errors={errors} name="job" as={<Text color="red" size="small" />} />
            </>
        );
    };

    return (
        <FormBox
            title={t(`common:profileEdit.infoModal.job.title`)}
            onHandleSubmit={handleSubmit(onSubmit)}
            onCloseClick={onCloseClick}
        >
            <div className="mb-2 p-10">{jobs && renderDropdown()}</div>
            <div className="d-flex justify-content-center mx-3">
                <Button disabled={false} type="submit">
                    {t("common:profileEdit.infoModal.buttonText")}
                </Button>
            </div>
        </FormBox>
    );
};
