import styled, { css } from "styled-components";
import { IButton } from "./Button";

// StyleTypes
const StyleTypeGradientCss = css`
    background: ${({ theme }) => theme.gradients.greenToBlue};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 30px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);

    span {
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    }

    :hover {
        background: ${({ theme }) => theme.gradients.greenToBlueHover};

        span {
            text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.5);
        }
    }
`;

const StyleTypeGhostCss = css`
    color: ${({ theme }) => theme.colors.facebook};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
`;

const StyleTypeMutedCss = css`
    border-radius: 30px;
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    color: ${({ theme }) => theme.colors.gray};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyleTypePlainCss = css`
    border-radius: 30px;
    border: 1px solid ${({ theme }) => theme.colors.facebook};
    color: ${({ theme }) => theme.colors.facebook};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-transform: uppercase;
`;

const StyleTypeFacebookCss = css`
    color: ${({ theme }) => theme.colors.facebook};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);

    :hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.facebook};
        border: 1px solid ${({ theme }) => theme.colors.facebook};

        > svg {
            color: ${({ theme }) => theme.colors.white};

            path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;

const StyleTypeAppleCss = css`
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    padding: 0 10px 0 10px;

    :hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};

        > svg {
            path {
                fill: ${({ theme }) => theme.colors.black};
            }
        }
    }
`;

// Sizes
const SizeSlimCss = css`
    padding: 0.3rem 0.7rem;
    font-size: 0.7rem;
`;
const SizeSmallCss = css`
    padding: 0.468rem 0.687rem;
    font-size: 0.75rem;
`;
const SizeMediumCss = css`
    padding: 0.625rem 1rem;
    font-size: 1rem;
`;
const SizeLargeCss = css`
    padding: 0.875rem 1.43rem;
    font-size: 1.25rem;
`;

// Block
const BlockCss = css`
    width: 100%;
`;

// Disabled
const DisabledCss = css`
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.white};
    background: unset;
    background-color: ${({ theme }) => theme.colors.disabled};
`;

export const Container = styled.button<IButton.IProps>`
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :focus {
        outline: none;
    }

    /* styleType */
    ${({ styleType }) => {
        switch (styleType) {
            case "gradient":
                return StyleTypeGradientCss;
            case "ghost":
                return StyleTypeGhostCss;
            case "muted":
                return StyleTypeMutedCss;
            case "plain":
                return StyleTypePlainCss;
            case "facebook":
                return StyleTypeFacebookCss;
            case "apple":
                return StyleTypeAppleCss;
            default:
                return StyleTypeGradientCss;
        }
    }}

    /* size */
    ${({ size }) => {
        switch (size) {
            case "slim":
                return SizeSlimCss;
            case "small":
                return SizeSmallCss;
            case "medium":
                return SizeMediumCss;
            case "large":
                return SizeLargeCss;
            default:
                return SizeMediumCss;
        }
    }}

    /* block */
    ${({ block }) => block && BlockCss}

    /* disabled */
    ${({ disabled }) => disabled && DisabledCss}
`;
