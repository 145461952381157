// #region Global Imports
import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Image } from "@Components/Basic";
import {
    useScreenSize,
    useOutsideClick,
    getCustomSliderConfig,
    Preset,
    useUpThere,
} from "@Definitions";
import { IStore, PutMeUpHereModel } from "@Interfaces";
import { SmartSlider } from "@Components";
import { Presentation } from "./PutMeUpHere.presentation";
import { Skeleton } from "./PutMeUpHere.skeleton";
import { IPutMeUpHere } from "./PutMeUpHere";
import { One, customSliderCss } from "./styled";
// #endregion Local Imports

const getUniqueKey = (id: string) => `PutMeUpHere__One--${id}`;

const renderPromoteds = (
    screenType: string,
    users: PutMeUpHereModel.IFetchedProfile[]
): JSX.Element => {
    const customSliderConfig = getCustomSliderConfig(screenType, {
        xsmall: {
            slidesToShow: 5,
            slidesToScroll: 4,
        },
        small: {
            autoplay: true,
            slidesToShow: 5,
            autoplaySpeed: 4000,
        },
        medium: {
            slidesToShow: 8,
            slidesToScroll: 2,
        },
        large: {
            slidesToShow: 11,
            slidesToScroll: 3,
        },
        xlarge: {
            slidesToShow: 14,
            slidesToScroll: 3,
        },
        common: {
            hide: false,
        },
    });

    const slides = users.map(({ Id, Username, Photo, IsOnline }) => (
        <One key={getUniqueKey(Id)} active={IsOnline}>
            <Link href="/profile/[id]?source=put me up here" as={`/profile/${Id}`}>
                <a>
                    <Image src={`${Photo.Url}${Preset.thumb}`} alt={Username} />
                </a>
            </Link>
        </One>
    ));

    return (
        <SmartSlider {...customSliderConfig} slides={slides} customSliderCss={customSliderCss} />
    );
};

export const Container: React.FunctionComponent<IPutMeUpHere.IProps> = ({ t }): JSX.Element => {
    const { Result: users, isPurchaseRevealed } = useSelector(
        ({ dashboard }: IStore) => dashboard.putMeUpHere
    );

    const { ContainsYou, putUserUpThere, setPurchaseRevealed } = useUpThere();

    const userPhoto = useSelector((store: IStore) => store.profile.self?.Photo.Url);

    const screenType = useScreenSize();

    const [isLoading, setLoading] = useState(false);

    const isRevealedAndXS = useCallback(() => isPurchaseRevealed && screenType === "xsmall", [
        isPurchaseRevealed,
        screenType,
    ]);

    useOutsideClick(() => {
        if (isRevealedAndXS()) {
            setPurchaseRevealed(false);
        }
    });

    const handlePurchase = async () => {
        if (isLoading) return;

        setLoading(true);

        await putUserUpThere();

        setLoading(false);

        setPurchaseRevealed(false);
    };

    const onPutMeThereClick = () => {
        if (!ContainsYou) setPurchaseRevealed(true);
    };

    if (!users) return <Skeleton />;

    const presentational = {
        users,
        ContainsYou,
        putUserUpThere,
        userPhoto,
        t,
        isLoading,
        screenType,
        isRevealedAndXS,
        onPutMeThereClick,
        isPurchaseRevealed,
        setPurchaseRevealed,
        handlePurchase,
        renderPromoteds,
    };

    return <Presentation {...presentational} />;
};
