import * as React from "react";

const SvgStarIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
        <path
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 2.645l1.69 5.03 5.305.052-4.26 3.161 1.588 5.063L12 14.875l-4.323 3.076 1.589-5.063-4.261-3.16 5.305-.054L12 4.644z"
            stroke="currentcolor"
            strokeWidth={0.3}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgStarIcon;
