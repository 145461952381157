import * as React from "react";

const SvgUpload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 97 95" {...props}>
        <defs>
            <filter
                x="-2.9%"
                y="-5%"
                width="105.9%"
                height="110%"
                filterUnits="objectBoundingBox"
                id="upload_svg__a"
            >
                <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                    stdDeviation={6}
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <g
            filter="url(#upload_svg__a)"
            transform="translate(-118 -396)"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        >
            <path d="M130.5 477a2 2 0 01-2-2v-20a2 2 0 114 0v18h64v-18a2 2 0 114 0v20h0a2 2 0 01-1.85 1.995l-.15.005h0-68 0zm34-72a2 2 0 011.754 1.04l22.773 22.772a2 2 0 01-2.829 2.829h0L166.5 411.942V459a2 2 0 11-4 0v-47.058l-19.698 19.699a2 2 0 01-2.702.117l-.127-.117a2 2 0 010-2.829h0l22.773-22.773A2 2 0 01164.5 405z" />
        </g>
    </svg>
);

export default SvgUpload;
