// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container, Image, Percent, Tint } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IAvatar } from "./Avatar";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

const Avatar: React.FunctionComponent<IAvatar.IProps> = ({
    type,
    src,
    customStyle,
    onClick,
    alt,
    withPercentage,
}): JSX.Element => {
    const ratio = useSelector((state: IStore) => state.common.profileOccupancyRatio);

    return (
        <Container type={type} customStyle={customStyle} onClick={onClick}>
            <Image alt={alt} src={src} />
            {withPercentage && ratio && (
                <>
                    <Tint percent={ratio || "0"} />
                    <Percent>
                        <span>{`${ratio}%`}</span>
                    </Percent>
                </>
            )}
        </Container>
    );
};

export { Avatar };
