// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IHR } from "./HR";
// #endregion Interface Imports

export const HR: React.FunctionComponent<IHR.IProps> = ({ className }) => {
    return <Container className={className} />;
};
