// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Button } from "@Components/Basic/Button";
import { GrayGradientDiv } from "../../styled";
// #endregion Local Imports

export const Container = styled(GrayGradientDiv)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Text = styled.div`
    width: 100%;
    margin-top: 102px;
    padding: 0 42px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    line-height: 20px;

    span:first-child {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
    }

    span:nth-child(2) {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-top: 82px;
        padding: 0 12px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: 146px;
        padding: 0 62px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-top: 47px;
        padding: 0 39.5px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    height: 160px;
    margin-bottom: 47px;

    div {
        width: 100%;
        height: 40px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        margin-bottom: 67px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-bottom: 167px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        margin-bottom: 64px;
    }
`;

export const OpenProfileButton = styled(Button)`
    span {
        padding: 0;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 20px;
        color: #ffffff;
        letter-spacing: -0.38px;
        text-align: center;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    }
`;

export const WhiteButton = styled(Button)`
    display: grid;
    place-items: center;

    :hover {
        span {
            text-shadow: none;
        }
    }

    span {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: 16px;
        color: ${({ theme }) => theme.colors.facebook};
        letter-spacing: -0.3px;
        text-align: center;
    }
`;

export const AddPhotoButton = styled(WhiteButton)``;
export const GetShowcasedButton = styled(WhiteButton)``;

AddPhotoButton.displayName = "AddPhotoButton";
GetShowcasedButton.displayName = "GetShowcasedButton";
OpenProfileButton.displayName = "OpenProfileButton";
