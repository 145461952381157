// #region Global Imports
import * as React from "react";
import { useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    RadialGradient,
    Title,
    Content,
    Icon,
    Couple,
    MatchedPerson,
    CurrentUser,
    Buttons,
    ContinueButton,
    StartChatButton,
    ContentBottom,
    ContentTop,
} from "./styled";
import { ContentBox } from "../../styled";
import { INewMatch } from "./NewMatch";
import { IStore } from "@Interfaces";
import { KalpKalbeKarsi, NewMatchIcon } from "@Icons";
import { Preset, theme } from "@Definitions";
// #endregion Local Imports

const NewMatch: React.FunctionComponent<INewMatch.IProps> = ({
    onStartChatClick,
    onContinueClick,
    t,
}): JSX.Element => {
    const likeStatus = useSelector((state: IStore) => state.dashboard.likeStatus);
    const matchedUser = useSelector(
        (state: IStore) =>
            likeStatus.isMatch &&
            state.dashboard.hotOrNot.list.find(user => user.Id === likeStatus.likedUserId)
    );

    const txt = (key: string) => t(`dashboard:HotOrNot.NewMatch.${key}`);
    return (
        <ContentBox>
            {matchedUser && (
                <Container>
                    <RadialGradient />
                    <Content>
                        <ContentTop>
                            <Title>
                                <KalpKalbeKarsi width="270px" height="69px" />
                            </Title>
                            <Icon>
                                <NewMatchIcon
                                    width="40px"
                                    height="36px"
                                    color={theme.colors.white}
                                />
                            </Icon>
                            <Couple>
                                <MatchedPerson>
                                    <div>
                                        <img
                                            src={`${matchedUser.PhotoUrl}${Preset.thumb2x}`}
                                            alt="match"
                                        />
                                    </div>
                                    <span>{`${matchedUser.Username}, ${matchedUser.Age}`}</span>
                                </MatchedPerson>
                                <CurrentUser>
                                    <img src="images/male-avatar.png" alt="avatar" />
                                </CurrentUser>
                            </Couple>
                        </ContentTop>
                        <ContentBottom>
                            <Buttons>
                                <StartChatButton iconClick={onStartChatClick}>
                                    <span>{txt("startChat")}</span>
                                </StartChatButton>
                                <ContinueButton onClick={onContinueClick}>
                                    {txt("continue")}
                                </ContinueButton>
                            </Buttons>
                        </ContentBottom>
                    </Content>
                </Container>
            )}
        </ContentBox>
    );
};

export { NewMatch };
