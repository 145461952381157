import * as React from "react";

const SvgEdit = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 10 17" {...props}>
        <path
            d="M8.941 1.164a2 2 0 01.732 2.732l-6 10.393-2.898 1.913a.5.5 0 01-.774-.447l.208-3.466 6-10.393a2 2 0 012.732-.732zm-.972 3.683l-1.732-1-5.044 8.737-.065 1.117.861.498.936-.615L7.97 4.847zM8.44 2.03a1 1 0 00-1.366.366l-.338.585 1.732 1 .338-.585a1 1 0 00-.366-1.366z"
            fill="currentcolor"
            fillRule="nonzero"
            stroke="currentcolor"
            strokeWidth={0.2}
        />
    </svg>
);

export default SvgEdit;
