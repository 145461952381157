// #region Global Imports
import * as React from "react";
import { TFunction } from "next-i18next";
import Router from "next/router";
import { css } from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Tooltip } from "@Components";
import { RightArrow, Trash } from "@Icons";
import { theme } from "@Definitions";
import { ListView, ListItem, Placeholder } from "./styled";
import { ISearchMenu } from "./SearchMenu";
import data from "./SearchMenu.data";
// #endregion Local Imports

const getUniqueKey = (i: number, n: string): string => `SearchMenu__ListView__ListItem__${n}--${i}`;

const translateListItems = (t: TFunction, list: ISearchMenu.IMenuItem[]): ISearchMenu.IMenuItem[] =>
    list.map(menuItem => ({
        ...menuItem,
        title: t(`common:search-menu.${menuItem.title}`),
    }));

const fetchUserSearchHistory = (): ISearchMenu.IMenuItem[] =>
    //
    // TODO: Fetch user search history from API.
    Array(4)
        .fill({})
        .map((h, i) => ({ title: `Arama ${i}` }));

const prepareList = (history: ISearchMenu.IMenuItem[]): ISearchMenu.IMenuItem[] =>
    data.map(menuItem =>
        menuItem.title === "kayıtlı aramalar"
            ? {
                ...menuItem,
                sub: history,
            }
            : menuItem
    );

export const SearchMenu: React.FunctionComponent<ISearchMenu.IProps> = ({ t }): JSX.Element => {
    const initialHistory = fetchUserSearchHistory();
    const [history, setHistory] = React.useState(initialHistory);

    const initialList = prepareList(history);
    const [list, setList] = React.useState(initialList);

    React.useEffect(() => {
        setList(prepareList(history));
    }, [history]);

    const handleItemClick = (url: string, e: React.MouseEvent) => {
        e.stopPropagation();
        if (url) Router.replace(url);
    };

    const handleItemDelete = (item: ISearchMenu.IMenuItem, e: React.MouseEvent) => {
        e.stopPropagation();
        setHistory(history.filter(h => h.title !== item.title));
        setList(prepareList(history));
    };

    const renderListItems = (_list: ISearchMenu.IMenuItem[], sub = false) => (
        <ListView>
            {sub && !_list.length && (
                <Placeholder>{t("common:search-menu.no-history")}</Placeholder>
            )}

            {_list.map((menuItem, i) => (
                <ListItem
                    key={getUniqueKey(i, menuItem.title)}
                    onClick={(e: React.MouseEvent) =>
                        !menuItem.sub && handleItemClick(menuItem.url || "", e)
                    }
                >
                    {menuItem.title}

                    {sub ? (
                        <Trash
                            width="16px"
                            height="16px"
                            role="none"
                            onClick={(e: React.MouseEvent) => handleItemDelete(menuItem, e)}
                            onKeyDown={() => true}
                        />
                    ) : (
                        <RightArrow width="10px" height="16px" color={theme.colors.gray} />
                        )}

                    {menuItem.sub && (
                        <Tooltip
                            placement="right"
                            customStyle={css`
                                position: relative;
                                top: 57px;
                                left: 30px;
                            `}
                        >
                            {renderListItems(menuItem.sub, true)}
                        </Tooltip>
                    )}
                </ListItem>
            ))}
        </ListView>
    );

    return renderListItems(translateListItems(t, list));
};
