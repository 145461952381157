// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Button, Card, Title, Text } from "@Components/Basic";
import { Row, Col } from "@Styled";
import { CircledStar } from "@Icons";
import { theme } from "@Definitions";
import { Container } from "./styled";
import { IBecomeGoldMember } from "./BecomeGoldMember";
// #endregion Local Imports

const BecomeGoldMember: React.FunctionComponent<IBecomeGoldMember.IProps> = ({
    t,
    description,
}): JSX.Element => {
    return (
        <Card overflowHidden>
            <Container className="py-5 px-5 px-xl-9 text-center text-md-left align-items-lg-center text-lg-center">
                <Row className="no-gutters">
                    <Col className="col-3 col-lg-12 d-none d-md-flex justify-content-center align-items-center d-lg-none">
                        <CircledStar width="90px" height="90px" color={theme.colors.mustard} />
                    </Col>
                    <Col className="col-12 col-md-9 col-lg-12">
                        <Title
                            className="mb-lg-4"
                            color="red"
                            size="large"
                            fontWeight="bold"
                            dangerouslySetInnerHTML={{
                                __html: t("dashboard:Widgets.BecomeGoldMember.title"),
                            }}
                        />
                        <CircledStar width="268px" height="90px" color={theme.colors.mustard} className="mb-6 d-none d-lg-block"/>
                        <Text
                            className="mb-3"
                            color="black"
                            size="large"
                            weight="bold"
                            dangerouslySetInnerHTML={{
                                __html:
                                    description ||
                                    t("dashboard:Widgets.BecomeGoldMember.description"),
                            }}
                        />
                        <Link
                            href="/payments/[type]/credit-card?source=dashboard standard"
                            as="/payments/gold/credit-card"
                        >
                            <a>
                                <Button styleType="gradient" className="text-uppercase">
                                    {t("dashboard:Widgets.BecomeGoldMember.continue")}
                                </Button>
                            </a>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
};

export { BecomeGoldMember };
