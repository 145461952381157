// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { SearchService, Analytics } from "@Services";
import { ActionConsts } from "@Definitions";
import { SearchModel, IStore } from "@Interfaces";
// #endregion Local Imports

export const SearchActions = {
    Reset: () => ({
        type: ActionConsts.Search.ResetReducer,
    }),

    /**
     * Sets loading state
     * @param loading Whether is loading or not
     */
    SetLoading: (loading: boolean) => ({
        payload: { loading },
        type: ActionConsts.Search.SetLoading,
    }),

    MakeSearch: async (getMore: boolean) => async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(SearchActions.SetLoading(true));

        const { currentPage, searchResults } = getState().search;
        const { filters } = getState().searchFilter;
        const {
            ageRange,
            location,
            hasPhoto,
            isOnline,
            isNewUser,
            distance,
            gender,
            ...advancedFilters
        } = filters;

        const profileIds = searchResults.map(val => val.Id).join(",");

        const requestPayload: SearchModel.SearchRequest = {
            Page: getMore ? currentPage + 1 : 0,
            Size: 20,
            MinAge: ageRange!.from,
            MaxAge: ageRange!.to,
            Country: location!.countryId,
            City: location!.cityId,
            Town: location!.townId,
            Gender: gender,
            HasPhoto: hasPhoto,
            IsOnline: isOnline,
            NewMember: isNewUser,
            Distance: distance,
            ...advancedFilters,
        };

        if (getMore) {
            requestPayload.Exclude = profileIds;
        }

        const { Success, Users, Total } = await SearchService.Search(requestPayload);

        if (!Success) {
            throw new Error("Error");
        }

        Analytics.event(
            "Loaded Search Results",
            {
                sa_distance: distance,
                sa_has_advanced_filters: Object.keys(advancedFilters).length > 0,
                sa_number_of_results: Total,
                sa_online_filter: requestPayload.IsOnline,
                sa_page_number: requestPayload.Page,
                sa_photo_filter: requestPayload.HasPhoto,
                sa_target_gender: requestPayload.Gender,
            },
            true
        );

        const type = getMore
            ? ActionConsts.Search.AddSearchResults
            : ActionConsts.Search.SetSearchResults;

        dispatch({
            payload: {
                searchResults: Users,
                currentPage: requestPayload.Page,
                total: Total,
                loading: false,
            },
            type,
        });
    },

    InboxEmptyViewSearch: async () => async (dispatch: Dispatch, getState: () => IStore) => {
        const {
            LookingForGender,
            LookingForMinAge,
            LookingForMaxAge,
            CityId,
            CountryId,
            TownId,
        } = getState().profile.self!;

        if (getState().inbox.emptyViewSearch?.result?.length) return;

        const requestPayload: SearchModel.SearchRequest = {
            Page: 0,
            Size: 16,
            MinAge: parseInt(LookingForMinAge, 10),
            MaxAge: parseInt(LookingForMaxAge, 10),
            Country: parseInt(CountryId, 10),
            City: parseInt(CityId, 10),
            Town: parseInt(TownId, 10),
            Gender: LookingForGender,
            HasPhoto: true,
            Distance: 10000,
        };

        const { Success, Users: result } = await SearchService.Search(requestPayload);

        if (!Success) throw new Error("Error");

        dispatch({
            payload: { result },
            type: ActionConsts.Inbox.FetchInboxEmptyView,
        });
    },
};
