// #region Global Imports
import styled, { css, CSSProp } from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    font-family: "Montserrat";
    width: 100%;
    height: 530px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 8px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 696px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: 492px;
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.div`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: ${({ theme }) => theme.colors.red};
    letter-spacing: -0.3px;
`;

export const HeaderIcons = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 3px;

    .active {
        color: ${({ theme }) => theme.colors.red};
    }

    div {
        width: 36px;
        height: 36px;
    }
`;

export const HeaderIcon = styled.div`
    display: flex;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.gray};
`;

export const BadgeIcon: CSSProp = css`
    width: 16px;
    height: 14px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
`;

export const ContentBox = styled.div`
    width: 334px;
    height: 458px;
    position: relative;
    border-radius: 4px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 274px;
        height: 458px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 374px;
        height: 622px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 329px;
        height: 420px;
    }
`;

export const GrayGradientDiv = styled.div`
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        180deg,
        ${({ theme }) => theme.colors.lighterGray} 0%,
        #ffffff 49%,
        ${({ theme }) => theme.colors.lighterGray} 100%
    );
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 4px;
`;

Header.displayName = "Header";
ContentBox.displayName = "ContentBox";
