// #region Global Imports
import * as React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Gender } from "@Interfaces/enums";
import { OnlineUserButton } from "@Components/OnlineUserButton";
import { IDiscoverLoginBox } from "./DiscoverLoginBox";
// #endregion Local Imports

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 10px;
    }
`;

const MiddleContent = styled.div`
    display: flex;
    padding-top: 10px;
    align-items: center;
    width: 373px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 253px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 300px;
        margin-bottom: 7px;
    }
`;
const OnlineUsersContainer = styled.div`
    display: flex;
    align-items: center;
    div:last-child {
        margin-left: 11px;
    }
`;

const IconGlass = styled.i`
    font-size: 64px;
    width: 64px;
    height: 73px;
`;

const ContentTitle = styled.div`
    width: 60%;
    margin-left: 20px;
    padding-bottom: 10px;
    line-height: 1.3;
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 62%;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 90%;
    }
`;

export const DiscoverLoginBox: React.FunctionComponent<IDiscoverLoginBox.IProps> = props => {
    const { t } = props;

    return (
        <Container {...props}>
            <MiddleContent>
                <IconGlass className="icon handglass" />
                <ContentTitle>{t("common:Discover Title")}</ContentTitle>
            </MiddleContent>
            <OnlineUsersContainer>
                <OnlineUserButton t={t} gender={Gender.female} />
                <OnlineUserButton t={t} gender={Gender.male} />
            </OnlineUsersContainer>
        </Container>
    );
};
