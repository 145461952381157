// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { AccountService } from "@Services";
import { ActionConsts } from "@Definitions";
import { IHomePage } from "@Interfaces";
// #endregion Local Imports

export const HomeActions = {
    Map: (payload: {}) => ({
        payload,
        type: ActionConsts.Home.SetReducer,
    }),

    Reset: () => ({
        type: ActionConsts.Home.ResetReducer,
    }),
    SetTopComponent: (payload: IHomePage.Actions.ISetTopComponent) => ({
        payload,
        type: ActionConsts.Home.SetTopComponent,
    }),

    OnlineUserCount: () => async (dispatch: Dispatch) => {
        const result = await AccountService.OnlineUserCount();

        const OnlineUserCountObj = { onlineUserCounts: result };

        dispatch({
            payload: OnlineUserCountObj,
            type: ActionConsts.Home.OnlineUserCount,
        });
    },
};
