import Router from "next/router";
import { MembershipType } from "@Interfaces/enums";
import { ICampaignActions, IProfilePage, ProfileModel } from "@Interfaces";

export const RedirectPayment = async (source?: ICampaignActions.AnalyticsSource) => {
    await Router.push(
        `/payments/[type]/credit-card/payment?source=${source}`,
        `/payments/gold/credit-card/payment`
    );
};

export const RedirectProfile = async (
    userId: number | string,
    source?: IProfilePage.AnalyticsSource
) => {
    await Router.push(`/profile/[id]?source=${source}`, `/profile/${userId}`);
};

export const getContactUrl = (profile?: ProfileModel.IProfile) => {
    const domain = "//www.siberalem.com";

    switch (profile?.MembershipType) {
        case MembershipType.gold:
            return `${domain}/iletisim-bilgileri-premium/?u=${profile.Username}`;

        case MembershipType.normal:
            return `${domain}/iletisim-bilgileri/?u=${profile.Username}`;

        default:
            return `${domain}/iletisim-bilgileri/`;
    }
};
