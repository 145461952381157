// #region Global Imports
import styled from "styled-components";
import { IconBlockButton } from "@Components/IconBlockButton";
// #endregion Global Imports

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 4px;
`;

export const BannerArea = styled.div<{ src: string }>`
    border: 1px solid ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.gray};
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ src }) =>
        src.length
            ? `background-image: url(${src}); background-size: cover; background-position: center;`
            : ""}

    span {
        ${({ src }) => (src.length ? "display: none;" : "")}
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 14px;
        letter-spacing: -0.17px;
        text-align: center;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        width: 250px;
        height: 250px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 336px;
        height: 280px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        width: 300px;
        height: 250px;
    }
`;

export const Blank = styled.div`
    width: 100%;
    height: 104px;
    display: grid;
    place-items: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: 186px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        height: 85px;
    }
`;

export const SkipButton = styled(IconBlockButton)`
    padding: 10.5px 12px;

    :hover {
        span {
            text-shadow: none;
        }
    }

    span {
        padding: 0;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 16px;
        color: ${({ theme }) => theme.colors.facebook};
        letter-spacing: -0.3px;
        text-align: center;
    }
`;

BannerArea.displayName = "BannerArea";
SkipButton.displayName = "SkipButton";
