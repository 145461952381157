// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ISmall } from "./Small";
// #endregion Interface Imports

export const Small: React.FunctionComponent<ISmall.IProps> = props => {
    return <Container {...props} />;
};
