import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PasswordForm = styled.form`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
export const FieldGroup = styled.form`
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

PasswordForm.displayName = "PasswordForm";
