// #region Global Imports
import Nookie from "nookies";
// #endregion Global Imports

// #region Local Imports
import { HttpModel } from "./Http";
// #endregion Local Imports

export const appendAdditionalParams = (
    payload?: HttpModel.IRequestPayload,
    userObj?: { userId: string; token: string }
) => {
    let additionalParams = {
        token: userObj ? userObj.token : null,
        userId: userObj ? userObj.userId : null,
        v: "web",
    };

    const cookie = Nookie.get();

    if (!userObj && cookie.account) {
        const { user } = JSON.parse(cookie.account);

        additionalParams = {
            token: user.Token,
            userId: user.UserId,
            v: "web",
        };
    }

    return { ...payload, ...additionalParams };
};
