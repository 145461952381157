import * as React from "react";

const SvgTaurusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 14 13" {...props}>
        <path
            d="M7.126 4.491c.347 0 .607-.044.867-.176s.477-.264.693-.44c.217-.177.39-.353.564-.617.173-.22.347-.44.52-.705.173-.265.347-.485.52-.705.173-.22.39-.44.607-.573.216-.176.476-.308.78-.44.303-.132.607-.176.997-.176h.216c.087 0 .174 0 .217.044.087 0 .13 0 .217.044v1.145h-.174c-.346 0-.65.044-.91.176-.26.088-.476.265-.693.44-.217.177-.39.353-.564.573l-.52.661c-.173.22-.346.44-.563.705-.173.22-.434.44-.694.617.304.176.564.396.78.66.26.265.434.53.607.838.174.308.304.616.39.969.087.352.13.705.13 1.101 0 .573-.086 1.102-.303 1.63-.217.529-.477.97-.867 1.322-.346.396-.78.66-1.3.88-.477.221-1.04.31-1.604.31-.563 0-1.127-.089-1.603-.31a5.233 5.233 0 01-1.3-.88c-.39-.353-.65-.837-.867-1.322-.217-.484-.304-1.057-.304-1.63 0-.352.044-.749.13-1.101.087-.353.217-.661.39-.97.174-.308.39-.572.607-.836.26-.265.52-.485.78-.661-.26-.176-.476-.397-.693-.617-.174-.22-.39-.44-.564-.705l-.52-.66a3.493 3.493 0 00-.563-.573 3.734 3.734 0 00-.694-.44 2.793 2.793 0 00-.91-.177h-.26V.747c.044 0 .13 0 .217-.044.043 0 .13 0 .217-.044h.26c.39 0 .693.044.997.176.303.088.563.264.78.44.216.176.433.353.606.573.174.22.347.44.52.705.174.22.347.44.52.705.174.264.347.44.564.616.217.177.433.309.694.44.476.09.78.177 1.083.177h0zm-2.73 4.141c0 .397.086.75.216 1.102.13.352.304.66.564.88.26.265.52.441.867.617.346.132.693.22 1.083.22a2.8 2.8 0 001.084-.22c.346-.132.606-.352.866-.616a2.76 2.76 0 00.564-.881c.13-.309.217-.705.217-1.102 0-.396-.087-.793-.217-1.101-.13-.353-.304-.661-.564-.881-.26-.265-.52-.44-.866-.617-.347-.176-.694-.22-1.084-.22-.39 0-.78.088-1.127.22s-.607.352-.867.617c-.26.264-.433.572-.563.88-.13.31-.173.706-.173 1.102h0z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgTaurusIcon;
