// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { HintContainer, Header } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IHint } from "./Hint";
// #endregion Interface Imports

const Hint: React.FunctionComponent<IHint.IProps> = ({
    renderIcon,
    header,
    backgroundColor,
    children,
}): JSX.Element => {
    return (
        <HintContainer backgroundColor={backgroundColor}>
            {renderIcon && renderIcon()}
            <Header>{header}</Header>
            <div>{children}</div>
        </HintContainer>
    );
};
export { Hint };
