// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic";
import { Stickers } from "./Components/Stickers";
import { Container, Textarea, Footer, Count, IconStyle, StickersModal, Overlay } from "./styled";
import { Emoji } from "@Components";
import { useScreenSize } from "@Definitions";
import { StickerIcon } from "@Icons";
// #endregion Local Imports

// #region Interface Imports
import { IMessageInput } from "./MessageInput";
import { StickerModel } from "../../Interfaces";
// #endregion Interface Imports

export const MessageInput: React.FunctionComponent<IMessageInput.IProps> = ({
    value,
    onChange,
    t,
    stickers,
    sendMessage,
}) => {
    const maxLength = 400;
    const [isEmojiOpen, setEmojiOpen] = React.useState(false);
    const [isStickerOpen, setStickerOpen] = React.useState(false);

    const escFunction = React.useCallback(event => {
        if (event.keyCode === 27) {
            setEmojiOpen(false);
        }
    }, []);

    const screenSize = useScreenSize();
    const isMobile = screenSize.includes("small");
    const isTablet = screenSize === "medium";

    React.useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <Container>
            <Textarea
                async
                autoFocus={!isMobile}
                maxLength={maxLength}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    onChange(event.target.value);
                }}
                data-cy="inbox-messages-textarea"
            />
            <Footer>
                <div className="d-flex">
                    {!isMobile && !isTablet && (
                        <Emoji
                            isOpen={isEmojiOpen}
                            onClick={() => {
                                setEmojiOpen(val => !val);
                            }}
                            onClickOverlay={() => {
                                setEmojiOpen(false);
                            }}
                            onSelect={(emoji: any) => {
                                onChange(`${value}${emoji.native}`);
                            }}
                        />
                    )}
                    {stickers && (
                        <>
                            <IconButton
                                renderIcon={() => (
                                    <IconStyle>
                                        <StickerIcon width="28px" height="28px" />
                                    </IconStyle>
                                )}
                                onIconClick={() => setStickerOpen(!isStickerOpen)}
                            />
                            {isStickerOpen && (
                                <>
                                    <Overlay onClick={() => setStickerOpen(!isStickerOpen)} />
                                    <StickersModal>
                                        <Stickers
                                            onSelect={(sticker: StickerModel.IStickerItem) => {
                                                sendMessage(false, sticker);
                                            }}
                                            t={t}
                                            stickerData={stickers}
                                        />
                                    </StickersModal>
                                </>
                            )}
                        </>
                    )}
                </div>
                <Count>{`${value.length}/${maxLength}`}</Count>
            </Footer>
        </Container>
    );
};
