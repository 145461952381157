// #region Global Imports
import React, { FC, useEffect } from "react";
import noScroll from "no-scroll";
// #endregion Global Imports

// #region Local Imports
import { Container, ChildBed, DismissButton } from "./styled";
import { IModal } from "./Modal";
// #endregion Local Imports

export const Modal: FC<IModal.IProps> = ({
    children,
    isDismissible = false,
    onDismiss,
    customModalCloseIcon,
    className,
}): JSX.Element => {
    const handleDismiss = () => {
        if (onDismiss) onDismiss();
    };

    useEffect(() => {
        noScroll.on();

        return () => {
            noScroll.off();
        };
    }, [children]);

    return (
        <Container data-cy="modal" className={className}>
            <ChildBed>
                {isDismissible && (
                    <DismissButton
                        customModalCloseIcon={customModalCloseIcon}
                        className="icon close-icon"
                        onClick={handleDismiss}
                        data-cy="modal-dismiss-btn"
                    />
                )}

                {children}
            </ChildBed>
        </Container>
    );
};
