// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { BlockButton } from "@Components/BlockButton";
// #endregion Local Imports

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 85px;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        padding: 163px 0;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        padding: 62px 0;
    }
`;

export const CreditButton = styled(BlockButton)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    color: #ffffff;
    letter-spacing: -0.38px;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    padding: 0 22px;
    height: 32px;
`;

export const Text = styled.div<{ type: string }>`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
    line-height: 20px;
    width: ${({ type }) => (type === "buy" ? "230px" : "190px")};
`;

export const CreditIcon = styled.div`
    width: 72px;
    height: 72px;
    color: ${({ theme }) => theme.colors.mustard};
    font-size: 72px;
`;

CreditButton.displayName = "CreditButton";
