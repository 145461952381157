import * as React from "react";

const SvgSearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm-2 7a8 8 0 016.287 12.948c.031.025.062.052.092.082l4.95 4.95a1 1 0 01-1.414 1.414l-4.95-4.95a1.01 1.01 0 01-.09-.102A8 8 0 1116 8zm0 1a7 7 0 100 14 7 7 0 000-14z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSearchIcon;
