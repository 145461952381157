// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { IDropdown } from "@Components/Basic/Dropdown/Dropdown";
import { theme } from "@Definitions";
import { EighteenValid, EighteenInvalid, Eighteen } from "@Icons";
import { getBirthday, ageIsValid, today, days, months, years } from "./helper";
import {
    Wrapper,
    Container,
    SelectDay,
    SelectMonth,
    SelectYear,
    DropdownGroup,
    ErrorMessage,
} from "./styled";
import { IBirthdayPicker } from "./BirthdayPicker";
// #endregion Local Imports

const BirthdayPicker: React.FunctionComponent<IBirthdayPicker.IProps> = ({
    value,
    name,
    onDateChange,
    t,
    errorText,
    isValid,
    _ref,
    isMobile,
}): JSX.Element => {
    const { useState, useEffect, useRef } = React;

    const [day, setDay] = useState(value ? value.getDate().toString() : "");
    const [monthIndex, setMonthIndex] = useState(value ? value.getMonth().toString() : "");
    const [year, setYear] = useState(value ? value.getFullYear().toString() : "");

    const monthSelect = useRef<HTMLSelectElement>();
    const yearSelect = useRef<HTMLSelectElement>();

    let validResult: Date | undefined;

    const renderIcon = () => {
        if (day && monthIndex && year) {
            const birthday = getBirthday(year, monthIndex, day);
            if (ageIsValid(birthday, today)) {
                validResult = birthday;
                return <EighteenValid width="42px" height="47px" color={theme.colors.green} />
            } else {
                return <EighteenInvalid width="42px" height="47px" color={theme.colors.red} />
            }
        }
        else {
            return <Eighteen width="38px" height="47px" color={theme.colors.gray} />
        }
    }

    useEffect(() => {
        const allIsSelected = day !== "" && monthIndex !== "" && year !== "";
        onDateChange(validResult, allIsSelected);
    }, [day, monthIndex, year]);

    return (
        <Wrapper>
            <input type="hidden" name={name} ref={_ref} />
            <Container>
                {renderIcon()}
                <DropdownGroup>
                    <SelectDay
                        options={days}
                        placeholder={t("common:forms.datepicker.day")}
                        onChange={(option: IDropdown.ISelectOption) => {
                            setDay(option.value.toString());
                            if (isMobile) {
                                monthSelect.current?.focus();
                            }
                        }}
                        value={day.length && { label: day, value: day }}
                        name="selectDay"
                        cy="register-day"
                    />
                    <SelectMonth
                        options={months}
                        placeholder={t("common:forms.datepicker.month")}
                        onChange={(option: IDropdown.ISelectOption) => {
                            setMonthIndex(option.value.toString());
                            if (isMobile) {
                                yearSelect.current?.focus();
                            }
                        }}
                        value={
                            monthIndex.length && {
                                label: (parseInt(monthIndex, 10) + 1).toString(),
                                value: monthIndex,
                            }
                        }
                        name="selectMonth"
                        ref={monthSelect}
                        cy="register-month"
                    />
                    <SelectYear
                        options={years}
                        placeholder={t("common:forms.datepicker.year")}
                        onChange={(option: IDropdown.ISelectOption) => {
                            setYear(option.value.toString());
                            yearSelect.current?.blur();
                        }}
                        value={year && { label: year, value: year }}
                        name="selectYear"
                        ref={yearSelect}
                        cy="register-year"
                    />
                </DropdownGroup>
            </Container>
            <ErrorMessage>{isValid === false && errorText}</ErrorMessage>
        </Wrapper>
    );
};

export { BirthdayPicker };
