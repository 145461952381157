import styled from "styled-components";

export const Container = styled.div``;

export const GenderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        margin-bottom: 15px;
        padding-left: 10px;
    }
`;

export const GenderDiv = styled.div`
    height: 15px;
`;

export const GenderIcons = styled.div`
    display: flex;
    margin-bottom: 12px;
`;

export const IconContainer = styled.div`
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.maxWidth.xSmall}) {
        font-size: 63px;
    }
`;

export const ErrorText = styled.div`
    font-family: "Montserrat";
    font-size: 10px;
    color: ${({ theme }) => theme.colors.red};
`;
