import * as React from "react";

const SvgSagittariusIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 13 13" {...props}>
        <path
            d="M.549 11.936l3.686-3.744-2.212-2.247.78-.793 2.212 2.247 5.29-5.419h-5.55V.88h7.112l.347.308v7.225H11.13V2.685L5.752 8.148l2.255 2.29-.78.793-2.255-2.29-3.686 3.788z"
            stroke="currentcolor"
            strokeWidth={0.2}
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSagittariusIcon;
