import * as React from "react";

const SvgSticker = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 41 41" {...props}>
        <path
            d="M32.5.092a8 8 0 018 8v16c0 8.837-7.163 16-16 16h-16a8 8 0 01-8-8v-24a8 8 0 018-8h24zm0 2h-24a6 6 0 00-6 6v24a6 6 0 006 6h11v-3c0-1.633.261-3.206.744-4.678a17.424 17.424 0 01-12.118-5.125 1.5 1.5 0 012.121-2.122 14.56 14.56 0 002.42 1.954c2.48 1.595 6.07 2.358 8.94 2.3 2.618-4.39 7.412-7.329 12.893-7.329h4v-12a6 6 0 00-6-6zm6 20h-4c-7.18 0-13 5.82-13 13v3h3c7.732 0 14-6.268 14-14v-2zM13.828 10.792a3 3 0 110 6 3 3 0 010-6zm13.186 0a3 3 0 110 6 3 3 0 010-6z"
            fill="currentcolor"
            stroke="#fff"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgSticker;
