// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { ILabel } from "./Label";
// #endregion Interface Imports

const Label: React.FunctionComponent<ILabel.IProps> = (props): JSX.Element => {
    const { text, customLabel } = props;
    return <Container customLabel={customLabel}>{text}</Container>;
};

export { Label };
