// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { ISearchPage } from "@Interfaces";
// #endregion Local Imports

export const SearchFilterActions = {
    /**
     * Reset current filter state
     * @param onlyAdvanced To keep basic filter settings
     */
    Reset: (onlyAdvanced?: boolean) => ({
        type: ActionConsts.SearchFilters.ResetReducer,
        payload: {
            onlyAdvanced,
        },
    }),

    /**
     * Set, update or remove filters
     */
    SetFilters: (filters: ISearchPage.Filters) => (dispatch: Dispatch) => {
        dispatch({
            type: ActionConsts.SearchFilters.SetFilters,
            payload: {
                filters,
            },
        });
    },
};
