// #region Global Imports
import styled, { keyframes } from "styled-components";
// #endregion Global Imports

const fadeIn = keyframes`
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
`;

const Container = styled.div<{ isFadeIn: boolean }>`
    background: ${({ theme }) => theme.gradients.grayHeader};
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.18);
    border-radius: 3px 3px 8px 8px;
    transform: translateX(0);
    opacity: ${({ isFadeIn }) => (isFadeIn ? 1 : 0)};
    animation: ${({ isFadeIn }) => (isFadeIn ? fadeIn : fadeOut)} 0.4s ease 0s 1;
`;

const TopNav = styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.green};
    height: 64px;
    width: 100%;
`;

const NavContent = styled.div<{ isFadeIn: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 32px 0 96px 17px;
    transform: translateX(0);
    opacity: ${({ isFadeIn }) => (isFadeIn ? 1 : 0)};
    animation: ${({ isFadeIn }) => (isFadeIn ? fadeIn : fadeOut)} 0.5s ease 0s 1;

    overflow-y: auto;
`;

const NavItem = styled.div`
    margin-bottom: 24px;
    > a {
        display: flex;
    }
`;

const NavText = styled.div`
    font-family: Montserrat;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    color: ${({ theme }) => theme.colors.gray};
    letter-spacing: -0.3px;
    padding-left: 24.5px;
    text-transform: lowercase;
`;

const CloseIcon = styled.div`
    display: flex;
    margin: 15px;
`;

const StyledIcon = styled.div`
    svg {
        color: ${({ theme }) => theme.colors.gray};
        :hover {
            color: ${({ theme }) => theme.colors.green};
        }
    }
`;

export { Container, TopNav, CloseIcon, NavItem, NavContent, NavText, StyledIcon };
