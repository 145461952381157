// #region Global Imports
import styled from "styled-components";
import {
    Container as MainContainer,
    Time as TimeContainer,
    Message as MessageContainer,
    InfoContent as InfoContentContainer,
} from "@Components/MessageListItem/styled";
// #endregion Global Imports

export const Container = styled(MainContainer)``;

export const Time = styled(TimeContainer)``;

export const AvatarContent = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 5px;
`;

export const InfoContent = styled(InfoContentContainer)``;

export const Message = styled(MessageContainer)``;
