import * as React from "react";

const SvgGooglePlay = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 21 21" {...props}>
        <defs>
            <linearGradient
                x1="61.031%"
                y1="4.966%"
                x2="26.52%"
                y2="71.904%"
                id="google-play_svg__a"
            >
                <stop stopColor="#00A0FF" offset="0%" />
                <stop stopColor="#00A1FF" offset=".66%" />
                <stop stopColor="#00BEFF" offset="26.01%" />
                <stop stopColor="#00D2FF" offset="51.22%" />
                <stop stopColor="#00DFFF" offset="76.04%" />
                <stop stopColor="#00E3FF" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="107.583%"
                y1="49.994%"
                x2="-130.477%"
                y2="49.994%"
                id="google-play_svg__b"
            >
                <stop stopColor="#FFE000" offset="0%" />
                <stop stopColor="#FFBD00" offset="40.87%" />
                <stop stopColor="orange" offset="77.54%" />
                <stop stopColor="#FF9C00" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="86.25%"
                y1="30.897%"
                x2="-50.143%"
                y2="136.018%"
                id="google-play_svg__c"
            >
                <stop stopColor="#FF3A44" offset="0%" />
                <stop stopColor="#C31162" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="-18.807%"
                y1="-11.873%"
                x2="42.096%"
                y2="35.069%"
                id="google-play_svg__d"
            >
                <stop stopColor="#32A071" offset="0%" />
                <stop stopColor="#2DA771" offset="6.85%" />
                <stop stopColor="#15CF74" offset="47.62%" />
                <stop stopColor="#06E775" offset="80.09%" />
                <stop stopColor="#00F076" offset="100%" />
            </linearGradient>
        </defs>
        <g transform="translate(.5 .25)" fill="none" fillRule="evenodd">
            <rect fill="#FFF" width={20} height={20} rx={10} />
            <g fillRule="nonzero">
                <path
                    d="M.296.17C.172.3.099.503.099.766v9.414c0 .264.073.467.197.598l.031.03L5.6 5.536v-.124L.327.138.296.17z"
                    fill="url(#google-play_svg__a)"
                    transform="translate(6 4.5)"
                />
                <path
                    d="M7.358 7.294L5.6 5.536v-.124l1.758-1.758.04.022L9.48 4.86c.594.338.594.89 0 1.229L7.398 7.272l-.04.022z"
                    fill="url(#google-play_svg__b)"
                    transform="translate(6 4.5)"
                />
                <path
                    d="M7.398 7.272L5.6 5.474.296 10.779c.195.207.52.233.884.026l6.218-3.533"
                    fill="url(#google-play_svg__c)"
                    transform="translate(6 4.5)"
                />
                <path
                    d="M7.398 3.676L1.18.143C.815-.064.491-.038.296.17L5.6 5.474l1.798-1.798z"
                    fill="url(#google-play_svg__d)"
                    transform="translate(6 4.5)"
                />
                <path
                    d="M13.358 11.732l-6.178 3.51c-.346.197-.655.184-.853.005l-.031.032.031.03c.198.18.507.192.853-.004l6.218-3.534-.04-.039z"
                    fill="#000"
                    opacity={0.2}
                />
                <path
                    d="M6.296 15.217c-.124-.132-.197-.335-.197-.598v.062c0 .263.073.467.197.598l.031-.032-.031-.03zM15.48 10.526l-2.122 1.206.04.04 2.083-1.183c.297-.169.446-.392.446-.615-.026.202-.177.399-.446.552z"
                    fill="#000"
                    opacity={0.12}
                />
                <path
                    d="M7.18 4.705l8.3 4.716c.27.154.421.351.447.553 0-.223-.149-.446-.446-.615l-8.3-4.716c-.596-.338-1.082-.057-1.082.624v.062c0-.68.486-.962 1.081-.624z"
                    fill="#FFF"
                    opacity={0.25}
                />
            </g>
        </g>
    </svg>
);

export default SvgGooglePlay;
