// #region Global Imports
import * as React from "react";
import { Range } from "rc-slider";
// #endregion Global Imports

// #region Local Imports
import { Container, HandleContainer } from "./styled";
import { SliderHandleAge } from "@Icons";
import { ICustomSliderRange } from "./CustomSliderRange";
// #endregion Local Imports

const CustomSliderRange: React.FunctionComponent<ICustomSliderRange.IProps> = ({
    rangeFrom,
    rangeTo,
    sendValues,
}): JSX.Element => {
    const [fromTo, setFromTo] = React.useState([rangeFrom, rangeTo]);

    React.useEffect(() => {
        setFromTo([rangeFrom, rangeTo]);
    }, [rangeFrom, rangeTo]);

    const handleChange = (rangeVal: any) => {
        setFromTo(rangeVal);
        sendValues(rangeVal[0], rangeVal[1]);
    };

    const CustomHandle: React.FunctionComponent<{
        offset: number;
        value: number;
        index: number;
    }> = (props): JSX.Element => {
        const { value, offset, index } = props;

        return (
            <HandleContainer offset={offset} key={index} index={index}>
                <input
                    type="number"
                    name={`slider-range-${index ? "to" : "from"}`}
                    min={18}
                    max={99}
                    value={value}
                    readOnly
                />
                <div className={`handle-${index ? "to" : "from"}`}>
                    <SliderHandleAge width="17px" height="20px" />
                    <span>{value}</span>
                </div>
            </HandleContainer>
        );
    };

    return (
        <Container>
            <Range
                min={18}
                max={99}
                allowCross={false}
                value={fromTo}
                onChange={handleChange}
                handle={CustomHandle}
                trackStyle={[
                    {
                        backgroundImage: "linear-gradient(to right, #00C1FF, #0076FF)",
                        height: 4,
                        zIndex: 9,
                    },
                ]}
            />
        </Container>
    );
};

export { CustomSliderRange };
