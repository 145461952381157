// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { IBadge } from "./Badge";
import { Container, Content, BadgeContent, DefaultBadgeContent } from "./styled";
// #endregion Local Imports

const Badge: React.FunctionComponent<IBadge.IProps> = ({
    count,
    color,
    hoverColor,
    children,
    customStyle,
    cy,
}): JSX.Element => {
    const value = count >= 100 ? "99+" : count;

    if (children)
        return (
            <Container data-cy={cy}>
                <Content hoverColor={hoverColor}>
                    {count > 0 && (
                        <BadgeContent
                            color={color}
                            hoverColor={hoverColor}
                            customStyle={customStyle}
                        >
                            {value}
                        </BadgeContent>
                    )}
                    {children}
                </Content>
            </Container>
        );

    return <DefaultBadgeContent color={color}>{value}</DefaultBadgeContent>;
};

export { Badge };
