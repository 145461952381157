// #region Local Imports
import { Http } from "@Services";
// #endregion Local Imports

// #region Interface Imports
import { OfferModel } from "@Interfaces";
// #endregion Interface Imports

export const OfferService = {
    GetOffer: async (userId: number | string): Promise<OfferModel.OfferResponse> => {
        return Http.Request("GET", `/profiles/${userId}/offer`, undefined);
    },
};
