// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports
import {
    DeleteButton as DeleteButtonContainer,
    Container as MainContainer,
    InfoContent as InfoContentContainer,
    AvatarContent as AvatarContentContainer,
    Buttons as ButtonsContainer,
} from "@Components/ProfileViewListItem/styled";

export const DeleteButton = styled(DeleteButtonContainer)``;

export const Container = styled(MainContainer)``;

export const AvatarContent = styled(AvatarContentContainer)`
    border-radius: 50%;
    overflow: hidden;
    width: 96px;
    height: 96px;
    padding: 0;
    margin-top: 12px;
    margin-right: 10px;
`;

export const InfoContent = styled(InfoContentContainer)``;

export const InfoContentItem = styled.div`
    font-size: 12px;
`;

export const Buttons = styled(ButtonsContainer)`
    margin-top: 5px;
`;

export const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 5px;
    margin-right: 5px;
`;
