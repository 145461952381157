// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { MetaDataService } from "@Services/API/MetaData";
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

export const MetaDataActions = {
    GetSearchFilterQuestions: () => async (dispatch: Dispatch) => {
        const result = await MetaDataService.SearchFilterQuestions();

        const searchFilterQuestions = {
            searchFilterQuestions: { Questions: result.Questions },
        };

        dispatch({
            payload: searchFilterQuestions,
            type: ActionConsts.MetaData.GetSearchFilterQuestions,
        });
    },
    FetchCountries: async () => async (dispatch: Dispatch) => {
        const response = await MetaDataService.FetchCountries();

        if (!response.Success) {
            throw new Error("Error");
        }

        dispatch({
            payload: { countries: response.Countries },
            type: ActionConsts.MetaData.FetchCountries,
        });
    },
    FetchCities: async (countryId: number, search: boolean) => async (dispatch: Dispatch) => {
        const response = await MetaDataService.FetchCities(countryId, search);

        if (!response.Success) {
            throw new Error("Error");
        }

        const action = {
            payload: search ? { searchCities: response.Cities } : { cities: response.Cities },
            type: ActionConsts.MetaData.FetchCities,
        };

        dispatch(action);
    },
    FetchTowns: async (cityId: number, search: boolean, keyword?: string) => async (
        dispatch: Dispatch
    ) => {
        const response = await MetaDataService.FetchTowns(cityId, search, keyword);

        if (!response.Success) {
            throw new Error("Error");
        }

        const action = {
            payload: { towns: response.Towns },
            type: ActionConsts.MetaData.FetchTowns,
        };

        dispatch(action);
    },
    DeleteTowns: () => (dispatch: Dispatch) => {
        dispatch({
            payload: { towns: [] },
            type: ActionConsts.MetaData.SetTowns,
        });
    },
    FetchJobs: async () => async (dispatch: Dispatch) => {
        const response = await MetaDataService.FetchJobs();

        if (!response.Success) {
            throw new Error("Error");
        }

        dispatch({
            payload: { jobs: response.Jobs },
            type: ActionConsts.MetaData.FetchJobs,
        });
    },
};
