// #region Global Imports
import React, { useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Button, TextInput } from "@Components/Basic";
import { FormBox } from "@Components";
import { FriendsIcon } from "@Icons";
import { IForgotPassword } from "./ForgotPassword";
import { Container, InnerContainer, InputContainer, BoxContainer, ContentText } from "./styled";
import { HomeActions, AccountActions } from "@Actions";
// #endregion Local Imports

export const ForgotPassword: React.FunctionComponent<IForgotPassword.IProps> = props => {
    const { t } = props;
    const dispatch = useDispatch();
    const [isSent, setIsSent] = useState(false);

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string().required(t("home:email.required")),
    });

    const onSubmit = (values: IForgotPassword.FormValues) => {
        dispatch(
            AccountActions.ForgotPassword({
                body: { usernameOrEmail: values.email },
            })
        );

        setIsSent(true);
    };

    const { register, handleSubmit, errors, formState } = useForm<IForgotPassword.FormValues>({
        validationSchema: ForgotPasswordSchema,
    });

    return (
        <Container {...props}>
            <FormBox
                title="ŞİFREMİ UNUTTUM"
                onCloseClick={() => {
                    dispatch(HomeActions.SetTopComponent({ topComponent: "login" }));
                }}
                onHandleSubmit={handleSubmit(onSubmit)}
            >
                <InnerContainer>
                    {!isSent && (
                        <>
                            <BoxContainer>
                                <ContentText>
                                    {t("home:forgotPassword.Lütfen kullanıcı")}
                                </ContentText>
                                <ContentText>
                                    {t("home:forgotPassword.Gerekli bilgilendirme")}
                                </ContentText>
                            </BoxContainer>
                            <InputContainer>
                                <form>
                                    <TextInput
                                        isValid={errors.email && false}
                                        placeholder="Kullanıcı Adı/E-posta"
                                        renderIcon={() => (
                                            <FriendsIcon width="38px" height="38px" />
                                        )}
                                        errorText={errors.email ? errors.email.message : ""}
                                        name="email"
                                        _ref={register}
                                    />

                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={formState.isSubmitting}
                                        type="submit"
                                        block
                                    >
                                        {t("home:buttons.send")}
                                    </Button>
                                </form>
                            </InputContainer>
                        </>
                    )}
                    {isSent && (
                        <BoxContainer>
                            <ContentText>{t("home:forgotPassword.Verilen bilgiler")}</ContentText>
                        </BoxContainer>
                    )}
                </InnerContainer>
            </FormBox>
        </Container>
    );
};
