import { Settings } from "react-slick";

interface UserDefined {
    xsmall: Partial<Settings>;
    small: Partial<Settings>;
    medium: Partial<Settings>;
    large: Partial<Settings>;
    xlarge: Partial<Settings>;
    common: Partial<{
        hide: boolean;
    }>;
}

export const getCustomSliderConfig = (
    screenType: string,
    { xsmall, small, medium, large, xlarge, common }: Partial<UserDefined> = {}
) => {
    const smartSliderProps = {
        hide: false,
        custom: {
            slidesToShow: 1,
            ...small,
        },
    };

    switch (screenType) {
        case "xsmall":
            return {
                ...smartSliderProps,
                custom: {
                    ...smartSliderProps.custom,
                    ...xsmall,
                },
            };
        case "small":
            return {
                ...smartSliderProps,
                custom: {
                    ...smartSliderProps.custom,
                    ...small,
                },
            };
        case "medium":
            return {
                ...smartSliderProps,
                custom: {
                    ...smartSliderProps.custom,
                    slidesToShow: 2,
                    ...medium,
                },
            };

        case "large":
            return {
                ...smartSliderProps,
                hide: true,
                ...common,
                custom: {
                    ...smartSliderProps.custom,
                    ...large,
                },
            };

        case "xlarge":
            return {
                ...smartSliderProps,
                hide: true,
                ...common,
                custom: {
                    ...smartSliderProps.custom,
                    ...xlarge,
                },
            };

        default:
            return smartSliderProps;
    }
};
