// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        margin-top: 20px;
    }
`;

export const UploadList = styled.div<{ optionType: string }>`
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    padding: 0 4px;
    position: absolute;
    width: ${({ optionType }) => (optionType === "small" ? "120px" : "150px")};
    z-index: 1;
    top: 40px;

    &:before {
        content: " ";
        position: absolute;
        height: 0;
        left: 45px;
        transform: rotate(180deg);
        top: -21px;
        bottom: auto;
        border: 11px solid;
        border-color: #fff transparent transparent transparent;
    }
`;

export const SmallUploadItem = styled.div`
    display: flex;
    padding: 10px 4px 0;
    justify-content: space-between;
    cursor: pointer;
        
    svg {
        padding-bottom: 1px;
        color: ${({ theme }) => theme.colors.gray};
    }
`;

export const UploadOptions = styled.div`
    display: flex;
    margin-bottom: 30px;
    margin-top: 15px;
`;

export const UploadItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
        
    svg {
        padding-bottom: 10px;
        color: ${({ theme }) => theme.colors.gray};
    }
`;
