import { IPusherComponent } from "@Components/PusherComponent/PusherComponent";

export const PusherSerializer = {
    MessageToRequest: (data: IPusherComponent.MessageSendData) => {
        return {
            With: PusherSerializer.MessageToProfile(data),
            LastMessage: {
                Date: new Date().toISOString(),
                IsRead: false,
                MessageId: Math.random().toString(),
                SenderId: data.From,
                SenderName: data.Username,
                Sticker: data.Sticker,
                Text: data.Message,
                Message: data.Message,
                Type: data.Type,
                Ip: null,
                WithProfile: {},
            },
            HasNew: true,
            IsPrioritized: data.IsPrioritized,
        };
    },
    MessageToUnreadCounts: (data: IPusherComponent.MessageSendData) => {
        return {
            NewRequestCount: data.UnreadRequest,
            NewChatsCount: data.UnreadDialogs,
            NewPrioritizedCount: data.UnreadPrioritized,
        };
    },
    MessageToMessage: (message: IPusherComponent.MessageSendData) => {
        return {
            MessageId: Math.random().toString(),
            Date: new Date(message.Date).toISOString(),
            Text: message.Message,
            SenderId: message.From,
            Type: message.Type,
            Sticker: message.Sticker,
            IsRead: false,
        };
    },
    MessageToProfile: (message: IPusherComponent.MessageSendData) => {
        return {
            Id: message.From,
            Age: message.Age,
            Photo: message.Photo,
            Gender: message.Gender,
            Job: message.Job,
            Location: message.Location,
            Username: message.Username,
            Zodiac: message.Zodiac,
            MaritalStatus: message.MaritalStatus,
            ZodiacId: null,
            IsOnline: null,
            JobId: null,
            TownName: null,
            MaritalStatusId: null,
        };
    },
};
