// #region Global Imports
import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    Text,
    Buttons,
    OpenProfileButton,
    GetShowcasedButton,
    AddPhotoButton,
} from "./styled";
import { CommonActions } from "@Actions";
import { useUpThere } from "@Definitions";
import { IStore } from "@Interfaces";
import { ContentBox } from "../../styled";
import { INoLikes } from "./NoLikes";
// #endregion Local Imports

export const NoLikes: FC<INoLikes.IProps> = ({ t }): JSX.Element => {
    const dispatch = useDispatch();

    const txt = (key: string) => t(`dashboard:HotOrNot.NoLikes.${key}`);

    const activatePhotoModal = async () => dispatch(await CommonActions.SetShowUploadPhoto(true));

    const { self } = useSelector(({ profile }: IStore) => profile);

    const { ContainsYou, setPurchaseRevealed } = useUpThere();

    return (
        <ContentBox>
            <Container>
                <Text>
                    <span>{txt("text1")}</span>
                    <br />
                    <span>{txt("text2")}</span>
                </Text>
                <Buttons>
                    <Link href="/profileEdit/[id]" as={`/profileEdit/${self?.Id}`}>
                        <a>
                            <OpenProfileButton size="medium" className="mb-4">
                                {txt("buttons.profile")}
                            </OpenProfileButton>
                        </a>
                    </Link>

                    <AddPhotoButton
                        styleType="ghost"
                        size="small"
                        className="mb-4"
                        onClick={activatePhotoModal}
                    >
                        {txt("buttons.addPhoto")}
                    </AddPhotoButton>

                    {!ContainsYou && (
                        <GetShowcasedButton
                            styleType="ghost"
                            size="small"
                            onClick={() => setPurchaseRevealed(true)}
                        >
                            {txt("buttons.showcase")}
                        </GetShowcasedButton>
                    )}
                </Buttons>
            </Container>
        </ContentBox>
    );
};
