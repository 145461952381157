// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import { Container, Time, AvatarContent, InfoContent, Message } from "./styled";
// #endregion Local Imports

export const MessageListItemSkeleton: React.FunctionComponent = () => (
    <>
        <Container HasNew={false}>
            <AvatarContent>
                <RectangleSkeleton width="50" height="50" />
            </AvatarContent>
            <InfoContent>
                <Time isSeen>
                    <RectangleSkeleton width="70" height="10" />
                </Time>
                <div>
                    <RectangleSkeleton width="100" height="10" />
                </div>
                <Message>
                    <RectangleSkeleton width="100" height="10" />
                </Message>
            </InfoContent>
        </Container>
        <hr className="py-0 my-1" />
    </>
);
