// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import {
    Container,
    CountText,
    BorderLine,
    PhotoContent,
    PhotoItem,
    PhotoContainer,
    UserImage,
    PhotoCountBox,
} from "./styled";
import { IProfileUserPhotos } from "./ProfileUserPhotos";
import { Preset } from "@Definitions";
// #endregion Local Imports

export const ProfileUserPhotos: React.FunctionComponent<IProfileUserPhotos.IProps> = ({
    photos,
    onPhotoClick,
}): JSX.Element => {
    const getKey = (index: number) => `user-photo-items-${index}`;

    const photoLength = photos.filter(x => x.State !== "Deleted").length;

    const findPhotoShowCount = (): number => {
        return photoLength === 2 || photoLength === 3 ? photoLength : 4;
    };

    return (
        <>
            {photos.filter(x => x.State !== "Deleted").length - 1 > 0 && (
                <Container photoCount={photos.length}>
                    <BorderLine />
                    <PhotoContainer>
                        <PhotoContent>
                            {photos
                                .filter(x => x.State !== "Deleted")
                                .slice(1, findPhotoShowCount())
                                .map((item, index) => (
                                    <PhotoItem
                                        key={getKey(index)}
                                        onClick={() => onPhotoClick(index + 1)}
                                    >
                                        <UserImage
                                            src={`${item.Url}${Preset.thumb}`}
                                            alt={item.PhotoName ? item.PhotoName : ""}
                                        />
                                    </PhotoItem>
                                ))}
                            {photoLength - findPhotoShowCount() > 0 && (
                                <PhotoCountBox>
                                    <CountText>
                                        {`+${photoLength - findPhotoShowCount()}`}
                                    </CountText>
                                </PhotoCountBox>
                            )}
                        </PhotoContent>
                    </PhotoContainer>
                    <BorderLine />
                </Container>
            )}
        </>
    );
};
