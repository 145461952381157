// #region Global Imports
import styled, { css } from "styled-components";
import { Col } from "@Styled/Grid";
// #endregion Global Imports

export const NotificationContainer = styled.div`
    position: absolute;
    top: 368px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        top: unset;
        bottom: 198px;
    }
`;

export const ReceivedNewMessageNotification = styled.div<{ active: boolean }>`
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.azureRadiance};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color: #fff;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(100%);
    opacity: 0;

    ${({ active }) =>
        active && {
            opacity: 1,
            transform: "translateY(0)",
        }}
`;

export const SendMessageContainer = styled.div<{ sectionType: string }>`
    position: relative;
    background-color: ${({ theme }) => theme.colors.lighterGray};
    border-bottom-right-radius: ${({ sectionType }) => (sectionType === "modal" ? "0px" : "12px")};
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
`;

export const MessagesContainer = styled.div<{ prioritized?: boolean; isMobile: boolean }>`
    height: 410px;
    flex: 1;
    overflow-y: scroll;
    background-image: ${({ prioritized }) => (prioritized ? "url('/images/prioritized.png')" : "")};

    ${({ isMobile }) => isMobile && { height: "100%" }};

    > div:first-child {
        margin-top: 75px;
    }

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        > div:first-child {
            margin-top: 125px;
        }
    }
`;

export const StyledIcon = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.gray};
    margin-right: 5px;

    :hover {
        color: ${({ theme }) => theme.colors.red};
    }
`;

export const UserButtons = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    right: -1px;
    top: 0px;
    background-color: ${({ theme }) => theme.colors.lighterGray};
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 0 12px 0 12px;
    padding: 10px;

    @media (max-width: ${({ theme }) => theme.maxWidth.small}) {
        top: 68px;
    }
`;

export const PreviewsCol = styled(Col)`
    max-height: 550px;
    overflow-y: scroll;

    @media (min-width: ${({ theme }) => theme.minWidth.medium}) {
        border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
    }
`;

export const HoverToolTipWrapper = styled.div`
    opacity: 0;
`;

export const IconButtonWrapper = styled.div`
    :hover {
        ${HoverToolTipWrapper} {
            opacity: 1;
        }
    }
`;

export const customHoverToolTipCss = css`
    :before {
        left: 4px;
    }
`;
