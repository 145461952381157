// #region Global Imports
import * as React from "react";
// #endregion Global Imports

// #region Local Imports
import { RectangleSkeleton } from "@Components/Basic";
import { Container } from "@Components/DashboardWidget/Components/BecomeGoldMember/styled";
import { Card, IconContainer, Button } from "./styled";
import { Col } from "@Styled";
// #endregion Local Imports

const Icon = () => (
    <IconContainer style={{ margin: "8px" }}>
        <RectangleSkeleton width="90" height="90" />
    </IconContainer>
);

interface Props {}

export const DashBoardWidgetSkeleton: React.FC<Props> = () => (
    <Card overflowHidden>
        <Container className="py-5 px-5 px-xl-9 flex-lg-column align-items-lg-center justify-content-lg-center">
            <Col className="mb-3">
                <Icon />
            </Col>
            <Col className="flex-column d-flex align-items-lg-center justify-content-center">
                <RectangleSkeleton width="180" height="24" style={{ marginBottom: "16px" }} />
                <Button>
                    <RectangleSkeleton width="120" height="44" />
                </Button>
            </Col>
        </Container>
    </Card>
);
