// #region Global Imports
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { Container as SideBar, Row, Col, LeftCol } from "@Styled/Dashboard";
import { ILayout } from "./Layout";
import { Container } from "./styled";
import {
    Footer,
    SearchMenu,
    Notifications,
    ProfileOccupancy,
    QuickProfileFill,
    DashboardWidget,
    ChatModal,
    ProbationModal,
    WinCreditModal,
} from "@Components";
import { useScreenSize } from "@Definitions";
import { ProfileActions } from "@Actions";

import { IStore } from "@Interfaces";

// #endregion Local Imports

const Layout: React.FunctionComponent<ILayout.IProps> = ({
    t,
    children,
    hasSideBar,
    hasOffer,
    renderSiderBarItem,
    hideProfileOccupancy,
}): JSX.Element => {
    const dispatch = useDispatch();
    const screenSize = useScreenSize();

    const inProbation = useSelector((state: IStore) => state.profile.self?.InProbation);

    const { NewRequestCount, NewChatsCount, NewPrioritizedCount } = useSelector(
        (state: IStore) => state.dashboard.notificationCounts
    );

    const showChatModal = useSelector((store: IStore) => store.common.showChatModal);

    const showWinCreditModal = useSelector((store: IStore) => store.common.showWinCreditModal);

    const [isModalOpen, setModalOpen] = React.useState(false);
    const [totalNotifCount, setTotalNotifCount] = React.useState(
        NewRequestCount + NewChatsCount + NewPrioritizedCount
    );

    const changeTitle = () => {
        if (typeof window !== "undefined") {
            if (totalNotifCount > 0) {
                const message = t("common:browserTitle.messageCount", {
                    count: totalNotifCount,
                });
                document.title = message;
            } else {
                const browserTitle = t("common:browserTitle.text");
                document.title = browserTitle;
            }
        }
    };

    React.useEffect(() => {
        setTotalNotifCount(NewRequestCount + NewChatsCount + NewPrioritizedCount);
        const browserTitle = t("common:browserTitle.text");

        window.addEventListener("focus", () => {
            document.title = browserTitle;
        });

        return () => {
            window.removeEventListener("focus", () => {
                document.title = browserTitle;
            });
        };
    }, [NewChatsCount, NewPrioritizedCount, NewRequestCount, t]);

    const renderOffer = () => {
        if (hasOffer && ["xsmall", "small", "medium"].includes(screenSize)) {
            return (
                <div className="container mb-4">
                    <div className="row">
                        <div className="col-12">
                            <DashboardWidget t={t} />
                        </div>
                    </div>
                </div>
            );
        }
        return false;
    };

    const handleProbation = async () => {
        dispatch(await ProfileActions.SeenProbation());
    };

    const renderProbation = () => <ProbationModal t={t} onButtonClick={handleProbation} />;

    return (
        <Container>
            {changeTitle()}
            {inProbation && renderProbation()}
            {renderOffer()}
            {hasSideBar ? (
                <SideBar className="mb-5">
                    <Row>
                        <Col className="col-lg-4 col-md-5 col-forefront">
                            {(screenSize === "large" ||
                                screenSize === "xlarge" ||
                                screenSize === "medium") && (
                                <LeftCol>
                                    <SearchMenu t={t} />
                                    <Notifications t={t} />
                                    {!hideProfileOccupancy && screenSize === "medium" && (
                                        <ProfileOccupancy
                                            openQuickFillModal={() => setModalOpen(true)}
                                            t={t}
                                        />
                                    )}
                                    {renderSiderBarItem}
                                </LeftCol>
                            )}
                        </Col>
                        {children}
                        {isModalOpen && (
                            <QuickProfileFill closeModal={() => setModalOpen(false)} t={t} />
                        )}
                    </Row>
                </SideBar>
            ) : (
                <div className="mb-5">{children}</div>
            )}

            <Footer t={t} />
            {showChatModal && <ChatModal t={t} />}
            {showWinCreditModal && <WinCreditModal t={t} />}
        </Container>
    );
};

export { Layout };
