// #region Global Imports
import * as React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { Container, Row, Col } from "@Styled/Grid";
import { Image } from "@Components/Basic";
import { IAppAd } from "./AppAd";
import { AppleIcon, GooglePlay } from "@Icons";
// #endregion Local Imports

const AppAdWrapper = styled.div`
    font-family: "Montserrat";
    color: #fff;
    font-size: 15px;
    font-weight: 500;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 180px;

    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        padding-top: 65px;
    }
    @media (max-width: ${({ theme }) => theme.maxWidth.large}) {
        padding-top: 50px;
    }
`;

const ContentHeader = styled.div`
    font-size: 24px;
    font-weight: 800;
`;

const PhoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StoreIcons = styled.div`
    display: flex;
    width: 30%;
    justify-content: space-around;
    padding-top: 12px;

    a {
        width: 100%;
    }
`;

const ContentText = styled.p`
    width: 95%;

    @media (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        width: 97%;
    }
`;

export const AppAd: React.FunctionComponent<IAppAd.IProps> = props => {
    const { t } = props;

    return (
        <AppAdWrapper {...props}>
            <Container>
                <Row>
                    <Col className="col-lg-4  col-md-3">
                        <ContentContainer>
                            <ContentHeader>
                                {t("home:appPromotion.Siberalem Her Yerde")}
                            </ContentHeader>
                            <ContentText>{t("home:appPromotion.Online dünya")}</ContentText>
                            <ContentText>{t("home:appPromotion.Akıllı Telefonlar")}</ContentText>
                            <ContentText>
                                {t("home:appPromotion.Biz de Siberalem olarak")}
                            </ContentText>
                        </ContentContainer>
                    </Col>
                    <Col className="col-lg-4 col-md-6">
                        <PhoneContainer>
                            <Image src="images/phone-ads.png" alt="phone advertisement" />
                            <StoreIcons>
                                <a
                                    href="//itunes.apple.com/tr/app/siberalem/id649741474?mt=8"
                                    target="_blank" rel="noreferrer"
                                >
                                    <AppleIcon width="48" height="48" />
                                </a>

                                <a
                                    href="//play.google.com/store/apps/details?id=com.workbench.siberalem&hl=tr"
                                    target="_blank" rel="noreferrer"
                                >
                                    <GooglePlay width="48" height="48" />
                                </a>
                            </StoreIcons>
                        </PhoneContainer>
                    </Col>
                    <Col className="col-lg-4 col-md-3 ">
                        <ContentContainer>
                            <ContentHeader>
                                {t("home:appPromotion.Siberalem IOS Uygulaması")}
                            </ContentHeader>
                            <ContentText>
                                {t("home:appPromotion.Siberalem IOS uygulamasını IPhone")}
                            </ContentText>
                        </ContentContainer>
                    </Col>
                </Row>
            </Container>
        </AppAdWrapper>
    );
};
