// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Container } from "./index.container";
// #endregion Local Imports

// #region Interface Imports
import { Component } from "./MasterDetailNav";
// #endregion Interface Imports

export const MasterDetailNav: Component = props => {
    const { masterAsPath } = props;

    return masterAsPath ? <Container {...props} /> : <div />;
};
