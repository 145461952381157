// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Button, Card, Text, Title } from "@Components/Basic";
import { theme } from "@Definitions";
import { Credit } from "@Icons";
import { Col, Row } from "@Styled";
import { Container } from "./styled";
// #endregion Local Imports

// #region Local Interface
import { IBuyCredits } from "./BuyCredits";
// #endregion Local Interface

const BuyCredits: React.FunctionComponent<IBuyCredits.IProps> = ({
    t,
    description,
}): JSX.Element => {
    return (
        <Card overflowHidden>
            <Container className="py-5 px-5 px-xl-9 text-center text-md-left align-items-lg-center text-lg-center">
                <Row className="no-gutters w-100">
                    <Col className="col-3 col-lg-12 d-none d-md-flex justify-content-center align-items-center d-lg-none">
                        <Credit width="90px" height="90px" color={theme.colors.mustard} />
                    </Col>
                    <Col className="col-12 col-md-9 col-lg-12">
                        <Title
                            className="mb-lg-4"
                            color="red"
                            size="large"
                            fontWeight="bold"
                            dangerouslySetInnerHTML={{
                                __html: t("dashboard:Widgets.BuyCredits.title"),
                            }}
                        />
                        <Credit width="268px" height="90px" color={theme.colors.mustard} className="mb-6 d-none d-lg-block"/>
                        <Text
                            className="mb-3"
                            color="black"
                            size="large"
                            weight="bold"
                            dangerouslySetInnerHTML={{
                                __html:
                                    description || t("dashboard:Widgets.BuyCredits.description"),
                            }}
                        />
                        <Link
                            href="/payments/[type]/credit-card?source=buy_coin"
                            as="/payments/coin/credit-card"
                        >
                            <a>
                                <Button styleType="gradient" className="text-uppercase">
                                    {t("dashboard:Widgets.BuyCredits.buy")}
                                </Button>
                            </a>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Card>
    );
};

export { BuyCredits };
