import * as React from "react";

const SvgCircleConversationIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 51 52" {...props}>
        <path
            d="M25.5.5C39.583.5 51 11.917 51 26S39.583 51.5 25.5 51.5 0 40.083 0 26 11.417.5 25.5.5zM30 17.815c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 2.361 0 4.563-.682 6.42-1.86h0l4.157 1.386.117.029a.75.75 0 00.832-.978h0l-1.386-4.157.203-.33A11.944 11.944 0 0042 29.814c0-6.627-5.373-12-12-12zm0 1.5c5.799 0 10.5 4.701 10.5 10.5 0 2.02-.57 3.95-1.626 5.617h0l-.382.601 1.136 3.41-2.734-.91-.676-.226-.602.382A10.443 10.443 0 0130 40.315c-5.799 0-10.5-4.7-10.5-10.5 0-5.799 4.701-10.5 10.5-10.5zM19.5 11c-6.627 0-12 5.373-12 12 0 2.361.682 4.563 1.86 6.42h0l-1.386 4.157-.025.099a.75.75 0 00.974.85h0l4.157-1.386.331.203a11.926 11.926 0 004.05 1.485 13.355 13.355 0 01-.538-1.646 10.424 10.424 0 01-3.04-1.308h0l-.601-.382-.676.225-2.734.911 1.136-3.41-.382-.602-.192-.315A10.444 10.444 0 019 23c0-5.799 4.701-10.5 10.5-10.5a10.48 10.48 0 018.248 4.002c.564-.094 1.14-.154 1.726-.176A11.988 11.988 0 0019.5 11z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.3}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgCircleConversationIcon;
