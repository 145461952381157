// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const SearchContainer = styled.div`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.foam};
`;

export const Container = styled.div`
    position: relative;
    height: 370px;
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.maxWidth.medium}) {
        height: unset;
    }
`;

export const Footer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.alto};
    padding: 5px 0;
    position: absolute;
    bottom: 0;
`;
