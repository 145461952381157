// #region Global Imports
import Router from "next/router";
// #endregion Global Imports

// #region Local Imports
import { SearchActions, CommonActions, DashboardActions } from "@Actions";
import { Analytics } from "@Services";
import { ScrollPositionHandler, StoreAllocator, DispatcherFunction } from "./RouteChangeHandler";
// #endregion Local Imports

const routeChangeStartData = { scrollY: 0 };

/**
 * Keeps and sets scroll position into the store accordingly
 * @param masterAsPath A query param to detect if routed from a master view
 * @param currentPath Path that is currently viewing
 */
const handleScrollPosition: ScrollPositionHandler = (dispatch, masterAsPath, currentPath) => {
    if (masterAsPath) {
        dispatch(CommonActions.SaveScrollPosition(routeChangeStartData.scrollY));
    }

    const isGoingOutOfMaster = ["search", "dashboard"].some(master => currentPath.includes(master));

    if (isGoingOutOfMaster) routeChangeStartData.scrollY = window.scrollY;
};

/**
 * Reusable resetter for store kept data
 */
export const resetScrollAndSavedData: DispatcherFunction = dispatch => {
    dispatch(DashboardActions.ResetRecentPhotos());
    dispatch(SearchActions.Reset());
    dispatch(CommonActions.SaveScrollPosition(0));
};

/**
 * Resets store-kept data if routed out of master/detail pattern
 * @param masterAsPath A query param to detect if routed from a master view
 * @param targetPath Target path that is currently routing to
 */
const handleStoreAllocation: StoreAllocator = (dispatch, masterAsPath, targetPath) => {
    const isNotComingFromDetail = !masterAsPath && targetPath.match(/search|dashboard/);

    if (isNotComingFromDetail) resetScrollAndSavedData(dispatch);
};

/**
 * Locks search results on master > detail navigation
 * and keeps scroll position.
 * @param dispatch store dispatcher
 */
export const attachRouterListener: DispatcherFunction = dispatch => {
    Router.events.on("routeChangeStart", (url: string) => {
        // * `masterAsPath` is only defined on `profile`
        // * and only if routed from `dashboard | search`.

        // ? Did route from a master
        const masterAsPath = Router.query?.masterAsPath?.toString() || "";

        handleStoreAllocation(dispatch, masterAsPath, url);

        handleScrollPosition(dispatch, masterAsPath, Router.asPath);
    });

    Router.events.on("routeChangeComplete", (url: string) => {
        Analytics.pageEvent();

        const isGoingToDashboard = url.includes("dashboard");

        if (!isGoingToDashboard) return;

        const omitScrollKeeper = Router.query?.omitScrollKeeper?.toString() || "";

        if (omitScrollKeeper) resetScrollAndSavedData(dispatch);
    });
};
