// #region Global Imports
import React, { useState, FC } from "react";
import { useDispatch } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { useUpThere } from "@Definitions";
import { CommonActions } from "@Actions";
import { Presentation } from "./index.presentation";
// #endregion Local Imports

// #region Interface Imports
import { IProps } from "./EmptyView";
// #endregion Interface Imports

export const EmptyView: FC<IProps> = props => {
    const dispatch = useDispatch();

    const [isQuickModalOpen, setQuickModal] = useState(false);

    const { ContainsYou, setPurchaseRevealed } = useUpThere();

    const activatePhotoModal = async () => dispatch(await CommonActions.SetShowUploadPhoto(true));

    const presentational = {
        ...props,
        activatePhotoModal,
        setPurchaseRevealed,
        ContainsYou,
        isQuickModalOpen,
        setQuickModal,
    };

    return <Presentation {...presentational} />;
};
