// #region Global Imports
import "isomorphic-unfetch";
import * as qs from "qs";
import Nookies from "nookies";
// #endregion Global Imports

// #region Local Imports
import { getEnvSafely } from "@Definitions/Wrappers";
import { pubSub } from "@Definitions/PubSub";
import { appendAdditionalParams } from "./helper";
// #endregion Local Imports

// #region Interface Imports
import { HttpModel } from "@Interfaces";
// #endregion Interface Imports

const API_URL = getEnvSafely("API_URL");
const BaseUrl = `${!process.browser ? API_URL : window.location.origin}/api`;

export const Http = {
    Request: async <A>(
        methodType: string,
        url: string,
        params?: HttpModel.IRequestQueryPayload,
        payload?: HttpModel.IRequestPayload,
        userObj?: { userId: string; token: string }
    ): Promise<A> => {
        return new Promise((resolve, reject) => {
            const query =
                methodType === "GET"
                    ? `?${qs.stringify(appendAdditionalParams(params || {}, userObj))}`
                    : "";

            const body =
                methodType === "POST" ? appendAdditionalParams(payload || {}, userObj) : payload;

            fetch(`${BaseUrl}${url}${query}`, {
                body: methodType !== "GET" ? qs.stringify(body) : null,
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: `${methodType}`,
            })
                .then(async response => {
                    if (response.status === 200) {
                        return resolve(await response.json());
                    }

                    if (response.status === 406) {
                        const json = await response.json();

                        if (json.Reason === "Guest") {
                            pubSub.publish("openRegisterModal");
                        }
                    }

                    if (response.status === 401) {
                        Nookies.destroy(null, "account", { path: "/" });
                        pubSub.publish("accountCancellation");
                    }

                    return reject(response);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    Upload: async <A>(url: string, payload: FormData): Promise<A> => {
        return new Promise((resolve, reject) => {
            const authenticationObject = appendAdditionalParams();

            payload.append("token", authenticationObject.token!);
            payload.append("userid", authenticationObject.userId!);

            fetch(`${BaseUrl}${url}`, {
                body: payload,
                cache: "no-cache",
                headers: {},
                method: "POST",
            })
                .then(async response => {
                    if (response.status === 200) {
                        return resolve(await response.json());
                    }
                    return reject(response);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
};
