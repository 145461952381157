// #region Global Imports
import * as React from "react";
import ContentLoader from "react-content-loader";
// #endregion Global Imports

// #region Local Imports
import { IPutMeUpHere } from "./PutMeUpHere";
import * as PMUH from "./styled";
import { SmartSlider } from "@Components";
import { getCustomSliderConfig, useScreenSize, repeatElement } from "@Definitions";
// #endregion Local Imports

export const Skeleton: React.FC<IPutMeUpHere.ISkeleton> = (): JSX.Element => {
    const biggestRect = (
        <ContentLoader
            speed={2}
            width={72}
            height={72}
            viewBox="0 0 72 72"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width="72" height="72" />
        </ContentLoader>
    );

    const screenType = useScreenSize();

    const customSliderConfig = getCustomSliderConfig(screenType, {
        xsmall: {
            slidesToShow: 5,
            slidesToScroll: 4,
        },
        small: {
            autoplay: true,
            slidesToShow: 5,
            autoplaySpeed: 4000,
        },
        medium: {
            slidesToShow: 8,
            slidesToScroll: 2,
        },
        large: {
            slidesToShow: 11,
            slidesToScroll: 3,
        },
        xlarge: {
            slidesToShow: 14,
            slidesToScroll: 3,
        },
        common: {
            hide: false,
        },
    });

    return (
        <PMUH.Container>
            <PMUH.Row>
                <PMUH.Col className="col-12">
                    <PMUH.RoundedBox isLoading={false}>
                        <PMUH.PurchaseViewToggle>
                            <PMUH.One>{biggestRect}</PMUH.One>
                        </PMUH.PurchaseViewToggle>
                        <SmartSlider
                            {...customSliderConfig}
                            slides={repeatElement(<PMUH.One>{biggestRect}</PMUH.One>, 20)}
                            hide={false}
                        />
                    </PMUH.RoundedBox>
                </PMUH.Col>
            </PMUH.Row>
        </PMUH.Container>
    );
};
