import * as React from "react";

const SvgFilterSetup = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <path
            d="M2 0a.5.5 0 01.5.5v8.563a2 2 0 010 3.874V15.5a.5.5 0 11-1 0l-.002-2.564a2 2 0 010-3.872L1.5.5A.5.5 0 012 0zm6 0a.5.5 0 01.5.5l.001 2.563a2 2 0 010 3.874L8.5 15.5a.5.5 0 11-1 0l-.002-8.564a2 2 0 010-3.872L7.5.5A.5.5 0 018 0zm6 0a.5.5 0 01.5.5l.001 6.563a2 2 0 010 3.874L14.5 15.5a.5.5 0 11-1 0l-.002-4.564a2 2 0 010-3.872L13.5.5A.5.5 0 0114 0zM2 10a1 1 0 100 2 1 1 0 000-2zm12-2a1 1 0 100 2 1 1 0 000-2zM8 4a1 1 0 100 2 1 1 0 000-2z"
            fill="currentcolor"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgFilterSetup;
