// #region Global Imports
import * as React from "react";
import Select from "react-select";
// #endregion Global Imports

// #region Local Imports
import { Container, ErrorText, NativeSelect, IconContainer } from "./styled";
import { theme, useScreenSize } from "@Definitions";
import { ChevronDown } from "@Icons";
import { IDropdown } from "./Dropdown";

// #endregion Local Imports

const styles = {
    option: (provided: any, state: IDropdown.ISelectState) => {
        return {
            ...provided,
            font: "Montserrat-medium",
            padding: "3px 15px",
            fontSize: "12px",
            borderBottom: "1px solid #dfe3ea",
            color: state.isSelected ? "#0076FF" : "#4A4A4A",
            background: "unset",

            "&:hover": {
                color: "#0076FF",
            },
            "&:active": {
                backgroundColor: "unset",
            },
        };
    },
    menu: (provided: any) => ({
        ...provided,
        padding: "10px",
        boxShadow: "2px 2px 12px 0px rgba(0,0,0,0.3)",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    control: (provided: any, state: any) => ({
        ...provided,
        paddingRight: "5px",
        paddingTop: "1px",
        borderRadius: "25px",
        fontFamily: "Montserrat",
        boxShadow: "none",
        borderColor: state.hasValue ? "#00F07B" : "#acb5c3",
        borderWidth: "2px",
        fontSize: "14px",
        fontWeight: "400",
        minWidth: "85px",
        width: state.selectProps.width || "unset",
        minHeight: state.selectProps.minHeight || "50px",
        paddingLeft: "1px",
        color: "4A4A4A",
        outline: "unset",
        "&:hover": {
            borderColor: state.selectProps.borderColor,
        },
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        "&:hover": {
            color: "#8791A1",
        },
        padding: "4px",
    }),
    multiValue: (provided: any) => {
        return {
            ...provided,
            borderRadius: "4px",
            backgroundColor: "#05D26E",
        };
    },
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: "#fff",
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: "#fff",
        paddingLeft: "0",
        paddingRight: "1",
        svg: {
            width: "18px",
            height: "18px",
            strokeWidth: "1",
        },
        ":hover": {
            backgroundColor: "#00BD60",
            color: "#fff",
        },
    }),
};

const { forwardRef } = React;

export const Dropdown: React.FunctionComponent<IDropdown.IProps> = forwardRef(
    (props, ref: React.Ref<HTMLSelectElement>) => {
        const {
            errorText,
            isValid,
            minHeight,
            width,
            borderColor,
            name,
            placeholder,
            onChange,
            value,
            options,
            forceReactSelect,
            cy,
            closeMenuOnSelect,
            t,
        } = props;

        const screenSize = useScreenSize();
        const isMobile = screenSize.includes("small");

        if (isMobile && !forceReactSelect) {
            const valueOption = value as IDropdown.ISelectOption;
            const nativeValue = (valueOption?.value as string) || "";
            const pHolder = placeholder as string;

            const handleNativeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
                if (onChange) {
                    const label = e.target[e.target.selectedIndex].innerText;
                    const option = { label, value: e.target.value };
                    onChange(option);
                }
            };

            return (
                <Container>
                    <NativeSelect
                        name={name}
                        placeholder={pHolder}
                        width={width || ""}
                        minHeight={minHeight || ""}
                        borderColor={borderColor || theme.colors.blue}
                        onChange={handleNativeChange}
                        value={nativeValue || undefined}
                        hasValue={nativeValue.length > 0}
                        ref={ref}
                        data-cy={cy}
                    >
                        {pHolder?.length && (
                            <option value="" selected disabled>
                                {placeholder}
                            </option>
                        )}

                        {options.map((item: IDropdown.ISelectOption) => {
                            return (
                                <option
                                    key={`dropdown-item-${item.value}`}
                                    value={item.value as string}
                                >
                                    {item.label}
                                </option>
                            );
                        })}
                    </NativeSelect>
                    <ErrorText>{isValid === false && errorText}</ErrorText>
                    <IconContainer>
                        <ChevronDown width="13px" height="13px" color={theme.colors.lightGray} />
                    </IconContainer>
                </Container>
            );
        }

        return (
            <Container>
                <Select
                    {...props}
                    id={cy}
                    instanceId={cy}
                    styles={styles as any} // TODO: FIX ME
                    width={width}
                    minHeight={minHeight}
                    borderColor={borderColor || theme.colors.blue}
                    closeMenuOnSelect={closeMenuOnSelect}
                    noOptionsMessage={() => t("common:dropDown.noOptions")}
                    menuPortalTarget={typeof window !== "undefined" ? document.body : null}
                    menuPosition="fixed"
                />
                <ErrorText>{isValid === false && errorText}</ErrorText>
            </Container>
        );
    }
);
