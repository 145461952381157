import * as React from "react";

const SvgWinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
        <path
            d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0zm0 1C8.611 1 1 8.611 1 18s7.611 17 17 17 17-7.611 17-17S27.389 1 18 1zm0 7c5.523 0 10 4.477 10 10s-4.477 10-10 10S8 23.523 8 18 12.477 8 18 8zm0 1a9 9 0 100 18 9 9 0 000-18zm-5.127 11.127a7.253 7.253 0 0010.253 0 .75.75 0 111.061 1.06 8.753 8.753 0 01-12.374 0 .75.75 0 111.06-1.06zM14.664 15a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8.093.75a.75.75 0 110 1.5h-3a.75.75 0 110-1.5h3z"
            fill="currentcolor"
            stroke="currentcolor"
            strokeWidth={0.5}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgWinkIcon;
