// #region Global Imports
import styled from "styled-components";
import { ICheckBoxGroup } from "./CheckBoxGroup";
// #endregion Global Imports

export const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const CheckboxContainer = styled.div<Partial<ICheckBoxGroup.IProps>>`
    width: 180px;
    margin-bottom: 3px;
    margin-right: 20px;

    ${({ customStyle }) => customStyle && customStyle};
`;
