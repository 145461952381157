// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { IInboxPage } from "@Interfaces";
// #endregion Local Imports

export const InboxFilterActions = {
    Reset: () => ({
        type: ActionConsts.InboxFilters.ResetReducer,
    }),

    SetFilters: (filters: IInboxPage.Filters) => (dispatch: Dispatch) => {
        dispatch({
            type: ActionConsts.InboxFilters.SetFilters,
            payload: {
                filters,
            },
        });
    },
};
