// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Preset } from "@Definitions";
import { CoinDark } from "@Icons";
import {
    Container,
    Content,
    Image,
    InnerWrapper,
    CoinContentContainer,
    CoinTime,
    ContentSpan,
    Icon,
    ContentWrapper,
} from "./styled";
// #endregion Local Imports

// #region Local Imports
import { INotificationItem } from "./NotificationItem";
// #endregion Local Imports

const typeChecker = (val: string): boolean => {
    switch (val) {
        case "profileView":
        case "message":
        case "wink":
        case "like":
        case "match":
        case "friend":
            return true;
        default:
            return false;
    }
};

const NotificationItem: React.FunctionComponent<INotificationItem.IProps> = ({
    image,
    text,
    dateText,
    type,
    id,
}): JSX.Element => {
    return (
        <Container type={type}>
            <InnerWrapper>
                {typeChecker(type) && (
                    <Link href="/profile/[id]?source=notification" as={`/profile/${id}`}>
                        <ContentWrapper>
                            <Image
                                src={`${image}${type === "like" ? Preset.blurThumb : Preset.thumb}`}
                                id="image-test"
                                alt="image-icon"
                            />
                            <Content>
                                <ContentSpan>{text}</ContentSpan>
                            </Content>
                        </ContentWrapper>
                    </Link>
                )}
                {type === "coin" && (
                    <>
                        <CoinDark width="36px" height="46px" />
                        <CoinContentContainer>
                            <Content>{text}</Content>
                            <CoinTime>
                                <ContentSpan>{dateText}</ContentSpan>
                            </CoinTime>
                        </CoinContentContainer>
                    </>
                )}
            </InnerWrapper>
        </Container>
    );
};

export { NotificationItem };
