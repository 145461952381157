// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Row, Col } from "@Styled/Dashboard";
import { Text } from "@Components/Basic";
import { Avatar } from "@Components";
import { Preset } from "@Definitions";
import { Container, UserInfo, AvatarStyle } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IIncentiveBanner } from "./IncentiveBanner";
// #endregion Interface Imports

export const IncentiveBanner: React.FunctionComponent<IIncentiveBanner.IProps> = ({
    t,
    IsOnline,
    Location,
    MaritalStatus,
    Job,
    Zodiac,
    Username,
    Age,
    Photo,
}) => (
    <Container className="p-1">
        <Row>
            <Col className="col-md-6 col-auto d-flex align-items-center right-border">
                <Avatar
                    customStyle={AvatarStyle}
                    alt="Profile Photo"
                    src={`${Photo!.Url}${Preset.thumb}`}
                    type={IsOnline ? "online" : "offline"}
                />
                <UserInfo className="ml-3">
                    <Text color="white" elementType="div" weight="bold">
                        {`${Username}, ${Age}`}
                    </Text>
                    <Text color="white" elementType="div" size="small">
                        {Location}
                        <br />
                        {Zodiac && Zodiac}
                        {Zodiac && MaritalStatus ? `, ` : ``}

                        {MaritalStatus && MaritalStatus}
                        {MaritalStatus && Job ? `, ` : ``}

                        {Job && `${Job}`}
                    </Text>
                </UserInfo>
            </Col>
            <Col className="col-md-6 col-auto custom-padding d-flex align-items-center">
                <Text
                    className="text-shrink"
                    color="white"
                    weight="bold"
                    elementType="div"
                    dangerouslySetInnerHTML={{
                        __html: t("common:payment.incentiveBanner.text", {
                            Username,
                        }),
                    }}
                />
            </Col>
        </Row>
    </Container>
);
